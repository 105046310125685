export function buildErrorMessage(kind: MediaDeviceKind): string {
  return `利用できる${displayDeviceKind(kind)}が見つかりません、本体設定や端末を変えて再度お試しください。`
}

function displayDeviceKind(kind: MediaDeviceKind): string {
  switch (kind) {
    case 'videoinput':
      return 'カメラ'
    case 'audioinput':
      return 'マイク'
    case 'audiooutput':
      return 'スピーカー'
  }
}
