import { useCallback } from 'react'
import { RequestEntryRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestRequestEntry() {
  const requestRequestEntry = useCallback((val: { interviewGuid: string; name: string }) => {
    const req = new RequestEntryRequest()

    req.setInterviewGuid(val.interviewGuid)
    req.setName(val.name)

    return callBff('requestEntry', [req])
  }, [])

  return { requestRequestEntry }
}
