import { useEffect } from 'react'

// https://usehooks.com/useOnClickOutside/ から持ってきて改変したもの

// Unlisence license
// https://github.com/gragland/usehooks/blob/c7a3b3fb7fa02480465b4d03f10b0bd1c0937e06/LICENSE

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ref = React.RefObject<any>

/**
 * ref を1つ受け取る。
 * クリックされたエリアがそのrefの外側である場合に、handler を実行する
 */
export function useOnClickOutside(ref: Ref, handler: (e: MouseEvent | TouchEvent) => void) {
  useOnClickOutsideAll([ref], handler)
}

/**
 * ref を複数受け取る。
 * クリックされたエリアが「すべてのrefの外側」である場合に、handler を実行する
 */
export function useOnClickOutsideAll(refs: ReadonlyArray<Ref>, handler: (e: MouseEvent | TouchEvent) => void) {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        for (const ref of refs) {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ('contains' in ref.current && ref.current.contains(event.target))) {
            return
          }
        }

        handler(event)
      }

      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)

      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add refs and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [refs, handler]
  )
}
