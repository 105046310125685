import React from 'react'
import { ErrorBox, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PageLayout } from '@/shared/components/PageLayout'

export const Expired: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} globalHeader={{ disableLogoLink: true }}>
      <Wrapper>
        <ErrorBox size="s">
          このページにはアクセスできません。
          <br />
          企業の担当者様にお問い合わせください。
        </ErrorBox>
      </Wrapper>
    </PageLayout>
  )
}

const Wrapper = styled.div`
  margin: 40px auto 0;

  width: 90%;
  max-width: 360px;
  padding: 16px;

  background-color: ${theme.color.white[1]};
`
