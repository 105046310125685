import { useEffect, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { DeviceChangeObserver } from 'amazon-chime-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'

const useAudioOutputContainer = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const [audioOutputs, setAudioOutputs] = useState<MediaDeviceInfo[]>([])
  const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState(meetingManager.selectedAudioOutputDevice)

  useEffect(() => {
    const callback = (updatedAudioOutputDevice: string | null): void => {
      setSelectedAudioOutputDevice(updatedAudioOutputDevice)
    }

    meetingManager.subscribeToSelectedAudioOutputDevice(callback)

    return (): void => {
      meetingManager.unsubscribeFromSelectedAudioOutputDevice(callback)
    }
  }, [meetingManager, audioOutputs, selectedAudioOutputDevice])

  useEffect(() => {
    let isMounted = true

    const observer: DeviceChangeObserver = {
      audioOutputsChanged: (newAudioOutputs: MediaDeviceInfo[]) => {
        setAudioOutputs(newAudioOutputs)
      },
    }

    async function initAudioOutput() {
      if (!audioVideo) {
        return
      }

      const devices = await audioVideo.listAudioOutputDevices()

      if (isMounted) {
        setAudioOutputs(devices)
        audioVideo.addDeviceChangeObserver(observer)
      }
    }

    initAudioOutput()

    return () => {
      isMounted = false
      audioVideo?.removeDeviceChangeObserver(observer)
    }
  }, [audioVideo])

  return useMemo(
    () => ({
      audioOutputs,
      selectedAudioOutputDevice,
    }),
    [audioOutputs, selectedAudioOutputDevice]
  )
}

export const AudioOutputContainer = createContainer(useAudioOutputContainer)
