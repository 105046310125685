import React from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { ErrorBox, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export const Expired: React.VFC = () => {
  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Wrapper>
        <ErrorBox size="s">
          このページは有効期限が切れています。
          <br />
          社内の人事担当者様にお問い合わせください。
        </ErrorBox>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  margin: 40px auto 0;

  width: 90%;
  max-width: 360px;
  padding: 16px;

  background-color: ${theme.color.white[1]};
`
