import React, { useMemo } from 'react'
import { Dropdown, DropdownOption, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  micDevices: readonly MediaDeviceInfo[]
  selectedMicDeviceId: string | undefined
  onChangeDevice: (deviceId: string) => void
}

export const ChangeMicDropdownOnBeforeEntered: React.VFC<Props> = (props) => {
  const options = useMemo<DropdownOption[]>(() => {
    return props.micDevices.map((d) => ({
      label: d.label,
      value: d.deviceId,
    }))
  }, [props.micDevices])

  return (
    <>
      <Wrapper>
        <TxtWrapper>
          <Txt bold>マイク</Txt>
        </TxtWrapper>
        <DropdownWrapper>
          <Dropdown
            size="max"
            value={props.selectedMicDeviceId}
            options={options}
            onChange={(e) => {
              props.onChangeDevice(e.target.value)
            }}
          />
        </DropdownWrapper>
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`
const TxtWrapper = styled.div`
  width: 80px;
  margin-bottom: 8px;
`

const DropdownWrapper = styled.div`
  margin-bottom: 8px;
  flex: 1;
`
