import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateSessionRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestCreateSession() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateSession = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new CreateSessionRequest()

    return callBff('createSession', [req, metadata], loggedInStaff?.guid)
  }, [buildMetadata, loggedInStaff])

  return { requestCreateSession }
}
