import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { VideoInputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { sharedSlice } from '../redux'
import { storeDeviceIdInLS } from '../storeDeviceIdInLS'

export const useUnmuteCamera = () => {
  const dispatch = useDispatch()
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { videoInputs, selectedVideoInputDevice } = VideoInputContainer.useContainer()

  return useCallback(async () => {
    // カメラミュート中にデバイスを変更した場合に必要
    if (selectedVideoInputDevice) {
      await meetingManager.selectVideoInputDevice(selectedVideoInputDevice)
      storeDeviceIdInLS('videoinput', videoInputs, selectedVideoInputDevice)
    }
    await meetingManager.unmuteCamera()
    dispatch(sharedSlice.actions.ownCameraMutedChanged(false))
  }, [dispatch, meetingManager, selectedVideoInputDevice, videoInputs])
}
