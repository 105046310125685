import React, { useCallback } from 'react'
import { ActiveTertiaryButton, TertiaryButton, Icon } from '@blue-agency/rogue'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { sharedSlice, SharedState } from '../../redux'
import { controlButtonBase } from './controlButtonBase'

export const BeforeEnteredMicMuteButton: React.VFC<{
  disabled: boolean
  sendMuteLog?: () => void
  sendUnmuteLog?: () => void
}> = ({ disabled, sendMuteLog, sendUnmuteLog }) => {
  const dispatch = useDispatch()

  const muteMic = useCallback(() => {
    if (sendMuteLog) {
      sendMuteLog()
    }
    dispatch(sharedSlice.actions.beforeEnteredOwnMicMutedChanged(true))
  }, [dispatch, sendMuteLog])

  const unmuteMic = useCallback(() => {
    if (sendUnmuteLog) {
      sendUnmuteLog()
    }
    dispatch(sharedSlice.actions.beforeEnteredOwnMicMutedChanged(false))
  }, [dispatch, sendUnmuteLog])

  return <MicMuteButtonConsumer muteMic={muteMic} unmuteMic={unmuteMic} disabled={disabled} />
}

type ConsumerProps = {
  muteMic: () => void
  unmuteMic: () => void
  disabled: boolean
}

const MicMuteButtonConsumer: React.VFC<ConsumerProps> = React.memo(({ muteMic, unmuteMic, disabled }) => {
  const isOwnMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)

  return isOwnMicMuted ? (
    <ActiveMuteButton
      text="解除"
      onClick={unmuteMic}
      icon={<Icon name="mute-mike" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_mic_unmute_button_on_before_entered_page' }}
    />
  ) : (
    <MuteButton
      text="ミュート"
      onClick={muteMic}
      icon={<Icon name="mike" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_mic_mute_button_on_before_entered_page' }}
    />
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const MuteButton = styled(TertiaryButton)`
  ${controlButtonBase};
  width: 52px;
`
// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const ActiveMuteButton = styled(ActiveTertiaryButton)`
  ${controlButtonBase};
  width: 52px;
`
