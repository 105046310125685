import React, { useEffect } from 'react'
import { CacheContainer } from '@blue-agency/front-state-management'
import { injectAlertBox } from '@blue-agency/im-shared-front'
import assert from 'assert'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Switch, Route } from 'react-router-dom'
import { MY_INTERNAL_PATHS } from '@/my/services/urlService'
import { SoundPlayContainer } from '@/shared/containers/SoundPlayContainer'
import { useUnauthorizedTracking } from '@/shared/hooks/useTracking'
import { history } from '../../historyModule'
import { MyErrorBoundary } from '../services/errorBoundaryService'
import { NotFoundPage } from '../services/errorBoundaryService/NotFoundPage'
import { CheckInterviewEnvironmentPage } from './CheckInterviewEnvironmentPage'
import { InterviewPage } from './InterviewPage'

const alertJsonUrl = process.env.REACT_APP_MY_ALERT_JSON_URL
assert(alertJsonUrl, 'alertJsonUrl is required')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
    },
  },
})

export const MyRoutes: React.VFC = React.memo(() => {
  useUnauthorizedTracking(history)
  useEffect(() => {
    injectAlertBox(alertJsonUrl)
  }, [])

  return (
    <MyErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <CacheContainer.Provider>
          <SoundPlayContainer.Provider>
            <Switch>
              <Route exact path={MY_INTERNAL_PATHS.interview} component={InterviewPage} />
              <Route
                exact
                path={MY_INTERNAL_PATHS.checkInterviewEnvironment}
                component={CheckInterviewEnvironmentPage}
              />
              <Route component={NotFoundPage} />
            </Switch>
            <ReactQueryDevtools />
          </SoundPlayContainer.Provider>
        </CacheContainer.Provider>
      </QueryClientProvider>
    </MyErrorBoundary>
  )
})
