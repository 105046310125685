import { useEffect } from 'react'
import { Comlink } from '@blue-agency/comlink'

/**
 * 閉じたときにcomlinkの取りこぼしがないようにflushしておく
 */
export const useComlinkFlushOnUnload = () => {
  useEffect(() => {
    const fn = () => {
      Comlink.flushLazyEvents()
    }
    window.addEventListener('beforeunload', fn)
    window.addEventListener('unload', fn)
    return () => {
      window.removeEventListener('beforeunload', fn)
      window.removeEventListener('unload', fn)
    }
  }, [])
}
