import { useState } from 'react'
import { Txt, Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export const DeletedRecording: React.VFC = () => {
  const [isDisplayDeletedRecording, setIsDisplayDeletedRecording] = useState<boolean>(true)
  const onClick = () => {
    setIsDisplayDeletedRecording(false)
  }
  if (!isDisplayDeletedRecording) {
    return null
  }
  return (
    <Wrapper>
      <DeletedRecordingIcon />
      <Txt>録画は既に削除されています</Txt>
      <CloseIcon onClick={onClick} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 60px;
  width: 100%;
  margin-top: 12px;
  background-color: ${theme.color.gray[4]};
  border-radius: 4px;
  align-items: center;
  position: relative;
`

const CloseIcon = styled(Icon).attrs({
  name: 'close',
  size: 'm',
})`
  position: absolute;
  right: 8px;
  top: 8px;
  &:hover {
    cursor: pointer;
  }
`

const DeletedRecordingIcon = styled(Icon).attrs({
  name: 'deleted-recording',
  size: 'll',
})`
  fill: none;
  margin-right: 8px;
`
