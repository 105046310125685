import { Seconds } from '../../containers/InterviewTimerContainer'

export const timerFormat = (seconds: Seconds) => {
  const h = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')

  const min = Math.floor(seconds % 60)
    .toString()
    .padStart(2, '0')

  return `${h}:${min}`
}
