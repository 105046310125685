import { useCallback } from 'react'
import { GetEntryRequestStatusRequest, GetEntryRequestStatusResponse } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export const EntryRequestStatus = GetEntryRequestStatusResponse.Status

export function useRequestGetEntryRequestStatus() {
  const requestGetEntryRequestStatus = useCallback(
    (val: { interviewGuid: string; entryRequestGuid: string; invitationToken: string }) => {
      const req = new GetEntryRequestStatusRequest()

      req.setInterviewGuid(val.interviewGuid)
      req.setEntryRequestGuid(val.entryRequestGuid)
      req.setInvitationToken(val.invitationToken)

      return callBff('getEntryRequestStatus', [req])
    },
    []
  )

  return { requestGetEntryRequestStatus }
}
