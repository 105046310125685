import React, { useMemo } from 'react'
import { ParticipantAudioVolumesContainer } from '@/shared/services/interviewService/containers/ParticipantAudioVolumesContainer'
import { AudioWaveCircle, AudioWaveCircleProps } from './AudioWaveCircle'

export interface RemoteAudioWaveCircleProps extends Omit<AudioWaveCircleProps, 'volume'> {
  attendeeId: string
}

export const RemoteAudioWaveCircle: React.VFC<RemoteAudioWaveCircleProps> = ({ attendeeId, ...rest }) => {
  const { remoteAudioVolumes } = ParticipantAudioVolumesContainer.useContainer()
  const vol = useMemo<number | undefined>(() => remoteAudioVolumes[attendeeId], [remoteAudioVolumes, attendeeId])

  return <AudioWaveCircle {...rest} volume={vol} />
}
