import { useCallback, useEffect } from 'react'
import { buildErrorMessage, useSpecificMediaDevice } from '@blue-agency/react-media-devices'
import { useDispatch } from 'react-redux'
import { useModal } from '@/shared/hooks/useModal'
import { useNoiseSuppression } from '@/shared/hooks/useNoiseSuppression'
import { useTransformStream } from '@/shared/hooks/useTransformStream'
import { useUA } from '@/shared/hooks/useUA'
import { isMac, isWindows } from '@/shared/services/browserCheckService/browser'
import { sharedSlice } from '@/shared/services/interviewService/redux'

export function useBeforeEntered() {
  const dispatch = useDispatch()

  const camera = useSpecificMediaDevice('videoinput')
  const mic = useSpecificMediaDevice('audioinput')
  const speaker = useSpecificMediaDevice('audiooutput')
  const stream = camera.stream
  const ua = useUA()
  const isUsedPC = isMac(ua) || isWindows(ua)

  useEffect(() => {
    if (camera.error) {
      alert(buildErrorMessage(camera.error))
    }
  }, [camera.error])
  useEffect(() => {
    if (mic.error) {
      alert(buildErrorMessage(mic.error))
    }
  }, [mic.error])

  useEffect(() => {
    // streamが取れない = エラーの場合、カメラ無効状態にする
    dispatch(sharedSlice.actions.unavailableVideoInputChanged(camera.error !== null))
  }, [dispatch, camera.error])

  useEffect(() => {
    // streamが取れない = エラーの場合、マイク無効状態にする
    dispatch(sharedSlice.actions.unavailableAudioInputChanged(mic.error !== null))
  }, [dispatch, mic.error])

  const isChangeCameraEnabled = isUsedPC
  const isChangeMicEnabled = isUsedPC
  const isChangeSpeakerEnabled = isUsedPC
  const isChangeNoiseSuppressionEnabled = isUsedPC
  const isChangeLightAdjustmentEnabled = isUsedPC

  const backgroundSettingModal = useModal()
  const {
    transformedStream,
    backgroundEffect,
    changeBackgroundEffect,
    backgroundUserImage,
    saveBackgroundUserImage,
    enabledLightAdjustment,
    setEnabledLightAdjustment,
    lightAdjustmentLevel,
    setLightAdjustmentLevel,
  } = useTransformStream(stream)
  const { enabledNoiseSuppression, setEnabledNoiseSuppression } = useNoiseSuppression()

  const videoElRef = useCallback(
    async (el: HTMLVideoElement | null) => {
      if (el === null) return
      if (transformedStream === undefined) return

      el.srcObject = transformedStream
    },
    [transformedStream]
  )

  return {
    videoElRef,
    isChangeCameraEnabled,
    camera,
    isChangeMicEnabled,
    mic,
    isChangeSpeakerEnabled,
    speaker,
    openBackgroundSettingModal: backgroundSettingModal.open,
    isOpenBackgroundSettingModal: backgroundSettingModal.active,
    closeBackgroundSettingModal: backgroundSettingModal.close,
    backgroundEffect,
    changeBackgroundEffect,
    backgroundUserImage,
    saveBackgroundUserImage,
    isChangeNoiseSuppressionEnabled,
    enabledNoiseSuppression,
    setEnabledNoiseSuppression,
    isChangeLightAdjustmentEnabled,
    enabledLightAdjustment,
    setEnabledLightAdjustment,
    lightAdjustmentLevel,
    setLightAdjustmentLevel,
  }
}
