import { useEffect, useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useDispatch } from 'react-redux'
import { useInterviewWebSocket, WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { sharedSlice } from '@/shared/services/interviewService/redux'
import { InterviewPageContainer } from '../../containers/InterviewPageContainer'
import { mySlice } from '../redux/mySlice'

const vaderWebHost = process.env.REACT_APP_VADER_WEB_HOST
if (!vaderWebHost) throw new Error('vaderWebHost not found')

function useInterview() {
  const dispatch = useDispatch()
  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) {
    throw new Error('invalid state')
  }
  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }

  const webSocketUrl = useMemo(() => {
    return `wss://${vaderWebHost}/channel_status/${pageCtx.videoInterviewGuid}`
  }, [pageCtx.videoInterviewGuid])

  const ws = useInterviewWebSocket(webSocketUrl)
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    const startHandler = (message: WebSocketMessageMap['start']) => {
      dispatch(sharedSlice.actions.startMessageReceived(message))
    }
    const finishHandler = () => {
      dispatch(mySlice.actions.finishMessageReceived())
    }

    addMessageListener('start', startHandler)
    addMessageListener('finish', finishHandler)

    return () => {
      removeMessageListener('start', startHandler)
      removeMessageListener('finish', finishHandler)
    }
  }, [addMessageListener, dispatch, removeMessageListener])

  return {
    interviewGuid: pageCtx.interviewGuid,
    ws,
  }
}

export const InterviewContainer = createContainer(useInterview)
