import React, { useEffect, useRef } from 'react'
import { theme } from '@blue-agency/rogue'
import { RemovableAnalyserNode } from 'amazon-chime-sdk-js'
import styled from 'styled-components'
import { assertIsDefined } from '@/assertions'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { AudioInputContainer } from '@/lib/meetingcomponent/DevicesContainer'

type Props = {
  width: string
  height: string
}

export const AudioWave: React.VFC<Props> = ({ width, height }) => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { audioInputs, selectedAudioInputDevice, audioInputDeviceUpdateTime } = AudioInputContainer.useContainer()
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvasAudioWaveRef = useRef<CanvasAudioWave | null>(null)

  useEffect(() => {
    if (!canvasRef.current || !audioVideo || !selectedAudioInputDevice) {
      return
    }

    const analyzer = audioVideo.createAnalyserNodeForAudioInput()
    if (!analyzer) {
      return
    }
    if (canvasAudioWaveRef.current) {
      canvasAudioWaveRef.current.cleanup()
      canvasAudioWaveRef.current = null
    }
    canvasAudioWaveRef.current = new CanvasAudioWave(
      canvasRef.current,
      analyzer,
      12,
      10,
      80,
      4,
      theme.color.green[4],
      theme.color.gray[3],
      4
    )

    canvasAudioWaveRef.current.draw()

    return () => {
      if (canvasAudioWaveRef.current) {
        canvasAudioWaveRef.current.cleanup()
        canvasAudioWaveRef.current = null
      }
    }
  }, [audioVideo, selectedAudioInputDevice, audioInputs, audioInputDeviceUpdateTime])
  // 選択しているデバイスが変わったとき、あるいは新しいデバイスが接続されてそれがデフォルトになったとき
  // ノイズ抑制オンオフだけが変わったときはデバイスIDは同じなのでaudioInputDeviceUpdateTimeで見る
  // enabledNoiseSuppressionだとデバイスの準備が終わる前に呼ばれる場合がある

  return <Canvas ref={canvasRef} width={width} height={height} />
}

const Canvas = styled.canvas`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`

export class CanvasAudioWave {
  private rafId: number | null = null

  constructor(
    private canvas: HTMLCanvasElement | null,
    private analyzer: RemovableAnalyserNode | null,
    private waveBarLength: number,
    private baseBarHeight: number,
    private maxBarHeight: number,
    private barWidth: number,
    private barColor: string,
    private vCenterLineColor: string,
    private barPadding: number,
    private fftSize: number = 128
  ) {
    assertIsDefined(analyzer)
    analyzer.fftSize = fftSize
  }

  draw() {
    if (this.rafId || !this.analyzer || !this.fftSize || !this.canvas) {
      return
    }

    const dpr = window.devicePixelRatio || 1
    this.canvas.width = this.canvas.offsetWidth * dpr
    this.canvas.height = this.canvas.offsetHeight * dpr
    const ctx = this.canvas.getContext('2d')
    if (!ctx) {
      return
    }
    ctx.save()
    ctx.scale(dpr, dpr)

    // getByteFrequencyDataによって返されるデータに含まれる最大周波数の概数
    // https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/getByteFrequencyData
    const approximateMaxFrequency = this.analyzer.context.sampleRate / 2
    // 2000Hzくらいの周波数に対応するindex. 2000Hz: 人間にとって聞き取りやすい周波数
    const from = Math.ceil((2000 * this.fftSize) / approximateMaxFrequency)

    const step = () => {
      if (!this.analyzer || !this.canvas) {
        return
      }
      if (!ctx) {
        return
      }
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

      const freqData = new Uint8Array(this.analyzer.frequencyBinCount)
      this.analyzer.getByteFrequencyData(freqData)
      const barHeights = [...Array(this.waveBarLength).keys()].map(
        (i) => this.baseBarHeight + ((freqData[i + from] ?? 0) / 255) * (this.maxBarHeight - this.baseBarHeight)
      )

      for (let i = 0; i < barHeights.length; i++) {
        this.drawBar(ctx, i * (this.barWidth + this.barPadding) + this.barPadding, barHeights[i] ?? this.baseBarHeight)
      }

      this.drawHorizontalLine(ctx, ctx.canvas.offsetHeight / 2)

      this.rafId = requestAnimationFrame(step)
    }

    this.rafId = requestAnimationFrame(step)
  }

  private drawHorizontalLine(ctx: CanvasRenderingContext2D, y: number) {
    ctx.beginPath()
    ctx.strokeStyle = this.vCenterLineColor
    ctx.lineWidth = 1
    ctx.moveTo(0, y)
    ctx.lineTo(ctx.canvas.width, y)
    ctx.stroke()
  }

  private drawBar(ctx: CanvasRenderingContext2D, x: number, h: number) {
    const w = this.barWidth
    const y0 = ctx.canvas.offsetHeight / 2
    const radius = w / 2
    ctx.beginPath()
    ctx.strokeStyle = this.barColor
    ctx.lineWidth = w
    ctx.lineCap = 'round'
    // 直線の始端・終端に円弧がつくので、その分の高さを引いて円弧も含めて指定した高さになるようにする
    ctx.moveTo(x + w / 2, y0 - h / 2 + radius)
    ctx.lineTo(x + w / 2, y0 + h / 2 - radius)
    ctx.stroke()
  }

  cleanup() {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId)
      this.rafId = null
    }

    this.canvas?.getContext('2d')?.restore()
    this.canvas = null

    if (this.analyzer) {
      this.analyzer.disconnect()
      this.analyzer.removeOriginalInputs()
      this.analyzer = null
    }
  }
}
