import { useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useSelector } from 'react-redux'
import { InterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { calcPartOffsets } from '@/shared/services/interviewService/containers/InterviewGuideContainer/calcPartOffsets'
import { InterviewTimerContainer } from '@/shared/services/interviewService/containers/InterviewTimerContainer'
import { SharedState } from '@/shared/services/interviewService/redux'

export type Part = {
  title: string
  description: string
  startSeconds: number
  isHighlight: boolean
  isCurrentPart: boolean
}

type Guide = {
  name: string
  parts: Part[]
}

const useInterviewGuide = () => {
  const interviewPage = InterviewPageContainer.useContainer()
  const { time } = InterviewTimerContainer.useContainer()
  const interviewState = useSelector((state: SharedState) => state.shared.interviewState)
  const isRunning = interviewState === 'InRunning'

  const isTimeDisable = useMemo(() => {
    if (!interviewPage.isValid) {
      return false
    }
    if (interviewPage.guide === undefined) {
      return false
    }
    const guide = interviewPage.guide
    // 1パートのみで0分開始の場合、時間の存在を知らせない
    return guide.partsList.length === 1 && guide.firstPartStartSeconds === 0
  }, [interviewPage])

  const guide = useMemo<Guide | undefined>(() => {
    if (!interviewPage.isValid) {
      return undefined
    }
    if (interviewPage.guide === undefined) {
      return undefined
    }
    const guide = interviewPage.guide
    const shouldHighlight = isRunning && !isTimeDisable
    const parts = calcPartOffsets(guide.partsList, guide.firstPartStartSeconds).map((part): Part => {
      return {
        title: part.title,
        description: part.description,
        startSeconds: part.startSeconds,
        isHighlight: shouldHighlight && part.startSeconds <= time && time < part.startSeconds + part.durationSeconds,
        isCurrentPart: part.startSeconds <= time && time < part.startSeconds + part.durationSeconds,
      }
    })

    return {
      name: guide.name,
      parts: parts,
    }
  }, [interviewPage, time, isTimeDisable, isRunning])

  const currentPart = useMemo<Part | undefined>(() => {
    return guide?.parts.find((part) => part.isCurrentPart)
  }, [guide])

  return {
    guide,
    currentPart,
    isTimeDisable,
  }
}

export const BizInterviewGuideContainer = createContainer(useInterviewGuide)
