import { useCallback, useContext } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { sharedSlice, SharedState } from '@/shared/services/interviewService/redux'

const useInterviewPinning = () => {
  const dispatch = useDispatch()

  const isInterviewPinningAreaOpen = useSelector((state: SharedState) => state.shared.isInterviewPinningAreaOpen)

  const { responsive } = useContext(ThemeContext)

  const toggleInterviewPinningAreaOpen = useCallback(() => {
    dispatch(sharedSlice.actions.toggleInterviewPinningAreaOpen({ shouldCloseChatArea: !responsive.pc }))
  }, [dispatch, responsive])

  return {
    isInterviewPinningAreaOpen,
    toggleInterviewPinningAreaOpen,
  }
}

export const InterviewPinningContainer = createContainer(useInterviewPinning)
