import { useCallback, useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { DataChannelMessagingContainer, labelMicMuted } from '@/lib/meetingcomponent/DataChannelMessagingContainer'
import { logger } from '@/logger'
import { isDataChannelReadySelector, participantsSelector, SharedState } from '@/shared/services/interviewService/redux'

export const useBroadcastMicMuted = () => {
  const { sendMessage } = DataChannelMessagingContainer.useContainer()

  const isOwnMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)
  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)
  const isDataChannelReady = useSelector(isDataChannelReadySelector)
  const participants = useSelector(participantsSelector, shallowEqual)

  const broadcastMuted = useCallback(
    (soraClientId: string, muted: boolean) => {
      sendMessage(labelMicMuted, { soraClientId, muted })
    },
    [sendMessage]
  )

  useEffect(() => {
    if (ownSoraClientId === undefined) return
    if (!isDataChannelReady) return

    logger.info('broadcastMuted', ownSoraClientId, isOwnMicMuted)
    broadcastMuted(ownSoraClientId, isOwnMicMuted)
  }, [isOwnMicMuted, participants, ownSoraClientId, isDataChannelReady, broadcastMuted])
}
