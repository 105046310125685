import type { SizeInNumber } from './useAreaSize'

export type TileSizeCalculator = (numParticipants: number) => SizeInNumber

export function getTileSizeCalculator(entireAreaSize: SizeInNumber): TileSizeCalculator {
  return (numParticipants: number) => {
    return calc(numParticipants, entireAreaSize.width, entireAreaSize.height)
  }
}

/**
 * 面接の参加人数と面接の描画エリア全体の広さを受け取り、タイルビューにしたときの1つのタイルの大きさを計算する
 */
export function calc(numParticipants: number, width: number, height: number): SizeInNumber {
  // 16:9 のタイルを、タイルの大きさができる限り大きくなるように領域に敷き詰めることを考える
  // そのような敷き詰めをすると、列(col)の数はいくつになるか？という候補を2つ出す (col1, col2)
  // 列の数が決まれば、行の数も決まり、タイルの大きさも決まる
  // 最後に、2つの候補に対して、最適な方(=タイルが大きい方)を選び、返却する

  const col1 = Math.max(1, Math.floor(0.75 * Math.sqrt((numParticipants * width) / height)))
  const result1 = inner(col1, numParticipants, width, height)

  const col2 = Math.max(1, Math.ceil(0.75 * Math.sqrt((numParticipants * width) / height)))
  const result2 = inner(col2, numParticipants, width, height)

  if (result1.height >= result2.height) {
    return result1
  } else {
    return result2
  }
}

function inner(col: number, numParticipants: number, width: number, height: number): SizeInNumber {
  const row = Math.ceil(numParticipants / col)
  const scale = Math.min(Math.floor(width / (16 * col)), Math.floor(height / (9 * row)))

  return {
    width: 16 * scale,
    height: 9 * scale,
  }
}
