import React, { useCallback } from 'react'
import { ActiveTertiaryButton, TertiaryButton, Icon } from '@blue-agency/rogue'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { sharedSlice, SharedState } from '../../redux'
import { controlButtonBase } from './controlButtonBase'

export const BeforeEnteredCameraMuteButton: React.VFC<{
  disabled: boolean
  sendOnCameraLog?: () => void
  sendOffCameraLog?: () => void
}> = React.memo(({ disabled, sendOnCameraLog, sendOffCameraLog }) => {
  const dispatch = useDispatch()

  const muteCamera = useCallback(() => {
    if (sendOnCameraLog) {
      sendOnCameraLog()
    }
    dispatch(sharedSlice.actions.ownCameraMutedChanged(true))
  }, [dispatch, sendOnCameraLog])

  const unmuteCamera = useCallback(() => {
    if (sendOffCameraLog) {
      sendOffCameraLog()
    }
    dispatch(sharedSlice.actions.ownCameraMutedChanged(false))
  }, [dispatch, sendOffCameraLog])

  return <CameraMuteButtonConsumer muteCamera={muteCamera} unmuteCamera={unmuteCamera} disabled={disabled} />
})

type ConsumerProps = {
  muteCamera: () => void
  unmuteCamera: () => void
  disabled: boolean
}

const CameraMuteButtonConsumer: React.VFC<ConsumerProps> = React.memo(({ muteCamera, unmuteCamera, disabled }) => {
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)

  return isOwnCameraMuted ? (
    <UnmuteButton
      text="解除"
      onClick={unmuteCamera}
      icon={<Icon name="movie-off" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_camera_unmute_button' }}
    />
  ) : (
    <MuteButton
      text="オフ"
      onClick={muteCamera}
      icon={<Icon name="movie" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_camera_mute_button' }}
    />
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const UnmuteButton = styled(ActiveTertiaryButton)`
  ${controlButtonBase};
  width: 52px;
`
// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const MuteButton = styled(TertiaryButton)`
  ${controlButtonBase};
  width: 52px;
`
