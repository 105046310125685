import React, { useCallback } from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, DangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

const text = `ご自身の画面を再読込します。
Web面接の接続を一度切断し、入室前の画面に戻ります。`

type Props = {
  active: boolean
  onCancel: () => void
}

export const ReloadModal: React.VFC<Props> = ({ active, onCancel }) => {
  const handleReload = useCallback(() => {
    window.location.reload()
  }, [])
  return (
    <Modal active={active} title="再読込確認" onClose={onCancel} size="m">
      <>
        <Upper>
          <Txt>{text}</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton onClick={onCancel} comlinkPushParams={{ action: 'click_close_reload_modal_button' }}>
              キャンセル
            </CancelButton>
            <NextButton onClick={handleReload} comlinkPushParams={{ action: 'click_reload_button' }}>
              再読込
            </NextButton>
          </ButtonGroup>
        </Bottom>
      </>
    </Modal>
  )
}

const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 40px;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton).attrs({ widthSize: 'MAX' })`
  width: 48%;
`
const NextButton = styled(DangerButton).attrs({ widthSize: 'MAX' })`
  width: 48%;
`
