import React, { useCallback, useEffect } from 'react'
import { TertiaryButton, Icon } from '@blue-agency/rogue'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { sharedSlice, SharedState } from '../../redux'
import { controlButtonBase } from './controlButtonBase'

export const MicMuteButton: React.VFC = () => {
  const dispatch = useDispatch()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  useEffect(() => {
    if (!ownSoraClientId) return
    dispatch(sharedSlice.actions.enteringInitialMicMutedChanged())
  }, [dispatch, ownSoraClientId])

  const muteMic = useCallback(() => {
    if (!audioVideo) {
      return
    }

    audioVideo.realtimeMuteLocalAudio()
  }, [audioVideo])

  const unmuteMic = useCallback(() => {
    if (!audioVideo) {
      return
    }

    audioVideo.realtimeUnmuteLocalAudio()
  }, [audioVideo])

  return <MicMuteButtonConsumer muteMic={muteMic} unmuteMic={unmuteMic} />
}

type ConsumerProps = {
  muteMic: () => void
  unmuteMic: () => void
}

const MicMuteButtonConsumer: React.VFC<ConsumerProps> = React.memo(({ muteMic, unmuteMic }) => {
  const isMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)
  const disabled = useSelector(
    (state: SharedState) => state.shared.isUnavailableAudioInput || state.shared.userSignalingStatus !== 'Completed'
  )

  return isMicMuted ? (
    <Button
      text="解除"
      onClick={unmuteMic}
      icon={<Icon name="mute-mike-color" size="l" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_mic_mute_active_button' }}
    />
  ) : (
    <Button
      text="ミュート"
      onClick={muteMic}
      icon={<Icon name="mike" size="l" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_mic_mute_button' }}
    />
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const Button = styled(TertiaryButton)`
  ${controlButtonBase};
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`
