import React, { useMemo } from 'react'
import { theme, Toggle, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { isSupportedNoiseSuppression } from '@/lib/interview-sdk-js'
import { getUserAgent } from '@/shared/services/userAgentService'

type Props = {
  enabledNoiseSuppression: boolean
  onChangeNoiseSuppression: (value: boolean) => void
  sendTurnOnNoiseSuppression?: () => void
  sendTurnOffNoiseSuppression?: () => void
}

export const ChangeNoiseSuppressionOnBeforeEntered: React.VFC<Props> = (props) => {
  const ua = useMemo(() => getUserAgent(), [])

  if (!isSupportedNoiseSuppression(ua)) {
    return null
  }

  const onChangeNoiseSuppressionWrap = (value: boolean) => {
    if (props.sendTurnOnNoiseSuppression && props.sendTurnOffNoiseSuppression) {
      if (value) {
        props.sendTurnOnNoiseSuppression()
      } else {
        props.sendTurnOffNoiseSuppression()
      }
    }
    props.onChangeNoiseSuppression(value)
  }

  return (
    <Wrapper>
      <Control>
        <ToggleWrapper>
          <Toggle checked={props.enabledNoiseSuppression} onChange={onChangeNoiseSuppressionWrap} />
        </ToggleWrapper>
        <Title>
          <Txt bold>雑音を抑制</Txt>
        </Title>
      </Control>
      <Description>
        <Txt>自分の周囲の雑音を抑制します</Txt>
      </Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.gray[5]};
  padding: 8px 7px 11px;
`

const Control = styled.div`
  display: flex;
  align-items: center;
`

const ToggleWrapper = styled.div``

const Title = styled.div`
  margin-left: 15px;
`

const Description = styled.div`
  margin-top: 1px;
  margin-left: 53px;
`
