import { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { useComlinkPushWithStaffGuid } from '@blue-agency/im-shared-front'
import { CreateCameraTestInvitationResponse } from '@blue-agency/proton/biz_skywalker_bff'
import {
  OverallTestOnConnectCallback,
  OverallTestConnectionConfigProps,
  OverallTestConnectionProps,
  OverallTestOnFailureCallback,
} from '@blue-agency/react-environment-check'
import invariant from 'tiny-invariant'
import { useRequestCreateCameraTestInvitation, useRequestDeleteCameraTestSessionToken } from '@/biz/services/bffService'

export const useEnvironmentCheck = () => {
  const [createProtocolTestRes, setCreateProtocolTestRes] = useState<CreateCameraTestInvitationResponse>()
  const comlinkPushWithStaffGuid = useComlinkPushWithStaffGuid()
  //今までカメラに繋いだときにプロトコルチェックをしていたので、それをそのまま流用している
  const { requestCreateCameraTestInvitation } = useRequestCreateCameraTestInvitation()

  useEffect(() => {
    const fn = async () => {
      const res = await requestCreateCameraTestInvitation()
      setCreateProtocolTestRes(res)
    }
    fn()
  }, [requestCreateCameraTestInvitation])

  const soraProps = useMemo<OverallTestConnectionConfigProps | undefined>(() => {
    if (!createProtocolTestRes) return
    return {
      sessionToken: createProtocolTestRes.getSessionToken(),
      channelId: createProtocolTestRes.getSoraChannelId(),
      webrtcHost: createProtocolTestRes.getWebrtcHost(),
    }
  }, [createProtocolTestRes])

  const connectedRef = useRef(false)

  const onConnect = useCallback<OverallTestOnConnectCallback>(
    (result) => {
      invariant(soraProps)

      comlinkPushWithStaffGuid({
        action: 'succeeded_to_connect_to_sora_in_protocol_test',
        metadata: {
          channelId: soraProps.channelId,
          sessionToken: soraProps.sessionToken,
          protocol: result.protocol ?? '',
          sendonlyAttempt: result.sendonlyAttempt?.toString() ?? '',
          recvonlyAttempt: result.recvonlyAttempt?.toString() ?? '',
        },
      })
      connectedRef.current = true
    },
    [comlinkPushWithStaffGuid, soraProps]
  )

  const onFailure = useCallback<OverallTestOnFailureCallback>(
    (e) => {
      invariant(soraProps)

      comlinkPushWithStaffGuid({
        action: 'failed_to_connect_to_sora_in_protocol_test',
        metadata: {
          role: e.role ?? '',
          errorMessage: e.message ?? '',
          reason: e.reason ?? '',
          sessionToken: soraProps.sessionToken,
        },
      })
    },
    [comlinkPushWithStaffGuid, soraProps]
  )

  const { requestDeleteCameraTestSessionToken } = useRequestDeleteCameraTestSessionToken()

  const onLeave = useCallback(async () => {
    if (!connectedRef.current) return
    invariant(soraProps)

    await requestDeleteCameraTestSessionToken(soraProps.sessionToken)
    comlinkPushWithStaffGuid({
      action: 'delete_protocol_test_session_token',
      metadata: {
        sessionToken: soraProps.sessionToken,
      },
    })
  }, [comlinkPushWithStaffGuid, requestDeleteCameraTestSessionToken, soraProps])

  const protocolCheckConnectionProps = useMemo<OverallTestConnectionProps | undefined>(() => {
    if (!soraProps) return
    return { sora: soraProps, onConnect, onFailure, onLeave }
  }, [onConnect, onFailure, onLeave, soraProps])

  return { protocolCheckConnectionProps }
}
