// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import {
  AudioMixController,
  AudioProfile,
  BrowserBehavior,
  ConnectionMonitor,
  EventController,
  Logger,
  MediaStreamBroker,
  MeetingSessionTURNCredentials,
  MeetingSessionVideoAvailability,
  RealtimeController,
  ReconnectController,
  RemovableObserver,
  SDP,
  SignalingClient,
  StatsCollector,
  TransceiverController,
  VideoCaptureAndEncodeParameter,
  VideoDownlinkBandwidthPolicy,
  VideoStreamIdSet,
  VideoStreamIndex,
  VideoTileController,
  VideoUplinkBandwidthPolicy,
  VolumeIndicatorAdapter,
} from 'amazon-chime-sdk-js'
import { SdkIndexFrame, SdkStreamServiceType } from 'amazon-chime-sdk-js/build/signalingprotocol/SignalingProtocol'
import { ImMeetingSessionConfiguration } from '..'
import { AudioVideoController } from './AudioVideoController'

/**
 * [[ImAudioVideoControllerState]] includes the compute resources shared by [[Task]].
 */
export class ImAudioVideoControllerState {
  logger: Logger

  browserBehavior: BrowserBehavior | null = null

  signalingClient: SignalingClient | null = null

  peer: RTCPeerConnection | null = null

  previousSdpOffer: SDP | null = null

  sdpOfferInit: RTCSessionDescriptionInit | null = null

  realtimeController: RealtimeController | null = null

  videoTileController: VideoTileController | null = null

  mediaStreamBroker: MediaStreamBroker | null = null

  audioMixController: AudioMixController | null = null

  activeAudioInput: MediaStream | null = null

  activeVideoInput: MediaStream | null = null

  transceiverController: TransceiverController | null = null

  indexFrame: SdkIndexFrame | null = null

  iceCandidates: RTCIceCandidate[] = []

  iceCandidateHandler: ((event: RTCPeerConnectionIceEvent) => void) | null = null

  iceGatheringStateEventHandler: (() => void) | null = null

  sdpAnswer: string | null = null

  turnCredentials: MeetingSessionTURNCredentials | null = null

  reconnectController: ReconnectController | null = null

  removableObservers: RemovableObserver[] = []

  audioProfile: AudioProfile | null = null

  videoStreamIndex: VideoStreamIndex | null = null

  videoDownlinkBandwidthPolicy: VideoDownlinkBandwidthPolicy | null = null

  videoUplinkBandwidthPolicy: VideoUplinkBandwidthPolicy | null = null

  lastKnownVideoAvailability: MeetingSessionVideoAvailability | null = null

  localVideoSender: RTCRtpSender | null = null

  localAudioSender: RTCRtpSender | null = null

  videoCaptureAndEncodeParameter: VideoCaptureAndEncodeParameter | null = null

  videosToReceive: VideoStreamIdSet | null = null

  videoSubscriptions: number[] | null = null

  videosPaused: VideoStreamIdSet | null = null

  videoDuplexMode: SdkStreamServiceType | null = null

  volumeIndicatorAdapter: VolumeIndicatorAdapter | null = null

  statsCollector: StatsCollector | null = null

  connectionMonitor: ConnectionMonitor | null = null

  videoInputAttachedTimestampMs = 0

  audioDeviceInformation: { [id: string]: string } = {}

  videoDeviceInformation: { [id: string]: string } = {}

  enableSimulcast = false

  eventController: EventController | null = null

  signalingOpenDurationMs: number | null = null

  iceGatheringDurationMs: number | null = null

  startAudioVideoTimestamp: number | null = null

  attendeePresenceDurationMs: number | null = null

  meetingStartDurationMs: number | null = null

  poorConnectionCount = 0

  maxVideoTileCount = 0

  startTimeMs: number | null = null

  recvOnlyPeer: RTCPeerConnection | null = null

  meetingSessionConfiguration: ImMeetingSessionConfiguration | null = null

  audioVideoController: AudioVideoController | null = null

  constructor(logger: Logger) {
    this.logger = logger
  }
}
