// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import * as Sentry from '@sentry/react'
import {
  AudioInputDevice,
  BrowserBehavior,
  ConsoleLogger,
  DefaultActiveSpeakerPolicy,
  DefaultBrowserBehavior,
  DefaultDeviceController,
  DefaultVideoTile,
  Device,
  GetUserMediaError,
  LogLevel,
  MeetingSessionConfiguration,
  MeetingSessionPOSTLogger,
  MultiLogger,
  NoVideoDownlinkBandwidthPolicy,
  NoVideoUplinkBandwidthPolicy,
  VideoInputDevice,
  VoiceFocusDeviceTransformer,
  VoiceFocusPaths,
} from 'amazon-chime-sdk-js'
import { ConnectionPublisher, ConnectionSubscriber } from 'sora-js-sdk'
import UAParser from 'ua-parser-js'
import {
  AudioVideoFacade,
  ImAudioVideoFacade,
  AudioVideoObserver,
  ImMeetingSession,
  ImMeetingSessionConfiguration,
  ImMeetingSessionStatus,
  ImMeetingSessionStatusCode,
} from '..'
import { isSupportedNoiseSuppression } from '../..'
import { AudioWaveAnalyser } from '../../AudioWaveAnalyser'
import { assertIsDefined } from '../../assertions'
import { findCandidateDevice } from '../../deviceCandidate/findCandidateDevice'
import { getSelectedDeviceIdFromStorage } from '../../deviceStorage'
import { RTCStatsLoggingParams, startRTCStatsLogging, StopRTCStatsLogging } from '../../rtcStatsLogging'
import { GetAudioInputMediaError, GetVideoInputMediaError } from '../error'
import { MutedVideoStream } from '../mutedvideostream/MutedVideoStream'
import { UnavailableVideoInputStream } from '../unavailablevideoinputstream/UnavailableVideoInputStream'
import {
  isSupportedBackgroundEffect,
  isSupportedLightAdjustment,
  MediaProcessorVideoTransformDevice,
} from '../videotransformdevice/MediaProcessorVideoTransformDevice'

interface StartAnalyseOption {
  interval?: number
  fftSize?: number
}

export enum DevicePermissionStatus {
  UNSET = 'UNSET',
  IN_PROGRESS = 'IN_PROGRESS',
  GRANTED = 'GRANTED',
  GRANTED_VIDEO_ONLY = 'GRANTED_VIDEO_ONLY',
  GRANTED_AUDIO_ONLY = 'GRANTED_AUDIO_ONLY',
  DENIED = 'DENIED',
}

export type FullDeviceInfoType = {
  selectedAudioOutputDevice: string | null
  selectedAudioInputDevice: string | null
  selectedVideoInputDevice: string | null
  audioInputDevices: MediaDeviceInfo[] | null
  audioOutputDevices: MediaDeviceInfo[] | null
  videoInputDevices: MediaDeviceInfo[] | null
}

export interface PostLogConfig {
  name: string
  batchSize: number
  intervalMs: number
  url: string
  logLevel: LogLevel
}

export interface ManagerConfig {
  logLevel: LogLevel
  postLogConfig?: PostLogConfig
  simulcastEnabled?: boolean
}

export enum MeetingStatus {
  Loading,
  Succeeded,
  Failed,
  Ended,
}

export type MeetingStatusObserver = (meetingStatus: MeetingStatus, meetingSessionStatus: ImMeetingSessionStatus) => void

export type VideoInputQuality = {
  width?: number
  height?: number
  frameRate?: number
}
export const DEFAULT_WIDTH = 640
export const DEFAULT_HEIGHT = 360
export const DEFAULT_FRAME_RATE = 20

const ASSETS_HOST = 'assets.interview-maker.com'

interface HTMLVideoElementWithSetSinkId extends HTMLVideoElement {
  setSinkId: (sinkId: string) => Promise<void>
}

export type BackgroundEffect =
  | { type: 'no-effect' }
  | { type: 'blur'; blurSize: number }
  | { type: 'replace'; image: HTMLImageElement }

export type LightAdjustment = { type: 'no-effect' } | { type: 'adjustment'; strength: number }

export class ImMeetingManager implements AudioVideoObserver {
  meetingSession: ImMeetingSession | null = null

  meetingStatus: MeetingStatus = MeetingStatus.Loading

  meetingStatusObservers: MeetingStatusObserver[] = []

  audioVideo: AudioVideoFacade | null = null

  audioVideoObservers: AudioVideoObserver = {}

  configuration: ImMeetingSessionConfiguration | null = null

  contentShareConfiguration: ImMeetingSessionConfiguration | null = null

  meetingId: string | null = null

  selectedAudioOutputDevice: string | null = null

  selectedAudioOutputDeviceObservers: ((deviceId: string | null) => void)[] = []

  selectedAudioInputDevice: string | null = null

  selectedAudioInputDeviceObservers: ((deviceId: string | null) => void)[] = []

  selectedVideoInputDevice: string | null = null

  selectedVideoInputDeviceObservers: ((deviceId: string | null) => void)[] = []

  audioInputDevices: MediaDeviceInfo[] | null = null

  audioOutputDevices: MediaDeviceInfo[] | null = null

  videoInputDevices: MediaDeviceInfo[] | null = null

  devicePermissionStatus = DevicePermissionStatus.UNSET

  devicePermissionsObservers: ((permission: DevicePermissionStatus) => void)[] = []

  activeSpeakerListener: ((activeSpeakers: string[]) => void) | null = null

  activeSpeakerCallbacks: ((activeSpeakers: string[]) => void)[] = []

  activeSpeakers: string[] = []

  audioVideoCallbacks: ((audioVideo: AudioVideoFacade | null) => void)[] = []

  devicesUpdatedCallbacks: ((fullDeviceInfo: FullDeviceInfoType) => void)[] = []

  logLevel: LogLevel = LogLevel.INFO

  postLoggerConfig: PostLogConfig | null = null

  simulcastEnabled = false

  browserBehavior: BrowserBehavior = new DefaultBrowserBehavior()

  private cameraMuted = false
  private unavailableVideoInput = false
  private unavailableAudioInput = false

  private backgroundEffect: BackgroundEffect = { type: 'no-effect' }

  private lightAdjustment: LightAdjustment = { type: 'no-effect' }

  private enabledNoiseSuppression = false

  private enabledLightAdjustment = false

  private isSupportedVoiceFocusNoiseSuppression: boolean | null = null

  private voiceFocusDeviceTransformer: VoiceFocusDeviceTransformer | null = null

  private _stopRTCStatsLogging?: StopRTCStatsLogging

  private analyserIdToAudioWaveAnalyser: {
    [id: string]: AudioWaveAnalyser
  } = {}

  private audioInputAudioWaveAnalyser?: AudioWaveAnalyser

  private lastVideoDevice: VideoInputDevice | undefined

  constructor(config: ManagerConfig) {
    this.logLevel = config.logLevel

    if (config.simulcastEnabled) {
      this.simulcastEnabled = config.simulcastEnabled
    }

    if (config.postLogConfig) {
      this.postLoggerConfig = config.postLogConfig
    }
  }

  initializeMeetingManager(): void {
    this.meetingSession = null
    this.audioVideo = null
    this.configuration = null
    this.contentShareConfiguration = null
    this.meetingId = null
    this.selectedAudioOutputDevice = null
    this.selectedAudioInputDevice = null
    this.selectedVideoInputDevice = null
    this.audioInputDevices = []
    this.audioOutputDevices = []
    this.videoInputDevices = []
    this.activeSpeakers = []
    this.activeSpeakerListener = null
    this.meetingStatus = MeetingStatus.Loading
    this.publishMeetingStatus()
    this.audioVideoObservers = {}
    this.analyserIdToAudioWaveAnalyser = {}
  }

  async joinAndStart(
    userSendRecv: ConnectionPublisher,
    contentSendOnly: ConnectionPublisher,
    contentRecvOnly: ConnectionSubscriber | null,
    beforeEnteredOwnCameraMuted: boolean,
    beforeEnteredOwnMicMuted: boolean,
    unavailableVideoInput: boolean,
    unavailableAudioInput: boolean,
    videoInputQuality?: VideoInputQuality,
    backgroundEffect?: BackgroundEffect,
    lightAdjustment?: LightAdjustment,
    enabledNoiseSuppression?: boolean,
    enabledLightAdjustment?: boolean
  ): Promise<void> {
    this.unavailableVideoInput = unavailableVideoInput
    this.unavailableAudioInput = unavailableAudioInput

    await this.join(userSendRecv, contentSendOnly, contentRecvOnly, videoInputQuality)
    assertIsDefined(this.audioVideo)
    if (!(this.audioVideo instanceof ImAudioVideoFacade)) {
      throw new Error('Improper class provided')
    }
    const tileId = this.audioVideo.setupLocalVideoTile()
    assertIsDefined(tileId)

    if (backgroundEffect) {
      this.backgroundEffect = backgroundEffect
    }

    if (lightAdjustment) {
      this.lightAdjustment = lightAdjustment
    }

    this.cameraMuted = beforeEnteredOwnCameraMuted

    await this.chooseVideoInputDeviceAsState()

    this.enabledNoiseSuppression = enabledNoiseSuppression ?? false
    if (this.selectedAudioInputDevice) {
      await this.chooseAudioInputDevice(this.selectedAudioInputDevice)
    }

    this.enabledLightAdjustment = enabledLightAdjustment ?? false
    if (this.enabledLightAdjustment) {
      await this.getVideoInputDevice(this.selectedVideoInputDevice)
    }

    if (beforeEnteredOwnMicMuted) {
      this.audioVideo.realtimeMuteLocalAudio()
    } else {
      this.audioVideo.realtimeUnmuteLocalAudio()
    }

    await this.start()
  }

  async join(
    userSendRecv: ConnectionPublisher,
    contentSendOnly: ConnectionPublisher,
    contentRecvOnly: ConnectionSubscriber | null,
    videoInputQuality?: VideoInputQuality
  ): Promise<void> {
    this.configuration = new ImMeetingSessionConfiguration(
      {
        MeetingId: userSendRecv.channelId,
        MediaPlacement: {
          SignalingUrl: `wss://${userSendRecv.signalingUrlCandidates}/signaling`,
        },
      },
      {
        AttendeeId: 'local',
        ExternalUserId: 'local',
      }
    )
    this.configuration.videoDownlinkBandwidthPolicy = new NoVideoDownlinkBandwidthPolicy()
    this.configuration.videoUplinkBandwidthPolicy = new NoVideoUplinkBandwidthPolicy()

    this.configuration.soraConnectionPublisher = userSendRecv
    if (contentRecvOnly) {
      this.configuration.soraConnectionSubscriber = contentRecvOnly
    }

    this.contentShareConfiguration = new ImMeetingSessionConfiguration(
      {
        MeetingId: contentSendOnly.channelId,
        MediaPlacement: {
          SignalingUrl: `wss://${contentSendOnly.signalingUrlCandidates}/signaling`,
        },
      },
      {
        AttendeeId: 'local#content',
        ExternalUserId: 'local#content',
      }
    )
    this.contentShareConfiguration.videoDownlinkBandwidthPolicy = new NoVideoDownlinkBandwidthPolicy()
    this.contentShareConfiguration.videoUplinkBandwidthPolicy = new NoVideoUplinkBandwidthPolicy()
    this.contentShareConfiguration.soraConnectionPublisher = contentSendOnly

    if (this.simulcastEnabled) {
      throw new Error('Simulcast not supported')
    }

    this.meetingId = this.configuration.meetingId
    await this.initializeMeetingSession(this.configuration, this.contentShareConfiguration, videoInputQuality)
  }

  async start(): Promise<void> {
    this.audioVideo?.start()
  }

  chooseVideoInputQuality(videoInputQuality?: VideoInputQuality): void {
    assertIsDefined(this.audioVideo)

    const width = videoInputQuality?.width || DEFAULT_WIDTH
    const height = videoInputQuality?.height || DEFAULT_HEIGHT
    const frameRate = videoInputQuality?.frameRate || DEFAULT_FRAME_RATE
    this.audioVideo.chooseVideoInputQuality(width, height, frameRate, 800)
  }

  // 再接続を行う際にstopBeforeRestartを実施・完了し、PreparedRestartになった後に実行する
  // 間に必要な処理を挟めるようにPreparedRestartからrestartを自動では実行しないようにしている
  async restart(): Promise<void> {
    assertIsDefined(this.audioVideo)
    await this.selectStoredDevices()
    await this.listAndSelectDevices()

    await this.chooseVideoInputDeviceAsState()

    this.audioVideo.startLocalVideoTile()
    this.audioVideo.start()
  }

  async stopBeforeRestart(): Promise<void> {
    if (this.audioVideo) {
      this.audioVideo.stopLocalVideoTile()
      await this.audioVideo.chooseVideoInputDevice(null)
      await this.audioVideo.chooseAudioInputDevice(null)
      await this.audioVideo.chooseAudioOutputDevice(null)
      this.audioVideo.stopBeforeRestart()
    }
  }

  async leave(): Promise<void> {
    if (this.audioVideo) {
      this.audioVideo.stopContentShare()
      this.audioVideo.stopLocalVideoTile()
      this.audioVideo.unbindAudioElement()

      try {
        await this.audioVideo.chooseVideoInputDevice(null)
        await this.audioVideo.chooseAudioInputDevice(null)
        await this.audioVideo.chooseAudioOutputDevice(null)
      } catch (e) {
        // 退室するのでエラーは無視する
        Sentry.captureException(e)
      }

      if (this.activeSpeakerListener) {
        this.audioVideo.unsubscribeFromActiveSpeakerDetector(this.activeSpeakerListener)
      }

      this.audioVideo.stop()
      this.audioVideo.removeObserver(this.audioVideoObservers)
    }
    this.initializeMeetingManager()
    this.publishAudioVideo()
    this.publishActiveSpeaker()
    this.stopRTCStatsLogging()
  }

  async initializeMeetingSession(
    config: ImMeetingSessionConfiguration,
    sendOnlyContentShareConfig: ImMeetingSessionConfiguration,
    videoInputQuality?: VideoInputQuality
  ): Promise<void> {
    const logger = this.createLogger(config)
    const ua = new UAParser(navigator.userAgent).getResult()
    const deviceControllerOption = {
      enableWebAudio: isSupportedNoiseSuppression(ua),
    }
    const deviceController = new DefaultDeviceController(logger, deviceControllerOption)

    this.meetingSession = new ImMeetingSession(config, sendOnlyContentShareConfig, logger, deviceController)

    this.audioVideo = this.meetingSession.audioVideo
    this.chooseVideoInputQuality(videoInputQuality)
    this.setupAudioVideoObservers()
    this.setupDeviceLabelTrigger()
    await this.selectStoredDevices()
    await this.listAndSelectDevices()
    this.publishAudioVideo()
    this.setupActiveSpeakerDetection()
    this.meetingStatus = MeetingStatus.Loading
    this.publishMeetingStatus()
  }

  async muteCamera(): Promise<void> {
    this.cameraMuted = true
    await this.chooseVideoInputDeviceAsState()
  }

  async unmuteCamera(): Promise<void> {
    this.cameraMuted = false
    await this.chooseVideoInputDeviceAsState()
    MutedVideoStream.instance.stop()
  }

  /**
   * stateに応じてchooseVideoInputDeviceを呼ぶ
   */
  async chooseVideoInputDeviceAsState(): Promise<void> {
    if (this.lastVideoDevice instanceof MediaProcessorVideoTransformDevice) {
      await this.lastVideoDevice.stop()
    }

    const device = await (async () => {
      if (this.unavailableVideoInput) {
        return UnavailableVideoInputStream.instance.start()
      } else if (this.cameraMuted) {
        return MutedVideoStream.instance.start()
      } else {
        return await this.getVideoInputDevice(this.selectedVideoInputDevice)
      }
    })()

    await this.audioVideo?.chooseVideoInputDevice(device)
    this.lastVideoDevice = device
  }

  private async getVideoInputDevice(device: Device): Promise<VideoInputDevice> {
    if (
      !isSupportedBackgroundEffect() ||
      !isSupportedLightAdjustment() ||
      device instanceof MediaProcessorVideoTransformDevice || // 二重にwrapしない
      !this.meetingSession
    ) {
      return device
    }
    if (this.backgroundEffect.type === 'no-effect' && this.lightAdjustment.type === 'no-effect') {
      return device
    }
    return new MediaProcessorVideoTransformDevice(device, this.backgroundEffect, this.lightAdjustment)
  }

  async changeBackgroundEffect(backgroundEffect: BackgroundEffect): Promise<void> {
    if (backgroundEffectDeepEqual(this.backgroundEffect, backgroundEffect)) {
      return
    }
    this.backgroundEffect = backgroundEffect
    await this.chooseVideoInputDeviceAsState()
    this.publishSelectedVideoInputDevice()
  }

  async changeLightAdjustment(lightAdjustment: LightAdjustment): Promise<void> {
    if (lightAdjustmentDeepEqual(this.lightAdjustment, lightAdjustment)) {
      return
    }
    this.lightAdjustment = lightAdjustment
    await this.chooseVideoInputDeviceAsState()
    this.publishSelectedVideoInputDevice()
  }

  async checkAndInitializeSupportedNoiseSuppression(): Promise<void> {
    if (!this.enabledNoiseSuppression) return
    if (this.isSupportedVoiceFocusNoiseSuppression !== null) return
    this.isSupportedVoiceFocusNoiseSuppression = false
    const paths: VoiceFocusPaths = {
      processors: `https://${ASSETS_HOST}/processors/`,
      wasm: `https://${ASSETS_HOST}/wasm/`,
      workers: `https://${ASSETS_HOST}/workers/`,
      models: `https://${ASSETS_HOST}/wasm/`,
    }
    if (!VoiceFocusDeviceTransformer.isSupported({ paths })) return
    // モデル複雑度を最低レベルのc10に設定
    // https://aws.github.io/amazon-chime-sdk-js/modules.html#voicefocusmodelcomplexity
    const voiceFocusDeviceTransformer = await VoiceFocusDeviceTransformer.create({ paths: paths, variant: 'c10' })
    if (!voiceFocusDeviceTransformer.isSupported()) return
    this.isSupportedVoiceFocusNoiseSuppression = true
    this.voiceFocusDeviceTransformer = voiceFocusDeviceTransformer
  }

  async chooseAudioInputDevice(deviceId: string): Promise<void> {
    await this.checkAndInitializeSupportedNoiseSuppression()
    let device: AudioInputDevice | undefined = deviceId
    try {
      if (this.enabledNoiseSuppression && this.isSupportedVoiceFocusNoiseSuppression) {
        assertIsDefined(this.voiceFocusDeviceTransformer)
        device = await this.voiceFocusDeviceTransformer.createTransformDevice(deviceId, {
          agc: { useBuiltInAGC: false, useVoiceFocusAGC: true, level: 1 },
        })
      }
    } finally {
      this.selectedAudioInputDevice = deviceId
      if (device) {
        await this.audioVideo?.chooseAudioInputDevice(device)
      }
      this.publishSelectedAudioInputDevice()
    }
  }

  async changeNoiseSuppression(enabledNoiseSuppression: boolean): Promise<void> {
    if (this.enabledNoiseSuppression === enabledNoiseSuppression) return
    this.enabledNoiseSuppression = enabledNoiseSuppression
    if (this.selectedAudioInputDevice) {
      await this.chooseAudioInputDevice(this.selectedAudioInputDevice)
    }
  }

  createLogger(configuration: MeetingSessionConfiguration): ConsoleLogger | MultiLogger {
    const consoleLogger = new ConsoleLogger('SDK', this.logLevel)
    let logger: ConsoleLogger | MultiLogger = consoleLogger

    if (this.postLoggerConfig) {
      logger = new MultiLogger(
        consoleLogger,
        new MeetingSessionPOSTLogger(
          this.postLoggerConfig.name,
          configuration,
          this.postLoggerConfig.batchSize,
          this.postLoggerConfig.intervalMs,
          this.postLoggerConfig.url,
          this.postLoggerConfig.logLevel
        )
      )
    }

    return logger
  }

  audioVideoDidStart = (): void => {
    this.meetingStatus = MeetingStatus.Succeeded
    this.publishMeetingStatus()
  }

  audioVideoDidStop = (sessionStatus: ImMeetingSessionStatus): void => {
    const sessionStatusCode = sessionStatus.statusCode()
    if (sessionStatusCode === ImMeetingSessionStatusCode.AudioCallEnded) {
      this.meetingStatus = MeetingStatus.Ended
      this.publishMeetingStatus(sessionStatus)
    } else if (sessionStatus.isFailure()) {
      this.meetingStatus = MeetingStatus.Failed
      this.publishMeetingStatus(sessionStatus)
    } else if (sessionStatusCode === ImMeetingSessionStatusCode.PreparedRestart) {
      this.meetingStatus = MeetingStatus.Loading
      this.publishMeetingStatus(sessionStatus)
      // no call leave
      return
    }
    this.leave()
  }

  setupAudioVideoObservers(): void {
    if (!this.audioVideo) {
      return
    }

    this.audioVideoObservers = {
      audioVideoDidStart: this.audioVideoDidStart,
      audioVideoDidStop: this.audioVideoDidStop,
    }

    this.audioVideo.addObserver(this.audioVideoObservers)
  }

  async updateDeviceLists(): Promise<void> {
    this.audioInputDevices = (await this.audioVideo?.listAudioInputDevices()) || []
    this.videoInputDevices = (await this.audioVideo?.listVideoInputDevices()) || []
    this.audioOutputDevices = (await this.audioVideo?.listAudioOutputDevices()) || []
  }

  setupDeviceLabelTrigger(): void {
    const callback = async (): Promise<MediaStream> => {
      this.devicePermissionStatus = DevicePermissionStatus.IN_PROGRESS
      this.publishDevicePermissionStatus()
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        })

        this.devicePermissionStatus = DevicePermissionStatus.GRANTED
        this.publishDevicePermissionStatus()
        return stream
      } catch (e) {
        let published = false
        try {
          await navigator.mediaDevices.getUserMedia({
            video: true,
          })
          this.devicePermissionStatus = DevicePermissionStatus.GRANTED_VIDEO_ONLY
          this.publishDevicePermissionStatus()
          published = true
        } catch (e) {
          // nop
        }

        if (!published) {
          try {
            await navigator.mediaDevices.getUserMedia({
              audio: true,
            })
            this.devicePermissionStatus = DevicePermissionStatus.GRANTED_AUDIO_ONLY
            this.publishDevicePermissionStatus()
            published = true
          } catch (e) {
            // nop
          }
        }

        if (!published) {
          this.devicePermissionStatus = DevicePermissionStatus.DENIED
          this.publishDevicePermissionStatus()
        }

        throw new Error(e)
      }
    }

    this.audioVideo?.setDeviceLabelTrigger(callback)
  }

  setupActiveSpeakerDetection(): void {
    this.publishActiveSpeaker()

    this.activeSpeakerListener = (activeSpeakers: string[]) => {
      this.activeSpeakers = activeSpeakers
      this.activeSpeakerCallbacks.forEach((cb) => cb(activeSpeakers))
    }

    this.audioVideo?.subscribeToActiveSpeakerDetector(new DefaultActiveSpeakerPolicy(), this.activeSpeakerListener)
  }

  async listAndSelectDevices(): Promise<void> {
    await this.updateDeviceLists()
    if (!this.unavailableAudioInput && !this.selectedAudioInputDevice && this.audioInputDevices) {
      const device = findCandidateDevice(this.audioInputDevices)
      if (device) {
        this.selectedAudioInputDevice = device.deviceId
        try {
          await this.chooseAudioInputDevice(this.selectedAudioInputDevice)
        } catch (e) {
          if (e instanceof GetUserMediaError) {
            throw new GetAudioInputMediaError(e)
          }
          throw e
        }
      }
    }
    if (!this.selectedAudioOutputDevice && this.audioOutputDevices) {
      const device = findCandidateDevice(this.audioOutputDevices)
      if (device) {
        this.selectedAudioOutputDevice = device.deviceId
        if (this.browserBehavior.supportsSetSinkId()) {
          await this.audioVideo?.chooseAudioOutputDevice(this.selectedAudioOutputDevice)
        }
        this.publishSelectedAudioOutputDevice()
      }
    }
    if (!this.unavailableVideoInput && !this.selectedVideoInputDevice && this.videoInputDevices) {
      const device = findCandidateDevice(this.videoInputDevices)
      if (device) {
        this.selectedVideoInputDevice = device.deviceId
        try {
          await this.chooseVideoInputDeviceAsState()
          this.publishSelectedVideoInputDevice()
        } catch (e) {
          if (e instanceof GetUserMediaError) {
            throw new GetVideoInputMediaError(e)
          }
          throw e
        }
      }
    }
  }

  selectAudioInputDevice = async (deviceId: string): Promise<void> => {
    if (deviceId === null) {
      await this.audioVideo?.chooseAudioInputDevice(null)
      this.selectedAudioInputDevice = null
      this.publishSelectedAudioInputDevice()
    } else {
      await this.chooseAudioInputDevice(deviceId)
    }
  }

  // ローカルストレージに一時保存されたdeviceIdから選択する
  // もし対応するデバイスが存在しない場合は選択しない
  selectStoredDevices = async (): Promise<void> => {
    await this.updateDeviceLists()
    const audioInput = getSelectedDeviceIdFromStorage('audioinput')
    const audioOutput = getSelectedDeviceIdFromStorage('audiooutput')
    const videoInput = getSelectedDeviceIdFromStorage('videoinput')
    const logger = this.configuration && this.createLogger(this.configuration)
    logger?.info(`videoInput: ${videoInput}`)
    logger?.info(`this.audioVideo: ${this.audioVideo}`)
    logger?.info(`this.videoInputDevices: ${this.videoInputDevices}`)

    if (!this.unavailableAudioInput && audioInput && this.audioInputDevices?.find((md) => md.deviceId === audioInput)) {
      try {
        await this.selectAudioInputDevice(audioInput)
      } catch (e) {
        if (e instanceof GetUserMediaError) {
          throw new GetAudioInputMediaError(e)
        }
        throw e
      }
    }
    if (audioOutput && this.audioOutputDevices?.find((md) => md.deviceId === audioOutput)) {
      await this.selectAudioOutputDevice(audioOutput)
    }
    if (!this.unavailableVideoInput && videoInput && this.videoInputDevices?.find((md) => md.deviceId === videoInput)) {
      try {
        await this.selectVideoInputDevice(videoInput)
      } catch (e) {
        if (e instanceof GetUserMediaError) {
          throw new GetVideoInputMediaError(e)
        }
        throw e
      }
    }
  }

  selectAudioOutputDevice = async (deviceId: string): Promise<void> => {
    await this.audioVideo?.chooseAudioOutputDevice(deviceId)
    this.selectedAudioOutputDevice = deviceId
    this.publishSelectedAudioOutputDevice()
  }

  setAudioOutputForVideoTile = async (deviceId: string, tileId: number): Promise<void> => {
    if (!this.browserBehavior.supportsSetSinkId() || !this.audioVideo) {
      return
    }
    const el = this.audioVideo.getVideoTile(tileId)?.state().boundVideoElement as
      | HTMLVideoElementWithSetSinkId
      | null
      | undefined
    if (!el || el.setSinkId === undefined) {
      return
    }
    await el.setSinkId(deviceId)
  }

  selectVideoInputDevice = async (deviceId: string): Promise<void> => {
    if (deviceId === null) {
      await this.audioVideo?.chooseVideoInputDevice(null)
      this.selectedVideoInputDevice = null
    } else {
      this.selectedVideoInputDevice = deviceId
      await this.chooseVideoInputDeviceAsState()
    }
    this.publishSelectedVideoInputDevice()
  }

  /**
   * 自分の映像を videoElement にバインドする
   * `audioVideo.bindVideoElement(localTileId, videoEl)` との違いは、以下の通り
   *   - `bindVideoElement` では、1つの localTileId は1つの videoElement にしか同時にバインドできない
   *       c.f.) https://github.com/aws/amazon-chime-sdk-js/issues/1082
   *   - `bindClonedLocalStreamToVideoElement` ではそのような制約がない
   * 例えば、自分の映像のプレビューをするための videoElement が欲しい場合などにこのメソッドが有用
   */
  bindClonedLocalStreamToVideoElement = (videoEl: HTMLVideoElement): void => {
    const localTile = this.audioVideo?.getLocalVideoTile()
    if (!localTile) {
      return
    }
    const stream = localTile.state().boundVideoStream
    if (!stream) {
      return
    }
    DefaultVideoTile.disconnectVideoStreamFromVideoElement(videoEl, false)
    DefaultVideoTile.connectVideoStreamToVideoElement(stream.clone(), videoEl, true)
  }

  setUserSendrecv = (userSendrecv: ConnectionPublisher): void => {
    if (!this.configuration) {
      return
    }
    this.configuration.soraConnectionPublisher = userSendrecv
  }

  // TODO: 微妙なAPI. reduxの状態整理が終わったら消す
  setContentSharePublisher = (publisher: ConnectionPublisher): void => {
    if (!this.contentShareConfiguration) {
      return
    }
    this.contentShareConfiguration.soraConnectionPublisher = publisher
  }

  async startRTCStatsLogging(params: RTCStatsLoggingParams): Promise<void> {
    if (!this.configuration?.soraConnectionPublisher) {
      throw new Error('undefined sora publisher')
    }
    // 多重で呼ばれたときのガード
    this.stopRTCStatsLogging()

    this._stopRTCStatsLogging = await startRTCStatsLogging(this.configuration.soraConnectionPublisher, params)
  }

  stopRTCStatsLogging(): void {
    if (this._stopRTCStatsLogging) {
      this._stopRTCStatsLogging()
    }
  }

  startTileAudioWaveAnalyser = (
    tileId: number,
    callback: (data: number[]) => void,
    option?: StartAnalyseOption
  ): string | null => {
    assertIsDefined(this.audioVideo)

    const tile = this.audioVideo.getVideoTile(tileId)
    if (!tile) {
      return null
    }
    const stream = tile.state().boundVideoStream
    if (!stream) {
      return null
    }
    if (stream.getAudioTracks().length === 0) {
      return null
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const contextClass: any =
      window.AudioContext ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any).webkitAudioContext as AudioContext)

    const context: AudioContext = new contextClass()

    // Safari 13.1でnullになることが観測されている
    if (!context) {
      return null
    }

    const al = context.createAnalyser()
    const source = context.createMediaStreamSource(stream)
    source.connect(al)

    const analyser = new AudioWaveAnalyser(al, () => {
      source.disconnect(al)
      context.close()
    })
    if (option?.fftSize) {
      al.fftSize = option.fftSize
    }

    this.analyserIdToAudioWaveAnalyser[analyser.id] = analyser
    if (option) {
      analyser.startAnalyse(callback, { interval: option.interval })
    }
    return analyser.id
  }

  stopTileAudioWaveAnalyser = (id: string) => {
    const analyer = this.analyserIdToAudioWaveAnalyser[id]
    if (!analyer) {
      return
    }
    analyer.cleanup()

    delete this.analyserIdToAudioWaveAnalyser[id]
  }

  startAudioInputAudioWaveAnalyser = (callback: (data: number[]) => void, option?: StartAnalyseOption) => {
    assertIsDefined(this.audioVideo)

    if (this.audioInputAudioWaveAnalyser) {
      this.stopAudioInputAudioWaveAnalyser()
    }
    const al = this.audioVideo.createAnalyserNodeForAudioInput()
    if (al === null) {
      return false
    }
    if (option?.fftSize) {
      al.fftSize = option.fftSize
    }
    const analyser = new AudioWaveAnalyser(al, () => {
      al.removeOriginalInputs()
    })
    if (option) {
      analyser.startAnalyse(callback, { interval: option.interval })
    }

    this.audioInputAudioWaveAnalyser = analyser

    return true
  }

  stopAudioInputAudioWaveAnalyser = () => {
    if (!this.audioInputAudioWaveAnalyser) return
    this.audioInputAudioWaveAnalyser.cleanup()
    this.audioInputAudioWaveAnalyser = undefined
  }

  /**
   * ====================================================================
   * Subscriptions
   * ====================================================================
   */

  subscribeToAudioVideo = (callback: (av: AudioVideoFacade | null) => void): void => {
    this.audioVideoCallbacks.push(callback)
  }

  unsubscribeFromAudioVideo = (callbackToRemove: (av: AudioVideoFacade | null) => void): void => {
    this.audioVideoCallbacks = this.audioVideoCallbacks.filter((callback) => callback !== callbackToRemove)
  }

  publishAudioVideo = (): void => {
    this.audioVideoCallbacks.forEach((callback) => {
      callback(this.audioVideo)
    })
  }

  subscribeToActiveSpeaker = (callback: (activeSpeakers: string[]) => void): void => {
    this.activeSpeakerCallbacks.push(callback)
    callback(this.activeSpeakers)
  }

  unsubscribeFromActiveSpeaker = (callbackToRemove: (activeSpeakers: string[]) => void): void => {
    this.activeSpeakerCallbacks = this.activeSpeakerCallbacks.filter((callback) => callback !== callbackToRemove)
  }

  publishActiveSpeaker = (): void => {
    this.activeSpeakerCallbacks.forEach((callback) => {
      callback(this.activeSpeakers)
    })
  }

  subscribeToDevicePermissionStatus = (callback: (permission: DevicePermissionStatus) => void): void => {
    this.devicePermissionsObservers.push(callback)
  }

  unsubscribeFromDevicePermissionStatus = (callbackToRemove: (permission: DevicePermissionStatus) => void): void => {
    this.devicePermissionsObservers = this.devicePermissionsObservers.filter(
      (callback) => callback !== callbackToRemove
    )
  }

  private publishDevicePermissionStatus = (): void => {
    this.devicePermissionsObservers.forEach((callback) => {
      callback(this.devicePermissionStatus)
    })
  }

  subscribeToSelectedVideoInputDevice = (callback: (deviceId: string | null) => void): void => {
    this.selectedVideoInputDeviceObservers.push(callback)
  }

  unsubscribeFromSelectedVideoInputDevice = (callbackToRemove: (deviceId: string | null) => void): void => {
    this.selectedVideoInputDeviceObservers = this.selectedVideoInputDeviceObservers.filter(
      (callback) => callback !== callbackToRemove
    )
  }

  private publishSelectedVideoInputDevice = (): void => {
    this.selectedVideoInputDeviceObservers.forEach((callback) => {
      callback(this.selectedVideoInputDevice)
    })
  }

  subscribeToSelectedAudioInputDevice = (callback: (deviceId: string | null) => void): void => {
    this.selectedAudioInputDeviceObservers.push(callback)
  }

  unsubscribeFromSelectedAudioInputDevice = (callbackToRemove: (deviceId: string | null) => void): void => {
    this.selectedAudioInputDeviceObservers = this.selectedAudioInputDeviceObservers.filter(
      (callback) => callback !== callbackToRemove
    )
  }

  private publishSelectedAudioInputDevice = (): void => {
    this.selectedAudioInputDeviceObservers.forEach((callback) => {
      callback(this.selectedAudioInputDevice)
    })
  }

  subscribeToSelectedAudioOutputDevice = (callback: (deviceId: string | null) => void): void => {
    this.selectedAudioOutputDeviceObservers.push(callback)
  }

  unsubscribeFromSelectedAudioOutputDevice = (callbackToRemove: (deviceId: string | null) => void): void => {
    this.selectedAudioOutputDeviceObservers = this.selectedAudioOutputDeviceObservers.filter(
      (callback) => callback !== callbackToRemove
    )
  }

  private publishSelectedAudioOutputDevice = (): void => {
    this.selectedAudioOutputDeviceObservers.forEach((callback) => {
      callback(this.selectedAudioOutputDevice)
    })
  }

  subscribeToMeetingStatus = (callback: MeetingStatusObserver): void => {
    this.meetingStatusObservers.push(callback)
  }

  unsubscribeFromMeetingStatus = (callbackToRemove: MeetingStatusObserver): void => {
    this.meetingStatusObservers = this.meetingStatusObservers.filter((callback) => callback !== callbackToRemove)
  }

  private publishMeetingStatus = (meetingSessionStatus?: ImMeetingSessionStatus) => {
    this.meetingStatusObservers.forEach((callback) => {
      callback(this.meetingStatus, meetingSessionStatus ?? new ImMeetingSessionStatus(ImMeetingSessionStatusCode.OK))
    })
  }
}

function backgroundEffectDeepEqual(a: BackgroundEffect, b: BackgroundEffect): boolean {
  if (a.type !== b.type) return false
  switch (a.type) {
    case 'no-effect': {
      return true
    }
    case 'blur': {
      if (b.type !== 'blur') {
        throw new Error('never')
      }
      return a.blurSize === b.blurSize
    }
    case 'replace': {
      if (b.type !== 'replace') {
        throw new Error('never')
      }
      return a.image === b.image
    }
  }
}

function lightAdjustmentDeepEqual(a: LightAdjustment, b: LightAdjustment): boolean {
  if (a.type !== b.type) return false
  switch (a.type) {
    case 'no-effect': {
      return true
    }
    case 'adjustment': {
      if (b.type !== 'adjustment') {
        throw new Error('never')
      }
      return a.strength === b.strength
    }
  }
}
