// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import {
  DefaultDevicePixelRatioMonitor,
  Logger,
  Maybe,
  VideoTile,
  VideoTileState,
  VideoTileFactory,
  VideoTileController,
  DevicePixelRatioWindowSource,
} from 'amazon-chime-sdk-js'
import { AudioVideoController, AudioVideoObserver } from '..'
import { assertIsDefined } from '../../assertions'

export class ImVideoTileController implements VideoTileController {
  private tileMap = new Map<number, VideoTile>()
  private nextTileId = 1
  private currentLocalTile: VideoTile | null = null
  private devicePixelRatioMonitor: undefined | DefaultDevicePixelRatioMonitor
  private currentPausedTilesByIds: Set<number> = new Set<number>()

  constructor(
    private tileFactory: VideoTileFactory,
    private audioVideoController: AudioVideoController,
    private logger: Logger
  ) {}

  private createDevicePixelRatioMonitorIfNeeded(): void {
    if (this.devicePixelRatioMonitor) {
      return
    }
    this.devicePixelRatioMonitor = new DefaultDevicePixelRatioMonitor(new DevicePixelRatioWindowSource(), this.logger)
  }

  private async discardDevicePixelRatioMonitorIfNotNeeded(): Promise<void> {
    if (this.tileMap.size || !this.devicePixelRatioMonitor) {
      return
    }
    const monitor = this.devicePixelRatioMonitor
    this.devicePixelRatioMonitor = undefined
    return monitor.destroy()
  }

  bindVideoElement(tileId: number, videoElement: HTMLVideoElement | null): void {
    const tile = this.getVideoTile(tileId)
    if (tile === null) {
      this.logger.warn(`Ignoring video element binding for unknown tile id ${tileId}`)
      return
    }
    tile.bindVideoElement(videoElement)
  }

  unbindVideoElement(tileId: number): void {
    this.bindVideoElement(tileId, null)
  }

  setupLocalVideoTile(): number {
    const tile = this.findOrCreateLocalVideoTile()
    assertIsDefined(this.currentLocalTile)
    this.currentLocalTile.stateRef().localTileStarted = true
    return tile.id()
  }

  startLocalVideoTile(): number {
    const tile = this.findOrCreateLocalVideoTile()
    assertIsDefined(this.currentLocalTile)
    this.currentLocalTile.stateRef().localTileStarted = true
    this.audioVideoController.update()
    return tile.id()
  }

  stopLocalVideoTile(): void {
    if (!this.currentLocalTile) {
      return
    }
    const attendeeId = this.audioVideoController?.configuration?.credentials?.attendeeId
    assertIsDefined(attendeeId)
    this.currentLocalTile.stateRef().localTileStarted = false
    this.currentLocalTile.bindVideoStream(attendeeId, true, null, null, null, null)
    this.audioVideoController.update()
  }

  hasStartedLocalVideoTile(): boolean {
    return !!(this.currentLocalTile && this.currentLocalTile.stateRef().localTileStarted)
  }

  removeLocalVideoTile(): void {
    if (this.currentLocalTile) {
      this.removeVideoTile(this.currentLocalTile.id())
    }
  }

  getLocalVideoTile(): VideoTile | null {
    return this.currentLocalTile
  }

  pauseVideoTile(tileId: number): void {
    const tile = this.getVideoTile(tileId)
    if (tile) {
      if (!this.currentPausedTilesByIds.has(tileId)) {
        const streamId = tile.stateRef().streamId
        assertIsDefined(streamId)
        this.audioVideoController.pauseReceivingStream(streamId)
        this.currentPausedTilesByIds.add(tileId)
      }
      tile.pause()
    }
  }

  unpauseVideoTile(tileId: number): void {
    const tile = this.getVideoTile(tileId)
    if (tile) {
      if (this.currentPausedTilesByIds.has(tileId)) {
        const streamId = tile.stateRef().streamId
        assertIsDefined(streamId)
        this.audioVideoController.resumeReceivingStream(streamId)
        this.currentPausedTilesByIds.delete(tileId)
      }
      tile.unpause()
    }
  }

  getVideoTile(tileId: number): VideoTile | null {
    if (!this.tileMap.has(tileId)) {
      return null
    }
    const tile = this.tileMap.get(tileId)
    assertIsDefined(tile)
    return tile
  }

  getVideoTileArea(tile: VideoTile): number {
    const state = tile.state()
    let tileHeight = 0
    let tileWidth = 0
    if (state.boundVideoElement) {
      tileHeight = state.boundVideoElement.clientHeight * state.devicePixelRatio
      tileWidth = state.boundVideoElement.clientWidth * state.devicePixelRatio
    }
    return tileHeight * tileWidth
  }

  getAllRemoteVideoTiles(): VideoTile[] {
    const result = new Array<VideoTile>()
    this.tileMap.forEach((tile: VideoTile, tileId: number): void => {
      if (!this.currentLocalTile || tileId !== this.currentLocalTile.id()) {
        result.push(tile)
      }
    })
    return result
  }

  getAllVideoTiles(): VideoTile[] {
    return Array.from(this.tileMap.values())
  }

  addVideoTile(localTile = false): VideoTile {
    const tileId = this.nextTileId
    this.nextTileId += 1
    this.createDevicePixelRatioMonitorIfNeeded()
    assertIsDefined(this.devicePixelRatioMonitor)
    const tile = this.tileFactory.makeTile(tileId, localTile, this, this.devicePixelRatioMonitor)
    this.tileMap.set(tileId, tile)
    return tile
  }

  removeVideoTile(tileId: number): void {
    if (!this.tileMap.has(tileId)) {
      return
    }
    const tile = this.tileMap.get(tileId)
    assertIsDefined(tile)
    if (this.currentLocalTile === tile) {
      this.currentLocalTile = null
    }
    tile.destroy()
    this.tileMap.delete(tileId)
    this.audioVideoController.forEachObserver((observer: AudioVideoObserver) => {
      Maybe.of(observer.videoTileWasRemoved).map((f) => {
        if (!f) return null
        return f.bind(observer)(tileId)
      })
    })
    this.discardDevicePixelRatioMonitorIfNotNeeded()
  }

  removeVideoTilesByAttendeeId(attendeeId: string): number[] {
    const tilesRemoved: number[] = []
    for (const tile of this.getAllVideoTiles()) {
      const state = tile.state()
      assertIsDefined(state.tileId)
      if (state.boundAttendeeId === attendeeId) {
        this.removeVideoTile(state.tileId)
        tilesRemoved.push(state.tileId)
      }
    }
    return tilesRemoved
  }

  removeAllVideoTiles(): void {
    const tileIds = Array.from(this.tileMap.keys())
    for (const tileId of tileIds) {
      this.removeVideoTile(tileId)
    }
  }

  sendTileStateUpdate(tileState: VideoTileState): void {
    this.audioVideoController.forEachObserver((observer: AudioVideoObserver) => {
      Maybe.of(observer.videoTileDidUpdate).map((f) => {
        if (!f) return null
        return f.bind(observer)(tileState)
      })
    })
  }

  haveVideoTilesWithStreams(): boolean {
    for (const tile of this.getAllVideoTiles()) {
      if (tile.state().boundVideoStream) {
        return true
      }
    }
    return false
  }

  haveVideoTileForAttendeeId(attendeeId: string): boolean {
    for (const tile of this.getAllVideoTiles()) {
      const state = tile.state()
      if (state.boundAttendeeId === attendeeId) {
        return true
      }
    }
    return false
  }

  captureVideoTile(tileId: number): ImageData | null {
    const tile = this.getVideoTile(tileId)
    if (!tile) {
      return null
    }
    return tile.capture()
  }

  private findOrCreateLocalVideoTile(): VideoTile {
    if (this.currentLocalTile) {
      return this.currentLocalTile
    }
    const attendeeId = this.audioVideoController?.configuration?.credentials?.attendeeId
    assertIsDefined(attendeeId)
    this.currentLocalTile = this.addVideoTile(true)
    this.currentLocalTile.bindVideoStream(attendeeId, true, null, null, null, null)
    return this.currentLocalTile
  }
}
