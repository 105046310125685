import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { SharedState } from '..'

export const isInterviewReadySelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.userSignalingStatus,
  (state: SharedState) => state.shared.interviewState,
  (userSignalingStatus, interviewState) => {
    return userSignalingStatus === 'Completed' && interviewState === 'InRunning'
  }
)
