import { useEffect, useMemo } from 'react'
import { useUserAgent } from '@blue-agency/react-utils'
import { alertToast, toast } from '@blue-agency/rogue'
import { findCandidateDevice } from '@/lib/interview-sdk-js'
import { AudioOutputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { isPcByUA } from '@/shared/utils/isPcByUA'

export const useSpeakerUnpluged = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioOutputs, selectedAudioOutputDevice } = AudioOutputContainer.useContainer()

  const ua = useUserAgent()
  const isPc = useMemo(() => isPcByUA(ua), [ua])

  useEffect(() => {
    // デバイス変更がない環境では無視
    if (!isPc) return

    // 選択中のデバイスが抜かれたときの対処
    if (!audioOutputs || audioOutputs.length === 0) return
    if (!selectedAudioOutputDevice) return

    if (audioOutputs.some((d) => d.deviceId === selectedAudioOutputDevice)) return

    const device = findCandidateDevice(audioOutputs)
    if (device) {
      meetingManager.selectAudioOutputDevice(device.deviceId)
      toast(`スピーカーを ${device.label} に切り替えました`)
    } else {
      alertToast('スピーカーデバイスが見つかりませんでした')
    }
  }, [isPc, meetingManager, audioOutputs, selectedAudioOutputDevice])
}
