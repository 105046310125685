import { Icon as _Icon, WifiIcon as _WifiIcon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

const Icon = styled(_Icon)`
  color: ${theme.color.white[1]};
`

export const StartIcon = styled(Icon).attrs({ name: 'start' })`
  width: 58px;
  height: 58px;
`
export const CannotRestartIcon = styled(Icon).attrs({ name: 'cannot-restart' })`
  color: ${theme.color.navy[1]};
  width: 78px;
  height: 78px;
`
export const ChatIcon = styled(Icon).attrs({ name: 'chat' })`
  width: 50px;
  height: 50px;
`
export const ReloadIcon = styled(Icon).attrs({ name: 'reload' })`
  width: 40px;
  height: 40px;
`
export const CloseBrowserIcon = styled(Icon).attrs({ name: 'close-browser' })`
  width: 40px;
  height: 40px;
`
export const EarphoneIcon = styled(Icon).attrs({ name: 'earphone' })`
  width: 50px;
  height: 50px;
`
export const WifiIcon = styled(_WifiIcon)`
  color: ${theme.color.white[1]};
  width: 44px;
  height: 44px;
`
