import { useContext, useState, useCallback, useMemo } from 'react'
import { theme, Icon, useIsIPad } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled, { ThemeContext } from 'styled-components'
import { MainVideoContainer } from '@/lib/meetingcomponent/MainVideoContainer'
import type { TileId } from '@/shared/services/interviewService/types'

type Props = {
  tileId: number | null
  icon: 'pin' | 'unpin'

  // このオーバーレイの対象が以下のどれか
  // 1. メインエリア (面接ビュー)
  // 2. サブエリア (面接ビュー)
  // 3. タイル (タイルビュー)
  target: 'main' | 'sub' | 'tile'
}

export function OverlayPin(props: Props) {
  const { togglePinState, mainVideoContentTileId } = MainVideoContainer.useContainer()
  const { responsive } = useContext(ThemeContext)
  const { isIPad } = useIsIPad()
  const consumerProps = {
    ...props,
    isPC: responsive.pc,
    togglePinState,
    mainVideoContentTileId,
    isIPad: isIPad,
  }

  return <OverlayPinConsumer {...consumerProps} />
}

type ConsumerProps = Props & {
  isPC: boolean
  togglePinState: (tileId: TileId | null) => void
  mainVideoContentTileId: TileId | null
  isIPad: boolean
}

function OverlayPinConsumer({
  tileId,
  icon,
  isPC,
  isIPad,
  togglePinState,
  mainVideoContentTileId,
  target,
}: ConsumerProps) {
  const [showOverlay, setShowOverlay] = useState(false)
  const forSubVideoArea = target === 'sub'
  const thisTileIsPinned = tileId === mainVideoContentTileId
  const showPinnedIcon = forSubVideoArea && !showOverlay && thisTileIsPinned

  const arrowPosition = forSubVideoArea ? 'topRight' : 'bottomRight'

  const dataFor = `toggle-pin-${tileId}`

  const tooltipMessage = useMemo(() => {
    if (icon === 'pin') {
      return forSubVideoArea ? (
        <>
          この映像をメイン
          <br />
          画面に固定します
        </>
      ) : (
        <>この映像をメイン画面に固定します</>
      )
    } else {
      return forSubVideoArea ? (
        <>
          メインエリアの映像の
          <br />
          固定を解除します
        </>
      ) : (
        <>メインエリアの映像の固定を解除します</>
      )
    }
  }, [icon, forSubVideoArea])

  const onClick = useCallback(() => {
    // スマホビューのときは面接ビューしか存在しないので、メインエリアがタップされてもピン外しをしない
    if (!isPC && target === 'main') {
      return
    }

    // 同様に、スマホビューのときは、サブエリアのピン留めされている映像がタップされてもピン外しをしない
    if (!isPC && forSubVideoArea && thisTileIsPinned) {
      return
    }

    togglePinState(tileId)
  }, [tileId, togglePinState, forSubVideoArea, thisTileIsPinned, isPC, target])

  const onMouseMove = useCallback(() => {
    // PCのときだけピンオーバーレイを表示する
    if (isPC && !isIPad) {
      setShowOverlay(true)
    }
  }, [isPC, isIPad])

  const onMouseLeave = useCallback(() => {
    setShowOverlay(false)
  }, [])

  return (
    <>
      {showPinnedIcon && <PinnedIcon />}
      <OverlayWrapper onClick={onClick} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}>
        {showOverlay && (
          <>
            <Overlay data-tip data-for={dataFor}>
              <OverlayIcon name={icon} />
            </Overlay>
            <Tooltip id={dataFor} arrowPosition={arrowPosition}>
              {tooltipMessage}
            </Tooltip>
          </>
        )}
      </OverlayWrapper>
    </>
  )
}

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Overlay = styled.span`
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  background-color: ${theme.color.white[1]};
  opacity: 0.8;
  width: 52px;
  height: 42px;
`

type OverlayIconProps = {
  name: 'pin' | 'unpin'
}

const OverlayIcon = styled(Icon).attrs<OverlayIconProps>((props) => ({
  name: props.name,
  size: 'l',
}))`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const PinnedIcon = styled(Icon).attrs(({ theme: { responsive } }) => ({
  name: 'pin',
  size: responsive.pc ? 'm' : 's',
}))`
  position: absolute;
  top: 20%;
  left: 2.68%;
  transform: translateY(-50%);
  color: ${theme.color.white[1]};
`
