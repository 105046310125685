import React from 'react'
import { theme, Icon } from '@blue-agency/rogue'
import { Switch, Case } from 'react-if'
import { useMeasure } from 'react-use'
import styled, { css } from 'styled-components'
import type { Warning } from '../hooksForApp/useUnstableConnectionWarning'

type BasePosition = { top: number; left: number; width: number; height: number }
type Size = { width: number; height: number }

type Props = {
  warning: Warning
  basePosition: BasePosition | undefined
  onClose: () => void
}

export const UnstableConnectionWarningBalloon: React.VFC<Props> = ({ warning, basePosition, onClose }) => {
  const [selfRef, selfPosition] = useMeasure<HTMLDivElement>()
  if (warning === undefined || basePosition === undefined) return null
  return (
    <Wrapper ref={selfRef} basePosition={basePosition} selfSize={selfPosition}>
      <Balloon>
        <Row>
          <CautionIcon />
          <Switch>
            <Case condition={warning === 'cpu'}>
              <Line>
                <Strong>デバイスに高い負荷がかかっています</Strong>
              </Line>
            </Case>
            <Case condition={warning === 'network'}>
              <Line>
                <Strong>ネットワークが不安定になっています</Strong>
              </Line>
            </Case>
          </Switch>
        </Row>
        <Row>
          <Line>問題がある場合はこちらから</Line>
        </Row>
        <Row>
          <Line>
            <Strong>トラブルシューティング</Strong>を参照してください。
          </Line>
        </Row>
        <CloseIcon onClick={onClose} />
      </Balloon>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  basePosition: BasePosition
  selfSize: Size | undefined
}>`
  position: fixed;
  // MenuDropdownより下に表示
  // ref. src/shared/services/interviewService/components/PcControls.tsx
  z-index: 98;

  ${({ basePosition, selfSize: balloonSize, theme: { responsive } }) => {
    if (!balloonSize) return
    if (responsive.pc) {
      // 下にあるcontrolsに少しborderが被った状態。leftは中央合わせ。
      return css`
        top: ${basePosition.top - balloonSize.height}px;
        left: ${basePosition.left + basePosition.width / 2 - balloonSize.width / 2}px;
      `
    }
    if (responsive.spLandscape) {
      // 右にあるcontrolsに少しborderが被った状態。bottomは少し浮かせた状態
      return css`
        bottom: 6px;
        right: ${basePosition.width - 6}px;
      `
    }
    if (responsive.spPortrait) {
      // 下にあるcontrolsに少しborderが被った状態。leftは中央合わせ。
      return css`
        top: ${basePosition.top - balloonSize.height}px;
        right: 0;
      `
    }
  }}
`

const Balloon = styled.div`
  position: relative;
  display: inline-block;

  background-color: white;
  box-shadow: 0px 4px 4px rgba(155, 146, 146, 0.2);
  color: ${theme.color.red[2]};
  text-align: left;
  padding: 6px 31px 7px 12px;
  border: 2px solid ${theme.color.red[2]};
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;

  ${({ theme: { responsive } }) => {
    if (responsive.pc) {
      return css`
        &:before {
          content: '';
          position: absolute;
          bottom: -23px;
          left: 50%;
          margin-left: -15px;
          border: 12px solid transparent;
          border-top: 12px solid ${theme.color.white[1]};
          z-index: 2;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -28px;
          left: 50%;
          margin-left: -17px;
          border: 14px solid transparent;
          border-top: 14px solid ${theme.color.red[2]};
          z-index: 1;
        }
      `
    }
    if (responsive.spLandscape) {
      return css`
        &:before {
          content: '';
          position: absolute;
          top: 70%;
          right: -24px;
          margin-top: -12px;
          border: 12px solid transparent;
          border-left: 12px solid ${theme.color.white[1]};
          z-index: 2;
        }

        &:after {
          content: '';
          position: absolute;
          top: 70%;
          right: -29px;
          margin-top: -14px;
          border: 14px solid transparent;
          border-left: 14px solid ${theme.color.red[2]};
          z-index: 1;
        }
      `
    }
    if (responsive.spPortrait) {
      return css`
        &:before {
          content: '';
          position: absolute;
          bottom: -23px;
          right: 15px;
          margin-left: -15px;
          border: 12px solid transparent;
          border-top: 12px solid ${theme.color.white[1]};
          z-index: 2;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -28px;
          right: 13px;
          margin-left: -17px;
          border: 14px solid transparent;
          border-top: 14px solid ${theme.color.red[2]};
          z-index: 1;
        }
      `
    }
  }}
`

const Row = styled.div`
  display: flex;
  align-items: center;
`
const Line = styled.p`
  display: inline-block;
`

const Strong = styled.span`
  font-weight: 800;
`

const CautionIcon = styled(Icon).attrs({ name: 'caution' })`
  margin-right: 6px;
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`
