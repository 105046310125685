import React from 'react'
import styled from 'styled-components'

export type Ratio = '16x9' | '26x9'
type Props = {
  ratio: Ratio
}

export const AspectBox: React.FC<Props> = (props) => {
  return (
    <AspectRatioWrapper ratio={props.ratio}>
      <AspectRatioInner>{props.children}</AspectRatioInner>
    </AspectRatioWrapper>
  )
}

// AspectRatioの指定を表現
// 参考： https://qiita.com/ryounagaoka/items/a98f59347ed758743b8d
const AspectRatioWrapper = styled.div<{ ratio: Ratio }>`
  position: relative;
  width: 100%;

  &:before {
    content: '';
    display: block;
    padding-top: ${({ ratio }) => (ratio === '16x9' ? '56.25%' : '34.62%')};
  }
`

const AspectRatioInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
