import { useApproveEntryMessageHandler } from './useApproveEntryMessageHandler'
import { useFailToEnterMessageHandler } from './useFailToEnterMessageHandler'
import { useListPendingEntryRequestsWhenEntered } from './useListPendingEntryRequestsWhenEntered'
import { useRejectEntryMessageHandler } from './useRejectEntryMessageHandler'

export function useEntryRequest() {
  useApproveEntryMessageHandler()
  useRejectEntryMessageHandler()
  useFailToEnterMessageHandler()
  useListPendingEntryRequestsWhenEntered()
}
