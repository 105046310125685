import { useCallback, useState, useEffect } from 'react'
import * as Sentry from '@sentry/react'
import { useRequestGetSpecialSurveyUrl } from '@/my/services/bffService'

export function useGetSpecialSurveyUrl(interviewGuid: string) {
  const { requestGetSpecialSurveyUrl } = useRequestGetSpecialSurveyUrl()
  const [existSpecialSurveyUrl, setExistSpecialSurveyUrl] = useState<boolean>(false)
  const [specialSurveyUrl, setSpecialSurveyUrl] = useState<string>()
  const handleGetSurveyUrl = useCallback(async () => {
    try {
      const res = await requestGetSpecialSurveyUrl(interviewGuid)
      setExistSpecialSurveyUrl(res.getExist())
      // https://faq.form.run/formtab#5e045ec61a85b85bb9eaaf65-06974dd3810c3dc4d35332ab
      // bffからは?_field_数字=がついた状態のURLを受け取る
      setSpecialSurveyUrl(res.getSurveyUrl() + interviewGuid)
    } catch (e) {
      Sentry.captureException(e)
      return
    }
  }, [interviewGuid, requestGetSpecialSurveyUrl])

  useEffect(() => {
    handleGetSurveyUrl()
  }, [handleGetSurveyUrl])

  return {
    existSpecialSurveyUrl,
    specialSurveyUrl,
  }
}
