import { useEffect, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useSelector } from 'react-redux'
import { AudioVideoObserver } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { isLocalVideoEnabledSelector } from '@/shared/services/interviewService/redux'

const useLocalVideo = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const isVideoEnabled = useSelector(isLocalVideoEnabledSelector)
  const [tileId, setTileId] = useState<number | null>(null)

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const observer: AudioVideoObserver = {
      videoTileDidUpdate: (tileState) => {
        if (!tileState.localTile || !tileState.tileId || tileId === tileState.tileId) {
          return
        }

        setTileId(tileState.tileId)
      },
    }
    audioVideo.addObserver(observer)

    return () => {
      audioVideo.removeObserver(observer)
    }
  }, [audioVideo, tileId])

  return {
    isVideoEnabled,
    tileId,
    setTileId,
  }
}

export const LocalVideoContainer = createContainer(useLocalVideo)
