import React, { useMemo, useEffect, useRef } from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  width: string
  height: string
  audioStream?: MediaStream
  waveBarLength?: number
  baseBarHeight?: number
  maxBarHeight?: number
  barWidth?: number
  barColor?: string
  muted: boolean
}

export const AudioWaveBar: React.VFC<Props> = (props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const canvasAudioWaveRef = useRef<CanvasAudioWaveBar | null>(null)

  const analyser = useMemo(() => {
    const context = new (window.AudioContext ||
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any).webkitAudioContext as AudioContext))()
    const analyser = context.createAnalyser()
    if (props.audioStream) {
      const source = context.createMediaStreamSource(props.audioStream)
      source.connect(analyser)
    }
    return analyser
  }, [props.audioStream])

  useEffect(() => {
    return () => {
      analyser.disconnect()
    }
  }, [analyser])

  useEffect(() => {
    if (!canvasRef.current) {
      return
    }

    canvasAudioWaveRef.current = new CanvasAudioWaveBar(
      analyser,
      canvasRef.current,
      props.waveBarLength ?? 3,
      props.baseBarHeight ?? 3,
      props.maxBarHeight ?? 16,
      props.barWidth ?? 3,
      props.barColor ?? theme.color.white[1],
      3,
      4.5
    )

    if (props.muted) {
      canvasAudioWaveRef.current.drawMute()
    } else {
      canvasAudioWaveRef.current.draw()
    }

    return () => {
      if (canvasAudioWaveRef.current) {
        canvasAudioWaveRef.current.cleanup()
        canvasAudioWaveRef.current = null
      }
    }
  }, [props, analyser])

  return <Canvas ref={canvasRef} width={props.width} height={props.height} />
}

const Canvas = styled.canvas`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`

export class CanvasAudioWaveBar {
  private rafId: number | null = null
  private analyser: AnalyserNode
  private fftSize: number = 512
  private minFrequency: number = 300
  private maxFrequency: number = 1200

  constructor(
    analyser: AnalyserNode,
    private canvas: HTMLCanvasElement | null,
    private waveBarLength: number,
    private baseBarHeight: number,
    private maxBarHeight: number,
    private barWidth: number,
    private barColor: string,
    private barPadding: number,
    private startPosition: number
  ) {
    analyser.fftSize = this.fftSize
    this.analyser = analyser
  }

  draw() {
    if (this.rafId || !this.analyser || !this.fftSize || !this.canvas) {
      return
    }

    const dpr = window.devicePixelRatio || 1
    this.canvas.width = this.canvas.offsetWidth * dpr
    this.canvas.height = this.canvas.offsetHeight * dpr
    const ctx = this.canvas.getContext('2d')
    if (!ctx) {
      return
    }
    ctx.save()
    ctx.scale(dpr, dpr)

    const step = () => {
      if (!this.analyser || !this.canvas) {
        return
      }
      if (!ctx) {
        return
      }
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)
      const frequencyResolution = this.analyser.context.sampleRate / this.analyser.fftSize
      // minFrequencyくらいの周波数に対応するindex
      const from = Math.floor(this.minFrequency / frequencyResolution)
      // maxFrequencyくらいの周波数に対応するindex
      const to = Math.ceil(this.maxFrequency / frequencyResolution)
      const offset = Math.floor((to - from + 1) / this.waveBarLength)
      const getBarHeight = (waveBarIndex: number, freqData: Uint8Array) => {
        if (offset < 1) {
          return this.baseBarHeight + (freqData[from + waveBarIndex]! / 255) * (this.maxBarHeight - this.baseBarHeight)
        }
        const freqDataStartIndex = from + waveBarIndex * offset
        const freqDataEndIndex = from + ((waveBarIndex + 1) * offset - 1)
        let max: number = 0
        for (let i = freqDataStartIndex; i <= freqDataEndIndex; i++) {
          max = Math.max(max, freqData[i]!)
        }
        return this.baseBarHeight + (max / 255) * (this.maxBarHeight - this.baseBarHeight)
      }

      const freqData = new Uint8Array(this.analyser.frequencyBinCount)
      this.analyser.getByteFrequencyData(freqData)
      const barHeights = [...Array(this.waveBarLength)].map((_, i) => getBarHeight(i, freqData))

      for (let i = 0; i < barHeights.length; i++) {
        this.drawBar(
          ctx,
          i * (this.barWidth + this.barPadding) + this.startPosition,
          barHeights[i] ?? this.baseBarHeight
        )
      }
      this.rafId = requestAnimationFrame(step)
    }

    this.rafId = requestAnimationFrame(step)
  }

  drawMute() {
    if (!this.canvas) return

    const dpr = window.devicePixelRatio || 1
    this.canvas.width = this.canvas.offsetWidth * dpr
    this.canvas.height = this.canvas.offsetHeight * dpr
    const ctx = this.canvas.getContext('2d')

    if (!ctx) return
    ctx.save()
    ctx.scale(dpr, dpr)
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height)

    for (let i = 0; i < this.waveBarLength; i++) {
      this.drawBar(ctx, i * (this.barWidth + this.barPadding) + this.startPosition, this.baseBarHeight)
    }
  }

  private drawBar(ctx: CanvasRenderingContext2D, x: number, h: number) {
    const w = this.barWidth
    const y0 = ctx.canvas.offsetHeight / 2
    const radius = w / 2
    ctx.beginPath()
    ctx.strokeStyle = this.barColor
    ctx.lineWidth = w
    ctx.lineCap = 'round'
    // 直線の始端・終端に円弧がつくので、その分の高さを引いて円弧も含めて指定した高さになるようにする
    ctx.moveTo(x + w / 2, y0 - h / 2 + radius)
    ctx.lineTo(x + w / 2, y0 + h / 2 - radius)
    ctx.stroke()
  }

  cleanup() {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId)
      this.rafId = null
    }

    this.canvas?.getContext('2d')?.restore()
    this.canvas = null
  }
}
