// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import {
  AsyncScheduler,
  AudioProfile,
  ContentShareConstants,
  ContentShareMediaStreamBroker,
  Destroyable,
  EventAttributes,
  EventName,
  Maybe,
  MeetingSessionConfiguration,
  MeetingSessionCredentials,
  VideoTile,
} from 'amazon-chime-sdk-js'
import { AudioVideoController, ImMeetingSessionStatus, ContentShareObserver, AudioVideoObserver } from '..'
import { ContentShareController } from './ContentShareController'

export class ImContentShareController implements ContentShareController, AudioVideoObserver, Destroyable {
  static createContentShareMeetingSessionConfigure(
    configuration: MeetingSessionConfiguration
  ): MeetingSessionConfiguration {
    const contentShareConfiguration = new MeetingSessionConfiguration()
    contentShareConfiguration.meetingId = configuration.meetingId
    contentShareConfiguration.externalMeetingId = configuration.externalMeetingId
    contentShareConfiguration.urls = configuration.urls
    contentShareConfiguration.credentials = new MeetingSessionCredentials()
    contentShareConfiguration.credentials.attendeeId =
      configuration.credentials?.attendeeId + ContentShareConstants.Modality
    if (contentShareConfiguration.credentials) {
      contentShareConfiguration.credentials.externalUserId = configuration.credentials?.externalUserId ?? null
    }
    contentShareConfiguration.credentials.joinToken =
      configuration.credentials?.joinToken + ContentShareConstants.Modality
    return contentShareConfiguration
  }

  private observerQueue: Set<ContentShareObserver> = new Set<ContentShareObserver>()
  private contentShareTile: VideoTile | null = null
  destroyed = false

  constructor(
    private mediaStreamBroker: ContentShareMediaStreamBroker,
    private contentAudioVideo: AudioVideoController,
    private attendeeAudioVideo: AudioVideoController
  ) {
    this.contentAudioVideo.addObserver(this)
  }

  setContentAudioProfile(audioProfile: AudioProfile): void {
    this.contentAudioVideo.setAudioProfile(audioProfile)
  }

  async startContentShare(stream: MediaStream): Promise<void> {
    if (!stream) {
      return
    }
    this.mediaStreamBroker.mediaStream = stream
    for (let i = 0; i < this.mediaStreamBroker.mediaStream.getTracks().length; i++) {
      const tracks = this.mediaStreamBroker?.mediaStream?.getTracks()
      tracks[i]?.addEventListener('ended', () => {
        this.stopContentShare()
      })
    }
    this.contentAudioVideo.start()
    if (this.mediaStreamBroker.mediaStream.getVideoTracks().length > 0) {
      this.contentAudioVideo.videoTileController.startLocalVideoTile()
    }
  }

  async startContentShareFromScreenCapture(sourceId?: string, frameRate?: number): Promise<MediaStream> {
    const mediaStream = await this.mediaStreamBroker.acquireScreenCaptureDisplayInputStream(sourceId, frameRate)
    await this.startContentShare(mediaStream)
    return mediaStream
  }

  pauseContentShare(): void {
    if (this.mediaStreamBroker.toggleMediaStream(false)) {
      this.forEachContentShareObserver((observer) => {
        Maybe.of(observer.contentShareDidPause).map((f) => f?.call(observer))
      })
    }
  }

  unpauseContentShare(): void {
    if (this.mediaStreamBroker.toggleMediaStream(true)) {
      this.forEachContentShareObserver((observer) => {
        Maybe.of(observer.contentShareDidUnpause).map((f) => f?.call(observer))
      })
    }
  }

  async destroy(): Promise<void> {
    // Idempotency.
    /* istanbul ignore if */
    if (!this.contentAudioVideo) {
      return
    }
    this.destroyed = true
    this.contentAudioVideo.removeObserver(this)
    this.stopContentShare()
    this.observerQueue.clear()
  }

  stopContentShare(): void {
    this.contentAudioVideo.stop()
    this.mediaStreamBroker.cleanup()
  }

  addContentShareObserver(observer: ContentShareObserver): void {
    this.observerQueue.add(observer)
  }

  removeContentShareObserver(observer: ContentShareObserver): void {
    this.observerQueue.delete(observer)
  }

  forEachContentShareObserver(observerFunc: (observer: ContentShareObserver) => void): void {
    for (const observer of this.observerQueue) {
      AsyncScheduler.nextTick(() => {
        if (this.observerQueue.has(observer)) {
          observerFunc(observer)
        }
      })
    }
  }

  audioVideoDidStart(): void {
    this.forEachContentShareObserver((observer) => {
      Maybe.of(observer.contentShareDidStart).map((f) => f?.call(observer))
    })
  }

  audioVideoDidStop(sessionStatus: ImMeetingSessionStatus): void {
    // If the content attendee got dropped or could not connect, stopContentShare will not be called
    // so make sure to clean up the media stream.
    this.mediaStreamBroker.cleanup()
    if (this.contentShareTile) {
      this.attendeeAudioVideo.videoTileController.removeVideoTile(this.contentShareTile.id())
      this.contentShareTile = null
    }
    this.forEachContentShareObserver((observer) => {
      Maybe.of(observer.contentShareDidStop).map((f) => f?.call(observer, sessionStatus))
    })
  }

  eventDidReceive?(name: EventName, attributes: EventAttributes): void {
    this.forEachContentShareObserver((observer) => {
      Maybe.of(observer.contentShareEventDidReceive).map((f) => f?.call(observer, name, attributes))
    })
  }
}
