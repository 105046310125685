import { useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { InterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { sharedSlice } from '@/shared/services/interviewService/redux'

// 面接に面接ガイドが紐付いている場合に
//   - 面接ガイドをオープンにする
//   - 面接ガイドが紐付いていることを示す真偽値を true にセットする
//
// 面接データの取得が完了した時に1回限り実行する
export const useOpenInterviewGuideAreaOnMount = () => {
  const dispatch = useDispatch()

  const done = useRef(false)

  const interviewPage = InterviewPageContainer.useContainer()

  useEffect(() => {
    if (done.current) {
      return
    }

    if (interviewPage.isValid !== true) {
      return
    }

    if (interviewPage.guide !== undefined) {
      dispatch(sharedSlice.actions.openInterviewGuideArea())
      dispatch(sharedSlice.actions.hasInterviewGuide())
    }

    done.current = true
  }, [interviewPage, dispatch])
}
