import throttle from 'just-throttle'
import { v4 as uuidv4 } from 'uuid'

export interface StartAnalyseOption {
  interval?: number
}

export class AudioWaveAnalyser {
  private rafId: number | null = null
  private minFrequency = 300
  private maxFrequency = 1200
  private analyser: AnalyserNode
  private callerCleanup: () => void
  private _id = uuidv4()
  private stopped = false

  constructor(analyser: AnalyserNode, cleanup: () => void) {
    this.analyser = analyser
    this.callerCleanup = cleanup
  }

  get id() {
    return this._id
  }

  startAnalyse(cb: (data: number[]) => void, option?: StartAnalyseOption) {
    if (this.rafId || !this.analyser) {
      return
    }

    const calcBase = () => {
      if (!this.analyser) {
        return
      }

      const frequencyResolution = this.analyser.context.sampleRate / this.analyser.fftSize
      // minFrequencyくらいの周波数に対応するindex
      const from = Math.floor(this.minFrequency / frequencyResolution)
      // maxFrequencyくらいの周波数に対応するindex
      const to = Math.ceil(this.maxFrequency / frequencyResolution)

      const freqData = new Uint8Array(this.analyser.frequencyBinCount)
      this.analyser.getByteFrequencyData(freqData)

      const data = Array.from(freqData.slice(from, to + 1))
      cb(data)
    }

    const calc = option?.interval ? throttle(calcBase, option?.interval, { trailing: true }) : calcBase

    const step = () => {
      calc()
      if (this.stopped) return
      this.rafId = requestAnimationFrame(step)
    }

    this.rafId = requestAnimationFrame(step)
  }

  cleanup() {
    this.stopped = true
    if (this.rafId) {
      cancelAnimationFrame(this.rafId)
      this.rafId = null
    }
    this.callerCleanup()
  }
}
