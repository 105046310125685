import React, { useCallback, useMemo, useState } from 'react'
import { CustomGrpcError } from '@blue-agency/im-shared-front'
import { alertToast, theme, Txt, WeakColorSeparator, Icon } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { useRequestApproveEntry, useRequestRejectEntry } from '@/biz/services/bffService'
import { bizSlice } from '../redux/bizSlice'
import { RootState } from '../redux/store'
import { InRoomContainer } from './containers/InRoomContainer'

type Props = PropsWithClassName

export const PendingEntryRequestsComponent = (props: Props): React.ReactElement | null => {
  const { interviewGuid } = InRoomContainer.useContainer()

  const dispatch = useDispatch()
  const processingEntryRequest = useSelector((state: RootState) => state.biz.processingEntryRequest)

  const pendingEntryRequests = useSelector((state: RootState) => state.biz.pendingEntryRequests)
  const pendingEntryRequest = pendingEntryRequests[0]

  const { requestApproveEntry } = useRequestApproveEntry()
  const { requestRejectEntry } = useRequestRejectEntry()

  const handleApproveClick = useCallback(async () => {
    if (!pendingEntryRequest) throw new Error('unexpected error')
    dispatch(bizSlice.actions.entryRequestApproved())
    const { entryRequestGuid } = pendingEntryRequest
    try {
      await requestApproveEntry(interviewGuid, entryRequestGuid)
    } catch (e) {
      dispatch(bizSlice.actions.processEntryRequestError())
      if (e instanceof CustomGrpcError) {
        if (!e.isInvalidArgument) {
          alertToast('リクエストの承認に失敗しました。\n再度承認してください。')
        }
      }
    }
  }, [dispatch, pendingEntryRequest, requestApproveEntry, interviewGuid])

  const handleRejectClick = useCallback(async () => {
    if (!pendingEntryRequest) throw new Error('unexpected error')
    dispatch(bizSlice.actions.entryRequestRejected())
    const { entryRequestGuid } = pendingEntryRequest
    try {
      await requestRejectEntry(interviewGuid, entryRequestGuid)
    } catch (e) {
      dispatch(bizSlice.actions.processEntryRequestError())
      if (e instanceof CustomGrpcError) {
        if (!e.isInvalidArgument) {
          alertToast('リクエストの拒否に失敗しました。\n再度拒否してください。')
        }
      }
    }
  }, [dispatch, interviewGuid, pendingEntryRequest, requestRejectEntry])

  const [toggleOtherReqOpen, setToggleOtherReqOpen] = useState<boolean>(false)
  const otherRequests = useMemo(
    () => [...pendingEntryRequests].splice(1, pendingEntryRequests.length),
    [pendingEntryRequests]
  )
  const otherRequestsNum = useMemo(() => otherRequests.length, [otherRequests])

  const isShowOtherRequests = useMemo(
    () => !!toggleOtherReqOpen && !!otherRequests,
    [toggleOtherReqOpen, otherRequests]
  )

  if (!pendingEntryRequest) {
    return null
  }

  return (
    <Wrapper className={props.className}>
      <Header>
        <Txt color={theme.color.white[1]} size="l">
          参加リクエスト
        </Txt>
      </Header>
      <Body>
        {otherRequestsNum ? (
          <>
            <Txt>
              <Name>{pendingEntryRequest.name}</Name>さん、
            </Txt>
            <Txt size="s">
              {`他${otherRequestsNum}名`}
              から参加リクエストがあります。
            </Txt>
          </>
        ) : (
          <Txt size="s">
            <Name>{pendingEntryRequest.name}</Name>さんから参加リクエストがあります。
          </Txt>
        )}
        {!!otherRequestsNum && (
          <ToggleButton onClick={() => setToggleOtherReqOpen(!toggleOtherReqOpen)}>
            <Txt size="s" color={theme.color.blue[2]}>
              他
            </Txt>
            <Txt size="l" color={theme.color.blue[2]}>
              {otherRequestsNum}
            </Txt>
            <Txt size="s" color={theme.color.blue[2]}>
              名を{toggleOtherReqOpen ? '非表示' : '表示'}
            </Txt>
            <Icon name={toggleOtherReqOpen ? 'arrow-up' : 'arrow-down'} size="m" />
          </ToggleButton>
        )}
        {isShowOtherRequests && (
          <StyledUl>
            {otherRequests?.map((request, index) => (
              <StyledLi key={index}>
                <div>{request.name}</div>
              </StyledLi>
            ))}
          </StyledUl>
        )}
      </Body>
      <WeakColorSeparator />
      <ButtonGroup>
        <StyledTertiaryButton
          onClick={handleRejectClick}
          comlinkPushParams={{ action: 'click_reject_entry_request' }}
          widthSize="L1"
          loading={processingEntryRequest}
        >
          <NameWrap>
            <ButtonInnerName>{pendingEntryRequest.name}</ButtonInnerName>
            <Txt size="s">さんを</Txt>
          </NameWrap>
          拒否
        </StyledTertiaryButton>
        <StyledPrimaryButton
          onClick={handleApproveClick}
          comlinkPushParams={{ action: 'click_approve_entry_request' }}
          widthSize="L1"
          loading={processingEntryRequest}
        >
          <NameWrap>
            <ButtonInnerName color={theme.color.white[1]}>{pendingEntryRequest.name}</ButtonInnerName>
            <Txt size="s" color={theme.color.white[1]}>
              さんを
            </Txt>
          </NameWrap>
          承認
        </StyledPrimaryButton>
      </ButtonGroup>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.white[1]};
  border: 2px solid ${theme.color.navy[1]};
  border-radius: 4px;
`

const Header = styled.div`
  background-color: ${theme.color.navy[1]};
  padding: 8px 20px;
`

const Body = styled.div`
  padding: 20px 12px;
`
const ButtonGroup = styled.div`
  display: flex;
  padding: 20px;

  > *:not(:first-child) {
    margin-left: 20px;
  }
`

const StyledTertiaryButton = styled(TertiaryButton)`
  padding: 8px 0;
  height: auto;
  font-size: ${theme.fontSize.m};
`

const StyledPrimaryButton = styled(PrimaryButton)`
  padding: 8px 0;
  height: auto;
`

const NameWrap = styled.div`
  display: flex;
  align-items: center;
`
const ButtonInnerName = styled(Txt).attrs({ size: 's' })`
  max-width: 88px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const Name = styled.span`
  font-weight: bold;
  font-size: ${theme.fontSize.m};
`

const ToggleButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  color: ${theme.color.blue[2]};
  svg {
    margin-left: 4px;
  }
  cursor: pointer;
`

const StyledUl = styled.ul`
  padding: 0 12px;
  margin-top: 8px;
`

const StyledLi = styled.li`
  font-size: ${theme.fontSize.m};
  margin-bottom: 8px;
  div {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
