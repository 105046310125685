import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetInterviewQualityRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetInterviewQuality() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetInterviewQuality = useCallback(
    async (interviewGuid: string) => {
      const metadata = await buildMetadata()

      const req = new GetInterviewQualityRequest()

      req.setInterviewGuid(interviewGuid)

      return callBff('getInterviewQuality', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestGetInterviewQuality }
}
