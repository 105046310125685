import { useEffect } from 'react'
import { useComlinkPushWithStaffGuid } from '@blue-agency/im-shared-front'
import { alertToast } from '@blue-agency/rogue'
import { useDispatch, useSelector } from 'react-redux'
import { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { RootState } from '../../../redux/store'
import { InRoomContainer } from '../../containers/InRoomContainer'

export function useFailToEnterMessageHandler() {
  const dispatch = useDispatch()
  const comlinkPushWithStaffGuid = useComlinkPushWithStaffGuid()

  const consumedEntryRequests = useSelector((state: RootState) => state.biz.consumedEntryRequests)

  const { ws } = InRoomContainer.useContainer()
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    const handler = (message: WebSocketMessageMap['fail to enter']) => {
      const target = consumedEntryRequests.find((r) => r.entryRequestGuid === message.entry_request_guid)
      if (!target) {
        comlinkPushWithStaffGuid('participant who failed to enter not found')
        return
      }
      alertToast(`参加者人数の上限を超えているため、${target.name}さんは、面接に参加できません。`)
    }

    addMessageListener<'fail to enter'>('fail to enter', handler)

    return () => {
      removeMessageListener<'fail to enter'>('fail to enter', handler)
    }
  }, [addMessageListener, comlinkPushWithStaffGuid, consumedEntryRequests, dispatch, removeMessageListener])
}
