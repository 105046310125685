// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { BrowserBehavior, Logger, VideoStreamIdSet, VideoStreamIndex, TransceiverController } from 'amazon-chime-sdk-js'
import { noop } from '../util'

export class SoraTransceiverController implements TransceiverController {
  protected _localCameraTransceiver: RTCRtpTransceiver | null = null
  protected _localAudioTransceiver: RTCRtpTransceiver | null = null
  protected videoSubscriptions: number[] = []
  protected defaultMediaStream: MediaStream | null = null
  protected peer: RTCPeerConnection | null = null

  constructor(protected logger: Logger, protected browserBehavior: BrowserBehavior) {}

  setEncodingParameters(_params: Map<string, RTCRtpEncodingParameters>): void {
    noop(_params)
  }

  static async setVideoSendingBitrateKbpsForSender(
    sender: RTCRtpSender,
    bitrateKbps: number,
    _logger: Logger
  ): Promise<void> {
    noop(_logger)
    if (!sender || bitrateKbps <= 0) {
      return
    }
    const param: RTCRtpSendParameters = sender.getParameters() as RTCRtpSendParameters
    if (!param.encodings) {
      param.encodings = []
    }
    for (const encodeParam of param.encodings) {
      encodeParam.maxBitrate = bitrateKbps * 1000
    }
    await sender.setParameters(param)
  }

  static async replaceAudioTrackForSender(sender: RTCRtpSender, track: MediaStreamTrack): Promise<boolean> {
    if (!sender) {
      return false
    }

    await sender.replaceTrack(track)
    return true
  }

  localAudioTransceiver(): RTCRtpTransceiver {
    if (!this._localAudioTransceiver) {
      throw new Error('localAudioTransceiver is not set')
    }
    return this._localAudioTransceiver
  }

  localVideoTransceiver(): RTCRtpTransceiver {
    if (!this._localCameraTransceiver) {
      throw new Error('localVideoTransceiver is not set')
    }
    return this._localCameraTransceiver
  }

  async setVideoSendingBitrateKbps(bitrateKbps: number): Promise<void> {
    // this won't set bandwidth limitation for video in Chrome
    if (!this._localCameraTransceiver || this._localCameraTransceiver.direction !== 'sendrecv') {
      return
    }
    const sender: RTCRtpSender = this._localCameraTransceiver.sender
    await SoraTransceiverController.setVideoSendingBitrateKbpsForSender(sender, bitrateKbps, this.logger)
  }

  setPeer(peer: RTCPeerConnection): void {
    this.peer = peer

    const senders = this.peer.getSenders()
    this.peer.getTransceivers().forEach((transceiver) => {
      if (!senders.includes(transceiver.sender)) return

      if (!transceiver.sender.track) return

      if (transceiver.sender.track.kind === 'audio' && !this._localAudioTransceiver) {
        this._localAudioTransceiver = transceiver
      }
      if (transceiver.sender.track.kind === 'video' && !this._localCameraTransceiver) {
        this._localCameraTransceiver = transceiver
      }
    })
  }

  setDefaultMediaStream(stream: MediaStream): void {
    this.defaultMediaStream = stream
  }

  reset(): void {
    this._localCameraTransceiver = null
    this._localAudioTransceiver = null
    this.videoSubscriptions = []
    this.defaultMediaStream = null
    this.peer = null
  }

  useTransceivers(): boolean {
    if (!this.peer || !this.browserBehavior.requiresUnifiedPlan()) {
      return false
    }

    return typeof this.peer.getTransceivers !== 'undefined'
  }

  hasVideoInput(): boolean {
    if (!this._localCameraTransceiver || this._localCameraTransceiver.direction !== 'sendrecv') return false

    return true
  }

  trackIsVideoInput(track: MediaStreamTrack): boolean {
    if (!this._localCameraTransceiver) {
      return false
    }
    return track === this._localCameraTransceiver.sender.track || track === this._localCameraTransceiver.receiver.track
  }

  setupLocalTransceivers(): void {
    return
  }

  async replaceAudioTrack(track: MediaStreamTrack): Promise<boolean> {
    if (!this._localAudioTransceiver || this._localAudioTransceiver.direction !== 'sendrecv') {
      this.logger.info('audio transceiver direction is not set up or not activated')
      return false
    }
    await this._localAudioTransceiver.sender.replaceTrack(track)
    return true
  }

  async setAudioInput(track: MediaStreamTrack | null): Promise<void> {
    await this.setTransceiverInput(this._localAudioTransceiver, track)
    return
  }

  async setVideoInput(track: MediaStreamTrack | null): Promise<void> {
    await this.setTransceiverInput(this._localCameraTransceiver, track)
    return
  }

  updateVideoTransceivers(videoStreamIndex: VideoStreamIndex, videosToReceive: VideoStreamIdSet): number[] {
    // RTPTransceiverの追加・directionの変更はsora-js-sdkでおこなわれるので、amazon-chime-sdk-jsでは特になにもしない
    return videosToReceive.array()
  }

  private transceiverIsVideo(transceiver: RTCRtpTransceiver): boolean {
    return (
      ((transceiver.receiver && transceiver.receiver.track && transceiver.receiver.track.kind === 'video') ||
        (transceiver.sender && transceiver.sender.track && transceiver.sender.track.kind === 'video')) ??
      false
    )
  }

  private debugDumpTransceivers(): string {
    let msg = ''
    let n = 0

    const transceivers = this.peer?.getTransceivers()
    if (transceivers) {
      for (const transceiver of transceivers) {
        if (!this.transceiverIsVideo(transceiver)) {
          continue
        }
        msg += `transceiver index=${n} mid=${transceiver.mid} subscription=${this.videoSubscriptions[n]} direction=${transceiver.direction}\n`
        n += 1
      }
    }
    return msg
  }

  private async setTransceiverInput(
    transceiver: RTCRtpTransceiver | null,
    track: MediaStreamTrack | null
  ): Promise<void> {
    if (!transceiver) {
      return
    }

    if (track) {
      transceiver.direction = 'sendrecv'
    } else {
      transceiver.direction = 'inactive'
    }

    await transceiver.sender.replaceTrack(track)
  }
}
