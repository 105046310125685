import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { theme } from '@blue-agency/rogue'
import { GlobalHeaderHelp } from '@/my/components/GlobalHeaderHelp'
import { PageLayout } from '@/shared/components/PageLayout'
import { StepContainer } from './containers/useStep'

export const Preparing = provide(StepContainer)((): React.ReactElement => {
  const { content } = StepContainer.useContainer()

  return (
    <PageLayout
      backgroundColor={theme.color.gray[5]}
      globalHeader={{
        rightNode: <GlobalHeaderHelp />,
        disableLogoLink: true,
      }}
    >
      {content}
    </PageLayout>
  )
})
