import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { assertIsDefined, assertNever } from '@/assertions'
import type { VideoTileState, AudioVideoFacade, AudioVideoObserver } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import {
  ownParticipantSelector,
  participantsOfOtherRoleSelector,
  participantsOfSameRoleSelector,
  participantsSelector,
  sharedSlice,
} from '@/shared/services/interviewService/redux'
import type { SharedState } from '@/shared/services/interviewService/redux'
import type { Participant, TileId } from '@/shared/services/interviewService/types'
import { isRecruiter, isScreenShare } from '@/shared/services/interviewService/types'

const MAIN_TILE_ID = 'main'

const useMainVideo = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const ownParticipant = useSelector(ownParticipantSelector)
  const participants = useSelector(participantsSelector, shallowEqual)
  const viewMode = useSelector((state: SharedState) => state.shared.viewMode)
  const dispatch = useDispatch()

  // MainVideoとして表示するTile
  // 弱いピン留め、強いピン留めのいずれの場合もこれが使用される
  // ピン留め・ピン外しのときには、このタイルが内部で持っているstreamが差し替わるということ
  const mainVideoTileId = useMainVideoTile()

  // 弱いピン留めのときにMainVideoとして表示される映像の"実体"を保持しているTileのID
  // 実体のことを content tile と呼んでいる
  const contentTileIdWhenWeakPinned = useContentForWeakPinned()

  // ピン留め状態を加味した content tile ID
  const contentTileId = useMemo(() => {
    switch (viewMode.type) {
      case 'strong_pinned':
        return viewMode.tileId
      case 'weak_pinned':
        return contentTileIdWhenWeakPinned
      case 'tile_view':
        // ピン留めは面接ビュー時の概念であるが、例えばPCでタイルビュー状態からウィンドウの幅を狭めるとモバイルビューに切り替わる
        // このときはstateとしては 'tile_view' だが、見た目上は面接ビュー、ということになる
        // このようなケースでMainVideoをとりあえず表示するため、'weak_pinned' の場合と同じ tileId を返す
        return contentTileIdWhenWeakPinned
      default:
        assertNever(viewMode)
    }
  }, [viewMode, contentTileIdWhenWeakPinned])

  useRebindTile(audioVideo, mainVideoTileId, contentTileId)

  // MainVideoの表示名および面接官か否か
  const { name, attendeeId, targetIsRecruiter, targetIsScreenShare } = useMemo(() => {
    const DEFAULT = {
      name: undefined,
      targetIsRecruiter: false,
      attendeeId: undefined,
      targetIsScreenShare: false,
    }

    if (!audioVideo || contentTileId === null) {
      return DEFAULT
    }

    const localTileId = audioVideo.getLocalVideoTile()?.state().tileId
    if (contentTileId === localTileId) {
      if (ownParticipant === undefined) {
        return DEFAULT
      }
      return {
        name: ownParticipant.name,
        attendeeId: ownParticipant.soraClientId,
        targetIsRecruiter: isRecruiter(ownParticipant.role),
        targetIsScreenShare: isScreenShare(ownParticipant.role),
      }
    }

    const attendeeId = audioVideo.getVideoTile(contentTileId)?.state().boundAttendeeId
    if (!attendeeId) {
      return DEFAULT
    }
    const target = participants.find((p) => p.soraClientId === attendeeId)
    if (target === undefined) {
      return DEFAULT
    }
    return {
      name: target.name,
      attendeeId: target.soraClientId,
      targetIsRecruiter: isRecruiter(target.role),
      targetIsScreenShare: isScreenShare(target.role),
    }
  }, [audioVideo, contentTileId, participants, ownParticipant])

  // ビデオタイル状態に変化(e.g. 退室)があったときの処理を行うobserverを設定
  useObserver(mainVideoTileId, contentTileId)

  const togglePinState = useCallback(
    (tileId: TileId | null) => {
      if (!tileId) return
      dispatch(sharedSlice.actions.toggleVideoPinState({ tileId, mainVideoContentTileId: contentTileId ?? undefined }))
    },
    [dispatch, contentTileId]
  )

  return {
    mainVideoContentTileId: contentTileId,
    mainVideoTileId,
    name,
    attendeeId: attendeeId,
    isRecruiter: targetIsRecruiter,
    isScreenShare: targetIsScreenShare,
    togglePinState,
  }
}

export const MainVideoContainer = createContainer(useMainVideo)

/**
 * 弱いピン留めのときにMainVideoとして表示するコンテンツを求め、その映像のtileIdを返す
 * また、その tileId を redux state に保存する
 * 処理ロジック: https://stadium.kibe.la/notes/10462
 */
function useContentForWeakPinned(): TileId | null {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const otherParticipantsOfSameRole = useSelector(participantsOfSameRoleSelector, shallowEqual)
  const otherParticipantsOfOtherRole = useSelector(participantsOfOtherRoleSelector, shallowEqual)

  const selfTile = audioVideo?.getLocalVideoTile()
  const selfBoundStream = selfTile?.state().boundVideoStream
  const selfTileId = selfTile?.id()

  const tileId = useMemo(() => {
    if (!audioVideo) {
      return null
    }

    const attendeeIdToTileId: Record<string, TileId> = Object.fromEntries(
      audioVideo
        .getAllRemoteVideoTiles()
        .filter((t) => t.state().boundAttendeeId && t.state().boundAttendeeId !== MAIN_TILE_ID)
        .map((t) => [t.state().boundAttendeeId, t.id()])
    )

    const isActiveParticipant = (p: Participant) => !!attendeeIdToTileId[p.soraClientId]

    const findFrom = otherParticipantsOfOtherRole.concat(otherParticipantsOfSameRole)

    const contentShareCandidate = findFrom.find((p) => isScreenShare(p.role) && isActiveParticipant(p))
    if (contentShareCandidate !== undefined) {
      const t = attendeeIdToTileId[contentShareCandidate.soraClientId]
      assertIsDefined(t)
      return t
    }

    const participantCandidate = findFrom.find(isActiveParticipant)
    if (participantCandidate !== undefined) {
      const t = attendeeIdToTileId[participantCandidate.soraClientId]
      assertIsDefined(t)
      return t
    }

    // selfTile (自分自身の映像) の準備ができているとき
    // selfTile が生成されるタイミング (= `selfTileId` に値が入るタイミング) と、
    // selfTile に stream が bind されるタイミングが異なるため、これらを両方ともチェックする必要がある
    if (selfBoundStream != null && selfTileId !== undefined) {
      return selfTileId
    }

    // selfTile がまだ準備できていないとき
    return null
  }, [audioVideo, otherParticipantsOfOtherRole, otherParticipantsOfSameRole, selfTileId, selfBoundStream])

  return tileId
}

/**
 * destTileId の video stream と tileIdToBeSet の video stream を比較し、
 * 異なっている場合、destTile の video stream を更新する
 */
function useRebindTile(audioVideo: AudioVideoFacade | null, destTileId: TileId | null, tileIdToBeSet: TileId | null) {
  useEffect(() => {
    if (audioVideo === null || destTileId === null || tileIdToBeSet === null) {
      return
    }

    const tileState = audioVideo.getVideoTile(tileIdToBeSet)?.state()
    if (tileState === undefined) {
      return
    }

    const destTile = audioVideo.getVideoTile(destTileId)
    if (destTile === null) {
      return
    }

    if (tileState.boundVideoStream && destTile.state().boundVideoStream !== tileState.boundVideoStream) {
      destTile.bindVideoStream(
        MAIN_TILE_ID,
        false,
        tileState.boundVideoStream,
        tileState.videoStreamContentWidth,
        tileState.videoStreamContentHeight,
        null
      )
    }
  }, [audioVideo, destTileId, tileIdToBeSet])
}

/*
 * ビデオタイル状態に変化(e.g. 退室、カメラミュート切り替え)があったときの処理を行うobserverを設定
 */
function useObserver(mainVideoTileId: TileId | null, contentTileId: TileId | null) {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const observer = useRef<AudioVideoObserver | null>(null)

  // 画面共有が開始されたときにピン留めを行う処理はここではなく
  // `connectionCreatedNotificationReceived` 内にある
  const videoTileDidUpdate = useCallback(
    (tileState: VideoTileState) => {
      if (mainVideoTileId === null || audioVideo === null) {
        return
      }

      if (tileState.boundAttendeeId === MAIN_TILE_ID) {
        return
      }

      const mainVideoTile = audioVideo.getVideoTile(mainVideoTileId)

      if (mainVideoTile === null) {
        return
      }

      // ピンされているタイルにstreamの変更やカメラミュート状態の変更などがあったら、メインビデオにも反映させる
      if (contentTileId === tileState.tileId) {
        if (mainVideoTile.state().boundVideoStream !== tileState.boundVideoStream) {
          mainVideoTile.bindVideoStream(
            MAIN_TILE_ID,
            false,
            tileState.boundVideoStream,
            tileState.videoStreamContentWidth,
            tileState.videoStreamContentHeight,
            null
          )
        }
      }
    },
    [audioVideo, contentTileId, mainVideoTileId]
  )

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    // 前回追加したobserverを消す
    if (observer.current !== null) {
      audioVideo.removeObserver(observer.current)
    }

    const nextObserver: AudioVideoObserver = {
      videoTileDidUpdate,
    }
    audioVideo.addObserver(nextObserver)
    observer.current = nextObserver

    return () => {
      if (observer.current !== null) {
        audioVideo?.removeObserver(observer.current)
      }
    }
  }, [audioVideo, videoTileDidUpdate])
}

/*
 * MainVideo用のTileを作成し、そのTileIdを返す
 * もしすでに作成済みのものがあったら、それを再利用する
 *
 * NOTE1: AudioVideoFacade の準備ができるまでは null が返される
 * NOTE2: 通常モード <-> 軽量モードの切り替え時にいったんすべてのタイルが破棄されるので、そのタイミングで再生成する
 */
function useMainVideoTile(): TileId | null {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const [mainVideoTileId, setMainVideoTileId] = useState<TileId | null>(null)

  useEffect(() => {
    if (audioVideo === null) {
      return
    }

    if (mainVideoTileId === null) {
      const newTile = audioVideo.addVideoTile()
      newTile.stateRef().boundAttendeeId = MAIN_TILE_ID
      setMainVideoTileId(newTile.id())
    }
  }, [audioVideo, mainVideoTileId])

  useEffect(() => {
    if (audioVideo === null) {
      return
    }

    // 削除されたタイルがMainVideo用のものだった場合、再生成する
    const videoTileWasRemoved = (tileId: TileId) => {
      if (tileId === mainVideoTileId) {
        const newTile = audioVideo.addVideoTile()
        newTile.stateRef().boundAttendeeId = MAIN_TILE_ID
        setMainVideoTileId(newTile.id())
      }
    }

    audioVideo.addObserver({ videoTileWasRemoved })

    return () => audioVideo.removeObserver({ videoTileWasRemoved })
  }, [audioVideo, mainVideoTileId])

  return mainVideoTileId
}
