import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { InterviewState } from '@/shared/services/interviewService'
import { sharedSlice } from '@/shared/services/interviewService/redux'
import { OwnState } from '@/shared/services/interviewService/types'

type State = {
  interviewState: InterviewState
  ownState: OwnState
  entryRequestGuid?: string
  invitationToken?: string
  ownName?: string
}

const initialState: State = {
  interviewState: 'NotStarted',
  ownState: 'BeforeEntered',
  entryRequestGuid: undefined,
  invitationToken: undefined,
  ownName: undefined,
}

export const mySlice = createSlice({
  name: 'my',
  initialState,
  reducers: {
    entryRequested: (
      state,
      { payload }: PayloadAction<{ entryRequestGuid: string; invitationToken: string; ownName: string }>
    ) => {
      state.entryRequestGuid = payload.entryRequestGuid
      state.invitationToken = payload.invitationToken
      state.ownName = payload.ownName
    },
    // NOTE: リセットしておかないとrejectedになったあとに再度リクエストボタンを押した際に前のguid, tokenでポーリングのリクエストが走ってしまう
    entryRequestRejected: (state) => {
      state.entryRequestGuid = undefined
      state.invitationToken = undefined
    },
    entryRequestApproved: (state) => {
      state.ownState = 'InRoom'
    },
    finishMessageReceived: (state) => {
      state.interviewState = 'Finished'
    },
    leavingInterviewRequested: (state) => {
      state.ownState = 'Leaved'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sharedSlice.actions.startMessageReceived, (state) => {
      state.interviewState = 'InRunning'
    })
  },
})
