import styled, { css } from 'styled-components'
import { Layout } from '@/shared/services/interviewService/containers/VideosLayoutContainer'

export const VideoWrapper = styled.div<{ layout: Layout }>`
  ${({ layout }) => {
    switch (layout) {
      case 'single':
        return css`
          width: 100%;
          margin-bottom: 8px;
        `
      case 'single_screen':
        return css`
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          margin-bottom: 8px;
        `
      case 'double':
        return css`
          width: calc(50% - 4px / 2);
          margin-bottom: 4px;
          &:nth-child(odd) {
            margin-right: 2px;
          }
          &:nth-child(even) {
            margin-left: 2px;
          }
        `
    }
  }}
`
