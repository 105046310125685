import React from 'react'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { Loading } from '@/shared/components/Loading'
import { checkBrowser } from '@/shared/services/browserCheckService'
import { useEnvironmentCheck } from '../hooks/useEnvironmentCheck'
import { Finished } from './Finished'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName

export const EnvironmentCheck: React.VFC<Props> = (props) => {
  const { isFinished, handleFinished } = StepContainer.useContainer()
  const { cameraTestConnectionProps } = useEnvironmentCheck()
  if (!cameraTestConnectionProps) return <Loading />
  return (
    <Wrapper className={props.className}>
      {isFinished ? (
        <Finished />
      ) : (
        <EnvironmentCheckComponent
          userType="interviewee"
          steps={[
            { name: 'recommendForParticipant' },
            { name: 'recommendForAll' },
            {
              name: 'browser',
              helpUrl: MY_EXTERNAL_URLS.help.environment.os,
              showRecommendPc: true,
              checkBrowserFunction: checkBrowser,
            },
            {
              name: 'camera',
              helpUrl: MY_EXTERNAL_URLS.help.environment.cameraAndMic,
              ...cameraTestConnectionProps,
            },
            {
              name: 'mic',
              helpUrl: MY_EXTERNAL_URLS.help.environment.cameraAndMic,
            },
            {
              name: 'speaker',
              helpUrl: MY_EXTERNAL_URLS.help.environment.speaker,
            },
            {
              name: 'speed',
              helpUrl: MY_EXTERNAL_URLS.help.environment.speed,
            },
          ]}
          onFinish={handleFinished}
        />
      )}
    </Wrapper>
  )
}

// TODO: 一時しのぎ
const headerHeight__temporaryValue = 32

const Wrapper = styled.div`
  min-height: calc(100vh - ${headerHeight__temporaryValue}px);
`
