// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { BaseTask, RemovableObserver, TaskCanceler } from 'amazon-chime-sdk-js'
import { ImMeetingSessionStatusCode } from '..'
import { ImAudioVideoControllerState } from '../audiovideocontroller/ImAudioVideoControllerState'

export class OpenRecvOnlySoraConnectionTask extends BaseTask implements RemovableObserver {
  protected taskName = 'OpenRecvOnlySoraConnectionTask'

  private removeTrackAddedEventListener: (() => void) | null = null
  private removeTrackRemovedEventListeners: { [trackId: string]: () => void } = {}

  private taskCanceler: TaskCanceler | null = null

  constructor(private context: ImAudioVideoControllerState) {
    super(context.logger)
  }

  removeObserver(): void {
    this.removeTrackAddedEventListener && this.removeTrackAddedEventListener()
    Object.values(this.removeTrackRemovedEventListeners).forEach((f) => f())
  }

  cancel(): void {
    if (this.taskCanceler) {
      this.taskCanceler.cancel()
      this.taskCanceler = null
    }
  }

  async run(): Promise<void> {
    this.context.removableObservers.push(this)
    const configuration = this.context.meetingSessionConfiguration

    if (this.context.recvOnlyPeer && this.context.recvOnlyPeer.connectionState !== 'closed') {
      return
    }

    if (!configuration?.soraConnectionSubscriber) {
      return
    }
    const soraConnection = configuration.soraConnectionSubscriber

    this.removeTrackAddedEventListener = () => {
      soraConnection.on('track', (): null => null)
      this.removeTrackAddedEventListener = null
    }
    soraConnection.on('track', this.trackAddedHandler)
    soraConnection.on('removetrack', (event: MediaStreamTrackEvent) => {
      const attendeeId = (event.target as EventTarget & { id: string }).id
      const track = event.track
      if (track.kind !== 'video') {
        return
      }

      const tiles = this.context.videoTileController?.getAllVideoTiles()
      if (tiles && tiles.length > 0) {
        const removed = tiles.find((tile) => tile.state().boundAttendeeId === attendeeId)
        if (removed) {
          this.context.videoTileController?.removeVideoTile(removed.id())
        }
      }
    })

    try {
      await soraConnection.connect()
    } catch (e) {
      throw new Error(
        `Aborting ${this.taskName} due to the meeting status code: ${ImMeetingSessionStatusCode.SoraSubscriberConnectionFailed} ${e.message}`
      )
    }

    // connectのawaitが終わる前にtrackが流れてくることがある
    if (!this.context.recvOnlyPeer && this.context.peer) {
      this.context.recvOnlyPeer = soraConnection.pc
      this.context.transceiverController?.setPeer(this.context.peer)
    }
  }

  private trackAddedHandler = (event: RTCTrackEvent): void => {
    if (!this.context.recvOnlyPeer) {
      this.context.recvOnlyPeer = event.target as RTCPeerConnection
    }
    const track: MediaStreamTrack = event.track
    this.context.logger.info(`received track event: kind=${track.kind} id=${track.id} label=${track.label}`)

    if (event.transceiver && event.transceiver.currentDirection === 'inactive') {
      return
    }

    if (!event.streams[0]) {
      this.context.logger.warn('Track event but no stream')
      return
    }

    const stream: MediaStream = event.streams[0]
    if (track.kind === 'audio') {
      // this.context.audioMixController.bindAudioStream(stream)
    } else if (track.kind === 'video') {
      this.addRemoteVideoTrack(track, stream)
    }
  }

  private addRemoteVideoTrack(track: MediaStreamTrack, stream: MediaStream): void {
    const trackId = track.id
    const attendeeId = stream.id
    if (this.context.videoTileController?.haveVideoTileForAttendeeId(attendeeId)) {
      this.context.logger.info(`Not adding remote track. Already have tile for attendeeId:  ${attendeeId}`)
      return
    }

    const tile = this.context.videoTileController?.addVideoTile()

    let width: number
    let height: number
    if (track.getSettings) {
      const cap: MediaTrackSettings = track.getSettings()
      width = cap.width as number
      height = cap.height as number
    } else {
      const cap: MediaTrackCapabilities = track.getCapabilities()
      width = cap.width as number
      height = cap.height as number
    }
    tile?.bindVideoStream(attendeeId, false, stream, width, height, null, attendeeId)
    this.logger.info(`video track added, created tile=${tile?.id()} track=${trackId} attendeeId=${attendeeId}`)
  }
}
