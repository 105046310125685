import React, { useEffect } from 'react'
import {
  AuthContainer,
  BizAuthorizedErrorBoundary,
  BizUnauthorizedErrorBoundary,
  injectAlertBox,
} from '@blue-agency/im-shared-front'
import { CustomGrpcError } from '@blue-agency/im-shared-front'
import { ErrorReason } from '@blue-agency/proton/web/v2/biz_skywalker_bff/error_reason_pb'
import { ErrorInfo } from '@blue-agency/proton/web/v2/errdetails/error_details_pb'
import assert from 'assert'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { SoundPlayContainer } from '@/shared/containers/SoundPlayContainer'
import { WindowSizeContainer } from '@/shared/containers/WindowSizeContainer'
import { AuthRoutes } from './AuthRoutes'
import { InterviewDeletedPage } from './pages/InterviewDeletedPage'

const alertJsonUrl = process.env.REACT_APP_BIZ_ALERT_JSON_URL
assert(alertJsonUrl, 'alertJsonUrl is required')

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      useErrorBoundary: true,
    },
  },
})

export const BizApp: React.VFC = React.memo(() => {
  useEffect(() => {
    injectAlertBox(alertJsonUrl)
  }, [])

  return (
    <AuthContainer.Provider>
      <SharedErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <WindowSizeContainer.Provider>
            <SoundPlayContainer.Provider>
              <AuthRoutes />
            </SoundPlayContainer.Provider>
          </WindowSizeContainer.Provider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </SharedErrorBoundary>
    </AuthContainer.Provider>
  )
})

const SharedErrorBoundary: React.FC = (props) => {
  const { isLoggedIn, logout } = AuthContainer.useContainer()

  return isLoggedIn ? (
    <BizAuthorizedErrorBoundary onUnauthenticated={logout}>
      <ErrorBoundary FallbackComponent={Fallback}>{props.children}</ErrorBoundary>
    </BizAuthorizedErrorBoundary>
  ) : (
    <BizUnauthorizedErrorBoundary>{props.children}</BizUnauthorizedErrorBoundary>
  )
}

type ErrorReasonMessage = keyof typeof ErrorReason

const Fallback: React.VFC<FallbackProps> = ({ error }) => {
  if (error instanceof CustomGrpcError) {
    if (error.isNotFound) {
      const detail = error.errorDetailsOfGoogleApis[0]
      if (detail instanceof ErrorInfo) {
        const reason = detail.getReason() as ErrorReasonMessage
        if (reason === 'INTERVIEW_DELETED') {
          return <InterviewDeletedPage />
        }
      }
    }
  }

  throw error
}
