import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { usePromptBeforeUnload, PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { NewTabLink, theme, Txt, Icon, useIsIPad } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { format } from 'date-fns'
import { Switch, Case, Default } from 'react-if'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { AudioWaveBar } from '@/lib/meetingcomponent/AudioWaveBar'
import {
  BackgroundSettingButton as _BackgroundSettingButton,
  BackgroundSettingIcon,
} from '@/shared/components/BackgroundSettingButton'
import { BackgroundSettingModalForBeforeEntered } from '@/shared/components/BackgroundSettingModal'
import { ChangeCameraDropdownOnBeforeEntered } from '@/shared/components/ChangeCameraDropdownOnBeforeEntered'
import { ChangeLightAdjustmentOnBeforeEntered } from '@/shared/components/ChangeLightAdjustmentOnBeforeEntered'
import { ChangeMicDropdownOnBeforeEntered } from '@/shared/components/ChangeMicDropdownOnBeforeEntered'
import { ChangeNoiseSuppressionOnBeforeEntered } from '@/shared/components/ChangeNoiseSuppressionOnBeforeEntered'
import { ChangeSpeakerDropdownOnBeforeEntered } from '@/shared/components/ChangeSpeakerDropdownOnBeforeEntered'
import { Loading } from '@/shared/components/Loading'
import { LowQualityBox } from '@/shared/components/LowQualityBox'
import { useInterviewDisplaySize } from '@/shared/hooks/useInterviewDisplaySize'
import cameraMutedImgSrc from '@/shared/services/interviewService/assets/camera_muted.png'
import unavailableVideoInputImgSrc from '@/shared/services/interviewService/assets/unavailable_video_input.png'
import { BeforeEnteredCameraMuteButton } from '@/shared/services/interviewService/components/controlButtons/BeforeEnteredCameraMuteButton'
import { BeforeEnteredMicMuteButton } from '@/shared/services/interviewService/components/controlButtons/BeforeEnteredMicMuteButton'
import { SharedState } from '@/shared/services/interviewService/redux'
import { InterviewPageContainer } from '../../containers/InterviewPageContainer'
import { ManualModalContainer } from './containers/ManualModalContainer'
import { useBeforeEntered } from './useBeforeEntered'

export const BeforeEntered: React.VFC = provide(ManualModalContainer)(() => {
  usePromptBeforeUnload()
  const ctx = useBeforeEntered()
  const pageCtx = InterviewPageContainer.useContainer()
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isOwnMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)

  const cameraDisabled = ctx.camera.devices === undefined
  const micDisabled = ctx.mic.devices === undefined

  const { responsive } = useInterviewDisplaySize()

  const { isIPad } = useIsIPad()
  if (pageCtx.isLoading) {
    return <Loading />
  }

  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }
  return (
    <PageLayoutWithGlonavi>
      {pageCtx.qualityMode === 'low' && <LowQualityBox />}
      <Wrapper>
        <Inner>
          <VideoWrapper>
            <VideoInner>
              <Switch>
                <Case condition={isUnavailableVideoInput}>
                  <Img src={unavailableVideoInputImgSrc} />
                </Case>
                <Case condition={isOwnCameraMuted}>
                  <Img src={cameraMutedImgSrc} />
                </Case>
                <Default>
                  <MyVideo ref={ctx.videoElRef} autoPlay playsInline muted />
                </Default>
              </Switch>
              <WaveCircle>
                <AudioWaveBar width="24" height="24" audioStream={ctx.mic.stream} muted={isOwnMicMuted} />
              </WaveCircle>
              <MuteButtonWrapper>
                <BeforeEnteredMicMuteButton disabled={micDisabled} />
                <BeforeEnteredCameraMuteButton disabled={cameraDisabled} />
              </MuteButtonWrapper>
              {responsive.pc && !isIPad && (
                <>
                  <BackgroundSettingButton
                    // my側との重複は想定内
                    // eslint-disable-next-line @blue-agency/react/unique-action-name
                    comlinkPushParams={{ action: 'open_background_setting_modal' }}
                    icon={<BackgroundSettingIcon />}
                    onClick={ctx.openBackgroundSettingModal}
                    disabled={isUnavailableVideoInput}
                  />
                  <BackgroundSettingModalForBeforeEntered
                    active={ctx.isOpenBackgroundSettingModal}
                    onClose={ctx.closeBackgroundSettingModal}
                    appType="biz"
                    videoElRef={ctx.videoElRef}
                    backgroundEffect={ctx.backgroundEffect}
                    onChangeBackgroundEffect={ctx.changeBackgroundEffect}
                    backgroundUserImage={ctx.backgroundUserImage}
                    saveBackgroundUserImage={ctx.saveBackgroundUserImage}
                  />
                </>
              )}
            </VideoInner>
            <VideoBtwSelectedDeviceSpacer />
            {ctx.isChangeCameraEnabled && (
              <ChangeCameraDropdownOnBeforeEntered
                cameraDevices={ctx.camera.devices ?? []}
                selectedCameraDeviceId={ctx.camera.selectedDeviceId}
                onChangeDevice={ctx.camera.changeDevice}
              />
            )}
            <SelectedDeviceSpacer />
            {ctx.isChangeMicEnabled && (
              <ChangeMicDropdownOnBeforeEntered
                micDevices={ctx.mic.devices ?? []}
                selectedMicDeviceId={ctx.mic.selectedDeviceId}
                onChangeDevice={ctx.mic.changeDevice}
              />
            )}
            <SelectedDeviceSpacer />
            {ctx.isChangeSpeakerEnabled && (
              <ChangeSpeakerDropdownOnBeforeEntered
                speakerDevices={ctx.speaker.devices ?? []}
                selectedSpeakerDeviceId={ctx.speaker.selectedDeviceId}
                onChangeDevice={ctx.speaker.changeDevice}
              />
            )}
            {ctx.isChangeNoiseSuppressionEnabled && (
              <ChangeNoiseSuppressionOnBeforeEntered
                enabledNoiseSuppression={!!ctx.enabledNoiseSuppression}
                onChangeNoiseSuppression={ctx.setEnabledNoiseSuppression}
              />
            )}
            {ctx.isChangeLightAdjustmentEnabled && (
              <ChangeLightAdjustmentOnBeforeEntered
                enabledLightAdjustment={!!ctx.enabledLightAdjustment}
                onChangeLightAdjustment={ctx.setEnabledLightAdjustment}
              />
            )}
          </VideoWrapper>
          <SideBox>
            <SectionHeader>
              <SectionNameTxt>{pageCtx.name}</SectionNameTxt>
              {(pageCtx.durationMinutes || pageCtx.scheduledStartTime) && (
                <SectionSubWrapper>
                  {pageCtx.scheduledStartTime && (
                    <SectionScheduledStartTime>
                      <Icon name="calendar-with-timer" />
                      <ScheduledStartTimeTxt>
                        予定日時：{format(pageCtx.scheduledStartTime, 'yyyy/MM/dd HH:mm')}
                      </ScheduledStartTimeTxt>
                    </SectionScheduledStartTime>
                  )}
                  {pageCtx.durationMinutes && (
                    <SectionDuration>
                      <Icon name="duration" />
                      <DurationTxt>
                        目安：{pageCtx.durationMinutes}
                        <DurationUnit>分</DurationUnit>
                      </DurationTxt>
                    </SectionDuration>
                  )}
                </SectionSubWrapper>
              )}
            </SectionHeader>
            <SectionTxt>参加準備ができたら、入室してください。</SectionTxt>
            <Txt>
              <NewTabLink href={BIZ_EXTERNAL_URLS.terms} action="open_biz_terms">
                利用規約
              </NewTabLink>
              および
              <NewTabLink href={BIZ_EXTERNAL_URLS.privacyPolicy} action="open_biz_privacy_policy">
                プライバシーポリシー
              </NewTabLink>
              に
            </Txt>
            <ButtonWrapper>
              <EnterButton comlinkPushParams={{ action: 'click_enterRoomButton' }} onClick={ctx.onClickEnterButton}>
                同意して入室
              </EnterButton>
            </ButtonWrapper>
          </SideBox>
        </Inner>
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`

const Inner = styled.div`
  width: 100%;
  justify-content: center;
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.pc) {
      return css`
        display: flex;
        margin-top: 20px;
        padding: 0 16px;
      `
    }
  }}
  ${({ theme }) => {
    if (theme.responsive.pc) {
      return css`
        margin-top: 44px;
      `
    }
  }}
`

const VideoWrapper = styled.div`
  flex: 1;
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.pc) {
      return css`
        margin-right: 20px;
        max-width: 400px;
      `
    }
  }}
  ${({ theme }) => {
    if (theme.responsive.spPortrait) {
      return css`
        margin-bottom: 16px;
      `
    }
  }}
`

const VideoInner = styled.div`
  position: relative;
  padding-top: calc(100% * 9 / 16);
`

const VideoBtwSelectedDeviceSpacer = styled.div`
  margin-bottom: 20px;
`

const WaveCircle = styled.div`
  position: absolute;
  left: 8px;
  bottom: 9px;
  width: 24px;
  height: 24px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background-color: ${theme.color.black[1]};

  ${(props) => {
    if (props.theme.responsive.spLandscape || props.theme.responsive.pc) {
      return css`
        margin-right: 20px;
        border: 1px solid ${theme.color.gray[3]};
        border-radius: 4px;
      `
    }
  }}
`

const MyVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;

  // 自分を映す video なので左右反転させる
  transform: scaleX(-1);

  background-color: ${theme.color.black[1]};

  ${(props) => {
    if (props.theme.responsive.spLandscape || props.theme.responsive.pc) {
      return css`
        border: 1px solid ${theme.color.gray[3]};
        border-radius: 4px;
      `
    }
  }};
`

const SelectedDeviceSpacer = styled.div`
  margin-bottom: 10px;
`

const MuteButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 52px;
  background-color: rgba(255, 255, 255, 0.5);
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

const SideBox = styled.div`
  width: 435px;

  ${({ theme }) => {
    if (theme.responsive.spPortrait) {
      return css`
        padding: 0 16px;
        width: 100%;
        max-width: 435px;
        margin: 0 auto;
      `
    }
  }};
`

const SectionTxt = styled(Txt).attrs({ size: 'xl' })`
  margin-bottom: 30px;
`

const ButtonWrapper = styled.div`
  margin-top: 8px;
`

const EnterButton = styled(PrimaryButton).attrs({ widthSize: 'MAX' })``

const SectionHeader = styled.div`
  margin-bottom: 18px;
`

const SectionNameTxt = styled(Txt).attrs({ size: 'l', bold: true })`
  margin-bottom: 6px;
`

const SectionDuration = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    padding-right: 4px;
  }
`

const DurationUnit = styled.span`
  font-size: ${theme.fontSize.m};
`

const DurationTxt = styled(Txt)``

const BackgroundSettingButton = styled(_BackgroundSettingButton)`
  position: absolute;
  bottom: 8px;
  right: 9px;
`

const SectionSubWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    padding-right: 24px;
  }
`

const SectionScheduledStartTime = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:not(:last-child) {
    padding-right: 4px;
  }
`

const ScheduledStartTimeTxt = styled(Txt)``
