import { useEffect, useState } from 'react'
import { InterviewWebSocket, WebSocketMessageMap } from './useInterviewWebSocket'

export function useWebRTCHost(channelId: string, initialWebRTCHost: string, ws: InterviewWebSocket) {
  const [webRTCHost, setWebRTCHost] = useState(initialWebRTCHost)
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    const changeWebRTCHostListener = (message: WebSocketMessageMap['in trouble']) => {
      if (message.action === 'change webrtc host') {
        if (message.channel_id === channelId) {
          setWebRTCHost(message.webrtc_host)
        }
      }
    }

    addMessageListener<'in trouble'>('in trouble', changeWebRTCHostListener)

    return () => {
      removeMessageListener<'in trouble'>('in trouble', changeWebRTCHostListener)
    }
  }, [addMessageListener, channelId, removeMessageListener])

  return webRTCHost
}
