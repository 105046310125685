import { memo } from 'react'
import { theme, Icon, Txt } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { TOOLTIP_ID_MAP } from '@/shared/services/tooltipService'

export const UserImage: React.VFC<{ selected: boolean; src: string; onClick: () => void; onClear: () => void }> = memo(
  ({ selected, src, onClick, onClear }) => {
    return (
      <Container>
        <Img selected={selected} src={src} onClick={onClick} />
        <ClearCircle onClick={onClear} selected={selected} />
        {selected && <ClearImageTooltip />}
      </Container>
    )
  }
)

const Container = styled.div`
  position: relative;
`

const Img = styled.img.attrs({ alt: 'backgroundUserImage' })<{ selected: boolean }>`
  ${({ selected }) =>
    selected &&
    css`
      outline: solid 2px ${theme.color.navy[1]};
    `}
  border-radius: 8px;
  width: 60px;
  height: 44px;
  cursor: pointer;
  :hover {
    filter: brightness(90%);
  }
`

const ClearCircle: React.VFC<{ onClick: () => void; selected: boolean }> = memo(({ onClick, selected }) => {
  return (
    <ClickableCircle
      onClick={selected ? undefined : onClick}
      selected={selected}
      data-tip
      data-for={TOOLTIP_ID_MAP.backgroundUserImage}
    >
      <CloseIcon />
    </ClickableCircle>
  )
})

const ClearImageTooltip: React.VFC = memo(() => {
  return (
    <Tooltip id={TOOLTIP_ID_MAP.backgroundUserImage} arrowPosition="bottomRight">
      <Txt>この背景は使用中のため削除できません</Txt>
    </Tooltip>
  )
})

const ClickableCircle = styled.div<{ selected: boolean }>`
  position: absolute;
  ${({ selected }) =>
    !selected &&
    css`
      cursor: pointer;
    `}
  top: -11px;
  right: -11px;
  border-radius: 20px;
  width: 22px;
  height: 22px;
  ${({ selected }) => css`
    background: ${selected ? theme.color.gray[3] : theme.color.navy[1]};
  `}
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  position: absolute;
  color: white;
  top: 5px;
  right: 5px;
  width: 11px;
  height: 11px;
`
