import { useEffect, useMemo } from 'react'
import { alertToast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import delay from 'delay'
import { useDispatch, useSelector } from 'react-redux'
import { assertIsDefined } from '@/assertions'
import {
  ImMeetingSessionStatusCode,
  MeetingStatus,
  MeetingStatusObserver,
  GetVideoInputMediaError,
  GetAudioInputMediaError,
  LightAdjustment,
} from '@/lib/interview-sdk-js'
import { ContentShareContainer } from '@/lib/meetingcomponent/ContentShareContainer'
import { DataChannelMessagingContainer } from '@/lib/meetingcomponent/DataChannelMessagingContainer'
import { LocalVideoContainer } from '@/lib/meetingcomponent/LocalVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import {
  buildScreenSharingPublisher,
  buildScreenSharingSubscriber,
  buildUserPublisher,
  isUnseparatedChannel,
} from '@/lib/meetingcomponent/buildSoraConnection'
import { logger } from '@/logger'
import { useBackgroundEffect } from '@/shared/hooks/useBackgroundEffect'
import { useEffectWaitCleanup } from '@/shared/hooks/useEffectWaitCleanup'
import { useLightAdjustment } from '@/shared/hooks/useLightAdjustment'
import { useNoiseSuppression } from '@/shared/hooks/useNoiseSuppression'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { sharedSlice, SharedState } from '@/shared/services/interviewService/redux'
import { convertToBackgroundEffectOption } from '@/shared/services/interviewService/types'
import {
  comlinkPushFeatureAtLeaveMeeting,
  comlinkPushIrregularJoin,
  comlinkPushScreenSharingSubscriberDisconnected,
  comlinkPushScreenSharingSubscriberSucceeded,
  comlinkPushFeatureAtStartMeeting,
  comlinkPushUserPublisherDisconnected,
  comlinkPushUserPublisherSucceeded,
} from './comlink'
import { irregularJoinStatus, irregularJoinAlertMsg } from './irregularJoin'
import { useWatchWebRtcStats } from './useWatchWebRtcStats'

const RTC_STATS_LOGGING_INTERVAL = 10_000
const logRTCStatsEndpoint = process.env.REACT_APP_LOG_RTC_STATS_ENDPOINT
if (!logRTCStatsEndpoint) throw new Error('logRTCStatsEndpoint not found')

export const useStartMeeting = () => {
  const dispatch = useDispatch()
  const {
    interviewGuid,
    mainPublisherProps,
    screenSharingSubscriberProps,
    screenSharingPublisherProps,
    interviewQuality,
    onReceivePushEvent,
  } = InterviewContainer.useContainer()
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { setTileId: setLocalTileId } = LocalVideoContainer.useContainer()
  const { screenSharingPublisherRef } = ContentShareContainer.useContainer()
  const { onReceived: onMessageReceived, setSendFn: setDataChannelSendFn } =
    DataChannelMessagingContainer.useContainer()
  const isOwnMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)
  const isUnavailableAudioInput = useSelector((state: SharedState) => state.shared.isUnavailableAudioInput)
  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)
  const { enabledNoiseSuppression, setEnabledNoiseSuppression } = useNoiseSuppression()

  const { backgroundEffect, backgroundUserImage } = useBackgroundEffect()
  const { enabledLightAdjustment } = useLightAdjustment()

  const userPublisher = useMemo(() => {
    return buildUserPublisher(
      { ...mainPublisherProps, videoBitrate: interviewQuality.videoBitRate },
      {
        onNotify: (data) => {
          // 参考： https://sora-doc.shiguredo.jp/signaling_notify#json
          switch (data.event_type) {
            case 'connection.created':
              dispatch(sharedSlice.actions.connectionCreatedNotificationReceived(data))
              return
            case 'connection.destroyed':
              dispatch(sharedSlice.actions.connectionDestroyedNotificationReceived(data))
              return
            case 'network.status':
              dispatch(sharedSlice.actions.networkStatusNotificationReceived(data))
              return
          }
        },
        onDisconnect: (ev) => {
          if (ev.type === 'abend') {
            comlinkPushUserPublisherDisconnected({
              interviewGuid,
              channelId: mainPublisherProps.channelId,
              webrtcHost: mainPublisherProps.webrtcHost,
              event: ev,
            })
            dispatch(sharedSlice.actions.userSignalingDisconnected())
          }
        },
        onMessage: (ev) => {
          onMessageReceived(ev.label, ev.data)
        },
      }
    )
  }, [mainPublisherProps, interviewQuality.videoBitRate, dispatch, interviewGuid, onMessageReceived])

  useEffect(() => {
    userPublisher.on('datachannel', () => {
      dispatch(sharedSlice.actions.dataChannelConnected())
    })

    return () => {
      dispatch(sharedSlice.actions.dataChannelDisconnected())
    }
  }, [dispatch, userPublisher])

  useEffect(() => {
    setDataChannelSendFn((label, data) => {
      userPublisher.sendMessage(label, data)
    })
  }, [setDataChannelSendFn, userPublisher])

  useWatchWebRtcStats(userPublisher)

  // onReceivePushEvent はdepsの関係で割と更新が走る
  // その度にuserPublisherを再生成すると壊れるので以下のように独立してハンドラを設定する
  useEffect(() => {
    userPublisher.on('push', (event) => {
      logger.info('sora push event', event.data)
      onReceivePushEvent(event.data)
    })
  }, [onReceivePushEvent, userPublisher])

  const needScreenSharingSubscriber = isUnseparatedChannel(mainPublisherProps, screenSharingSubscriberProps)
  const screenSharingSubscriber = useMemo(
    () =>
      needScreenSharingSubscriber
        ? null
        : buildScreenSharingSubscriber(screenSharingSubscriberProps, {
            onNotify: (data) => {
              switch (data.event_type) {
                case 'connection.created':
                  dispatch(sharedSlice.actions.connectionCreatedNotificationReceived(data))
                  return
                case 'connection.destroyed':
                  dispatch(sharedSlice.actions.connectionDestroyedNotificationReceived(data))
                  return
                case 'network.status':
                  dispatch(sharedSlice.actions.networkStatusNotificationReceived(data))
                  return
              }
            },
            onDisconnect: (ev) => {
              if (ev.type === 'abend') {
                comlinkPushScreenSharingSubscriberDisconnected({
                  interviewGuid,
                  channelId: screenSharingSubscriberProps.channelId,
                  webrtcHost: screenSharingSubscriberProps.webrtcHost,
                  event: ev,
                })
                dispatch(sharedSlice.actions.screenShareRecvSignalingDisconnected())
              }
            },
          }),
    [dispatch, interviewGuid, needScreenSharingSubscriber, screenSharingSubscriberProps]
  )

  const screenSharingPublisher = useMemo(() => {
    const screenSharingPublisher = buildScreenSharingPublisher(screenSharingPublisherProps)
    screenSharingPublisherRef.current = screenSharingPublisher
    return screenSharingPublisher
  }, [screenSharingPublisherProps, screenSharingPublisherRef])

  useEffect(() => {
    const cb: MeetingStatusObserver = async (status, sessionStatus) => {
      logger.info(`[MeetingStatusObserver] status: ${status.toString()}, sessionStatus: ${sessionStatus.toString()}`)

      switch (status) {
        case MeetingStatus.Succeeded:
          assertIsDefined(userPublisher)

          const userPublisherSoraClientId = userPublisher.clientId
          assertIsDefined(userPublisherSoraClientId)
          dispatch(
            sharedSlice.actions.userSignalingSucceeded({
              soraClientId: userPublisherSoraClientId,
            })
          )
          comlinkPushUserPublisherSucceeded({
            interviewGuid,
            channelId: userPublisher.channelId,
            connectionId: userPublisher.connectionId,
            webrtcHost: userPublisher.connectedSignalingUrl,
          })

          if (!screenSharingSubscriber) {
            // 資料共有チャンネル分離前
            return
          }
          const screenShareSubscriberSoraClientId = screenSharingSubscriber.clientId
          assertIsDefined(screenShareSubscriberSoraClientId)
          dispatch(
            sharedSlice.actions.screenShareRecvSignalingSucceeded({
              soraClientId: screenShareSubscriberSoraClientId,
            })
          )
          comlinkPushScreenSharingSubscriberSucceeded({
            interviewGuid: interviewGuid,
            channelId: screenSharingSubscriber.channelId,
            connectionId: screenSharingSubscriber.connectionId,
            webrtcHost: screenSharingSubscriber.connectedSignalingUrl,
          })
          break
        case MeetingStatus.Failed:
          switch (sessionStatus.statusCode()) {
            case ImMeetingSessionStatusCode.NoActiveVideoInput:
              dispatch(sharedSlice.actions.unavailableVideoInputChanged(true))
              break
            case ImMeetingSessionStatusCode.NoActiveAudioInput:
              // NOTE: chime-sdkが空のaudio trackを追加するためこの経路はこない
              alert('利用できるマイクが見つかりません。リロードをお試しください。')
              break
            case ImMeetingSessionStatusCode.SoraPublisherConnectionFailed:
              dispatch(sharedSlice.actions.userSignalingFailed('Error/Unknown'))
              break
            case ImMeetingSessionStatusCode.SoraParticipantsLimitExceeded:
              dispatch(sharedSlice.actions.userSignalingFailed('Error/NumberOfParticipantsLimit'))
              break
            case ImMeetingSessionStatusCode.SoraSubscriberConnectionFailed:
              dispatch(sharedSlice.actions.screenShareRecvSignalingFailed('Error/Unknown'))
              break
          }
          break
      }
      if (sessionStatus.statusCode() === ImMeetingSessionStatusCode.PreparedRestart) {
        logger.info('ImMeetingSessionStatusCode.PreparedRestart')

        // 接続を分散させるためにランダムで遅延を入れる
        await delay(3000 * Math.random())

        assertIsDefined(meetingManager.audioVideo)
        dispatch(sharedSlice.actions.resetMetadata())
        meetingManager.setUserSendrecv(userPublisher)

        meetingManager.chooseVideoInputQuality({
          frameRate: interviewQuality.videoFrameRate,
        })

        await meetingManager.restart()
        meetingManager.startRTCStatsLogging({
          intervalMs: RTC_STATS_LOGGING_INTERVAL,
          logEndpoint: logRTCStatsEndpoint,
        })
      }
    }

    meetingManager.subscribeToMeetingStatus(cb)
    return () => {
      meetingManager.unsubscribeFromMeetingStatus(cb)
    }
  }, [dispatch, interviewGuid, interviewQuality.videoFrameRate, meetingManager, screenSharingSubscriber, userPublisher])

  useEffectWaitCleanup(() => {
    if (!meetingManager) {
      return
    }

    logger.info('meetingManager.joinAndStart')
    comlinkPushFeatureAtStartMeeting({
      enabledNoiseSuppression,
      backgroundEffect,
      videoFrameRate: interviewQuality.videoFrameRate,
      isOwnCameraMuted,
      isOwnMicMuted,
    })
    const bgOpt = convertToBackgroundEffectOption(backgroundEffect, backgroundUserImage) ?? { type: 'no-effect' }
    const lightAdjustmentOpt: LightAdjustment = enabledLightAdjustment
      ? { type: 'adjustment', strength: 50 }
      : { type: 'no-effect' }
    meetingManager
      .joinAndStart(
        userPublisher,
        screenSharingPublisher,
        screenSharingSubscriber,
        isOwnCameraMuted,
        isOwnMicMuted,
        isUnavailableVideoInput,
        isUnavailableAudioInput,
        { frameRate: interviewQuality.videoFrameRate },
        bgOpt,
        lightAdjustmentOpt,
        enabledNoiseSuppression
      )
      .then(() => {
        logger.info('succeeded in meetingManager.joinAndStart', {
          userPublisher,
          screenSharingPublisher,
          screenSharingSubscriber,
        })
        const localTileId = meetingManager.audioVideo?.getLocalVideoTile()?.id()
        assertIsDefined(localTileId)
        setLocalTileId(localTileId)

        meetingManager.startRTCStatsLogging({
          intervalMs: RTC_STATS_LOGGING_INTERVAL,
          logEndpoint: logRTCStatsEndpoint,
        })

        const status = irregularJoinStatus(isUnavailableVideoInput, isUnavailableAudioInput)
        if (status) {
          const alertMsg = irregularJoinAlertMsg(status)
          alertToast(alertMsg)
          comlinkPushIrregularJoin({ interviewGuid, status })
        }
      })
      .catch((e) => {
        logger.info('failed to meetingManager.joinAndStart', {
          userPublisher,
          screenSharingPublisher,
          screenSharingSubscriber,
          error: e,
        })
        if (e instanceof GetVideoInputMediaError) {
          dispatch(sharedSlice.actions.unavailableVideoInputChanged(true))
          return
        }
        if (e instanceof GetAudioInputMediaError) {
          dispatch(sharedSlice.actions.unavailableAudioInputChanged(true))
          return
        }
        Sentry.captureException(e, {
          tags: {
            context: 'failed to meetingManager.joinAndStart',
          },
        })
        /**
         * https://stadium-co-jp.slack.com/archives/C02A51MH1PD/p1653392095519259?thread_ts=1653382969.828789&cid=C02A51MH1PD
         * Amazon Voice Focus絡みと思われるエラーが出ることがあるので、ノイズ抑制をOFFにして再度試行する
         */
        if (enabledNoiseSuppression) {
          setEnabledNoiseSuppression(false)
          alert('動作が不安定になる恐れがあるため、ノイズ抑制をオフにしました。')
          return
        }
        alert('接続に失敗しました。リロードをお試しください。')
      })

    return async () => {
      logger.info('meetingManager.leave')
      comlinkPushFeatureAtLeaveMeeting({
        enabledNoiseSuppression,
        backgroundEffect,
        videoFrameRate: interviewQuality.videoFrameRate,
        isOwnCameraMuted,
        isOwnMicMuted,
      })
      await meetingManager.leave().catch((e) => {
        meetingManager.leave().catch((e) => {
          Sentry.captureException(e)
        })
      })
    }
    // isOwnCameraMuted, isOwnMicMuted, backgroundEffectの状態が変わったときに
    // useEffectを実行する必要がないためlintを無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    meetingManager,
    screenSharingPublisher,
    screenSharingSubscriber,
    setLocalTileId,
    isUnavailableVideoInput,
    isUnavailableAudioInput,
    enabledNoiseSuppression,
  ])

  // reconnect
  useEffect(() => {
    if (!meetingManager.audioVideo) return
    if (!ownSoraClientId) return // 接続後を保証

    logger.info('reconnect')

    // 主にchatのws再接続を強制するために1度消すのが必要
    dispatch(sharedSlice.actions.resetSignalingStatus())

    meetingManager.stopRTCStatsLogging()
    meetingManager.stopBeforeRestart()

    // NOTE: meetingManager.audioVideoやownSoraClientIdは接続後に更新され、そこで再接続は実施したくないのでdepsから外す
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPublisher])
}
