import { createSlice } from '@reduxjs/toolkit'
import { sharedSlice } from './sharedSlice'

type State = {
  startMessageServerSentTime?: string
  interviewStartTime?: string
}

const initialState: State = {
  startMessageServerSentTime: undefined,
  interviewStartTime: undefined,
}

export const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sharedSlice.actions.startMessageReceived, (state, { payload }) => {
      state.interviewStartTime = payload.start_time
      state.startMessageServerSentTime = payload.current_time
    })
  },
})
