import { useState, useEffect, useRef } from 'react'
import {
  transformStreamWithMediaProcessorBGBlur,
  transformStreamWithMediaProcessorBGReplace,
  transformStreamWithMediaProcessorLightAdjustment,
} from '@/lib/interview-sdk-js'
import { createImgFromUri } from '@/shared/services/image'
import { BACKGROUND_BLUR_SIZE_MAP } from '@/shared/services/interviewService/types'
import { useBackgroundEffect } from './useBackgroundEffect'
import { useLightAdjustment } from './useLightAdjustment'

export const useTransformStream = (originStream: MediaStream | undefined) => {
  const [transformedStream, setTransformedStream] = useState(originStream)
  const stopTransformRef = useRef(() => {})
  const { backgroundEffect, backgroundUserImage, changeBackgroundEffect, saveBackgroundUserImage } =
    useBackgroundEffect()
  const { enabledLightAdjustment, setEnabledLightAdjustment, lightAdjustmentLevel, setLightAdjustmentLevel } =
    useLightAdjustment()

  useEffect(() => {
    stopTransformRef.current()
    if (!originStream) return
    switch (backgroundEffect) {
      case 'no-effect':
        if (enabledLightAdjustment) {
          const f = async () => {
            const { stream, stop } = await transformStreamWithMediaProcessorLightAdjustment(
              originStream,
              lightAdjustmentLevel ?? 0.0
            )
            stopTransformRef.current = stop
            setTransformedStream(stream)
          }
          f()
        } else {
          setTransformedStream(originStream)
        }
        break
      case 'blur-strong':
      case 'blur-weak': {
        const f = async () => {
          let stream = originStream
          let stop = () => {}
          if (enabledLightAdjustment) {
            const { stream: adjustmentStream, stop: stopAdjustment } =
              await transformStreamWithMediaProcessorLightAdjustment(originStream, lightAdjustmentLevel ?? 0.0)
            stream = adjustmentStream
            stop = stopAdjustment
          }
          const { stream: blurredStream, stop: stopBlur } = await transformStreamWithMediaProcessorBGBlur(
            stream,
            BACKGROUND_BLUR_SIZE_MAP[backgroundEffect]
          )
          stopTransformRef.current = () => {
            stop()
            stopBlur()
          }
          setTransformedStream(blurredStream)
        }
        f()
        break
      }
      case 'user-image': {
        if (!backgroundUserImage) {
          setTransformedStream(originStream)
          break
        }
        const f = async () => {
          let stream = originStream
          let stop = () => {}
          if (enabledLightAdjustment) {
            const { stream: adjustmentStream, stop: stopAdjustment } =
              await transformStreamWithMediaProcessorLightAdjustment(originStream, lightAdjustmentLevel ?? 0.0)
            stream = adjustmentStream
            stop = stopAdjustment
          }
          const { stream: replacedStream, stop: stopReplace } = await transformStreamWithMediaProcessorBGReplace(
            stream,
            createImgFromUri(backgroundUserImage)
          )
          stopTransformRef.current = () => {
            stop()
            stopReplace()
          }
          setTransformedStream(replacedStream)
        }
        f()
        break
      }
      default:
        break
    }
    return () => {
      stopTransformRef.current()
    }
  }, [backgroundEffect, originStream, backgroundUserImage, enabledLightAdjustment, lightAdjustmentLevel])

  return {
    transformedStream,
    backgroundEffect,
    changeBackgroundEffect,
    backgroundUserImage,
    saveBackgroundUserImage,
    enabledLightAdjustment,
    setEnabledLightAdjustment,
    lightAdjustmentLevel,
    setLightAdjustmentLevel,
  }
}
