// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import {
  ActiveSpeakerDetector,
  AsyncScheduler,
  AudioMixController,
  AudioProfile,
  AudioVideoEventAttributes,
  DefaultBrowserBehavior,
  DefaultRealtimeController,
  DefaultSessionStateController,
  DefaultTransceiverController,
  DefaultVideoCaptureAndEncodeParameter,
  DefaultVideoStreamIdSet,
  DefaultVideoTileFactory,
  Destroyable,
  EventController,
  Logger,
  Maybe,
  MediaStreamBroker,
  MeetingSessionVideoAvailability,
  RealtimeController,
  ReconnectController,
  SerialGroupTask,
  SessionStateController,
  SessionStateControllerAction,
  SessionStateControllerTransitionResult,
  TimeoutTask,
  VideoSource,
  VideoTileController,
  WebSocketAdapter,
} from 'amazon-chime-sdk-js'
import { SdkStreamServiceType } from 'amazon-chime-sdk-js/build/signalingprotocol/SignalingProtocol'
import {
  AudioVideoObserver,
  ImEventController,
  ImMeetingSessionConfiguration,
  ImVideoTileController,
  NoOpActiveSpeakerDetector,
  NoOpAudioMixController,
  NoOpConnectionMonitor,
  NoOpStatsCollector,
  SoraTransceiverController,
} from '..'
import { ImMeetingSessionStatus as MeetingSessionStatus } from '../meetingsession/ImMeetingSessionStatus'
import { ImMeetingSessionStatusCode as MeetingSessionStatusCode } from '../meetingsession/ImMeetingSessionStatusCode'
import { AttachMediaInputTask } from '../task/AttachMediaInputTask'
import { CleanStoppedSessionTask } from '../task/CleanStoppedSessionTask'
import { OpenRecvOnlySoraConnectionTask } from '../task/OpenRecvOnlySoraConnectionTask'
import { OpenSoraConnectionTask } from '../task/OpenSoraConnectionTask'
import { ReceiveAudioInputTask } from '../task/ReceiveAudioInputTask'
import { ReceiveVideoInputTask } from '../task/ReceiveVideoInputTask'
import { noop } from '../util'
import { AudioVideoController } from './AudioVideoController'
import { ImAudioVideoControllerState } from './ImAudioVideoControllerState'

export class ImAudioVideoController implements AudioVideoController, Destroyable {
  private _logger: Logger
  private _configuration: ImMeetingSessionConfiguration
  private _webSocketAdapter: WebSocketAdapter
  private _realtimeController: RealtimeController
  private _activeSpeakerDetector?: ActiveSpeakerDetector
  private _videoTileController: VideoTileController
  private _mediaStreamBroker: MediaStreamBroker
  private _reconnectController: ReconnectController
  private _audioMixController: AudioMixController
  private _eventController: EventController
  private _audioProfile: AudioProfile = new AudioProfile()

  private observerQueue: Set<AudioVideoObserver> = new Set<AudioVideoObserver>()
  private meetingSessionContext: ImAudioVideoControllerState
  private sessionStateController: SessionStateController

  private readonly enableSimulcast = false
  private totalRetryCount = 0
  private startAudioVideoTimestamp = 0
  destroyed = false

  constructor(
    configuration: ImMeetingSessionConfiguration,
    logger: Logger,
    webSocketAdapter: WebSocketAdapter,
    mediaStreamBroker: MediaStreamBroker,
    reconnectController: ReconnectController
  ) {
    this._logger = logger
    this.meetingSessionContext = new ImAudioVideoControllerState(this._logger)
    this.sessionStateController = new DefaultSessionStateController(this._logger)
    this._configuration = configuration
    this.enableSimulcast = false

    this._webSocketAdapter = webSocketAdapter
    this._realtimeController = new DefaultRealtimeController()
    if (
      this._realtimeController instanceof DefaultRealtimeController &&
      configuration.credentials !== null &&
      configuration.credentials.attendeeId !== null &&
      configuration.credentials.externalUserId !== null
    ) {
      this._realtimeController.realtimeSetLocalAttendeeId(
        configuration.credentials.attendeeId,
        configuration.credentials.externalUserId
      )
    }

    this._mediaStreamBroker = mediaStreamBroker
    this._reconnectController = reconnectController
    this._videoTileController = new ImVideoTileController(new DefaultVideoTileFactory(), this, this._logger)
    this._audioMixController = new NoOpAudioMixController()
    this._eventController = new ImEventController(this)
  }

  async destroy(): Promise<void> {
    this.observerQueue.clear()
    this.destroyed = true
  }

  get configuration(): ImMeetingSessionConfiguration {
    return this._configuration
  }

  get realtimeController(): RealtimeController {
    return this._realtimeController
  }

  get videoTileController(): VideoTileController {
    return this._videoTileController
  }

  get audioMixController(): AudioMixController {
    return this._audioMixController
  }

  get eventController(): EventController {
    return this._eventController
  }

  get activeSpeakerDetector(): ActiveSpeakerDetector {
    if (!this._activeSpeakerDetector) {
      this._activeSpeakerDetector = new NoOpActiveSpeakerDetector()
    }
    return this._activeSpeakerDetector
  }

  get logger(): Logger {
    return this._logger
  }

  get rtcPeerConnection(): RTCPeerConnection | null {
    return (this.meetingSessionContext && this.meetingSessionContext.peer) || null
  }

  get mediaStreamBroker(): MediaStreamBroker {
    return this._mediaStreamBroker
  }

  getRTCPeerConnectionStats(selector?: MediaStreamTrack): Promise<RTCStatsReport> {
    if (!this.rtcPeerConnection) {
      return Promise.resolve(new RTCStatsReport())
    }
    return this.rtcPeerConnection.getStats(selector)
  }

  setAudioProfile(audioProfile: AudioProfile): void {
    this._audioProfile = audioProfile
  }

  addObserver(observer: AudioVideoObserver): void {
    this.logger.info('adding meeting observer')
    this.observerQueue.add(observer)
  }

  removeObserver(observer: AudioVideoObserver): void {
    this.logger.info('removing meeting observer')
    this.observerQueue.delete(observer)
  }

  forEachObserver(observerFunc: (observer: AudioVideoObserver) => void): void {
    for (const observer of this.observerQueue) {
      AsyncScheduler.nextTick(() => {
        if (this.observerQueue.has(observer)) {
          observerFunc(observer)
        }
      })
    }
  }

  start(): void {
    this.sessionStateController.perform(SessionStateControllerAction.Connect, () => {
      this.actionConnect(false)
    })
  }

  private async actionConnect(reconnecting: boolean): Promise<void> {
    this.meetingSessionContext = new ImAudioVideoControllerState(this.logger)
    this.meetingSessionContext.eventController = this.eventController
    this.meetingSessionContext.browserBehavior = new DefaultBrowserBehavior({
      enableUnifiedPlanForChromiumBasedBrowsers: true,
    })

    this.meetingSessionContext.meetingSessionConfiguration = this.configuration
    this.meetingSessionContext.mediaStreamBroker = this._mediaStreamBroker
    this.meetingSessionContext.realtimeController = this._realtimeController
    this.meetingSessionContext.audioMixController = this._audioMixController
    this.meetingSessionContext.audioVideoController = this

    this.meetingSessionContext.transceiverController = new SoraTransceiverController(
      this.logger,
      this.meetingSessionContext.browserBehavior
    )

    this.meetingSessionContext.videoTileController = this._videoTileController
    this.meetingSessionContext.videoDownlinkBandwidthPolicy = this.configuration.videoDownlinkBandwidthPolicy
    this.meetingSessionContext.videoUplinkBandwidthPolicy = this.configuration.videoUplinkBandwidthPolicy
    this.meetingSessionContext.enableSimulcast = this.enableSimulcast

    this.meetingSessionContext.audioProfile = this._audioProfile

    this.meetingSessionContext.lastKnownVideoAvailability = new MeetingSessionVideoAvailability()
    this.meetingSessionContext.videoCaptureAndEncodeParameter = new DefaultVideoCaptureAndEncodeParameter(
      0,
      0,
      0,
      0,
      false
    )
    this.meetingSessionContext.videosToReceive = new DefaultVideoStreamIdSet()
    this.meetingSessionContext.videosPaused = new DefaultVideoStreamIdSet()
    this.meetingSessionContext.statsCollector = new NoOpStatsCollector()
    this.meetingSessionContext.connectionMonitor = new NoOpConnectionMonitor()
    this.meetingSessionContext.reconnectController = this._reconnectController
    this.meetingSessionContext.videoDeviceInformation = {}

    if (!reconnecting) {
      this.totalRetryCount = 0
      this._reconnectController.reset()
      this.startAudioVideoTimestamp = Date.now()
      this.forEachObserver((observer) => {
        Maybe.of(observer.audioVideoDidStartConnecting).map((f) => f && f.bind(observer)(false))
      })
      /* istanbul ignore else */
      if (this.eventController) {
        this.eventController.publishEvent('meetingStartRequested')
      }
    }
    this.meetingSessionContext.startAudioVideoTimestamp = this.startAudioVideoTimestamp
    if (this._reconnectController.hasStartedConnectionAttempt()) {
      // This does not reset the reconnect deadline, but declare it's not the first connection.
      this._reconnectController.startedConnectionAttempt(false)
    } else {
      this._reconnectController.startedConnectionAttempt(true)
    }

    try {
      await new SerialGroupTask(this.logger, this.wrapTaskName('AudioVideoStart'), [
        new ReceiveAudioInputTask(this.meetingSessionContext),
        new ReceiveVideoInputTask(this.meetingSessionContext),
        new TimeoutTask(
          this.logger,
          new SerialGroupTask(this.logger, 'Media', [
            new SerialGroupTask(this.logger, 'Peer', [
              new OpenSoraConnectionTask(this.meetingSessionContext),
              new OpenRecvOnlySoraConnectionTask(this.meetingSessionContext),
              new AttachMediaInputTask(this.meetingSessionContext),
            ]),
          ]),
          this.configuration.connectionTimeoutMs
        ),
      ]).run()
      this.sessionStateController.perform(SessionStateControllerAction.FinishConnecting, () => {
        /* istanbul ignore else */
        if (this.eventController) {
          this.meetingSessionContext.meetingStartDurationMs = Date.now() - this.startAudioVideoTimestamp

          this.eventController.publishEvent('meetingStartSucceeded', {
            maxVideoTileCount: this.meetingSessionContext.maxVideoTileCount,
            poorConnectionCount: this.meetingSessionContext.poorConnectionCount,
            retryCount: this.totalRetryCount,
            signalingOpenDurationMs: this.meetingSessionContext.signalingOpenDurationMs ?? undefined,
            iceGatheringDurationMs: this.meetingSessionContext.iceGatheringDurationMs ?? undefined,
            meetingStartDurationMs: this.meetingSessionContext.meetingStartDurationMs ?? undefined,
          })
        }
        this.meetingSessionContext.startTimeMs = Date.now()
        this.actionFinishConnecting()
      })
    } catch (error) {
      this.sessionStateController.perform(SessionStateControllerAction.Fail, async () => {
        const status = new MeetingSessionStatus(this.getMeetingStatusCode(error) || MeetingSessionStatusCode.TaskFailed)
        await this.actionDisconnect(status, true, error)
        if (!this.handleMeetingSessionStatus(status, error)) {
          this.notifyStop(status, error)
        }
      })
    }
  }

  private actionFinishConnecting(): void {
    this.meetingSessionContext.videoDuplexMode = SdkStreamServiceType.RX

    this.forEachObserver((observer) => {
      Maybe.of(observer.audioVideoDidStart).map((f) => f && f.bind(observer)())
    })
    this._reconnectController.reset()
  }

  stop(): void {
    /*
    Stops the current audio video meeting session.
    The stop method execution is deferred and executed after
    the current reconnection attempt completes.
    It disables any further reconnection attempts.
    Upon completion, AudioVideoObserver's `audioVideoDidStop`
    callback function is called with `MeetingSessionStatusCode.Left`.
    */
    this.sessionStateController.perform(SessionStateControllerAction.Disconnect, () => {
      this._reconnectController.disableReconnect()
      this.logger.info('attendee left meeting, session will not be reconnected')
      this.actionDisconnect(new MeetingSessionStatus(MeetingSessionStatusCode.Left), false, null)
    })
  }

  stopBeforeRestart(): void {
    this.sessionStateController.perform(SessionStateControllerAction.Disconnect, () => {
      this._reconnectController.disableReconnect()
      this.logger.info('attendee disconnect meeting, session will not be reconnected')
      this.actionDisconnect(new MeetingSessionStatus(MeetingSessionStatusCode.PreparedRestart), false, null)
    })
  }

  private async actionDisconnect(
    status: MeetingSessionStatus,
    reconnecting: boolean,
    error: Error | null
  ): Promise<void> {
    try {
      await new SerialGroupTask(this.logger, this.wrapTaskName('AudioVideoClean'), [
        new TimeoutTask(
          this.logger,
          new CleanStoppedSessionTask(this.meetingSessionContext),
          this.configuration.connectionTimeoutMs
        ),
      ]).run()
    } catch (cleanError) {
      this.logger.info('fail to clean')
    }
    this.sessionStateController.perform(SessionStateControllerAction.FinishDisconnecting, () => {
      if (!reconnecting) {
        this.notifyStop(status, error)
      }
    })
  }

  update(): boolean {
    const result = this.sessionStateController.perform(SessionStateControllerAction.Update, () => {
      this.actionUpdate(true)
    })
    return (
      result === SessionStateControllerTransitionResult.Transitioned ||
      result === SessionStateControllerTransitionResult.DeferredTransition
    )
  }

  restartLocalVideo(callback: () => void): boolean {
    const restartVideo = async (): Promise<void> => {
      if (this._videoTileController.hasStartedLocalVideoTile()) {
        this.logger.info('stopping local video tile prior to local video restart')
        this._videoTileController.stopLocalVideoTile()
        this.logger.info('preparing local video restart update')
        await this.actionUpdate(false)
        this.logger.info('starting local video tile for local video restart')
        this._videoTileController.startLocalVideoTile()
      }
      this.logger.info('finalizing local video restart update')
      await this.actionUpdate(true)
      callback()
    }
    const result = this.sessionStateController.perform(SessionStateControllerAction.Update, () => {
      restartVideo()
    })
    return (
      result === SessionStateControllerTransitionResult.Transitioned ||
      result === SessionStateControllerTransitionResult.DeferredTransition
    )
  }

  async replaceLocalVideo(): Promise<void> {
    let videoStream: MediaStream | null = null
    try {
      videoStream = await this.mediaStreamBroker.acquireVideoInputStream()
    } catch (error) {
      throw new Error(`could not acquire video stream from mediaStreamBroker due to ${error.message}`)
    }

    if (!videoStream || videoStream.getVideoTracks().length < 1) {
      throw new Error('could not acquire video track')
    }

    const videoTrack = videoStream.getVideoTracks()[0]
    if (!this.meetingSessionContext || !this.meetingSessionContext.peer) {
      // MEMO(johejo)
      // 元々はここで例外をthrowしていた
      // 背景ぼかしを入れた際になぜか一時的にpeerがnullになっていることがあり、一旦無視(return)して続行すると上手くいく
      this.logger.warn('no active meeting and peer connection')
      return
    }

    if (this.meetingSessionContext.browserBehavior?.requiresUnifiedPlan() && videoTrack) {
      await this.meetingSessionContext.transceiverController?.setVideoInput(videoTrack)
    } else {
      throw new Error('cannot replace track on Plan B')
    }

    // if there is a local tile, a video tile update event should be fired.
    const localTile = this.meetingSessionContext.videoTileController?.getLocalVideoTile()
    if (localTile) {
      const state = localTile.state()
      const settings = videoStream.getVideoTracks()[0]?.getSettings()
      // so tile update wil be fired.
      localTile.bindVideoStream(
        state.boundAttendeeId ?? '',
        true,
        videoStream,
        settings?.width ?? 0,
        settings?.height ?? 0,
        state.streamId,
        state.boundExternalUserId ?? undefined
      )
    }

    // Update the active video input on subscription context to match what we just changed
    // so that subsequent meeting actions can reuse and destroy it.
    this.meetingSessionContext.activeVideoInput = videoStream
  }

  async restartLocalAudio(callback: () => void): Promise<void> {
    let audioStream: MediaStream | null = null
    try {
      audioStream = await this.mediaStreamBroker.acquireAudioInputStream()
    } catch (error) {
      this.logger.info('could not acquire audio stream from mediaStreamBroker')
    }
    if (!audioStream || audioStream.getAudioTracks().length < 1) {
      throw new Error('could not acquire audio track')
    }

    const audioTrack = audioStream.getAudioTracks()[0]
    if (!this.meetingSessionContext || !this.meetingSessionContext.peer) {
      throw new Error('no active meeting and peer connection')
    }
    let replaceTrackSuccess = false

    if (
      this.meetingSessionContext.browserBehavior?.requiresUnifiedPlan() &&
      this.meetingSessionContext.transceiverController &&
      audioTrack
    ) {
      replaceTrackSuccess = await this.meetingSessionContext.transceiverController.replaceAudioTrack(audioTrack)
    } else if (this.meetingSessionContext.localAudioSender && audioTrack) {
      replaceTrackSuccess = await DefaultTransceiverController.replaceAudioTrackForSender(
        this.meetingSessionContext.localAudioSender,
        audioTrack
      )
    }
    this._realtimeController.realtimeSetLocalAudioInput(audioStream)
    this.meetingSessionContext.activeAudioInput = audioStream
    callback()
    if (replaceTrackSuccess) {
      return Promise.resolve()
    } else {
      return Promise.reject()
    }
  }

  private async actionUpdate(notify: boolean): Promise<void> {
    // TODO: do not block other updates while waiting for video input
    try {
      await new SerialGroupTask(this.logger, this.wrapTaskName('AudioVideoUpdate'), [
        new ReceiveVideoInputTask(this.meetingSessionContext),
        new TimeoutTask(
          this.logger,
          new SerialGroupTask(this.logger, 'UpdateSession', [new AttachMediaInputTask(this.meetingSessionContext)]),
          this.configuration.connectionTimeoutMs
        ),
      ]).run()
      if (notify) {
        this.sessionStateController.perform(SessionStateControllerAction.FinishUpdating, () => {
          this.actionFinishUpdating()
        })
      }
    } catch (error) {
      this.sessionStateController.perform(SessionStateControllerAction.FinishUpdating, () => {
        const status = new MeetingSessionStatus(this.getMeetingStatusCode(error) || MeetingSessionStatusCode.TaskFailed)
        if (status.statusCode() !== MeetingSessionStatusCode.IncompatibleSDP) {
          this.logger.info('failed to update audio-video session')
        }
        this.handleMeetingSessionStatus(status, error)
      })
    }
  }

  private notifyStop(status: MeetingSessionStatus, error: Error | null): void {
    this.forEachObserver((observer) => {
      Maybe.of(observer.audioVideoDidStop).map((f) => f && f.bind(observer)(status))
    })

    /* istanbul ignore else */
    if (this.eventController) {
      const {
        signalingOpenDurationMs,
        poorConnectionCount,
        startTimeMs,
        iceGatheringDurationMs,
        attendeePresenceDurationMs,
        meetingStartDurationMs,
      } = this.meetingSessionContext

      const attributes: AudioVideoEventAttributes = {
        maxVideoTileCount: this.meetingSessionContext.maxVideoTileCount,
        meetingDurationMs: startTimeMs === null ? 0 : Math.round(Date.now() - startTimeMs),
        meetingStatus: MeetingSessionStatusCode[status.statusCode()],
        signalingOpenDurationMs: signalingOpenDurationMs ?? undefined,
        iceGatheringDurationMs: iceGatheringDurationMs ?? undefined,
        attendeePresenceDurationMs: attendeePresenceDurationMs ?? undefined,
        poorConnectionCount,
        meetingStartDurationMs: meetingStartDurationMs ?? undefined,
        retryCount: this.totalRetryCount,
      }

      /* istanbul ignore next: toString is optional */
      const meetingErrorMessage = (error && error.message) || status.toString?.() || ''
      if (attributes.meetingDurationMs === 0) {
        attributes.meetingErrorMessage = meetingErrorMessage
        delete attributes.meetingDurationMs
        delete attributes.attendeePresenceDurationMs
        delete attributes.meetingStartDurationMs
        this.eventController.publishEvent('meetingStartFailed', attributes)
      } else if (status.isFailure() || status.isAudioConnectionFailure()) {
        attributes.meetingErrorMessage = meetingErrorMessage
        this.eventController.publishEvent('meetingFailed', attributes)
      } else {
        this.eventController.publishEvent('meetingEnded', attributes)
      }
    }
  }

  private actionFinishUpdating(): void {
    this.logger.info('updated audio-video session')
  }

  reconnect(status: MeetingSessionStatus, error: Error | null): boolean {
    // 状態を単純にするためとりあえずはreconnectしない
    this.sessionStateController.perform(SessionStateControllerAction.Fail, () => {
      this.actionDisconnect(status, false, error)
    })

    return false
  }

  private wrapTaskName(taskName: string): string {
    return `${taskName}/${this.configuration.meetingId}/${this.configuration.credentials?.attendeeId}`
  }

  private getMeetingStatusCode(error: Error): MeetingSessionStatusCode | null {
    const matched = /the meeting status code: (\d+)/.exec(error && error.message)
    if (matched && matched.length > 1) {
      return Number(matched[1])
    } else {
      return null
    }
  }

  handleMeetingSessionStatus(status: MeetingSessionStatus, error: Error | null): boolean {
    this.logger.info(`handling status: ${MeetingSessionStatusCode[status.statusCode()]}`)
    if (status.statusCode() === MeetingSessionStatusCode.IncompatibleSDP) {
      this.restartLocalVideo(() => {
        this.logger.info('handled incompatible SDP by attempting to restart video')
      })
      return true
    }
    if (status.statusCode() === MeetingSessionStatusCode.VideoCallSwitchToViewOnly) {
      this._videoTileController.removeLocalVideoTile()
      this.forEachObserver((observer: AudioVideoObserver) => {
        Maybe.of(observer.videoSendDidBecomeUnavailable).map((f) => f && f.bind(observer)())
      })
      return false
    }
    if (status.isTerminal()) {
      this.logger.error('session will not be reconnected')
      if (this.meetingSessionContext.reconnectController) {
        this.meetingSessionContext.reconnectController.disableReconnect()
      }
    }
    if (status.isFailure() || status.isTerminal()) {
      if (this.meetingSessionContext.reconnectController) {
        const willRetry = this.reconnect(status, error)
        if (willRetry) {
          this.logger.warn(
            `will retry due to status code ${MeetingSessionStatusCode[status.statusCode()]}${
              error ? ` and error: ${error.message}` : ''
            }`
          )
        } else {
          this.logger.error(
            `failed with status code ${MeetingSessionStatusCode[status.statusCode()]}${
              error ? ` and error: ${error.message}` : ''
            }`
          )
        }
        return willRetry
      }
    }
    return false
  }

  setVideoMaxBandwidthKbps(maxBandwidthKbps: number): void {
    noop(maxBandwidthKbps)
  }

  async handleHasBandwidthPriority(hasBandwidthPriority: boolean): Promise<void> {
    noop(hasBandwidthPriority)
  }

  pauseReceivingStream(streamId: number): void {
    noop(streamId)
    throw new Error('Not implemented')
  }

  resumeReceivingStream(streamId: number): void {
    noop(streamId)
    throw new Error('Not implemented')
  }

  getRemoteVideoSources(): VideoSource[] {
    throw new Error('Not implemented')
  }
}
