import { useCallback, useEffect, useState } from 'react'
import mitt from 'mitt'
import type { ToastEvent } from '../interviewPinningToast'

export const TOAST_EVENT_NAME = '@@skywalker/interviewPinningToast'
export const toastEmitter = mitt()

export function useToast() {
  const [toastParams, setToastParams] = useState<ToastEvent>()

  useEffect(() => {
    const handleToastEvent = (event: ToastEvent | undefined) => {
      if (event === undefined) {
        return
      }
      setToastParams(event)
    }

    toastEmitter.on(TOAST_EVENT_NAME, handleToastEvent)

    return () => {
      toastEmitter.off(TOAST_EVENT_NAME, handleToastEvent)
    }
  }, [])

  const handleToastClose = useCallback(() => {
    if (toastParams === undefined) {
      return
    }

    setToastParams(undefined)
  }, [toastParams])

  useEffect(() => {
    if (toastParams === undefined) {
      return
    }

    const timer = setTimeout(() => {
      handleToastClose()
    }, toastParams.duration)

    return () => {
      clearTimeout(timer)
    }
  }, [toastParams, handleToastClose])

  return { toastParams, handleToastClose }
}
