import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName

export const RecommendPcBox: React.VFC<Props> = (props) => {
  return (
    <Wrapper className={props.className}>
      <Header>チェックポイント</Header>
      <Inner>
        <RecommendText>
          <Txt bold>PCでのご利用がオススメ！</Txt>
        </RecommendText>
        <Txt>
          複数の参加者が想定される場合や、長時間接続される場合は、より動作が安定するPCでのご利用をオススメしています。
        </Txt>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  border: 1px solid ${theme.color.navy[1]};
  border-radius: 2px;
  max-width: 327px;
`

const Header = styled.div`
  background-color: ${theme.color.navy[1]};
  color: ${theme.color.white[1]};
  padding: 8px;
  text-align: center;
`

const Inner = styled.div`
  padding: 20px 15px;
`

const RecommendText = styled.div`
  margin-bottom: 10px;
`
