import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BackgroundEffect } from '../types'

type State = {
  backgroundEffect: BackgroundEffect
  backgroundUserImage: string | undefined // data uri
}

const initialState: State = {
  backgroundEffect: 'no-effect',
  backgroundUserImage: undefined,
}

export const sharedStoredSlice = createSlice({
  name: 'sharedStored',
  initialState,
  reducers: {
    changeBackgroundEffect: (state, { payload }: PayloadAction<{ backgroundEffect: BackgroundEffect }>) => {
      state.backgroundEffect = payload.backgroundEffect
    },
    saveBackgroundUserImage: (state, { payload }: PayloadAction<{ backgroundUserImage: string | undefined }>) => {
      state.backgroundUserImage = payload.backgroundUserImage
    },
  },
})
