import React, { useContext, useRef, useLayoutEffect } from 'react'
import { PrimaryLinkTxt, Txt } from '@blue-agency/rogue'
import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import useScript from 'react-script-hook'
import styled, { ThemeContext } from 'styled-components'
import { GlobalHeaderHelp } from '@/my/components/GlobalHeaderHelp'
import { PageLayout } from '@/shared/components/PageLayout'
import { buttonResetStyle } from '@/shared/styles/buttonResetStyle'
import { InterviewPageContainer } from '../../containers/InterviewPageContainer'
import { reEntry } from '../../reEntry'
import { RootState } from '../redux/store'
import { useGetSpecialSurveyUrl } from './specialSurveyCard/useGetSpecialSurveyUrl'

const isLeavedSelector = createDraftSafeSelector(
  (state: RootState) => state.my.ownState,
  (ownState) => ownState === 'Leaved'
)

export const Finished: React.VFC = () => {
  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) throw new Error('invalid state')

  const specialSurveyUrl = useGetSpecialSurveyUrl(pageCtx.interviewGuid)
  const getFormByURL = (form: string) => {
    const fromIndex = form.indexOf('@')
    return form.substring(fromIndex)
  }

  return (
    <PageLayout
      globalHeader={{
        rightNode: <GlobalHeaderHelp />,
        disableLogoLink: true,
      }}
    >
      {specialSurveyUrl.specialSurveyUrl && (
        <Survey
          surveyForm={
            specialSurveyUrl.existSpecialSurveyUrl ? getFormByURL(specialSurveyUrl.specialSurveyUrl) : undefined
          }
        />
      )}
    </PageLayout>
  )
}

type Props = {
  surveyForm?: string
}

const FORM_RUN_SCRIPT_URL = process.env.REACT_APP_FORM_RUN_SCRIPT_URL

const Survey: React.VFC<Props> = (props) => {
  useScript({ src: FORM_RUN_SCRIPT_URL ?? null })
  const { responsive } = useContext(ThemeContext)
  const isLeaved = useSelector(isLeavedSelector)
  const ownName = useSelector((state: RootState) => state.my.ownName)
  const iframe = `<div
        class="formrun-embed"
        data-formrun-form="${props.surveyForm}"
        >
      </div>`
  const HTMLComponent = () => {
    const divRef = useRef<HTMLDivElement>(null)
    useLayoutEffect(() => {
      if (!divRef.current) {
        return
      }

      const fragment = document.createRange().createContextualFragment(iframe)
      divRef.current.appendChild(fragment)
    }, [])

    return divRef
  }

  const renderReEntry = (): React.ReactElement | null => {
    if (!isLeaved) return null
    if (!ownName) return null
    const onReEntryClick = () => {
      reEntry({ ownName })
    }

    return (
      <ReEntryWrapper>
        <Txt size="l">再度参加される場合は</Txt>
        <ReEntryButton onClick={onReEntryClick}>
          <PrimaryLinkTxt size="l">こちら</PrimaryLinkTxt>
        </ReEntryButton>
      </ReEntryWrapper>
    )
  }

  return (
    <Wrapper paddingBottom={responsive.pc ? '0px' : '90px'}>
      <Txt size={responsive.pc ? 'xxl' : 'l'} bold>
        お疲れ様でした。終了しました。
      </Txt>
      {renderReEntry()}
      {props.surveyForm && <IframeWrapper isPc={responsive.pc} ref={HTMLComponent()} />}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ paddingBottom: string }>`
  display: flex;
  width: 100%;
  align-self: center;
  flex-direction: column;
  align-items: center;
  padding: 40px 0px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`
const ReEntryWrapper = styled.div`
  display: flex;
  margin-top: 8px;
`
const ReEntryButton = styled.button`
  ${buttonResetStyle};
`
const IframeWrapper = styled.div<{ isPc: boolean }>`
  width: ${({ isPc }) => (isPc ? 640 : 320)}px;
`
