import React from 'react'
import { NotFoundPage } from '@blue-agency/im-shared-front'
import { Switch, Route } from 'react-router-dom'
import { history } from '@/historyModule'
import { useAuthorizedTracking } from '@/shared/hooks/useTracking'
import { InterviewMinutesPage } from '../pages/InterviewMinutesPage'
import { InterviewPage } from '../pages/InterviewPage'
import { PocOfflineInterviewPage } from '../pages/PocOfflineInterviewPage'
import { BIZ_INTERNAL_PATHS } from '../services/urlService'

export const AuthorizedRoutes: React.VFC = React.memo(() => {
  useAuthorizedTracking(history)
  return (
    <Switch>
      <Route exact path={BIZ_INTERNAL_PATHS.interview} component={InterviewPage} />
      <Route exact path={BIZ_INTERNAL_PATHS.pocOfflineInterview} component={PocOfflineInterviewPage} />
      <Route exact path={BIZ_INTERNAL_PATHS.interviewMinutes} component={InterviewMinutesPage} />
      <Route component={() => <NotFoundPage showDashboardLink />} />
    </Switch>
  )
})
