import React, { useMemo } from 'react'
import { Dropdown, DropdownOption, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  cameraDevices: readonly MediaDeviceInfo[]
  selectedCameraDeviceId: string | undefined
  onChangeDevice: (deviceId: string) => void
}

export const ChangeCameraDropdownOnBeforeEntered: React.VFC<Props> = (props) => {
  const options = useMemo<DropdownOption[]>(() => {
    return props.cameraDevices.map((d) => ({
      label: d.label,
      value: d.deviceId,
    }))
  }, [props.cameraDevices])

  return (
    <Wrapper>
      <TxtWrapper>
        <Txt bold>カメラ</Txt>
      </TxtWrapper>
      <DropdownWrapper>
        <Dropdown
          size="max"
          value={props.selectedCameraDeviceId}
          options={options}
          onChange={(e) => {
            props.onChangeDevice(e.target.value)
          }}
        />
      </DropdownWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const TxtWrapper = styled.div`
  width: 80px;
  margin-bottom: 8px;
`

const DropdownWrapper = styled.div`
  margin-bottom: 8px;
  flex: 1;
`
