import { useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { IntervieweeLogType } from '@blue-agency/proton/web/v2/im'
import invariant from 'tiny-invariant'
import { InterviewPageContainer } from '../../../containers/InterviewPageContainer'

function useManualModal() {
  const { sendIntervieweeLog } = InterviewPageContainer.useContainer()
  invariant(sendIntervieweeLog)
  const [isManualModalShown, setIsManualModalShown] = useState(false)

  const handleManualModalOpen = useCallback(() => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_OPEN_USAGE)
    setIsManualModalShown(true)
  }, [sendIntervieweeLog])
  const handleManualModalClose = useCallback(() => {
    setIsManualModalShown(false)
  }, [])

  return {
    isManualModalShown,
    handleManualModalOpen,
    handleManualModalClose,
  }
}

export const ManualModalContainer = createContainer(useManualModal)
