import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetParticipantRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

type Args = {
  interviewGuid: string
  soraClientId: string
}

export function useRequestGetParticipant() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetParticipant = useCallback(
    async (args: Args) => {
      const metadata = await buildMetadata()

      const req = new GetParticipantRequest()
      req.setInterviewGuid(args.interviewGuid)
      req.setSoraClientId(args.soraClientId)

      return callBff('getParticipant', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestGetParticipant }
}
