import React from 'react'
import { theme, Toggle, Txt, useIsIPad } from '@blue-agency/rogue'
import styled from 'styled-components'
import { isSupportedLightAdjustment } from '@/lib/interview-sdk-js'

// rogueにSliderが無いので一旦level=0.5固定で実装
type Props = {
  enabledLightAdjustment: boolean
  onChangeLightAdjustment: (enabled: boolean) => void
  sendTurnOnLightAdjustment?: () => void
  sendTurnOffLightAdjustment?: () => void
  sendChangeLightAdjustmentLevel?: () => void
}

export const ChangeLightAdjustmentOnBeforeEntered: React.VFC<Props> = (props) => {
  const { isIPad } = useIsIPad()

  if (!isSupportedLightAdjustment() || isIPad) {
    return null
  }

  const onChangeLightAdjustmentWrap = (enabled: boolean) => {
    if (props.sendTurnOnLightAdjustment && props.sendTurnOffLightAdjustment) {
      if (enabled) {
        props.sendTurnOnLightAdjustment()
      } else {
        props.sendTurnOffLightAdjustment()
      }
    }
    props.onChangeLightAdjustment(enabled)
  }

  return (
    <Wrapper>
      <Control>
        <ToggleWrapper>
          <Toggle checked={props.enabledLightAdjustment} onChange={onChangeLightAdjustmentWrap} />
        </ToggleWrapper>
        <Title>
          <Txt bold>映像を明るく</Txt>
        </Title>
      </Control>
      <Description>
        <Txt>自分の映像を明るくします</Txt>
      </Description>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${theme.color.gray[5]};
  padding: 8px 7px 11px;
`

const Control = styled.div`
  display: flex;
  align-items: center;
`

const ToggleWrapper = styled.div``

const Title = styled.div`
  margin-left: 15px;
`

const Description = styled.div`
  margin-top: 1px;
  margin-left: 53px;
`
