import React from 'react'
import { InPageChatBot } from '@blue-agency/im-shared-front'
import { Provider, useSelector } from 'react-redux'
import { WindowSizeContainer } from '@/shared/containers/WindowSizeContainer'
import { BeforeEntered } from './BeforeEntered'
import { Finished } from './Finished'
import { InRoom } from './InRoom'
import { InterviewContainer } from './containers/InterviewContainer'
import { RootState, store } from './redux/store'

export const Interview: React.VFC = React.memo(() => {
  return (
    <Provider store={store}>
      <WindowSizeContainer.Provider>
        <InterviewContainer.Provider>
          <Inner />
        </InterviewContainer.Provider>
      </WindowSizeContainer.Provider>
    </Provider>
  )
})

export const Inner: React.VFC = React.memo(() => {
  const interviewState = useSelector((state: RootState) => state.my.interviewState)
  const ownState = useSelector((state: RootState) => state.my.ownState)

  // TODO: interviewState と ownState を分けるときに整理する
  if (interviewState === 'Finished') {
    return (
      <>
        <InPageChatBot botType="my" />
        <Finished />
      </>
    )
  }

  switch (ownState) {
    case 'BeforeEntered':
      return (
        <>
          <InPageChatBot botType="my" />
          <BeforeEntered />
        </>
      )
    case 'InRoom':
      return <InRoom />
    case 'Leaved':
      return <Finished />
  }
})
