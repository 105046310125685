import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { sharedStoredSlice, SharedState } from '@/shared/services/interviewService/redux'
import { BackgroundEffect } from '@/shared/services/interviewService/types'

export const useBackgroundEffect = () => {
  const dispatch = useDispatch()
  const backgroundEffect = useSelector((state: SharedState) => state.sharedStored.backgroundEffect)
  const backgroundUserImage = useSelector((state: SharedState) => state.sharedStored.backgroundUserImage)

  const changeBackgroundEffect = useCallback(
    (backgroundEffect: BackgroundEffect) => {
      dispatch(sharedStoredSlice.actions.changeBackgroundEffect({ backgroundEffect: backgroundEffect }))
    },
    [dispatch]
  )

  const saveBackgroundUserImage = useCallback(
    (uri: string | undefined) => {
      dispatch(sharedStoredSlice.actions.saveBackgroundUserImage({ backgroundUserImage: uri }))
    },
    [dispatch]
  )

  return {
    backgroundEffect,
    backgroundUserImage,
    changeBackgroundEffect,
    saveBackgroundUserImage,
  }
}
