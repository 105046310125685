import { useCallback, useEffect, useState } from 'react'
import { theme } from '@blue-agency/rogue'
import resizeHeightIconImage from './resize_height_icon.svg'
import resizeWidthIconImage from './resize_width_icon.svg'

export function LeftSectionResizer(props: { handleResize: (arg: number) => void }) {
  const [mouseHovered, setMouseHovered] = useState<boolean>(false)
  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault()
        props.handleResize(e.movementX)
      }
    },
    [isDragging, props]
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    if (!isDragging) return
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '20px',
        height: '100%',
        position: 'absolute',
        right: -10,
        cursor: `url(${resizeWidthIconImage}) 5 15, col-resize`,
      }}
      onMouseEnter={() => setMouseHovered(true)}
      onMouseLeave={() => setMouseHovered(false)}
      onMouseDown={handleMouseDown}
    >
      <div
        style={{
          width: '4px',
          height: '100%',
          ...(mouseHovered && { backgroundColor: theme.color.green[4] }),
        }}
      />
    </div>
  )
}

export function InterviewMinutesResizer(props: { handleResize: (arg: number) => void }) {
  const [mouseHovered, setMouseHovered] = useState<boolean>(false)

  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault()
        props.handleResize(e.movementY)
      }
    },
    [isDragging, props]
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    if (!isDragging) return
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        position: 'absolute',
        bottom: -20,
        cursor: `url(${resizeHeightIconImage}) 20 20, col-resize`,
      }}
      onMouseEnter={() => setMouseHovered(true)}
      onMouseLeave={() => setMouseHovered(false)}
      onMouseDown={handleMouseDown}
    >
      <div
        style={{
          width: '100%',
          height: '4px',
          ...(mouseHovered && { backgroundColor: theme.color.green[4] }),
        }}
      />
    </div>
  )
}

export function VideoAreaResizer(props: { handleResize: (arg: number) => void }) {
  const [mouseHovered, setMouseHovered] = useState<boolean>(false)

  const [isDragging, setIsDragging] = useState(false)

  const handleMouseDown = useCallback(() => {
    setIsDragging(true)
  }, [])

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (isDragging) {
        e.preventDefault()
        props.handleResize(e.movementY)
      }
    },
    [isDragging, props]
  )

  const handleMouseUp = useCallback(() => {
    setIsDragging(false)
  }, [])

  useEffect(() => {
    if (!isDragging) return
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isDragging, handleMouseMove, handleMouseUp])

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '40px',
        position: 'absolute',
        bottom: -40,
        cursor: `url(${resizeHeightIconImage}) 20 20, col-resize`,
      }}
      onMouseEnter={() => setMouseHovered(true)}
      onMouseLeave={() => setMouseHovered(false)}
      onMouseDown={handleMouseDown}
    >
      <div
        style={{
          width: '100%',
          height: '4px',
          ...(mouseHovered && { backgroundColor: theme.color.green[4] }),
        }}
      />
    </div>
  )
}
