import React from 'react'
import { Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Dots } from '@/shared/components/Dots'
import { BackButton, CloseButton } from '@/shared/components/ManualModal/Button'
import { CloseBrowserIcon, WifiIcon, ReloadIcon } from '@/shared/components/ManualModal/IconMobile'
import {
  Wrapper,
  Upper,
  Row,
  HeaderWrapper,
  IconWrapper,
  TextWrapper,
  BottomWrapper,
  DotsWrapper,
  Bottom,
} from '@/shared/components/ManualModal/LayoutMobile'
import { ManualModalContainer } from '../containers/ManualModalContainer'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName

export const SecondMobile = ({ className }: Props): React.ReactElement => {
  const { handleManualModalClose } = ManualModalContainer.useContainer()
  const { handleBack } = StepContainer.useContainer()

  return (
    <Wrapper className={className}>
      <Upper>
        <Row>
          <HeaderWrapper>
            <IconWrapper>
              <CloseBrowserIcon />
            </IconWrapper>
            <div style={{ flex: 1 }}>
              <Txt size="l">不要なアプリ、タブを閉じる</Txt>
            </div>
          </HeaderWrapper>
          <TextWrapper>
            <Txt>
              バックグラウンドで動作しているアプリなどの影響で、音声や映像が起動しないことがあります。端末を再起動するなど、不要なアプリは閉じてからご利用ください。
            </Txt>
          </TextWrapper>
        </Row>
        <Row>
          <HeaderWrapper>
            <IconWrapper>
              <WifiIcon unstableLevel={0} />
            </IconWrapper>
            <div style={{ flex: 1 }}>
              <Txt size="l">安定した通信環境</Txt>
            </div>
          </HeaderWrapper>
          <TextWrapper>
            <Txt>
              学校内のWi-Fi など公衆のWi-Fi
              は、接続が途中で不安定になったり、音声や映像の品質が低下することがございます。
              <br />
              4G回線や強いWi-Fi 環境にて、ご利用ください。
            </Txt>
          </TextWrapper>
        </Row>
        <Row>
          <HeaderWrapper>
            <IconWrapper>
              <ReloadIcon />
            </IconWrapper>
            <div style={{ flex: 1 }}>
              <Txt size="l">ブラウザの再読込</Txt>
            </div>
          </HeaderWrapper>
          <TextWrapper>
            <Txt>
              音声/映像が不安定な場合にお試しください。
              <br />
              接続状況が改善する場合があります。
            </Txt>
          </TextWrapper>
        </Row>
      </Upper>
      <BottomWrapper>
        <WeakColorSeparator />
        <DotsWrapper>
          <Dots count={2} currentPage={2} />
        </DotsWrapper>
        <Bottom>
          <BackButton
            comlinkPushParams={{
              // PCの方と、biz側との重複は想定内
              // eslint-disable-next-line @blue-agency/react/unique-action-name
              action: 'click_back_button_on_waiting_room_manual',
            }}
            onClick={handleBack}
          />
          <CloseButton
            comlinkPushParams={{
              // PCの方と、biz側との重複は想定内
              // eslint-disable-next-line @blue-agency/react/unique-action-name
              action: 'click_close_button_on_waiting_room_manual',
            }}
            onClick={handleManualModalClose}
          />
        </Bottom>
      </BottomWrapper>
    </Wrapper>
  )
}
