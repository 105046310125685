import { buildErrorByGrpcError, GrpcError } from '@blue-agency/im-shared-front'
import { BizSkywalkerBffServicePromiseClient } from '@blue-agency/proton/biz_skywalker_bff'
import { MySkywalkerBffServiceClient } from '@blue-agency/proton/my_skywalker_bff'
import asyncRetry from 'async-retry'
import * as grpcWeb from 'grpc-web'

const retryCount = 5

type RpcName = keyof BizSkywalkerBffServicePromiseClient | keyof MySkywalkerBffServiceClient

export const callWithRetry = <T>(rpcName: RpcName, request: () => Promise<T>) => {
  const retryFn = async (bail: (e: Error) => void) => {
    return await request().catch((err) => {
      const { shouldRetry } = checkRetryError(rpcName, err)
      const customErr = buildErrorByGrpcError(err)
      if (!shouldRetry) {
        return bail(customErr) as never
      }
      throw customErr
    })
  }

  return asyncRetry(retryFn, { retries: retryCount })
}

const checkRetryError: (rpcName: RpcName, err: GrpcError) => { shouldRetry: boolean } = (rpcName, err) => {
  // FIXME: 一時的な対応. 適切なStatusCodeかerrorDetailsをbffから返してリトライ判定する
  // ref: https://blue-agency.atlassian.net/browse/PIM-709
  if (rpcName === 'getParticipant' && err.code === grpcWeb.StatusCode.NOT_FOUND) {
    return { shouldRetry: true }
  }

  switch (err.code) {
    case grpcWeb.StatusCode.INVALID_ARGUMENT:
    case grpcWeb.StatusCode.NOT_FOUND:
    case grpcWeb.StatusCode.PERMISSION_DENIED:
    case grpcWeb.StatusCode.UNAUTHENTICATED:
    case grpcWeb.StatusCode.RESOURCE_EXHAUSTED:
    case grpcWeb.StatusCode.FAILED_PRECONDITION:
    case grpcWeb.StatusCode.UNIMPLEMENTED:
      return { shouldRetry: false }
    default:
      return { shouldRetry: true }
  }
}
