import { useEffect, useRef } from 'react'
import delay from 'delay'
import { useMountedState } from 'react-use'

type Callback = () => Promise<void>

/**
 * 処理が完了して指定時間後に処理を実行
 */
export const useContinuousTimeout = (callback: Callback, delayMs: number) => {
  const isMouned = useMountedState()
  const savedCallback = useRef<Callback>(async () => {})
  // eslint-disable-next-line @blue-agency/react/useEffect-requires-deps
  useEffect(() => {
    savedCallback.current = async () => {
      if (isMouned()) {
        await delay(delayMs)
        await callback()

        await savedCallback.current()
      }
    }
  })
  useEffect(() => {
    savedCallback.current()
  }, [delayMs])
}
