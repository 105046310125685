import { comlinkPush, CustomGrpcError, GrpcError } from '@blue-agency/im-shared-front'

export const logRpcErr = (rpcName: string, err: GrpcError, loggedInStaffGuid: string | undefined) => {
  const customErr = new CustomGrpcError(err)

  const metadata: Record<string, string> = {
    name: rpcName,
    message: customErr.message ?? '',
    metadata: JSON.stringify(customErr.metadata) ?? '',
    code: customErr.code.toString() ?? '',
  }

  if (loggedInStaffGuid) {
    metadata['loggedInStaffGuid'] = loggedInStaffGuid
  }

  comlinkPush({
    action: 'rpc_error',
    metadata,
  })
}
