import { CheckBrowserFunction, CheckBrowserResult } from '@blue-agency/react-environment-check'
import * as semver from 'semver'
import { UAParser } from 'ua-parser-js'
import { isWindows, isMac, isIOS, isIPadOS, isAndroid, shouldValidateIPadOSBySafariSemver } from './browser'

// チェックの基準はこの記事に定義されている
// https://stadium.kibe.la/notes/13366

type Messages = {
  notRecommendedStrongly: string
  unsupportedChromeVersion: string
  unsupportedFirefoxVersion: string
  unsupportedEdgeVersion: string
  unsupportedBrowser: string
  unsupportedIOSBrowser: string
  notRecommendedIOSVersion: string
  unsupportedIOSVersion: string
  unsupportedIpadOSBrowser: string
  notRecommendedIpadOSVersion: string
  unsupportedIpadOSVersion: string
  unsupportedAndroidBrowser: string
  notRecommendedBrowser: string
}

export const defaultMessages: Messages = {
  notRecommendedStrongly: 'この端末は、推奨端末ではありません。\n別の端末をご利用ください。',
  unsupportedChromeVersion: '最新のGoogle Chromeにアップデートしてください。',
  unsupportedFirefoxVersion: '最新のFirefoxにアップデートしてください。',
  unsupportedEdgeVersion: '最新のMicrosoft Edgeにアップデートしてください。',
  unsupportedBrowser: 'お使いのOS、ブラウザはご利用いただけません。',
  unsupportedIOSBrowser: 'こちらのブラウザは利用できません。\nSafariからページを開きなおしてください。',
  notRecommendedIOSVersion:
    'OSが最新版ではありません。\n音声や映像に問題が生じることがありますので、OSをアップデートしてから開始してください。',
  unsupportedIOSVersion: 'OSが最新版でないためご利用できません。\nOSをアップデートしてください。',
  unsupportedIpadOSBrowser: 'こちらのブラウザは利用できません。\nSafariからページを開きなおしてください。',
  notRecommendedIpadOSVersion:
    'OSが最新版ではありません。\n音声や映像に問題が生じることがありますので、OSをアップデートしてから開始してください。',
  unsupportedIpadOSVersion: 'OSが最新版でないためご利用できません。\nOSをアップデートしてください。',
  unsupportedAndroidBrowser: 'こちらのブラウザは利用できません。\nGoogle Chromeをご利用ください。',
  notRecommendedBrowser: 'このブラウザは、推奨ブラウザではありません。\n音声や映像に問題が生じることがあります。',
}

export const getCheckBrowser =
  (messages: Messages): CheckBrowserFunction =>
  (ua) => {
    if (ua === '') {
      return {
        type: 'notRecommendedStrongly',
        message: messages.notRecommendedStrongly,
      }
    }

    const uaParser = new UAParser(ua)
    const uaResult = uaParser.getResult()

    if (isWindows(uaResult)) {
      return checkWindows(uaResult, messages)
    }

    if (isMac(uaResult)) {
      return checkMac(uaResult, messages)
    }

    if (isIOS(uaResult)) {
      return checkIOS(uaResult, messages)
    }

    if (isIPadOS(uaResult)) {
      return checkIPadOS(uaResult, messages)
    }

    if (isAndroid(uaResult)) {
      return checkAndroid(uaResult, messages)
    }

    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

export const checkBrowserDefault: CheckBrowserFunction = getCheckBrowser(defaultMessages)

/*
  iOS バージョンの文字列として、`12.0` といったように、セマンティックバージョニングで言うところの
  パッチバージョンがないケースがある
  complementPatchVersion では、バージョン比較しやすいようにパッチバージョンを補完して返す
 */
function complementPatchVersion(version: string): string | undefined {
  if (semver.valid(version)) {
    return version
  }

  const versionDotCount = (version.match(/\./g) || []).length

  if (versionDotCount === 1) {
    const ver = version + '.0'

    if (semver.valid(ver)) {
      return ver
    }
  }

  return undefined
}

function checkWindows(ua: UAParser.IResult, messages: Messages): CheckBrowserResult {
  if (!ua.browser.version) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  const majorVersion = parseInt(ua.browser.version, 10)

  if (ua.browser.name === 'Chrome') {
    if (majorVersion >= 71) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedChromeVersion,
      }
    }
  }

  if (ua.browser.name === 'Firefox') {
    if (majorVersion >= 78) {
      return {
        type: 'notRecommended',
        message: messages.notRecommendedBrowser,
      }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedFirefoxVersion,
      }
    }
  }

  if (ua.browser.name === 'Edge') {
    if (majorVersion >= 79) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedEdgeVersion,
      }
    }
  }

  if (ua.browser.name === 'IE') {
    return {
      type: 'unsupported',
      message: messages.unsupportedBrowser,
    }
  }

  return {
    type: 'notRecommendedStrongly',
    message: messages.notRecommendedStrongly,
  }
}

function checkMac(ua: UAParser.IResult, messages: Messages): CheckBrowserResult {
  if (!ua.browser.version) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  const majorVersion = parseInt(ua.browser.version, 10)

  if (ua.browser.name === 'Chrome') {
    if (majorVersion >= 71) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedChromeVersion,
      }
    }
  }

  if (ua.browser.name === 'Firefox') {
    if (majorVersion >= 78) {
      return {
        type: 'notRecommended',
        message: messages.notRecommendedBrowser,
      }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedFirefoxVersion,
      }
    }
  }

  if (ua.browser.name === 'Edge') {
    if (majorVersion >= 79) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedEdgeVersion,
      }
    }
  }

  if (ua.browser.name === 'Safari') {
    return { type: 'valid', message: '' }
  }

  return {
    type: 'notRecommendedStrongly',
    message: messages.notRecommendedStrongly,
  }
}

function checkIOS(ua: UAParser.IResult, messages: Messages): CheckBrowserResult {
  if (!ua.os.version) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  if (ua.browser.name !== 'Mobile Safari') {
    return {
      type: 'unsupported',
      message: messages.unsupportedIOSBrowser,
    }
  }

  const iOSSemver = complementPatchVersion(ua.os.version)
  if (iOSSemver === undefined) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  if (semver.gte(iOSSemver, '14.3.0')) {
    return {
      type: 'valid',
      message: '',
    }
  }

  if (semver.lt(iOSSemver, '14.3.0') && semver.gte(iOSSemver, '13.0.0')) {
    return {
      type: 'notRecommended',
      message: messages.notRecommendedIOSVersion,
    }
  }

  if (semver.lt(iOSSemver, '13.0.0')) {
    return {
      type: 'unsupported',
      message: messages.unsupportedIOSVersion,
    }
  }

  return {
    type: 'notRecommendedStrongly',
    message: messages.notRecommendedStrongly,
  }
}

function checkIPadOS(ua: UAParser.IResult, messages: Messages): CheckBrowserResult {
  if (!ua.os.version) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  if (ua.browser.name !== 'Mobile Safari' && ua.browser.name !== 'Safari') {
    return {
      type: 'unsupported',
      message: messages.unsupportedIpadOSBrowser,
    }
  }

  // Safariのバージョンを見てバリデーションする場合、以下の対応表を参考にiPadOSのバージョンとマッピングする
  // https://qiita.com/yoshitake_1201/items/05a13fd77c18ff380eb6
  if (shouldValidateIPadOSBySafariSemver(ua)) {
    if (!ua.browser.version) {
      return {
        type: 'notRecommendedStrongly',
        message: messages.notRecommendedStrongly,
      }
    }

    const safariSemver = complementPatchVersion(ua.browser.version)
    if (safariSemver === undefined) {
      return {
        type: 'notRecommendedStrongly',
        message: messages.notRecommendedStrongly,
      }
    }

    if (semver.gte(safariSemver, '14.0.2')) {
      return {
        type: 'valid',
        message: '',
      }
    }

    if (semver.lt(safariSemver, '14.0.2') && semver.gte(safariSemver, '13.0.3')) {
      return {
        type: 'notRecommended',
        message: messages.notRecommendedIpadOSVersion,
      }
    }

    return {
      type: 'unsupported',
      message: messages.unsupportedIpadOSVersion,
    }
  }

  const iPadOSSemver = complementPatchVersion(ua.os.version)
  if (iPadOSSemver === undefined) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  if (semver.gte(iPadOSSemver, '14.3.0')) {
    return {
      type: 'valid',
      message: '',
    }
  }

  if (semver.lt(iPadOSSemver, '14.3.0') && semver.gte(iPadOSSemver, '13.2.2')) {
    return {
      type: 'notRecommended',
      message: messages.notRecommendedIpadOSVersion,
    }
  }

  return {
    type: 'unsupported',
    message: messages.unsupportedIpadOSVersion,
  }
}

function checkAndroid(ua: UAParser.IResult, messages: Messages): CheckBrowserResult {
  if (!ua.browser.version) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  const isPixel3 = ua.device.model?.includes('Pixel 3') ?? false

  if (isPixel3) {
    return {
      type: 'notRecommendedStrongly',
      message: messages.notRecommendedStrongly,
    }
  }

  const majorVersion = parseInt(ua.browser.version, 10)

  if (ua.browser.name === 'Chrome' || ua.browser.name === 'Chrome WebView') {
    if (majorVersion >= 71) {
      return { type: 'valid', message: '' }
    } else {
      return {
        type: 'unsupported',
        message: messages.unsupportedChromeVersion,
      }
    }
  }

  return {
    type: 'unsupported',
    message: messages.unsupportedAndroidBrowser,
  }
}
