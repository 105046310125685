import React, { useMemo, useState } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import {
  CreateImAssistantQuestionsRequest,
  CreateImAssistantQuestionsResponse,
} from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import { Icon, theme, InputText, Dropdown, DropdownOption, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { Rnd } from 'react-rnd'
import styled from 'styled-components'
import { BizInterviewImAssistantQuestionContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewImAssistantQuestion'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'
import assistantImage from '../../assets/im-assistant.svg'
import { InterviewAutoQuestionContainer } from '../../containers/InterviewAutoQuestionContainer'

type Props = {
  boundsClass: string
  hasGuid: boolean
}

export const AutoQuestionGptModal: React.VFC<Props> = (props) => {
  const { createImAssistantQuestion } = BizInterviewImAssistantQuestionContainer.useContainer()

  const [questionType, seQuestionType] = useState<CreateImAssistantQuestionsRequest.QuestionType>(
    CreateImAssistantQuestionsRequest.QuestionType.QUESTION_TYPE_SENSE_OF_COMMUNICATING
  )
  const [isRequesting, setIsRequesting] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [inputQuestion, setInputQuestion] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [questions, setQuestions] = useState<CreateImAssistantQuestionsResponse.Question[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const questionCategories: DropdownOption[] = [
    {
      label: 'コミュニケーション力（伝える力）',
      value: CreateImAssistantQuestionsRequest.QuestionType.QUESTION_TYPE_SENSE_OF_COMMUNICATING,
    },
    {
      label: 'コミュニケーション力（聞く力）',
      value: CreateImAssistantQuestionsRequest.QuestionType.QUESTION_TYPE_SENSE_OF_LISTENING,
    },
    {
      label: '主体性',
      value: CreateImAssistantQuestionsRequest.QuestionType.QUESTION_TYPE_INDIVIDUALITY,
    },
    {
      label: 'チャレンジ精神',
      value: CreateImAssistantQuestionsRequest.QuestionType.QUESTION_TYPE_CHALLENGING_SPIRIT,
    },
    {
      label: '論理的思考力',
      value: CreateImAssistantQuestionsRequest.QuestionType.QUESTION_TYPE_LOGICAL_THINKING,
    },
  ]

  const options = useMemo<DropdownOption[]>(() => {
    return questionCategories.map((category) => ({
      label: category.label,
      value: category.value,
    }))
  }, [questionCategories])

  const onChangeQuestionType = (questionType: string) => {
    const currentQuestion = Object.values(CreateImAssistantQuestionsRequest.QuestionType).find(
      (value) => String(value) === questionType
    ) as CreateImAssistantQuestionsRequest.QuestionType

    if (!currentQuestion) {
      return
    }

    seQuestionType(currentQuestion)
  }

  const onChangeQuestion = (value: string) => {
    if (value.length >= 500) {
      // MEMO: 長文を入れた後、Submitでエラー表示されると辛い為、テキスト入力中に見せておきたい。
      setErrorMessage('500文字以内で入力してください')
    } else {
      setErrorMessage('')
      setInputQuestion(value)
    }
  }

  const onSubmit = async () => {
    comlinkPush({
      action: 'click_send_create_im_assistant_question',
    })
    if (inputQuestion.length === 0) {
      // MEMO: onChangeで判定すると、質問を入れ直す度にエラーが出て、使い勝手が悪くなる。
      setErrorMessage('入力してください')
    } else {
      setIsRequesting(true)
      setErrorMessage('')
      const res = await createImAssistantQuestion(questionType, inputQuestion)

      if (res.questions) {
        setQuestions(res.questions)
        setIsError(false)
        setIsRequesting(false)
      }

      if (res.error) {
        setIsError(true)
        setIsRequesting(false)
      }
    }
  }

  // MEMO: ガイドがない時は左上に配置する。ガイドがある時は、ガイドに重ならない様に表示する。
  const x = useMemo(() => (props.hasGuid ? 28 : 8), [props.hasGuid])
  const y = useMemo(() => (props.hasGuid ? 102 : 8), [props.hasGuid])

  const { closenInterviewAutoQuestionArea: close } = InterviewAutoQuestionContainer.useContainer()

  const BottomRightHandle = () => (
    <ResizeControlWrapper>
      <ResizeControl>
        <ResizableIcon />
      </ResizeControl>
    </ResizeControlWrapper>
  )

  return (
    <Rnd
      default={{
        x,
        y,
        width: 460,
        height: 396,
      }}
      dragHandleClassName="header"
      bounds={props.boundsClass}
      minWidth="320px"
      minHeight="200px"
      resizeHandleComponent={{ bottomRight: <BottomRightHandle /> }}
    >
      <DraggableContent>
        <Header className="header">
          <HeaderTitle>
            <InterviewAiAubbleIcon size="m" />
            <Txt color={theme.color.white[1]} bold>
              im Assistant （β版）
            </Txt>
          </HeaderTitle>
          <HeaderControls>
            <DraggableIcon />
            <ControlsSeparator />
            {/* ios chrome and safariにてクリックできるようにする https://github.com/bokuweb/react-rnd/issues/733#issuecomment-924397205 */}
            <IconButton onClick={close} onGotPointerCapture={close}>
              <CloseIcon />
            </IconButton>
          </HeaderControls>
        </Header>
        <Body>
          <DropdowntWrapper>
            <Dropdown
              value={questionType}
              options={options}
              size="l"
              onChange={(e) => {
                onChangeQuestionType(e.target.value)
              }}
            />
          </DropdowntWrapper>
          <QuestionInputWrapper>
            <InputText
              type="textarea"
              placeholder="「飲食店アルバイト リーダー 」「不動産営業 3年」など&#13;&#10;応募者に関する情報を入力してください（500文字まで）"
              disabled={isRequesting}
              autoComplete="off"
              size="l"
              value={inputQuestion}
              onChange={(e) => {
                onChangeQuestion(e.target.value)
              }}
              onBlurError={errorMessage}
            />
            <PrimaryButton
              comlinkPushParams={{}}
              onClick={() => {
                onSubmit()
              }}
              widthSize="S"
              disabled={isRequesting}
            >
              送信
            </PrimaryButton>
          </QuestionInputWrapper>
          <GuideParts>
            <AssistantAnswer>
              <img src={assistantImage} alt="" width="60" height="60" />
              <AnswerContent>
                {isRequesting ? (
                  <AnswerItem>
                    <Txt size="m">質問内容を考えています...</Txt>
                    <Txt size="m">1分程度お時間をいただきます...</Txt>
                  </AnswerItem>
                ) : isError ? (
                  <AnswerItem>
                    <Txt size="m">
                      質問をうまく考え出すことができませんでした。 <br />
                      少し時間を空けるか、入力内容を変更して 再度送信してください。
                    </Txt>
                  </AnswerItem>
                ) : questions.length > 0 ? (
                  <>
                    {questions.map((question, i) => (
                      <AnswerItem key={i}>
                        <Txt size="m" bold>
                          {i + 1}.&nbsp;{question.getText()}
                        </Txt>
                        <Txt size="m">{question.getComment()}</Txt>
                      </AnswerItem>
                    ))}
                  </>
                ) : (
                  <>
                    <AnswerItem>
                      <Txt size="m">
                        こんにちは、im Assistantです!
                        <br />
                        皆さまの構造化面接の実現をサポートします。 <br />
                        「応募者にどのような質問をするべきか？」 困ったことはありませんか？
                      </Txt>
                    </AnswerItem>
                    <AnswerItem>
                      <Txt size="m">
                        2つ設定して送信するだけで <br />
                        応募者への質問内容をいくつかピックアップします 。
                      </Txt>
                    </AnswerItem>
                    <AnswerItem>
                      <Txt size="m">
                        ※本機能はOpenAI社の「ChatGPT API」を使用しています <br />
                        機能詳細は
                        <TxtLink href="https://im-cp.zendesk.com/hc/ja/articles/16693802258969" target="_blank">
                          こちらの記事
                        </TxtLink>
                        をご覧ください。
                      </Txt>
                    </AnswerItem>
                  </>
                )}
              </AnswerContent>
            </AssistantAnswer>
          </GuideParts>
        </Body>
      </DraggableContent>
    </Rnd>
  )
}

const AnswerItem = styled.div`
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

const TxtLink = styled.a`
  color: ${theme.color.blue[2]};
`
const AssistantAnswer = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 12px;
`
const AnswerContent = styled.div`
  background-color: ${theme.color.green[8]};
  padding: 8px 12px;
  color: ${theme.color.navy[1]};
`

const DraggableContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  background-color: ${theme.color.navy[1]};
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
`

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;

  *:not(:last-child) {
    margin-right: 6px;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;

  *:not(:last-child) {
    margin-right: 16px;
  }
`

const Body = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  opacity: 0.95;
  background-color: ${theme.color.white[1]};
  padding: 12px;
`

const GuideParts = styled.div`
  display: flex;
  flex-direction: column;
  ${overflowYScrollStyle};
`

const IconButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  color: ${theme.color.white[1]};
`

const InterviewAiAubbleIcon = styled(Icon).attrs({ name: 'ai-bubble' })`
  color: ${theme.color.white[1]};
`

const DraggableIcon = styled(Icon).attrs({ name: 'move-all' })`
  color: ${theme.color.white[1]};
`

const ResizableIcon = styled(Icon).attrs({ name: 'resize' })`
  color: ${theme.color.white[1]};
  width: 100%;
  height: 100%;
`

const ControlsSeparator = styled.div`
  background-color: ${theme.color.white[1]};
  height: 18px;
  width: 1px;
`

const ResizeControlWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ResizeControl = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 33px;
  height: 33px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
const DropdowntWrapper = styled.div`
  margin-bottom: 12px;
  select {
    width: 370px;
  }
`
const QuestionInputWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
  textarea {
    width: 370px;
  }
`
