import React from 'react'
import { useBroadcastMicMuted } from '@/lib/meetingcomponent/useBroadcastMicMuted'
import { useBroadcastNetworkUnstableLevel } from '@/lib/meetingcomponent/useBroadcastNetworkUnstableLevel'
import { useHandleDeviceChangeError } from '@/lib/meetingcomponent/useHandleDeviceChangeError'
import { useReceiveMicMuted } from '@/lib/meetingcomponent/useReceiveMicMuted'
import { useReceiveNetworkUnstableLevel } from '@/lib/meetingcomponent/useReceiveNetworkUnstableLevel'
import { useSendDeviceInfoToComlink } from '@/lib/meetingcomponent/useSendDeviceInfoToComlink'
import { useStartMeeting } from '@/lib/meetingcomponent/useStartMeeting'
import { useSubscribeMicMuted } from '@/lib/meetingcomponent/useSubscribeMicMuted'

export const Meeting: React.FC = (props) => {
  useHandleDeviceChangeError()
  useSendDeviceInfoToComlink()
  useReceiveMicMuted()
  useReceiveNetworkUnstableLevel()
  useSubscribeMicMuted()
  useStartMeeting()
  useBroadcastMicMuted()
  useBroadcastNetworkUnstableLevel()

  return <>{props.children}</>
}
