import { theme, Toggle, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { OldViewChangeInfoBalloon } from '@/shared/services/interviewService/components/OldViewChangeInfoBalloon'
import { MinutesViewContainer } from '@/shared/services/interviewService/containers/MinutesViewContainer'

export function MinutesButton() {
  const { isMinutesView, toggleMinutesView } = MinutesViewContainer.useContainer()
  return (
    <Wrapper>
      <OldViewChangeInfoBalloon basePosition={{ top: -85, left: 30 }} />
      <Toggle checked={isMinutesView} onChange={toggleMinutesView} />
      <Txt size="s" color={theme.color.navy[1]}>
        議事録ビュー
      </Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 8px;
  align-items: center;
`
