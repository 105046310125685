import { useCallback, useState } from 'react'
import { Comlink } from '@blue-agency/comlink'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateInterviewTranscriptionPinningRequest } from '@blue-agency/proton/biz_skywalker_bff'
import {
  ListInterviewTranscriptionsResponse,
  CountInterviewTranscriptionsResponse,
} from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import { alertToast, toast } from '@blue-agency/rogue'
import assert from 'assert'
import { useMount } from 'react-use'
import { useRequestCountInterviewTranscriptions } from '@/biz/services/bffService/useRequestCountInterviewTranscriptions'
import { useRequestCreateInterviewTranscriptionPinning } from '@/biz/services/bffService/useRequestCreateInterviewTranscriptionPinning'
import { useRequestDeleteInterviewTranscriptionPinning } from '@/biz/services/bffService/useRequestDeleteInterviewTranscriptionPinning'
import { useRequestListInterviewTranscriptions } from '@/biz/services/bffService/useRequestListInterviewTranscriptions'
import { useInterviewMinute } from '@/biz/services/interviewMinuteService/useInterviewMinute'
import type {
  ListInterviewTranscription,
  ListInterviewTranscriptionPin,
} from '@/biz/services/interviewMinuteService/useInterviewMinute'
import { Role } from '@/shared/services/interviewService/containers/InterviewContainer/enterRpcResponseType'

export const useTranscription = (interviewGuid: string) => {
  const loggedInStaff = useLoggedInStaff()
  assert(loggedInStaff)
  const [transcriptions, setTranscriptions] = useState<ListInterviewTranscription[] | undefined>(undefined)

  const { bindTranscriptionsOfSameSpeaker, replaceFillerMessage } = useInterviewMinute()

  const { requestListInterviewTranscriptions } = useRequestListInterviewTranscriptions()
  const { requestCountInterviewTranscriptions } = useRequestCountInterviewTranscriptions()
  const { requestCreateInterviewTranscriptionPinning } = useRequestCreateInterviewTranscriptionPinning()
  const { requestDeleteInterviewTranscriptionPinning } = useRequestDeleteInterviewTranscriptionPinning()

  const getListInterviewTranscriptions = useCallback(
    async (afterStartTime: number, transcriptionLimit: number): Promise<ListInterviewTranscriptionsResponse> => {
      return await requestListInterviewTranscriptions({ interviewGuid, afterStartTime, transcriptionLimit })
    },
    [interviewGuid, requestListInterviewTranscriptions]
  )

  const transcriptionCount = useCallback(async (): Promise<CountInterviewTranscriptionsResponse> => {
    return await requestCountInterviewTranscriptions({ interviewGuid })
  }, [interviewGuid, requestCountInterviewTranscriptions])

  useMount(async () => {
    const resCount = await transcriptionCount()
    if (resCount.getTranscriptionsCount() === 0) {
      setTranscriptions([])
      return
    }
    const resList = await getListInterviewTranscriptions(0, resCount.getTranscriptionsCount())
    const items = resList.getInterviewTranscriptionsList()
    setTranscriptions(
      bindTranscriptionsOfSameSpeaker(
        items.map((item): ListInterviewTranscription => {
          const pins: ListInterviewTranscriptionPin[] = item.getInterviewTranscriptionPinningsList()?.map((pin) => ({
            guid: pin.getGuid(),
            fullName: `${pin.getName()?.getFamilyName() ?? ''} ${pin.getName()?.getGivenName() ?? ''}`,
            pinType: pin.getType(),
            staffGuid: pin.getStaffGuid(),
          }))
          return {
            guid: item.getGuid(),
            soraClientId: item.getSoraClientId(),
            startTime: item.getStartTime(),
            endTime: item.getEndTime(),
            message: item.getInterviewTranscriptionSentence(),
            name: item.getParticipant()?.getName()?.slice(0, 2) ?? '',
            iconType: item.getRole() === Role.INTERVIEWER ? 'biz' : 'my',
            pins: pins,
          }
        })
      )
    )
  })

  const pushOwnPinToList = useCallback(
    (
      transcriptionGuid: string,
      transcriptionPinningGuid: string,
      pinType: ListInterviewTranscriptionsResponse.InterviewPinningType
    ) => {
      setTranscriptions((list) =>
        list?.map((item) => {
          if (item.guid !== transcriptionGuid) return item
          return {
            ...item,
            pins: [
              ...item.pins,
              {
                guid: transcriptionPinningGuid,
                fullName: `${loggedInStaff.name.familyName} ${loggedInStaff.name.givenName}`,
                pinType: pinType,
                staffGuid: loggedInStaff.guid,
              },
            ],
          }
        })
      )
    },
    [loggedInStaff]
  )

  const pushComlink = useCallback(
    (transcriptionSentenceGuid: string, action: string) => {
      Comlink.push({
        type: 'manual_activity',
        group: 'skywalker-front',
        action: action,
        metadata: {
          interviewGuid: interviewGuid,
          transcriptionSentenceGuid: transcriptionSentenceGuid,
        },
      })
    },
    [interviewGuid]
  )

  const handleCreateGoodPin = useCallback(
    async (transcriptionGuid: string) => {
      try {
        const res = await requestCreateInterviewTranscriptionPinning({
          interviewGuid: interviewGuid,
          interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType.GOOD,
          transcriptionSentenceGuid: transcriptionGuid,
        })
        pushComlink(transcriptionGuid, 'click_good_transcription_pined')
        pushOwnPinToList(
          transcriptionGuid,
          res.getGuid(),
          ListInterviewTranscriptionsResponse.InterviewPinningType.GOOD
        )
      } catch (err) {
        alertToast('ピンの入力に失敗しました')
      }
    },
    [interviewGuid, requestCreateInterviewTranscriptionPinning, pushComlink, pushOwnPinToList]
  )

  const handleCreateBadPin = useCallback(
    async (transcriptionGuid: string) => {
      try {
        const res = await requestCreateInterviewTranscriptionPinning({
          interviewGuid: interviewGuid,
          interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType.BAD,
          transcriptionSentenceGuid: transcriptionGuid,
        })
        pushComlink(transcriptionGuid, 'click_bad_transcription_pined')
        pushOwnPinToList(transcriptionGuid, res.getGuid(), ListInterviewTranscriptionsResponse.InterviewPinningType.BAD)
      } catch (err) {
        alertToast('ピンの入力に失敗しました')
        return
      }
    },
    [interviewGuid, requestCreateInterviewTranscriptionPinning, pushComlink, pushOwnPinToList]
  )

  const handleCreateQuestionPin = useCallback(
    async (transcriptionGuid: string) => {
      try {
        const res = await requestCreateInterviewTranscriptionPinning({
          interviewGuid: interviewGuid,
          interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType.QUESTION,
          transcriptionSentenceGuid: transcriptionGuid,
        })
        pushComlink(transcriptionGuid, 'click_question_transcription_pined')
        pushOwnPinToList(
          transcriptionGuid,
          res.getGuid(),
          ListInterviewTranscriptionsResponse.InterviewPinningType.QUESTION
        )
      } catch (err) {
        alertToast('ピンの入力に失敗しました')
        return
      }
    },
    [interviewGuid, requestCreateInterviewTranscriptionPinning, pushComlink, pushOwnPinToList]
  )

  const popDeletePinFromList = useCallback((transcriptionGuid: string, transcriptionPinningGuid: string) => {
    setTranscriptions((list) =>
      list?.map((item) => {
        if (item.guid !== transcriptionGuid) return item
        return {
          ...item,
          pins: item.pins.filter((pin) => pin.guid !== transcriptionPinningGuid),
        }
      })
    )
  }, [])

  const handleDeleteInterviewTranscriptionPinning = useCallback(
    async (transcriptionGuid: string, transcriptionPinningGuid: string) => {
      try {
        await requestDeleteInterviewTranscriptionPinning(transcriptionPinningGuid)
        toast('ピンを削除しました')
      } catch (err) {
        alertToast('ピンの削除に失敗しました。')
        return
      }
      popDeletePinFromList(transcriptionGuid, transcriptionPinningGuid)
    },
    [requestDeleteInterviewTranscriptionPinning, popDeletePinFromList]
  )

  return {
    transcriptions,
    replaceFillerMessage,
    handleCreateGoodPin,
    handleCreateBadPin,
    handleCreateQuestionPin,
    handleDeleteInterviewTranscriptionPinning,
  }
}
