import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { RejectEntryRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestRejectEntry() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestRejectEntry = async (interviewGuid: string, entryRequestGuid: string) => {
    const metadata = await buildMetadata()

    const req = new RejectEntryRequest()

    req.setInterviewGuid(interviewGuid)
    req.setEntryRequestGuid(entryRequestGuid)

    return callBff('rejectEntry', [req, metadata], loggedInStaff?.guid)
  }

  return { requestRejectEntry }
}
