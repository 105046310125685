import { useMemo } from 'react'
import { DataChannelConfiguration } from '@/lib/interview-sdk-js'
import { InterviewWebSocket } from '../../../hooks/useInterviewWebSocket'
import { useWebRTCHost } from '../../../hooks/useWebRTCHost'
import { SignalingPoint, SoraConnectionProps } from '../types'

export const useSoraConnectionProps = (
  signalingPoint: SignalingPoint,
  ws: InterviewWebSocket,
  sessionToken: string,
  invitationToken: string,
  dataChannels?: DataChannelConfiguration[]
) => {
  const webrtcHost = useWebRTCHost(signalingPoint.channelId, signalingPoint.webrtcHost, ws)
  const channelId = signalingPoint.channelId

  const publisherProps = useMemo<SoraConnectionProps>(() => {
    return {
      webrtcHost,
      channelId,
      sessionToken: sessionToken,
      invitationToken: invitationToken,
      dataChannels,
    }
  }, [invitationToken, sessionToken, channelId, webrtcHost, dataChannels])

  return publisherProps
}
