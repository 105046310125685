import React, { useCallback, useState } from 'react'
import { AuthContainer, PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { Txt, theme, A, Icon, PrimaryLinkTxt } from '@blue-agency/rogue'
import { SecondaryButton } from '@blue-agency/rogue/im'
import jwtDecode from 'jwt-decode'
import { useParams, generatePath } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { InterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { BIZ_EXTERNAL_URLS, BIZ_INTERNAL_PATHS } from '@/biz/services/urlService'
import { getBrowserClientUuid } from '@/shared/services/browserClientUuidService'
import { buttonResetStyle } from '@/shared/styles/buttonResetStyle'

type TokenDTO = {
  at_hash: string
  aud: string[]
  auth_time: number
  exp: number
  iat: number
  iss: string
  jti: string
  nonce: string
  oguid: string
  rat: number
  sid: string
  sub: string // staff_guid
}

type Props = {
  // 面接ルーム終了ではなく、自分だけ退室してきたか否かのフラグ
  leave?: boolean
}

export const ProductQualityQuestionnaire: React.VFC<Props> = ({ leave = false }) => {
  const { authClient } = AuthContainer.useContainer()
  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) throw new Error('invalid state')

  const { interviewGuid } = useParams<{ interviewGuid: string }>()

  const [starRating, setStarRating] = useState(0)

  const openSurveyLink = useCallback(async () => {
    const token = await authClient.getVerifiedToken()
    const decodedToken = jwtDecode<TokenDTO>(token)
    const staffGuid = decodedToken.sub
    const browserClientUuid = await getBrowserClientUuid()
    const params = `${pageCtx.interviewGuid}+${browserClientUuid}+im_web_interview+${staffGuid}`
    window.location.href = BIZ_EXTERNAL_URLS.survey + params
  }, [pageCtx.interviewGuid, authClient])

  const handleClickStar = useCallback(
    (rating: number) => () => {
      setStarRating(rating)
      openSurveyLink()
    },
    [openSurveyLink]
  )

  const onReEntryClick = useCallback(() => {
    window.location.reload()
  }, [])

  return (
    <PageLayoutWithGlonavi>
      <Wrapper>
        <MainMessage>
          <Txt size="l">お疲れ様でした。{leave ? '退室しました。' : '面接が終了しました。'}</Txt>
          {leave && (
            <Txt size="l">
              再度参加される場合は
              <ReEntryButton onClick={onReEntryClick}>
                <PrimaryLinkTxt size="l">こちら</PrimaryLinkTxt>
              </ReEntryButton>
            </Txt>
          )}
        </MainMessage>
        <Card>
          <RequestTxt size="l" bold color={theme.color.white[1]}>
            サービス品質向上のため、
            <br />
            ご意見をお聞かせください。
          </RequestTxt>
          <Estimation>
            <StarGroup>
              {[...Array(5)].map((_, index) => (
                <A
                  key={index}
                  onClick={handleClickStar(index + 1)}
                  comlinkPushParams={{
                    action: 'open_interviewer_survey_page',
                    metadata: {
                      starRating: (index + 1).toString(),
                    },
                  }}
                >
                  {starRating < index + 1 ? <EmptyStar name="star" /> : <FilledStar name="filled-star" />}
                </A>
              ))}
            </StarGroup>
            <TxtGroup>
              <Txt size="s" color={theme.color.white[1]}>
                とても悪い
              </Txt>
              <Txt size="s" color={theme.color.white[1]}>
                とても良い
              </Txt>
            </TxtGroup>
          </Estimation>
        </Card>
        <NoteTxt size="s">※このページを閉じるとアンケートに回答できなくなるのでご注意ください。</NoteTxt>
        {pageCtx.isInterviewTranscriptionEnabled && (
          <ButtonWrapper>
            <EnterButton
              onClick={() =>
                window.open(generatePath(BIZ_INTERNAL_PATHS.interviewMinutes, { interviewGuid }), '_blank')
              }
              comlinkPushParams={{ action: 'click_biz_open_interview_minutes_button' }}
            >
              <IconTxt>
                <span>議事録</span>
                <CopyIcon />
              </IconTxt>
              <Txt size="s" color={theme.color.green[3]}>
                面接の文字起こしが確認できます
              </Txt>
            </EnterButton>
          </ButtonWrapper>
        )}
      </Wrapper>
    </PageLayoutWithGlonavi>
  )
}

const MainMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > *:not(:first-child) {
    margin-top: 22px;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 20px;
`
const Card = styled.div`
  margin-top: 54px;
  max-width: 100%;
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  opacity: 0.85;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: ${theme.color.white[1]};
  background-color: ${theme.color.green[3]};

  ${({ theme }) => {
    if (theme.responsive.pc || theme.responsive.spLandscape) {
      return css`
        width: 360px;
      `
    }
  }}
`
const RequestTxt = styled(Txt)`
  text-align: center;
`
const NoteTxt = styled(Txt)`
  margin-top: 20px;
`
const Estimation = styled.div`
  margin-top: 30px;
`
const StarGroup = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 240px;
`
const iconStyle = css`
  color: ${theme.color.white[1]};
  padding: 3px;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  transition: background-color 200ms 0s ease;
  :hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`
const EmptyStar = styled(Icon)`
  ${iconStyle}
`
const FilledStar = styled(Icon)`
  ${iconStyle}
`
const TxtGroup = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
`
const ReEntryButton = styled.button`
  ${buttonResetStyle};
`

const ButtonWrapper = styled.div`
  margin-top: 8px;
`

const EnterButton = styled(SecondaryButton).attrs({ widthSize: 'MAX' })`
  width: 380px;
  height: auto;
  padding: 8px;
  margin-top: 63px;
`

const CopyIcon = styled(Icon).attrs({ name: 'target-blank' })`
  cursor: pointer;
`

const IconTxt = styled(Txt).attrs({ size: 'm' })`
  display: flex;
  justify-content: center;
  gap: 3px;
  color: ${theme.color.green[3]};
`
