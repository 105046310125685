import { Icon, TertiaryButton, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { useInterviewDisplaySize } from '@/shared/hooks/useInterviewDisplaySize'
import { controlButtonBase } from '@/shared/services/interviewService/components/controlButtons/controlButtonBase'
import { useReloadModal } from '@/shared/services/interviewService/hooksForUi/useMenuDropdown/useReloadModal'

export function ReloadButton() {
  const { openReloadModal, renderReloadModal } = useReloadModal()
  const { responsive } = useInterviewDisplaySize()

  if (responsive.pc) {
    return (
      <>
        <PcButton
          onClick={openReloadModal}
          text="再読込"
          direction="column"
          icon={<Icon name="reload" />}
          // ファイル内での重複は想定内
          // eslint-disable-next-line @blue-agency/react/unique-action-name
          comlinkPushParams={{ action: 'click_reload_item' }}
        />
        {renderReloadModal()}
      </>
    )
  }

  return (
    <>
      <SpButton
        onClick={openReloadModal}
        text="再読込"
        direction="row"
        icon={<Icon name="reload" size="m" />}
        // eslint-disable-next-line @blue-agency/react/unique-action-name
        comlinkPushParams={{ action: 'click_reload_item' }}
      />
      {renderReloadModal()}
    </>
  )
}

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const PcButton = styled(TertiaryButton)`
  ${controlButtonBase}
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const SpButton = styled(TertiaryButton)`
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 0;
  font-size: ${theme.fontSize.s};
  span {
    margin-left: 4px;
  }
`
