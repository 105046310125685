import React from 'react'
import { useSelector } from 'react-redux'
import { SharedState } from '../../redux'
import { NumberOfParticipantsLimitErrorModal } from './NumberOfParticipantsLimitErrorModal'
import { UnknownErrorModal } from './UnknownErrorModal'

export const SignalingErrorModal: React.VFC = () => {
  const userSignalingStatus = useSelector((state: SharedState) => state.shared.userSignalingStatus)
  const screenShareRecvSignalingStatus = useSelector(
    (state: SharedState) => state.shared.screenShareRecvSignalingStatus
  )

  switch (userSignalingStatus) {
    case 'Error/Unknown':
      return <UnknownErrorModal />
    case 'Error/NumberOfParticipantsLimit':
      return <NumberOfParticipantsLimitErrorModal />
    case 'Disconnected':
      // UIがシグナリング失敗時と同じ
      return <UnknownErrorModal />
    case 'Pending':
    case 'Completed':
      // nop
      break
  }

  switch (screenShareRecvSignalingStatus) {
    case 'Error/Unknown':
      return <UnknownErrorModal />
    case 'Error/NumberOfParticipantsLimit': // screenShareRecvでは発生しないためnull
      break
    case 'Disconnected':
      // UIがシグナリング失敗時と同じ
      return <UnknownErrorModal />
    case 'Pending':
    case 'Completed':
      break
  }

  return null
}
