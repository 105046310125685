import React from 'react'
import { Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Dots } from '@/shared/components/Dots'
import { NextButton } from '@/shared/components/ManualModal/Button'
import { StartIcon, ChatIcon, EarphoneIcon } from '@/shared/components/ManualModal/IconMobile'
import {
  Wrapper,
  Upper,
  Row,
  HeaderWrapper,
  IconWrapper,
  TextWrapper,
  BottomWrapper,
  DotsWrapper,
  Bottom,
} from '@/shared/components/ManualModal/LayoutMobile'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName

export const FirstMobile = ({ className }: Props): React.ReactElement => {
  const { handleNext } = StepContainer.useContainer()

  return (
    <Wrapper className={className}>
      <Upper>
        <Row>
          <HeaderWrapper>
            <IconWrapper>
              <StartIcon />
            </IconWrapper>
            <div style={{ flex: 1 }}>
              <Txt size="l">参加開始</Txt>
            </div>
          </HeaderWrapper>
          <TextWrapper>
            <Txt>
              氏名を入力後、「参加をリクエスト」を押して
              <br />
              相手に承認されると参加開始となります。
            </Txt>
          </TextWrapper>
        </Row>
        <Row>
          <HeaderWrapper>
            <IconWrapper>
              <ChatIcon />
            </IconWrapper>
            <div style={{ flex: 1 }}>
              <Txt size="l">チャット機能</Txt>
            </div>
          </HeaderWrapper>
          <TextWrapper>
            <Txt>
              参加者全員でチャット可能です。
              <br />
              音声/映像のトラブル時にもご利用いただけます。
            </Txt>
          </TextWrapper>
        </Row>
        <Row>
          <HeaderWrapper>
            <IconWrapper>
              <EarphoneIcon />
            </IconWrapper>
            <div style={{ flex: 1 }}>
              <Txt size="l">イヤホンの再接続</Txt>
            </div>
          </HeaderWrapper>
          <TextWrapper>
            <Txt>
              「音声が聞こえない」ときは、イヤホンを再接続してみましょう。接続状況が改善され、聞こえるようになる場合があります。
            </Txt>
          </TextWrapper>
        </Row>
      </Upper>
      <BottomWrapper>
        <WeakColorSeparator />
        <DotsWrapper>
          <Dots count={2} currentPage={1} />
        </DotsWrapper>
        <Bottom>
          <NextButton
            comlinkPushParams={{
              // PCの方と、biz側との重複は想定内
              // eslint-disable-next-line @blue-agency/react/unique-action-name
              action: 'click_next_button_on_waiting_room_manual',
            }}
            onClick={handleNext}
          />
        </Bottom>
      </BottomWrapper>
    </Wrapper>
  )
}
