import React from 'react'
import { OutputSelector } from '@reduxjs/toolkit'
import styled from 'styled-components'
import { LocalVideo } from '@/lib/meetingcomponent/LocalVideo'
import { LocalVideoContainer } from '@/lib/meetingcomponent/LocalVideoContainer'
import { RemoteVideos } from '@/lib/meetingcomponent/RemoteVideos'
import { VideoWrapper } from '@/shared/services/interviewService/components/SubAreaVideoWrapper'
import { VideosLayoutContainer } from '@/shared/services/interviewService/containers/VideosLayoutContainer'
import { SharedState } from '@/shared/services/interviewService/redux'
import { Participant } from '@/shared/services/interviewService/types'

type Props = {
  targetParticipantsSelector: OutputSelector<SharedState, Participant[], (res: Participant[]) => Participant[]>
  hasLocalVideo?: boolean
  isScreenShare?: boolean
}

export const VideoList: React.VFC<Props> = (props) => {
  const { isVideoEnabled: isLocalVideoEnabled } = LocalVideoContainer.useContainer()
  const { videoWrapperLayout } = VideosLayoutContainer.useContainer()

  return (
    <div>
      <Inner>
        {props.hasLocalVideo && isLocalVideoEnabled && (
          <VideoWrapper layout={videoWrapperLayout(props.isScreenShare ?? false)}>
            <LocalVideo />
          </VideoWrapper>
        )}
        <RemoteVideos
          targetParticipantsSelector={props.targetParticipantsSelector}
          isScreenShare={props.isScreenShare ?? false}
        />
      </Inner>
    </div>
  )
}

const Inner = styled.div`
  display: flex;
  flex-wrap: wrap;
`
