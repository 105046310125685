import { useCallback } from 'react'
import { IntervieweeLogType } from '@blue-agency/proton/im'
import { SendIntervieweeLogRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

type Args = {
  interviewGuid: string
  clientBrowserUuid: string
  logType: IntervieweeLogType
}
export function useRequestSendIntervieweeLog() {
  const requestSendIntervieweeLog = useCallback((args: Args) => {
    const req = new SendIntervieweeLogRequest()

    req.setInterviewGuid(args.interviewGuid)
    req.setClientBrowserUuid(args.clientBrowserUuid)
    req.setIntervieweeLogType(args.logType)

    return callBff('sendIntervieweeLog', [req])
  }, [])

  return { requestSendIntervieweeLog }
}
