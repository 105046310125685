import { useCallback } from 'react'
import { GetInterviewQualityRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestGetInterviewQuality() {
  const requestGetInterviewQuality = useCallback((interviewGuid: string) => {
    const req = new GetInterviewQualityRequest()

    req.setInterviewGuid(interviewGuid)

    return callBff('getInterviewQuality', [req])
  }, [])

  return { requestGetInterviewQuality }
}
