import { useCallback, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import * as Sentry from '@sentry/react'
import retry from 'retry'
import invariant from 'tiny-invariant'

export const labelMicMuted = '#mic-muted'
export const labelNetworkUnstableLevel = '#network-unstable-level'

export type DataChannelMessagingLabel = typeof labelMicMuted | typeof labelNetworkUnstableLevel

export type Data = Record<string, unknown>
type SendFn = (label: string, data: Uint8Array) => void
type Observer = (data: Data) => void
type ObserverMap = {
  [labelMicMuted]: Observer[]
  [labelNetworkUnstableLevel]: Observer[]
}

const serializeData = (data: Data): Uint8Array => {
  const j = JSON.stringify(data)
  return new TextEncoder().encode(j)
}

const deserializeData = (buf: ArrayBuffer): Data => {
  const j = new TextDecoder().decode(buf)
  return JSON.parse(j)
}

const useDataChannelMessaging = () => {
  const sendFn = useRef<SendFn | null>(null)
  const observers = useRef<ObserverMap>({ [labelMicMuted]: [], [labelNetworkUnstableLevel]: [] })

  const setSendFn = useCallback((fn: SendFn) => {
    sendFn.current = fn
  }, [])

  const sendMessage = useCallback((label: string, data: Data) => {
    invariant(sendFn.current, 'no sendFn')

    const send = sendFn.current
    try {
      // リトライ入れてsendMessage
      const op = retry.operation({ retries: 5, minTimeout: 1 * 1000, maxRetryTime: 5 * 1000 })
      op.attempt(() => {
        try {
          send(label, serializeData(data))
        } catch (e) {
          if (op.retry(e)) {
            return
          }
          throw e
        }
      })
    } catch (e) {
      Sentry.captureException(e)
      // 現状ミュート状態の相手への送信とunstable levelの送信に使っており、
      // 飛んでなくてもそこまで困らず、面接できないよりはましなので握りつぶす
    }
  }, [])

  const onReceived = useCallback((label: string, data: ArrayBuffer) => {
    if (label !== labelMicMuted && label !== labelNetworkUnstableLevel) {
      return
    }
    const d = deserializeData(data)
    if (observers.current[label]) {
      for (const observer of observers.current[label]!) {
        observer(d)
      }
    }
  }, [])

  const addObserver = useCallback((label: DataChannelMessagingLabel, observer: Observer) => {
    observers.current[label].push(observer)
  }, [])

  const removeObserver = useCallback((label: DataChannelMessagingLabel, observer: Observer) => {
    const idx = observers.current[label].indexOf(observer)
    if (idx >= 0) {
      observers.current[label].splice(idx, 1)
    }
  }, [])

  return {
    setSendFn,
    sendMessage,
    onReceived,
    addObserver,
    removeObserver,
  }
}

export const DataChannelMessagingContainer = createContainer(useDataChannelMessaging)
