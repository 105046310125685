import { useCallback } from 'react'
import { GetInterviewRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestGetInterview() {
  const requestGetInterview = useCallback((interviewGuid: string) => {
    const req = new GetInterviewRequest()

    req.setInterviewGuid(interviewGuid)

    return callBff('getInterview', [req])
  }, [])

  return { requestGetInterview }
}
