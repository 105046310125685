import React, { forwardRef } from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { useToggle } from '@/shared/hooks/useToggle'

type Props = {
  recordingUrl: string
}

export const Recording = forwardRef((props: Props, ref: React.Ref<HTMLIFrameElement>) => {
  const [isDisplayRecording, toggleIsDisplayRecording] = useToggle(true)

  return (
    <Content isDisplayRecording={isDisplayRecording}>
      <Header className="header">
        {!isDisplayRecording && <Txt color={theme.color.white[1]}>面接録画</Txt>}
        <IconButton onClick={toggleIsDisplayRecording}>
          {isDisplayRecording ? <MinimizeIcon /> : <MaximizeIcon />}
        </IconButton>
      </Header>
      {isDisplayRecording && (
        <RecordingContent title="Web面接録画" src={props.recordingUrl} allow="fullscreen" ref={ref} />
      )}
    </Content>
  )
})

const RecordingContent = styled.iframe`
  height: 100%;
  width: 100%;
`

const Header = styled.div`
  background-color: ${theme.color.navy[1]};
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  *:not(:last-child) {
    margin-right: 10px;
  }
`

const IconButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`

const MinimizeIcon = styled(Icon).attrs({ name: 'minimize' })`
  color: ${theme.color.white[1]};
`

const MaximizeIcon = styled(Icon).attrs({ name: 'maximize' })`
  color: ${theme.color.white[1]};
`

const Content = styled.div<{ isDisplayRecording: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  position: absolute;
  bottom: 8px;
  right: 8px;
  width: ${({ isDisplayRecording }) => (isDisplayRecording ? '563px' : 'auto')};
  height: ${({ isDisplayRecording }) => (isDisplayRecording ? '364px' : 'auto')};
  overflow: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`
