import { useCallback } from 'react'
import { theme } from '@blue-agency/rogue'
import { OutputSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { VideoList } from '@/shared/services/interviewService/components/VideoList'
import { AppType, InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import {
  contentShareSelector,
  intervieweesSelector,
  intervieweesWithoutContentShareSelector,
  interviewersSelector,
  interviewersWithoutContentShareSelector,
  SharedState,
  visibleIntervieweesCountSelector,
  visibleInterviewersCountSelector,
} from '@/shared/services/interviewService/redux'
import type { Participant, ParticipantRole } from '@/shared/services/interviewService/types'
import { VideosLayoutContainer } from '../../containers/VideosLayoutContainer'

/**
 *  参加者一覧レイアウト
 *  SP縦での2カラム横並びのレイアウト、及びPCやSP横での1カラム縦並びレイアウトを1箇所で構築している。
 *  Safariでの画面回転時に画面外にVideoタグが移動した際に自動再生されない(音声が聞こえない)問題を防ぐために、
 *  Videoタグを縦・横で同一のものを利用するように、VideoListを縦・横で使い回す必要がある。
 */
export const Videos: React.VFC = () => {
  const { appType } = InterviewContainer.useContainer()
  const { layoutType } = VideosLayoutContainer.useContainer()
  const orderedRoles: [ParticipantRole, ParticipantRole] =
    layoutType === 'horizontal'
      ? ['interviewer', 'interviewee']
      : appType === 'biz'
      ? ['interviewee', 'interviewer']
      : ['interviewer', 'interviewee']
  const participantsSelector = useCallback(
    (role: ParticipantRole) => {
      return layoutType === 'horizontal'
        ? role === 'interviewer'
          ? interviewersSelector
          : intervieweesSelector
        : role === 'interviewer'
        ? interviewersWithoutContentShareSelector
        : intervieweesWithoutContentShareSelector
    },
    [layoutType]
  )

  return (
    <Wrapper layoutType={layoutType}>
      {layoutType === 'vertical' && (
        <VideoListWrapper layoutType={layoutType}>
          <VideoList targetParticipantsSelector={contentShareSelector} isScreenShare />
        </VideoListWrapper>
      )}
      <VideoListWrapper layoutType={layoutType}>
        <RoleToVideoList
          role={orderedRoles[0]!}
          targetParticipantsSelector={participantsSelector(orderedRoles[0]!)}
          appType={appType}
        />
      </VideoListWrapper>
      {layoutType === 'horizontal' && <VideoListSpPortraitSeparator />}
      <VideoListWrapper layoutType={layoutType}>
        <RoleToVideoList
          role={orderedRoles[1]!}
          targetParticipantsSelector={participantsSelector(orderedRoles[1]!)}
          appType={appType}
        />
      </VideoListWrapper>
    </Wrapper>
  )
}

type RoleToVideoListProps = {
  role: ParticipantRole
  targetParticipantsSelector: OutputSelector<SharedState, Participant[], (res: Participant[]) => Participant[]>
  appType: AppType
}

const RoleToVideoList: React.VFC<RoleToVideoListProps> = (props) => {
  const hasInterviewers = useSelector(visibleInterviewersCountSelector) > 0
  const hasInterviewees = useSelector(visibleIntervieweesCountSelector) > 0

  return (
    <>
      {props.role === 'interviewer'
        ? hasInterviewers && (
            <>
              <VideoList
                targetParticipantsSelector={props.targetParticipantsSelector}
                hasLocalVideo={props.appType === 'biz'}
              />
            </>
          )
        : hasInterviewees && (
            <>
              <VideoList
                targetParticipantsSelector={props.targetParticipantsSelector}
                hasLocalVideo={props.appType === 'my'}
              />
            </>
          )}
    </>
  )
}

const Wrapper = styled.div<{ layoutType: 'horizontal' | 'vertical' }>`
  width: 100%;
  height: 100%;
  background-color: ${theme.color.darkGray[1]};

  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;

  ${({ layoutType }) => {
    if (layoutType === 'horizontal') {
      return css`
        display: flex;
        flex-direction: row-reverse;
      `
    }
  }}
`

const VideoListWrapper = styled.div<{ layoutType: 'horizontal' | 'vertical' }>`
  ${({ layoutType }) => {
    switch (layoutType) {
      case 'horizontal':
        return css`
          width: 50%;
        `
      case 'vertical':
        return css`
          width: 100%;
        `
    }
  }}
`

const VideoListSpPortraitSeparator = styled.div`
  width: 10px;
  height: 100%;
`
