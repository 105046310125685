import { alertToast } from '@blue-agency/rogue'
import { LightAdjustmentGpuProcessor, llieModelNames } from '@shiguredo/light-adjustment-gpu/dist/light_adjustment_gpu'
import { VirtualBackgroundProcessor } from '@shiguredo/virtual-background'
import { VideoTransformDevice, Device, BrowserBehavior, DefaultBrowserBehavior } from 'amazon-chime-sdk-js'
import {
  BackgroundEffect,
  LightAdjustment,
} from '@/lib/interview-sdk-js/amazon-chime-sdk-js/meetingmanager/ImMeetingManager'
import { LIGHT_ADJUSTMENT_ASSET_PATH, VIRTUAL_BACKGROUND_ASSET_PATH } from '@/lib/interview-sdk-js/mediaProcessor'

export class MediaProcessorVideoTransformDevice implements VideoTransformDevice {
  private inputMediaStream: MediaStream | undefined
  outputMediaStream: MediaStream
  private virtualBackgroundProcessor: VirtualBackgroundProcessor
  private lightAdjustmentGpuProcessor: LightAdjustmentGpuProcessor

  constructor(
    private device: Device,
    private backgroundEffect: BackgroundEffect,
    private lightAdjustment: LightAdjustment,
    private browserBehavior: BrowserBehavior = new DefaultBrowserBehavior()
  ) {
    this.outputMediaStream = new MediaStream()
    this.virtualBackgroundProcessor = new VirtualBackgroundProcessor(VIRTUAL_BACKGROUND_ASSET_PATH)
    this.lightAdjustmentGpuProcessor = new LightAdjustmentGpuProcessor(
      LIGHT_ADJUSTMENT_ASSET_PATH,
      llieModelNames.semanticGuidedLlie648x360,
      lightAdjustment.type === 'adjustment' ? lightAdjustment.strength : 0.0
    )
  }

  async stop(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ;(this.lightAdjustmentGpuProcessor as any).stopProcessing()
    this.virtualBackgroundProcessor.stopProcessing()
    if (this.inputMediaStream) {
      for (const track of this.inputMediaStream.getVideoTracks()) {
        track.stop()
      }
    }
    if (this.outputMediaStream) {
      for (const track of this.outputMediaStream.getVideoTracks()) {
        track.stop()
      }
    }

    this.inputMediaStream = undefined
  }

  async intrinsicDevice(): Promise<Device> {
    const trackConstraints: MediaTrackConstraints = {}

    // Empty string and null.
    if (!this.device) {
      return trackConstraints
    }

    // Device ID.
    if (typeof this.device === 'string') {
      if (this.browserBehavior.requiresNoExactMediaStreamConstraints()) {
        trackConstraints.deviceId = this.device
      } else {
        trackConstraints.deviceId = { exact: this.device }
      }
      return trackConstraints
    }

    if ((this.device as MediaStream).id) {
      // Nothing we can do.
      return this.device
    }

    // It's constraints.
    return {
      ...this.device,
      ...trackConstraints,
    }
  }

  async transformStream(mediaStream: MediaStream): Promise<MediaStream> {
    this.inputMediaStream = mediaStream
    if (mediaStream === undefined || mediaStream.getVideoTracks().length === 0) {
      throw new Error('No videotrack exists')
    }
    let videoTrack = mediaStream.getVideoTracks()[0] as MediaStreamVideoTrack
    switch (this.lightAdjustment.type) {
      case 'no-effect':
        // 何もしない
        break
      case 'adjustment':
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        videoTrack = await (this.lightAdjustmentGpuProcessor as any).startProcessing(videoTrack).catch(() => {
          alertToast(
            'ファイルの読込エラーが発生しました。再読込して再度お試しください。改善されない場合は、ご利用環境をご確認ください。'
          )
          return videoTrack
        })
        break
      default:
        throw new Error('Unknown light adjustment type')
    }
    switch (this.backgroundEffect.type) {
      case 'no-effect':
        this.outputMediaStream = new MediaStream([videoTrack])
        break
      case 'blur':
        const blurredTrack = await this.virtualBackgroundProcessor
          .startProcessing(videoTrack, {
            blurRadius: this.backgroundEffect.blurSize,
          })
          .catch(() => {
            alertToast(
              'ファイルの読込エラーが発生しました。再読込して再度お試しください。改善されない場合は、ご利用環境をご確認ください。'
            )
            return videoTrack
          })
        this.outputMediaStream = new MediaStream([blurredTrack])
        break
      case 'replace':
        const replacedTrack = await this.virtualBackgroundProcessor
          .startProcessing(videoTrack, {
            backgroundImage: this.backgroundEffect.image,
          })
          .catch(() => {
            alertToast(
              'ファイルの読込エラーが発生しました。再読込して再度お試しください。改善されない場合は、ご利用環境をご確認ください。'
            )
            return videoTrack
          })
        this.outputMediaStream = new MediaStream([replacedTrack])
        break
      default:
        throw new Error('Unknown background effect type')
    }

    return this.outputMediaStream
  }

  onOutputStreamDisconnect(): void {
    const deviceIsMediaStream = this.device && (this.device as MediaStream).id

    // Stop processing but keep the pipe and processors
    if (this.outputMediaStream) {
      for (const track of this.outputMediaStream.getVideoTracks()) {
        track.stop()
      }
    }

    // Turn off the camera, unless device is a MediaStream
    if (!deviceIsMediaStream) {
      if (this.inputMediaStream) {
        for (const track of this.inputMediaStream.getVideoTracks()) {
          track.stop()
        }
      }
    }
  }

  getInnerDevice(): Device {
    return this.device
  }
}

export const isSupportedBackgroundEffect = (): boolean => {
  return VirtualBackgroundProcessor.isSupported()
}

export const isSupportedLightAdjustment = (): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (LightAdjustmentGpuProcessor as any).isSupported()
}
