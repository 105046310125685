import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateCameraTestInvitationRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestCreateCameraTestInvitation() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateCameraTestInvitation = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new CreateCameraTestInvitationRequest()

    return callBff('createCameraTestInvitation', [req, metadata], loggedInStaff?.guid)
  }, [buildMetadata, loggedInStaff])

  return { requestCreateCameraTestInvitation }
}
