import React from 'react'
import { PageLayoutWithGlonavi } from '@blue-agency/im-shared-front'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { SecondaryButton } from '@blue-agency/rogue/im'
import { generatePath, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { BIZ_INTERNAL_PATHS } from '@/biz/services/urlService'

type Props = {
  isInterviewTranscriptionEnabled: boolean
}

// Interview/Finished と違って、ページを開いたときに終了している場合に表示するコンポーネント
export const Finished: React.VFC<Props> = ({ isInterviewTranscriptionEnabled }) => {
  const { interviewGuid } = useParams<{ interviewGuid: string }>()

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <Wrapper>
        <Txt size="l">お疲れ様でした。面接が終了しました。</Txt>
      </Wrapper>
      {isInterviewTranscriptionEnabled && (
        <ButtonWrapper>
          <EnterButton
            onClick={() => window.open(generatePath(BIZ_INTERNAL_PATHS.interviewMinutes, { interviewGuid }), '_blank')}
            comlinkPushParams={{ action: 'click_biz_open_interview_minutes_button_on_finished_screen' }}
          >
            <IconTxt>
              <span>議事録</span>
              <CopyIcon />
            </IconTxt>
            <Txt size="s" color={theme.color.green[3]}>
              面接の文字起こしが確認できます
            </Txt>
          </EnterButton>
        </ButtonWrapper>
      )}
    </PageLayoutWithGlonavi>
  )
}

const Wrapper = styled.div`
  margin: 42px auto 0;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 20px 0;
`

const EnterButton = styled(SecondaryButton).attrs({ widthSize: 'MAX' })`
  width: 380px;
  height: auto;
  padding: 8px;
`

const CopyIcon = styled(Icon).attrs({ name: 'target-blank' })`
  cursor: pointer;
`

const IconTxt = styled(Txt).attrs({ size: 'm' })`
  display: flex;
  justify-content: center;
  gap: 3px;
  color: ${theme.color.green[3]};
`
