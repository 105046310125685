import { useRef, useEffect } from 'react'

export function usePrevious<T>(val: T): T | undefined {
  const ref = useRef<T | undefined>(undefined)

  useEffect(() => {
    ref.current = val
  }, [val])

  return ref.current
}
