import * as google_protobuf_timestamp_pb from 'google-protobuf/google/protobuf/timestamp_pb'

// 構造的には ↓ の型は共通で、Union して1つの型として扱えるが
// type EnterRpcResponse = BizEnterResponse.AsObject | MyEnterResponse.AsObject
//
// 内部でそれぞれの Enum を定義しているために、うまく Union として扱えない
// 構造を抜き出したものを定義する

export type EnterRpcResponse = {
  participantsList: Array<{
    soraClientId: string
    name: string
  }>
  chatRoomGuid: string
  messagesList: Array<{
    messageGuid: string
    participant?: {
      soraClientId: string
      name: string
    }
    text: string
    createTime?: google_protobuf_timestamp_pb.Timestamp.AsObject
    role: Role
  }>
}

export enum Role {
  ROLE_UNKNOWN = 0,
  INTERVIEWER = 1,
  INTERVIEWEE = 2,
}
