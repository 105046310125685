import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PageLayout } from '@/shared/components/PageLayout'

// Interview/Finished と違って、ページを開いたときに終了している場合に表示するコンポーネント
export const Finished: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} globalHeader={{ disableLogoLink: true }}>
      <Wrapper>
        <Txt size="l">お疲れ様でした。終了しました。</Txt>
      </Wrapper>
    </PageLayout>
  )
}

const Wrapper = styled.div`
  margin: 42px auto 0;
`
