import { useEffect, useMemo } from 'react'
import { useUserAgent } from '@blue-agency/react-utils'
import { alertToast, toast } from '@blue-agency/rogue'
import { findCandidateDevice } from '@/lib/interview-sdk-js'
import { AudioInputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { isPcByUA } from '@/shared/utils/isPcByUA'

export const useMicUnpluged = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioInputs, selectedAudioInputDevice } = AudioInputContainer.useContainer()

  const ua = useUserAgent()
  const isPc = useMemo(() => isPcByUA(ua), [ua])

  useEffect(() => {
    // デバイス変更がない環境では無視
    if (!isPc) return

    // 選択中のデバイスが抜かれたときの対処
    if (!audioInputs || audioInputs.length === 0) return
    if (!selectedAudioInputDevice) return

    if (audioInputs.some((d) => d.deviceId === selectedAudioInputDevice)) return

    const device = findCandidateDevice(audioInputs)
    if (device) {
      meetingManager.selectAudioInputDevice(device.deviceId)
      toast(`マイクを ${device.label} に切り替えました`)
    } else {
      alertToast('マイクデバイスが見つかりませんでした')
    }
  }, [isPc, meetingManager, audioInputs, selectedAudioInputDevice])
}
