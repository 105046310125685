import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled, { DefaultTheme } from 'styled-components'

export const Footer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  padding: 0px ${({ theme }) => (theme.responsive.pc ? 8 : 4)}px;
  height: ${({ theme }) => (theme.responsive.pc ? 30 : 16)}px;

  display: flex;
  align-items: center;
`

export const AudioStatus = styled.div`
  margin-right: ${({ theme }) => (theme.responsive.pc ? 10 : 4)}px;
`

export const Name = styled(Txt).attrs(({ theme: { responsive } }) => ({
  color: theme.color.white[1],
  size: responsive.pc ? 's' : 'xs',
}))`
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-shadow: 0px 0px 2px ${theme.color.black[1]};

  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://nuconeco.net/iphone-safari-type/
  -webkit-text-size-adjust: 100%;

  display: flex;
  align-items: center;
`

export const MuteMicIcon = styled(Icon).attrs({ name: 'mute-mike' })`
  width: ${({ theme }) => (theme.responsive.pc ? 12 : 6)}px;
  height: ${({ theme }) => (theme.responsive.pc ? 12 : 6)}px;
  padding: ${({ theme }) => (theme.responsive.pc ? 4 : 2)}px;

  border-radius: 50%;
  background-color: ${theme.color.red[2]};
  color: ${theme.color.white[1]};
  opacity: 0.9;
`

export const CompanyIcon = styled(Icon).attrs(({ theme }) => ({
  name: 'company',
  size: theme.responsive.pc ? 'm' : 's',
}))`
  filter: drop-shadow(0px 0px 2px ${theme.color.black[1]});
  margin-right: 4px;
`

export const Overlay = styled.div<{ isMainVideo: boolean; hasName: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: ${(p) => {
    if (p.isMainVideo) {
      return `${theme.color.black[1]}80`
    }
    if (p.hasName) {
      return 'linear-gradient(180deg, rgba(196, 196, 196, 0) 58.16%, rgba(0, 0, 0, 0.5) 100%)'
    }
    return 'initial'
  }};
`

export const audioWaveCircleProps = (theme: DefaultTheme) => {
  return {
    width: theme.responsive.pc ? 20 : 20 / 2,
    height: theme.responsive.pc ? 20 : 20 / 2,
    barWidth: theme.responsive.pc ? 2.5 : 2.5 / 2,
    barPadding: theme.responsive.pc ? 2.5 : 2.5 / 2,
    baseBarHeight: 0,
    centerMaxBarHeight: theme.responsive.pc ? 13 : 13 / 2,
    sideMaxBarHeight: theme.responsive.pc ? 8 : 8 / 2,
  }
}
