import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { sharedSlice, SharedState } from '@/shared/services/interviewService/redux'

export const useSubscribeMicMuted = () => {
  const dispatch = useDispatch()

  const { audioVideo } = AudioVideoContainer.useContainer()

  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const observer = (mute: boolean) => {
      dispatch(sharedSlice.actions.micMutedChanged(mute))
    }

    audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(observer)

    return () => {
      audioVideo.realtimeUnsubscribeToMuteAndUnmuteLocalAudio(observer)
    }
  }, [audioVideo, dispatch, ownSoraClientId])
}
