import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { IntervieweeLogType } from '@blue-agency/proton/web/v2/im'
import { alertToast } from '@blue-agency/rogue'
import { yupResolver } from '@hookform/resolvers/yup'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import invariant from 'tiny-invariant'
import * as yup from 'yup'
import { getReEntryName, setReEntryName } from '@/my/pages/InterviewPage/reEntry'
import { useRequestGetEntryRequestStatus, useRequestRequestEntry, EntryRequestStatus } from '@/my/services/bffService'
import { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { InterviewPageContainer } from '../../../containers/InterviewPageContainer'
import { InterviewContainer } from '../../containers/InterviewContainer'
import { mySlice } from '../../redux/mySlice'
import { RootState } from '../../redux/store'

const myApiHost = process.env.REACT_APP_MY_API_HOST
invariant(myApiHost)

const POLLING_INTERVAL = 10_000

type Form = {
  name: string
}

const validationSchema: yup.SchemaOf<Form> = yup
  .object({
    name: yup.string().required('入力してください').max(30, '30文字以内で入力してください'),
  })
  .defined()

export function useRequestEntryForm() {
  const dispatch = useDispatch()
  const { interviewGuid, ws } = InterviewContainer.useContainer()
  const { sendIntervieweeLog } = InterviewPageContainer.useContainer()
  invariant(sendIntervieweeLog)
  const [isRequesting, setIsRequesting] = useState<boolean>(false)

  const { requestRequestEntry } = useRequestRequestEntry()
  const { requestGetEntryRequestStatus } = useRequestGetEntryRequestStatus()

  const form = useForm<Form>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
    defaultValues: {
      // 再参加のとき、前回の名前をデフォルトで入力する
      name: getReEntryName(),
    },
  })

  const entryRequestGuid = useSelector((state: RootState) => state.my.entryRequestGuid)
  const invitationToken = useSelector((state: RootState) => state.my.invitationToken)

  const { responsive } = useContext(ThemeContext)

  const { handleSubmit } = form
  const requestEnter = useMemo(() => {
    const fn: SubmitHandler<Form> = async (data) => {
      setIsRequesting(true)
      sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_REQUEST_ENTRY)
      const res = await requestRequestEntry({ interviewGuid, name: data.name })
      if (res) {
        setReEntryName(data.name)
        dispatch(
          mySlice.actions.entryRequested({
            entryRequestGuid: res.getEntryRequestGuid(),
            invitationToken: res.getInvitationToken(),
            ownName: data.name,
          })
        )
      }
    }

    return handleSubmit(fn)
  }, [dispatch, handleSubmit, interviewGuid, requestRequestEntry, sendIntervieweeLog])

  useEffect(() => {
    const handleRejectEntry = (message: WebSocketMessageMap['reject entry']) => {
      if (message.entry_request_guid === entryRequestGuid) {
        setIsRequesting(false)
        dispatch(mySlice.actions.entryRequestRejected())
        alertToast('リクエストが拒否されました。')
      }
    }
    const handleApproveEntry = (message: WebSocketMessageMap['approve entry']) => {
      if (message.entry_request_guid === entryRequestGuid) {
        dispatch(mySlice.actions.entryRequestApproved())
      }
    }

    ws.addMessageListener('reject entry', handleRejectEntry)
    ws.addMessageListener('approve entry', handleApproveEntry)

    return () => {
      ws.removeMessageListener('reject entry', handleRejectEntry)
      ws.removeMessageListener('approve entry', handleApproveEntry)
    }
  }, [dispatch, entryRequestGuid, ws])

  // wsで受信が漏れたときの補完
  useEffect(() => {
    // 定期的にポーリングする。レスポンスが遅くて処理が重なったりしないようにsetIntervalではなくsetTimeoutを繰り返す形にする。
    let stopped = false
    const fn = async () => {
      if (stopped) {
        return
      }
      if (!isRequesting || !entryRequestGuid || !invitationToken) {
        return
      }
      try {
        const res = await requestGetEntryRequestStatus({ interviewGuid, entryRequestGuid, invitationToken })
        const status = res.getStatus()
        if (status === EntryRequestStatus.APPROVED) {
          setIsRequesting(false)
          dispatch(mySlice.actions.entryRequestApproved())
        } else if (status === EntryRequestStatus.REJECTED) {
          setIsRequesting(false)
          dispatch(mySlice.actions.entryRequestRejected())
          alertToast('リクエストが拒否されました。')
        }
      } finally {
        // approvedやrejectedが得られた場合は続行する必要はないが、isRequestingがfalseになって止まるので画一的にする
        setTimeout(fn, POLLING_INTERVAL)
      }
    }

    fn()

    return () => {
      stopped = true
    }
  }, [dispatch, requestGetEntryRequestStatus, isRequesting, interviewGuid, entryRequestGuid, invitationToken])

  const cancelEntryRequest = useCallback(() => {
    if (entryRequestGuid === undefined || invitationToken === undefined) {
      return undefined
    }
    const blob = new Blob(
      [
        JSON.stringify({
          interview_guid: interviewGuid,
          entry_request_guid: entryRequestGuid,
          invitation_token: invitationToken,
        }),
      ],
      { type: 'application/json' }
    )

    navigator.sendBeacon(`${myApiHost}/cancelEntryRequest`, blob)
  }, [entryRequestGuid, interviewGuid, invitationToken])

  useEffect(() => {
    let requested = false

    const cancel = () => {
      if (requested) return
      requested = true
      cancelEntryRequest()
    }

    window.addEventListener('unload', cancel)
    window.addEventListener('beforeunload', cancel)
    window.addEventListener('pagehide', cancel)

    return () => {
      window.removeEventListener('unload', cancel)
      window.removeEventListener('beforeunload', cancel)
      window.removeEventListener('pagehide', cancel)
    }
  }, [cancelEntryRequest])

  // 参加リクエストのタイムアウト
  useEffect(() => {
    if (!isRequesting) {
      return
    }

    const timeoutMs = 1000 * 60 * 20

    const id = window.setTimeout(() => {
      setIsRequesting(false)
      cancelEntryRequest()
      alertToast('リクエストがタイムアウトしました。\n再度参加をリクエストしてください。')
    }, timeoutMs)

    return () => {
      window.clearTimeout(id)
    }
  }, [cancelEntryRequest, isRequesting])

  return {
    isRequesting,
    responsive,
    requestEnter,

    form,
  }
}
