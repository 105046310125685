import React, { useMemo } from 'react'
import { Modal as _Modal, Txt, WeakColorSeparator, NewTabLink, theme } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import type { AppType } from '@/shared/services/interviewService/containers/InterviewContainer'

export type UnstableConnectionModalProps = {
  active: boolean
  appType: AppType
  isPc: boolean
  onClose: () => void
}

export const UnstableConnectionModal: React.VFC<UnstableConnectionModalProps> = ({
  active,
  appType,
  isPc,
  onClose,
}) => {
  const helpUrl = useMemo(() => {
    switch (appType) {
      case 'biz':
        return BIZ_EXTERNAL_URLS.help.interview
      case 'my':
        return MY_EXTERNAL_URLS.help.interview
    }
  }, [appType])

  return (
    <Modal active={active} title="トラブルシューティング" onClose={onClose} size="l">
      <>
        <Upper>
          <Row>
            <DescriptionWrapper>
              <DescriptionTitle>デバイスに高い負荷がかかっているとき</DescriptionTitle>
              <Ul>
                {isPc && (
                  <Li>
                    背景設定、雑音抑制などの機能をONにしている場合はOFFにしてください。
                    <br />※ […] ボタンを押して開くメニュー内の [背景設定] [音声 / 映像設定] から切り替えが可能です。
                  </Li>
                )}
                <Li>不要なブラウザタブや他のアプリケーションを閉じてください。</Li>
                {isPc && <Li>パソコンをご利用の場合は、ファンがふさがれていないことを確認してください。</Li>}
              </Ul>
            </DescriptionWrapper>
          </Row>
          <Row>
            <DescriptionWrapper>
              <DescriptionTitle>ネットワークが不安定なとき</DescriptionTitle>
              <Ul>
                {appType === 'biz' && <Li>軽量モードをお試しください。</Li>}
                <Li>Wi-Fiをご利用の場合は、ルーターに近づいてみてください。</Li>
                <Li>再読込をお試しください (より安定したネットワークに接続できる可能性があります)。</Li>
              </Ul>
            </DescriptionWrapper>
          </Row>
          <Row>
            <DescriptionWrapper>
              <DescriptionTitle>音声が聞こえないとき</DescriptionTitle>
              <Ul>
                <Li>デバイスの音量がゼロになっていないか確認してください。</Li>
                <Li>イヤホンをご利用の場合は、イヤホンを接続し直したあと、再読込をお試しください。</Li>
                <Li>Bluetoothイヤホンは非推奨です。有線のイヤホンかデバイスのスピーカーをご利用ください。</Li>
                <Li>音声が届かなくなった場合は、チャット機能を連絡にご利用ください。</Li>
              </Ul>
            </DescriptionWrapper>
          </Row>
          <NoticeTxt>
            上記でも接続状況が解消されない場合は、
            <NewTabLink href={helpUrl} action="click_speed_help_from_UnstableConnectionModal">
              ヘルプページ
            </NewTabLink>
            をご覧ください。
          </NoticeTxt>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonGroup>
            <CloseButton comlinkPushParams={{ action: 'click_close_button_UnstableConnectionModal' }} onClick={onClose}>
              閉じる
            </CloseButton>
          </ButtonGroup>
        </Bottom>
      </>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  width: 700px;
  max-width: 90%;
  max-height: 90%;
`

const Upper = styled.div`
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.spPortrait) {
      return css`
        padding: 20px 16px;
      `
    }
  }};
  ${({ theme }) => {
    if (theme.responsive.pc) {
      return css`
        padding: 20px;
      `
    }
  }};
`

const Row = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 26px;
  }
`

const DescriptionWrapper = styled.div`
  flex: 1;
`

const DescriptionTitle = styled.p`
  margin-bottom: 10px;
  font-weight: 700;
  font-size: ${theme.fontSize.l};
  line-height: 18px;
`

const Ul = styled.ul`
  margin-left: 20px;
`
const Li = styled.li`
  font-size: ${theme.fontSize.m};
  line-height: 18px;
`

const NoticeTxt = styled(Txt)`
  margin-top: 30px;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton).attrs({ widthSize: 'L2', height: 'L' })``
