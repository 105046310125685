/* eslint-disable @typescript-eslint/no-explicit-any */
import { Metadata } from '@blue-agency/comlink/dist/src/com_event'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { Dispatch, Middleware, MiddlewareAPI, Action, PayloadAction } from '@reduxjs/toolkit'
import { omit } from 'lodash-es'

const isPayloadAction = (action: any): action is PayloadAction<any> => {
  return action.payload !== undefined
}

const payloadToMetadata = (payload: any): Metadata | undefined => {
  const toString = (value: any): string => {
    if (typeof value === 'object' && value !== null) {
      return JSON.stringify(value)
    } else {
      return `${value}`
    }
  }

  if (typeof payload === 'object' && payload !== null) {
    const metadata: Metadata = {}
    // metabaseで見やすいようソート
    const keys = Object.keys(payload).sort()
    for (const key of keys) {
      metadata[key] = toString(payload[key])
    }
    return metadata
  } else {
    return { payload: `${payload}` }
  }
}

export const filter = (action: Action): Action | PayloadAction<unknown> | undefined => {
  switch (action.type) {
    // 変更があったときだけ以下で投げる
    // shared/services/interviewService/hooksForApp/useComlinkNetworkUnstableLevel.ts
    case 'shared/networkStatusNotificationReceived':
      return undefined
    // WebRTC Statsは別途送っており、頻度も高いのでcomlinkでは送らない
    case 'shared/updateConnectionStatsSummary':
      return undefined
    case 'my/entryRequested':
      if (isPayloadAction(action)) {
        return { type: action.type, payload: omit(action.payload, 'ownName') }
      }
      break
    case 'biz/requestEntryReceived':
      if (isPayloadAction(action)) {
        return { type: action.type, payload: omit(action.payload, 'name') }
      }
      break
    case 'shared/enterRpcCallSucceeded':
      if (isPayloadAction(action)) {
        const payload = { ...action.payload }
        payload.participants = payload.participants.map((p: any) => omit(p, 'name'))
        return { type: action.type, payload }
      }
      break
    case 'shared/participantEntered':
      if (isPayloadAction(action)) {
        return { type: action.type, payload: omit(action.payload, 'name') }
      }
      break
  }

  return action
}

export const comlinkMiddleware: Middleware =
  ({ getState }: MiddlewareAPI) =>
  (next: Dispatch) =>
  (action: Action) => {
    const action_ = filter(action)
    if (action_) {
      const metadata = isPayloadAction(action_) ? payloadToMetadata(action_.payload) : undefined
      comlinkPush(
        {
          action: action_.type,
          metadata,
        },
        { lazy: true }
      )
    }

    return next(action)
  }
