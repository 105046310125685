import { useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useDispatch, useSelector } from 'react-redux'
import { sharedSlice, SharedState } from '@/shared/services/interviewService/redux'

const useMinutesView = () => {
  const dispatch = useDispatch()

  const isMinutesView = useSelector((state: SharedState) => state.shared.isMinutesView)

  const toggleMinutesView = useCallback(() => {
    dispatch(sharedSlice.actions.toggleMinutesView())
  }, [dispatch])

  return {
    isMinutesView,
    toggleMinutesView,
  }
}

export const MinutesViewContainer = createContainer(useMinutesView)
