import React, { useEffect, useRef, useContext, useState, useCallback } from 'react'
import { theme, Txt, Icon } from '@blue-agency/rogue'
import { Switch, Case, Default, If, Then, Else } from 'react-if'
import { useSelector } from 'react-redux'
import { useMeasure } from 'react-use'
import { useInterval } from 'react-use'
import styled, { ThemeContext, css } from 'styled-components'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { CameraMutedImage } from '@/lib/meetingcomponent/CameraMutedImage'
import { LocalVideoContainer } from '@/lib/meetingcomponent/LocalVideoContainer'
import { MainVideoContainer } from '@/lib/meetingcomponent/MainVideoContainer'
import { UnavailableVideoInputImage } from '@/lib/meetingcomponent/UnavailableVideoInputImage'
import { LocalAudioWaveCircle as _LocalAudioWaveCircle } from '@/shared/services/interviewService/components/AudioWaveCircle/LocalAudioWaveCircle'
import { RemoteAudioWaveCircle as _RemoteAudioWaveCircle } from '@/shared/services/interviewService/components/AudioWaveCircle/RemoteAudioWaveCircle'
import { MinutesViewContainer } from '@/shared/services/interviewService/containers/MinutesViewContainer'
import { SpeakingParticipantContainer } from '@/shared/services/interviewService/containers/SpeakingParticipantContainer'
import { SharedState } from '@/shared/services/interviewService/redux'
import { OverlayPin } from './OverlayPin'

interface Size {
  width: number
  height: number
}

export const MainVideo: React.VFC = () => {
  const { responsive } = useContext(ThemeContext)
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { mainVideoContentTileId, mainVideoTileId, name, isRecruiter, isScreenShare, attendeeId } =
    MainVideoContainer.useContainer()
  const { tileId: localTileId, isVideoEnabled: isLocalVideoEnabled } = LocalVideoContainer.useContainer()
  const { speakingSoraClientId } = SpeakingParticipantContainer.useContainer()
  const { isMinutesView } = MinutesViewContainer.useContainer()

  const videoEl = useRef<HTMLVideoElement>(null)

  const mainVideoIsMyself = localTileId === mainVideoContentTileId
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)
  const micMutedMap = useSelector((state: SharedState) => state.shared.micMutedMap)
  const showCameraMutedImage = mainVideoIsMyself && isOwnCameraMuted
  const showUnavailableVideoInputImage = mainVideoIsMyself && isUnavailableVideoInput

  // 自分の映像がピンされている + セルフビューがオフになっているときはメインビデオを表示しない
  const isMainVideoDisabled = mainVideoIsMyself && !isLocalVideoEnabled

  const isSpeaking = Boolean(attendeeId && attendeeId === speakingSoraClientId)
  const isMuted = Boolean(attendeeId && micMutedMap[attendeeId])

  useEffect(() => {
    if (
      !audioVideo ||
      !mainVideoTileId ||
      !videoEl.current ||
      showCameraMutedImage ||
      showUnavailableVideoInputImage ||
      isMainVideoDisabled
    ) {
      return
    }

    audioVideo.bindVideoElement(mainVideoTileId, videoEl.current)

    return () => {
      if (!audioVideo) return
      const tile = audioVideo.getVideoTile(mainVideoTileId)
      if (tile) {
        audioVideo.unbindVideoElement(mainVideoTileId)
      }
    }
  }, [audioVideo, mainVideoTileId, showCameraMutedImage, isMainVideoDisabled, showUnavailableVideoInputImage])

  // 動的レイアウト計算
  const [wrapperRef, wrapperSize] = useMeasure<HTMLDivElement>()
  const [videoSize, setVideoSize] = useState<Size | undefined>(undefined)
  const handleVideoLoadedMetadata = useCallback(() => {
    if (videoEl.current?.videoWidth && videoEl.current?.videoHeight) {
      setVideoSize({ width: videoEl.current?.videoWidth, height: videoEl.current?.videoHeight })
    }
  }, [])

  useInterval(() => {
    // NOTE: 映像ソースがスマートフォンの場合に回転すると解像度が変わるがloadedmetadataは発火しない。
    //       回転時に発火するイベントがなく、timeupdateイベントを使うほど頻度が必要ではないためintervalで監視する
    if (
      videoEl.current?.videoWidth &&
      videoEl.current?.videoHeight &&
      videoSize &&
      videoEl.current.videoWidth !== videoSize.width &&
      videoEl.current.videoHeight !== videoSize.height
    ) {
      setVideoSize({ width: videoEl.current?.videoWidth, height: videoEl.current?.videoHeight })
    }
  }, 333 /* 3fps */)

  if (isMainVideoDisabled) {
    return <Wrapper isMinutesView={isMinutesView} />
  }

  // カメラオフ時のカメラ映像を画像にしている経緯
  // https://github.com/blue-agency/yashiori-front/pull/1166
  // https://github.com/blue-agency/react-im-interview/pull/27
  return (
    <Wrapper ref={wrapperRef} isMinutesView={isMinutesView}>
      <ContentWrapper isSpeaking={isSpeaking} wrapperSize={wrapperSize} videoSize={videoSize}>
        <Switch>
          <Case condition={showCameraMutedImage}>
            <CameraMutedImage />
          </Case>
          <Case condition={showUnavailableVideoInputImage}>
            <UnavailableVideoInputImage />
          </Case>
          <Default>
            {/* 音声は RemoteVideos から再生されるので、MainVideo で音声を再生する必要はない */}
            <Video muted ref={videoEl} mirror={mainVideoIsMyself} onLoadedMetadata={handleVideoLoadedMetadata} />
          </Default>
        </Switch>

        <If condition={!isScreenShare}>
          <Then>
            <Footer>
              <AudioStatus>
                <If condition={isMuted}>
                  <Then>
                    <MuteMicIcon />
                  </Then>
                  <Else>
                    <If condition={mainVideoIsMyself}>
                      <Then>
                        <LocalAudioWaveCircle />
                      </Then>
                      <Else>{!!attendeeId && <RemoteAudioWaveCircle attendeeId={attendeeId} />}</Else>
                    </If>
                  </Else>
                </If>
              </AudioStatus>

              {responsive.pc && name && (
                <Name>
                  {isRecruiter && <CompanyIcon />}
                  {name}
                </Name>
              )}
            </Footer>
          </Then>
        </If>
        <OverlayPin tileId={mainVideoContentTileId} icon="unpin" target="main" />
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isMinutesView: boolean }>`
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  background-color: ${theme.color.darkGray[1]};

  ${({ isMinutesView, theme: { responsive } }) => {
    if (isMinutesView) {
      return css`
        padding: 0;
      `
    }
    if (responsive.spPortrait) {
      return css`
        padding: 8px;
      `
    } else {
      return css`
        padding: 8px 0px 8px 8px;
      `
    }
  }}
`

const ContentWrapper = styled.div<{
  isSpeaking: boolean
  wrapperSize: Size | undefined
  videoSize: Size | undefined
}>`
  position: relative;

  ${({ wrapperSize, videoSize }) => {
    if (!wrapperSize || !videoSize) {
      return css`
        width: 100%;
        height: 100%;
      `
    }

    if (wrapperSize.width / wrapperSize.height > videoSize.width / videoSize.height) {
      // wrapperの方が横長
      return css`
        width: auto;
        height: 100%;
        aspect-ratio: ${videoSize.width} / ${videoSize.height};
      `
    } else {
      // wrapperの方が縦長
      return css`
        width: 100%;
        height: auto;
        aspect-ratio: ${videoSize.width} / ${videoSize.height};
      `
    }
  }}

  ${({ isSpeaking, videoSize }) => {
    if (isSpeaking && videoSize) {
      return css`
        outline: 3px solid ${theme.color.green[4]};
      `
    }
  }}
`

const Video = styled.video.attrs((props: { mirror: boolean }) => ({ mirror: props.mirror }))`
  width: 100%;
  height: 100%;

  ${({ mirror }) => mirror && 'transform: rotateY(180deg);'}
`

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  padding: 8px;

  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
`

const AudioStatus = styled.div`
  margin-right: 16px;
`

const Name = styled(Txt).attrs({ color: theme.color.white[1], size: 'm' })`
  text-shadow: 0px 0px 2px ${theme.color.black[1]}00;

  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://nuconeco.net/iphone-safari-type/
  -webkit-text-size-adjust: 100%;

  display: flex;
  align-items: center;
`

const CompanyIcon = styled(Icon).attrs({ name: 'company' })`
  filter: drop-shadow(0px 0px 2px ${theme.color.black[1]});
  margin-right: 4px;
`

const LocalAudioWaveCircle = styled(_LocalAudioWaveCircle).attrs({
  width: 20,
  height: 20,
  barWidth: 2.5,
  barPadding: 2.5,
  baseBarHeight: 0,
  centerMaxBarHeight: 13,
  sideMaxBarHeight: 8,
})``

const RemoteAudioWaveCircle = styled(_RemoteAudioWaveCircle).attrs({
  width: 20,
  height: 20,
  barWidth: 2.5,
  barPadding: 2.5,
  baseBarHeight: 0,
  centerMaxBarHeight: 13,
  sideMaxBarHeight: 8,
})``

const MuteMicIcon = styled(Icon).attrs({ name: 'mute-mike' })`
  width: 12px;
  height: 12px;
  padding: 4px;

  border-radius: 50%;
  background-color: ${theme.color.red[2]};
  color: ${theme.color.white[1]};
  opacity: 0.9;
`
