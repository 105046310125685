export const LOCAL_STORAGE_KEY = {
  interviewSessionToken: {
    user: 'my/interviewSessionToken/user',
    screenShare: 'my/interviewSessionToken/screenShare',
    screenShareRecv: 'my/interviewSessionToken/screenShareRecv',
  },
  interview: {
    reEntryName: 'my/reEntryName',
  },
}
