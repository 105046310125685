import { useEffect, useMemo } from 'react'
import { useUserAgent } from '@blue-agency/react-utils'
import { alertToast, toast } from '@blue-agency/rogue'
import { findCandidateDevice } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { VideoInputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { isPcByUA } from '@/shared/utils/isPcByUA'
import { useMuteCamera } from './useMuteCamera'

export const useCameraUnpluged = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { videoInputs, selectedVideoInputDevice, setSelectedVideoInputDevice } = VideoInputContainer.useContainer()
  const muteCamera = useMuteCamera()

  const ua = useUserAgent()
  const isPc = useMemo(() => isPcByUA(ua), [ua])

  useEffect(() => {
    // デバイス変更がない環境では無視
    if (!isPc) return

    // 選択中のデバイスが抜かれたときの対処
    if (!videoInputs || videoInputs.length === 0) return
    if (!selectedVideoInputDevice) return

    if (videoInputs.some((d) => d.deviceId === selectedVideoInputDevice)) return

    const fn = async () => {
      if (!audioVideo) return

      const device = findCandidateDevice(videoInputs)
      if (device) {
        // 1度止めないと動かないので止める
        audioVideo.stopLocalVideoTile()
        // プライバシーのためいったんカメラオフ
        muteCamera()
        // unmute時にMeetingManagerに反映されるのでstateだけ変更する
        setSelectedVideoInputDevice(device.deviceId)
        audioVideo.startLocalVideoTile()
        toast(`カメラを ${device.label} に切り替えました`)
      } else {
        alertToast('カメラデバイスが見つかりませんでした')
      }
    }

    fn()
  }, [isPc, meetingManager, audioVideo, videoInputs, selectedVideoInputDevice, muteCamera, setSelectedVideoInputDevice])
}
