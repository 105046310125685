import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useRequestGetEntryRequest } from '@/biz/services/bffService'
import { bizSlice } from '../../redux/bizSlice'

export function useEntryRequestHandler() {
  const dispatch = useDispatch()

  const { requestGetEntryRequest } = useRequestGetEntryRequest()

  return useCallback(
    async (interviewGuid: string, entryRequestGuid: string) => {
      const res = await requestGetEntryRequest(interviewGuid, entryRequestGuid)

      dispatch(
        bizSlice.actions.requestEntryReceived({
          entryRequestGuid: entryRequestGuid,
          name: res.getName(),
        })
      )
    },
    [dispatch, requestGetEntryRequest]
  )
}
