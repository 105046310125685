import React from 'react'
import { ListInterviewTranscriptionsResponse } from '@blue-agency/proton/web/v2/biz_skywalker_bff'

export type ListInterviewTranscription = {
  guid: string
  soraClientId: string
  startTime: number
  endTime: number
  message: string
  iconType: 'biz' | 'my'
  name: string
  pins: ListInterviewTranscriptionPin[]
}
export type ListInterviewTranscriptionPin = {
  guid: string
  fullName: string
  staffGuid: string
  pinType: ListInterviewTranscriptionsResponse.InterviewPinningType
}

export function useInterviewMinute() {
  const bindTranscriptionsOfSameSpeaker = (transcriptions: ListInterviewTranscription[]) => {
    if (transcriptions.length < 2) return transcriptions

    const boundTranscriptions = []
    for (const head of transcriptions) {
      const last = boundTranscriptions[boundTranscriptions.length - 1]
      if (last === undefined) {
        boundTranscriptions.push(head)
        continue
      }

      // 話者が同一でない場合は結合しない
      if (last.soraClientId !== head.soraClientId) {
        boundTranscriptions.push(head)
        continue
      }

      // 時間が大きく経過している場合は結合しない
      if (head.startTime - last.endTime > 30 * 1000) {
        boundTranscriptions.push(head)
        continue
      }

      // 一文が短すぎる場合は結合する
      if (last.message.length < 80) {
        last.message = last.message + ' ' + head.message
      } else {
        boundTranscriptions.push(head)
      }
    }

    return boundTranscriptions
  }

  const replaceFillerMessage = (message: string) => {
    const splitMessages = message.split('%')
    return splitMessages.map((msg, index): React.ReactNode => {
      if (index % 2 === 0) {
        return <React.Fragment key={index}>{msg}</React.Fragment>
      } else {
        if (index === splitMessages.length - 1) {
          return <React.Fragment key={index}>%{msg}</React.Fragment>
        }
        return <span key={index}>{msg}</span>
      }
    })
  }

  return {
    bindTranscriptionsOfSameSpeaker,
    replaceFillerMessage,
  }
}
