import { Sora, ConnectionOptions, DataChannelConfiguration } from '@/lib/interview-sdk-js'

const VIDEO_CODEC_TYPE = 'VP8'
const DEFAULT_VIDEO_BITRATE = 600
const USE_DATACHANNEL_SIGNALING = true
const IGNORE_DISCONNECT_WEBSOCKET = true

export type Args = {
  webrtcHost: string
  metadata: {
    session_token: string
    invitation_token: string
    is_screen_shared?: boolean
    is_screen_sharing_recv?: boolean
  }
  channelId: string
  videoBitrate?: number
  dataChannels?: DataChannelConfiguration[]
}

export function buildPublisher({ webrtcHost, channelId, metadata, videoBitrate, dataChannels }: Args) {
  const connection = Sora.connection(`wss://${webrtcHost}/signaling`, false)
  const options: ConnectionOptions = {
    multistream: true,
    videoCodecType: VIDEO_CODEC_TYPE,
    videoBitRate: videoBitrate || DEFAULT_VIDEO_BITRATE,
    dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
    ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    dataChannels,
  }
  return connection.sendrecv(channelId, metadata, options)
}

export function buildSendonlyPublisher({ webrtcHost, channelId, metadata, videoBitrate, dataChannels }: Args) {
  const connection = Sora.connection(`wss://${webrtcHost}/signaling`, false)
  const options: ConnectionOptions = {
    multistream: true,
    videoCodecType: VIDEO_CODEC_TYPE,
    videoBitRate: videoBitrate || DEFAULT_VIDEO_BITRATE,
    dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
    ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    dataChannels,
  }
  return connection.sendonly(channelId, metadata, options)
}

export function buildSubscriber({ webrtcHost, channelId, metadata, videoBitrate, dataChannels }: Args) {
  const connection = Sora.connection(`wss://${webrtcHost}/signaling`, false)
  const options: ConnectionOptions = {
    multistream: true,
    videoCodecType: VIDEO_CODEC_TYPE,
    videoBitRate: videoBitrate || DEFAULT_VIDEO_BITRATE,
    dataChannelSignaling: USE_DATACHANNEL_SIGNALING,
    ignoreDisconnectWebSocket: IGNORE_DISCONNECT_WEBSOCKET,
    dataChannels,
  }
  return connection.recvonly(channelId, metadata, options)
}
