import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { participantsSelector } from '@/shared/services/interviewService/redux'
import { isRecruiter, isScreenShare } from '@/shared/services/interviewService/types'

export function useGetParticipantInfoForDisplay(attendeeId: string | undefined) {
  const participants = useSelector(participantsSelector, shallowEqual)

  const participant = useMemo(() => {
    return participants.find((p) => p.soraClientId === attendeeId)
  }, [participants, attendeeId])

  const screenShare = useMemo(() => {
    if (participant === undefined) {
      return false
    }
    return isScreenShare(participant.role)
  }, [participant])

  const name = useMemo(() => {
    if (participant === undefined) {
      return
    }
    if (isScreenShare(participant.role)) {
      return '画面共有'
    }
    return participant.name
  }, [participant])

  return {
    name,
    isScreenShare: screenShare,
    isRecruiter: participant !== undefined ? isRecruiter(participant.role) : false,
  }
}
