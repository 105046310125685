import { alertToast } from '@blue-agency/rogue'
import { LightAdjustmentGpuProcessor, llieModelNames } from '@shiguredo/light-adjustment-gpu/dist/light_adjustment_gpu'
import { VirtualBackgroundProcessor } from '@shiguredo/virtual-background'
import { LIGHT_ADJUSTMENT_ASSET_PATH, VIRTUAL_BACKGROUND_ASSET_PATH } from './asset'

let virtualBackgroundProcessor: VirtualBackgroundProcessor | undefined = undefined
let lightAdjustmentProcessor: LightAdjustmentGpuProcessor | undefined = undefined

export async function transformStreamWithMediaProcessorBGBlur(
  stream: MediaStream,
  blurSize: number
): Promise<{
  stream: MediaStream
  stop: () => void
}> {
  if (!virtualBackgroundProcessor) {
    virtualBackgroundProcessor = new VirtualBackgroundProcessor(VIRTUAL_BACKGROUND_ASSET_PATH)
  }
  const track = stream.getVideoTracks()[0]
  if (!track) {
    return { stream, stop: () => {} }
  }
  const blurredTrack = await virtualBackgroundProcessor
    .startProcessing(track as MediaStreamVideoTrack, {
      blurRadius: blurSize,
    })
    .catch(() => {
      alertToast(
        'ファイルの読込エラーが発生しました。再読込して再度お試しください。改善されない場合は、ご利用環境をご確認ください。'
      )
      return track as MediaStreamVideoTrack
    })
  const audioTrack = stream.getAudioTracks()[0]
  if (audioTrack) {
    return {
      stream: new MediaStream([blurredTrack, audioTrack]),
      stop: () => virtualBackgroundProcessor?.stopProcessing(),
    }
  } else {
    return { stream: new MediaStream([blurredTrack]), stop: () => virtualBackgroundProcessor?.stopProcessing() }
  }
}

export async function transformStreamWithMediaProcessorBGReplace(
  stream: MediaStream,
  img: HTMLImageElement
): Promise<{
  stream: MediaStream
  stop: () => void
}> {
  if (!virtualBackgroundProcessor) {
    virtualBackgroundProcessor = new VirtualBackgroundProcessor(VIRTUAL_BACKGROUND_ASSET_PATH)
  }
  const track = stream.getVideoTracks()[0]
  if (!track) {
    return { stream, stop: () => {} }
  }
  const audioTrack = stream.getAudioTracks()[0]
  const replacedTrack = await virtualBackgroundProcessor
    .startProcessing(track as MediaStreamVideoTrack, {
      backgroundImage: img,
    })
    .catch(() => {
      alertToast(
        'ファイルの読込エラーが発生しました。再読込して再度お試しください。改善されない場合は、ご利用環境をご確認ください。'
      )
      return track as MediaStreamVideoTrack
    })
  if (audioTrack) {
    return {
      stream: new MediaStream([replacedTrack, audioTrack]),
      stop: () => virtualBackgroundProcessor?.stopProcessing(),
    }
  } else {
    return { stream: new MediaStream([replacedTrack]), stop: () => virtualBackgroundProcessor?.stopProcessing() }
  }
}

export async function transformStreamWithMediaProcessorLightAdjustment(
  stream: MediaStream,
  level: number
): Promise<{
  stream: MediaStream
  stop: () => void
}> {
  if (!lightAdjustmentProcessor) {
    lightAdjustmentProcessor = new LightAdjustmentGpuProcessor(
      LIGHT_ADJUSTMENT_ASSET_PATH,
      llieModelNames.semanticGuidedLlie648x360,
      level
    )
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(lightAdjustmentProcessor as any).setStrength(level)
  const track = stream.getVideoTracks()[0]
  if (!track) {
    return { stream, stop: () => {} }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ;(lightAdjustmentProcessor as any).stopProcessing()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const adjustmentTrack = await (lightAdjustmentProcessor as any)
    .startProcessing(track as MediaStreamVideoTrack, {})
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .catch((e: any) => {
      alertToast(
        'ファイルの読込エラーが発生しました。再読込して再度お試しください。改善されない場合は、ご利用環境をご確認ください。'
      )
      return track as MediaStreamVideoTrack
    })
  const audioTrack = stream.getAudioTracks()[0]
  if (audioTrack) {
    return {
      stream: new MediaStream([adjustmentTrack, audioTrack]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stop: () => (lightAdjustmentProcessor as any).stopProcessing(),
    }
  } else {
    return {
      stream: new MediaStream([adjustmentTrack]),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stop: () => (lightAdjustmentProcessor as any).stopProcessing(),
    }
  }
}
