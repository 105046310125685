import { useEffect } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import {
  DataChannelMessagingContainer,
  labelNetworkUnstableLevel,
} from '@/lib/meetingcomponent/DataChannelMessagingContainer'
import { participantsSelector, isDataChannelReadySelector, SharedState } from '@/shared/services/interviewService/redux'

export const useBroadcastNetworkUnstableLevel = () => {
  const { sendMessage } = DataChannelMessagingContainer.useContainer()

  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)
  const isDataChannelReady = useSelector(isDataChannelReadySelector)
  const networkUnstableLevel = useSelector((state: SharedState) => state.shared.networkUnstableLevel)
  const participants = useSelector(participantsSelector, shallowEqual)

  useEffect(() => {
    if (networkUnstableLevel === undefined) {
      return
    }
    if (ownSoraClientId === undefined) {
      return
    }
    if (!isDataChannelReady) {
      return
    }
    sendMessage(labelNetworkUnstableLevel, {
      soraClientId: ownSoraClientId,
      unstableLevel: networkUnstableLevel,
    })
  }, [networkUnstableLevel, ownSoraClientId, participants, isDataChannelReady, sendMessage])
}
