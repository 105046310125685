import { useCallback, useEffect } from 'react'
import { Txt, Icon, theme, toast, alertToast, CircularProgress, NewTabLink } from '@blue-agency/rogue'
import { PrimaryButton as _PrimaryButton } from '@blue-agency/rogue/im'
import Markdown from 'markdown-to-jsx'
import { useCopyToClipboard } from 'react-use'
import styled from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { ReactComponent as FailedIllust } from './svg/failed.svg'
import { ReactComponent as InitialIllust } from './svg/initial.svg'
import { ReactComponent as ProgressIllust } from './svg/progress.svg'

type Props =
  | {
      status: 'completed'
      summary: string
    }
  | {
      status: 'failed'
    }
  | {
      status: 'progress'
    }
  | {
      status: 'enabled'
      onCreate: () => void
    }
  | {
      status: 'disabled'
    }
  | {
      status: 'initial'
    }

export type SummaryStatus = Props['status']

export const SummaryCard = (props: Props) => {
  const [state, copyToClipboard] = useCopyToClipboard()

  const copySummary = useCallback(() => {
    if (props.status !== 'completed') return
    copyToClipboard(props.summary)
  }, [copyToClipboard, props])

  useEffect(() => {
    if (state.value) {
      toast('要約をコピーしました')
    }
    if (state.error) {
      alertToast('要約のコピーに失敗しました')
    }
  }, [state])

  return (
    <Wrapper>
      <Header>
        <Txt size="l">AI要約</Txt>
        <CopyIcon enabled={props.status === 'completed'} onClick={copySummary} />
      </Header>
      <Body>
        {props.status === 'completed' && (
          <SummaryTxt>
            <Markdown>{props.summary}</Markdown>
          </SummaryTxt>
        )}
        {props.status === 'failed' && (
          <>
            <IllustWrapper>
              <FailedIllust style={{ maxWidth: '136px' }} />
            </IllustWrapper>
            <Txt size="l">要約データの生成に失敗しました。</Txt>
          </>
        )}
        {props.status === 'progress' && (
          <>
            <IllustWrapper>
              <ProgressIllust style={{ maxWidth: '100px' }} />
            </IllustWrapper>
            <Txt size="l">要約中です。しばらくお待ち下さい。</Txt>
            <IllustWrapper>
              <CircularProgress borderWidth={1} color={theme.color.red['iM']} />
            </IllustWrapper>
          </>
        )}
        {props.status === 'enabled' && (
          <>
            <IllustWrapper>
              <InitialIllust style={{ maxWidth: '280px' }} />
            </IllustWrapper>
            <Txt size="l">AIによる面接内容の要約をご利用いただけます。</Txt>
            <Txt>※要約処理に2~3分程度かかります。</Txt>
            <PrimaryButton
              widthSize="L1"
              comlinkPushParams={{
                action: 'click_create_summary_button',
              }}
              onClick={props.onCreate}
            >
              面接内容を要約
            </PrimaryButton>
            <HelpLinkWrapper>
              <NewTabLink href={BIZ_EXTERNAL_URLS.help.summarization} action="open_summarization_help_page">
                要約機能についてのよくある質問はこちら
              </NewTabLink>
            </HelpLinkWrapper>
          </>
        )}
        {props.status === 'disabled' && (
          <>
            <IllustWrapper>
              <InitialIllust style={{ maxWidth: '280px' }} />
            </IllustWrapper>
            <Txt size="l">AI要約のご利用は別途ご契約が必要です。</Txt>
          </>
        )}
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${theme.color.gray[4]};
  border-radius: 4px;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: ${theme.color.navy[3]};
  border-bottom: solid 1px ${theme.color.gray[4]};
  border-radius: 4px 4px 0 0;
`

const CopyIcon = styled(Icon).attrs({
  name: 'copy',
  size: 'l',
})<{ enabled: boolean }>`
  color: ${({ enabled }) => (enabled ? theme.color.navy[1] : theme.color.gray[2])};
  &:hover {
    cursor: ${({ enabled }) => (enabled ? 'pointer' : 'not-allowed')};
  }
`

const Body = styled.div`
  padding: 0 12px;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SummaryTxt = styled(Txt)`
  padding: 12px 0;
  width: 100%;
  h3 {
    margin-bottom: 8px;

    :not(:first-child) {
      margin-top: 20px;
    }
  }
  ul {
    padding-left: 20px;
  }
`

const IllustWrapper = styled.div`
  margin: 20px auto;
  & > svg {
    width: 100%;
    height: 100%;
  }
`

const PrimaryButton = styled(_PrimaryButton)`
  margin-top: 12px;
`

const HelpLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 32px;
  align-items: end;
`
