import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { participantsWithoutContentShareSelector, SharedState } from '..'

export const remoteTilesWithoutContentShareSelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.remoteTiles,
  participantsWithoutContentShareSelector,
  (remoteTiles, participants) => {
    const res: typeof remoteTiles = {}
    participants.forEach((p) => {
      const remoteTile = remoteTiles[p.soraClientId]
      if (remoteTile !== undefined) {
        res[p.soraClientId] = remoteTile
      }
    })
    return res
  }
)
