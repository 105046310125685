import React from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, SecondaryDangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { InRoomContainer } from './containers/InRoomContainer'

export const LeaveModal: React.VFC = () => {
  const { leaveModal, leaveInterview } = InRoomContainer.useContainer()
  const { active, close } = leaveModal

  return (
    <Modal active={active} onClose={close} title="退室の確認" size="s">
      <Upper>
        <Txt>
          退室しようとしています。
          <br />
          再入室には、参加リクエスト承認が必要となりますので、ご注意ください。
        </Txt>
      </Upper>
      <WeakColorSeparator />
      <ButtonGroup>
        <TertiaryButton
          onClick={close}
          widthSize="L1"
          comlinkPushParams={{ action: 'click_cancel_button_on_leave_modal' }}
        >
          キャンセル
        </TertiaryButton>
        <SecondaryDangerButton
          onClick={leaveInterview}
          widthSize="L1"
          comlinkPushParams={{ action: 'click_leave_interview_button_on_leave_modal' }}
        >
          退室
        </SecondaryDangerButton>
      </ButtonGroup>
    </Modal>
  )
}

const Upper = styled.div`
  margin: 16px 20px 40px;
`

const ButtonGroup = styled.div`
  display: flex;
  padding: 20px;
  justify-content: space-between;
`
