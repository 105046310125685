import { initImSharedFront } from '@blue-agency/im-shared-front'
import assert from 'assert'

function initializeImSharedFront() {
  const bizAnakinFront = process.env.REACT_APP_BIZ_ANAKIN_FRONT_HOST
  assert(bizAnakinFront)

  const bizHuttFront = process.env.REACT_APP_BIZ_HUTT_FRONT_HOST
  assert(bizHuttFront)

  const myHuttFront = process.env.REACT_APP_MY_HUTT_FRONT_HOST
  assert(myHuttFront)

  const logoutEndpoint = process.env.REACT_APP_BIZ_LOGOUT_ENDPOINT
  assert(logoutEndpoint)

  const openidClientId = process.env.REACT_APP_BIZ_OPENID_CLIENT_ID
  assert(openidClientId)

  const openidConfigurationEndpoint = process.env.REACT_APP_BIZ_OPENID_CONFIGURATION_ENDPOINT
  assert(openidConfigurationEndpoint)

  const bffHost = (() => {
    if (window.location.pathname.includes('/biz/')) {
      return process.env.REACT_APP_BIZ_API_HOST
    }
    if (window.location.pathname.includes('/my/')) {
      return process.env.REACT_APP_MY_API_HOST
    }
    return ''
  })()
  assert(bffHost)

  const techtouchOrg = (() => {
    if (window.location.pathname.includes('/biz/')) {
      return process.env.REACT_APP_TECHTOUCH_ORGANIZATION_UUID ?? ''
    }
    return ''
  })()
  const techtouchAdmin = process.env.REACT_APP_TECHTOUCH_ADMINISTORATOR ?? ''
  const techtouchNormal = process.env.REACT_APP_TECHTOUCH_NORMAL ?? ''
  const techtouchAssistant = process.env.REACT_APP_TECHTOUCH_ASSISTANT ?? ''
  const techtouchUserID = process.env.REACT_APP_TECHTOUCH_USER_ID ?? ''
  const techtouchOrganizationID = process.env.REACT_APP_TECHTOUCH_ORGANIZATION_ID ?? ''
  if (techtouchOrg !== '') {
    assert(techtouchAdmin)
    assert(techtouchNormal)
    assert(techtouchAssistant)
    assert(techtouchUserID)
    assert(techtouchOrganizationID)
  }

  initImSharedFront({
    applicationName: 'skywalker-front',
    host: {
      bizAnakinFront,
      bizHuttFront,
      myHuttFront,
      skywalkerFront: window.location.origin,
      bff: bffHost,
    },
    logoutEndpoint,
    openidClientId,
    openidConfigurationEndpoint,
    signInPathPrefix: '/biz/',
    techtouch: {
      organizationUuid: techtouchOrg,
      administratorProperty: techtouchAdmin,
      normalProperty: techtouchNormal,
      assistantProperty: techtouchAssistant,
      userIdProperty: techtouchUserID,
      organizationIdProperty: techtouchOrganizationID,
    },
  })
}

initializeImSharedFront()
