export const MY_EXTERNAL_URLS = {
  help: {
    top: 'https://im-ac.zendesk.com/hc/ja',
    interview: 'https://im-ac.zendesk.com/hc/ja/articles/900004599386',
    screenShare: 'https://im-ac.zendesk.com/hc/ja/articles/900005552043',
    environment: {
      os: 'https://im-ac.zendesk.com/hc/ja/articles/900004594046',
      speaker: 'https://im-ac.zendesk.com/hc/ja/articles/900004599406',
      cameraAndMic: 'https://im-ac.zendesk.com/hc/ja/articles/900005552103',
      speed: 'https://im-ac.zendesk.com/hc/ja/articles/900004599426',
      mannedSupport: 'https://im-ac.zendesk.com/hc/ja/articles/4409490770585',
    },
    changeDevice: 'https://im-ac.zendesk.com/hc/ja/articles/900004764806',
    backgroundSetting: 'https://im-ac.zendesk.com/hc/ja/articles/4407393724313',
  },
  terms: 'https://interview-maker.jp/terms/participant-terms/',
}
