import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Modal, Body, modalHeaderHeight, modalMaxHeight, modalMaxWidth } from '@/shared/components/ManualModal/Layout'
import { WindowSizeContainer } from '@/shared/containers/WindowSizeContainer'
import { ManualModalContainer } from '../containers/ManualModalContainer'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName<{
  active: boolean
}>

export const ManualModal = provide(StepContainer)((props: Props): React.ReactElement => {
  const { handleManualModalClose } = ManualModalContainer.useContainer()
  const { manualModalBody } = StepContainer.useContainer()
  const windowSize = WindowSizeContainer.useContainer()
  const modalHeight = Math.min(modalMaxHeight, windowSize.height - 60)
  const modalWidth = Math.min(modalMaxWidth, windowSize.width - 20)
  const bodyHeight = modalHeight - modalHeaderHeight
  return (
    <Modal
      className={props.className}
      active={props.active}
      onClose={handleManualModalClose}
      titleSize="l"
      title="操作方法"
      height={modalHeight}
      width={modalWidth}
      stopScroll
    >
      <Body height={bodyHeight}>{manualModalBody}</Body>
    </Modal>
  )
})
