import React, { useEffect, useRef, useState } from 'react'
import { theme, useIsIPad } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { BizInterviewGuideContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { MainVideo } from '@/lib/meetingcomponent/MainVideo'
import { MinutesViewChatList } from '@/shared/services/interviewService/components/ChatList/MinutesViewChatList'
import { PcInterviewGuide } from '@/shared/services/interviewService/components/InterviewGuide/PcInterviewGuide'
import { InterviewMinutes } from '@/shared/services/interviewService/components/InterviewMinutes'
import {
  LeftSectionResizer,
  InterviewMinutesResizer,
  VideoAreaResizer,
} from '@/shared/services/interviewService/components/Layout/MinutesViewResizer'
import {
  getLocalStorageLefSectionWithPercent,
  setMinutesViewSizesLocalStorage,
} from '@/shared/services/interviewService/components/Layout/MinutesViewSizesLocalStorage'
import { MinutesViewVideos } from '@/shared/services/interviewService/components/Layout/MinutesViewVideos'
import { Videos } from '@/shared/services/interviewService/components/Layout/Videos'
import { useDebouncedResizeObserver } from '@/shared/services/interviewService/components/Layout/useDebouncedResizeObserver'
import { useMinutesViewAreaSizes } from '@/shared/services/interviewService/components/Layout/useMinutesViewAreaSizes'
import { MinutesViewPcControls } from '@/shared/services/interviewService/components/MinutesViewPcControls'
import { MinutesViewPrivateMemo } from '@/shared/services/interviewService/components/minutesViewPrivateMemo'
import { ChatUnreadContainer } from '@/shared/services/interviewService/containers/ChatUnreadContainer'
import { VideosLayoutContainer } from '@/shared/services/interviewService/containers/VideosLayoutContainer'
import { SharedState } from '@/shared/services/interviewService/redux'
import { MinutesViewWaitingEntryRequestMessage } from '../WaitingEntryRequestMessage'

export type MinutesViewConsumerProps = {
  interviewGuid: string
  renderStartScreenShareModal: () => JSX.Element
  renderFinishScreenShareModal: () => JSX.Element
  renderScreenShareLimitModal: () => JSX.Element
  isChatAreaOpen: boolean
  hasInterviewGuide: boolean
  isInterviewTranscriptionEnabled: boolean
}

const LEFT_SECTION_MIN_WIDTH = 430
const RIGHT_SECTION_MIN_WIDTH = 300
const MINUTES_AREA_MIN_HEIGHT = 200
const PRIVATE_MEMO_AREA_MIN_HEIGHT = 200
const VIDEO_AREA_MIN_HEIGHT = 200
const INTERVIEW_GUIDE_MIN_HEIGHT = 100

type MinutesViewAreaSize = {
  percentSize: number
  pixelSize?: number
}

type MinutesViewAreaSizes = {
  leftSectionWithSize: MinutesViewAreaSize // カメラ・ガイド（設定されてるガイドがあれば）領域の横幅
  interviewMinutesAreaHeightSize: MinutesViewAreaSize // 文字起こし領域の高さ
  privateMemoAreaHeightSize: MinutesViewAreaSize // プライベートメモ領域の高さ
  videoAreaHeightSize: MinutesViewAreaSize // ビデオ領域の高さ（ガイド設定がない場合、percentSize100で固定される）
  interviewGuideAreaHeightSize: MinutesViewAreaSize // ガイド領域の高さ
}

const getDefaultMinutesViewAreaSizes = (hasInterviewGuide: boolean): MinutesViewAreaSizes => {
  const localStorageLeftSectionWith = getLocalStorageLefSectionWithPercent('MINUTES_VIEW_LEFT_SECTION_WIDTH')
  const localStorageInterviewAreaHeight = getLocalStorageLefSectionWithPercent(
    'MINUTES_VIEW_INTERVIEW_MINUTES_AREA_HEIGHT'
  )
  const localStorageVideoAreaHeight = getLocalStorageLefSectionWithPercent('MINUTES_VIEW_VIDEO_AREA_HEIGHT')
  return {
    leftSectionWithSize: {
      percentSize: localStorageLeftSectionWith ? localStorageLeftSectionWith : 57,
    },
    interviewMinutesAreaHeightSize: {
      percentSize: localStorageInterviewAreaHeight ? localStorageInterviewAreaHeight : 55,
    },
    privateMemoAreaHeightSize: {
      percentSize: localStorageInterviewAreaHeight ? 100 - localStorageInterviewAreaHeight : 45,
    },
    videoAreaHeightSize: {
      percentSize: hasInterviewGuide ? (localStorageVideoAreaHeight ? localStorageVideoAreaHeight : 75) : 100,
    },
    interviewGuideAreaHeightSize: {
      percentSize: localStorageVideoAreaHeight ? 100 - localStorageVideoAreaHeight : 25,
    },
  }
}

export const MinutesViewLayoutConsumer: React.VFC<MinutesViewConsumerProps> = React.memo((props) => {
  const isNotStarted = useSelector((state: SharedState) => state.shared.interviewState === 'NotStarted')
  const { isIPad } = useIsIPad()
  const userSignalingStatus = useSelector((state: SharedState) => state.shared.userSignalingStatus)
  const subVideoWrapperResizeObserver = useDebouncedResizeObserver(30)
  const DUMMY_ENTIRE_AREA_SIZE = {
    width: 610,
    height: 410,
  }

  const mainWrapperRef = useRef<HTMLDivElement>(null)
  const leftSectionRef = useRef<HTMLDivElement>(null)
  const rightSectionRef = useRef<HTMLDivElement>(null)
  const interviewMinutesAreaRef = useRef<HTMLDivElement>(null)
  const privateMemoAreRef = useRef<HTMLDivElement>(null)
  const videoAreaRef = useRef<HTMLDivElement>(null)
  const interviewGuideAreaRef = useRef<HTMLDivElement>(null)

  const [minutesViewAreaSizes, setMinutesViewAreaSizes] = useState<MinutesViewAreaSizes>(
    getDefaultMinutesViewAreaSizes(props.hasInterviewGuide)
  )

  useEffect(() => {
    if (props.hasInterviewGuide) {
      const localStorageVideoAreaHeight = getLocalStorageLefSectionWithPercent('MINUTES_VIEW_VIDEO_AREA_HEIGHT')
      setMinutesViewAreaSizes((prevState) => ({
        ...prevState,
        videoAreaHeightSize: {
          pixelSize: videoAreaRef.current?.offsetHeight,
          percentSize: localStorageVideoAreaHeight ? localStorageVideoAreaHeight : 75,
        },
        interviewGuideAreaHeightSize: {
          percentSize: localStorageVideoAreaHeight ? 100 - localStorageVideoAreaHeight : 25,
          pixelSize: interviewGuideAreaRef.current?.offsetHeight,
        },
      }))
    }
  }, [props.hasInterviewGuide, videoAreaRef, interviewGuideAreaRef])

  useEffect(() => {
    if (
      leftSectionRef.current &&
      interviewMinutesAreaRef.current &&
      videoAreaRef.current &&
      privateMemoAreRef.current
    ) {
      const leftSectionWithPixel = leftSectionRef.current?.offsetWidth
      setMinutesViewAreaSizes((prevState) => ({
        ...prevState,
        leftSectionWithSize: {
          ...prevState.leftSectionWithSize,
          pixelSize: leftSectionWithPixel < LEFT_SECTION_MIN_WIDTH ? LEFT_SECTION_MIN_WIDTH : leftSectionWithPixel,
        },
        interviewMinutesAreaHeightSize: {
          ...prevState.interviewMinutesAreaHeightSize,
          pixelSize: interviewMinutesAreaRef.current?.offsetHeight,
        },
        privateMemoAreaHeightSize: {
          ...prevState.privateMemoAreaHeightSize,
          pixelSize: privateMemoAreRef.current?.offsetHeight,
        },
      }))
    }
  }, [leftSectionRef, interviewMinutesAreaRef, privateMemoAreRef, videoAreaRef])

  const handleLeftSectionWidth = (arg: number) => {
    if (
      leftSectionRef.current &&
      rightSectionRef.current &&
      minutesViewAreaSizes.leftSectionWithSize.pixelSize &&
      mainWrapperRef.current
    ) {
      if (
        minutesViewAreaSizes.leftSectionWithSize.pixelSize + arg <= LEFT_SECTION_MIN_WIDTH ||
        rightSectionRef.current?.offsetWidth - arg <= RIGHT_SECTION_MIN_WIDTH
      ) {
        return
      }
      setMinutesViewAreaSizes((prevState) => ({
        ...prevState,
        leftSectionWithSize: {
          ...prevState.leftSectionWithSize,
          pixelSize: prevState.leftSectionWithSize.pixelSize ? prevState.leftSectionWithSize.pixelSize + arg : 0,
        },
      }))
      setMinutesViewSizesLocalStorage(
        'MINUTES_VIEW_LEFT_SECTION_WIDTH',
        Math.floor(
          ((minutesViewAreaSizes.leftSectionWithSize.pixelSize + arg) / mainWrapperRef.current?.offsetWidth) * 100
        )
      )
    }
  }

  const handleInterviewMinutesAreaHeight = (arg: number) => {
    if (
      minutesViewAreaSizes.interviewMinutesAreaHeightSize.pixelSize &&
      minutesViewAreaSizes.privateMemoAreaHeightSize.pixelSize &&
      mainWrapperRef.current
    ) {
      if (
        minutesViewAreaSizes.interviewMinutesAreaHeightSize.pixelSize + arg <= MINUTES_AREA_MIN_HEIGHT ||
        minutesViewAreaSizes.privateMemoAreaHeightSize.pixelSize - arg <= PRIVATE_MEMO_AREA_MIN_HEIGHT
      ) {
        return
      }
      setMinutesViewAreaSizes((prevState) => ({
        ...prevState,
        interviewMinutesAreaHeightSize: {
          ...prevState.interviewMinutesAreaHeightSize,
          pixelSize: prevState.interviewMinutesAreaHeightSize.pixelSize
            ? prevState.interviewMinutesAreaHeightSize.pixelSize + arg
            : 0,
        },
        privateMemoAreaHeightSize: {
          ...prevState.privateMemoAreaHeightSize,
          pixelSize: prevState.privateMemoAreaHeightSize.pixelSize
            ? prevState.privateMemoAreaHeightSize.pixelSize - arg
            : 0,
        },
      }))
      setMinutesViewSizesLocalStorage(
        'MINUTES_VIEW_INTERVIEW_MINUTES_AREA_HEIGHT',
        Math.floor(
          ((minutesViewAreaSizes.interviewMinutesAreaHeightSize.pixelSize + arg) /
            mainWrapperRef.current?.offsetHeight) *
            100
        )
      )
    }
  }

  const handleVideoAreaHeight = (arg: number) => {
    if (!props.hasInterviewGuide) {
      return
    }
    if (
      minutesViewAreaSizes.videoAreaHeightSize.pixelSize &&
      minutesViewAreaSizes.interviewGuideAreaHeightSize.pixelSize
    ) {
      if (
        minutesViewAreaSizes.videoAreaHeightSize.pixelSize + arg <= VIDEO_AREA_MIN_HEIGHT ||
        minutesViewAreaSizes.interviewGuideAreaHeightSize.pixelSize - arg <= INTERVIEW_GUIDE_MIN_HEIGHT
      )
        return
    }
    if (
      minutesViewAreaSizes.videoAreaHeightSize.pixelSize &&
      minutesViewAreaSizes.interviewGuideAreaHeightSize.pixelSize &&
      mainWrapperRef.current
    ) {
      setMinutesViewAreaSizes((prevState) => ({
        ...prevState,
        videoAreaHeightSize: {
          ...prevState.videoAreaHeightSize,
          pixelSize: prevState.videoAreaHeightSize.pixelSize ? prevState.videoAreaHeightSize.pixelSize + arg : 0,
        },
        interviewGuideAreaHeightSize: {
          ...prevState.interviewGuideAreaHeightSize,
          pixelSize: prevState.interviewGuideAreaHeightSize.pixelSize
            ? prevState.interviewGuideAreaHeightSize.pixelSize - arg
            : 0,
        },
      }))
      setMinutesViewSizesLocalStorage(
        'MINUTES_VIEW_VIDEO_AREA_HEIGHT',
        Math.floor(
          ((minutesViewAreaSizes.videoAreaHeightSize.pixelSize + arg) / mainWrapperRef.current?.offsetHeight) * 100
        )
      )
    }
  }

  const { calcSubVideoSize } = useMinutesViewAreaSizes({
    subVideoAreaSize: {
      width: subVideoWrapperResizeObserver.width ?? DUMMY_ENTIRE_AREA_SIZE.width,
      height: subVideoWrapperResizeObserver.height ?? DUMMY_ENTIRE_AREA_SIZE.height,
    },
  })
  return (
    <Wrapper ref={mainWrapperRef}>
      {props.renderStartScreenShareModal()}
      {props.renderFinishScreenShareModal()}
      {props.renderScreenShareLimitModal()}
      <ContentWrapper>
        {isNotStarted && userSignalingStatus === 'Completed' && <WaitingEntryRequestMessage />}
        <ChatUnreadContainer.Provider>
          <ControlsArea>
            <MinutesViewPcControls />
          </ControlsArea>
        </ChatUnreadContainer.Provider>
        <MainAreaWrapper>
          <LeftSection ref={leftSectionRef} widthSize={minutesViewAreaSizes.leftSectionWithSize}>
            <VideoArea ref={videoAreaRef} heightSize={minutesViewAreaSizes.videoAreaHeightSize}>
              <MainVideoArea hasInterviewGuide={props.hasInterviewGuide}>
                <MainVideo />
              </MainVideoArea>
              <SubVideosArea ref={subVideoWrapperResizeObserver.ref} hasInterviewGuide={props.hasInterviewGuide}>
                <MinutesViewVideos calcSubVideoSize={calcSubVideoSize} />
              </SubVideosArea>
              {props.hasInterviewGuide && !isIPad && <VideoAreaResizer handleResize={handleVideoAreaHeight} />}
            </VideoArea>
            {props.hasInterviewGuide && (
              <BizInterviewGuideContainer.Provider>
                <InterviewGuidArea
                  ref={interviewGuideAreaRef}
                  heightSize={minutesViewAreaSizes.interviewGuideAreaHeightSize}
                >
                  <PcInterviewGuide />
                </InterviewGuidArea>
              </BizInterviewGuideContainer.Provider>
            )}
            {!isIPad && <LeftSectionResizer handleResize={handleLeftSectionWidth} />}
          </LeftSection>
          <RightSection ref={rightSectionRef}>
            <InterviewMinutesArea
              ref={interviewMinutesAreaRef}
              heightSize={minutesViewAreaSizes.interviewMinutesAreaHeightSize}
            >
              <InterviewMinutes
                isInterviewTranscriptionEnabled={props.isInterviewTranscriptionEnabled}
                interviewGuid={props.interviewGuid}
                isNotStarted={isNotStarted}
              />
              {!isIPad && <InterviewMinutesResizer handleResize={handleInterviewMinutesAreaHeight} />}
            </InterviewMinutesArea>
            <InterviewPrivateMemoArea
              ref={privateMemoAreRef}
              heightSize={minutesViewAreaSizes.privateMemoAreaHeightSize}
            >
              <MinutesViewPrivateMemo interviewGuid={props.interviewGuid} isNotStarted={isNotStarted} />
            </InterviewPrivateMemoArea>
          </RightSection>
        </MainAreaWrapper>
        {props.isChatAreaOpen && (
          <ChatArea>
            <MinutesViewChatList />
          </ChatArea>
        )}
        {/*DummyWrapper: 参加者のマイグ音声が既存のVideosコンポーネントからコントロールされてたため、表示はしないがDummyコンポーネントとして宣言しておく*/}
        <DummyWrapper>
          <VideosLayoutContainer.Provider>
            <Videos />
          </VideosLayoutContainer.Provider>
        </DummyWrapper>
      </ContentWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

const WaitingEntryRequestMessage = styled(MinutesViewWaitingEntryRequestMessage)`
  position: absolute;
  top: 92px;
  left: 111px;
  right: 0;
  // Videoの名前より大きくする
  z-index: 3;

  margin: 0 auto;
  width: 360px;
`

const ControlsArea = styled.div`
  height: 100%;
  width: 117px;
`

const ChatArea = styled.div`
  min-width: 200px;
`

const MainAreaWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`

const LeftSection = styled.div<{ widthSize: MinutesViewAreaSize }>`
  width: ${({ widthSize }) => (widthSize.pixelSize ? `${widthSize.pixelSize}px` : `${widthSize.percentSize}%`)};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-right: 1px solid ${theme.color.gray[3]};
`

const VideoArea = styled.div<{ heightSize: MinutesViewAreaSize }>`
  position: relative;
  width: 100%;
  height: ${({ heightSize }) => (heightSize.pixelSize ? `${heightSize.pixelSize}px` : `${heightSize.percentSize}%`)};
  border-right: 1px solid ${theme.color.gray[3]};
`

const MainVideoArea = styled.div<{ hasInterviewGuide: boolean }>`
  width: 100%;
  height: ${({ hasInterviewGuide }) => (hasInterviewGuide ? '74%' : '55%')}; ;
`

const SubVideosArea = styled.div<{ hasInterviewGuide: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${({ hasInterviewGuide }) => (hasInterviewGuide ? '26%' : '45%')}; ;
`

const InterviewGuidArea = styled.div<{ heightSize: MinutesViewAreaSize }>`
  width: 100%;
  border-right: 1px solid ${theme.color.gray[3]};
  height: ${({ heightSize }) => (heightSize.pixelSize ? `${heightSize.pixelSize}px` : `${heightSize.percentSize}%`)};
`

const RightSection = styled.div`
  flex: 1;
  min-width: 296px;
  display: flex;
  height: 100%;
  flex-direction: column;
`

const InterviewMinutesArea = styled.div<{ heightSize: MinutesViewAreaSize }>`
  position: relative;
  width: 100%;
  height: ${({ heightSize }) => (heightSize.pixelSize ? `${heightSize.pixelSize}px` : `${heightSize.percentSize}%`)}; ;
`

const InterviewPrivateMemoArea = styled.div<{ heightSize: MinutesViewAreaSize }>`
  width: 100%;
  height: ${({ heightSize }) => (heightSize.pixelSize ? `${heightSize.pixelSize}px` : `${heightSize.percentSize}%`)}; ;
`

const DummyWrapper = styled.div`
  display: none;
`
