import React from 'react'
import { TertiaryButton, Icon } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useMuteCamera } from '@/shared/services/interviewService/hooksForApp/useMuteCamera'
import { useUnmuteCamera } from '@/shared/services/interviewService/hooksForApp/useUnmuteCamera'
import { SharedState } from '../../redux'
import { controlButtonBase } from './controlButtonBase'

export const CameraMuteButton: React.VFC = React.memo(() => {
  const muteCamera = useMuteCamera()
  const unmuteCamera = useUnmuteCamera()

  return <CameraMuteButtonConsumer muteCamera={muteCamera} unmuteCamera={unmuteCamera} />
})

type ConsumerProps = {
  muteCamera: () => void
  unmuteCamera: () => void
}

const CameraMuteButtonConsumer: React.VFC<ConsumerProps> = React.memo(({ muteCamera, unmuteCamera }) => {
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const disabled = useSelector(
    (state: SharedState) => state.shared.isUnavailableVideoInput || state.shared.userSignalingStatus !== 'Completed'
  )

  return isOwnCameraMuted ? (
    <Button
      text="解除"
      onClick={unmuteCamera}
      icon={<Icon name="movie-off-color" size="l" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_camera_unmute_button_on_before_entered_page' }}
    />
  ) : (
    <Button
      text="オフ"
      onClick={muteCamera}
      icon={<Icon name="movie" size="l" />}
      direction="column"
      disabled={disabled}
      comlinkPushParams={{ action: 'click_camera_mute_button_on_before_entered_page' }}
    />
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const Button = styled(TertiaryButton)`
  ${controlButtonBase};
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`
