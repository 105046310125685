import { Modal as _Modal, theme } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'

export const modalHeaderHeight = 40
export const modalMaxHeight = 545
export const modalMaxWidth = 700

const bottomHeight = 100

export const Modal = styled(_Modal)<{ width: number; height: number }>`
  ${({ width, height }) =>
    css`
      width: ${width}px;
      max-height: ${height}px;
    `}
`
export const Body = styled.div<{ height: number }>`
  max-height: ${({ height }) => height}px;
  overflow: auto;
`

export const Wrapper = styled.div`
  height: 100%;
`
export const Upper = styled.div`
  padding: 40px;
  height: calc(100% - ${bottomHeight}px);
  ${overflowYScrollStyle};
`
export const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 0 30px;
  &:not(:first-child) {
    margin-top: 37px;
  }
`
export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  margin-right: 40px;
`
export const BottomWrapper = styled.div`
  height: ${bottomHeight}px;
  position: sticky;
  bottom: 0;
  background-color: ${theme.color.white[1]};
`
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`
export const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
`
