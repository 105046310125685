import React, { useCallback, useEffect, useState } from 'react'
import { PageLayout, useLoggedInStaff } from '@blue-agency/im-shared-front'
import { useSpecificMediaDevice } from '@blue-agency/react-media-devices'
import { Dropdown, theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/dist/im'
import styled from 'styled-components'
import { Box } from '@/shared/components/Box'
import { Loading } from '@/shared/components/Loading'
import { BizPocOfflineInterviewContainer } from './containers/BizPocOfflineInterview'
import Result from './lib/result'
import Wrp from './lib/wrp'

const websocketUrl = 'wss://acp-api.amivoice.com/v1/nolog/'
const grammarFileNames = '-a-general'

const Content = () => {
  const mic = useSpecificMediaDevice('audioinput')
  const { getTranscriptionOnetimeAppKey } = BizPocOfflineInterviewContainer.useContainer()

  const [isRecording, setIsRecording] = useState<boolean>(false)

  const [tokens, setTokens] = useState<string[]>([])
  const [preText, setPreText] = useState<string>('')

  const sanitize_ = (s: string) => {
    return s
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&apos;')
      .replace(/"/g, '&quot;')
      .replace(/%/g, '')
  }

  // 認識処理中に呼び出されます。
  // MEMO result.jsに渡す関数の為、Type定義できない箇所あり
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const resultUpdated = useCallback((result: any) => {
    result = Result.parse(result)
    const text = result.text ? sanitize_(result.text) : '...'
    setPreText(text)
  }, [])

  // 認識処理が確定した時に呼び出されます。
  // MEMO result.jsに渡す関数の為、Type定義できない箇所あり
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const resultFinalized = useCallback(
    (result: any) => {
      setPreText('')
      result = Result.parse(result)
      const text = result.text
        ? `${result.results[0].tokens[0].label}: ${sanitize_(result.text)}`
        : result.code !== 'o' && result.message
        ? '(' + result.message + ')'
        : '(返答なし)'
      setTokens([...tokens, text])
    },
    [tokens]
  )

  // 音声認識ライブラリ／録音ライブラリのメソッドの画面要素への登録
  const start = useCallback(async () => {
    setIsRecording(true)
    // 音声認識サーバへの音声データの供給中かどうかのチェック
    if (Wrp.isActive()) {
      // 音声認識サーバへの音声データの供給中の場合...
      // 音声認識サーバへの音声データの供給の停止
      Wrp.feedDataPause()
    } else {
      // 音声認識サーバへの音声データの供給中でない場合...
      // グラマファイル名が指定されているかどうかのチェック
      Wrp.feedDataResume()
    }
  }, [])

  const stop = useCallback(async () => {
    setIsRecording(false)
    Wrp.feedDataPause()
  }, [])

  const feedDataPauseEnded = useCallback((reason) => {
    setIsRecording(false)
  }, [])

  useEffect(() => {
    const init = async () => {
      // 音声認識ライブラリのプロパティ要素の設定
      Wrp.serverURL = websocketUrl
      Wrp.grammarFileNames = grammarFileNames
      Wrp.codec = '16K'
      Wrp.resultUpdatedInterval = '1000'
      Wrp.issuerURL = 'https://acp-api.amivoice.com/issue_service_authorization'
      const { onetimeKey } = await getTranscriptionOnetimeAppKey()
      Wrp.authorization = onetimeKey
      Wrp.resultFinalized = resultFinalized
      Wrp.resultUpdated = resultUpdated
      Wrp.feedDataPauseEnded = feedDataPauseEnded
      Wrp.segmenterProperties = 'useDiarizer=1'
    }

    init()
  }, [feedDataPauseEnded, getTranscriptionOnetimeAppKey, resultFinalized, resultUpdated])

  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Wrapper>
        <Txt size="xl" textAlign="center">
          対面面接の文字起こし機能（α版）
        </Txt>
        <Box marginTop={20}>
          <Txt size="l">再読込やブラウザを閉じるとデータは消失しますので、ご注意ください。</Txt>
        </Box>
        <Box display="flex" marginTop={20} alignItems="center" justifyContent="space-between">
          <Box width="80px">
            <Txt bold>マイク</Txt>
          </Box>
          {mic.devices && (
            <Dropdown
              size="ll"
              value={mic.selectedDeviceId}
              options={
                mic.devices
                  ? mic.devices.map((d) => ({
                      label: d.label,
                      value: d.deviceId,
                    }))
                  : []
              }
              onChange={(e) => {
                mic.changeDevice(e.target.value)
              }}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="center" marginTop={20}>
          {isRecording ? (
            <PrimaryButton widthSize="L1" comlinkPushParams={{ action: 'end_transcription' }} onClick={stop}>
              終了
            </PrimaryButton>
          ) : (
            <PrimaryButton widthSize="L1" comlinkPushParams={{ action: 'start_transcription' }} onClick={start}>
              文字起こし開始
            </PrimaryButton>
          )}
        </Box>
        <Box
          marginTop={20}
          borderTop={`1px solid ${theme.color.gray[3]}`}
          paddingTop={20}
          overflowY="auto"
          height="400px"
        >
          {tokens.map((token, i) => (
            <Txt color={theme.color.gray[1]} key={i}>
              {token}
            </Txt>
          ))}

          <Txt color={theme.color.gray[1]}>{preText}</Txt>

          {!isRecording && !tokens.length && <Txt color={theme.color.gray[1]}>会話内容が、こちらに表示されます...</Txt>}
        </Box>
      </Wrapper>
    </PageLayout>
  )
}

export const PocOfflineInterviewPage: React.VFC = () => {
  const loggedInStaff = useLoggedInStaff()

  if (!loggedInStaff) {
    return <Loading />
  }

  return (
    <>
      <BizPocOfflineInterviewContainer.Provider>
        <Content />
      </BizPocOfflineInterviewContainer.Provider>
    </>
  )
}

const Wrapper = styled.div`
  max-width: 620px;
  margin: 20px auto;
`
