import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { ListMessagesResponse, CreateMessageRequest } from '@blue-agency/proton/c3po'
import { useDispatch, useSelector } from 'react-redux'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { ThemeContext } from 'styled-components'
import invariant from 'tiny-invariant'
import { sharedSlice, SharedState } from '../../redux'
import { buildWebSocketUrl } from './buildWebSocketUrl'

const maxRetryCount = 5

function useChat() {
  const dispatch = useDispatch()

  const isChatAreaOpen = useSelector((state: SharedState) => state.shared.isChatAreaOpen)

  const { responsive } = useContext(ThemeContext)

  const toggleChatAreaOpen = useCallback(() => {
    dispatch(sharedSlice.actions.toggleChatAreaOpen({ shouldClosePrivateMemoAreaOrInterviewGuideArea: !responsive.pc }))
  }, [dispatch, responsive])

  const closeChatArea = useCallback(() => {
    dispatch(sharedSlice.actions.closeChatArea())
  }, [dispatch])

  const [isChatFocused, setIsChatFocused] = useState(false)

  const isOpenChatWebSocketConnection = useSelector((state: SharedState) => state.shared.isChatWebSocketConnected)
  const chatRoomGuid = useSelector((state: SharedState) => state.shared.chatRoomGuid)
  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  const handleMessage = useCallback(
    (event: MessageEvent) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(event.data)
      reader.onload = async () => {
        const bytes = new Uint8Array(reader.result as ArrayBuffer)
        const msg = ListMessagesResponse.ChatMessage.deserializeBinary(bytes)
        dispatch(sharedSlice.actions.chatMessageReceived(msg.toObject()))
      }
    },
    [dispatch]
  )

  // chatRoomGuid が undefined のときは、WebSocket に接続しないように null を返す
  // useWebSocket が null を受け付けるので null を返す
  // ownSoraClientId は 再接続処理でIDが変わったときにwsを再接続するために条件に入れている
  // https://github.com/robtaussig/react-use-websocket/blob/2efda87e09508c91c3605e92936e7a00fc1964e5/src/lib/use-websocket.ts#L16
  const websocketUrl = useMemo<string | null>(() => {
    return chatRoomGuid && ownSoraClientId ? buildWebSocketUrl(chatRoomGuid) : null
  }, [chatRoomGuid, ownSoraClientId])

  const { sendMessage, readyState } = useWebSocket(websocketUrl, {
    reconnectAttempts: maxRetryCount,
    onMessage: handleMessage,
    shouldReconnect,
    // どのユーザーのチャットなのか識別するために protocol を使う
    // 名前と紐付けるために soraClientId を指定する
    // https://stadium-co-jp.slack.com/archives/C01FN768U3U/p1609898547075900
    protocols: [ownSoraClientId ?? ''],
  })

  const sendChatMessage = useCallback(
    (text: string) => {
      invariant(isOpenChatWebSocketConnection)
      invariant(chatRoomGuid)
      invariant(ownSoraClientId)

      const message = new CreateMessageRequest()
      message.setRoomGuid(chatRoomGuid)
      message.setText(text)
      message.setUserGuid(ownSoraClientId)
      message.setUserType(ListMessagesResponse.UserType.UNKNOWN)
      sendMessage(message.serializeBinary())
    },
    [isOpenChatWebSocketConnection, chatRoomGuid, ownSoraClientId, sendMessage]
  )

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      dispatch(sharedSlice.actions.chatWebSocketConnected())
    }

    if (readyState === ReadyState.CLOSED) {
      dispatch(sharedSlice.actions.chatWebSocketClosed())
    }
  }, [dispatch, readyState])

  return { sendChatMessage, isChatAreaOpen, toggleChatAreaOpen, closeChatArea, isChatFocused, setIsChatFocused }
}

export const ChatContainer = createContainer(useChat)

const shouldReconnect = (e: WebSocketEventMap['close']) => {
  // MEMO: 正常終了の時はretryしない
  if (e.code === 1005 && e.reason === '') return false
  return true
}
