// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { AudioMixController, AudioMixObserver } from 'amazon-chime-sdk-js'
import { noop } from '../util'

export class NoOpAudioMixController implements AudioMixController {
  bindAudioDevice(device: MediaDeviceInfo | null): Promise<void> {
    noop(device)
    return Promise.resolve(undefined)
  }

  bindAudioElement(element: HTMLAudioElement): Promise<void> {
    noop(element)
    return Promise.resolve(undefined)
  }

  bindAudioStream(stream: MediaStream): Promise<void> {
    noop(stream)
    return Promise.resolve(undefined)
  }

  unbindAudioElement(): void {
    noop()
  }

  getCurrentMeetingAudioStream(): Promise<MediaStream | null> {
    return Promise.resolve(null)
  }

  addAudioMixObserver(observer: AudioMixObserver): void {
    noop(observer)
  }

  removeAudioMixObserver(observer: AudioMixObserver): void {
    noop(observer)
  }
}
