import React from 'react'
import { PageLayout } from '@blue-agency/im-shared-front'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { Icon, NewTabLink, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '../../services/urlService'
import interviewDeletedImage from './interviewDeleted.svg'

const Header: React.VFC = () => {
  return (
    <Wrapper>
      <NewTabLink href={BIZ_EXTERNAL_URLS.help.top} hideIcon action="open_help_page">
        <QuestionIcon />
      </NewTabLink>
    </Wrapper>
  )
}

export const InterviewDeletedPage: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} header={<Header />} disableLogoLink>
      <Inner>
        <Description>
          <Txt size="l">このページは削除された可能性があります。</Txt>
        </Description>
        <NoUnderline>
          <a href={getInternalUrls().dashboard}>
            <Txt size="l" color={theme.color.blue[2]}>
              ダッシュボードへ戻る
            </Txt>
          </a>
        </NoUnderline>
        <img src={interviewDeletedImage} alt="" width="180" />
      </Inner>
    </PageLayout>
  )
}

const Inner = styled.div`
  margin: 44px auto;
  max-width: 90%;
  text-align: center;
`

const Description = styled.div`
  margin-bottom: 15px;
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question' })`
  height: 22px;
  width: 22px;
  color: ${theme.color.navy[1]};
`

const NoUnderline = styled.div`
  margin-bottom: 25px;
  > * {
    text-decoration: none;
  }
`
