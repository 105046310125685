import { useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useRequestPocOfflineInterview } from '@/biz/services/bffService'

const usePocOfflineInterview = () => {
  const { requestGetTranscriptionOnetimeAppKeyRequest } = useRequestPocOfflineInterview()

  const getTranscriptionOnetimeAppKey = useCallback(async () => {
    const res = await requestGetTranscriptionOnetimeAppKeyRequest()

    const onetimeKey = res.getOnetimeKey()

    return {
      onetimeKey,
    }
  }, [requestGetTranscriptionOnetimeAppKeyRequest])

  return {
    getTranscriptionOnetimeAppKey,
  }
}

export const BizPocOfflineInterviewContainer = createContainer(usePocOfflineInterview)
