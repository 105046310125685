// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.

/*
 * [[AttachMediaInputTask]] adds audio and video input to peer connection.
 */
import { BaseTask, VideoLogEvent } from 'amazon-chime-sdk-js'
import { ImAudioVideoControllerState } from '../audiovideocontroller/ImAudioVideoControllerState'

export class AttachMediaInputTask extends BaseTask {
  protected taskName = 'AttachMediaInputTask'

  constructor(private context: ImAudioVideoControllerState) {
    super(context.logger)
  }

  async run(): Promise<void> {
    const transceiverController = this.context.transceiverController
    if (transceiverController === null) {
      return
    }
    if (this.context?.peer) {
      transceiverController.setPeer(this.context.peer)
    }
    transceiverController.setupLocalTransceivers()

    const audioInput = this.context.activeAudioInput

    if (audioInput) {
      const audioTracks = audioInput.getAudioTracks()
      if (this.context.browserBehavior?.requiresUnifiedPlan()) {
        this.context.logger?.info('attaching audio track to peer connection (unified-plan)')
        await transceiverController.setAudioInput(audioTracks[0] ?? null)
      } else {
        this.context.logger?.info('attaching audio track to peer connection (plan-b)')

        const senders = this.context.peer?.getSenders()
        audioInput.getAudioTracks().forEach((track: MediaStreamTrack) => {
          if (
            !senders ||
            !senders.find((sender: RTCRtpSender) => {
              return sender.track?.id === track.id
            })
          ) {
            // unclear why this does not deal with the case of removing
            // an existing track as we do in attachVideoInput
            this.context.localAudioSender = this.context.peer?.addTrack(track, audioInput) ?? null
          }
        })
      }
    } else {
      await transceiverController.setAudioInput(null)
      this.context.logger?.info('no audio track')
    }

    const videoInput = this.context.activeVideoInput
    if (videoInput) {
      const videoTracks = videoInput.getVideoTracks()
      const videoTrack: MediaStreamTrack | null = videoTracks[0] ?? null
      if (this.context.browserBehavior?.requiresUnifiedPlan()) {
        this.context.logger?.info('attaching video track to peer connection (unified-plan)')
        await transceiverController.setVideoInput(videoTrack)
        if (this.context.enableSimulcast && this.context.videoUplinkBandwidthPolicy) {
          const encodingParam = this.context.videoUplinkBandwidthPolicy.chooseEncodingParameters()
          transceiverController.setEncodingParameters(encodingParam)
        }
      } else {
        this.context.logger?.info('attaching video track to peer connection (plan-b)')

        const senders = this.context.peer?.getSenders()
        if (
          !senders ||
          !senders.find((sender: RTCRtpSender) => {
            return sender.track && sender.track.id === videoTracks[0]?.id
          })
        ) {
          if (this.context.localVideoSender) {
            this.context.peer?.removeTrack(this.context.localVideoSender)
            this.context.localVideoSender = null
          }
          if (this.context.peer && videoTracks[0]) {
            this.context.localVideoSender = this.context.peer.addTrack(videoTracks[0], videoInput)
          }
        }
      }

      if (videoTrack && this.context.statsCollector) {
        this.context.statsCollector.logVideoEvent(VideoLogEvent.InputAttached, this.context.videoDeviceInformation)
        this.context.videoInputAttachedTimestampMs = Date.now()
      }
    } else {
      await transceiverController.setVideoInput(null)
      this.context.logger?.info('no video track')
      if (this.context.localVideoSender) {
        this.context.logger?.info('removing track from peer')
        this.context.peer?.removeTrack(this.context.localVideoSender)
        this.context.localVideoSender = null
      }
    }

    if (this.context.videoStreamIndex && this.context.videosToReceive) {
      this.context.videoSubscriptions = transceiverController.updateVideoTransceivers(
        this.context.videoStreamIndex,
        this.context.videosToReceive
      )
    }
  }
}
