import { selectedDeviceIdStorageKey } from '@blue-agency/react-media-devices'

export function getSelectedDeviceIdFromStorage(kind: MediaDeviceKind): string | undefined {
  return localStorage.getItem(selectedDeviceIdStorageKey[kind]) ?? undefined
}

export function setSelectedDeviceIdToStorage(kind: MediaDeviceKind, deviceId: string): void {
  localStorage.setItem(selectedDeviceIdStorageKey[kind], deviceId)
}

export function removeSelectedDeviceIdFromStorage(kind: MediaDeviceKind): void {
  localStorage.removeItem(selectedDeviceIdStorageKey[kind])
}
