import React, { Suspense, useMemo } from 'react'
import { GeneralErrorPage, NotFoundPage } from '@blue-agency/im-shared-front'
import { ToastContainer } from '@blue-agency/rogue'
import '@blue-agency/rogue/dist/assets/style.css'
import * as Sentry from '@sentry/react'
import { createBrowserHistory } from 'history'
import { Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { BizApp } from './biz/BizApp'
import { BIZ_INTERNAL_PATHS } from './biz/services/urlService'
import './initializeImSharedFront'
import { MyRoutes } from './my/pages'
import { MY_INTERNAL_PATHS } from './my/services/urlService'
import './shared/assets/layout.css'
import { Loading } from './shared/components/Loading'
import { useComlinkFlushOnUnload } from './shared/hooks/useComlinkFlushOnUnload'
import { useInterviewDisplaySize } from './shared/hooks/useInterviewDisplaySize'
import { useSetMinHeightToBody } from './shared/hooks/useSetMinHeightToBody'
import { useTracking } from './shared/hooks/useTracking'
import { initSentry } from './shared/initSentry'

const history = createBrowserHistory()

if (process.env.REACT_APP_SENTRY_ENABLED === 'true') {
  initSentry()
}

const App: React.FC = () => {
  useSetMinHeightToBody()
  useTracking()
  useComlinkFlushOnUnload()
  const { responsive } = useInterviewDisplaySize()

  const theme = useMemo(() => {
    return { responsive }
  }, [responsive])

  return (
    <Router history={history}>
      <Sentry.ErrorBoundary fallback={() => <GeneralErrorPage showDashboardLink={false} />}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route path={BIZ_INTERNAL_PATHS.index} component={BizApp} />
              <Route path={MY_INTERNAL_PATHS.index} component={MyRoutes} />
              <Route component={() => <NotFoundPage showDashboardLink={false} />} />
            </Switch>

            <ToastContainer />
          </Suspense>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </Router>
  )
}

export default App
