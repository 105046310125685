import React from 'react'
import { Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = {
  leftNode?: React.ReactNode
  rightNode?: React.ReactNode
  disableLogoLink?: boolean
  dashboardUrl?: string
  readNotification?: () => void
  newNotification?: boolean
}

export const GlobalHeader = (props: PropsWithClassName<Props>): React.ReactElement => {
  return (
    <Wrapper className={props.className}>
      {props.disableLogoLink ? (
        <Logo />
      ) : (
        <Link href={props.dashboardUrl}>
          <Logo />
        </Link>
      )}
      <ChildrenWrapper>
        <Left>{props.leftNode}</Left>
        <RightWrapper>
          <Right>
            {props.newNotification ? (
              <>
                <div onClick={props.readNotification}>{props.rightNode}</div>
                <ToolBarUnReadDot />
              </>
            ) : (
              <div>{props.rightNode}</div>
            )}
          </Right>
        </RightWrapper>
      </ChildrenWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background: ${theme.color.white[1]};
  border-bottom: 1px solid ${theme.color.gray[3]};
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.15);
`
const Link = styled.a`
  height: 50px;
`
const Logo = styled(Icon).attrs({ name: 'logo', width: '120', height: '50' })`
  color: ${theme.color.black[1]};
  margin-left: 20px;
`
const ChildrenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1;
  margin-left: 20px;
  padding: 0 20px;
  height: 100%;
`
const Left = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
const Right = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`
const RightWrapper = styled.div`
  position: relative;
`

const ToolBarUnReadDot = styled.div`
  position: absolute;
  height: 11px;
  width: 11px;
  border-radius: 100%;
  background-color: #f24822;
  pointer-events: none;
  top: 25%;
  left: 70%;
`
