import React from 'react'
import { ProductQualityQuestionnaire } from './ProductQualityQuestionnaire'

type Props = {
  // 面接ルーム終了ではなく、自分だけ退室してきたか否かのフラグ
  leave?: boolean
}

export const Finished: React.VFC<Props> = ({ leave = false }) => {
  return <ProductQualityQuestionnaire leave={leave} />
}
