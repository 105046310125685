import { useCallback, useEffect, useState } from 'react'
import { BizInterviewGuideContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { Part } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'

const DUMMY_DISPLAY_PART = {
  title: '',
  description: '',
  startSeconds: 0,
  isHighlight: false,
}

export const useInterviewGuideNotification = () => {
  const [displayPart, setDisplayPart] = useState<Part | undefined>(undefined)
  const { openInterviewGuideArea, isInterviewGuideAreaOpen } = InterviewGuideContainer.useContainer()
  const { currentPart } = BizInterviewGuideContainer.useContainer()
  const prevCurrentPart = usePrevious(currentPart)

  const close = useCallback(() => {
    setDisplayPart(undefined)
  }, [setDisplayPart])

  useEffect(() => {
    if (currentPart?.startSeconds !== prevCurrentPart?.startSeconds && !isInterviewGuideAreaOpen) {
      // 面接ガイドを閉じている時にPartが切り替わったら通知を表示する
      setDisplayPart(currentPart)
    }
  }, [setDisplayPart, currentPart, prevCurrentPart, isInterviewGuideAreaOpen])

  useEffect(() => {
    if (isInterviewGuideAreaOpen) {
      // 面接ガイドを開いたら非表示にする
      setDisplayPart(undefined)
    }
  }, [isInterviewGuideAreaOpen])

  return {
    active: !!displayPart,
    displayPart: displayPart ?? DUMMY_DISPLAY_PART,
    openInterviewGuideArea,
    close,
  }
}
