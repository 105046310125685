import { useEffect } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { useSelector } from 'react-redux'
import { SharedState } from '../redux'

export const useComlinkNetworkUnstableLevel = () => {
  const networkUnstableLevel = useSelector((state: SharedState) => state.shared.networkUnstableLevel)
  useEffect(() => {
    if (networkUnstableLevel === undefined) {
      return
    }
    comlinkPush({
      action: 'change_unstable_level',
      metadata: {
        unstableLevel: networkUnstableLevel.toString(),
      },
    })
  }, [networkUnstableLevel])
}
