// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { ConnectionMonitor } from 'amazon-chime-sdk-js'
import { noop } from '../util'

export class NoOpConnectionMonitor implements ConnectionMonitor {
  start(): void {
    noop()
  }
  stop(): void {
    noop()
  }
}
