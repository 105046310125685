import React from 'react'
import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName<{
  count: number
  currentPage: number
}>

export const Dots = ({ count, currentPage, className }: Props): React.ReactElement => {
  return (
    <Wrapper className={className}>
      {Array(count)
        .fill(0)
        .map((_, index) => (
          <Dot key={index} active={currentPage === index + 1} />
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Dot = styled.div<{ active?: boolean }>`
  &:not(:first-child) {
    margin-left: 6px;
  }
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${({ active }) => (active ? theme.color.green[5] : theme.color.gray[4])};
`
