// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { ActiveSpeakerDetector, ActiveSpeakerPolicy } from 'amazon-chime-sdk-js'
import { noop } from '../util'

export class NoOpActiveSpeakerDetector implements ActiveSpeakerDetector {
  subscribe(
    policy: ActiveSpeakerPolicy,
    callback: (activeSpeakers: string[]) => void,
    scoresCallback?: (scores: { [p: string]: number }) => void,
    scoresCallbackIntervalMs?: number
  ): void {
    noop(policy, callback, scoresCallback, scoresCallbackIntervalMs)
  }
  unsubscribe(callback: (activeSpeakers: string[]) => void): void {
    noop(callback)
  }
}
