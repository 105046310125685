const MIN_TH = 90

export type CalcAudioVolumeBarHeightsParams = {
  waveBarLength: number
  baseBarHeight: number
  centerMaxBarHeight: number
  sideMaxBarHeight: number
}

export const calcAudioVolumeBarHeights = (
  vol: number,
  { baseBarHeight, centerMaxBarHeight, sideMaxBarHeight }: CalcAudioVolumeBarHeightsParams
) => {
  // 正規化
  let v = Math.max(0, vol - MIN_TH) / (255 - MIN_TH)

  const centerH = baseBarHeight + v * (centerMaxBarHeight - baseBarHeight)
  const sideH = Math.max((centerH * sideMaxBarHeight) / centerMaxBarHeight, baseBarHeight)
  const barHeights = [sideH, centerH, sideH]
  return barHeights
}
