import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { SharedState } from '../../redux'

export function useScrollToBottom() {
  const messageContainerRef = useRef<HTMLDivElement>(null)

  const chatMessages = useSelector((state: SharedState) => state.shared.chatMessages)

  useEffect(() => {
    if (messageContainerRef.current === null) {
      return
    }
    // scrollIntoView を使って強制スクロールを実現すると、Mobile Safari で、仮想キーボードが出現しているときの
    // 挙動がおかしくなるので注意
    // https://github.com/blue-agency/skywalker-front/pull/667#issuecomment-912463065
    messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
  }, [chatMessages])

  return { messageContainerRef }
}
