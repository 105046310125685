import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'

const bottomHeight = 100

export const Wrapper = styled.div`
  height: 100%;
`
export const Upper = styled.div`
  padding: 15px;
  height: calc(100% - ${bottomHeight}px);
  ${overflowYScrollStyle};
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &:not(:first-child) {
    margin-top: 20px;
  }
`
export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
`
export const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  margin-right: 12px;
`
export const TextWrapper = styled.div`
  width: 100%;
  align-items: flex-start;
`
export const BottomWrapper = styled.div`
  height: ${bottomHeight}px;
  position: sticky;
  bottom: 0;
  background-color: ${theme.color.white[1]};
`
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
`
export const DotsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
`
