import React, { useCallback, useEffect, useState } from 'react'
import { Icon, theme, Txt, CircularProgress, Button } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import { useMount } from 'react-use'
import styled from 'styled-components'
import { useRequestGetInterviewPrivateMemo } from '@/biz/services/bffService/useRequestGetInterviewPrivateMemo'
import { useRequestUpdateInterviewPrivateMemo } from '@/biz/services/bffService/useRequestUpdateInterviewPrivateMemo'
import checkImage from './check.svg'
import reloadImage from './reload.svg'

type Props = {
  interviewGuid: string
  isNotStarted: boolean
}

export function MinutesViewPrivateMemo(props: Props) {
  const { interviewGuid, isNotStarted } = props

  const [privateMemoFirstLoading, setPrivateMemoFirstLoading] = useState<boolean>(true)
  const [privateMemo, setPrivateMemo] = useState<string>('')
  const [privateMemoNeedUpdate, setPrivateMemoNeedUpdate] = useState<boolean>(false)
  const [privateMemoGetError, setPrivateMemoGetError] = useState<boolean>(false)

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPrivateMemo(e.target.value)
    setPrivateMemoNeedUpdate(true)
  }, [])

  const { requestGetInterviewPrivateMemo } = useRequestGetInterviewPrivateMemo()
  const { requestUpdateInterviewPrivateMemo } = useRequestUpdateInterviewPrivateMemo()

  // 5秒に1度プライベートメモの更新処理をupdateを実行する
  // (ただしメモの内容に変更があった場合のみ)
  useEffect(() => {
    const interval = setInterval(async () => {
      if (privateMemoNeedUpdate) {
        await requestUpdateInterviewPrivateMemo(interviewGuid, privateMemo)
        setPrivateMemoNeedUpdate(false)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [interviewGuid, requestUpdateInterviewPrivateMemo, privateMemoNeedUpdate, privateMemo])

  const fetchInvitePrivateMemo = useCallback(async () => {
    try {
      const res = await requestGetInterviewPrivateMemo(interviewGuid)
      setPrivateMemo(res.getText())
      setPrivateMemoFirstLoading(false)
      setPrivateMemoGetError(false)
    } catch (e) {
      setPrivateMemoFirstLoading(false)
      setPrivateMemoGetError(true)
      throw e
    }
  }, [interviewGuid, requestGetInterviewPrivateMemo])

  useMount(() => {
    // 入室時にプライベートメモを作られるが、タイミングによってはまだ作られていない場合がある
    // そのため、3秒後に取得を試みる
    setTimeout(async () => {
      await fetchInvitePrivateMemo()
    }, 3000)
  })

  useEffect(() => {
    const interval = setInterval(async () => {
      if (privateMemoNeedUpdate) {
        await requestUpdateInterviewPrivateMemo(interviewGuid, privateMemo)
        setPrivateMemoNeedUpdate(false)
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [interviewGuid, requestUpdateInterviewPrivateMemo, privateMemoNeedUpdate, privateMemo])

  return (
    <Wrapper>
      <Header>
        <HeaderLabel>
          <Txt size="s" color={theme.color.navy[1]} bold>
            プライベートメモ
          </Txt>
          <QuestionIconWrapper data-tip data-for="private_memo">
            <QuestionIcon />
          </QuestionIconWrapper>
          <Tooltip id="private_memo" arrowPosition="topLeft">
            <Txt size="m" color={theme.color.navy[1]}>
              あなた専用のメモ欄です。
              <br />
              応募者や他のユーザーには一切見えません。
            </Txt>
          </Tooltip>
        </HeaderLabel>

        {!isNotStarted && (
          <HeaderMessageWrapper>
            <MessageArea>
              {privateMemoNeedUpdate ? <ReloadIcon /> : <CheckIcon />}
              <MessageTxt>{privateMemoNeedUpdate ? '保存中…' : '保存完了'}</MessageTxt>
            </MessageArea>
          </HeaderMessageWrapper>
        )}
      </Header>

      <TextAreaWrapper>
        {privateMemoGetError ? (
          <ErrorWrapper>
            <Txt>プライベートメモが開始できませんでした</Txt>
            <Button onClick={fetchInvitePrivateMemo} comlinkPushParams={{ action: 'retry_private_memo' }}>
              リトライ
            </Button>
          </ErrorWrapper>
        ) : privateMemoFirstLoading ? (
          <LoadingWrapper>
            <CircularProgress size={64} borderWidth={1} color={theme.color.red['iM']} />
          </LoadingWrapper>
        ) : (
          !isNotStarted && (
            <TextArea onChange={onChange} value={privateMemo} placeholder="あなた専用のメモを入力する…" />
          )
        )}
      </TextAreaWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${theme.color.navy[1]};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 34px;
  padding: 8px;
  background-color: ${theme.color.navy[3]};
  border-bottom: 1px solid ${theme.color.gray[3]};
`

const HeaderLabel = styled.div`
  display: flex;
  align-content: center;
  gap: 2px;
`

const HeaderMessageWrapper = styled.div`
  display: flex;
`

const MessageArea = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`

const ReloadIcon = styled.img.attrs({ src: reloadImage })`
  margin-right: 4px;
`
const CheckIcon = styled.img.attrs({ src: checkImage })`
  margin-right: 4px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question-bg-white' })`
  height: 22px;
  width: 22px;
  color: ${theme.color.navy[1]};
`

const QuestionIconWrapper = styled.span`
  cursor: pointer;
  margin-top: -3px;
`

const MessageTxt = styled(Txt).attrs({ size: 's' })`
  color: ${theme.color.gray[1]};
`

const TextAreaWrapper = styled.div`
  height: 100%;
  padding: 12px;
`

const TextArea = styled.textarea`
  height: 100%;
  width: 100%;
  border: none;
  resize: none;
  font-size: 12px;
  color: ${theme.color.navy[1]};
  :focus {
    outline: none;
  }
`

const LoadingWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ErrorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 12px;
`
