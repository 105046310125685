import React, { useLayoutEffect, useRef } from 'react'
import { theme, Txt as _Txt } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { BizInterviewGuideContainer, Part } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { timerFormat } from '@/shared/services/interviewService/components/Timer/timerFormat'

type Props = {
  part: Part
}

export const GuidePartView: React.VFC<Props> = ({ part }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isTimeDisable } = BizInterviewGuideContainer.useContainer()

  const highlight = part.isHighlight
  const prevHighlight = usePrevious(highlight)

  useLayoutEffect(() => {
    if (highlight && prevHighlight === undefined) {
      ref.current?.scrollIntoView({
        block: 'start',
      })
    }

    if (highlight && !prevHighlight) {
      ref.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [highlight, prevHighlight])

  return (
    <GuidePart highlight={highlight} ref={ref}>
      <TimeLeftSpacer />
      <Time disable={isTimeDisable}>
        <Txt size="l" color={highlight ? theme.color.green[4] : theme.color.navy[1]}>
          {timerFormat(part.startSeconds)}
        </Txt>
      </Time>
      <GuidePartBody>
        <GuidePartTitle>
          <Txt size="l" color={highlight ? theme.color.green[4] : theme.color.navy[1]} bold>
            {part.title}
          </Txt>
        </GuidePartTitle>
        <GuidePartDescription>
          <Txt size="l" color={theme.color.navy[1]}>
            {part.description}
          </Txt>
        </GuidePartDescription>
      </GuidePartBody>
    </GuidePart>
  )
}

const GuidePart = styled.div<{ highlight: boolean }>`
  display: flex;
  flex-direction: row;
  padding: 8px 16px 8px 0;
  background-color: ${({ highlight }) => (highlight ? theme.color.green[8] : theme.color.white[1])};
`

const TimeLeftSpacer = styled.div`
  width: 12px;
  min-width: 12px;
`

const Time = styled.div<{ disable: boolean }>`
  margin: 0 4px 0 0;
  width: 50px;
  min-width: 50px;

  ${({ disable }) =>
    disable &&
    css`
      display: none;
    `}
`

const GuidePartBody = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  overflow-wrap: break-word;
`

const GuidePartTitle = styled.div``

const GuidePartDescription = styled.div``

const Txt = styled(_Txt)`
  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://nuconeco.net/iphone-safari-type/
  -webkit-text-size-adjust: 100%;
`
