import React from 'react'
import { PageLayout } from '@blue-agency/im-shared-front'
import { Icon, NewTabLink, theme, Txt } from '@blue-agency/rogue'
import { Illustration } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { MY_EXTERNAL_URLS } from '../urlService'

const Header: React.VFC = () => {
  return (
    <Wrapper>
      <NewTabLink href={MY_EXTERNAL_URLS.help.top} hideIcon action="open_help_page">
        <QuestionIcon />
      </NewTabLink>
    </Wrapper>
  )
}

export const NotFoundPage: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} header={<Header />} disableLogoLink>
      <Inner>
        <Description>
          <Txt size="l">
            お探しのページは存在しません。
            <br />
            URLが誤っているか、現在利用できない可能性があります。
            <br />
            企業の担当者様にお問い合わせください。
          </Txt>
        </Description>
        <Illustration name="not-found" width="278" />
      </Inner>
    </PageLayout>
  )
}

const Inner = styled.div`
  margin: 44px auto;
  max-width: 90%;
  text-align: center;
`

const Description = styled.div`
  margin-bottom: 40px;
`

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question' })`
  height: 22px;
  width: 22px;
  color: ${theme.color.navy[1]};
`
