import React from 'react'
import { PageLayout } from '@blue-agency/im-shared-front'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { Illustration } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const NotFinishedPage: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]}>
      <Inner>
        <Description>
          <Txt size="l">
            議事録画面は面接終了後にご利用いただけます。
            <br />
            面接後に再度アクセスしてください。
          </Txt>
        </Description>
        <NoUnderline>
          <a href={getInternalUrls().dashboard}>
            <Txt size="l" color={theme.color.blue[2]}>
              ダッシュボードへ戻る
            </Txt>
          </a>
        </NoUnderline>
        <Illustration name="general-error" width="200" />
      </Inner>
    </PageLayout>
  )
}

const Inner = styled.div`
  margin: 44px auto;
  max-width: 90%;
  text-align: center;
`

const Description = styled.div`
  margin-bottom: 25px;
`
const NoUnderline = styled.div`
  margin-bottom: 25px;
  > * {
    text-decoration: none;
  }
`
