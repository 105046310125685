import { useMemo } from 'react'
import { useIsIPad } from '@blue-agency/rogue'
import { useInterviewDisplaySize } from '@/shared/hooks/useInterviewDisplaySize'
import type { TileSizeCalculator } from './tileView'
import { getTileSizeCalculator } from './tileView'

export type SizeInNumber = {
  width: number
  height: number
}

export type SizeInString = {
  width: string
  height: string
}

type Args = {
  // 面接画面の全体の広さ
  // alert box の表示を除いた領域なので、window そのものの大きさとは必ずしも一致しない
  entireAreaSize: SizeInNumber
  isTileView: boolean
  isChatAreaOpen: boolean
  isInterviewGuideAreaOpen: boolean
  isInterviewAutoQuestionAreaOpen: boolean
  isVideosAreaOpen: boolean
}

export function useAreaSize({
  entireAreaSize,
  isTileView,
  isChatAreaOpen,
  isInterviewGuideAreaOpen,
  isInterviewAutoQuestionAreaOpen,
  isVideosAreaOpen,
}: Args): AreaSize {
  const { isIPad } = useIsIPad()
  const { responsive } = useInterviewDisplaySize()

  return useMemo(() => {
    // pc
    if (responsive.pc) {
      return pcAreaSize({ entireAreaSize, isTileView, isChatAreaOpen })
    }

    const chatOrMemoOrGuide = isChatAreaOpen || isInterviewGuideAreaOpen || isInterviewAutoQuestionAreaOpen

    // spPortrait
    if (responsive.spPortrait) {
      if (isIPad) {
        return tabletPortraitAreaSize(chatOrMemoOrGuide)
      } else {
        return spPortraitAreaSize(chatOrMemoOrGuide)
      }
    }

    // spLandscape
    if (isIPad) {
      return tabletLandscapeAreaSize(chatOrMemoOrGuide, isVideosAreaOpen)
    }
    return spLandscapeAreaSize(chatOrMemoOrGuide, isVideosAreaOpen)
  }, [
    entireAreaSize,
    isTileView,
    isChatAreaOpen,
    isInterviewGuideAreaOpen,
    isInterviewAutoQuestionAreaOpen,
    isVideosAreaOpen,
    responsive,
    isIPad,
  ])
}

type AreaSize = {
  // 面接ビュー or タイルビュー
  // 今のビューモードによらず、DOM 上には両方のビューを置いておき、実際の表示の切り替えは
  // display: none; の出し分けによって行っている
  // そのため、例えば今のビューが面接ビューである場合でも、タイルビューの表示に関わる情報を
  // ダミー情報としてもっておく
  type: 'interview_view' | 'tile_view'

  // 面接ビュー時に一番大きく表示される映像のサイズ
  // タイルビュー時にも非表示要素としてDOM上には置いておく
  main: SizeInString

  // 面接ビュー時にメインエリアの横に表示される参加者映像一覧のサイズ
  // タイルビュー時にも非表示要素としてDOM上には置いておく
  videos: SizeInString

  // タイルビュー時の映像一覧に関するサイズ情報
  // 面接ビュー時にも非表示要素としてDOM上には置いておく
  tiles: {
    areaSize: SizeInString
    tileSizeCalculator: TileSizeCalculator
  }

  // チャット、応募者メモ、面接ガイド
  menuContent: SizeInString

  // コントロールバー
  control: SizeInString

  // 面接ガイドのドラッグ可能モーダルの可動領域
  draggableInterviewGuide: SizeInString
}

const DUMMY_MAIN_SIZE = {
  width: '1000px',
  height: '600px',
}

const DUMMY_VIDEOS_SIZE = {
  width: '200px',
  height: '600px',
}

const DUMMY_TILES_SIZE = {
  areaSize: {
    width: '1000px',
    height: '600px',
  },
  tileSizeCalculator: getTileSizeCalculator({ width: 1000, height: 600 }),
}

const DUMMY_DRAGGABLE_INTERVIEW_GUIDE_SIZE = {
  width: '600px',
  height: '600px',
}

type PcAreaSizeArgs = Pick<Args, 'entireAreaSize' | 'isTileView' | 'isChatAreaOpen'>

function pcAreaSize({ entireAreaSize, isTileView, isChatAreaOpen }: PcAreaSizeArgs): AreaSize {
  const chatWidth = 250

  // PCのみ、チャットエリアとガイドエリアの表示が排他ではない
  let mw = 0
  if (isChatAreaOpen) mw += chatWidth

  const menuWidth = `${mw}px`
  const controlHeightNum = 60
  const controlHeight = `${controlHeightNum}px`
  const menuContent = {
    width: menuWidth,
    height: `calc(100% - ${controlHeight})`,
  }
  const control = {
    height: '60px',
    width: '100%',
  }
  const draggableInterviewGuide = {
    width: '100%',
    height: `calc(100% - ${controlHeight})`,
  }

  if (isTileView) {
    return {
      type: 'tile_view',
      main: DUMMY_MAIN_SIZE,
      videos: DUMMY_VIDEOS_SIZE,
      tiles: {
        areaSize: {
          width: `calc(100% - ${menuWidth})`,
          height: `calc(100% - ${controlHeight})`,
        },
        tileSizeCalculator: getTileSizeCalculator({
          height: entireAreaSize.height - controlHeightNum,
          width: entireAreaSize.width - mw,
        }),
      },
      menuContent,
      control,
      draggableInterviewGuide,
    }
  } else {
    const videosWidth = '217px'

    return {
      type: 'interview_view',
      main: {
        width: `calc(100% - ${videosWidth} - ${menuWidth})`,
        height: `calc(100% - ${controlHeight})`,
      },
      videos: {
        width: videosWidth,
        height: `calc(100% - ${controlHeight})`,
      },
      tiles: DUMMY_TILES_SIZE,
      menuContent,
      control,
      draggableInterviewGuide,
    }
  }
}

function tabletPortraitAreaSize(chatOrMemoOrGuide: boolean): AreaSize {
  const videosHeight = chatOrMemoOrGuide ? '237px' : '388px'
  const menuHeight = chatOrMemoOrGuide ? '450px' : '0px'
  const controlHeight = '60px'

  return {
    type: 'interview_view',
    main: {
      height: `calc(100% - ${videosHeight} - ${menuHeight} - ${controlHeight})`,
      width: '100%',
    },
    videos: {
      height: videosHeight,
      width: '100%',
    },
    tiles: DUMMY_TILES_SIZE,
    menuContent: {
      height: `min(${menuHeight}, 100% - ${videosHeight} - ${controlHeight})`,
      width: '100%',
    },
    control: {
      height: controlHeight,
      width: '100%',
    },
    draggableInterviewGuide: DUMMY_DRAGGABLE_INTERVIEW_GUIDE_SIZE,
  }
}

function tabletLandscapeAreaSize(chatOrMemoOrGuide: boolean, videos: boolean): AreaSize {
  const videosWidth = videos ? '207px' : '0px'
  const menuWidth = chatOrMemoOrGuide ? '250px' : '0px'
  const controlWidth = '64px'

  return {
    type: 'interview_view',
    main: {
      width: `calc(100% - ${videosWidth} - ${menuWidth} - ${controlWidth})`,
      height: '100%',
    },
    videos: {
      width: videosWidth,
      height: '100%',
    },
    tiles: DUMMY_TILES_SIZE,
    menuContent: {
      width: menuWidth,
      height: '100%',
    },
    control: {
      width: controlWidth,
      height: '100%',
    },
    draggableInterviewGuide: DUMMY_DRAGGABLE_INTERVIEW_GUIDE_SIZE,
  }
}

function spPortraitAreaSize(chatOrMemoOrGuide: boolean): AreaSize {
  const videosHeight = chatOrMemoOrGuide ? '121px' : '223px'
  const menuHeight = chatOrMemoOrGuide ? '272px' : '0px'
  const controlHeight = '60px'

  return {
    type: 'interview_view',
    main: {
      height: `calc(100% - ${videosHeight} - ${menuHeight} - ${controlHeight})`,
      width: '100%',
    },
    videos: {
      height: videosHeight,
      width: '100%',
    },
    tiles: DUMMY_TILES_SIZE,
    menuContent: {
      height: `min(${menuHeight}, 100% - ${videosHeight} - ${controlHeight})`,
      width: '100%',
    },
    control: {
      height: controlHeight,
      width: '100%',
    },
    draggableInterviewGuide: DUMMY_DRAGGABLE_INTERVIEW_GUIDE_SIZE,
  }
}

function spLandscapeAreaSize(chatOrMemoOrGuide: boolean, videos: boolean): AreaSize {
  const videosWidth = videos ? '127px' : '0px'
  const menuWidth = chatOrMemoOrGuide ? '250px' : '0px'
  const controlWidth = '64px'

  return {
    type: 'interview_view',
    main: {
      width: `calc(100% - ${videosWidth} - ${menuWidth} - ${controlWidth})`,
      height: '100%',
    },
    videos: {
      width: videosWidth,
      height: '100%',
    },
    tiles: DUMMY_TILES_SIZE,
    menuContent: {
      width: menuWidth,
      height: '100%',
    },
    control: {
      width: controlWidth,
      height: '100%',
    },
    draggableInterviewGuide: DUMMY_DRAGGABLE_INTERVIEW_GUIDE_SIZE,
  }
}
