import React, { useMemo, useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { IntervieweeLogType } from '@blue-agency/proton/web/v2/im'
import { InterviewPageContainer } from '../../containers/InterviewPageContainer'
import { Entrance } from '../Entrance'
import { EnvironmentCheck } from '../EnvironmentCheck'

type Step = 'entrance' | 'environmentCheck'

const useStep = () => {
  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) {
    throw new Error('invalid state')
  }
  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }

  const [step, setStep] = useState<Step>('entrance')

  const content = useMemo(() => {
    switch (step) {
      case 'entrance':
        return <Entrance />
      case 'environmentCheck':
        return <EnvironmentCheck />
      default:
        throw new Error('Invalid step')
    }
  }, [step])

  const { onPreparingFinish, sendIntervieweeLog } = pageCtx

  const toEnvironmentCheck = useCallback(() => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_AGREE_TERMS_OF_SERVICES)
    setStep('environmentCheck')
  }, [sendIntervieweeLog])

  const handleEnvironmentCheckFinish = useCallback(() => {
    onPreparingFinish()
  }, [onPreparingFinish])

  return { content, handleEnvironmentCheckFinish, toEnvironmentCheck }
}

export const StepContainer = createContainer(useStep)
