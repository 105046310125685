import React from 'react'
import { Icon, theme } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { FinishButton as BizFinishButton } from '@/biz/components/FinishButton'
import { useFinishDropdown } from '@/biz/pages/InterviewPage/Interview/InRoom/hooks/useFinishDropdown'
import { FinishButton as MyFinishButton } from '@/my/components/FinishButton'
import { NotificationDot as _NotificationDot } from '@/shared/components/NotificationDot'
import type { DropdownBasePosition } from '@/shared/hooks/useDropdown'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { buttonResetStyle } from '@/shared/styles/buttonResetStyle'
import { useMenuDropdown } from '../hooksForUi/useMenuDropdown'
import { Dropdown as _Dropdown } from './Dropdown'
import { Timer } from './Timer'
import { CameraMuteButton } from './controlButtons/CameraMuteButton'
import { ChatButton } from './controlButtons/ChatButton'
import { GuideButton } from './controlButtons/GuideButton'
import { MicMuteButton } from './controlButtons/MicMuteButton'
import { MinutesButton } from './controlButtons/MinutesButton'
import { PinButton } from './controlButtons/PinButton'
import { ReloadButton } from './controlButtons/ReloadButton'
import { ScreenShareButton } from './controlButtons/ScreenShareButton'

export const PcControls: React.VFC = () => {
  const {
    dropdownItems: menuDropdownItems,
    dropdownOpenerRef: menuDropdownOpenerRef,
    dropdownRef: menuDropdownRef,
    isDropdownShown: isMenuDropdownShown,
    basePosition: menuDropdownBasePosition,
    toggleDropdownShown: toggleMenuDropdownShown,
    showingTroubleShootingNotification,
    renderReloadModal,
    renderUnstableConnectionModal,
    renderChangeDeviceModal,
    renderBackgroundSettingModal,
    renderQualityModeModal,
    renderUnstableConnectionWarningBalloon,
  } = useMenuDropdown()
  // 画面共有(getDisplayMedia)できない環境では画面共有ボタンを出さない
  const showScreenShareButton = typeof navigator.mediaDevices.getDisplayMedia !== 'undefined'

  const { appType } = InterviewContainer.useContainer()
  const isMy = appType === 'my'
  const menuDropdownWidth = 250

  return (
    <Wrapper>
      <Left>
        {!isMy && <MinutesButton />}
        <VerticalLine />
        <TimerWrapper>
          <Timer />
        </TimerWrapper>
        <VerticalLine />
        <LeftButtonsWrapper>
          <ReloadButton />
        </LeftButtonsWrapper>
      </Left>
      <MiddleWrapper>
        <Middle>
          <ButtonsWrapper>
            <MicMuteButton />
            <CameraMuteButton />
          </ButtonsWrapper>
          <VerticalLine />
          <ButtonsWrapper>
            {!isMy && <GuideButton />}
            {!isMy && <PinButton />}
            <ChatButton />
            {showScreenShareButton && <ScreenShareButton />}
            <DropdownOpener ref={menuDropdownOpenerRef}>
              {showingTroubleShootingNotification && <NotificationDot />}
              <MoreButton onClick={toggleMenuDropdownShown} data-tip data-for="more">
                <MoreIcon />
              </MoreButton>
              {!isMenuDropdownShown && (
                <Tooltip id="more" arrowPosition="bottomLeft">
                  その他のメニュー
                </Tooltip>
              )}
            </DropdownOpener>
          </ButtonsWrapper>
          {renderReloadModal()}
          {renderUnstableConnectionModal()}
          {renderChangeDeviceModal()}
          {renderBackgroundSettingModal()}
          {renderQualityModeModal()}
          {isMenuDropdownShown &&
            menuDropdownBasePosition &&
            ReactDOM.createPortal(
              <MenuDropdown
                ref={menuDropdownRef}
                items={menuDropdownItems}
                basePosition={menuDropdownBasePosition}
                width={menuDropdownWidth}
              />,
              document.getElementById('root')!
            )}
          {ReactDOM.createPortal(renderUnstableConnectionWarningBalloon(), document.getElementById('root')!)}
        </Middle>
      </MiddleWrapper>
      <Right>{isMy ? <MyFinishButton /> : <FinishButtonAndDropdown />}</Right>
    </Wrapper>
  )
}

function FinishButtonAndDropdown() {
  const {
    dropdownItems: finishDropdownItems,
    dropdownOpenerRef: finishDropdownOpenerRef,
    dropdownRef: finishDropdownRef,
    isDropdownShown: isFinishDropdownShown,
    basePosition: finishDropdownBasePosition,
    toggleDropdownShown: toggleFinishDropdownShown,
  } = useFinishDropdown()

  const finishDropdownWidth = 234

  return (
    <>
      <DropdownOpener ref={finishDropdownOpenerRef}>
        <BizFinishButton toggle={toggleFinishDropdownShown} isDropdownShown={isFinishDropdownShown} />
      </DropdownOpener>
      {isFinishDropdownShown &&
        finishDropdownBasePosition &&
        ReactDOM.createPortal(
          <FinishDropdown
            ref={finishDropdownRef}
            items={finishDropdownItems}
            basePosition={finishDropdownBasePosition}
            width={finishDropdownWidth}
          />,
          document.getElementById('root')!
        )}
    </>
  )
}

const Wrapper = styled.div`
  position: relative;
  border-top: 1px solid ${theme.color.gray[4]};
  height: 100%;
  width: 100%;
`

const Left = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
`

const MiddleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`

const Middle = styled.div`
  height: 100%;
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

const Right = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 16px;
`

const TimerWrapper = styled.div`
  margin-left: 16px;
  margin-right: 20px;
`

const VERTICAL_LINE_MARGIN = '8px'

const VerticalLine = styled.div`
  width: 1px;
  margin: ${VERTICAL_LINE_MARGIN} 0;
  height: calc(100% - ${VERTICAL_LINE_MARGIN} * 2);
  background-color: ${theme.color.gray[3]};
  opacity: 0.5;
`

const buttonsWrapper = css`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  & > * {
    width: 90px;
    height: 48px;
  }
`

const ButtonsWrapper = styled.div`
  ${buttonsWrapper}
`

const LeftButtonsWrapper = styled.div`
  ${buttonsWrapper};
  margin-left: 8px;
`

const DropdownOpener = styled.div`
  position: relative;
`

const MoreButton = styled.button`
  ${buttonResetStyle};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MoreIcon = styled(Icon).attrs({ name: 'more-horizontal', width: '24px', height: '24px' })`
  cursor: pointer;
`

type DropdownProps = {
  basePosition: DropdownBasePosition
}

const MenuDropdown = styled(_Dropdown)<DropdownProps>`
  position: fixed;
  bottom: 60px;
  right: calc(100% - ${({ basePosition }) => basePosition.right + 100}px);
  z-index: 99;
`

const FinishDropdown = styled(_Dropdown)<DropdownProps>`
  position: absolute;
  bottom: 60px;
  right: calc(100% - ${({ basePosition }) => basePosition.right + 5}px);
  z-index: 99;
`

const NotificationDot = styled(_NotificationDot)`
  position: absolute;
  top: 12px;
  right: 16px;
`
