import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { InPageChatBot, useInitializeComlinkStaff } from '@blue-agency/im-shared-front'
import { GetInterviewResponse } from '@blue-agency/proton/biz_skywalker_bff'
import { EnvironmentCheck } from './EnvironmentCheck/EnvironmentCheck'
import { Expired } from './Expired'
import { Finished } from './Finished'
import { Interview } from './Interview'
import { InterviewPageContainer } from './containers/InterviewPageContainer'

export const InterviewPage: React.VFC = provide(InterviewPageContainer)(() => {
  const pageCtx = InterviewPageContainer.useContainer()

  useInitializeComlinkStaff()

  if (pageCtx.isLoading) {
    // TODO: ローディング表示に置き換える
    return null
  }

  switch (pageCtx.status) {
    case GetInterviewResponse.Status.VALID:
      return pageCtx.isFinishedEnvironmentCheck ? (
        <Interview />
      ) : (
        <>
          <InPageChatBot botType="biz" isBottomFixed />
          <EnvironmentCheck />
        </>
      )
    case GetInterviewResponse.Status.FINISHED:
      return <Finished isInterviewTranscriptionEnabled={pageCtx.isInterviewTranscriptionEnabled} />
    case GetInterviewResponse.Status.EXPIRED:
      return <Expired />
  }
  throw new Error('unexpected state')
})
