import React from 'react'
import { IntervieweeLogType } from '@blue-agency/proton/web/v2/im'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import styled from 'styled-components'
import invariant from 'tiny-invariant'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { checkBrowser } from '@/shared/services/browserCheckService'
import { InterviewPageContainer } from '../../InterviewPage/containers/InterviewPageContainer'
import { useEnvironmentCheck } from '../../hooks/useEnvironmentCheck'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName

export const EnvironmentCheck: React.VFC<Props> = ({ className }) => {
  const { sendIntervieweeLog } = InterviewPageContainer.useContainer()
  invariant(sendIntervieweeLog)
  const { handleEnvironmentCheckFinish } = StepContainer.useContainer()

  const agreeEnvironmentPage1Log = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_AGREE_USAGE_ENVIRONMENT_PAGE1)
  }
  const agreeEnvironmentPage2Log = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_AGREE_USAGE_ENVIRONMENT_PAGE2)
  }
  const passOSBrowserTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_PASS_OS_BROWSER_TEST)
  }
  const failOSBrowserTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_FAIL_OS_BROWSER_TEST)
  }
  const passCameraTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_PASS_CAMERA_TEST)
  }
  const failCameraTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_FAIL_CAMERA_TEST)
  }
  const passMicTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_PASS_MICROPHONE_TEST)
  }
  const failMicTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_FAIL_MICROPHONE_TEST)
  }
  const passSpeakerTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_PASS_SPEAKER_TEST)
  }
  const failSpeakerTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_FAIL_SPEAKER_TEST)
  }
  const passSpeedTestLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_PASS_INTERNET_SPEED_TEST)
  }

  const { cameraTestConnectionProps } = useEnvironmentCheck()
  if (!cameraTestConnectionProps) return null // TODO: ローディング表示
  return (
    <Wrapper className={className}>
      <EnvironmentCheckComponent
        userType="interviewee"
        steps={[
          { name: 'recommendForParticipant', onPass: agreeEnvironmentPage1Log },
          {
            name: 'recommendForAll',
            onPass: agreeEnvironmentPage2Log,
          },
          {
            name: 'browser',
            helpUrl: MY_EXTERNAL_URLS.help.environment.os,
            showRecommendPc: true,
            checkBrowserFunction: checkBrowser,
            onPass: passOSBrowserTestLog,
            onFail: failOSBrowserTestLog,
          },
          {
            name: 'camera',
            helpUrl: MY_EXTERNAL_URLS.help.environment.cameraAndMic,
            onPass: passCameraTestLog,
            onFail: failCameraTestLog,
            ...cameraTestConnectionProps,
          },
          {
            name: 'mic',
            helpUrl: MY_EXTERNAL_URLS.help.environment.cameraAndMic,
            onPass: passMicTestLog,
            onFail: failMicTestLog,
          },
          {
            name: 'speaker',
            helpUrl: MY_EXTERNAL_URLS.help.environment.speaker,
            onPass: passSpeakerTestLog,
            onFail: failSpeakerTestLog,
          },
          {
            name: 'speed',
            helpUrl: MY_EXTERNAL_URLS.help.environment.speed,
            onPass: passSpeedTestLog,
          },
        ]}
        onFinish={handleEnvironmentCheckFinish}
      />
    </Wrapper>
  )
}

// TODO: 一時しのぎ
const headerHeight__temporaryValue = 32

const Wrapper = styled.div`
  min-height: calc(100vh - ${headerHeight__temporaryValue}px);
`
