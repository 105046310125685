import { LOCAL_STORAGE_KEY } from '../storageService'

const visibilities = ['visible', 'hidden'] as const

export type SelfViewVisibility = typeof visibilities[number]

export function getSavedSelfViewVisibility(): SelfViewVisibility {
  const visibility = localStorage.getItem(LOCAL_STORAGE_KEY.selfViewVisibility) ?? 'visible'

  // SelfViewVisibility 以外の値を返さないようにチェック
  for (const v of visibilities) {
    if (v === visibility) {
      return v
    }
  }

  return 'visible'
}

export function saveSelfViewVisibility(visibility: SelfViewVisibility) {
  localStorage.setItem(LOCAL_STORAGE_KEY.selfViewVisibility, visibility)
}
