import React from 'react'
import { theme } from '@blue-agency/rogue'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { FinishButton as BizFinishButton } from '@/biz/components/FinishButton'
import { useFinishDropdown } from '@/biz/pages/InterviewPage/Interview/InRoom/hooks/useFinishDropdown'
import { FinishButton as MyFinishButton } from '@/my/components/FinishButton'
import type { DropdownBasePosition } from '@/shared/hooks/useDropdown'
import { ReloadButton } from '@/shared/services/interviewService/components/controlButtons/ReloadButton'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { Dropdown as _Dropdown } from './Dropdown'
import { Timer } from './Timer'

export const MobileHeaderControls: React.VFC = React.memo(() => {
  const { appType } = InterviewContainer.useContainer()
  const isMy = appType === 'my'

  return (
    <Wrapper>
      <Left>
        <TimerWrapper>
          <Timer />
        </TimerWrapper>
        <VerticalLine />
        <ButtonWrapper>
          <ReloadButton />
        </ButtonWrapper>
      </Left>
      <Right>{isMy ? <MyFinishButton /> : <FinishButtonAndDropdown />}</Right>
    </Wrapper>
  )
})

function FinishButtonAndDropdown() {
  const {
    dropdownItems: finishDropdownItems,
    dropdownOpenerRef: finishDropdownOpenerRef,
    dropdownRef: finishDropdownRef,
    isDropdownShown: isFinishDropdownShown,
    basePosition: finishDropdownBasePosition,
    toggleDropdownShown: toggleFinishDropdownShown,
  } = useFinishDropdown()

  const finishDropdownWidth = 234

  return (
    <>
      <DropdownOpener ref={finishDropdownOpenerRef}>
        <BizFinishButton toggle={toggleFinishDropdownShown} isDropdownShown={isFinishDropdownShown} />
      </DropdownOpener>
      {isFinishDropdownShown &&
        finishDropdownBasePosition &&
        ReactDOM.createPortal(
          <FinishDropdown
            ref={finishDropdownRef}
            items={finishDropdownItems}
            basePosition={finishDropdownBasePosition}
            width={finishDropdownWidth}
          />,
          document.getElementById('root')!
        )}
    </>
  )
}

const Wrapper = styled.div`
  height: 35px;
  width: 100%;
  background-color: ${theme.color.gray[5]};
  display: flex;
  justify-content: space-between;
`

const Left = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`

const TimerWrapper = styled.div`
  margin: 0 8px;
`

const ButtonWrapper = styled.div`
  width: 64px;
  height: 100%;
  margin-left: 4px;
`

const VERTICAL_LINE_MARGIN = '6px'

const VerticalLine = styled.div`
  margin: 5px 0;
  width: 1px;
  height: calc(100% - ${VERTICAL_LINE_MARGIN} * 2);
  background-color: ${theme.color.gray[3]};
`

const DropdownOpener = styled.div`
  position: relative;
`

const FinishDropdown = styled(_Dropdown)<{ basePosition: DropdownBasePosition }>`
  position: absolute;
  z-index: 99;

  ${({ theme, basePosition }) => {
    if (theme.responsive.pc) {
      return css`
        bottom: 60px;
        right: calc(100% - ${basePosition.right + 100}px);
      `
    }

    return css`
      top: 36px;
      right: calc(100% - ${basePosition.right + 3}px);
    `
  }}
`
