import React from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, SecondaryDangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { InRoomContainer } from './containers/InRoomContainer'

export const FinishModal: React.VFC = () => {
  const { finishModal, finishInterview } = InRoomContainer.useContainer()
  const { active, close } = finishModal
  return (
    <Modal active={active} onClose={close} title="面接を終了" size="s">
      <Upper>
        <Txt>
          面接を終了しようとしています。
          <br />
          面接官が複数の場合は、一人が終了させた時点で面接が終了となります。
        </Txt>
      </Upper>
      <Separator />
      <Bottom>
        <ButtonGroup>
          <CancelButton
            onClick={close}
            widthSize="L1"
            comlinkPushParams={{ action: 'click_cancel_button_on_finish_modal' }}
          >
            キャンセル
          </CancelButton>
          <FinishButton
            onClick={finishInterview}
            widthSize="L1"
            comlinkPushParams={{ action: 'click_finish_room_button_on_finish_modal' }}
          >
            面接を終了
          </FinishButton>
        </ButtonGroup>
      </Bottom>
    </Modal>
  )
}

const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 40px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const CancelButton = styled(TertiaryButton)`
  width: 48%;
`
const FinishButton = styled(SecondaryDangerButton)`
  width: 48%;
`
