import { theme } from '@blue-agency/rogue'
import { DropdownLinkItem } from '@/shared/hooks/useDropdown'

type Args = {
  leaveInterview: () => void
  kickoutAllIntervieweeModalOpen: () => void
  finishModalOpen: () => void
  intervieweesNotExist: boolean
  notDisplayFinishButtonOrganizationGUIDList: string[]
  organizationGUID: string
}

export const getDropdownItems = (args: Args) => {
  const dropdownItems: DropdownLinkItem[] = [
    {
      type: 'DropdownLinkItem',
      text: '自分のみ退室',
      onClick: args.leaveInterview,
      comlinkOnClickAction: 'click_leave_interview_room_button',
    },
    {
      type: 'DropdownLinkItem',
      text: '応募者のみ退室',
      onClick: args.kickoutAllIntervieweeModalOpen,
      detailDescription: '評価のすり合わせにご利用ください',
      disabled: args.intervieweesNotExist,
      comlinkOnClickAction: 'click_kickout_all_interviewee_button',
    },
    {
      type: 'DropdownLinkItem',
      text: '面接を終了',
      onClick: args.finishModalOpen,
      comlinkOnClickAction: 'click_finish_interview_button',
      color: theme.color.red[2],
    },
  ]
  // https://blue-agency.atlassian.net/browse/PIM-3745
  if (args.notDisplayFinishButtonOrganizationGUIDList.includes(args.organizationGUID)) {
    dropdownItems.pop()
  }

  return dropdownItems
}
