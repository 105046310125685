import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BizInterviewGuideContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { GuidePartView } from '@/shared/services/interviewService/components/InterviewGuide/GuidePartView'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'

export function PcInterviewGuide() {
  const { guide } = BizInterviewGuideContainer.useContainer()
  if (!guide) {
    return null
  }
  return (
    <Wrapper>
      <Header>
        <Txt size="s" color={theme.color.navy[1]} bold>
          面接ガイド
        </Txt>
      </Header>
      <Body>
        <NameWrapper>
          <Txt size="s" color={theme.color.gray[1]} bold>
            {guide.name}
          </Txt>
        </NameWrapper>
        <GuideParts>
          {guide.parts.map((part) => {
            return <GuidePartView key={part.startSeconds} part={part} />
          })}
        </GuideParts>
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${theme.color.navy[1]};
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  height: 34px;
  padding: 8px;
  background-color: ${theme.color.navy[3]};
  border-bottom: 1px solid ${theme.color.gray[3]};
`
const Body = styled.div`
  width: 100%;
  height: 100%;
  padding: 8px 12px 8px 12px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const NameWrapper = styled.div`
  padding: 8px 12px 8px 12px;
`

const GuideParts = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${overflowYScrollStyle};
`
