import React from 'react'
import { theme, Icon } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const LowQualityBox = () => {
  return (
    <Wrapper>
      軽量モードにより、解像度が低く設定されています
      <QuestionIconWrapper data-tip data-for="low-quality-tip">
        <QuestionIcon />
      </QuestionIconWrapper>
      <Tooltip id="low-quality-tip" arrowPosition="topLeft">
        管理者により、通信を安定させるために、参加者全員の解像度が通常よりも低く設定されています。
        <br />
        なお、この設定は入室後に変更できます。
      </Tooltip>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: ${theme.color.gray[5]};
  font-size: 12px;
  line-height: 14px;
  color: ${theme.color.navy[1]};
`

const QuestionIconWrapper = styled.span`
  cursor: pointer;
  margin-left: 9px;
`

const QuestionIcon = styled(Icon).attrs({ name: 'question' })`
  height: 18px;
  width: 18px;
  color: ${theme.color.navy[1]};
`
