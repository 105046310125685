import { useLocalStorage, useMount } from 'react-use'
import { useUA } from '@/shared/hooks/useUA'
import { isMac, isWindows } from '@/shared/services/browserCheckService/browser'

const DEFAULT_LIGHT_ADJUSTMENT_LEVEL = 0.3

export const useLightAdjustment = () => {
  const [enabledLightAdjustment, setEnabledLightAdjustment] = useLocalStorage<boolean>(
    'enabledLightAdjustment',
    undefined
  )
  const [lightAdjustmentStrength, setLightAdjustmentStrength] = useLocalStorage<number>(
    'lightAdjustmentLevel',
    undefined
  )
  const ua = useUA()
  const isUsedPC = isMac(ua) || isWindows(ua)

  useMount(() => {
    // PCで未設定の場合のデフォルト値をセットする
    // falseだと挙動としては何も対処しないのと同じだが、将来的にデフォルトをONに出来るように残しておく
    if (isUsedPC && enabledLightAdjustment === undefined) {
      setEnabledLightAdjustment(false)
    }
    if (isUsedPC && lightAdjustmentStrength === undefined) {
      setLightAdjustmentStrength(DEFAULT_LIGHT_ADJUSTMENT_LEVEL)
    }
  })

  return {
    enabledLightAdjustment,
    setEnabledLightAdjustment,
    lightAdjustmentLevel: lightAdjustmentStrength,
    setLightAdjustmentLevel: setLightAdjustmentStrength,
  }
}
