import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetOrganizationInterviewTranscriptionSettingRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetOrganizationInterviewTranscriptionSetting() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetOrganizationInterviewTranscriptionSetting = useCallback(async () => {
    const metadata = await buildMetadata()

    const req = new GetOrganizationInterviewTranscriptionSettingRequest()

    return callBff('getOrganizationInterviewTranscriptionSetting', [req, metadata], loggedInStaff?.guid)
  }, [buildMetadata, loggedInStaff])

  return { requestGetOrganizationInterviewTranscriptionSetting }
}
