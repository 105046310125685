import { useState, useCallback } from 'react'
import { createContainer, useCachedPromise } from '@blue-agency/front-state-management'
import { IntervieweeLogType } from '@blue-agency/proton/im'
import { GetInterviewResponse } from '@blue-agency/proton/my_skywalker_bff'
import { InterviewQualityMode as BffInterviewQualityMode } from '@blue-agency/proton/web/v2/my_skywalker_bff'
import { toast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { WithLoading } from '@/@types/withLoading'
import {
  useRequestGetInterview,
  useRequestGetInterviewQuality,
  useRequestSendIntervieweeLog,
} from '@/my/services/bffService'
import { cacheKey } from '@/my/services/cacheKeyService'
import { queryKey } from '@/my/services/queryKeyService'
import { SESSION_STORAGE_KEY } from '@/my/services/storageService'
import { getBrowserClientUuid } from '@/shared/services/browserClientUuidService'
import { InterviewQuality, InterviewQualityMode } from '@/shared/services/interviewService/types'

export type ResponseValid = {
  status: GetInterviewResponse.Status.VALID
  isValid: true
  isPrepared: boolean
  interviewGuid: string
  organizationName: string
  videoInterviewGuid: string
  qualityMode: InterviewQualityMode
  onPreparingFinish: () => void
  sendIntervieweeLog: (logType: IntervieweeLogType) => Promise<void>
}

type ResponseInvalid = {
  isValid: false
  isPrepared: boolean
  interviewGuid: string
  organizationName: string
  sendIntervieweeLog: (logType: IntervieweeLogType) => Promise<void>
}

export type ResponseFinished = ResponseInvalid & {
  status: GetInterviewResponse.Status.FINISHED
}

export type ResponseExpired = ResponseInvalid & {
  status: GetInterviewResponse.Status.EXPIRED
}

export type ResponseUnknown = ResponseInvalid & {
  status: GetInterviewResponse.Status.STATUS_UNKNOWN
}

export type Response = WithLoading<ResponseValid | ResponseFinished | ResponseExpired | ResponseUnknown>

function useInterviewPage(): Response {
  const { interviewGuid } = useParams<{ interviewGuid: string }>()

  const { data, isLoading } = useGetInterviewQuery(interviewGuid)

  const [isPrepared, setIsPrepared] = useState(() => {
    return window.sessionStorage.getItem(SESSION_STORAGE_KEY.interview.preparing(interviewGuid)) === 'done'
  })

  const onPreparingFinish = useCallback(() => {
    toast('動作環境テストが完了しました')
    window.sessionStorage.setItem(SESSION_STORAGE_KEY.interview.preparing(interviewGuid), 'done')
    setIsPrepared(true)
  }, [interviewGuid])

  const quality = useGetInterviewQuality(interviewGuid)

  const { requestSendIntervieweeLog } = useRequestSendIntervieweeLog()
  const sendIntervieweeLog = useCallback(
    async (logType: IntervieweeLogType) => {
      const clientBrowserUuid = await getBrowserClientUuid()
      try {
        await requestSendIntervieweeLog({ interviewGuid, clientBrowserUuid, logType })
      } catch (err) {
        // 失敗してもSentryにだけ送って何もしない
        Sentry.captureException(err)
        return
      }
    },
    [requestSendIntervieweeLog, interviewGuid]
  )

  if (isLoading) {
    return { isLoading: true }
  }

  invariant(data)

  const status = data.getStatus()
  if (status === GetInterviewResponse.Status.VALID) {
    return {
      status,
      isValid: true,
      isLoading: false,
      interviewGuid,
      organizationName: data.getOrganizationName(),
      videoInterviewGuid: data.getVideoInterviewGuid(),
      isPrepared,
      qualityMode: quality.mode,
      onPreparingFinish,
      sendIntervieweeLog,
    }
  } else {
    return {
      status,
      isValid: false,
      isPrepared,
      isLoading: false,
      interviewGuid,
      organizationName: data.getOrganizationName(),
      sendIntervieweeLog,
    }
  }
}

export const InterviewPageContainer = createContainer(useInterviewPage)

function useGetInterviewQuery(interviewGuid: string) {
  const { requestGetInterview } = useRequestGetInterview()

  return useQuery([queryKey.interview, interviewGuid], () => requestGetInterview(interviewGuid))
}

function useGetInterviewQuality(interviewGuid: string) {
  const { requestGetInterviewQuality } = useRequestGetInterviewQuality()

  return useCachedPromise(cacheKey.getInterviewQuality({ interviewGuid }), async (): Promise<InterviewQuality> => {
    const res = await requestGetInterviewQuality(interviewGuid)
    return {
      mode: res.getMode() === BffInterviewQualityMode.LOW ? 'low' : 'normal',
      videoBitRate: res.getVideoBitRate(),
      videoFrameRate: res.getVideoFrameRate(),
    }
  })
}
