import React, { createContext } from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { useToast, toastEmitter, TOAST_EVENT_NAME } from './hooks/useToast'

export type ToastType = 'good' | 'bad' | 'question'

export type ToastProps = {
  duration: number
  type: ToastType
  onClose: () => void
}

type ToastStyleValues = {
  [k in ToastType]: {
    bgColor: string
  }
}

const toastStyleValues: ToastStyleValues = {
  good: {
    bgColor: theme.color.blue[4],
  },
  bad: {
    bgColor: theme.color.red[4],
  },
  question: {
    bgColor: theme.color.orange[2],
  },
}

export const ToastContext = createContext<ToastProps | undefined>(undefined)

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
})
const fadeOut = keyframes({
  from: {
    opacity: 1,
  },
  to: {
    opacity: 0,
  },
})

export type ToastEvent = {
  type: ToastType
  duration: number
}

export function showInterviewPinningToast(type: ToastType, duration: number) {
  toastEmitter.emit<ToastEvent>(TOAST_EVENT_NAME, {
    type: type,
    duration: duration,
  })
}

export const InterviewPinningToastContainer = (): React.ReactElement | null => {
  const { toastParams, handleToastClose } = useToast()
  if (toastParams === undefined) {
    return null
  }
  return <InterviewPinningToast type={toastParams.type} duration={toastParams.duration} onClose={handleToastClose} />
}

const InterviewPinningToast: React.FC<ToastProps> = ({ duration, type }) => {
  const faceIcon = () => {
    switch (type) {
      case 'good':
        return <InterviewPinningGoodFaceIcon />
      case 'bad':
        return <InterviewPinningBadFaceIcon />
      case 'question':
        return <InterviewPinningQuestionFaceIcon />
    }
  }

  return ReactDOM.createPortal(
    <Wrapper duration={duration} type={type}>
      {faceIcon()}
      <Txt>を記録しました</Txt>
    </Wrapper>,
    document.getElementById('root') as HTMLElement
  )
}

const animationDuration = 300
const Wrapper = styled.div<{ type: ToastType; duration: number }>`
  display: inline-flex;
  position: absolute;
  padding: 10px 8px 10px 8px;
  line-height: 1.5;
  bottom: 272px;
  right: 260px;
  margin: auto;
  justify-content: start;
  align-items: center;
  background-color: ${({ type }) => toastStyleValues[type].bgColor};
  opacity: 0.9;
  border-radius: 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  animation: ${fadeIn} ${animationDuration}ms 0ms forwards,
    ${fadeOut} ${animationDuration}ms ${({ duration }) => duration}ms forwards;
  width: 188px;
  height: 34px;
  text-align: center;
  white-space: pre-wrap;
  z-index: 2;
`

const InterviewPinningGoodFaceIcon = styled(Icon).attrs({ name: 'good-face' })`
  color: ${theme.color.blue[1]};
  padding-right: 2px;
`
const InterviewPinningBadFaceIcon = styled(Icon).attrs({ name: 'bad-face' })`
  color: ${theme.color.red[2]};
  padding-right: 2px;
`
const InterviewPinningQuestionFaceIcon = styled(Icon).attrs({ name: 'question-face' })`
  color: ${theme.color.orange[1]};
  padding-right: 2px;
`
