import { useCallback, useEffect, useState } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { speakerTestSoundSrc } from './speakerTestSoundSrc'

const audio = new Audio()
audio.src = speakerTestSoundSrc
audio.volume = 0.1

type Response = {
  playSound: () => void
  isPlaying: boolean
}

export function usePlayDemoSound(selectedSpeakerDeviceId: string | undefined): Response {
  const [isPlaying, setIsPlaying] = useState(false)

  const playSound = useCallback(() => {
    setIsPlaying(true)
    audio.play()
  }, [])

  // デモ音声の再生が終わったときに isPlaying を false に戻す
  useEffect(() => {
    const setIsPlayingToFalse = () => setIsPlaying(false)

    audio.addEventListener('ended', setIsPlayingToFalse)
    audio.addEventListener('error', setIsPlayingToFalse)

    return () => {
      audio.removeEventListener('ended', setIsPlayingToFalse)
      audio.removeEventListener('error', setIsPlayingToFalse)
    }
  }, [])

  // 選択されているスピーカーが切り替わったときに、音声の実際の出力先を変更する
  useEffect(() => {
    const setSinkId = async () => {
      if (audio.setSinkId === undefined) return
      if (selectedSpeakerDeviceId === undefined) return
      try {
        await audio.setSinkId(selectedSpeakerDeviceId)
      } catch (e) {
        comlinkPush({
          action: 'failed_to_switch_speaker_on_device_change_modal',
          metadata: {
            errorMessage: e.message ?? '',
          },
        })
        alert('スピーカーの切り替えに失敗しました。')
      }
    }

    setSinkId()
  }, [selectedSpeakerDeviceId])

  return {
    playSound,
    isPlaying,
  }
}
