import { useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { CreateImAssistantQuestionsRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { useSelector } from 'react-redux'
import { InterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { useRequestCreateImAssistantQuestion } from '@/biz/services/bffService'
import { SharedState } from '@/shared/services/interviewService/redux'

const useInterviewImAssistantQuestion = () => {
  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) {
    throw new Error('invalid state')
  }
  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }
  const soraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  const { requestCreateImAssistantQuestions } = useRequestCreateImAssistantQuestion()

  const createImAssistantQuestion = useCallback(
    async (questionType: CreateImAssistantQuestionsRequest.QuestionType, targetInformation: string) => {
      if (soraClientId === undefined) {
        throw new Error('cannot use before entered')
      }
      const res = await requestCreateImAssistantQuestions({
        interviewGuid: pageCtx.interviewGuid,
        questionType,
        targetInformation,
      })

      const questions = res.getQuestionsList()
      const error = res.getError()

      return {
        questions,
        error,
      }
    },
    [pageCtx.interviewGuid, requestCreateImAssistantQuestions, soraClientId]
  )

  return {
    createImAssistantQuestion,
  }
}

export const BizInterviewImAssistantQuestionContainer = createContainer(useInterviewImAssistantQuestion)
