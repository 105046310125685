import { useEffect, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useContainer } from '@blue-agency/front-state-management/dist/libs/unstated-next'
import differenceBy from 'lodash-es/differenceBy'
import { useSelector } from 'react-redux'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { ChatContainer } from '@/shared/services/interviewService/containers/ChatContainer'
import { SharedState } from '@/shared/services/interviewService/redux'

const useChatUnread = () => {
  const [hasUnread, setHasUnread] = useState(false)
  const { isChatAreaOpen } = useContainer(ChatContainer)

  const chatMessages = useSelector((state: SharedState) => state.shared.chatMessages)
  const prevChatMessages = usePrevious(chatMessages)

  useEffect(() => {
    if (isChatAreaOpen) {
      setHasUnread(false)
      return
    }

    const setHasUnreadWhenNewMessageAdded = () => {
      const added = (() => {
        if (!prevChatMessages) {
          return chatMessages
        }
        return differenceBy(chatMessages, prevChatMessages, (m) => m.messageGuid)
      })()

      if (added.length > 0) {
        setHasUnread(true)
      }
    }
    setHasUnreadWhenNewMessageAdded()
  }, [chatMessages, isChatAreaOpen, prevChatMessages])

  return {
    hasUnread,
  }
}

export const ChatUnreadContainer = createContainer(useChatUnread)
