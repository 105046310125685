import { useEffect, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import differenceInSeconds from 'date-fns/differenceInSeconds'
import { useSelector } from 'react-redux'
import { SharedState } from '../redux'

export type Seconds = number

// タイマーの表示を更新する間隔 (ms)
// タイマーは秒単位の表示なので、500ms間隔での更新は無駄ではあるものの、setInterval による実際の実行間隔は
// 他の処理の負荷に依存するため、短めの間隔に設定することで可能な限り時間通りにタイマーが更新されるようにする
const INTERVAL = 500

type Response = {
  time: Seconds
}

function useInterviewTimer(): Response {
  const [time, setTime] = useState(0)

  const interviewStartTime = useSelector((state: SharedState) => state.timer.interviewStartTime)

  useEffect(() => {
    if (interviewStartTime === undefined) {
      return
    }

    const timer = setInterval(() => {
      // setInterval(callback, ms) としたとき、ms ごとに callback が呼び出される保証はまったくない
      // 特に、背景ぼかしなどのヘビーな処理を行っているときは、callback の実行間隔がどんどん広がっていく
      // c.f. https://stackoverflow.com/questions/29971898/how-to-create-an-accurate-timer-in-javascript
      // 単純に現在の面接時間を1秒ずつインクリメントしていくのではなく、常に現在時刻と開始時刻の差分を表示することで、
      // なるべく正確なタイマーを実現する
      const diff = differenceInSeconds(new Date(), new Date(interviewStartTime))
      setTime(diff)
    }, INTERVAL)

    return () => {
      clearInterval(timer)
    }
  }, [interviewStartTime])

  return { time }
}

export const InterviewTimerContainer = createContainer(useInterviewTimer)
