import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useBeforeEntered as useBeforeEnteredBase } from '@/shared/services/interviewService/hooksForApp/useBeforeEntered'
import { bizSlice } from '../redux/bizSlice'
import { ManualModalContainer } from './containers/ManualModalContainer'

export function useBeforeEntered() {
  const dispatch = useDispatch()

  const {
    videoElRef,
    isChangeCameraEnabled,
    camera,
    isChangeMicEnabled,
    mic,
    isChangeSpeakerEnabled,
    speaker,
    openBackgroundSettingModal,
    isOpenBackgroundSettingModal,
    closeBackgroundSettingModal,
    backgroundEffect,
    changeBackgroundEffect,
    backgroundUserImage,
    saveBackgroundUserImage,
    isChangeNoiseSuppressionEnabled,
    enabledNoiseSuppression,
    setEnabledNoiseSuppression,
    isChangeLightAdjustmentEnabled,
    enabledLightAdjustment,
    setEnabledLightAdjustment,
    lightAdjustmentLevel,
    setLightAdjustmentLevel,
  } = useBeforeEnteredBase()

  const onClickEnterButton = useCallback(() => {
    dispatch(bizSlice.actions.enterRoomButtonClicked())
  }, [dispatch])

  const { isManualModalShown, handleManualModalOpen } = ManualModalContainer.useContainer()

  return {
    videoElRef,
    isChangeCameraEnabled,
    onClickEnterButton,
    isManualModalShown,
    handleManualModalOpen,
    camera,
    isChangeMicEnabled,
    mic,
    isChangeSpeakerEnabled,
    speaker,
    openBackgroundSettingModal,
    isOpenBackgroundSettingModal,
    closeBackgroundSettingModal,
    backgroundEffect,
    changeBackgroundEffect,
    backgroundUserImage,
    saveBackgroundUserImage,
    isChangeNoiseSuppressionEnabled,
    enabledNoiseSuppression,
    setEnabledNoiseSuppression,
    isChangeLightAdjustmentEnabled,
    enabledLightAdjustment,
    setEnabledLightAdjustment,
    lightAdjustmentLevel,
    setLightAdjustmentLevel,
  }
}
