// Copyright 2020-2021 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { useCallback, useEffect, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { alertToast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useDispatch, useSelector } from 'react-redux'
import { assertIsDefined } from '@/assertions'
import { ContentShareObserver, ImMeetingSessionStatusCode, ConnectionPublisher } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { buildScreenSharingPublisher } from '@/lib/meetingcomponent/buildSoraConnection'
import { useChanged } from '@/shared/hooks/useChanged'
import { useModal } from '@/shared/hooks/useModal'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { sharedSlice, SharedState } from '../../../shared/services/interviewService/redux'

const useContentShare = () => {
  const dispatch = useDispatch()
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { screenSharingPublisherProps } = InterviewContainer.useContainer()
  const { interviewGuid, appType } = InterviewContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const isRunningOwnScreenShare = useSelector((state: SharedState) => state.shared.isRunningOwnScreenShare)

  const isScreenSharingPublisherPropsChanged = useChanged(screenSharingPublisherProps)

  // ScreenShareUiContainerに置くべき. とりあえずの便宜上ここに書いている
  const startScreenShareModal = useModal()
  const finishScreenShareModal = useModal()
  const screenShareLimitModal = useModal()

  const screenSharingPublisherRef = useRef<ConnectionPublisher | null>(null)

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    if (isScreenSharingPublisherPropsChanged && isRunningOwnScreenShare) {
      audioVideo.stopContentShare()
      const publisher = buildScreenSharingPublisher(screenSharingPublisherProps)
      meetingManager.setContentSharePublisher(publisher)
      screenSharingPublisherRef.current = publisher
      alert('画面共有の接続が切れました。再度お試しください。')
    }
  }, [
    audioVideo,
    isScreenSharingPublisherPropsChanged,
    isRunningOwnScreenShare,
    meetingManager,
    screenSharingPublisherProps,
  ])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const contentShareObserver: ContentShareObserver = {
      contentShareDidStart: () => {
        assertIsDefined(screenSharingPublisherRef.current)

        dispatch(sharedSlice.actions.ownScreenShareStarted())
        comlinkPush({ action: 'started_screen_share', metadata: { interviewGuid, appType } })
        comlinkPush({
          action: 'succeeded_to_connect_screen_share_stream_to_sora_in_interview',
          metadata: {
            interviewGuid,
            channelId: screenSharingPublisherRef.current.channelId,
            connectionId: screenSharingPublisherRef.current.connectionId ?? '',
            soraHost: screenSharingPublisherRef.current.connectedSignalingUrl,
          },
        })
      },
      contentShareDidStop: (sessionStatus) => {
        if (!sessionStatus.isFailure()) {
          dispatch(sharedSlice.actions.ownScreenShareFinished())
          comlinkPush({ action: 'finished_screen_share', metadata: { interviewGuid, appType } })
          return
        }

        comlinkPush({
          action: 'failed_to_start_screen_share',
          metadata: { interviewGuid, appType, error: sessionStatus.toString() },
        })
        switch (sessionStatus.statusCode()) {
          case ImMeetingSessionStatusCode.SoraParticipantsLimitExceeded:
            screenShareLimitModal.open()
            break
          default:
            Sentry.captureException(sessionStatus.toString())
            alert('画面共有に失敗しました。もう一度お試しください。')
            break
        }
      },
    }

    audioVideo.addContentShareObserver(contentShareObserver)

    return () => {
      audioVideo.removeContentShareObserver(contentShareObserver)
    }
  }, [audioVideo, dispatch, screenShareLimitModal, interviewGuid, appType])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const cb = (e: PromiseRejectionEvent) => {
      // Safari は、OS での画面共有設定はなく、ユーザーが都度 Safari 上でブロックするものなので今回は表示しない
      // ref: https://github.com/blue-agency/skywalker-front/pull/491
      const blockedByOS =
        // Chrome でのメッセージ
        e.reason.message === 'Permission denied by system' ||
        // Firefox でのメッセージ
        e.reason.name === 'NotFoundError'

      if (blockedByOS) {
        return alertToast('画面を共有できません。\nPCの設定画面からブラウザに画面共有権限を付与してください。')
      }

      // https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia
      // ブラウザ上の、共有する画面 選択でキャンセルしたときにこのエラーが発生する
      if (e.reason.name === 'NotAllowedError') {
        return
      }
      // https://developer.mozilla.org/ja/docs/Web/API/MediaDevices/getUserMedia
      if (e.reason.name === 'NotReadableError') {
        alert('ご利用のパソコンで画面共有を行う権限がない可能性があります。')
        return null
      }
    }

    window.addEventListener('unhandledrejection', cb)
    return () => window.removeEventListener('unhandledrejection', cb)
  }, [audioVideo, dispatch, isRunningOwnScreenShare])

  const startContentShare = useCallback<() => Promise<void>>(async () => {
    if (!audioVideo || isRunningOwnScreenShare) {
      return
    }

    await audioVideo.startContentShareFromScreenCapture()
  }, [audioVideo, isRunningOwnScreenShare])

  const stopContentShare = useCallback<() => void>(() => {
    if (!audioVideo) {
      return
    }

    audioVideo.stopContentShare()
  }, [audioVideo])

  return {
    startContentShare,
    stopContentShare,
    startScreenShareModal,
    finishScreenShareModal,
    screenShareLimitModal,
    screenSharingPublisherRef,
  }
}

export const ContentShareContainer = createContainer(useContentShare)
