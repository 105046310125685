import React from 'react'
import { Icon, theme, Txt, TertiaryButton } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { BizInterviewPinningContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewPinningContainer'

export const InterviewPinningModal: React.VFC = () => {
  const {
    createInterviewPinningTypeGood,
    createInterviewPinningTypeBad,
    createInterviewPinningTypeQuestion,
    isCreatableInterviewPinning,
  } = BizInterviewPinningContainer.useContainer()
  return (
    <Wrapper>
      <Header className="header">
        <HeaderTitle>
          <InterviewPinningIcon size="m" />
          <Txt color={theme.color.white[1]} bold>
            ピン留め機能
          </Txt>
        </HeaderTitle>
      </Header>
      <Body>
        <Txt>
          応募者の気になる発言に押してみましょう！
          <br />
          録画でふり返りがしやすくなります。
        </Txt>
        <Txt color={theme.color.gray[1]}>※応募者には表示されません。</Txt>
        <ButtonGroup padding="8px">
          <GoodButton
            onClick={createInterviewPinningTypeGood}
            disabled={!isCreatableInterviewPinning}
            icon={<Icon name="good-face" size="l" />}
            text="グッド"
            comlinkPushParams={{ action: 'click_good_pined' }}
          />
          <BadButton
            onClick={createInterviewPinningTypeBad}
            disabled={!isCreatableInterviewPinning}
            icon={<Icon name="bad-face" size="l" />}
            text="イマイチ"
            comlinkPushParams={{ action: 'click_bad_pined' }}
          />
          <QuestionButton
            onClick={createInterviewPinningTypeQuestion}
            disabled={!isCreatableInterviewPinning}
            icon={<Icon name="question-face" size="l" />}
            text="要確認"
            comlinkPushParams={{ action: 'click_question_pined' }}
          />
        </ButtonGroup>
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 320px;
  overflow: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  background-color: ${theme.color.navy[1]};
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
`

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;

  *:not(:last-child) {
    margin-right: 6px;
  }
`

const Body = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  opacity: 0.95;
  padding: 8px;
  background-color: ${theme.color.white[1]};
`

const InterviewPinningIcon = styled(Icon).attrs({ name: 'map-pin-outline' })`
  color: ${theme.color.white[1]};
`

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const GoodButton = styled(TertiaryButton).attrs({ direction: 'column' })`
  height: 53px;
  padding: 1px;
  font-size: 12px;
  border-color: ${theme.color.blue[1]};
  color: ${theme.color.blue[1]};
  background-color: ${theme.color.gray[5]};
  width: 60px;
`
// eslint-disable-next-line deprecation/deprecation
const BadButton = styled(TertiaryButton).attrs({ direction: 'column' })`
  height: 53px;
  padding: 1px;
  font-size: 12px;
  border-color: ${theme.color.red[2]};
  color: ${theme.color.red[2]};
  background-color: ${theme.color.gray[5]};
  width: 60px;
`
// eslint-disable-next-line deprecation/deprecation
const QuestionButton = styled(TertiaryButton).attrs({ direction: 'column' })`
  height: 53px;
  padding: 1px;
  font-size: 12px;
  border-color: ${theme.color.orange[1]};
  color: ${theme.color.orange[1]};
  background-color: ${theme.color.gray[5]};
  width: 60px;
`

const ButtonGroup = styled.div<{ padding: string }>`
  display: flex;
  justify-content: start;
  column-gap: 10px;
  ${({ padding }) =>
    css`
      padding: ${padding};
    `}
`
