import React, { useContext, useState, useEffect, useMemo } from 'react'
import { Responsive, theme, useIsIPad } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled, { css, ThemeContext } from 'styled-components'
import { BizInterviewGuideContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { BizInterviewImAssistantQuestionContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewImAssistantQuestion'
import { BizInterviewPinningContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewPinningContainer'
import { MainVideo } from '@/lib/meetingcomponent/MainVideo'
import { VideosAreaContainer } from '@/lib/meetingcomponent/VideosAreaContainer'
import { ChatList } from '@/shared/services/interviewService/components/ChatList/ChatList'
import { MinutesViewLayoutConsumer } from '@/shared/services/interviewService/components/Layout/MinutesViewLayoutConsumer'
import { MobileControls } from '@/shared/services/interviewService/components/MobileControls'
import { MobileHeaderControls } from '@/shared/services/interviewService/components/MobileHeaderControls'
import { PcControls } from '@/shared/services/interviewService/components/PcControls'
import { SpPortraitControls } from '@/shared/services/interviewService/components/SpPortraitControls'
import { InterviewAutoQuestionContainer } from '@/shared/services/interviewService/containers/InterviewAutoQuestionContainer'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'
import { InterviewPinningContainer } from '@/shared/services/interviewService/containers/InterviewPinningContainer'
import { MinutesViewContainer } from '@/shared/services/interviewService/containers/MinutesViewContainer'
import { VideosLayoutContainer } from '@/shared/services/interviewService/containers/VideosLayoutContainer'
import { isSafari } from '@/shared/services/userAgentService'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'
import { ChatContainer } from '../../containers/ChatContainer'
import { ChatUnreadContainer } from '../../containers/ChatUnreadContainer'
import { AppType, InterviewContainer } from '../../containers/InterviewContainer'
import { ScreenShareUiContainer } from '../../containers/ScreenShareUiContainer'
import { SharedState, visibleInterviewersCountSelector } from '../../redux'
import type { ViewMode } from '../../types'
import { AutoQuestionGptModal } from '../AutoQuestionGpt/AutoQuestionGptModal'
import { InterviewGuideNotification } from '../InterviewGuide/InterviewGuideNotification'
import { PcInterviewGuideModal } from '../InterviewGuide/PcInterviewGuideModal'
import { SpInterviewGuide } from '../InterviewGuide/SpInterviewGuide'
import { InterviewPinningModal } from '../InterviewPinning/InterviewPinningModal'
import { InterviewPinningToastContainer } from '../InterviewPinning/interviewPinningToast'
import { WaitingEntryRequestMessage as _WaitingEntryRequestMessage } from '../WaitingEntryRequestMessage'
import { TileViewVideos } from './TileViewVideos'
import { Videos } from './Videos'
import { useAreaSize } from './useAreaSize'
import type { SizeInString } from './useAreaSize'
import { useDebouncedResizeObserver } from './useDebouncedResizeObserver'

type Props = {
  isInterviewTranscriptionEnabled: boolean
}

export const Layout: React.VFC<Props> = React.memo(({ isInterviewTranscriptionEnabled }) => {
  const { appType, interviewGuid } = InterviewContainer.useContainer()
  const { isVideosAreaOpen } = VideosAreaContainer.useContainer()
  const { isChatAreaOpen, isChatFocused } = ChatContainer.useContainer()
  const { isInterviewGuideAreaOpen, hasInterviewGuide } = InterviewGuideContainer.useContainer()
  const { isInterviewAutoQuestionAreaOpen } = InterviewAutoQuestionContainer.useContainer()
  const { isInterviewPinningAreaOpen } = InterviewPinningContainer.useContainer()
  const { renderStartScreenShareModal, renderFinishScreenShareModal, renderScreenShareLimitModal } =
    ScreenShareUiContainer.useContainer()
  const { isMinutesView } = MinutesViewContainer.useContainer()
  const { isIPad } = useIsIPad()
  const { responsive } = useContext(ThemeContext)

  const viewMode = useSelector((state: SharedState) => state.shared.viewMode)

  const hasInterviewers = useSelector(visibleInterviewersCountSelector) > 0

  const isMinutesViewLayout = useMemo(() => {
    return appType === 'biz' && responsive.pc && isMinutesView
  }, [appType, isMinutesView, responsive.pc])

  const props = {
    appType,
    interviewGuid,
    isVideosAreaOpen,
    isChatAreaOpen,
    isChatFocused,
    isInterviewGuideAreaOpen,
    hasInterviewGuide,
    isInterviewAutoQuestionAreaOpen,
    isMinutesViewLayout,
    renderStartScreenShareModal,
    renderFinishScreenShareModal,
    renderScreenShareLimitModal,
    responsive,
    isIPad,
    hasInterviewers,
    viewMode,
    isInterviewPinningAreaOpen,
    isInterviewTranscriptionEnabled,
  }

  return isMinutesViewLayout ? <MinutesViewLayoutConsumer {...props} /> : <LayoutConsumer {...props} />
})

type ConsumerProps = {
  appType: AppType
  interviewGuid: string
  isVideosAreaOpen: boolean
  isChatAreaOpen: boolean
  isChatFocused: boolean
  isInterviewGuideAreaOpen: boolean
  hasInterviewGuide: boolean
  isInterviewAutoQuestionAreaOpen: boolean
  isMinutesViewLayout: boolean
  renderStartScreenShareModal: () => JSX.Element
  renderFinishScreenShareModal: () => JSX.Element
  renderScreenShareLimitModal: () => JSX.Element
  responsive: Responsive
  isIPad: boolean
  hasInterviewers: boolean
  viewMode: ViewMode
  isInterviewPinningAreaOpen: boolean
}

const LayoutConsumer: React.VFC<ConsumerProps> = React.memo((props) => {
  const isNotStarted = useSelector((state: SharedState) => state.shared.interviewState === 'NotStarted')
  const userSignalingStatus = useSelector((state: SharedState) => state.shared.userSignalingStatus)

  // NOTE: パフォーマンスの観点から、各レイアウトの View を1コンポーネントで実現している
  // このコンポーネントを変更するときは、スマホの画面回転でもたつかないかなどチェックすることをおすすめします
  // https://github.com/blue-agency/skywalker-front/pull/410

  // MEMO: Safariのバグの回避策としてVideosAreaを一瞬消した後に、再表示している
  // - チャットの表示・非表示切り替え時
  // - 面接ガイドの表示・非表示切り替え時
  // - チャットの入力欄にフォーカスがあたったとき（モバイルだと仮想キーボードが出現するタイミング）
  // に再表示を実行する
  // 参考:
  // https://github.com/blue-agency/yashiori-front/pull/951
  // https://stadium-co-jp.slack.com/archives/C02A51MH1PD/p1630996703109200?thread_ts=1630996549.108700&cid=C02A51MH1PD
  const [hideVideosArea, setHideVideosArea] = useState(false)
  useEffect(() => {
    if (props.isVideosAreaOpen && isSafari()) {
      setHideVideosArea(true)
      requestAnimationFrame(() => {
        setHideVideosArea(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.isChatAreaOpen,
    props.isInterviewGuideAreaOpen,
    props.isInterviewAutoQuestionAreaOpen,
    props.isChatFocused,
    props.isMinutesViewLayout,
  ])

  const resizeObserver = useDebouncedResizeObserver(50)
  const DUMMY_ENTIRE_AREA_SIZE = {
    width: 1280,
    height: 720,
  }
  const areaSize = useAreaSize({
    entireAreaSize: {
      width: resizeObserver.width ?? DUMMY_ENTIRE_AREA_SIZE.width,
      height: resizeObserver.height ?? DUMMY_ENTIRE_AREA_SIZE.height,
    },
    isTileView: props.responsive.pc && props.viewMode.type === 'tile_view',
    isChatAreaOpen: props.isChatAreaOpen,
    isInterviewGuideAreaOpen: props.isInterviewGuideAreaOpen,
    isInterviewAutoQuestionAreaOpen: props.isInterviewAutoQuestionAreaOpen,
    isVideosAreaOpen: props.isVideosAreaOpen,
  })

  const isTileView = areaSize.type === 'tile_view'

  return (
    <Wrapper ref={resizeObserver.ref}>
      {!props.responsive.pc && (
        <>
          <MobileHeaderControls />
          <MobileHeaderBorder />
        </>
      )}
      <ContentWrapper>
        {props.appType === 'biz' && isNotStarted && userSignalingStatus === 'Completed' && (
          <WaitingEntryRequestMessage />
        )}

        {/**
         * 'tile_view' or 'interview_view' によって DOM を完全に切り分けると、
         * Safari でビュー切替時に一部の映像が表示されなくなる問題が発生することがある
         * https://github.com/blue-agency/skywalker-front/pull/878#issuecomment-979857128
         * この問題に対応するため、今のモードによらず DOM 上には両方のビューを置いておき、
         * 実際の表示の切り替えは display: none; の出し分けによって行うようにしている
         */}
        <TileViewArea
          hide={!isTileView}
          size={{
            width: areaSize.tiles.areaSize.width,
            height: areaSize.tiles.areaSize.height,
          }}
        >
          <TileViewVideos tileSizeCalculator={areaSize.tiles.tileSizeCalculator} />
        </TileViewArea>
        <MainArea hide={isTileView} size={areaSize.main}>
          <MainVideo />
        </MainArea>

        <VideosArea hide={hideVideosArea || isTileView} size={areaSize.videos}>
          <VideosLayoutContainer.Provider>
            <Videos />
          </VideosLayoutContainer.Provider>
        </VideosArea>

        <MenuContentArea size={areaSize.menuContent}>
          {props.appType === 'biz' && (
            <>
              <BizInterviewGuideContainer.Provider>
                {props.isInterviewGuideAreaOpen && !props.responsive.pc && <SpInterviewGuide />}
                {props.isInterviewGuideAreaOpen && props.isChatAreaOpen && !props.responsive.pc && (
                  <LineBetweenGuideAndChat />
                )}
              </BizInterviewGuideContainer.Provider>
            </>
          )}
          {props.isChatAreaOpen && <ChatList />}
        </MenuContentArea>

        {props.renderStartScreenShareModal()}
        {props.renderFinishScreenShareModal()}
        {props.renderScreenShareLimitModal()}

        <ChatUnreadContainer.Provider>
          {props.responsive.pc && (
            <PcControlsArea size={areaSize.control}>
              <PcControls />
            </PcControlsArea>
          )}

          {props.responsive.spPortrait && !props.isIPad && (
            <SpPortraitControlsArea size={areaSize.control}>
              <SpPortraitControls />
            </SpPortraitControlsArea>
          )}

          {(props.responsive.spLandscape || (props.responsive.spPortrait && props.isIPad)) && (
            <MobileControlsArea size={areaSize.control}>
              <MobileControls />
            </MobileControlsArea>
          )}
        </ChatUnreadContainer.Provider>

        {/* 面接ガイドのドラッグ領域を規定するdiv */}
        {props.appType === 'biz' && props.responsive.pc && (
          <>
            <BizInterviewGuideContainer.Provider>
              <GuideNotificationWrapper>
                <InterviewGuideNotification />
              </GuideNotificationWrapper>
              {props.isInterviewGuideAreaOpen && (
                <>
                  <DraggableInterviewGuideBoundsArea
                    size={areaSize.draggableInterviewGuide}
                    className="draggable-interview-guide-area"
                  />
                  <PcInterviewGuideModal boundsClass=".draggable-interview-guide-area" />
                </>
              )}
            </BizInterviewGuideContainer.Provider>
          </>
        )}
        {props.appType === 'biz' && props.responsive.pc && props.isInterviewAutoQuestionAreaOpen && (
          <BizInterviewImAssistantQuestionContainer.Provider>
            <DraggableAutoQuestionArea
              size={areaSize.draggableInterviewGuide}
              className="draggable-auto-question-area"
            />
            <AutoQuestionGptModal
              boundsClass=".draggable-auto-question-area"
              hasGuid={props.isInterviewGuideAreaOpen}
            />
          </BizInterviewImAssistantQuestionContainer.Provider>
        )}
        {props.appType === 'biz' && props.responsive.pc && (
          <InterviewPinningWrapper>
            <BizInterviewPinningContainer.Provider>
              {props.isInterviewPinningAreaOpen && (
                <>
                  <InterviewPinningToastContainer />
                  <InterviewPinningModal />
                </>
              )}
            </BizInterviewPinningContainer.Provider>
          </InterviewPinningWrapper>
        )}
      </ContentWrapper>
    </Wrapper>
  )
})

const MOBILE_HEADER_HEIGHT = '36px'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  ${({ theme }) =>
    theme.responsive.spPortrait &&
    css`
      flex-direction: column;
    `}
  ${({ theme }) =>
    theme.responsive.pc
      ? css`
          height: 100%;
        `
      : css`
          height: calc(100% - ${MOBILE_HEADER_HEIGHT});
        `}
`

const MainArea = styled.div<{
  size: SizeInString
  hide: boolean
}>`
  position: relative;
  background: ${theme.color.black[1]};

  ${({ hide }) => {
    if (hide) {
      return css`
        display: none;
      `
    }
  }}

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const VideosArea = styled.div<{
  size: SizeInString
  hide: boolean
}>`
  background: ${theme.color.darkGray[1]};
  ${overflowYScrollStyle};

  ${({ hide }) => {
    if (hide) {
      return css`
        display: none;
      `
    }
  }}

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const MenuContentArea = styled.div<{ size: SizeInString }>`
  ${({ theme }) => {
    if (theme.responsive.pc) {
      return css`
        display: flex;
      `
    } else {
      return css`
        ${Wrapper} && {
          overflow-y: scroll;
        }
      `
    }
  }}

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const WaitingEntryRequestMessage = styled(_WaitingEntryRequestMessage)`
  position: absolute;
  top: 92px;
  left: 0;
  right: 0;
  // Videoの名前より大きくする
  z-index: 3;

  margin: 0 auto;
  width: 360px;
`

const PcControlsArea = styled.div<{ size: SizeInString }>`
  bottom: 0;
  position: absolute;
  overflow-x: hidden;
  overflow-y: overlay;

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const MobileControlsArea = styled.div<{ size: SizeInString }>`
  overflow-x: hidden;
  overflow-y: overlay;
  border-left: 1px solid ${theme.color.gray[3]};

  ${({ theme: t }) => {
    if (t.responsive.spPortrait) {
      return css`
        position: absolute;
        bottom: 0;
        z-index: 1;
      `
    }
    if (t.responsive.spLandscape) {
      return css`
        background-color: ${theme.color.gray[5]};
      `
    }
  }}

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const SpPortraitControlsArea = styled.div<{ size: SizeInString }>`
  overflow-x: hidden;
  overflow-y: overlay;

  ${({ theme }) => {
    if (theme.responsive.spPortrait) {
      return css`
        position: absolute;
        bottom: 0;
        z-index: 1;
      `
    }
  }}

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const LineBetweenGuideAndChat = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${theme.color.gray[4]};
`

const MobileHeaderBorder = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.color.gray[4]};
`

const TileViewArea = styled.div<{
  size: SizeInString
  hide: boolean
}>`
  position: relative;
  background: ${theme.color.darkGray[1]};

  ${({ hide }) => {
    if (hide) {
      return css`
        display: none;
      `
    }
  }}

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const DraggableInterviewGuideBoundsArea = styled.div<{
  size: SizeInString
}>`
  position: absolute;
  background-color: transparent;
  pointer-events: none;

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const DraggableAutoQuestionArea = styled.div<{
  size: SizeInString
}>`
  position: absolute;
  background-color: transparent;
  pointer-events: none;

  ${({ size }) => {
    return css`
      ${Wrapper} && {
        height: ${size.height};
        width: ${size.width};
      }
    `
  }}
`

const GuideNotificationWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
`

const InterviewPinningWrapper = styled.div`
  position: absolute;
  bottom: 68px;
  right: 260px;
`
