import React from 'react'
import { Icon, theme, Txt, A } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import styled from 'styled-components'
import { ChatMessage, ChatRole } from '../../types'

type Props = {
  message: ChatMessage
}

const regExp = /(https?:\/\/\S+)/g

export const MinutesViewChatMessageComponent: React.VFC<Props> = React.memo(({ message }) => {
  return (
    <Wrapper message={message}>
      <NameWrapper>
        {message.participant.role === 'interviewer' && (
          <IconWrapper>
            <Icon width="11px" height="12px" name="company" />
          </IconWrapper>
        )}
        <Name>{message.participant.name}</Name>
      </NameWrapper>
      <Body>
        <Span>
          {reactStringReplace(message.text, regExp, (match, i) => (
            <A
              key={i}
              href={match}
              target="_blank"
              rel="noopener noreferrer"
              comlinkPushParams={{ action: 'click_url_in_minute_view_chat_message' }}
            >
              {match}
            </A>
          ))}
        </Span>
      </Body>
    </Wrapper>
  )
})

const backgroundColorMap: Record<ChatRole, string> = {
  interviewer: theme.color.white[1],
  interviewee: theme.color.gray[5],
}

type MessageProps = {
  message: ChatMessage
}

const Wrapper = styled.div<MessageProps>`
  display: flex;
  flex-direction: column;
  padding: 8px 4px 8px 4px;
  gap: 4px;

  border-bottom: 1px solid ${theme.color.gray[4]};
  background-color: ${({ message }) => backgroundColorMap[message.participant.role]};
`

const NameWrapper = styled.div`
  display: flex;
  align-content: center;
  gap: 4px;
`

const IconWrapper = styled.div``

const Name = styled(Txt).attrs({ color: theme.color.gray[1], size: 'xs' })`
  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://rilaks.jp/blog/text-inflation/
  -webkit-text-size-adjust: 100%;
`
const Body = styled(Txt).attrs({ color: theme.color.navy[1], size: 's' })`
  margin-left: 12px;
  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://rilaks.jp/blog/text-inflation/
  -webkit-text-size-adjust: 100%;
`
const Span = styled.span`
  word-break: break-all;
`
