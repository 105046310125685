import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { SharedState } from '..'

export const isRunningOtherScreenShareSelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.isRunningOwnScreenShare,
  (state: SharedState) => state.shared.metadataMap,
  (isRunningOwnScreenShare, metadataMap) => {
    if (isRunningOwnScreenShare) {
      return false
    }

    return Object.values(metadataMap).some((metadata) => {
      return metadata.interview_role === 'recruiter_screen' || metadata.interview_role === 'applicant_screen'
    })
  }
)
