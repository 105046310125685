import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { DeleteCameraTestSessionTokenRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestDeleteCameraTestSessionToken() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestDeleteCameraTestSessionToken = useCallback(
    async (sessionToken: string) => {
      const metadata = await buildMetadata()

      const req = new DeleteCameraTestSessionTokenRequest()
      req.setSessionToken(sessionToken)

      return callBff('deleteCameraTestSessionToken', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestDeleteCameraTestSessionToken }
}
