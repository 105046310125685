import React from 'react'
import { ParticipantAudioVolumesContainer } from '@/shared/services/interviewService/containers/ParticipantAudioVolumesContainer'
import { AudioWaveCircle, AudioWaveCircleProps } from './AudioWaveCircle'

export interface LocalAudioWaveCircleProps extends Omit<AudioWaveCircleProps, 'volume'> {
  className?: string
}

export const LocalAudioWaveCircle: React.VFC<LocalAudioWaveCircleProps> = (props) => {
  const { localAudioVolume: vol } = ParticipantAudioVolumesContainer.useContainer()
  return <AudioWaveCircle {...props} volume={vol} />
}
