import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { bizSlice } from '../../../redux/bizSlice'
import { InRoomContainer } from '../../containers/InRoomContainer'

export function useApproveEntryMessageHandler() {
  const dispatch = useDispatch()

  const { ws } = InRoomContainer.useContainer()
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    const handler = (message: WebSocketMessageMap['approve entry']) => {
      dispatch(bizSlice.actions.approveEntryMessageReceived(message))
    }

    addMessageListener<'approve entry'>('approve entry', handler)

    return () => {
      removeMessageListener<'approve entry'>('approve entry', handler)
    }
  }, [addMessageListener, dispatch, removeMessageListener])
}
