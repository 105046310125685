import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import {
  Modal as _Modal,
  WeakColorSeparator,
  Dropdown,
  Txt,
  Icon,
  NewTabLink,
  theme,
  Toggle,
  useIsIPad,
} from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { isSupportedLightAdjustment, isSupportedNoiseSuppression } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { AudioWave } from '@/lib/meetingcomponent/AudioWave'
import { AudioInputContainer, AudioOutputContainer, VideoInputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { VideoPreview } from '@/lib/meetingcomponent/VideoPreview'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { useLightAdjustment } from '@/shared/hooks/useLightAdjustment'
import { useNoiseSuppression } from '@/shared/hooks/useNoiseSuppression'
import { useHandleChangeDevice } from '@/shared/services/interviewService/hooksForUi/useMenuDropdown/useChangeDeviceModal/useHandleChangeDevice'
import { getUserAgent } from '@/shared/services/userAgentService'
import { InterviewContainer } from '../../../containers/InterviewContainer'
import { sharedSlice, SharedState } from '../../../redux'
import { saveSelfViewVisibility } from '../../../selfViewVisibility'
import { storeDeviceIdInLS } from '../../../storeDeviceIdInLS'
import { useDeviceOptions } from './useDeviceOptions'
import { usePlayDemoSound } from './usePlayDemoSound'

type Props = {
  active: boolean
  onClose: () => void
}

export const ChangeDeviceModal: React.VFC<Props> = (props) => {
  const dispatch = useDispatch()
  const { appType } = InterviewContainer.useContainer()
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const { videoInputs, selectedVideoInputDevice, setSelectedVideoInputDevice } = VideoInputContainer.useContainer()
  const { audioInputs, selectedAudioInputDevice } = AudioInputContainer.useContainer()
  const { audioOutputs, selectedAudioOutputDevice } = AudioOutputContainer.useContainer()
  const { enabledNoiseSuppression, setEnabledNoiseSuppression } = useNoiseSuppression()
  const { enabledLightAdjustment, setEnabledLightAdjustment } = useLightAdjustment()
  const cameraOptions = useDeviceOptions(videoInputs)
  const micOptions = useDeviceOptions(audioInputs)
  const speakerOptions = useDeviceOptions(audioOutputs)

  const { playSound, isPlaying } = usePlayDemoSound(selectedAudioOutputDevice ?? undefined)
  const selfViewVisibility = useSelector((state: SharedState) => state.shared.selfViewVisibility)
  const isOwnMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)

  const videoRef = useRef<HTMLVideoElement>(null)

  const helpLink = appType === 'biz' ? BIZ_EXTERNAL_URLS.help.changeDevice : MY_EXTERNAL_URLS.help.changeDevice

  const selectVideoInputDevice = useCallback(
    async (deviceId: string) => {
      if (isOwnCameraMuted) {
        setSelectedVideoInputDevice(deviceId)
        return
      }
      await meetingManager?.selectVideoInputDevice(deviceId)
      storeDeviceIdInLS('videoinput', videoInputs, deviceId)
    },
    [meetingManager, videoInputs, isOwnCameraMuted, setSelectedVideoInputDevice]
  )
  const selectAudioInputDevice = useCallback(
    async (deviceId: string) => {
      await meetingManager?.selectAudioInputDevice(deviceId)
      storeDeviceIdInLS('audioinput', audioInputs, deviceId)
    },
    [meetingManager, audioInputs]
  )
  const selectAudioOutputDevice = useCallback(
    async (deviceId: string) => {
      await meetingManager?.selectAudioOutputDevice(deviceId)
      storeDeviceIdInLS('audiooutput', audioOutputs, deviceId)
    },
    [meetingManager, audioOutputs]
  )

  const handleChangeCamera = useHandleChangeDevice(selectVideoInputDevice)
  const handleChangeSpeaker = useHandleChangeDevice(selectAudioOutputDevice)
  const handleChangeMic = useHandleChangeDevice(selectAudioInputDevice)

  useEffect(() => {
    if (!audioVideo || !videoRef.current) {
      return
    }

    audioVideo.startVideoPreviewForVideoInput(videoRef.current)

    const videoEl = videoRef.current
    return () => {
      if (videoEl) {
        audioVideo.stopVideoPreviewForVideoInput(videoEl)
      }
    }
  }, [audioVideo, selectedVideoInputDevice])

  const changeSelfViewVisibility = useCallback(
    (v: boolean) => {
      if (v) {
        saveSelfViewVisibility('visible')
        dispatch(sharedSlice.actions.showSelfView())
      } else {
        saveSelfViewVisibility('hidden')
        dispatch(sharedSlice.actions.hideSelfView())
      }
    },
    [dispatch]
  )

  const changeEnabledNoiseSuppression = useCallback(
    (newValue: boolean) => {
      meetingManager.changeNoiseSuppression(newValue)
      setEnabledNoiseSuppression(newValue)
    },
    [setEnabledNoiseSuppression, meetingManager]
  )

  const changeEnabledLightAdjustment = useCallback(
    (newValue: boolean) => {
      meetingManager.changeLightAdjustment(newValue ? { type: 'adjustment', strength: 0.3 } : { type: 'no-effect' })
      setEnabledLightAdjustment(newValue)
    },
    [setEnabledLightAdjustment, meetingManager]
  )

  const ua = useMemo(() => {
    return getUserAgent()
  }, [])

  const { isIPad } = useIsIPad()

  return (
    <Modal active={props.active} title={isIPad ? '映像設定' : '音声 / 映像設定'} onClose={props.onClose}>
      <>
        <Upper>
          <Row>
            <Label>カメラ</Label>
            <Dropdown
              size="m"
              value={selectedVideoInputDevice ?? undefined}
              options={cameraOptions}
              onChange={handleChangeCamera}
              disabled={isUnavailableVideoInput}
            />
            <Demo>{!isUnavailableVideoInput && <VideoPreview />}</Demo>
          </Row>
          <WeakColorSeparator />
          {!isIPad && (
            <>
              <Row>
                <Label>マイク</Label>
                <Dropdown
                  size="m"
                  value={selectedAudioInputDevice ?? undefined}
                  options={micOptions}
                  onChange={handleChangeMic}
                />
                <Demo>
                  {isOwnMicMuted ? (
                    <Txt>ミュート中</Txt>
                  ) : (
                    <WaveBlock>
                      <AudioWave width="100" height="56" />
                    </WaveBlock>
                  )}
                </Demo>
              </Row>
              <WeakColorSeparator />
            </>
          )}
          {speakerOptions.length > 0 && (
            <>
              <Row>
                <Label>スピーカー</Label>
                <Dropdown
                  size="m"
                  value={selectedAudioOutputDevice ?? undefined}
                  options={speakerOptions}
                  onChange={handleChangeSpeaker}
                  disabled={isPlaying}
                />
                <Demo>
                  <DemoSoundPlayButton
                    icon={<Icon name="filled-play" size="s" />}
                    comlinkPushParams={{
                      action: 'start_playing_demo_sound_during_interview',
                    }}
                    onClick={playSound}
                    disabled={isPlaying}
                  />
                  {isPlaying && <PlayingTxt>再生中</PlayingTxt>}
                </Demo>
              </Row>
              <WeakColorSeparator />
            </>
          )}
          {isSupportedNoiseSuppression(ua) && (
            <>
              <Row>
                <Label>
                  <Toggle checked={!!enabledNoiseSuppression} onChange={changeEnabledNoiseSuppression} />
                </Label>
                <Description>
                  <Txt size="m">雑音を抑制</Txt>
                </Description>
              </Row>
              <SupplementRow>
                <Label></Label>
                <Description>
                  <Txt size="s" color={theme.color.gray[1]}>
                    自分の周囲の雑音を抑制します
                  </Txt>
                </Description>
              </SupplementRow>
              <WeakColorSeparator />
            </>
          )}
          {isSupportedLightAdjustment() && !isIPad && (
            <>
              <Row>
                <Label>
                  <Toggle checked={!!enabledLightAdjustment} onChange={changeEnabledLightAdjustment} />
                </Label>
                <Description>
                  <Txt size="m">映像を明るく</Txt>
                </Description>
              </Row>
              <SupplementRow>
                <Label></Label>
                <Description>
                  <Txt size="s" color={theme.color.gray[1]}>
                    自分の映像を明るくします
                  </Txt>
                </Description>
              </SupplementRow>
              <WeakColorSeparator />
            </>
          )}
          <Row>
            <Label>
              <Toggle checked={selfViewVisibility === 'visible'} onChange={changeSelfViewVisibility} />
            </Label>
            <Description>
              <Txt size="m">自分の画面に自分の映像を表示</Txt>
            </Description>
          </Row>
          <SupplementRow>
            <Label></Label>
            <Description>
              <Txt size="s" color={theme.color.gray[1]}>
                ※他の参加者には表示されたままです
              </Txt>
            </Description>
          </SupplementRow>
          <WeakColorSeparator />
          <HelpText>
            動作しない場合は
            <NewTabLink href={helpLink} action="open_help_on_ChangeDeviceModal">
              ヘルプページ
            </NewTabLink>
            をご確認ください。
          </HelpText>
        </Upper>
        <Bottom>
          <ButtonGroup>
            <CloseButton comlinkPushParams={{ action: 'close_ChangeDeviceModal' }} onClick={props.onClose}>
              閉じる
            </CloseButton>
          </ButtonGroup>
        </Bottom>
      </>
    </Modal>
  )
}

const Modal = styled(_Modal).attrs({ size: 'm' })`
  max-width: 90%;
`

const Upper = styled.div`
  padding: 0 16px;
`

const Row = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;

  :not(:last-child) {
    margin-bottom: 20px;
  }

  :last-child {
    margin-bottom: 40px;
  }
`

const SupplementRow = styled(Row)`
  margin-top: -20px;
`

const Demo = styled.div`
  padding-left: 20px;
  display: flex;
`

const Label = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  width: 136px;
`

const DemoSoundPlayButton = styled(PrimaryButton).attrs({ widthSize: 'MAX', heightSize: 'S' })`
  width: 24px;
  border-radius: 50%;
`
const PlayingTxt = styled(Txt).attrs({ size: 'm' })`
  margin-left: 11px;
`

const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const CloseButton = styled(TertiaryButton).attrs({ widthSize: 'L2', heightSize: 'L' })``
const WaveBlock = styled.div`
  width: 100px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const HelpText = styled(Txt).attrs({ size: 'm' })`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Description = styled.div``
