import { useCallback, useRef } from 'react'
import styled from 'styled-components'

const IMAGE_SIZE_LIMIT = 2 * 10 ** 6 // 2MB

export const useSelectImage = (
  onLoadend: (dataUri: string) => void,
  onReadError?: (ev: ProgressEvent<FileReader>) => void,
  onFileSizeLimitExceeded?: (size: number) => void,
  onInvalidFileTypeError?: (type: string) => void
) => {
  const hiddenInputRef = useRef<HTMLInputElement | null>(null)

  const clickHiddenInput = useCallback(() => {
    hiddenInputRef.current?.click()
  }, [])

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const item = e.target.files?.item(0)
      if (!item) return
      switch (item.type) {
        case 'image/jpeg':
        case 'image/jpg':
        case 'image/png':
          break
        default:
          onInvalidFileTypeError?.(item.type)
          return
      }
      if (item.size > IMAGE_SIZE_LIMIT) {
        onFileSizeLimitExceeded?.(item.size)
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          onLoadend(reader.result)
        }
      }
      reader.onerror = onReadError ?? null
      reader.readAsDataURL(item)
      e.target.value = '' // 同じファイルを連続で選択した際でもイベントを発火させるために必要
    },
    [onLoadend, onReadError, onFileSizeLimitExceeded, onInvalidFileTypeError]
  )

  const renderHiddenInput = useCallback(() => {
    return <HiddenFileInput ref={hiddenInputRef} onChange={handleOnChange} />
  }, [handleOnChange])

  return {
    clickHiddenInput,
    renderHiddenInput,
  }
}

const HiddenFileInput = styled.input.attrs({ type: 'file', accept: '.png,.jpeg,.jpg' })`
  display: none;
`
