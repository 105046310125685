import { useCallback, useMemo } from 'react'
import { createContainer, useCachedPromise } from '@blue-agency/front-state-management'
import { InterviewQualityMode as BffInterviewQualityMode } from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import { toast } from '@blue-agency/rogue'
import { useDispatch } from 'react-redux'
import invariant from 'tiny-invariant'
import { assertNever } from '@/assertions'
import {
  useRequestChangeInterviewQuality,
  useRequestEnter,
  useRequestFinishInterview,
  useRequestGetInterviewQuality,
  useRequestGetParticipant,
  useRequestKickoutAllInterviewees,
} from '@/biz/services/bffService'
import { cacheKey } from '@/biz/services/cacheKeyService'
import { DataChannelConfiguration } from '@/lib/interview-sdk-js'
import { labelMicMuted, labelNetworkUnstableLevel } from '@/lib/meetingcomponent/DataChannelMessagingContainer'
import { useInterviewWebSocket } from '@/shared/hooks/useInterviewWebSocket'
import { useModal } from '@/shared/hooks/useModal'
import { useChangedInterviewQuality } from '@/shared/services/interviewService/hooksForApp/useChangedInterviewQuality'
import { useParticipantEnteredEventHandler } from '@/shared/services/interviewService/hooksForApp/useParticipantEnteredEventHandler'
import { useSoraConnectionProps } from '@/shared/services/interviewService/hooksForApp/useSoraConnectionProps'
import {
  InterviewQuality,
  InterviewQualityMode,
  isSoraPushData,
  SignalingPoints,
  SignalingPushMessageData,
} from '@/shared/services/interviewService/types'
import { InterviewPageContainer } from '../../../containers/InterviewPageContainer'
import { bizSlice } from '../../redux/bizSlice'
import { useCanceledEntryRequestHandler } from '../hooks/useCanceledEntryRequestHandler'
import { useEntryRequestHandler } from '../hooks/useEntryRequestHandler'
import { useInterviewTranscription } from '../hooks/useInterviewTranscription'

const vaderWebHost = process.env.REACT_APP_VADER_WEB_HOST
if (!vaderWebHost) throw new Error('vaderWebHost not found')

type Params = {
  sessionToken: {
    user: string
    screenShare: string
    screenShareRecv: string
  }
  signalingPoints: SignalingPoints
}

function useInRoom(params?: Params) {
  invariant(params)

  const dispatch = useDispatch()

  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) throw new Error('invalid state')

  if (!pageCtx.isValid) throw new Error('invalid status')

  const webSocketUrl = `wss://${vaderWebHost}/channel_status/${pageCtx.videoInterviewGuid}`
  const ws = useInterviewWebSocket(webSocketUrl)

  const dataChannels = useMemo<DataChannelConfiguration[]>(
    () => [
      { label: labelMicMuted, direction: 'sendrecv' },
      { label: labelNetworkUnstableLevel, direction: 'sendrecv' },
    ],
    []
  )
  const mainPublisherProps = useSoraConnectionProps(
    params.signalingPoints.main,
    ws,
    params.sessionToken.user,
    pageCtx.invitationToken,
    dataChannels
  )
  const screenSharingPublisherProps = useSoraConnectionProps(
    params.signalingPoints.screenSharing,
    ws,
    params.sessionToken.screenShare,
    pageCtx.invitationToken
  )
  const screenSharingSubscriberProps = useSoraConnectionProps(
    params.signalingPoints.screenSharing,
    ws,
    params.sessionToken.screenShareRecv,
    pageCtx.invitationToken
  )

  const { requestGetInterviewQuality } = useRequestGetInterviewQuality()
  const callGetInterviewQualityRpc = useCallback(async (): Promise<InterviewQuality> => {
    const res = await requestGetInterviewQuality(pageCtx.interviewGuid)
    return {
      mode: res.getMode() === BffInterviewQualityMode.LOW ? 'low' : 'normal',
      videoBitRate: res.getVideoBitRate(),
      videoFrameRate: res.getVideoFrameRate(),
    }
  }, [requestGetInterviewQuality, pageCtx.interviewGuid])

  const initialInterviewQuality = useCachedPromise(
    cacheKey.getInterviewQualityInRoom({ interviewGuid: pageCtx.interviewGuid }),
    callGetInterviewQualityRpc
  )
  const [changedInterviewQuality, handleChangedInterviewQuality] =
    useChangedInterviewQuality(callGetInterviewQualityRpc)
  const interviewQuality = changedInterviewQuality || initialInterviewQuality

  const { requestChangeInterviewQuality } = useRequestChangeInterviewQuality()
  const changeInterviewQuality = useCallback(
    async (mode: InterviewQualityMode) => {
      const m = mode === 'low' ? BffInterviewQualityMode.LOW : BffInterviewQualityMode.NORMAL
      await requestChangeInterviewQuality(pageCtx.interviewGuid, m)
    },
    [pageCtx.interviewGuid, requestChangeInterviewQuality]
  )

  const { requestEnter } = useRequestEnter()

  const callEnterRpc = useCallback(
    async (soraClientId: string) => {
      const res = await requestEnter({ interviewGuid: pageCtx.interviewGuid, soraClientId })
      return res.toObject()
    },
    [requestEnter, pageCtx.interviewGuid]
  )

  const { requestGetParticipant } = useRequestGetParticipant()

  const callGetParticipantRpc = useCallback(
    async (soraClientId: string) => {
      const res = await requestGetParticipant({ interviewGuid: pageCtx.interviewGuid, soraClientId })
      return res.toObject()
    },
    [requestGetParticipant, pageCtx.interviewGuid]
  )

  const onReceiveFinishMessage = useCallback(() => {
    dispatch(bizSlice.actions.finishMessageReceived())
  }, [dispatch])

  const finishModal = useModal()

  const { open: openFinishModal } = finishModal
  const onRequestFinish = useCallback(() => {
    openFinishModal()
  }, [openFinishModal])

  const kickoutAllIntervieweeModal = useModal()

  const { requestFinishInterview } = useRequestFinishInterview()

  const finishInterview = useCallback(async () => {
    await requestFinishInterview({ interviewGuid: pageCtx.interviewGuid })
  }, [pageCtx.interviewGuid, requestFinishInterview])

  const leaveInterview = useCallback(() => {
    dispatch(bizSlice.actions.leavingInterviewRequested())
  }, [dispatch])

  const { requestKickoutAllInterviewees } = useRequestKickoutAllInterviewees()
  const kickoutAllInterviewees = useCallback(() => {
    requestKickoutAllInterviewees(pageCtx.interviewGuid)
  }, [pageCtx.interviewGuid, requestKickoutAllInterviewees])

  const handleEntered = useParticipantEnteredEventHandler(callGetParticipantRpc)
  const handleEntryRequest = useEntryRequestHandler()
  const handleCanceledEntryRequest = useCanceledEntryRequestHandler()

  const { createInterviewTranscription } = useInterviewTranscription(pageCtx.interviewGuid)

  const onReceivePushEvent = useCallback(
    async (data: SignalingPushMessageData): Promise<void> => {
      if (!isSoraPushData(data)) return
      switch (data.type) {
        case 'APPLICANT_KICK_OUT': {
          toast('評価のすり合わせが完了したら\n面接を終了してください。')
          return
        }
        case 'PARTICIPANT_ENTERED': {
          handleEntered(pageCtx.interviewGuid, data.sora_client_id)
          return
        }
        case 'ENTRY_REQUEST': {
          handleEntryRequest(pageCtx.interviewGuid, data.entry_request_guid)
          return
        }
        case 'CANCELED_ENTRY_REQUEST': {
          handleCanceledEntryRequest(data.entry_request_guid)
          return
        }
        case 'CHANGED_QUALITY': {
          handleChangedInterviewQuality()
          return
        }
        case 'audio_streaming_result': {
          if (data.result.results[0] && data.result.code !== 'o') {
            createInterviewTranscription(data)
          }

          return
        }
        default:
          assertNever(data)
      }
    },
    [
      handleEntered,
      pageCtx.interviewGuid,
      handleEntryRequest,
      handleCanceledEntryRequest,
      handleChangedInterviewQuality,
      createInterviewTranscription,
    ]
  )

  return {
    // interviewService に渡す
    appType: 'biz' as const,
    interviewGuid: pageCtx.interviewGuid,
    ws,
    mainPublisherProps,
    screenSharingPublisherProps,
    screenSharingSubscriberProps,
    interviewQuality,
    callEnterRpc,
    callGetParticipantRpc,
    onRequestFinish,
    onReceiveFinishMessage,
    leaveInterview,
    onReceivePushEvent,

    changeInterviewQuality,

    // biz 側でのみ使う
    finishModal,
    finishInterview,
    kickoutAllIntervieweeModal,
    kickoutAllInterviewees,
    isInterviewTranscriptionEnabled: pageCtx.isInterviewTranscriptionEnabled,
  }
}

export const InRoomContainer = createContainer(useInRoom)
