import { comlinkPush } from '@blue-agency/im-shared-front'
import { BizSkywalkerBffServicePromiseClient } from '@blue-agency/proton/biz_skywalker_bff'
import { callWithRetry, logRpcErr } from '@/shared/services/bffService'

const hostname = process.env.REACT_APP_BIZ_API_HOST
if (!hostname) throw new Error('hostname not found')

export const bffClient = new BizSkywalkerBffServicePromiseClient(hostname)

if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([bffClient])
}

export function callBff<T extends keyof BizSkywalkerBffServicePromiseClient>(
  rpcName: T,
  req: Parameters<BizSkywalkerBffServicePromiseClient[T]>,
  loggedInStaffGuid: string | undefined
): ReturnType<BizSkywalkerBffServicePromiseClient[T]> {
  const metadata: Record<string, string> = {
    rpc: rpcName,
  }

  if (loggedInStaffGuid) {
    metadata['loggedInStaffGuid'] = loggedInStaffGuid
  }

  comlinkPush(
    {
      action: 'call_rpc',
      metadata,
    },
    { lazy: true }
  )

  // @ts-expect-error
  return callWithRetry(rpcName, () =>
    // @ts-expect-error
    bffClient[rpcName].apply(bffClient, req).catch((e) => {
      logRpcErr(rpcName, e, loggedInStaffGuid)
      throw e
    })
  )
}
