import React from 'react'
import { Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { Dots } from '@/shared/components/Dots'
import { NextButton } from '@/shared/components/ManualModal/Button'
import { ChatIcon, EarphoneIcon, StartIcon } from '@/shared/components/ManualModal/Icon'
import {
  Bottom,
  BottomWrapper,
  DotsWrapper,
  IconWrapper,
  Row,
  Upper,
  Wrapper,
} from '@/shared/components/ManualModal/Layout'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName

export const First = ({ className }: Props): React.ReactElement => {
  const { handleNext } = StepContainer.useContainer()

  return (
    <Wrapper className={className}>
      <Upper>
        <Row>
          <IconWrapper>
            <StartIcon />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>参加開始</Txt>
            <Txt>
              氏名を入力後、「参加をリクエスト」を押して
              <br />
              相手に承認されると参加開始となります。
            </Txt>
          </div>
        </Row>
        <Row>
          <IconWrapper>
            <ChatIcon />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>チャット機能</Txt>
            <Txt>
              参加者全員でチャット可能です。
              <br />
              音声/映像のトラブル時にもご利用いただけます。
            </Txt>
          </div>
        </Row>
        <Row>
          <IconWrapper>
            <EarphoneIcon />
          </IconWrapper>
          <div style={{ flex: 1 }}>
            <Txt>イヤホンの再接続</Txt>
            <Txt>
              「音声が聞こえない」ときは、イヤホンを再接続してみましょう。
              <br />
              接続状況が改善され、聞こえるようになる場合があります。
            </Txt>
          </div>
        </Row>
      </Upper>
      <BottomWrapper>
        <WeakColorSeparator />
        <DotsWrapper>
          <Dots count={2} currentPage={1} />
        </DotsWrapper>
        <Bottom>
          <NextButton
            comlinkPushParams={{
              // Mobileの方と、biz側との重複は想定内
              // eslint-disable-next-line @blue-agency/react/unique-action-name
              action: 'click_next_button_on_waiting_room_manual',
            }}
            onClick={handleNext}
          />
        </Bottom>
      </BottomWrapper>
    </Wrapper>
  )
}
