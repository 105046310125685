import { useCallback } from 'react'
import { EnterRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

type Args = {
  interviewGuid: string
  soraClientId: string
  name: string
}

export function useRequestEnter() {
  const requestEnter = useCallback((args: Args) => {
    const req = new EnterRequest()

    req.setInterviewGuid(args.interviewGuid)
    req.setSoraClientId(args.soraClientId)
    req.setName(args.name)

    return callBff('enter', [req])
  }, [])

  return { requestEnter }
}
