import React from 'react'
import { useContainer } from '@blue-agency/front-state-management/dist/libs/unstated-next'
import { Icon, TertiaryButton, theme } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useInterviewDisplaySize } from '@/shared/hooks/useInterviewDisplaySize'
import { ChatUnreadContainer } from '@/shared/services/interviewService/containers/ChatUnreadContainer'
import { ChatContainer } from '../../containers/ChatContainer'
import { isChatReadySelector } from '../../redux'
import { controlButtonBase } from './controlButtonBase'

export const ChatButton: React.VFC = React.memo(() => {
  const { isChatAreaOpen, toggleChatAreaOpen } = ChatContainer.useContainer()
  return <ChatButtonConsumer isChatAreaOpen={isChatAreaOpen} toggleChatAreaOpen={toggleChatAreaOpen} />
})

type ConsumerProps = {
  isChatAreaOpen: boolean
  toggleChatAreaOpen: () => void
}

const ChatButtonConsumer: React.VFC<ConsumerProps> = React.memo((props) => {
  const isChatReady = useSelector(isChatReadySelector)
  const { hasUnread } = useContainer(ChatUnreadContainer)
  const { responsive } = useInterviewDisplaySize()

  return (
    <Wrapper>
      {props.isChatAreaOpen ? (
        <Button
          text={responsive.pc ? 'チャット非表示' : '非表示'}
          onClick={props.toggleChatAreaOpen}
          icon={<Icon name="message-active" size="l" />}
          direction="column"
          disabled={!isChatReady}
          comlinkPushParams={{ action: 'click_chat_active_button' }}
        />
      ) : (
        <Button
          text="チャット"
          onClick={props.toggleChatAreaOpen}
          icon={<Icon name="message" size="l" />}
          direction="column"
          disabled={!isChatReady}
          comlinkPushParams={{ action: 'click_chat_button' }}
        />
      )}
      {isChatReady && hasUnread && <RedCircle />}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  position: relative;
`
// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const Button = styled(TertiaryButton)`
  ${controlButtonBase};
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`
const RedCircle = styled.div`
  position: absolute;
  top: calc(50% - 19px);
  right: calc(50% - 14px);

  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${theme.color.red[2]};
`
