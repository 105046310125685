import React from 'react'
import { useModal } from '@/shared/hooks/useModal'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { UnstableConnectionModal } from './UnstableConnectionModal'

export function useUnstableConnectionModal(isPc: boolean) {
  const modal = useModal()
  const { appType } = InterviewContainer.useContainer()

  const renderUnstableConnectionModal = () => {
    return <UnstableConnectionModal active={modal.active} appType={appType} isPc={isPc} onClose={modal.close} />
  }

  return {
    openUnstableConnectionModal: modal.open,
    closeUnstableConnectionModal: modal.close,
    renderUnstableConnectionModal,
  }
}
