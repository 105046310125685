import { Txt, theme, InputText, CircularProgress } from '@blue-agency/rogue'
import styled from 'styled-components'
import checkImage from './svg/check.svg'
import reloadImage from './svg/reload.svg'

export type PrivateMemoStatus = 'loaded' | 'initial' | 'notFound' | 'updating'
type Props = {
  status: PrivateMemoStatus
  privateMemo: string
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
}

export const PrivateMemoCard = ({ status, privateMemo, onChange }: Props) => {
  return (
    <Wrapper>
      <Header>
        <Txt size="l">プライベートメモ</Txt>
        {status === 'loaded' && (
          <HeaderSub>
            <CheckIcon />
            <Txt>保存完了</Txt>
          </HeaderSub>
        )}
        {status === 'updating' && (
          <HeaderSub>
            <ReloadIcon />
            <Txt>保存中…</Txt>
          </HeaderSub>
        )}
      </Header>
      {status === 'initial' && (
        <LoadingBody>
          <CircularProgress size={64} borderWidth={1} color={theme.color.red['iM']} />
        </LoadingBody>
      )}
      {status === 'notFound' && (
        <NotFoundBody>
          <Txt>面接に参加していないため、プライベートメモはありません</Txt>
        </NotFoundBody>
      )}
      {(status === 'loaded' || status === 'updating') && (
        <Body>
          <TextArea onChange={onChange} value={privateMemo} placeholder="あなた専用のメモを入力する…" />
        </Body>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${theme.color.gray[4]};
  border-radius: 4px;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: ${theme.color.navy[3]};
  border-bottom: solid 1px ${theme.color.gray[4]};
  border-radius: 4px 4px 0 0;
`

const HeaderSub = styled.div`
  display: flex;
  align-items: center;
  p {
    color: ${theme.color.gray[1]};
  }
`

const ReloadIcon = styled.img.attrs({ src: reloadImage })`
  margin-right: 4px;
`

const CheckIcon = styled.img.attrs({ src: checkImage })`
  margin-right: 4px;
`

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NotFoundBody = styled(Body)`
  padding: 20px;
`

const LoadingBody = styled(Body)`
  justify-content: center;
`

const TextArea = styled(InputText).attrs({ type: 'textarea' })`
  height: 100%;
  width: 100%;
  div {
    height: 100%;
  }
  textarea {
    height: 100%;
    border: none;
    resize: none;
    width: 100%;
    padding: 12px;
    font-size: ${theme.fontSize.m};
  }

  textarea:focus {
    outline: none;
  }

  textarea:hover {
    background-color: ${theme.color.gray[4]};
  }
`
