import { useCallback } from 'react'
import { GetSignalingPointsRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestGetSignalingPoints() {
  const requestGetSignalingPoints = useCallback(async (interviewGuid: string, invitationToken: string) => {
    const req = new GetSignalingPointsRequest()
    req.setInterviewGuid(interviewGuid)
    req.setInvitationToken(invitationToken)
    return callBff('getSignalingPoints', [req])
  }, [])

  return { requestGetSignalingPoints }
}
