import { useCallback, useEffect, useState } from 'react'
import { CustomGrpcError } from '@blue-agency/im-shared-front'
import { useMount } from 'react-use'
import { useRequestGetInterviewPrivateMemo } from '@/biz/services/bffService/useRequestGetInterviewPrivateMemo'
import { useRequestUpdateInterviewPrivateMemo } from '@/biz/services/bffService/useRequestUpdateInterviewPrivateMemo'
import type { PrivateMemoStatus } from '@/shared/components/InterviewMinutes/PrivateMemoCard'

export const usePrivateMemo = (interviewGuid: string) => {
  const [status, setStatus] = useState<PrivateMemoStatus>('initial')
  const [privateMemo, setPrivateMemo] = useState<string>('')

  const { requestGetInterviewPrivateMemo } = useRequestGetInterviewPrivateMemo()
  const { requestUpdateInterviewPrivateMemo } = useRequestUpdateInterviewPrivateMemo()

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setPrivateMemo(e.target.value)
    setStatus('updating')
  }, [])

  useMount(async () => {
    try {
      const res = await requestGetInterviewPrivateMemo(interviewGuid)
      setPrivateMemo(res.getText())
      setStatus('loaded')
    } catch (e) {
      if (e instanceof CustomGrpcError && e.isNotFound) {
        setStatus('notFound')
      } else {
        throw e
      }
    }
  })

  useEffect(() => {
    const interval = setInterval(async () => {
      if (status === 'updating') {
        await requestUpdateInterviewPrivateMemo(interviewGuid, privateMemo)
        setStatus('loaded')
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [interviewGuid, requestUpdateInterviewPrivateMemo, status, privateMemo])

  return {
    privateMemo,
    handleChange,
    status,
  }
}
