import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateInterviewPinningRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

type Args = {
  interviewGuid: string
  soraClientId: string
  interviewPinningType: CreateInterviewPinningRequest.InterviewPinningType
}

export function useRequestCreateInterviewPinning() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateInterviewPinning = useCallback(
    async (args: Args) => {
      const metadata = await buildMetadata()

      const req = new CreateInterviewPinningRequest()
      req.setInterviewGuid(args.interviewGuid)
      req.setSoraClientId(args.soraClientId)
      req.setType(args.interviewPinningType)

      return callBff('createInterviewPinning', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestCreateInterviewPinning }
}
