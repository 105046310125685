import { useState, useMemo } from 'react'
import { debounce } from 'lodash-es'
import useResizeObserver from 'use-resize-observer'

type HookResponse = ReturnType<typeof useResizeObserver>
type ObservedSize = Omit<HookResponse, 'ref'>

// c.f. https://github.com/ZeeCoder/use-resize-observer#throttle--debounce
export function useDebouncedResizeObserver(wait: number): HookResponse {
  const [size, setSize] = useState<ObservedSize>({
    width: undefined,
    height: undefined,
  })
  const onResize = useMemo(() => debounce(setSize, wait, { leading: true }), [wait])
  const { ref } = useResizeObserver<HTMLDivElement>({ onResize })

  return {
    ref,
    ...size,
  }
}
