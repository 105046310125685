import React from 'react'
import { useModal } from '@/shared/hooks/useModal'
import { ChangeDeviceModal } from './ChangeDeviceModal'

export function useChangeDeviceModal() {
  const modal = useModal()

  const renderChangeDeviceModal = () => {
    return <ChangeDeviceModal active={modal.active} onClose={modal.close} />
  }

  return {
    openChangeDeviceModal: modal.open,
    renderChangeDeviceModal,
  }
}
