import React from 'react'
import { theme, Txt, NewTabLink } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { isIOSDevice } from '@/shared/services/userAgentService'
import { RecommendPcBox as _RecommendPcBox } from './RecommendPcBox'
import { StepContainer } from './containers/useStep'

type Props = PropsWithClassName

export const Entrance: React.VFC<Props> = (props) => {
  const { toEnvironmentCheck } = StepContainer.useContainer()
  return (
    <>
      <Wrapper className={props.className}>
        <WrapperInner>
          <Txt size="l">インタビューメーカー セルフ動作テスト</Txt>
          <MainTxt>
            ご利用される端末やブラウザ、通信環境によっては
            <br />
            正常に動作しない可能性がございます。
            <br />
            開始の当日までに必ず推奨環境をご確認の上
            <br />
            動作環境テストを実施してください。
          </MainTxt>
          <SupportEnvironmentTableWrapper>
            <SupportEnvironmentTableTitle>PC</SupportEnvironmentTableTitle>
            <Table>
              <TableHead>
                <tr>
                  <FirstTh>ご利用端末</FirstTh>
                  <SecondTh>OS</SecondTh>
                  <ThirdTh>ブラウザ</ThirdTh>
                </tr>
              </TableHead>
              <tbody>
                <tr>
                  <Td>Windows</Td>
                  <Td>Windows10以上の最新版</Td>
                  <Td>Google Chrome, Microsoft Edgeの最新版</Td>
                </tr>
                <tr>
                  <Td>Mac</Td>
                  <Td>macOS Mojave(10.14.5)以上の最新版</Td>
                  <Td>Google Chrome, Safariの最新版</Td>
                </tr>
              </tbody>
            </Table>
            <SupportEnvironmentTableTitle>スマートフォン</SupportEnvironmentTableTitle>
            <Table>
              <TableHead>
                <tr>
                  <FirstTh>ご利用端末</FirstTh>
                  <SecondTh>OS</SecondTh>
                  <ThirdTh>ブラウザ</ThirdTh>
                </tr>
              </TableHead>
              <tbody>
                <tr>
                  <Td>
                    iPhone 8<br />
                    以降
                  </Td>
                  <Td>iOSの最新版</Td>
                  <Td>Safariの最新版</Td>
                </tr>
                <tr>
                  <Td>Android</Td>
                  <Td>Android 7以上</Td>
                  <Td>Google Chromeの最新版</Td>
                </tr>
              </tbody>
            </Table>
          </SupportEnvironmentTableWrapper>
          {isIOSDevice() && <RecommendPcBox />}
          <HelpGuideTxtWrapper>
            <Txt>
              その他、ご利用される環境や注意事項については、
              <br />
              <NewTabLink
                href={MY_EXTERNAL_URLS.help.environment.os}
                action="open_participant_interview_recommendation_env_help_page_for_manned_support"
              >
                ヘルプページ
              </NewTabLink>
              をご確認ください
            </Txt>
          </HelpGuideTxtWrapper>
        </WrapperInner>
      </Wrapper>
      <FixedFooter>
        <SubTxt>
          <NewTabLink href={MY_EXTERNAL_URLS.terms} action="open_participant_terms_page_for_manned_support">
            利用規約及びプライバシーポリシー
          </NewTabLink>
          に
        </SubTxt>
        <TestButton
          widthSize="L3"
          onClick={toEnvironmentCheck}
          comlinkPushParams={{ action: 'to_environment_check_page_for_manned_support' }}
        >
          同意して次に進む
        </TestButton>
      </FixedFooter>
    </>
  )
}

const footerHeight = '98px'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  width: calc(327px + 20px * 2);
  margin-bottom: ${footerHeight};
  width: auto;
`
const MainTxt = styled(Txt)`
  font-size: ${theme.fontSize.m};
  text-align: center;

  @media (min-width: 500px) {
    font-size: ${theme.fontSize.m};
    text-align: left;
  }

  ${WrapperInner} && {
    margin-top: 20px;
    width: 100%;
  }
`
const SupportEnvironmentTableWrapper = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 335px;
`
const SupportEnvironmentTableTitle = styled.p`
  font-size: ${theme.fontSize.m};

  ${SupportEnvironmentTableWrapper} &&:not(:first-of-type) {
    margin-top: 20px;
  }
`
const Table = styled.table`
  border-collapse: collapse;

  ${SupportEnvironmentTableWrapper} && {
    margin-top: 10px;
  }
`
const TableHead = styled.thead`
  background-color: ${theme.color.gray[4]};
`
const Th = styled.th`
  background-color: ${theme.color.gray[4]};
  border: 1px solid ${theme.color.gray[3]};
  font-size: ${theme.fontSize.m};
  font-weight: normal;
  padding: 8px 4px;
  text-align: left;
`
const FirstTh = styled(Th)`
  width: 0.2%;
`
const SecondTh = styled(Th)`
  width: 0.3%;
`
const ThirdTh = styled(Th)`
  width: 0.5%;
`
const Td = styled.td`
  border: 1px solid ${theme.color.gray[3]};
  word-break: break-word;
  font-size: ${theme.fontSize.s};
  line-height: 1.5;
  padding: 6px;
  vertical-align: middle;
`
const HelpGuideTxtWrapper = styled.div`
  text-align: center;

  @media (min-width: 500px) {
    text-align: left;
  }

  ${WrapperInner} && {
    margin-top: 18px;
    width: 100%;
  }
`
const SubTxt = styled(Txt)`
  ${WrapperInner} && {
    margin-top: 20px;
    width: 100%;
  }
`
const RecommendPcBox = styled(_RecommendPcBox)`
  ${WrapperInner} && {
    margin-top: 20px;
  }
`
const FixedFooter = styled.div`
  display: flex;
  height: ${footerHeight};
  flex-direction: column;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  background-color: ${theme.color.white[1]};
  border-top: 1px solid ${theme.color.gray[4]};
  padding: 12px 20px;
`
const TestButton = styled(PrimaryButton).attrs({ heightSize: 'L' })`
  ${FixedFooter} && {
    margin-top: 8px;
    max-width: 328px;
    width: 100%;
  }
`
