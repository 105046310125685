import React, { useEffect, useRef } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { Icon, theme } from '@blue-agency/rogue'
import { OutputSelector } from '@reduxjs/toolkit'
import { If, Then, Else } from 'react-if'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { AudioOutputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { MainVideoContainer } from '@/lib/meetingcomponent/MainVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { OverlayPin } from '@/lib/meetingcomponent/OverlayPin'
import { Video } from '@/lib/meetingcomponent/Video'
import { RemoteAudioWaveCircle } from '@/shared/services/interviewService/components/AudioWaveCircle/RemoteAudioWaveCircle'
import { VideoWrapper } from '@/shared/services/interviewService/components/SubAreaVideoWrapper'
import { AspectBox } from '@/shared/services/interviewService/components/VideoList/AspectBox'
import { NetworkUnstableLevel } from '@/shared/services/interviewService/components/VideoList/NetworkUnstableLevel'
import { SpeakingParticipantContainer } from '@/shared/services/interviewService/containers/SpeakingParticipantContainer'
import { VideosLayoutContainer } from '@/shared/services/interviewService/containers/VideosLayoutContainer'
import { useGetParticipantInfoForDisplay } from '@/shared/services/interviewService/hooksForUi/useGetParticipantInfoForDisplay'
import type { SharedState } from '@/shared/services/interviewService/redux'
import { Participant } from '@/shared/services/interviewService/types'
import { Footer, AudioStatus, Name, MuteMicIcon, Overlay, CompanyIcon, audioWaveCircleProps } from './CommonVideoParts'
import { RemoteVideoTileContainer } from './RemoteVideoTileContainer'

const attendeeIdToParticipant = (attendeeId: string | undefined, participants: Participant[]) => {
  if (attendeeId === undefined) return undefined
  return participants.find((p) => p.soraClientId === attendeeId)
}

type Props = {
  targetParticipantsSelector: OutputSelector<SharedState, Participant[], (res: Participant[]) => Participant[]>
  isScreenShare: boolean
}

export const RemoteVideos: React.VFC<Props> = (props) => {
  const { tiles, tileIdToAttendeeId } = RemoteVideoTileContainer.useContainer()
  const participants = useSelector(props.targetParticipantsSelector)
  const { videoWrapperLayout } = VideosLayoutContainer.useContainer()

  const remoteTiles = tiles
    .filter((tileId) => !!attendeeIdToParticipant(tileIdToAttendeeId[tileId], participants))
    .map((tileId) => ({ tileId, attendeeId: tileIdToAttendeeId[tileId] }))
    .filter(({ tileId, attendeeId }) => tileId >= 0 && attendeeId)

  return (
    <>
      {remoteTiles.map(({ tileId, attendeeId }) => (
        <VideoWrapper key={tileId} layout={videoWrapperLayout(props.isScreenShare)}>
          <RemoteVideo tileId={tileId} attendeeId={attendeeId} />
        </VideoWrapper>
      ))}
    </>
  )
}

export const RemoteVideo: React.VFC<{ tileId: number; attendeeId?: string }> = ({ tileId, attendeeId }) => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { mainVideoContentTileId } = MainVideoContainer.useContainer()
  const micMutedMap = useSelector((state: SharedState) => state.shared.micMutedMap)
  const { selectedAudioOutputDevice } = AudioOutputContainer.useContainer()
  const { screenShareIconSize, videoWrapperAspectRatio } = VideosLayoutContainer.useContainer()
  const isRunningOwnScreenShare = useSelector((state: SharedState) => state.shared.isRunningOwnScreenShare)
  const networkUnstableLevelMap = useSelector((state: SharedState) => state.shared.networkUnstableLevelMap)

  const videoEl = useRef<HTMLVideoElement>(null)

  const { name, isScreenShare, isRecruiter } = useGetParticipantInfoForDisplay(attendeeId)
  const { speakingSoraClientId } = SpeakingParticipantContainer.useContainer()

  const isMainVideo = mainVideoContentTileId === tileId
  const isMuted = attendeeId && micMutedMap[attendeeId]
  const isSpeaking = !isMainVideo && attendeeId !== undefined && speakingSoraClientId === attendeeId
  const networkUnstableLevel = attendeeId ? networkUnstableLevelMap[attendeeId] : undefined

  useEffect(() => {
    if (!audioVideo || !videoEl.current) return

    audioVideo.bindVideoElement(tileId, videoEl.current)
    videoEl.current.muted = false

    return () => {
      audioVideo?.unbindVideoElement(tileId)
    }
  }, [audioVideo, tileId])

  useEffect(() => {
    if (!audioVideo || !videoEl.current) return

    if (selectedAudioOutputDevice) {
      meetingManager
        .setAudioOutputForVideoTile(selectedAudioOutputDevice, tileId)
        .then(() => {
          comlinkPush({
            action: 'success_to_set_sink_for_other_participants_audio',
            metadata: {
              tileId: tileId.toString(),
            },
          })
        })
        .catch((e) => {
          comlinkPush({
            action: 'failed_to_set_sink_for_other_participants_audio',
            metadata: {
              tileId: tileId.toString(),
              errorMessage: e.message ?? '',
            },
          })

          // DOMException が発生することがある（原因は不明）が、この例外が発生してもスピーカーの切り替えには成功している可能性が高い
          // そのため「失敗した可能性があります」という曖昧な表現にしている
          alert(
            '音声の出力デバイス設定に失敗した可能性があります。相手からの音声が聞こえない場合、ブラウザを再読込してください。'
          )
        })
    }
  }, [meetingManager, audioVideo, selectedAudioOutputDevice, tileId])

  const renderVideo = () => {
    if (isScreenShare) {
      return (
        <ScreenShareIconWrapper>
          <ScreenShareIcon width={screenShareIconSize.width} height={screenShareIconSize.height} />
          {/* 音声つきで画面共有されている場合があるので、音声再生のために見えないvideoタグを置いておく */}
          {/* ただし、画面共有をしているのが自分である場合は音声を再生する必要がない */}
          {!isRunningOwnScreenShare && <InvisibleVideo ref={videoEl} />}
        </ScreenShareIconWrapper>
      )
    }

    return <Video ref={videoEl} />
  }

  return (
    <AspectBox ratio={videoWrapperAspectRatio(isScreenShare)}>
      <Wrapper isSpeaking={isSpeaking}>
        {renderVideo()}
        <Overlay hasName={!!name} isMainVideo={isMainVideo} />
        {!isScreenShare && <NetworkUnstableLevel networkUnstableLevel={networkUnstableLevel} />}

        <Footer>
          <AudioStatus>
            <If condition={isMuted}>
              <Then>
                <MuteMicIcon />
              </Then>
              <Else>{attendeeId && <AudioWaveCircle attendeeId={attendeeId} />}</Else>
            </If>
          </AudioStatus>

          <Name>
            {isRecruiter && <CompanyIcon />}
            {name}
          </Name>
        </Footer>

        <OverlayPin tileId={tileId} icon={isMainVideo ? 'unpin' : 'pin'} target="sub" />
      </Wrapper>
    </AspectBox>
  )
}

const Wrapper = styled.div<{ isSpeaking: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  background-color: ${theme.color.black[1]};

  ${({ theme }) => {
    if (!theme.responsive.pc) {
      // PC Safari において、overflow: hidden があると、ピンアイコンマウスオーバー時の tooltip の表示が崩れてしまう
      // そのため PC 以外のみ overflow: hidden を有効にする
      return css`
        overflow: hidden;
      `
    }
  }}

  ${({ isSpeaking }) => {
    if (isSpeaking) {
      return css`
        outline: 3px solid ${theme.color.green[4]};
      `
    }
  }}
`

const ScreenShareIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  background-color: ${theme.color.white[1]};
  border: 2px solid ${theme.color.white[1]};
`

const ScreenShareIcon = styled(Icon).attrs({ name: 'screen-share' })<{ width: string; height: string }>`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

const InvisibleVideo = styled.video`
  display: none;
`

const AudioWaveCircle = styled(RemoteAudioWaveCircle).attrs(({ theme }) => audioWaveCircleProps(theme))``
