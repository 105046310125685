export type IrregularJoinStatus = 'NoCamera' | 'NoMic' | 'Neither'

export const irregularJoinStatus = (
  isUnavailableVideoInput: boolean,
  isUnavailableAudioInput: boolean
): IrregularJoinStatus | undefined => {
  if (isUnavailableVideoInput && isUnavailableAudioInput) {
    return 'Neither'
  }
  if (isUnavailableVideoInput) {
    return 'NoCamera'
  }
  if (isUnavailableAudioInput) {
    return 'NoMic'
  }
  return undefined
}

export const irregularJoinAlertMsg = (status: IrregularJoinStatus) => {
  switch (status) {
    case 'NoCamera':
      return '利用できるカメラが見つかりません。\n音声のみで接続されます。'
    case 'NoMic':
      return '利用できるマイクが見つかりません。\n映像のみで接続されます。'
    case 'Neither':
      return '利用できるカメラとマイクが見つかりません。\n映像・音声なしで接続されます。'
  }
}
