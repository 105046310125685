import React from 'react'
import { Comlink } from '@blue-agency/comlink'
import { CreateInterviewTranscriptionPinningRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { ListInterviewTranscriptionsResponse } from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import { alertToast, Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { useRequestCreateInterviewTranscriptionPinning } from '@/biz/services/bffService/useRequestCreateInterviewTranscriptionPinning'

type Props = {
  interviewGuid: string
  transcriptionGuid: string
  onIconClick: (
    transcriptionGuid: string,
    transcriptionPinningGuid: string,
    pinType: ListInterviewTranscriptionsResponse.InterviewPinningType
  ) => void
  onPlay?: () => void
}

export const ButtonBox: React.VFC<Props> = ({ interviewGuid, transcriptionGuid, onIconClick, onPlay }) => {
  const { requestCreateInterviewTranscriptionPinning } = useRequestCreateInterviewTranscriptionPinning()

  const pushComlink = (interviewGuid: string, transcriptionSentenceGuid: string, action: string) => {
    Comlink.push({
      type: 'manual_activity',
      group: 'skywalker-front',
      action: action,
      metadata: {
        interviewGuid: interviewGuid,
        transcriptionSentenceGuid: transcriptionSentenceGuid,
      },
    })
  }

  const handleGoodButtonClick = async () => {
    try {
      const res = await requestCreateInterviewTranscriptionPinning({
        interviewGuid: interviewGuid,
        interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType.GOOD,
        transcriptionSentenceGuid: transcriptionGuid,
      })
      pushComlink(interviewGuid, transcriptionGuid, 'click_good_transcription_pined')
      onIconClick(
        transcriptionGuid,
        res.getGuid(),
        CreateInterviewTranscriptionPinningRequest.InterviewPinningType.GOOD
      )
    } catch (err) {
      alertToast('ピンの入力に失敗しました')
    }
  }

  const handleBadButtonClick = async () => {
    try {
      const res = await requestCreateInterviewTranscriptionPinning({
        interviewGuid: interviewGuid,
        interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType.BAD,
        transcriptionSentenceGuid: transcriptionGuid,
      })
      pushComlink(interviewGuid, transcriptionGuid, 'click_bad_transcription_pined')
      onIconClick(transcriptionGuid, res.getGuid(), ListInterviewTranscriptionsResponse.InterviewPinningType.BAD)
    } catch (err) {
      alertToast('ピンの入力に失敗しました')
      return
    }
  }

  const handleQuestionButtonClick = async () => {
    try {
      const res = await requestCreateInterviewTranscriptionPinning({
        interviewGuid: interviewGuid,
        interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType.QUESTION,
        transcriptionSentenceGuid: transcriptionGuid,
      })
      pushComlink(interviewGuid, transcriptionGuid, 'click_question_transcription_pined')
      onIconClick(transcriptionGuid, res.getGuid(), ListInterviewTranscriptionsResponse.InterviewPinningType.QUESTION)
    } catch (err) {
      alertToast('ピンの入力に失敗しました')
      return
    }
  }

  return (
    <>
      <Wrapper>
        <ButtonWrapper onClick={handleGoodButtonClick}>
          <GoodButton />
        </ButtonWrapper>
        <ButtonWrapper onClick={handleBadButtonClick}>
          <BadButton />
        </ButtonWrapper>
        <ButtonWrapper onClick={handleQuestionButtonClick}>
          <QuestionButton />
        </ButtonWrapper>
        {onPlay && (
          <>
            <VerticalLine />
            <ButtonWrapper onClick={onPlay}>
              <SeekTimeAndPlayButton />
            </ButtonWrapper>
          </>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 1rem;
  height: 40px;
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--white-1, #fff);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
`

const GoodButton = styled(Icon).attrs({ name: 'good-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.blue[1]};
`

const BadButton = styled(Icon).attrs({ name: 'bad-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.red[2]};
`

const QuestionButton = styled(Icon).attrs({ name: 'question-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.orange[1]};
`

const SeekTimeAndPlayButton = styled(Icon).attrs({ name: 'play-circle' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.navy[1]};
`

const VerticalLine = styled.div`
  width: 1px;
  margin: 4px 0;
  height: 32px;
  background-color: ${theme.color.gray[3]};
  opacity: 0.5;
`

const ButtonWrapper = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: ${theme.color.gray[4]};
    cursor: pointer;
  }
`
