import { comlinkPush } from '@blue-agency/im-shared-front'
import { useDispatch, useSelector } from 'react-redux'
import invariant from 'tiny-invariant'
import { useContinuousTimeout } from '@/shared/hooks/useContinuousTimeout'
import { InterviewContainer } from '../containers/InterviewContainer'
import { sharedSlice, shortageParticipantSoraClientIdsSelector } from '../redux'

const INTERVAL_MS = 5000

/**
 * wsのenterイベントを喪失した場合にparticipantが取得されないため補完する
 */
export function usePollingGetShotageParticipants() {
  const dispatch = useDispatch()
  const { callGetParticipantRpc } = InterviewContainer.useContainer()

  const shortageSoraClientIds = useSelector(shortageParticipantSoraClientIdsSelector)

  useContinuousTimeout(async () => {
    if (shortageSoraClientIds.length > 0) {
      comlinkPush({
        action: 'get_shortage_participants',
        metadata: {
          shortageSoraClientIds: JSON.stringify(shortageSoraClientIds),
        },
      })
    }

    for (const soraClientId of shortageSoraClientIds) {
      try {
        const res = await callGetParticipantRpc(soraClientId)
        invariant(res.participant)
        dispatch(
          sharedSlice.actions.participantEntered({
            soraClientId: res.participant.soraClientId,
            name: res.participant.name,
          })
        )
      } catch {
        // ignore
      }
    }
  }, INTERVAL_MS)
}
