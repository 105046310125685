import React, { RefObject } from 'react'
import { UnstableConnectionWarningBalloon } from '@/shared/services/interviewService/components/UnstableConnectionWarningBalloon'
import type { Warning } from '../../hooksForApp/useUnstableConnectionWarning'

export const useUnstableConnectionWarningBalloon = (
  posRef: RefObject<HTMLElement | null>,
  warning: Warning,
  onClose: () => void
) => {
  const renderUnstableConnectionWarningBalloon = () => {
    const basePosition = posRef.current ? posRef.current.getBoundingClientRect() : undefined
    if (!basePosition) return null
    return <UnstableConnectionWarningBalloon warning={warning} basePosition={basePosition} onClose={onClose} />
  }
  return {
    renderUnstableConnectionWarningBalloon,
  }
}
