import { Icon, theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { BizInterviewGuideContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { GuidePartView } from '@/shared/services/interviewService/components/InterviewGuide/GuidePartView'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'

export function SpInterviewGuide() {
  const { closeInterviewGuideArea } = InterviewGuideContainer.useContainer()
  const { guide } = BizInterviewGuideContainer.useContainer()

  return guide ? (
    <Wrapper>
      <Header>
        <Title>
          <Icon name="guide" />
          <Txt bold>面接ガイド</Txt>
        </Title>
        <CloseButton onClick={closeInterviewGuideArea}>
          <Icon name="close" />
        </CloseButton>
      </Header>
      <Body>
        <GuideName>{guide.name}</GuideName>
        <GuideParts>
          {guide.parts.map((part) => {
            return <GuidePartView key={part.startSeconds} part={part} />
          })}
        </GuideParts>
      </Body>
    </Wrapper>
  ) : null
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const HEADER_HEIGHT = '36px'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid ${theme.color.navy[1]};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 5px;
    /* アイコンの縦位置 調整 */
    margin-bottom: 2px;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
`

const Body = styled.div`
  height: calc(100% - ${HEADER_HEIGHT});
  display: flex;
  flex-direction: column;
`

const GuideName = styled(Txt).attrs({ size: 'm', bold: true })`
  color: ${theme.color.gray[1]};
  padding: 7px 12px 4px;
`

const GuideParts = styled.div`
  display: flex;
  flex-direction: column;
  ${overflowYScrollStyle};
`
