import { useEffect } from 'react'
import { Icon, TertiaryButton } from '@blue-agency/rogue'
import styled from 'styled-components'
import { InterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { controlButtonBase } from '@/shared/services/interviewService/components/controlButtons/controlButtonBase'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'

export function GuideButton() {
  const interviewPage = InterviewPageContainer.useContainer()
  const { isInterviewGuideAreaOpen, toggleInterviewGuideAreaOpen, closeInterviewGuideArea } =
    InterviewGuideContainer.useContainer()

  const guideExist = interviewPage.isValid && interviewPage.guide !== undefined

  useEffect(() => {
    if (!guideExist) {
      closeInterviewGuideArea()
    }
  }, [closeInterviewGuideArea, guideExist])

  if (!guideExist) {
    return null
  }

  return (
    <>
      {isInterviewGuideAreaOpen ? (
        <Button
          onClick={toggleInterviewGuideAreaOpen}
          text="ガイド非表示"
          direction="column"
          icon={<Icon name="guide-active" size="l" />}
          comlinkPushParams={{ action: 'click_close_interview_guide_button' }}
        />
      ) : (
        <Button
          onClick={toggleInterviewGuideAreaOpen}
          text="ガイド"
          direction="column"
          icon={<Icon name="guide" size="l" />}
          comlinkPushParams={{ action: 'click_open_interview_guide_button' }}
        />
      )}
    </>
  )
}

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const Button = styled(TertiaryButton)`
  ${controlButtonBase}
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`
