import { useCallback, useEffect, useState } from 'react'
import { CustomGrpcError } from '@blue-agency/im-shared-front'
import { WebInterviewSummaryStatus } from '@blue-agency/proton/web/v2/im'
import { useRequestCreateInterviewSummary, useRequestGetInterviewSummary } from '@/biz/services/bffService'
import type { SummaryStatus } from '@/shared/components/InterviewMinutes/SummaryCard'

export const useSummary = (interviewGuid: string) => {
  const [status, setStatus] = useState<SummaryStatus>('initial')
  const [summary, setSummary] = useState<string>('')

  const { requestGetInterviewSummary } = useRequestGetInterviewSummary()
  const { requestCreateInterviewSummary } = useRequestCreateInterviewSummary()

  const handleCreateSummary = useCallback(async () => {
    await requestCreateInterviewSummary({ interviewGuid: interviewGuid })
    setStatus('progress')
  }, [interviewGuid, requestCreateInterviewSummary])

  useEffect(() => {
    const fetchSummary = async () => {
      try {
        const res = await requestGetInterviewSummary(interviewGuid)
        if (res.getStatus() === WebInterviewSummaryStatus.WEB_INTERVIEW_SUMMARY_STATUS_COMPLETED) {
          setSummary(res.getSummary())
          setStatus('completed')
        } else if (res.getStatus() === WebInterviewSummaryStatus.WEB_INTERVIEW_SUMMARY_STATUS_FAILED) {
          setStatus('failed')
        } else if (res.getStatus() === WebInterviewSummaryStatus.WEB_INTERVIEW_SUMMARY_STATUS_IN_PROGRESS) {
          setStatus('progress')
        }
      } catch (e) {
        if (e instanceof CustomGrpcError) {
          if (e.isNotFound) {
            setStatus('enabled')
            return
          } else if (e.isFailedPrecondition) {
            setStatus('disabled')
            return
          }
        }
        throw e
      }
    }

    if (status === 'initial') {
      fetchSummary()
      return
    }

    if (status === 'disabled' || status === 'failed' || status === 'completed') return

    const interval = setInterval(fetchSummary, 10000)
    return () => clearInterval(interval)
  }, [interviewGuid, requestGetInterviewSummary, status])

  return {
    summary,
    handleCreateSummary,
    status,
  }
}
