import { useEffect, useRef, useState, DependencyList } from 'react'
import { useMountedState } from 'react-use'

type Cleanup = (() => Promise<unknown>) | undefined
type EffectCallback = () => Cleanup

export const useEffectWaitCleanup = (effect: EffectCallback, deps: DependencyList): void => {
  const cleanupping = useRef(false)
  const isMounted = useMountedState()

  // 更新通知にのみ使用
  const [refleshDep, reflesh] = useState(true)

  useEffect(() => {
    if (cleanupping.current) {
      return
    }

    const cleanup = effect()
    if (!cleanup) {
      return
    }

    return () => {
      cleanupping.current = true
      cleanup().then(() => {
        cleanupping.current = false
        if (isMounted()) {
          reflesh((prev) => !prev)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, refleshDep])
}
