import { StaticImageStream } from '../staticimagestream/StaticImageStream'
import { UNAVAILABLE_VIDEO_INPUT_IMG } from './unavailableVideoInputImg'

export class UnavailableVideoInputStream extends StaticImageStream {
  private static _instance: UnavailableVideoInputStream

  private constructor() {
    super()
  }

  public static get instance(): UnavailableVideoInputStream {
    if (!this._instance) {
      this._instance = new this()
    }
    return this._instance
  }

  protected src(): string {
    return UNAVAILABLE_VIDEO_INPUT_IMG
  }
}
