import { PushType } from '@blue-agency/proton/web/v2/vader/push_type_pb'
import { SignalingPushMessage } from 'sora-js-sdk/dist/types'
import {
  SignalingNotifyConnectionCreated,
  BackgroundEffect as BackgroundEffectOption,
  DataChannelConfiguration,
} from '@/lib/interview-sdk-js'
import { createImgFromUri } from '@/shared/services/image'

export type InterviewState = 'NotStarted' | 'InRunning' | 'Finished'

export type OwnState = 'BeforeEntered' | 'InRoom' | 'Leaved'

// NOTE: soraClientId は Participant ごとに異なり、Participant の id として使う
export type SoraClientId = string

// NOTE: amazon-chime-sdk-js の VideoTileController が管理している VideoTile に割り振られた ID
//
// cited from VideoTileState.d.ts:
// The unique identifier for the [[VideoTile]] managed by [[VideoTileController]]. Each attendee can have at most one tileId.
export type TileId = number

export type Participant = {
  soraClientId: SoraClientId
  name: string
  role: InterviewRole
}

export type ParticipantWithoutRole = Omit<Participant, 'role'>

export type UnstableLevel = 0 | 1 | 2 | 3

export type NetworkStatusNotification = {
  event_type: 'network.status'
  unstable_level: UnstableLevel
}

// https://github.com/blue-agency/vader/blob/8b14b9cca5e85e89cd0b251913017db6433e8c6b/domain/auth.go#L22-L29
const allInterviewRoles = [
  'recruiter',
  'applicant',
  'recruiter_screen',
  'applicant_screen',
  'recruiter_screen_recv',
  'applicant_screen_recv',
] as const

export type RawInterviewRole = typeof allInterviewRoles[number]

export type InterviewRole = Exclude<RawInterviewRole, 'recruiter_screen_recv' | 'applicant_screen_recv'>

export function isScreenShare(role: RawInterviewRole): boolean {
  return role === 'applicant_screen' || role === 'recruiter_screen'
}

export function isRecruiter(role: RawInterviewRole): boolean {
  return role === 'recruiter' || role === 'recruiter_screen'
}

export function isApplicant(role: RawInterviewRole): boolean {
  return role === 'applicant' || role === 'applicant_screen'
}

export type RawMetadata = {
  interview_role: RawInterviewRole
}

export function ensureRawMetadata(
  rawMetadata: SignalingNotifyConnectionCreated['metadata']
): rawMetadata is RawMetadata {
  if (
    !rawMetadata ||
    typeof rawMetadata === 'boolean' ||
    typeof rawMetadata === 'number' ||
    typeof rawMetadata === 'string' ||
    Array.isArray(rawMetadata) ||
    typeof rawMetadata['interview_role'] !== 'string'
  ) {
    return false
  }
  return allInterviewRoles.includes(rawMetadata['interview_role'] as RawInterviewRole)
}

export type Metadata = {
  interview_role: InterviewRole
}

export function ensureMetadata(rawMetadata: RawMetadata): rawMetadata is Metadata {
  return (
    rawMetadata.interview_role !== 'recruiter_screen_recv' && rawMetadata.interview_role !== 'applicant_screen_recv'
  )
}

export type ParticipantRole = 'interviewer' | 'interviewee'

export type ChatRole = 'interviewer' | 'interviewee'

export type ChatMessage = {
  messageGuid: string
  participant: {
    soraClientId: SoraClientId
    name: string
    role: ChatRole
  }
  text: string
  createTime: Date
}

export type SoraSignalingErrorReason = 'Error/Unknown' | 'Error/NumberOfParticipantsLimit'
export type SoraSignalingStatus = 'Pending' | 'Completed' | 'Disconnected' | SoraSignalingErrorReason

export type SignalingPoints = {
  main: SignalingPoint
  screenSharing: SignalingPoint
}

export type SignalingPoint = {
  channelId: string
  webrtcHost: string
}

export type SoraConnectionProps = {
  webrtcHost: string
  sessionToken: string
  invitationToken: string
  channelId: string
  videoBitrate?: number
  dataChannels?: DataChannelConfiguration[]
}

export type BackgroundEffect = 'no-effect' | 'blur-weak' | 'blur-strong' | 'user-image'

export const BACKGROUND_BLUR_SIZE_MAP: Readonly<
  Record<Extract<BackgroundEffect, 'blur-weak' | 'blur-strong'>, number>
> = {
  'blur-weak': 7,
  'blur-strong': 11,
}

export type InterviewQualityMode = 'normal' | 'low'
export type InterviewQuality = {
  mode: InterviewQualityMode
  videoBitRate: number
  videoFrameRate: number
}

export function convertToBackgroundEffectOption(
  backgroundEffect: BackgroundEffect,
  backgroundUserImage: string | undefined
): BackgroundEffectOption | undefined {
  switch (backgroundEffect) {
    case 'no-effect': {
      return { type: 'no-effect' }
    }
    case 'blur-weak':
    case 'blur-strong': {
      return { type: 'blur', blurSize: BACKGROUND_BLUR_SIZE_MAP[backgroundEffect] }
    }
    case 'user-image': {
      if (backgroundUserImage) {
        return { type: 'replace', image: createImgFromUri(backgroundUserImage) }
      }
      // 画像がない場合は呼び出し元でスキップするためにundefinedを返す
      return undefined
    }
  }
}

export type SoraPushType = keyof typeof PushType | 'audio_streaming_result'

export type SignalingPushMessageData = SignalingPushMessage['data']
interface SoraPushDataBase extends SignalingPushMessageData {
  type: SoraPushType
}

export interface SoraPushDataApplicantKickOut extends SoraPushDataBase {
  type: 'APPLICANT_KICK_OUT'
}
export interface SoraPushDataParticipantEntered extends SoraPushDataBase {
  type: 'PARTICIPANT_ENTERED'
  sora_client_id: string
}

export interface SoraPushDataEntryRequest extends SoraPushDataBase {
  type: 'ENTRY_REQUEST'
  entry_request_guid: string
}

export interface SoraPushDataCanceledEntryRequest extends SoraPushDataBase {
  type: 'CANCELED_ENTRY_REQUEST'
  entry_request_guid: string
}

export interface SoraPushDataChangedQuality extends SoraPushDataBase {
  type: 'CHANGED_QUALITY'
}

// https://docs.amivoice.com/amivoice-api/manual/user-guide/response/result-format#websocket-%E9%9F%B3%E5%A3%B0%E8%AA%8D%E8%AD%98-api
// https://sora-doc.shiguredo.jp/AUDIO_STREAMING#c7c2a2
// soraから配信される音声認識結果のresultにAmivoiceの結果をそのまま入れている
export interface SoraPushDataAudioStreamingResult extends SoraPushDataBase {
  type: 'audio_streaming_result'
  connection_id: string
  result: {
    code?: string
    error_message?: string
    message?: string
    results: {
      confidence: number
      endtime: number
      rulename: string
      starttime: number
      tags: string[]
      text: string
      tokens: {
        confidence: number
        endtime: number
        spoken: string
        starttime: number
        written: string
      }[]
    }[]
    utteranceid: string
  }
}

export type SoraPushData =
  | SoraPushDataApplicantKickOut
  | SoraPushDataParticipantEntered
  | SoraPushDataEntryRequest
  | SoraPushDataCanceledEntryRequest
  | SoraPushDataChangedQuality
  | SoraPushDataAudioStreamingResult

export const isSoraPushData = (d: SignalingPushMessageData): d is SoraPushData => {
  if (!d.type) return false
  // 値として正しいtypeが導出できないので妥協
  return typeof d.type === 'string'
}

export type ViewMode = InterviewView | TileView

// 面接ビューモードには2つのピン留め状態がある
//
// 1. 強いピン留め
//   ユーザーのクリックによる明示的なピン留めがされている or 画面共有が開始したときに強制的にピンが打たれる
// 2. 弱いピン留め
//   ユーザーは明示的にピン留めをしていない状態。
//   相手ロール -> 自分ロール(自分以外) -> 自分 の優先度に従い、自動でメインエリアの映像が選択される
type InterviewView = StrongPinned | WeakPinned

type StrongPinned = {
  type: 'strong_pinned'
  tileId: TileId
}

type WeakPinned = {
  type: 'weak_pinned'
}

type TileView = {
  type: 'tile_view'
}
