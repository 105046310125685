import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ApproveEntryRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestApproveEntry() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestApproveEntry = useCallback(
    async (interviewGuid: string, entryRequestGuid: string) => {
      const metadata = await buildMetadata()

      const req = new ApproveEntryRequest()

      req.setInterviewGuid(interviewGuid)
      req.setEntryRequestGuid(entryRequestGuid)

      return callBff('approveEntry', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestApproveEntry }
}
