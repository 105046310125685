import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { InterviewContainer } from '../containers/InterviewContainer'
import { sharedSlice } from '../redux'

export function useStartMessageHandler() {
  const dispatch = useDispatch()

  const { ws } = InterviewContainer.useContainer()
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    function startHandler(message: WebSocketMessageMap['start']) {
      dispatch(sharedSlice.actions.startMessageReceived(message))
    }

    addMessageListener('start', startHandler)
    return () => {
      removeMessageListener('start', startHandler)
    }
  }, [addMessageListener, dispatch, removeMessageListener])
}
