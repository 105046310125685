import React, { useCallback } from 'react'
import { useModal } from '@/shared/hooks/useModal'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { InterviewQualityMode } from '@/shared/services/interviewService/types'
import { QualityModeModal } from './QualityModeModal'

export function useQualityModeModal() {
  const modal = useModal()
  const { interviewQuality, changeInterviewQuality } = InterviewContainer.useContainer()
  const handleOnOk = useCallback(
    async (val: InterviewQualityMode) => {
      if (!changeInterviewQuality) {
        throw new Error('unexpected no changeInterviewQuality')
      }
      await changeInterviewQuality(val)
      modal.close()
    },
    [changeInterviewQuality, modal]
  )

  const renderQualityModeModal = () => {
    return (
      <QualityModeModal
        active={modal.active}
        onClose={modal.close}
        qualityMode={interviewQuality.mode}
        onOk={handleOnOk}
      />
    )
  }

  return {
    openQualityModeModal: modal.open,
    renderQualityModeModal,
  }
}
