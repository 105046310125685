import React from 'react'
import { useModal } from '@/shared/hooks/useModal'
import { ReloadModal } from './ReloadModal'

export function useReloadModal() {
  const modal = useModal()

  const renderReloadModal = () => {
    return <ReloadModal active={modal.active} onCancel={modal.close} />
  }

  return {
    openReloadModal: modal.open,
    renderReloadModal,
  }
}
