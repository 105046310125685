import { useEffect, useReducer } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useDispatch } from 'react-redux'
import { AudioVideoObserver } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { sharedSlice } from '@/shared/services/interviewService/redux'
import { initialState, reducer, VideoTileActionType } from './state'

const useRemoteVideoTile = () => {
  const sharedDispatch = useDispatch()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    const observer: AudioVideoObserver = {
      videoTileDidUpdate: (tileState): void => {
        if (tileState.boundAttendeeId && tileState.tileId && !tileState.isContent && !tileState.localTile) {
          const { tileId, boundAttendeeId } = tileState
          dispatch({
            type: VideoTileActionType.UPDATE,
            payload: {
              tileId,
              attendeeId: boundAttendeeId,
            },
          })
          sharedDispatch(
            sharedSlice.actions.remoteTileUpdated({
              tileId: tileState.tileId,
              soraClientId: tileState.boundAttendeeId,
            })
          )
        }
      },
      videoTileWasRemoved: (tileId): void => {
        dispatch({
          type: VideoTileActionType.REMOVE,
          payload: {
            tileId,
          },
        })
        sharedDispatch(sharedSlice.actions.remoteTileRemoved({ tileId }))
      },
    }

    audioVideo.addObserver(observer)
    return () => audioVideo?.removeObserver(observer)
  }, [audioVideo, sharedDispatch])

  useEffect(() => {
    if (!audioVideo) {
      return
    }

    return () => dispatch({ type: VideoTileActionType.RESET })
  }, [audioVideo])

  return state
}

export const RemoteVideoTileContainer = createContainer(useRemoteVideoTile)
