import React from 'react'
import { Icon } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import { css } from 'styled-components'

const buttonStyle = css`
  /* Button コンポーネントの padding を上書きしている  */
  padding: 0 0 0 4px;
`

type Props = {
  disabled: boolean
  submit: () => void
}

export const SendButton: React.VFC<Props> = React.memo(({ disabled, submit }) => {
  return (
    <PrimaryButton
      icon={<Icon name="send" size="m" />}
      type="button"
      // onClick を使うと、テキストエリアの onBlur とコンフリクトしてしまい、SP縦画面でうまく動作しない
      // 苦肉の策でonMouseDownを利用している (モバイル端末でもこのイベントでOK)
      onMouseDown={submit}
      css={buttonStyle}
      widthSize="S"
      heightSize="S"
      disabled={disabled}
      comlinkPushParams={{
        action: 'click_send_chat_message_button',
      }}
    />
  )
})
