import { useEffect } from 'react'
import { toast } from '@blue-agency/rogue'
import differenceBy from 'lodash-es/differenceBy'
import { useSelector } from 'react-redux'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { participantsSelector, SharedState } from '../redux'

export function useParticipantAddedToast() {
  const participants = useSelector(participantsSelector)
  const prevParticipants = usePrevious(participants)

  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)
  const soraClientIdEnteredBeforeMeList = useSelector(
    (state: SharedState) => state.shared.soraClientIdEnteredBeforeMeList
  )

  useEffect(() => {
    if (ownSoraClientId === undefined) return

    // 入室済みの参加者についてはトーストを表示したくないと思ったが、
    // 入室済みを判別するのが難しく、入室済みの参加者もトーストを表示する
    // 仕様的には、表示してもしなくてもどちらでもよい
    // https://github.com/blue-agency/skywalker-front/pull/175#discussion_r560928849
    const addedParticipants = (() => {
      if (!prevParticipants) {
        return participants
      }
      return differenceBy(participants, prevParticipants, (item) => item.soraClientId)
    })()

    if (addedParticipants[0]) {
      const isNotMe = addedParticipants[0].soraClientId !== ownSoraClientId
      // prettier-ignore
      const isNotScreenShare = addedParticipants[0].role !== 'applicant_screen' && addedParticipants[0].role !== 'recruiter_screen'
      // prettier-ignore
      const isEnteredAfterMe = soraClientIdEnteredBeforeMeList && !soraClientIdEnteredBeforeMeList.includes(addedParticipants[0].soraClientId)

      if (isNotMe && isNotScreenShare && isEnteredAfterMe) {
        toast(`${addedParticipants[0].name}さんが参加しました`)
      }
    }
  }, [ownSoraClientId, participants, prevParticipants, soraClientIdEnteredBeforeMeList])
}
