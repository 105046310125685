import React from 'react'
import { usePlayNotificationSoundWhenRequested } from '@/biz/pages/InterviewPage/Interview/InRoom/hooks/useEntryRequest/usePlayNotificationSoundWhenRequested'
import { useOpenInterviewGuideAreaOnMount } from '@/biz/services/interviewGuideService'
import { Meeting } from '@/lib/meetingcomponent/Meeting'
import { MeetingProvider } from '@/lib/meetingcomponent/MeetingProvider'
import { InterviewAutoQuestionContainer } from '@/shared/services/interviewService/containers/InterviewAutoQuestionContainer'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'
import { InterviewPinningContainer } from '@/shared/services/interviewService/containers/InterviewPinningContainer'
import { InterviewTimerContainer } from '@/shared/services/interviewService/containers/InterviewTimerContainer'
import { MinutesViewContainer } from '@/shared/services/interviewService/containers/MinutesViewContainer'
import { ParticipantAudioVolumesContainer } from '@/shared/services/interviewService/containers/ParticipantAudioVolumesContainer'
import { SpeakingParticipantContainer } from '@/shared/services/interviewService/containers/SpeakingParticipantContainer'
import { usePlayNotificationSoundWhenChatMessageReceived } from '@/shared/services/interviewService/hooksForApp/usePlayNotificationSoundWhenChatMessageReceived'
import { Layout } from './components/Layout'
import { SignalingErrorModal } from './components/SoraSignalingErrorModal'
import { ChatContainer } from './containers/ChatContainer'
import { InterviewContainer, InterviewContainerProps } from './containers/InterviewContainer'
import { useCallEnterRpcWhenEntered } from './hooksForApp/useCallEnterRpcWhenEntered'
import { useCameraUnpluged } from './hooksForApp/useCameraUnpluged'
import { useComlinkConnectionStatsSummary } from './hooksForApp/useComlinkConnectionStatsSummary'
import { useComlinkNetworkUnstableLevel } from './hooksForApp/useComlinkNetworkUnstableLevel'
import { useFinishMessageHandler } from './hooksForApp/useFinishMessageHandler'
import { useMicUnpluged } from './hooksForApp/useMicUnpluged'
import { useParticipantAddedToast } from './hooksForApp/useParticipantAddedToast'
import { usePollingGetShotageParticipants } from './hooksForApp/usePollingGetShotageParticipants'
import { useSpeakerUnpluged } from './hooksForApp/useSpeakerUnpluged'
import { useStartMessageHandler } from './hooksForApp/useStartMessageHandler'

type Props = InterviewContainerProps

export const InterviewRoom: React.VFC<Props> = React.memo((props) => {
  return (
    <InterviewContainer.Provider initialState={props}>
      <ChatContainer.Provider>
        <InterviewGuideContainer.Provider>
          <InterviewAutoQuestionContainer.Provider>
            <InterviewPinningContainer.Provider>
              <InterviewTimerContainer.Provider>
                <MeetingProvider>
                  <ParticipantAudioVolumesContainer.Provider>
                    <SpeakingParticipantContainer.Provider>
                      <MinutesViewContainer.Provider>
                        <SideEffectResolver>
                          {props.appType === 'biz' && <BizSideEffectResolver />}
                          <Meeting>
                            <Layout isInterviewTranscriptionEnabled={props.isInterviewTranscriptionEnabled ?? false} />
                          </Meeting>
                          <SignalingErrorModal />
                        </SideEffectResolver>
                      </MinutesViewContainer.Provider>
                    </SpeakingParticipantContainer.Provider>
                  </ParticipantAudioVolumesContainer.Provider>
                </MeetingProvider>
              </InterviewTimerContainer.Provider>
            </InterviewPinningContainer.Provider>
          </InterviewAutoQuestionContainer.Provider>
        </InterviewGuideContainer.Provider>
      </ChatContainer.Provider>
    </InterviewContainer.Provider>
  )
})

// Container が return する値や Redux store の変更、WebSocket からの通知を受けて、
// なんらかの処理をする Hook を呼び出すコンポーネント
const SideEffectResolver: React.FC = React.memo((props) => {
  useCallEnterRpcWhenEntered()

  // WebSocket からのメッセージをハンドリングする
  useStartMessageHandler()
  useFinishMessageHandler()

  // WebSocket のenterイベント喪失補完
  usePollingGetShotageParticipants()

  // 参加者
  useParticipantAddedToast()

  // チャット
  usePlayNotificationSoundWhenChatMessageReceived()

  // network unstable levelのcomlink送信
  useComlinkNetworkUnstableLevel()

  // WebRTC接続状況悪化時のcomlink送信
  useComlinkConnectionStatsSummary()

  // デバイスが抜かれたときのハンドリング
  useCameraUnpluged()
  useMicUnpluged()
  useSpeakerUnpluged()

  return <>{props.children}</>
})

const BizSideEffectResolver: React.VFC = React.memo(() => {
  // 入室リクエスト
  usePlayNotificationSoundWhenRequested()
  // 面接ガイドを開く
  useOpenInterviewGuideAreaOnMount()

  return null
})
