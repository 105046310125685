import React, { useCallback, useEffect, useRef, useState } from 'react'
import { assistant, useLoggedInStaff } from '@blue-agency/im-shared-front'
import {
  CountInterviewTranscriptionsResponse,
  ListInterviewTranscriptionsResponse,
} from '@blue-agency/proton/biz_skywalker_bff'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { Illustration } from '@blue-agency/rogue/im'
import assert from 'assert'
import { useMount } from 'react-use'
import styled from 'styled-components'
import { ButtonBox } from '@/biz/pages/InterviewMinutesPage/Buttons'
import { useRequestListInterviewTranscriptions } from '@/biz/services/bffService'
import { useRequestCountInterviewTranscriptions } from '@/biz/services/bffService/useRequestCountInterviewTranscriptions'
import { useRequestDeleteInterviewTranscriptionPinning } from '@/biz/services/bffService/useRequestDeleteInterviewTranscriptionPinning'
import { useInterviewMinute } from '@/biz/services/interviewMinuteService/useInterviewMinute'
import type {
  ListInterviewTranscription,
  ListInterviewTranscriptionPin,
} from '@/biz/services/interviewMinuteService/useInterviewMinute'
import { Box } from '@/shared/components/Box'
import { DeleteTranscriptionPin } from '@/shared/components/InterviewMinutes'
import { Role } from '@/shared/services/interviewService/containers/InterviewContainer/enterRpcResponseType'

type Props = {
  interviewGuid: string
  isNotStarted: boolean
  isInterviewTranscriptionEnabled: boolean
}

export function InterviewMinutes(props: Props) {
  const { interviewGuid, isNotStarted } = props
  const loggedInStaff = useLoggedInStaff()
  assert(loggedInStaff)

  const { requestListInterviewTranscriptions } = useRequestListInterviewTranscriptions()
  const { requestCountInterviewTranscriptions } = useRequestCountInterviewTranscriptions()
  const { requestDeleteInterviewTranscriptionPinning } = useRequestDeleteInterviewTranscriptionPinning()
  const showTranscription = loggedInStaff.authzType !== assistant
  const [list, setList] = useState<ListInterviewTranscription[]>([])
  const scrollRef = useRef<HTMLDivElement>(null)
  const lastStartTime = useRef<number>(0)
  const lastTranscriptionGuid = useRef<string>('')
  const [showButtonBox, setShowButtonBox] = useState<boolean>(false)
  const [hoveredPinGuid, setHoveredPinGuid] = useState<string | null>(null)
  const [showTranscriptionGuid, setShowTranscriptionGuid] = useState<string>('')
  const [shouldPoling, setShouldPoling] = useState<boolean>(false)
  const { bindTranscriptionsOfSameSpeaker, replaceFillerMessage } = useInterviewMinute()
  const getListInterviewTranscriptions = useCallback(
    async (afterStartTime: number, transcriptionLimit: number): Promise<ListInterviewTranscriptionsResponse> => {
      return await requestListInterviewTranscriptions({ interviewGuid, afterStartTime, transcriptionLimit })
    },
    [interviewGuid, requestListInterviewTranscriptions]
  )

  const transcriptionCount = useCallback(async (): Promise<CountInterviewTranscriptionsResponse> => {
    return await requestCountInterviewTranscriptions({ interviewGuid })
  }, [interviewGuid, requestCountInterviewTranscriptions])

  const reloadList = useCallback(
    async (limit: number) => {
      const res = await getListInterviewTranscriptions(lastStartTime.current, limit)
      const items = res.getInterviewTranscriptionsList()
      const lastGUID = lastTranscriptionGuid.current

      const itemHeight = scrollRef.current !== null ? scrollRef.current.children[0]?.clientHeight : 0
      const isBottom =
        scrollRef.current !== null && itemHeight !== undefined
          ? // スクロールが最下部から文字起こしの列5つ分までは最下部を閲覧しているとして判断する
            scrollRef.current.scrollTop + scrollRef.current.clientHeight + itemHeight * 5 >
            scrollRef.current.scrollHeight
          : false

      const currentScrollTop = scrollRef.current !== null ? scrollRef.current.scrollTop : 0

      setList((list) =>
        bindTranscriptionsOfSameSpeaker([
          ...list,
          ...items
            .filter((item) => item.getGuid() !== lastGUID)
            .map((item): ListInterviewTranscription => {
              const pins: ListInterviewTranscriptionPin[] = item
                .getInterviewTranscriptionPinningsList()
                ?.map((pin) => ({
                  guid: pin.getGuid(),
                  fullName: `${pin.getName()?.getFamilyName() ?? ''} ${pin.getName()?.getGivenName() ?? ''}`,
                  pinType: pin.getType(),
                  staffGuid: pin.getStaffGuid(),
                }))
              return {
                guid: item.getGuid(),
                soraClientId: item.getSoraClientId(),
                startTime: item.getStartTime(),
                endTime: item.getEndTime(),
                message: item.getInterviewTranscriptionSentence(),
                name: item.getParticipant()?.getName()?.slice(0, 2) ?? '',
                iconType: item.getRole() === Role.INTERVIEWER ? 'biz' : 'my',
                pins: pins,
              }
            }),
        ])
      )

      if (!isBottom && scrollRef.current !== null && scrollRef.current.scrollTop !== 0) {
        scrollRef.current.scrollTop = currentScrollTop
        return
      }

      if (scrollRef.current !== null && !res.getIsInterviewFinished()) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight
      }

      lastTranscriptionGuid.current = items[items.length - 1]?.getGuid() ?? ''
      lastStartTime.current = items[items.length - 1]?.getStartTime() ?? 0
    },
    [getListInterviewTranscriptions, lastTranscriptionGuid, lastStartTime, scrollRef, bindTranscriptionsOfSameSpeaker]
  )

  useEffect(() => {
    if (!shouldPoling) return
    const defaultLimit = 100

    const interval = setInterval(() => {
      reloadList(defaultLimit)
    }, 2000)

    return () => clearInterval(interval)
  }, [reloadList, shouldPoling])

  useMount(async () => {
    if (!props.isInterviewTranscriptionEnabled) {
      return
    }
    const res = await transcriptionCount()
    setShouldPoling(!res.getIsInterviewFinished())
    if (res.getTranscriptionsCount() === 0) {
      return
    }
    // ポーリングする必要があれば最初の100件、そうでなければ全件を取得する
    const count = shouldPoling ? 100 : res.getTranscriptionsCount()
    reloadList(count)
  })

  const pushOwnPinToList: (
    transcriptionGuid: string,
    transcriptionPinningGuid: string,
    pinType: ListInterviewTranscriptionsResponse.InterviewPinningType
  ) => void = (
    transcriptionGuid: string,
    transcriptionPinningGuid: string,
    pinType: ListInterviewTranscriptionsResponse.InterviewPinningType
  ) => {
    setList((list) =>
      list.map((item) => {
        if (item.guid !== transcriptionGuid) return item
        return {
          ...item,
          pins: [
            ...item.pins,
            {
              guid: transcriptionPinningGuid,
              fullName: `${loggedInStaff.name.familyName} ${loggedInStaff.name.givenName}`,
              pinType: pinType,
              staffGuid: loggedInStaff.guid,
            },
          ],
        }
      })
    )
  }

  const popDeletePinFromList: (transcriptionGuid: string, transcriptionPinningGuid: string) => void = (
    transcriptionGuid: string,
    transcriptionPinningGuid: string
  ) => {
    setList((list) =>
      list.map((item) => {
        if (item.guid !== transcriptionGuid) return item
        return {
          ...item,
          pins: item.pins.filter((pin) => pin.guid !== transcriptionPinningGuid),
        }
      })
    )
  }

  return (
    <Wrapper>
      <Header>
        <Txt size="s" color={theme.color.navy[1]} bold>
          文字起こしβ版
        </Txt>
      </Header>
      {showTranscription ? (
        <>
          {list.length > 0 ? (
            <ScrollBox pt="16px" px="12px" ref={scrollRef}>
              {list.map((transcription) => (
                <TranscriptionListItem key={transcription.guid}>
                  <NameIcon iconType={transcription.iconType}>{transcription.name}</NameIcon>
                  <TranscriptionListItemBody>
                    <TranscriptionTextWrapper
                      onMouseEnter={() => {
                        setShowButtonBox(true)
                        setShowTranscriptionGuid(transcription.guid)
                      }}
                      onMouseLeave={() => setShowButtonBox(false)}
                    >
                      {showButtonBox && transcription.guid === showTranscriptionGuid && (
                        <ButtonBox
                          interviewGuid={interviewGuid}
                          transcriptionGuid={transcription.guid}
                          onIconClick={pushOwnPinToList}
                        />
                      )}
                      <TranscriptionText>{replaceFillerMessage(transcription.message)}</TranscriptionText>
                    </TranscriptionTextWrapper>
                    {transcription.pins.length > 0 && (
                      <PinWrapper>
                        {transcription.pins.map((pin, index) => (
                          <Pin
                            key={index}
                            onMouseEnter={() => {
                              if (pin.staffGuid === loggedInStaff.guid) {
                                setHoveredPinGuid(pin.guid)
                              }
                            }}
                            onMouseLeave={() => {
                              setHoveredPinGuid(null)
                            }}
                          >
                            <PinIcon type={pin.pinType} />
                            <FullNameTxt>{pin.fullName}</FullNameTxt>
                            {pin.guid === hoveredPinGuid && (
                              <DeleteTranscriptionPin
                                transcriptionGuid={transcription.guid}
                                transcriptionPinningGuid={pin.guid}
                                onIconClick={popDeletePinFromList}
                                requestDeleteInterviewTranscriptionPinning={requestDeleteInterviewTranscriptionPinning}
                              ></DeleteTranscriptionPin>
                            )}
                          </Pin>
                        ))}
                      </PinWrapper>
                    )}
                  </TranscriptionListItemBody>
                </TranscriptionListItem>
              ))}
            </ScrollBox>
          ) : isNotStarted ? (
            <ErrorBox>
              <Txt size="m">面接開始後に文字起こしが表示されます</Txt>
            </ErrorBox>
          ) : (
            <OptionsTextWrapper>
              <Txt size="m">※文字起こし機能はオプション機能です。</Txt>
              <Txt>お申込がお済みでないお客様には文字起こしが表示されません。</Txt>
            </OptionsTextWrapper>
          )}
        </>
      ) : (
        <ErrorBox>
          <PermissionDeniedIllustration />
          <Txt size="m">文字起こしデータを閲覧する権限がありません</Txt>
        </ErrorBox>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${theme.color.navy[1]};
  padding-bottom: 16px;
`

const Header = styled.div`
  display: flex;
  align-content: center;
  height: 34px;
  padding: 8px;
  background-color: ${theme.color.navy[3]};
  border-bottom: 1px solid ${theme.color.gray[3]};
`

const ScrollBox = styled(Box)`
  overflow: scroll;
  height: calc(100vh - 45.2px - 32px);
  overflow-x: hidden;
`

const OptionsTextWrapper = styled.div`
  height: 50px;
  padding: 12px;
`

const ErrorBox = styled(Box)`
  padding-top: 20px;
  text-align: center;
`

const PermissionDeniedIllustration = styled(Illustration).attrs({ name: 'permission-denied' })`
  width: 300px;
`

const TranscriptionListItem = styled(Box)`
  min-height: 28px;
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 4px;
`

const TranscriptionListItemIcon = styled.div`
  font-size: 12px;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  text-align: center;
  display: inline-grid;
  align-items: center;
  user-select: none;
  flex-shrink: 0;
`

const NameIcon = styled(TranscriptionListItemIcon)<{ iconType: ListInterviewTranscription['iconType'] }>`
  color: ${({ iconType }) => (iconType === 'my' ? theme.color.navy[1] : theme.color.white[1])};
  background: ${({ iconType }) => (iconType === 'my' ? theme.color.navy[3] : theme.color.navy[1])};
`

const TranscriptionTextWrapper = styled.div`
  width: 100%;
  position: relative;
`

const TranscriptionListItemBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
`

const TranscriptionText = styled(Txt).attrs({ size: 'm' })`
  &:hover {
    background-color: ${theme.color.gray[4]};
  }

  span {
    display: inline-block;
    color: ${theme.color.gray[2]};
    background-color: ${theme.color.gray[4]};
  }
`

const Pin = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background-color: ${theme.color.gray[4]};
  }
`

const PinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`

type PinProps = {
  type: ListInterviewTranscriptionsResponse.InterviewPinningType
}

const PinIcon: React.FC<PinProps> = ({ type }) => {
  switch (type) {
    case ListInterviewTranscriptionsResponse.InterviewPinningType.GOOD:
      return <GoodPin />
    case ListInterviewTranscriptionsResponse.InterviewPinningType.BAD:
      return <BadPin />
    case ListInterviewTranscriptionsResponse.InterviewPinningType.QUESTION:
      return <QuestionPin />
  }
  return null
}

const GoodPin = styled(Icon).attrs({ name: 'good-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.blue[1]};
`

const BadPin = styled(Icon).attrs({ name: 'bad-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.red[2]};
`

const QuestionPin = styled(Icon).attrs({ name: 'question-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.orange[1]};
`

const FullNameTxt = styled(Txt).attrs({ size: 's' })`
  color: ${theme.color.gray[1]};
`
