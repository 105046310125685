import { ListMessagesResponse } from '@blue-agency/proton/c3po'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import invariant from 'tiny-invariant'
import { assertIsDefined, assertNever } from '@/assertions'
import { SignalingNotifyConnectionCreated, SignalingNotifyConnectionDestroyed } from '@/lib/interview-sdk-js'
import { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { timestampToDate } from '../../bffService'
import { ConnectionStatsSummary } from '../ConnectionStatsSummary'
import { interviewRoleToChatRoleMap } from '../chatRole'
import { getSavedSelfViewVisibility, SelfViewVisibility } from '../selfViewVisibility'
import type {
  ChatMessage,
  InterviewState,
  Metadata,
  NetworkStatusNotification,
  ParticipantWithoutRole,
  UnstableLevel,
  SoraSignalingErrorReason,
  SoraSignalingStatus,
  SoraClientId,
  TileId,
  ViewMode,
} from '../types'
import { ensureMetadata, ensureRawMetadata, isScreenShare } from '../types'

type MetadataWithIsDestroyed = Metadata & {
  isDestroyed?: true
}

type MetadataMap = Record<SoraClientId, MetadataWithIsDestroyed>
type MicMutedMap = Record<SoraClientId, boolean>
type NetworkUnstableLevelMap = Record<SoraClientId, UnstableLevel>

const LS_KEY_INTERVIEW_AUTO_QUESTION_AREA_OPEN = 'interviewAutoQuestionAreaOpen'
const IS_MINUTES_VIEW = 'isMinutesView'

export type DataChannelStatus = 'Pending' | 'Completed'

type State = {
  interviewState: InterviewState
  ownSoraClientId?: SoraClientId
  isSignalingCompleted: boolean
  // 自分より先に入室していた参加者について、参加時のトーストを表示しないように取っておく
  // Enter Rpc Response の participants が入る
  // Enter Rpc 完了前は undefined とする
  soraClientIdEnteredBeforeMeList?: SoraClientId[]
  userSignalingStatus: SoraSignalingStatus
  dataChannelStatus: DataChannelStatus
  screenShareRecvSignalingStatus: SoraSignalingStatus
  participantWithoutRoleList: ParticipantWithoutRole[]
  remoteTiles: Record<SoraClientId, TileId>
  isRunningOwnScreenShare: boolean
  chatRoomGuid?: string
  chatMessages: ChatMessage[]
  isChatWebSocketConnected: boolean
  metadataMap: MetadataMap
  micMutedMap: MicMutedMap
  isOwnMicMuted: boolean
  isOwnCameraMuted: boolean
  isUnavailableVideoInput: boolean
  isUnavailableAudioInput: boolean
  networkUnstableLevel?: UnstableLevel
  selfViewVisibility: SelfViewVisibility
  isChatAreaOpen: boolean
  isInterviewGuideAreaOpen: boolean
  isInterviewAutoQuestionAreaOpen: boolean
  isMinutesView: boolean
  hasInterviewGuide: boolean
  viewMode: ViewMode
  connectionStatsSummary: ConnectionStatsSummary
  networkUnstableLevelMap: NetworkUnstableLevelMap
  isInterviewPinningAreaOpen: boolean
}

const initialState: State = {
  interviewState: 'NotStarted',
  ownSoraClientId: undefined,
  isSignalingCompleted: false,
  soraClientIdEnteredBeforeMeList: undefined,
  userSignalingStatus: 'Pending',
  dataChannelStatus: 'Pending',
  screenShareRecvSignalingStatus: 'Pending',
  participantWithoutRoleList: [],
  remoteTiles: {},
  isRunningOwnScreenShare: false,
  chatRoomGuid: undefined,
  chatMessages: [],
  isChatWebSocketConnected: false,
  metadataMap: {},
  micMutedMap: {},
  isOwnMicMuted: false,
  isOwnCameraMuted: false,
  isUnavailableVideoInput: false,
  isUnavailableAudioInput: false,
  networkUnstableLevel: undefined,
  selfViewVisibility: getSavedSelfViewVisibility(),
  isChatAreaOpen: false,
  // biz の場合は、マウント時に BizSizeEffectResolver によって true になる
  isInterviewGuideAreaOpen: false,
  // 最初に訪れた人にはIm assistantを見せたいのでOFFにしていない(lsにfalseが入っていない)かで判断する
  isInterviewAutoQuestionAreaOpen: localStorage.getItem(LS_KEY_INTERVIEW_AUTO_QUESTION_AREA_OPEN) !== 'false',
  isMinutesView: localStorage.getItem(IS_MINUTES_VIEW) === 'true',
  // biz かつ 面接に面接ガイドが紐付いている場合に BizSideEffectResolver によって true になる
  hasInterviewGuide: false,
  viewMode: { type: 'weak_pinned' },
  connectionStatsSummary: {},
  networkUnstableLevelMap: {},
  isInterviewPinningAreaOpen: false,
}

type EnterRpcCallSucceededPayload = {
  participants: Array<{
    soraClientId: SoraClientId
    name: string
  }>
  chatRoomGuid: string
  chatMessages: ChatMessage[]
}

// TODO: baseSlice とかに名前変える
export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    userSignalingSucceeded: (state, { payload }: PayloadAction<{ soraClientId: SoraClientId }>) => {
      state.userSignalingStatus = 'Completed'
      state.ownSoraClientId = payload.soraClientId

      // userSignalingSucceeded が実行されているタイミングでは、metadataMap などの準備が完了しているはずであるため
      // それに基づいてデフォルトのビューを選択する
      state.viewMode = getDefaultViewMode(state)
    },
    resetSignalingStatus: (state) => {
      state.userSignalingStatus = 'Pending'
      state.ownSoraClientId = undefined
      state.dataChannelStatus = 'Pending'
    },
    userSignalingFailed: (state, { payload }: PayloadAction<SoraSignalingErrorReason>) => {
      state.userSignalingStatus = payload
    },
    userSignalingDisconnected: (state) => {
      state.userSignalingStatus = 'Disconnected'
    },
    dataChannelConnected: (state) => {
      state.dataChannelStatus = 'Completed'
    },
    dataChannelDisconnected: (state) => {
      state.dataChannelStatus = 'Pending'
    },
    screenShareRecvSignalingSucceeded: (state, { payload }: PayloadAction<{ soraClientId: SoraClientId }>) => {
      state.screenShareRecvSignalingStatus = 'Completed'
    },
    screenShareRecvSignalingFailed: (state, { payload }: PayloadAction<SoraSignalingErrorReason>) => {
      state.screenShareRecvSignalingStatus = payload
    },
    screenShareRecvSignalingDisconnected: (state) => {
      state.screenShareRecvSignalingStatus = 'Disconnected'
    },
    startMessageReceived: (state, action: PayloadAction<WebSocketMessageMap['start']>) => {
      state.interviewState = 'InRunning'
    },
    participantEntered: (state, { payload }: PayloadAction<{ soraClientId: string; name: string }>) => {
      if (payload.soraClientId !== state.ownSoraClientId) {
        state.participantWithoutRoleList.push({ soraClientId: payload.soraClientId, name: payload.name })
      }
    },
    remoteTileUpdated: (state, { payload }: PayloadAction<{ tileId: TileId; soraClientId: SoraClientId }>) => {
      state.remoteTiles[payload.soraClientId] = payload.tileId
    },
    remoteTileRemoved: (state, { payload }: PayloadAction<{ tileId: TileId }>) => {
      const remoteTiles = Object.entries(state.remoteTiles).filter(([_, v]) => v !== payload.tileId)
      state.remoteTiles = Object.fromEntries(remoteTiles)
    },
    enterRpcCallSucceeded: (state, { payload }: PayloadAction<EnterRpcCallSucceededPayload>) => {
      state.participantWithoutRoleList = payload.participants
      state.soraClientIdEnteredBeforeMeList = payload.participants
        .map((p) => p.soraClientId)
        .filter((soraClientId) => soraClientId !== state.ownSoraClientId)
      state.chatRoomGuid = payload.chatRoomGuid
      state.chatMessages = payload.chatMessages
    },
    otherParticipantLeft: (state, { payload }: PayloadAction<{ soraClientId: SoraClientId }>) => {
      const newParticipants = state.participantWithoutRoleList.filter((p) => p.soraClientId !== payload.soraClientId)
      state.participantWithoutRoleList = newParticipants
    },
    connectionCreatedNotificationReceived: (state, { payload }: PayloadAction<SignalingNotifyConnectionCreated>) => {
      assertIsDefined(payload.client_id)
      if (!ensureRawMetadata(payload.metadata)) {
        throw new Error('Bad connection created notification payload')
      }

      // interview_role = 'recruiter_screen_recv', 'applicant_screen_recv' の場合は metadataMap に追加しない
      if (ensureMetadata(payload.metadata)) {
        state.metadataMap[payload.client_id] = payload.metadata
      }

      // NOTE: metadata_listからdataへ移行される。サーバー側移行で動作するよう両方参照しておく。
      // 移行が終わったらmetadata_listへの参照を削除する。
      // https://sora-doc.shiguredo.jp/DEPRECATED#8a323e
      payload.metadata_list?.forEach((item) => {
        assertIsDefined(item.client_id)
        if (!ensureRawMetadata(item.metadata) || !ensureMetadata(item.metadata)) {
          return
        }
        state.metadataMap[item.client_id] = item.metadata
      })
      payload.data?.forEach((item) => {
        assertIsDefined(item.client_id)
        if (!ensureRawMetadata(item.metadata) || !ensureMetadata(item.metadata)) {
          return
        }
        state.metadataMap[item.client_id] = item.metadata
      })

      // 画面共有だったら"強いピン留め"をする
      const screenShare = isScreenShare(payload.metadata.interview_role)
      const tileId = state.remoteTiles[payload.client_id]
      if (screenShare && tileId) {
        state.viewMode = {
          type: 'strong_pinned',
          tileId,
        }
      }

      if (state.viewMode.type === 'weak_pinned') {
        const participantsInfo = getParticipantsInfo(state)
        const ownMetadata = getOwnMetadata(state)
        if (participantsInfo !== undefined && ownMetadata !== undefined) {
          const { sameRole, otherRole } = participantsInfo

          // 相手ロール2人目以降の入室のとき、タイルビューに切り替える
          if (otherRole >= 2 && payload.metadata?.interview_role !== ownMetadata.interview_role) {
            state.viewMode = { type: 'tile_view' }
          }

          // 自ロール4人目以降の入室のとき、タイルビューに切り替える
          if (sameRole >= 4 && payload.metadata?.interview_role === ownMetadata.interview_role) {
            state.viewMode = { type: 'tile_view' }
          }
        }
      }
    },
    connectionDestroyedNotificationReceived: (
      state,
      { payload }: PayloadAction<SignalingNotifyConnectionDestroyed>
    ) => {
      if (!ensureRawMetadata(payload.metadata)) {
        throw new Error('Bad connection destroyed notification payload')
      }
      assertIsDefined(payload.client_id)

      // interview_role = 'recruiter_screen_recv', 'applicant_screen_recv' の場合はReduxの状態を変える必要はないためearly returnする
      if (!ensureMetadata(payload.metadata)) {
        return
      }

      const metadata = payload.metadata

      // viewMode更新の前に、metadataMap を更新する
      if (payload.client_id in state.metadataMap) {
        // metadataMap はチャットの role 表示に使うので、画面共有のものだけ削除する
        // この値をもとに現在、画面共有されているか算出するので削除する
        if (metadata.interview_role === 'applicant_screen' || metadata.interview_role === 'recruiter_screen') {
          delete state.metadataMap[payload.client_id!]
        } else {
          state.metadataMap[payload.client_id!] = {
            ...metadata,
            isDestroyed: true,
          }
        }
      }

      // ピン留めされている人(or 画面共有)が退室した場合は、ViewModeをデフォルトに戻す
      const pinnedParticipantHasLeft =
        state.viewMode.type === 'strong_pinned' && state.viewMode.tileId === state.remoteTiles[payload.client_id]
      if (pinnedParticipantHasLeft) {
        state.viewMode = getDefaultViewMode(state)
      }
    },
    resetMetadata: (state) => {
      state.metadataMap = {}
    },
    networkStatusNotificationReceived: (state, { payload }: PayloadAction<NetworkStatusNotification>) => {
      state.networkUnstableLevel = payload.unstable_level
    },
    chatMessageReceived: (state, { payload }: PayloadAction<ListMessagesResponse.ChatMessage.AsObject>) => {
      const participant = state.participantWithoutRoleList.find((p) => p.soraClientId === payload.userGuid)
      invariant(participant)

      const metadata = state.metadataMap[payload.userGuid]
      invariant(metadata)

      const chatMessage: ChatMessage = {
        messageGuid: payload.messageGuid,
        participant: {
          soraClientId: payload.userGuid,
          name: participant.name,
          role: interviewRoleToChatRoleMap[metadata.interview_role],
        },
        text: payload.text,
        createTime: timestampToDate(payload.createdAt, true),
      }

      state.chatMessages.push(chatMessage)
    },
    ownScreenShareStarted: (state) => {
      state.isRunningOwnScreenShare = true
    },
    ownScreenShareFinished: (state) => {
      state.isRunningOwnScreenShare = false
    },
    chatWebSocketConnected: (state) => {
      state.isChatWebSocketConnected = true
    },
    chatWebSocketClosed: (state) => {
      state.isChatWebSocketConnected = false
    },
    micMutedChanged: (state, { payload }: PayloadAction<boolean>) => {
      invariant(state.ownSoraClientId)

      state.isOwnMicMuted = payload
      state.micMutedMap[state.ownSoraClientId] = payload
    },
    muteMessageReceived: (state, { payload }: PayloadAction<{ soraClientId: string; muted: boolean }>) => {
      // 自分のも DataChannel からの変更で受け付けると、変更前に送った muted 状態とクライアントの状態にズレが発生して
      // muted が高速で切り替わることがあったため、自分の状態は DataChannel のメッセージによって変更しない
      if (payload.soraClientId !== state.ownSoraClientId) {
        state.micMutedMap[payload.soraClientId] = payload.muted
      }
    },
    beforeEnteredOwnMicMutedChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isOwnMicMuted = payload
    },
    // 入室時のマイクのミュート状態を入室前や再接続前のマイクのミュート状態と同じにする
    enteringInitialMicMutedChanged: (state) => {
      invariant(state.ownSoraClientId)

      const muted = state.micMutedMap[state.ownSoraClientId] ?? state.isOwnMicMuted
      state.micMutedMap[state.ownSoraClientId] = muted
    },
    ownCameraMutedChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isOwnCameraMuted = payload
    },
    unavailableVideoInputChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isUnavailableVideoInput = payload
    },
    unavailableAudioInputChanged: (state, { payload }: PayloadAction<boolean>) => {
      state.isUnavailableAudioInput = payload
    },
    hideSelfView: (state) => {
      state.selfViewVisibility = 'hidden'
    },
    showSelfView: (state) => {
      state.selfViewVisibility = 'visible'
    },
    toggleChatAreaOpen: (
      state,
      { payload }: PayloadAction<{ shouldClosePrivateMemoAreaOrInterviewGuideArea: boolean }>
    ) => {
      state.isChatAreaOpen = !state.isChatAreaOpen
      if (payload.shouldClosePrivateMemoAreaOrInterviewGuideArea) {
        state.isInterviewGuideAreaOpen = false
      }
    },
    closeChatArea: (state) => {
      state.isChatAreaOpen = false
    },
    toggleInterviewGuideAreaOpen: (state, { payload }: PayloadAction<{ shouldCloseChatArea: boolean }>) => {
      state.isInterviewGuideAreaOpen = !state.isInterviewGuideAreaOpen
      if (payload.shouldCloseChatArea) {
        state.isChatAreaOpen = false
      }
    },
    toggleInterviewAutoQuestionAreaOpen: (state, { payload }: PayloadAction<{ shouldCloseChatArea: boolean }>) => {
      state.isInterviewAutoQuestionAreaOpen = !state.isInterviewAutoQuestionAreaOpen
      localStorage.setItem(
        LS_KEY_INTERVIEW_AUTO_QUESTION_AREA_OPEN,
        state.isInterviewAutoQuestionAreaOpen ? 'true' : 'false'
      )
      if (payload.shouldCloseChatArea) {
        state.isChatAreaOpen = false
      }
    },
    toggleMinutesView: (state) => {
      state.isMinutesView = !state.isMinutesView
      localStorage.setItem(IS_MINUTES_VIEW, state.isMinutesView ? 'true' : 'false')
    },
    closeInterviewGuideArea: (state) => {
      state.isInterviewGuideAreaOpen = false
    },
    closeAutoQuestionArea: (state) => {
      state.isInterviewAutoQuestionAreaOpen = false
      localStorage.setItem(LS_KEY_INTERVIEW_AUTO_QUESTION_AREA_OPEN, 'false')
    },
    openInterviewGuideArea: (state) => {
      state.isInterviewGuideAreaOpen = true
    },
    hasInterviewGuide: (state) => {
      state.hasInterviewGuide = true
    },
    pinVideo: (state, { payload }: PayloadAction<{ tileId: TileId }>) => {
      state.viewMode = {
        type: 'strong_pinned',
        tileId: payload.tileId,
      }
    },
    toggleVideoPinState: (state, { payload }: PayloadAction<{ tileId: TileId; mainVideoContentTileId?: TileId }>) => {
      switch (state.viewMode.type) {
        case 'strong_pinned': {
          if (state.viewMode.tileId === payload.tileId) {
            state.viewMode = {
              type: 'tile_view',
            }
          } else {
            state.viewMode = {
              type: 'strong_pinned',
              tileId: payload.tileId,
            }
          }
          return
        }
        case 'weak_pinned': {
          // MainVideoとして表示されているものをクリックしたときにはタイルビューに移行する
          // それ以外がクリックされたときは強いピン留めに移行する
          if (payload.mainVideoContentTileId === payload.tileId) {
            state.viewMode = {
              type: 'tile_view',
            }
          } else {
            state.viewMode = {
              type: 'strong_pinned',
              tileId: payload.tileId,
            }
          }
          return
        }
        case 'tile_view': {
          state.viewMode = {
            type: 'strong_pinned',
            tileId: payload.tileId,
          }
          return
        }
        default: {
          assertNever(state.viewMode)
        }
      }
    },
    updateConnectionStatsSummary: (state, { payload }: PayloadAction<ConnectionStatsSummary>) => {
      state.connectionStatsSummary = payload
    },
    unstableLevelMessageReceived: (
      state,
      { payload }: PayloadAction<{ soraClientId: string; unstableLevel: UnstableLevel }>
    ) => {
      if (payload.soraClientId !== state.ownSoraClientId) {
        state.networkUnstableLevelMap[payload.soraClientId] = payload.unstableLevel
      }
    },
    toggleInterviewPinningAreaOpen: (state, { payload }: PayloadAction<{ shouldCloseChatArea: boolean }>) => {
      state.isInterviewPinningAreaOpen = !state.isInterviewPinningAreaOpen
      if (payload.shouldCloseChatArea) {
        state.isChatAreaOpen = false
      }
    },
  },
})

type ParticipantsInfo = {
  sameRole: number
  otherRole: number
  screenShare: { share: false } | { share: true; soraClientId: SoraClientId }
}

function getDefaultViewMode(state: State): ViewMode {
  const participantsInfo = getParticipantsInfo(state)
  if (participantsInfo === undefined) {
    return {
      type: 'weak_pinned',
    }
  }

  const { sameRole, otherRole, screenShare } = participantsInfo

  // https://blue-agency.atlassian.net/browse/IMLD-670
  // の "入室時" ロジックに従ってビューモードを判定

  if (sameRole <= 3 && otherRole <= 1) {
    if (screenShare.share) {
      const tileId = state.remoteTiles[screenShare.soraClientId]
      return tileId === undefined ? { type: 'weak_pinned' } : { type: 'strong_pinned', tileId }
    } else {
      return { type: 'weak_pinned' }
    }
  }

  if (screenShare.share) {
    const tileId = state.remoteTiles[screenShare.soraClientId]
    return tileId === undefined ? { type: 'weak_pinned' } : { type: 'strong_pinned', tileId }
  }

  return {
    type: 'tile_view',
  }
}

function getParticipantsInfo(state: State): ParticipantsInfo | undefined {
  const ownMetadata = getOwnMetadata(state)
  if (ownMetadata === undefined) {
    return undefined
  }

  return Object.entries(state.metadataMap)
    .filter(([, meta]) => meta.isDestroyed === undefined)
    .reduce(
      (prev, [clientId, metadata]) => {
        if (isScreenShare(metadata.interview_role)) {
          return {
            ...prev,
            screenShare: {
              share: true,
              soraClientId: clientId,
            },
          }
        } else if (metadata.interview_role === ownMetadata?.interview_role) {
          return {
            ...prev,
            sameRole: prev.sameRole + 1,
          }
        } else {
          return {
            ...prev,
            otherRole: prev.otherRole + 1,
          }
        }
      },
      { sameRole: 0, otherRole: 0, screenShare: { share: false } } as ParticipantsInfo
    )
}

function getOwnMetadata(state: State): MetadataWithIsDestroyed | undefined {
  if (state.ownSoraClientId === undefined) {
    return undefined
  }

  return state.metadataMap[state.ownSoraClientId]
}
