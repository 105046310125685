import { SharedState } from '@/shared/services/interviewService/redux'
import { createStore } from '@/shared/services/interviewService/redux/createStore'
import { mySlice } from './mySlice'

export type RootState = SharedState & {
  my: ReturnType<typeof mySlice.reducer>
}

export const store = createStore({
  my: mySlice.reducer,
})
