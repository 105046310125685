import React from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, SecondaryDangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type Props = {
  active: boolean
  onClose: () => void
  onFinish: () => void
}

export const FinishScreenShareModal: React.VFC<Props> = ({ active, onClose, onFinish }) => {
  return (
    <Modal active={active} title="画面共有終了の確認" onClose={onClose} size="s">
      <Wrapper>
        <Upper>
          <Txt>画面共有を終了してもいいですか？</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <ButtonGroup>
            <CancelButton
              onClick={onClose}
              comlinkPushParams={{
                action: 'click_close_screen_share_modal_button',
              }}
            >
              キャンセル
            </CancelButton>
            <FinishButton onClick={onFinish} comlinkPushParams={{ action: 'click_finish_screen_share_button' }}>
              画面共有を終了
            </FinishButton>
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 40px;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const CancelButton = styled(TertiaryButton).attrs({ widthSize: 'MAX' })`
  width: 48%;
`
const FinishButton = styled(SecondaryDangerButton).attrs({ widthSize: 'MAX' })`
  width: 48%;
`
