import React, { useContext } from 'react'
import { Icon, Responsive, theme, useIsIPad } from '@blue-agency/rogue'
import ReactDOM from 'react-dom'
import styled, { css, ThemeContext } from 'styled-components'
import { NotificationDot as _NotificationDot } from '@/shared/components/NotificationDot'
import type { DropdownBasePosition } from '@/shared/hooks/useDropdown'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { buttonResetStyle } from '@/shared/styles/buttonResetStyle'
import { useMenuDropdown } from '../hooksForUi/useMenuDropdown'
import { Dropdown as _Dropdown } from './Dropdown'
import { CameraMuteButton } from './controlButtons/CameraMuteButton'
import { ChatButton } from './controlButtons/ChatButton'
import { MicMuteButton } from './controlButtons/MicMuteButton'

export const MobileControls: React.VFC = React.memo(() => {
  const { responsive } = useContext(ThemeContext)
  const { isIPad } = useIsIPad()
  const { appType } = InterviewContainer.useContainer()
  const isMy = appType === 'my'
  return <MobileControlsConsumer responsive={responsive} isIPad={isIPad} isMy={isMy} />
})

type ConsumerProps = {
  responsive: Responsive
  isIPad: boolean
  isMy: boolean
}

const menuDropdownWidth = 250

const MobileControlsConsumer: React.VFC<ConsumerProps> = React.memo(({ responsive, isIPad, isMy }) => {
  const {
    dropdownItems,
    dropdownRef,
    dropdownOpenerRef,
    isDropdownShown,
    basePosition,
    toggleDropdownShown,
    showingTroubleShootingNotification,
    renderReloadModal,
    renderUnstableConnectionModal,
    renderQualityModeModal,
    renderUnstableConnectionWarningBalloon,
  } = useMenuDropdown()

  return (
    <Wrapper>
      <LeftSpace />
      <Middle isIPad={isIPad}>
        <ChatButton />
        <MicMuteButton />
        <CameraMuteButton />
      </Middle>
      <Right>
        <DropdownOpener ref={dropdownOpenerRef}>
          <MenuButton onClick={toggleDropdownShown}>
            <MenuIcon />
          </MenuButton>
          {showingTroubleShootingNotification && <NotificationDot />}
        </DropdownOpener>
        {isDropdownShown &&
          basePosition &&
          ReactDOM.createPortal(
            <Dropdown ref={dropdownRef} items={dropdownItems} basePosition={basePosition} width={menuDropdownWidth} />,
            document.getElementById('root')!
          )}
        {ReactDOM.createPortal(renderUnstableConnectionWarningBalloon(), document.getElementById('root')!)}
        {renderReloadModal()}
        {renderUnstableConnectionModal()}
        {renderQualityModeModal()}
      </Right>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;

  ${({ theme: t }) => {
    if (t.responsive.spLandscape) {
      return css`
        flex-direction: column;
      `
    }
  }}
`

const LeftSpace = styled.div`
  ${({ theme: t }) => {
    if (t.responsive.spPortrait) {
      return css`
        width: 60px;
        height: 100%;
      `
    }
    if (t.responsive.spLandscape) {
      return css`
        width: 100%;
        height: 58px;
      `
    }
  }}
`

const Middle = styled.div<{ isIPad: boolean }>`
  display: flex;
  justify-content: center;

  ${({ theme: t, isIPad }) => {
    if (t.responsive.spPortrait) {
      return css`
        flex-direction: row;

        > *:not(:first-child) {
          margin-left: 28px;
        }

        & > * {
          width: 74px;
          height: 100%;
        }
      `
    }
    if (t.responsive.spLandscape) {
      if (isIPad) {
        return css`
          flex-direction: column;

          > *:not(:first-child) {
            margin-top: 14px;
          }

          & > * {
            width: 100%;
            height: 48px;
          }
        `
      }
      return css`
        flex-direction: column;

        > *:not(:first-child) {
          margin-top: 6px;
        }

        & > * {
          width: 100%;
          height: 48px;
        }
      `
    }
  }}
`

const Right = styled.div`
  ${({ theme: t }) => {
    if (t.responsive.spPortrait) {
      return css`
        width: 60px;
        height: 100%;
        min-width: 60px;
      `
    }
    if (t.responsive.spLandscape) {
      return css`
        width: 100%;
        height: 58px;
        min-height: 58px;
      `
    }
  }}
`

const MenuButton = styled.button`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${theme.color.navy[1]};
`

const MenuIcon = styled(Icon).attrs({ name: 'more-horizontal', width: '24px', height: '24px' })`
  width: 21px;
  height: 21px;
`

const DropdownOpener = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
`

type DropdownProps = {
  basePosition: DropdownBasePosition
}

const Dropdown = styled(_Dropdown)<DropdownProps>`
  ${({ theme, basePosition }) => {
    if (theme.responsive.spPortrait) {
      return css`
        position: fixed;
        bottom: calc(100% - ${basePosition.top}px);
        right: calc(100% - ${basePosition.right}px);
      `
    }
    if (theme.responsive.spLandscape) {
      return css`
        position: fixed;
        bottom: calc(100% - ${basePosition.bottom}px);
        right: calc(100% - ${basePosition.left}px);
      `
    }
  }}
`
const NotificationDot = styled(_NotificationDot)`
  position: absolute;
  top: 16px;
  right: 12px;
`
