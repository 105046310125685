import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ChatMessageForm } from '@/shared/services/interviewService/components/ChatList/ChatMessageForm'
import { MinutesViewChatMessageComponent } from '@/shared/services/interviewService/components/ChatList/MinutesViewChatMessageComponent'
import { useScrollToBottom } from '@/shared/services/interviewService/components/ChatList/useScrollToBottom'
import { SharedState } from '@/shared/services/interviewService/redux'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'

export const MinutesViewChatList: React.VFC = React.memo(() => {
  const chatMessages = useSelector((state: SharedState) => state.shared.chatMessages)
  const { messageContainerRef } = useScrollToBottom()

  return (
    <Wrapper>
      <Header>
        <Txt size="s" color={theme.color.navy[1]} bold>
          チャット
        </Txt>
      </Header>
      <Inner ref={messageContainerRef}>
        {chatMessages.map((m) => (
          <MinutesViewChatMessageComponent key={m.messageGuid} message={m} />
        ))}
      </Inner>
      <FormWrapper>
        <ChatMessageForm />
      </FormWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 2px solid ${theme.color.navy[1]};
  border-left: 1px solid ${theme.color.gray[3]};
  padding-bottom: 16px;
`

const Header = styled.div`
  display: flex;
  align-content: center;
  height: 34px;
  padding: 8px;
  background-color: ${theme.color.navy[3]};
  border-bottom: 1px solid ${theme.color.gray[3]};
`

const Inner = styled.div`
  ${overflowYScrollStyle};
  flex: 1;
`

const FormWrapper = styled.div`
  padding: 12px 2px 6px 4px;
  border-top: 1px solid #eeeeee;
`
