import { useCallback, useMemo, useState, memo, ComponentProps, ReactNode, RefObject } from 'react'
import {
  Modal,
  TertiaryButton as OldTertiaryButton,
  theme,
  WeakColorSeparator,
  Icon,
  Txt,
  ErrorBox,
} from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { Box } from '@/shared/components/Box'
import { BackgroundEffect } from '@/shared/services/interviewService/types'
import { Caution } from './Caution'
import { UserImage } from './UserImage'
import { useSelectImage } from './useSelectImage'

export const BackgroundSettingModal: React.VFC<{
  active: boolean
  onClose: () => void
  appType: 'biz' | 'my'
  videoPreview: ReactNode
  backgroundEffect: BackgroundEffect | undefined
  onChangeBackgroundEffect: (backgroundEffect: BackgroundEffect) => void
  backgroundUserImage: string | undefined
  saveBackgroundUserImage: (uri: string | undefined) => void
}> = ({
  active,
  onClose: _onClose,
  appType,
  videoPreview,
  backgroundEffect,
  onChangeBackgroundEffect,
  backgroundUserImage,
  saveBackgroundUserImage,
}) => {
  const selectedNoEffect = useMemo(() => {
    return backgroundEffect === undefined || backgroundEffect === 'no-effect'
  }, [backgroundEffect])
  const selectedBlurWeak = useMemo(() => {
    return backgroundEffect === 'blur-weak'
  }, [backgroundEffect])
  const selectedBlurStrong = useMemo(() => {
    return backgroundEffect === 'blur-strong'
  }, [backgroundEffect])
  const selectedUserImage = useMemo(() => {
    return backgroundEffect === 'user-image'
  }, [backgroundEffect])

  const onSelectNoEffect = useCallback(() => {
    onChangeBackgroundEffect('no-effect')
  }, [onChangeBackgroundEffect])

  const onSelectBlurWeak = useCallback(() => {
    onChangeBackgroundEffect('blur-weak')
  }, [onChangeBackgroundEffect])

  const onSelectBlurStrong = useCallback(() => {
    onChangeBackgroundEffect('blur-strong')
  }, [onChangeBackgroundEffect])

  const onSelectUserImage = useCallback(() => {
    onChangeBackgroundEffect('user-image')
  }, [onChangeBackgroundEffect])

  const clearBackgroundUserImage = useCallback(() => {
    saveBackgroundUserImage(undefined)
  }, [saveBackgroundUserImage])

  const [errMsg, setErrMsg] = useState('')
  const clearErrMsg = useCallback(() => {
    setErrMsg('')
  }, [])
  const onFileSizeLimitExceeded = useCallback(() => {
    setErrMsg(
      'ファイルが大きすぎるため、アップロードできませんでした。\n※アップロード可能なファイルのサイズ上限は2MBです。'
    )
  }, [])
  const onReadError = useCallback(() => {
    setErrMsg('ファイル読み込みに失敗しました。')
  }, [])
  const onInvalidFileTypeError = useCallback(() => {
    setErrMsg('ファイルの拡張子がjpgもしくはpngではありません。適切なファイルをアップロードしてください。')
  }, [])

  const onLoadend = useCallback(
    (dataUri: string) => {
      saveBackgroundUserImage(dataUri)
      clearErrMsg() // 正常に画像をロードできた際にエラーメッセージを削除する
    },
    [saveBackgroundUserImage, clearErrMsg]
  )

  const { clickHiddenInput, renderHiddenInput } = useSelectImage(
    onLoadend,
    onReadError,
    onFileSizeLimitExceeded,
    onInvalidFileTypeError
  )

  const onClose = useCallback(() => {
    clearErrMsg() // モーダルを閉じた時にエラーメッセージを削除する
    _onClose()
  }, [clearErrMsg, _onClose])

  return (
    <Modal title="背景設定" active={active} size="l" onClose={onClose}>
      {errMsg && (
        <Box padding="20px 20px 0 20px">
          <ErrorBox>
            <Txt color={theme.color.red[2]}>{errMsg}</Txt>
          </ErrorBox>
        </Box>
      )}
      <Caution appType={appType} />
      {videoPreview}
      <ButtonGroup padding="20px">
        <EffectButton
          icon={<BackgroundNoEffect />}
          text="なし"
          selected={selectedNoEffect}
          onClick={onSelectNoEffect}
          comlinkPushParams={{ action: 'set_no_effect_on_background' }}
        />
        <EffectButton
          icon={<BackgroundBlurWeak />}
          text="ぼかし弱"
          selected={selectedBlurWeak}
          onClick={onSelectBlurWeak}
          comlinkPushParams={{ action: 'set_blur_weak_on_background' }}
        />
        <EffectButton
          icon={<BackgroundBlurStrong />}
          text="ぼかし強"
          selected={selectedBlurStrong}
          onClick={onSelectBlurStrong}
          comlinkPushParams={{ action: 'set_blur_strong_on_background' }}
        />
        {backgroundUserImage && (
          <UserImage
            selected={selectedUserImage}
            src={backgroundUserImage}
            onClick={onSelectUserImage}
            onClear={clearBackgroundUserImage}
          />
        )}
      </ButtonGroup>
      <ButtonGroup padding="0px 10px 20px">
        <ImageSelectButton
          icon={<BackgroundUserImage />}
          text="背景画像をアップロード"
          onClick={clickHiddenInput}
          comlinkPushParams={{ action: 'upload_user_image_for_background' }}
        />
        {renderHiddenInput()}
      </ButtonGroup>
      <WeakColorSeparator />
      <CloseArea onClose={onClose} />
    </Modal>
  )
}

const baseEffectButtonCss = css`
  height: 44px;
  padding: 2px;
  font-size: 10px;
  background-color: ${theme.color.gray[5]};
`

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const EffectButton = styled(OldTertiaryButton).attrs({ direction: 'column' })<{ selected: boolean }>`
  ${baseEffectButtonCss}
  ${({ selected }) =>
    selected
      ? css`
          border: 2px solid ${theme.color.navy[1]};
        `
      : css`
          border: 0px;
        `}
  width: 60px;
`

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const ImageSelectButton = styled(OldTertiaryButton).attrs({ direction: 'column' })`
  ${baseEffectButtonCss}
  border: 0px;
  width: 121px;
`

const ButtonGroup = styled.div<{ padding: string }>`
  display: flex;
  justify-content: center;
  column-gap: 10px;
  ${({ padding }) =>
    css`
      padding: ${padding};
    `}
`

const CloneButton = styled(TertiaryButton).attrs({
  widthSize: 'L2',
  heightSize: 'L',
  comlinkPushParams: { action: 'close_background_setting_modal' },
})``

const CloseArea: React.VFC<{ onClose: () => void }> = memo(({ onClose }) => {
  return (
    <ButtonGroup padding="20px">
      <CloneButton onClick={onClose}>閉じる</CloneButton>
    </ButtonGroup>
  )
})

const BackgroundBlurStrong = styled(Icon).attrs({ name: 'background-blur-strong' })``
const BackgroundBlurWeak = styled(Icon).attrs({ name: 'background-blur-weak' })``
const BackgroundNoEffect = styled(Icon).attrs({ name: 'background-no-effect' })``
const BackgroundUserImage = styled(Icon).attrs({ name: 'upload' })``

/*
 * 入室前画面用の背景設定モーダル
 * BackgroundSettingModalをラップしてvideoPreview: ReactNodeの代わりにHTMLVideoElementのrefを受け取る
 */
export const BackgroundSettingModalForBeforeEntered: React.VFC<
  Omit<ComponentProps<typeof BackgroundSettingModal>, 'videoPreview'> & {
    videoElRef: ((el: HTMLVideoElement | null) => void) | RefObject<HTMLVideoElement>
  }
> = (props) => {
  return <BackgroundSettingModal {...props} videoPreview={<VideoPreview autoPlay muted ref={props.videoElRef} />} />
}

export const videoPreviewCss = css`
  margin: 0 auto;
  transform: scaleX(-1);
  width: 400px;
  height: 225px;
  border: 1px solid ${theme.color.gray[3]};
  border-radius: 4px;
`

const VideoPreview = styled.video`
  ${videoPreviewCss}
`
