import React, { useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { ContentShareContainer } from '@/lib/meetingcomponent/ContentShareContainer'
import { FinishScreenShareModal } from '../../components/ScreenShare/FinishScreenShareModal'
import { ScreenShareLimitModal } from '../../components/ScreenShare/ScreenShareLimitModal'
import { StartScreenShareModal } from '../../components/ScreenShare/StartScreenShareModal'

function useScreenShareUi() {
  const { startContentShare, stopContentShare, startScreenShareModal, finishScreenShareModal, screenShareLimitModal } =
    ContentShareContainer.useContainer()

  const onStart = useCallback(async () => {
    await startContentShare()
    startScreenShareModal.close()
  }, [startContentShare, startScreenShareModal])

  const onFinish = useCallback(() => {
    stopContentShare()
    finishScreenShareModal.close()
  }, [stopContentShare, finishScreenShareModal])

  const renderStartScreenShareModal = useCallback(() => {
    return (
      <StartScreenShareModal
        active={startScreenShareModal.active}
        onStart={onStart}
        onClose={startScreenShareModal.close}
      />
    )
  }, [onStart, startScreenShareModal.active, startScreenShareModal.close])

  const renderFinishScreenShareModal = useCallback(() => {
    return (
      <FinishScreenShareModal
        active={finishScreenShareModal.active}
        onFinish={onFinish}
        onClose={finishScreenShareModal.close}
      />
    )
  }, [onFinish, finishScreenShareModal.active, finishScreenShareModal.close])

  const renderScreenShareLimitModal = useCallback(() => {
    return <ScreenShareLimitModal active={screenShareLimitModal.active} onClose={screenShareLimitModal.close} />
  }, [screenShareLimitModal.active, screenShareLimitModal.close])

  return {
    renderStartScreenShareModal,
    renderFinishScreenShareModal,
    renderScreenShareLimitModal,

    openStartScreenShareModal: startScreenShareModal.open,
    openFinishScreenShareModal: finishScreenShareModal.open,
  }
}

export const ScreenShareUiContainer = createContainer(useScreenShareUi)
