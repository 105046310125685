import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { SharedState } from '..'

export const isDataChannelReadySelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.userSignalingStatus,
  (state: SharedState) => state.shared.dataChannelStatus,
  (userSignalingStatus, dataChannelStatus) => {
    return userSignalingStatus === 'Completed' && dataChannelStatus === 'Completed'
  }
)
