import { createContainer } from '@blue-agency/front-state-management'
import { useToggle } from '@/shared/hooks/useToggle'

const useVideosArea = () => {
  const [isVideosAreaOpen, toggleIsVideosAreaOpen] = useToggle(true)

  return {
    isVideosAreaOpen,
    toggleIsVideosAreaOpen,
  }
}

export const VideosAreaContainer = createContainer(useVideosArea)
