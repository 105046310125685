import React, { useCallback, useState } from 'react'
import { theme } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ChatContainer } from '../../containers/ChatContainer'
import { isChatReadySelector } from '../../redux'
import { SendButton } from './SendButton'

export const ChatMessageForm: React.VFC = React.memo(() => {
  const { sendChatMessage, setIsChatFocused } = ChatContainer.useContainer()
  const isChatReady = useSelector(isChatReadySelector)
  return (
    <ChatMessageFormConsumer
      sendChatMessage={sendChatMessage}
      setIsChatFocused={setIsChatFocused}
      isChatReady={isChatReady}
    />
  )
})

type ConsumerProps = {
  sendChatMessage: (text: string) => void
  setIsChatFocused: (focus: boolean) => void
  isChatReady: boolean
}

const ChatMessageFormConsumer: React.VFC<ConsumerProps> = React.memo(
  ({ sendChatMessage, setIsChatFocused, isChatReady }) => {
    const [value, setValue] = useState('')
    const [rows, setRows] = useState(1)

    const submit = useCallback(() => {
      const trimmedText = value.trim()
      if (trimmedText === '') return
      setRows(1)
      sendChatMessage(trimmedText)
      setValue('')
    }, [value, sendChatMessage])

    const handleChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const lines = (event.target.value + '\n')?.match(/\n/g)?.length || 1
      setRows(Math.min(5, lines))
      setValue(event.target.value)
    }, [])

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'Enter') {
          submit()
        }
      },
      [submit]
    )

    return (
      <Form>
        <Wrapper>
          <Textarea
            value={value}
            disabled={!isChatReady}
            placeholder="入力してください"
            rows={rows}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsChatFocused(true)}
            onBlur={() => setIsChatFocused(false)}
          />
          <WrapperButton>
            <SendButton disabled={!isChatReady} submit={submit} />
          </WrapperButton>
        </Wrapper>
      </Form>
    )
  }
)

export const __ChatMessageFormConsumer = ChatMessageFormConsumer

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
`

const WrapperButton = styled.div`
  top: 0;
  margin: 0 4px;
`

const Form = styled.form`
  align-items: center;
`

const Textarea = styled.textarea`
  box-sizing: border-box;
  flex: 1;
  padding: 4px 8px;
  min-height: 43px;
  margin-right: 6px;

  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[2]};
  border-radius: 4px;
  color: ${theme.color.navy[1]};
  font-size: ${theme.fontSize.s};
  line-height: 1.4;
  resize: none;

  // Firefox で textarea の幅バグ対策
  // https://qiita.com/ituki_b/items/3c66972a16d4c944b164
  //
  // https://blue-agency.atlassian.net/browse/PIM-852
  min-width: 0;

  &::placeholder {
    color: ${theme.color.gray[2]};
  }
`
