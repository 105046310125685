import { StaticImageStream } from '../staticimagestream/StaticImageStream'
import { CAMERA_OFF_IMG } from './cameraOffImg'

export class MutedVideoStream extends StaticImageStream {
  private static _instance: MutedVideoStream

  private constructor() {
    super()
  }

  public static get instance(): MutedVideoStream {
    if (!this._instance) {
      this._instance = new this()
    }
    return this._instance
  }

  protected src(): string {
    return CAMERA_OFF_IMG
  }
}
