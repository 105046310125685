import { useCallback, useContext, useMemo } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { ThemeContext } from 'styled-components'
import { Ratio } from '@/shared/services/interviewService/components/VideoList/AspectBox'

type Size = {
  width: string
  height: string
}

/**
 * single: PC, SP横用の1カラムレイアウト。16x9で縦1列に並ぶ。
 * single_screen: PC, SP横での画面共有用レイアウト。26x9で縦1列に並ぶ。
 * double: SP縦用の2カラムレイアウト。
 */
export type Layout = 'single' | 'single_screen' | 'double'

function useVideosLayout() {
  const { responsive } = useContext(ThemeContext)

  const layoutType = useMemo<'horizontal' | 'vertical'>(() => {
    return responsive.spPortrait ? 'horizontal' : 'vertical'
  }, [responsive])

  const videoWrapperLayout = useCallback(
    (isScreenShare: boolean): Layout => {
      if (layoutType === 'horizontal') {
        return 'double'
      }
      return isScreenShare ? 'single_screen' : 'single'
    },
    [layoutType]
  )

  const videoWrapperAspectRatio = useCallback(
    (isScreenShare: boolean): Ratio => {
      return videoWrapperLayout(isScreenShare) === 'single_screen' ? '26x9' : '16x9'
    },
    [videoWrapperLayout]
  )

  const screenShareIconSize = useMemo<Size>(() => {
    return layoutType === 'vertical'
      ? {
          width: '25%',
          height: '25%',
        }
      : {
          width: '30%',
          height: '30%',
        }
  }, [layoutType])

  return {
    layoutType,
    videoWrapperLayout,
    videoWrapperAspectRatio,
    screenShareIconSize,
  }
}

export const VideosLayoutContainer = createContainer(useVideosLayout)
