// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import {
  AudioLogEvent,
  ClientMetricReport,
  MeetingSessionLifecycleEvent,
  MeetingSessionLifecycleEventCondition,
  MeetingSessionStatus,
  SignalingClient,
  StatsCollector,
  VideoLogEvent,
  VideoStreamIndex,
} from 'amazon-chime-sdk-js'
import { noop } from '../util'

export class NoOpStatsCollector implements StatsCollector {
  logAudioEvent(eventName: AudioLogEvent, attributes?: { [p: string]: string }): void {
    noop(eventName, attributes)
  }

  logLatency(eventName: string, timeMs: number, attributes?: { [p: string]: string }): void {
    noop(eventName, timeMs, attributes)
  }

  logLifecycleEvent(
    lifecycleEvent: MeetingSessionLifecycleEvent,
    condition: MeetingSessionLifecycleEventCondition
  ): void {
    noop(lifecycleEvent, condition)
  }

  logMeetingSessionStatus(status: MeetingSessionStatus): void {
    noop(status)
  }

  logStateTimeout(stateName: string, attributes?: { [p: string]: string }): void {
    noop(stateName, attributes)
  }

  logVideoEvent(eventName: VideoLogEvent, attributes?: { [p: string]: string }): void {
    noop(eventName, attributes)
  }

  start(
    signalingClient: SignalingClient,
    videoStreamIndex: VideoStreamIndex,
    clientMetricReport?: ClientMetricReport
  ): boolean {
    noop(signalingClient, videoStreamIndex, clientMetricReport)
    return true
  }

  stop(): void {
    noop()
  }
}
