import React, { useCallback } from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton, SecondaryDangerButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { InRoomContainer } from './containers/InRoomContainer'

export const KickoutAllIntervieweeModal: React.VFC = () => {
  const { kickoutAllIntervieweeModal, kickoutAllInterviewees } = InRoomContainer.useContainer()
  const { active, close } = kickoutAllIntervieweeModal
  const onKickout = useCallback(() => {
    kickoutAllInterviewees()
    close()
  }, [kickoutAllInterviewees, close])
  return (
    <Modal active={active} onClose={close} title="応募者のみ退室" size="s">
      <Upper>
        <Txt>
          応募者全員をルームから退室させます。
          <br />
          面接官は残りますので、評価のすり合わせにご利用ください。
        </Txt>
      </Upper>
      <Separator />
      <Bottom>
        <ButtonGroup>
          <CancelButton
            onClick={close}
            widthSize="L1"
            comlinkPushParams={{ action: 'click_cancel_button_on_kickout_all_interviewee_modal' }}
          >
            キャンセル
          </CancelButton>
          <FinishButton
            onClick={onKickout}
            widthSize="L1"
            comlinkPushParams={{ action: 'click_kickout_all_interviewee_button_on_kickout_all_interviewee_modal' }}
          >
            応募者のみ退室
          </FinishButton>
        </ButtonGroup>
      </Bottom>
    </Modal>
  )
}

const Upper = styled.div`
  padding: 20px 16px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 40px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const CancelButton = styled(TertiaryButton)`
  width: 48%;
`
const FinishButton = styled(SecondaryDangerButton)`
  width: 48%;
`
