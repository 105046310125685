import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { InPageChatBot } from '@blue-agency/im-shared-front'
import { GetInterviewResponse } from '@blue-agency/proton/my_skywalker_bff'
import { Expired } from './Expired'
import { Finished } from './Finished'
import { Interview } from './Interview'
import { Preparing } from './Preparing'
import { InterviewPageContainer } from './containers/InterviewPageContainer'

export const InterviewPage: React.VFC = provide(InterviewPageContainer)(() => {
  const { isLoading, isPrepared, status } = InterviewPageContainer.useContainer()

  if (isLoading) {
    // TODO: ローディング表示に置き換える
    return null
  }

  switch (status) {
    case GetInterviewResponse.Status.VALID:
      return isPrepared ? (
        <Interview />
      ) : (
        <>
          <InPageChatBot botType="my" isBottomFixed />
          <Preparing />
        </>
      )
    case GetInterviewResponse.Status.FINISHED:
      return <Finished />
    case GetInterviewResponse.Status.EXPIRED:
      return <Expired />
  }
  throw new Error('unexpected state')
})
