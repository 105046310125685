import { useEffect, useMemo } from 'react'
import type { Metadata } from '@blue-agency/comlink/dist/src/com_event'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { useSelector } from 'react-redux'
import { usePrevious } from 'react-use'
import { getBadIndicators, getBecomeWorseIndicators } from '../ConnectionStatsSummary'
import { SharedState } from '../redux'

export const useComlinkConnectionStatsSummary = () => {
  const statsSummary = useSelector((state: SharedState) => state.shared.connectionStatsSummary)
  const isStaticImageVideoSrc = useSelector(
    (state: SharedState) => state.shared.isOwnCameraMuted || state.shared.isUnavailableVideoInput
  )

  const badIndicators = useMemo(
    () => getBadIndicators(statsSummary, isStaticImageVideoSrc),
    [isStaticImageVideoSrc, statsSummary]
  )
  const prevBadIndicators = usePrevious(badIndicators)

  useEffect(() => {
    const worseIndicators = getBecomeWorseIndicators(prevBadIndicators, badIndicators)
    if (!worseIndicators) return
    if (worseIndicators.size === 0) return

    const metadata: Metadata = {}
    worseIndicators.forEach((i) => {
      // 実装上ないはずだけど一応ガード
      if (statsSummary[i] === undefined) {
        return
      }
      metadata[i] = String(statsSummary[i])
    })

    comlinkPush(
      {
        action: 'got_worse_connection_stats',
        metadata,
      },
      { lazy: true }
    )
  }, [badIndicators, prevBadIndicators, statsSummary])
}
