import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateInterviewSummaryRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

type Args = {
  interviewGuid: string
}

export function useRequestCreateInterviewSummary() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateInterviewSummary = useCallback(
    async (args: Args) => {
      const metadata = await buildMetadata()

      const req = new CreateInterviewSummaryRequest()
      req.setInterviewGuid(args.interviewGuid)

      return callBff('createInterviewSummary', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestCreateInterviewSummary }
}
