import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetSignalingPointsRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetSignalingPoints() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetSignalingPoints = useCallback(
    async (interviewGuid: string) => {
      const metadata = await buildMetadata()
      const req = new GetSignalingPointsRequest()
      req.setInterviewGuid(interviewGuid)
      return callBff('getSignalingPoints', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestGetSignalingPoints }
}
