import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateInterviewTranscriptionRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { SoraPushDataAudioStreamingResult } from '@/shared/services/interviewService/types'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

type Args = {
  interviewGuid: string
  soraClientId: string
  data: SoraPushDataAudioStreamingResult
}

export function useRequestCreateInterviewTranscription() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateInterviewTranscription = useCallback(
    async (args: Args) => {
      const metadata = await buildMetadata()

      if (!args.data.result.results[0] || !args.data.result.message) {
        return
      }

      const req = new CreateInterviewTranscriptionRequest()
      req.setInterviewGuid(args.interviewGuid)
      req.setSoraClientId(args.soraClientId)
      req.setConfidence(args.data.result.results[0].confidence)
      req.setStartTime(args.data.result.results[0].starttime)
      req.setEndTime(args.data.result.results[0].endtime)
      req.setUtteranceId(args.data.result.utteranceid)
      // 文字起こしの1リクエスト分全体のテキスト
      req.setInterviewTranscriptionSentence(args.data.result.message)

      const audioData = JSON.stringify(args.data)
      const bytes = new TextEncoder().encode(audioData)
      req.setRawAudioStreamingResult(bytes)

      return callBff('createInterviewTranscription', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestCreateInterviewTranscription }
}
