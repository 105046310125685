import { useCallback, useEffect, useRef } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { comlinkPush } from '@blue-agency/im-shared-front'

const useSoundPlay = () => {
  const audio = useRef(new Audio())

  // NOTE: iOSはautoplayのポリシーがあるため、事前にユーザーインラタクションでplay()しておく
  useEffect(() => {
    const handler = () => {
      audio.current.src = ''
      audio.current.muted = false
      // TODO: SafariでNotSupportedErrorが出るので一旦エラーを握りつぶしておく、エラーが出ないように対応する
      audio.current.play().catch((_e) => undefined)
      window.removeEventListener('touchstart', handler)
    }
    window.addEventListener('touchstart', handler)
  }, [])

  // speakerDeviceIdがnullの場合はデフォルトの音声出力デバイスで出力する。
  const playSound = useCallback((speakerDeviceId: string | null, src: string, volume: number = 1) => {
    if (volume < 0 || 1 < volume) throw new Error('volume is out of the range [0, 1]')
    audio.current.src = src
    audio.current.volume = volume

    if (audio.current.setSinkId && speakerDeviceId) {
      audio.current
        .setSinkId(speakerDeviceId)
        .catch((e) => {
          comlinkPush({ action: 'failed_to_play_sound', metadata: { message: e.message ?? '', src, speakerDeviceId } })
        })
        .finally(() => audio.current.play()) // setSinkIdの成否にかかわらず音を再生する
    } else {
      audio.current.play()
    }
  }, [])

  return { playSound }
}

export const SoundPlayContainer = createContainer(useSoundPlay)
