import { useEffect } from 'react'
import { toast } from '@blue-agency/rogue'
import { useDispatch, useSelector } from 'react-redux'
import { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import { bizSlice } from '../../../redux/bizSlice'
import { RootState } from '../../../redux/store'
import { InRoomContainer } from '../../containers/InRoomContainer'

export function useRejectEntryMessageHandler() {
  const dispatch = useDispatch()

  const pendingEntryRequests = useSelector((state: RootState) => state.biz.pendingEntryRequests)

  const { ws } = InRoomContainer.useContainer()
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    const handler = (message: WebSocketMessageMap['reject entry']) => {
      const isValid = pendingEntryRequests.some((r) => r.entryRequestGuid === message.entry_request_guid)

      if (isValid) {
        toast('リクエストを拒否しました')
      }

      dispatch(bizSlice.actions.rejectEntryMessageReceived(message))
    }

    addMessageListener<'reject entry'>('reject entry', handler)

    return () => {
      removeMessageListener<'reject entry'>('reject entry', handler)
    }
  }, [addMessageListener, dispatch, pendingEntryRequests, removeMessageListener])
}
