import { useCallback, useContext } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { sharedSlice, SharedState } from '@/shared/services/interviewService/redux'

const useInterviewAutoQuestion = () => {
  const dispatch = useDispatch()

  const { responsive } = useContext(ThemeContext)

  const isInterviewAutoQuestionAreaOpen = useSelector((state: SharedState) =>
    responsive.pc ? state.shared.isInterviewAutoQuestionAreaOpen : false
  )

  const toggleInterviewAutoQuestionAreaOpen = useCallback(() => {
    dispatch(sharedSlice.actions.toggleInterviewAutoQuestionAreaOpen({ shouldCloseChatArea: !responsive.pc }))
  }, [dispatch, responsive])

  const closenInterviewAutoQuestionArea = useCallback(() => {
    dispatch(sharedSlice.actions.closeAutoQuestionArea())
  }, [dispatch])

  return {
    isInterviewAutoQuestionAreaOpen,
    toggleInterviewAutoQuestionAreaOpen,
    closenInterviewAutoQuestionArea,
  }
}

export const InterviewAutoQuestionContainer = createContainer(useInterviewAutoQuestion)
