import { useState } from 'react'
import { ListInterviewTranscriptionsResponse } from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import { Txt, theme, Icon, CircularProgress } from '@blue-agency/rogue'
import styled from 'styled-components'
import type { ListInterviewTranscription } from '@/biz/services/interviewMinuteService/useInterviewMinute'
import { Box } from '@/shared/components/Box'

type Props = {
  loggedInStaffGuid: string
  transcriptions?: ListInterviewTranscription[]
  replaceFillerMessage: (message: string) => React.ReactNode
  handleCreateGoodPin: (transcriptionGuid: string) => void
  handleCreateBadPin: (transcriptionGuid: string) => void
  handleCreateQuestionPin: (transcriptionGuid: string) => void
  handleDeleteInterviewTranscriptionPinning: (transcriptionGuid: string, transcriptionPinningGuid: string) => void
  handleSeekTimeAndPlay?: (time: number) => void
}

export const TranscriptionCard = ({
  loggedInStaffGuid,
  transcriptions,
  replaceFillerMessage,
  handleCreateGoodPin,
  handleCreateBadPin,
  handleCreateQuestionPin,
  handleDeleteInterviewTranscriptionPinning,
  handleSeekTimeAndPlay,
}: Props) => {
  const [showButtonBox, setShowButtonBox] = useState<boolean>(false)
  const [hoveredPinGuid, setHoveredPinGuid] = useState<string | null>(null)
  const [showTranscriptionGuid, setShowTranscriptionGuid] = useState<string>('')
  return (
    <Wrapper>
      <Header>
        <Txt size="l">文字起こし</Txt>
      </Header>
      {transcriptions === undefined ? (
        <LoadingBody>
          <CircularProgress size={64} borderWidth={1} color={theme.color.red['iM']} />
        </LoadingBody>
      ) : transcriptions.length === 0 ? (
        <NotFoundBody>
          <Txt>該当する情報はありません</Txt>
        </NotFoundBody>
      ) : (
        <Body>
          {transcriptions.map((transcription) => (
            <TranscriptionListItem key={transcription.guid}>
              <NameIcon iconType={transcription.iconType}>{transcription.name}</NameIcon>
              <TranscriptionListItemBody>
                <TranscriptionTextWrapper
                  onMouseEnter={() => {
                    setShowButtonBox(true)
                    setShowTranscriptionGuid(transcription.guid)
                  }}
                  onMouseLeave={() => setShowButtonBox(false)}
                >
                  {showButtonBox && transcription.guid === showTranscriptionGuid && (
                    <ButtonsWrapper>
                      <ButtonWrapper onClick={() => handleCreateGoodPin(transcription.guid)}>
                        <GoodButton />
                      </ButtonWrapper>
                      <ButtonWrapper onClick={() => handleCreateBadPin(transcription.guid)}>
                        <BadButton />
                      </ButtonWrapper>
                      <ButtonWrapper onClick={() => handleCreateQuestionPin(transcription.guid)}>
                        <QuestionButton />
                      </ButtonWrapper>
                      {handleSeekTimeAndPlay && (
                        <>
                          <VerticalLine />
                          <ButtonWrapper
                            onClick={() => handleSeekTimeAndPlay(Math.ceil(transcription.startTime / 1000))}
                          >
                            <SeekTimeAndPlayButton />
                          </ButtonWrapper>
                        </>
                      )}
                    </ButtonsWrapper>
                  )}
                  <TranscriptionText>{replaceFillerMessage(transcription.message)}</TranscriptionText>
                </TranscriptionTextWrapper>
                {transcription.pins.length > 0 && (
                  <PinWrapper>
                    {transcription.pins.map((pin, index) => (
                      <Pin
                        key={index}
                        onMouseEnter={() => {
                          if (pin.staffGuid === loggedInStaffGuid) {
                            setHoveredPinGuid(pin.guid)
                          }
                        }}
                        onMouseLeave={() => {
                          setHoveredPinGuid(null)
                        }}
                      >
                        <PinIcon type={pin.pinType} />
                        <FullNameTxt>{pin.fullName}</FullNameTxt>
                        {pin.guid === hoveredPinGuid && (
                          <DeletePinWrapper
                            onClick={() => handleDeleteInterviewTranscriptionPinning(transcription.guid, pin.guid)}
                          >
                            <DeletePin />
                          </DeletePinWrapper>
                        )}
                      </Pin>
                    ))}
                  </PinWrapper>
                )}
              </TranscriptionListItemBody>
            </TranscriptionListItem>
          ))}
        </Body>
      )}
    </Wrapper>
  )
}

type PinProps = {
  type: ListInterviewTranscriptionsResponse.InterviewPinningType
}

const PinIcon: React.FC<PinProps> = ({ type }) => {
  switch (type) {
    case ListInterviewTranscriptionsResponse.InterviewPinningType.GOOD:
      return <GoodPin />
    case ListInterviewTranscriptionsResponse.InterviewPinningType.BAD:
      return <BadPin />
    case ListInterviewTranscriptionsResponse.InterviewPinningType.QUESTION:
      return <QuestionPin />
  }
  return null
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 1px ${theme.color.gray[4]};
  border-radius: 4px;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: ${theme.color.navy[3]};
  border-bottom: solid 1px ${theme.color.gray[4]};
  border-radius: 4px 4px 0 0;
`

const Body = styled.div`
  padding: 20px 12px;
  overflow-y: auto;
`

const NotFoundBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`

const LoadingBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const TranscriptionText = styled(Txt).attrs({ size: 'm' })`
  &:hover {
    background-color: ${theme.color.gray[4]};
  }

  span {
    display: inline-block;
    color: ${theme.color.gray[2]};
    background-color: ${theme.color.gray[4]};
  }
`

const TranscriptionListItemBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: center;
`

const TranscriptionTextWrapper = styled.div`
  width: 100%;
  position: relative;
`

const TranscriptionListItem = styled(Box)`
  min-height: 28px;
  display: flex;
  align-items: baseline;
  gap: 16px;
  margin-bottom: 4px;
`

const TranscriptionListItemIcon = styled.div`
  font-size: 12px;
  width: 28px;
  height: 28px;
  border-radius: 50px;
  text-align: center;
  display: inline-grid;
  align-items: center;
  user-select: none;
  flex-shrink: 0;
`

const NameIcon = styled(TranscriptionListItemIcon)<{ iconType: ListInterviewTranscription['iconType'] }>`
  color: ${({ iconType }) => (iconType === 'my' ? theme.color.navy[1] : theme.color.white[1])};
  background: ${({ iconType }) => (iconType === 'my' ? theme.color.navy[3] : theme.color.navy[1])};
`

const Pin = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  &:hover {
    background-color: ${theme.color.gray[4]};
  }
`

const PinWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
`

const GoodPin = styled(Icon).attrs({ name: 'good-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.blue[1]};
`

const BadPin = styled(Icon).attrs({ name: 'bad-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.red[2]};
`

const QuestionPin = styled(Icon).attrs({ name: 'question-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.orange[1]};
`

const FullNameTxt = styled(Txt).attrs({ size: 's' })`
  color: ${theme.color.gray[1]};
`

const DeletePinWrapper = styled.div`
  position: relative;
`

const DeletePin = styled(Icon).attrs({ name: 'delete' })`
  position: absolute;
  top: -32px;
  height: 24px;
  width: 24px;
  display: flex;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 4px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  background: ${theme.color.white[1]};
  color: ${theme.color.red[2]};
  &:hover {
    background-color: ${theme.color.gray[4]};
    cursor: pointer;
  }
`

const ButtonsWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 1rem;
  height: 40px;
  display: flex;
  align-items: flex-start;
  border-radius: 4px;
  background: var(--white-1, #fff);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
`

const GoodButton = styled(Icon).attrs({ name: 'good-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.blue[1]};
`

const BadButton = styled(Icon).attrs({ name: 'bad-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.red[2]};
`

const QuestionButton = styled(Icon).attrs({ name: 'question-face' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.orange[1]};
`

const SeekTimeAndPlayButton = styled(Icon).attrs({ name: 'play-circle' })`
  height: 24px;
  width: 24px;
  color: ${theme.color.navy[1]};
`

const VerticalLine = styled.div`
  width: 1px;
  margin: 4px 0;
  height: 32px;
  background-color: ${theme.color.gray[3]};
  opacity: 0.5;
`

const ButtonWrapper = styled.button`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: transparent;
  &:hover {
    background-color: ${theme.color.gray[4]};
    cursor: pointer;
  }
`
