import { useEffect } from 'react'
import { Comlink } from '@blue-agency/comlink'
import { AudioInputContainer, AudioOutputContainer, VideoInputContainer } from '@/lib/meetingcomponent/DevicesContainer'

export const useSendDeviceInfoToComlink = () => {
  const { audioInputs, selectedAudioInputDevice } = AudioInputContainer.useContainer()
  const { audioOutputs, selectedAudioOutputDevice } = AudioOutputContainer.useContainer()
  const { videoInputs, selectedVideoInputDevice } = VideoInputContainer.useContainer()

  // 選択されたデバイス情報を送信
  useEffect(() => {
    comlinkSendSelectedDevice(audioInputs, selectedAudioInputDevice)
  }, [selectedAudioInputDevice, audioInputs])

  useEffect(() => {
    comlinkSendSelectedDevice(audioOutputs, selectedAudioOutputDevice)
  }, [selectedAudioOutputDevice, audioOutputs])

  useEffect(() => {
    comlinkSendSelectedDevice(videoInputs, selectedVideoInputDevice)
  }, [selectedVideoInputDevice, videoInputs])

  // デバイス一覧が更新されたら送信
  useEffect(() => {
    comlinkSendDeviceList({
      audioInputs,
      audioOutputs,
      videoInputs,
    })
  }, [audioInputs, audioOutputs, videoInputs])
}

const comlinkSendDeviceList = (params: {
  videoInputs: MediaDeviceInfo[]
  audioInputs: MediaDeviceInfo[]
  audioOutputs: MediaDeviceInfo[]
}) => {
  const deviceList = Object.values(params).flat()
  if (deviceList.length > 0) {
    Comlink.push({
      type: 'manual_activity',
      group: 'media-devices',
      action: 'update_connected_devices',
      metadata: {
        devices: JSON.stringify(deviceList.map((e) => e.label)),
      },
    })
  }
}

const comlinkSendSelectedDevice = (devices: MediaDeviceInfo[], deviceId: string | null) => {
  const device = findDevice(devices, deviceId)
  if (device === undefined) {
    return
  }
  Comlink.push({
    type: 'manual_activity',
    group: 'media-devices',
    action: `${device.kind}_selected`,
    metadata: {
      selectedDevice: device.label,
    },
  })
}

const findDevice = (devices: MediaDeviceInfo[], deviceId: string | null): MediaDeviceInfo | undefined => {
  if (deviceId === null) {
    return
  }

  return devices.find((d) => d.deviceId === deviceId)
}
