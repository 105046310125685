import React, { useEffect, useRef } from 'react'
import { Button, Icon, theme, Txt } from '@blue-agency/rogue'
import styled, { css, keyframes } from 'styled-components'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { timerFormat } from '@/shared/services/interviewService/components/Timer/timerFormat'
import { useInterviewGuideNotification } from '@/shared/services/interviewService/hooksForUi/InterviewGuide/useInterviewGuideNotification'

const omit = (text: string, len: number) => {
  return text.length >= len ? text.slice(0, len - 1) + '…' : text
}

export const InterviewGuideNotification: React.VFC = () => {
  const { active, displayPart, openInterviewGuideArea, close } = useInterviewGuideNotification()
  const wrapper = useRef<HTMLDivElement>(null)
  const preActive = usePrevious(active)
  useEffect(() => {
    // HACK: ios safariにて、再表示時にanimationが再再生されない。以下の処理でanimation状態をリセットすることにより再再生されるようになる。
    // https://stackoverflow.com/questions/6268508/restart-animation-in-css3-any-better-way-than-removing-the-element
    if (active && !preActive) {
      if (!!wrapper.current) {
        wrapper.current.style.animation = 'none'
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        wrapper.current.offsetHeight
        wrapper.current.style.animation = ''
      }
    }
  }, [active, preActive])

  return (
    <Wrapper active={active} ref={wrapper}>
      <Content>
        <Time disabled={displayPart.startSeconds === 0}>
          <Txt size="m" color={theme.color.green[4]}>
            {timerFormat(displayPart.startSeconds)}
          </Txt>
        </Time>
        <GuidePartBody>
          <GuidePartTitle>
            <Txt size="m" color={theme.color.green[4]} bold>
              {omit(displayPart.title === '' ? displayPart.description : displayPart.title, 17)}
            </Txt>
          </GuidePartTitle>
          <GuidePartButton
            onClick={openInterviewGuideArea}
            comlinkPushParams={{
              action: 'click_open_interview_guide_on_interview_guide_notification',
            }}
          >
            <Txt color={theme.color.blue[1]}>面接ガイドを開く</Txt>
          </GuidePartButton>
        </GuidePartBody>
      </Content>
      <IconButton onClick={close}>
        <CloseIcon />
      </IconButton>
    </Wrapper>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Wrapper = styled.div<{ active: boolean }>`
  ${({ active }) => {
    if (active) {
      return css`
        animation: ${fadeIn} 0.5s ease-in;
      `
    } else {
      return css`
        display: none;
        animation: none;
      `
    }
  }}
`

const Content = styled.div`
  background-color: ${theme.color.white[1]};
  margin: 8px 8px 0 0;
  padding: 8px;
  width: 284px;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
`

const Time = styled.div<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    css`
      display: none;
    `}
`

const GuidePartBody = styled.div`
  margin-left: 4px;
`

const GuidePartTitle = styled.div``

const GuidePartButton = styled(Button)`
  background-color: transparent;
  border: 0;
  cursor: pointer;

  > * {
    text-decoration: underline;
  }
`

const IconButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${theme.color.navy[1]};
  height: 16px;
  width: 16px;
  border-radius: 8px;
  border: 0;
  padding: 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  color: ${theme.color.white[1]};
  height: 12px;
  width: 12px;
`
