import { useCallback, useContext } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useDispatch, useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { sharedSlice, SharedState } from '@/shared/services/interviewService/redux'

const useInterviewGuide = () => {
  const dispatch = useDispatch()

  const isInterviewGuideAreaOpen = useSelector((state: SharedState) => state.shared.isInterviewGuideAreaOpen)

  const hasInterviewGuide = useSelector((state: SharedState) => state.shared.hasInterviewGuide)

  const { responsive } = useContext(ThemeContext)

  const toggleInterviewGuideAreaOpen = useCallback(() => {
    dispatch(sharedSlice.actions.toggleInterviewGuideAreaOpen({ shouldCloseChatArea: !responsive.pc }))
  }, [dispatch, responsive])

  const openInterviewGuideArea = useCallback(() => {
    dispatch(sharedSlice.actions.openInterviewGuideArea())
  }, [dispatch])

  const closeInterviewGuideArea = useCallback(() => {
    dispatch(sharedSlice.actions.closeInterviewGuideArea())
  }, [dispatch])

  return {
    isInterviewGuideAreaOpen,
    hasInterviewGuide,
    toggleInterviewGuideAreaOpen,
    openInterviewGuideArea,
    closeInterviewGuideArea,
  }
}

export const InterviewGuideContainer = createContainer(useInterviewGuide)
