import React, { useState, useCallback, useMemo, useEffect, useContext } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { ThemeContext } from 'styled-components'
import { ManualModalContainer } from '../../containers/ManualModalContainer'
import { First } from '../First'
import { FirstMobile } from '../FirstMobile'
import { Second } from '../Second'
import { SecondMobile } from '../SecondMobile'

const useStep = () => {
  const { isManualModalShown } = ManualModalContainer.useContainer()
  const [step, setStep] = useState(1)
  const { responsive } = useContext(ThemeContext)

  useEffect(() => {
    setStep(1)
  }, [isManualModalShown])

  const manualModalBody = useMemo(() => {
    switch (step) {
      case 1:
        if (responsive.pc) {
          return <First />
        }
        return <FirstMobile />
      case 2:
        if (responsive.pc) {
          return <Second />
        }
        return <SecondMobile />
      default:
        throw new Error('Invalid step')
    }
  }, [responsive, step])

  const handleNext = useCallback(() => {
    setStep((prev) => ++prev)
  }, [])
  const handleBack = useCallback(() => {
    setStep((prev) => --prev)
  }, [])

  return {
    handleNext,
    handleBack,
    manualModalBody,
  }
}

export const StepContainer = createContainer(useStep)
