import { useCallback } from 'react'
import { GetParticipantRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

type Args = {
  interviewGuid: string
  soraClientId: string
  invitationToken: string
}

export function useRequestGetParticipant() {
  const requestGetParticipant = useCallback((args: Args) => {
    const req = new GetParticipantRequest()
    req.setInterviewGuid(args.interviewGuid)
    req.setSoraClientId(args.soraClientId)
    req.setInvitationToken(args.invitationToken)

    return callBff('getParticipant', [req])
  }, [])

  return { requestGetParticipant }
}
