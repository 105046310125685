import { useCallback } from 'react'
import { DeleteCameraTestSessionTokenRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

type Args = {
  sessionToken: string
}

export function useRequestDeleteCameraTestSessionToken() {
  const requestDeleteCameraTestSessionToken = useCallback((args: Args) => {
    const req = new DeleteCameraTestSessionTokenRequest()

    req.setSessionToken(args.sessionToken)

    return callBff('deleteCameraTestSessionToken', [req])
  }, [])

  return { requestDeleteCameraTestSessionToken }
}
