import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'
import { SESSION_STORAGE_KEY } from '@/shared/services/storageService'
import { getBadIndicators } from '../ConnectionStatsSummary'
import { SharedState } from '../redux'
import { InterviewQuality } from '../types'

export type Warning = 'cpu' | 'network' | undefined

export const useUnstableConnectionWarning = (
  interviewQuality: InterviewQuality
): { warning: Warning; close: () => void } => {
  const backgroundEffect = useSelector((state: SharedState) => state.sharedStored.backgroundEffect)
  const statsSummary = useSelector((state: SharedState) => state.shared.connectionStatsSummary)
  const isStaticImageVideoSrc = useSelector(
    (state: SharedState) => state.shared.isOwnCameraMuted || state.shared.isUnavailableVideoInput
  )

  const badIndicators = useMemo(
    () => getBadIndicators(statsSummary, isStaticImageVideoSrc),
    [isStaticImageVideoSrc, statsSummary]
  )

  // 1度閉じたら面接中は表示しないためにsession storageにストアする
  const [closedNetworkWarning, setClosedNetworkWarning] = useSessionStorage(
    SESSION_STORAGE_KEY.closedNetworkWarning,
    false
  )
  const [closedCpuWarning, setClosedCpuWarning] = useSessionStorage(SESSION_STORAGE_KEY.closedCpuWarning, false)

  const warning = useMemo<Warning>(() => {
    if (badIndicators.has('protocol')) {
      badIndicators.delete('protocol')
    }
    if (!badIndicators) return
    if (badIndicators.size === 0) return

    if (!closedCpuWarning) {
      if (badIndicators.has('qualityLimitationReason') && statsSummary.qualityLimitationReason === 'cpu') {
        return 'cpu'
      }
      // fpsが下がる要因がネットワーク起因の場合も考えられるため、背景効果使用時のみCPU負荷とみなす
      if (badIndicators.has('videoSrcFps') && backgroundEffect !== 'no-effect') {
        return 'cpu'
      }
    }

    if (!closedNetworkWarning) {
      // 軽量モードのときはqualityLimitationReason: bandwidthがかかりやすいので無視する
      if (
        interviewQuality.mode !== 'low' &&
        badIndicators.has('qualityLimitationReason') &&
        statsSummary.qualityLimitationReason === 'bandwidth'
      ) {
        return 'network'
      }
      if (badIndicators.has('rtt')) {
        return 'network'
      }
    }
    return undefined
  }, [
    backgroundEffect,
    badIndicators,
    closedCpuWarning,
    closedNetworkWarning,
    interviewQuality.mode,
    statsSummary.qualityLimitationReason,
  ])

  const close = useCallback(() => {
    if (warning === 'cpu') {
      setClosedCpuWarning(true)
    } else if (warning === 'network') {
      setClosedNetworkWarning(true)
    }
  }, [setClosedCpuWarning, setClosedNetworkWarning, warning])

  return {
    warning,
    close,
  }
}
