import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetInterviewSummaryByInterviewGUIDRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestGetInterviewSummary() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestGetInterviewSummary = useCallback(
    async (interviewGuid: string) => {
      const metadata = await buildMetadata()

      const req = new GetInterviewSummaryByInterviewGUIDRequest()

      req.setInterviewGuid(interviewGuid)

      return callBff('getInterviewSummaryByInterviewGUID', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestGetInterviewSummary }
}
