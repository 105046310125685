import { useCallback, useMemo } from 'react'
import { createContainer, useCachedPromise } from '@blue-agency/front-state-management'
import { InterviewQualityMode as BffInterviewQualityMode } from '@blue-agency/proton/web/v2/my_skywalker_bff'
import { useDispatch, useSelector } from 'react-redux'
import invariant from 'tiny-invariant'
import { assertNever } from '@/assertions'
import { DataChannelConfiguration } from '@/lib/interview-sdk-js'
import { labelMicMuted, labelNetworkUnstableLevel } from '@/lib/meetingcomponent/DataChannelMessagingContainer'
import { useCreateInterviewTranscription } from '@/my/pages/hooks/useCreateInterviewTranscription'
import { useRequestEnter, useRequestGetInterviewQuality, useRequestGetParticipant } from '@/my/services/bffService'
import { cacheKey } from '@/my/services/cacheKeyService'
import { useModal } from '@/shared/hooks/useModal'
import { useChangedInterviewQuality } from '@/shared/services/interviewService/hooksForApp/useChangedInterviewQuality'
import { useParticipantEnteredEventHandler } from '@/shared/services/interviewService/hooksForApp/useParticipantEnteredEventHandler'
import { useSoraConnectionProps } from '@/shared/services/interviewService/hooksForApp/useSoraConnectionProps'
import {
  InterviewQuality,
  isSoraPushData,
  SignalingPoints,
  SignalingPushMessageData,
} from '@/shared/services/interviewService/types'
import { InterviewContainer } from '../../containers/InterviewContainer'
import { mySlice } from '../../redux/mySlice'
import { RootState } from '../../redux/store'
import { SessionToken } from './useSessionToken'

type Params = {
  sessionToken: SessionToken
  signalingPoints: SignalingPoints
}

function useInRoom(params?: Params) {
  invariant(params)

  const dispatch = useDispatch()

  const { interviewGuid, ws } = InterviewContainer.useContainer()
  const { requestEnter } = useRequestEnter()
  const { requestGetParticipant } = useRequestGetParticipant()

  const invitationToken = useSelector((state: RootState) => state.my.invitationToken)
  invariant(invitationToken)

  const ownName = useSelector((state: RootState) => state.my.ownName)
  invariant(ownName)

  const dataChannels = useMemo<DataChannelConfiguration[]>(
    () => [
      { label: labelMicMuted, direction: 'sendrecv' },
      { label: labelNetworkUnstableLevel, direction: 'sendrecv' },
    ],
    []
  )
  const mainPublisherProps = useSoraConnectionProps(
    params.signalingPoints.main,
    ws,
    params.sessionToken.user,
    invitationToken,
    dataChannels
  )
  const screenSharingPublisherProps = useSoraConnectionProps(
    params.signalingPoints.screenSharing,
    ws,
    params.sessionToken.screenShare,
    invitationToken
  )
  const screenSharingSubscriberProps = useSoraConnectionProps(
    params.signalingPoints.screenSharing,
    ws,
    params.sessionToken.screenShareRecv,
    invitationToken
  )

  const { requestGetInterviewQuality } = useRequestGetInterviewQuality()
  const callGetInterviewQualityRpc = useCallback(async (): Promise<InterviewQuality> => {
    const res = await requestGetInterviewQuality(interviewGuid)
    return {
      mode: res.getMode() === BffInterviewQualityMode.LOW ? 'low' : 'normal',
      videoBitRate: res.getVideoBitRate(),
      videoFrameRate: res.getVideoFrameRate(),
    }
  }, [requestGetInterviewQuality, interviewGuid])
  const initialInterviewQuality = useCachedPromise(
    cacheKey.getInterviewQualityInRoom({ interviewGuid }),
    callGetInterviewQualityRpc
  )
  const [changedInterviewQuality, handleChangedInterviewQuality] =
    useChangedInterviewQuality(callGetInterviewQualityRpc)
  const interviewQuality = changedInterviewQuality || initialInterviewQuality

  const callEnterRpc = useCallback(
    async (soraClientId: string) => {
      const res = await requestEnter({
        interviewGuid,
        soraClientId,
        name: ownName,
      })
      return res.toObject()
    },
    [interviewGuid, ownName, requestEnter]
  )

  const callGetParticipantRpc = useCallback(
    async (soraClientId: string) => {
      const res = await requestGetParticipant({
        interviewGuid,
        soraClientId,
        invitationToken,
      })
      return res.toObject()
    },
    [interviewGuid, invitationToken, requestGetParticipant]
  )

  const onReceiveFinishMessage = useCallback(() => {
    dispatch(mySlice.actions.finishMessageReceived())
  }, [dispatch])

  const leaveModal = useModal()

  const { open: openLeaveModal } = leaveModal
  const onRequestFinish = useCallback(() => {
    openLeaveModal()
  }, [openLeaveModal])

  const leaveInterview = useCallback(() => {
    dispatch(mySlice.actions.leavingInterviewRequested())
  }, [dispatch])

  const handleEntered = useParticipantEnteredEventHandler(callGetParticipantRpc)

  const { createInterviewTranscription } = useCreateInterviewTranscription(interviewGuid)

  const onReceivePushEvent = useCallback(
    (data: SignalingPushMessageData): void => {
      if (!isSoraPushData(data)) return
      switch (data.type) {
        case 'APPLICANT_KICK_OUT': {
          dispatch(mySlice.actions.leavingInterviewRequested())
          return
        }
        case 'PARTICIPANT_ENTERED': {
          handleEntered(interviewGuid, data.sora_client_id)
          return
        }
        case 'ENTRY_REQUEST':
          return
        case 'CANCELED_ENTRY_REQUEST':
          return
        case 'CHANGED_QUALITY':
          handleChangedInterviewQuality()
          return
        case 'audio_streaming_result':
          if (data.result.results[0] && data.result.code !== 'o') {
            createInterviewTranscription(data)
          }

          return
        default:
          assertNever(data)
      }
    },
    [handleChangedInterviewQuality, createInterviewTranscription, dispatch, handleEntered, interviewGuid]
  )

  return {
    // interviewService に渡す
    appType: 'my' as const,
    interviewGuid,
    mainPublisherProps,
    screenSharingPublisherProps,
    screenSharingSubscriberProps,
    interviewQuality,
    ws,
    callEnterRpc,
    callGetParticipantRpc,
    onRequestFinish,
    onReceiveFinishMessage,
    onReceivePushEvent,

    // my 側でのみ使う
    leaveModal,
    leaveInterview,
  }
}

export const InRoomContainer = createContainer(useInRoom)
