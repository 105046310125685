import React, { useCallback, useState } from 'react'
import { Modal as _Modal, RadioGroup as _RadioGroup, WeakColorSeparator } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { InterviewQualityMode } from '@/shared/services/interviewService/types'

export type QualityModeModalProps = {
  className?: string
  active: boolean
  onClose: () => void
  onOk: (val: InterviewQualityMode) => Promise<void>
  qualityMode: InterviewQualityMode
}

const RadioLabel = styled.span`
  font-size: 14px;
`

const options = [
  {
    value: 'normal',
    label: <RadioLabel>通常モード</RadioLabel>,
  },
  {
    value: 'low',
    label: <RadioLabel>軽量モード</RadioLabel>,
  },
]

export const QualityModeModal: React.FC<QualityModeModalProps> = ({
  className,
  active,
  onClose,
  onOk,
  qualityMode,
}) => {
  const [current, setCurrent] = useState(qualityMode)
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== 'low' && e.target.value !== 'normal') {
      return
    }
    setCurrent(e.target.value)
  }, [])
  const handleOk = useCallback(() => {
    return onOk(current)
  }, [current, onOk])

  return (
    <Modal className={className} active={active} title="軽量モード設定" onClose={onClose} size="l">
      <Wrapper>
        <Upper>
          <Row>
            <Description>
              モード変更は、すべての参加者に対して実行されます。
              <br />
              変更処理に数秒かかる場合がありますが、すべての参加者が自動的に新しいモードに切り替わります。
            </Description>
          </Row>
          <Row>
            <RadioGroup
              name="quality-mode"
              defaultValue={qualityMode}
              onChange={handleChange}
              direction="column"
              options={options}
            />
          </Row>
          <Row>
            <RadioDesc>画質を落として、通信速度が低い状態でもつながりやすくします。</RadioDesc>
          </Row>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonGroup>
            <CloseButton
              comlinkPushParams={{ action: 'click_close_button_on_quality_mode_modal' }}
              widthSize="L1"
              onClick={onClose}
            >
              キャンセル
            </CloseButton>
            <PrimaryButton
              disabled={current === qualityMode}
              comlinkPushParams={{ action: 'click_ok_button_on_quality_mode_modal' }}
              widthSize="L1"
              onClick={handleOk}
            >
              保存
            </PrimaryButton>
          </ButtonGroup>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Modal = styled(_Modal)`
  width: ${({ theme }) => (theme.responsive.spPortrait ? '360px' : '700px')};
  max-width: 90%;
  max-height: 90%;
`
const Row = styled.div`
  display: flex;
  align-items: center;
`

const Wrapper = styled.div``
const Upper = styled.div`
  padding: ${({ theme }) => (theme.responsive.spPortrait ? '20px 16px' : '21px 20px 50px')};
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseButton = styled(TertiaryButton)`
  margin-right: 10px;
`

const Description = styled.span`
  font-size: 14px;
  line-height: 20px;
`

const RadioDesc = styled.span`
  margin-top: 8px;
  margin-left: 24px;
  .mobile & {
    margin-left: ${24 + 8}px;
  }
  font-size: 12px;
`

const RadioGroup = styled(_RadioGroup)`
  margin-top: 30px;
`
