import { useCallback } from 'react'
import { useRequestCreateSession } from '@/biz/services/bffService'

export const useSessionToken = () => {
  const { requestCreateSession } = useRequestCreateSession()

  const getSessionToken = useCallback(
    async (storageKey: string) => {
      const sessionTokenInLocalStorage = localStorage.getItem(storageKey)
      if (sessionTokenInLocalStorage) return sessionTokenInLocalStorage

      const res = await requestCreateSession()

      const sessionToken = res.getSessionToken()

      localStorage.setItem(storageKey, sessionToken)
      return sessionToken
    },
    [requestCreateSession]
  )

  return { getSessionToken }
}
