import { useState, useCallback } from 'react'
import { toast } from '@blue-agency/rogue'
import { InterviewQuality } from '../types'

export const useChangedInterviewQuality = (
  getInterviewQuality: () => Promise<InterviewQuality>
): [InterviewQuality | undefined, () => void] => {
  const [quality, setQuality] = useState<InterviewQuality>()

  const onChange = useCallback(async () => {
    const q = await getInterviewQuality()
    setQuality(q)

    const str = q.mode === 'low' ? '軽量' : '通常'
    toast(str + 'モードが設定されました')
  }, [getInterviewQuality])

  return [quality, onChange]
}
