import React from 'react'
import { provide } from '@blue-agency/front-state-management'
import { IntervieweeLogType } from '@blue-agency/proton/web/v2/im'
import { theme, WeakColorSeparator, useIsIPad } from '@blue-agency/rogue'
import { Switch, Case, Default } from 'react-if'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { AudioWaveBar } from '@/lib/meetingcomponent/AudioWaveBar'
import { GlobalHeaderHelp } from '@/my/components/GlobalHeaderHelp'
import {
  BackgroundSettingButton as _BackgroundSettingButton,
  BackgroundSettingIcon,
} from '@/shared/components/BackgroundSettingButton'
import { BackgroundSettingModalForBeforeEntered } from '@/shared/components/BackgroundSettingModal'
import { ChangeCameraDropdownOnBeforeEntered } from '@/shared/components/ChangeCameraDropdownOnBeforeEntered'
import { ChangeLightAdjustmentOnBeforeEntered } from '@/shared/components/ChangeLightAdjustmentOnBeforeEntered'
import { ChangeMicDropdownOnBeforeEntered } from '@/shared/components/ChangeMicDropdownOnBeforeEntered'
import { ChangeNoiseSuppressionOnBeforeEntered } from '@/shared/components/ChangeNoiseSuppressionOnBeforeEntered'
import { ChangeSpeakerDropdownOnBeforeEntered } from '@/shared/components/ChangeSpeakerDropdownOnBeforeEntered'
import { Loading } from '@/shared/components/Loading'
import { LowQualityBox } from '@/shared/components/LowQualityBox'
import { PageLayout } from '@/shared/components/PageLayout'
import { useInterviewDisplaySize } from '@/shared/hooks/useInterviewDisplaySize'
import cameraMutedImgSrc from '@/shared/services/interviewService/assets/camera_muted.png'
import unavailableVideoInputImgSrc from '@/shared/services/interviewService/assets/unavailable_video_input.png'
import { BeforeEnteredCameraMuteButton } from '@/shared/services/interviewService/components/controlButtons/BeforeEnteredCameraMuteButton'
import { BeforeEnteredMicMuteButton } from '@/shared/services/interviewService/components/controlButtons/BeforeEnteredMicMuteButton'
import { SharedState } from '@/shared/services/interviewService/redux'
import { InterviewPageContainer } from '../../containers/InterviewPageContainer'
import { RequestEntryForm } from './RequestEntryForm'
import { ManualModalContainer } from './containers/ManualModalContainer'
import { useBeforeEntered } from './useBeforeEntered'

export const BeforeEntered: React.VFC = provide(ManualModalContainer)(() => {
  const ctx = useBeforeEntered()
  const pageCtx = InterviewPageContainer.useContainer()
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isOwnMicMuted = useSelector((state: SharedState) => state.shared.isOwnMicMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)

  const cameraDisabled = ctx.camera.devices === undefined || ctx.camera.error !== null
  const micDisabled = ctx.mic.devices === undefined || ctx.mic.error !== null

  const { responsive } = useInterviewDisplaySize()

  const { isIPad } = useIsIPad()

  if (pageCtx.isLoading) {
    return <Loading />
  }
  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }

  const { sendIntervieweeLog } = pageCtx
  const openBackgroundSettingModalForInterviewee = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_OPEN_BACKGROUND_SETTING)
    ctx.openBackgroundSettingModal()
  }

  const sendMuteLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_MUTE)
  }
  const sendUnmuteLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_UNMUTE)
  }

  const sendOnCameraLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_ON_CAMERA)
  }
  const sendOffCameraLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_OFF_CAMERA)
  }

  const sendTurnOnNoiseSuppressionLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_TURN_ON_NOISE_SUPPRESSION)
  }
  const sendTurnOffNoiseSuppressionLog = () => {
    sendIntervieweeLog(IntervieweeLogType.INTERVIEWEE_LOG_TYPE_TURN_OFF_NOISE_SUPPRESSION)
  }

  return (
    <PageLayout
      globalHeader={{
        rightNode: <GlobalHeaderHelp />,
        disableLogoLink: true,
      }}
    >
      {pageCtx.qualityMode === 'low' && <LowQualityBox />}
      <Wrapper>
        <Inner>
          <VideoWrapper>
            <VideoInner>
              <Switch>
                <Case condition={isUnavailableVideoInput}>
                  <Img src={unavailableVideoInputImgSrc} />
                </Case>
                <Case condition={isOwnCameraMuted}>
                  <Img src={cameraMutedImgSrc} />
                </Case>
                <Default>
                  <MyVideo ref={ctx.videoElRef} autoPlay playsInline muted />
                </Default>
              </Switch>
              <WaveCircle>
                <AudioWaveBar width="24" height="24" audioStream={ctx.mic.stream} muted={isOwnMicMuted} />
              </WaveCircle>
              <MuteButtonWrapper>
                <BeforeEnteredMicMuteButton
                  disabled={micDisabled}
                  sendMuteLog={sendMuteLog}
                  sendUnmuteLog={sendUnmuteLog}
                />
                <BeforeEnteredCameraMuteButton
                  disabled={cameraDisabled}
                  sendOnCameraLog={sendOnCameraLog}
                  sendOffCameraLog={sendOffCameraLog}
                />
              </MuteButtonWrapper>
              {responsive.pc && !isIPad && (
                <>
                  <BackgroundSettingButton
                    // biz側との重複は想定内
                    // eslint-disable-next-line @blue-agency/react/unique-action-name
                    comlinkPushParams={{ action: 'open_background_setting_modal' }}
                    icon={<BackgroundSettingIcon />}
                    onClick={openBackgroundSettingModalForInterviewee}
                    disabled={isUnavailableVideoInput}
                  />
                  <BackgroundSettingModalForBeforeEntered
                    active={ctx.isOpenBackgroundSettingModal}
                    onClose={ctx.closeBackgroundSettingModal}
                    appType="my"
                    videoElRef={ctx.videoElRef}
                    backgroundEffect={ctx.backgroundEffect}
                    onChangeBackgroundEffect={ctx.changeBackgroundEffect}
                    backgroundUserImage={ctx.backgroundUserImage}
                    saveBackgroundUserImage={ctx.saveBackgroundUserImage}
                  />
                </>
              )}
            </VideoInner>
            {ctx.isChangeCameraEnabled && (
              <ChangeDeviceDropdownWrapper>
                <ChangeCameraDropdownOnBeforeEntered
                  cameraDevices={ctx.camera.devices ?? []}
                  selectedCameraDeviceId={ctx.camera.selectedDeviceId}
                  onChangeDevice={ctx.camera.changeDevice}
                />
              </ChangeDeviceDropdownWrapper>
            )}
            {ctx.isChangeMicEnabled && (
              <ChangeDeviceDropdownWrapper>
                <ChangeMicDropdownOnBeforeEntered
                  micDevices={ctx.mic.devices ?? []}
                  selectedMicDeviceId={ctx.mic.selectedDeviceId}
                  onChangeDevice={ctx.mic.changeDevice}
                />
              </ChangeDeviceDropdownWrapper>
            )}
            {ctx.isChangeSpeakerEnabled && (
              <ChangeDeviceDropdownWrapper>
                <ChangeSpeakerDropdownOnBeforeEntered
                  speakerDevices={ctx.speaker.devices ?? []}
                  selectedSpeakerDeviceId={ctx.speaker.selectedDeviceId}
                  onChangeDevice={ctx.speaker.changeDevice}
                />
              </ChangeDeviceDropdownWrapper>
            )}
            {ctx.isChangeNoiseSuppressionEnabled && (
              <ChangeNoiseSuppressionOnBeforeEntered
                enabledNoiseSuppression={!!ctx.enabledNoiseSuppression}
                onChangeNoiseSuppression={ctx.setEnabledNoiseSuppression}
                sendTurnOnNoiseSuppression={sendTurnOnNoiseSuppressionLog}
                sendTurnOffNoiseSuppression={sendTurnOffNoiseSuppressionLog}
              />
            )}
            {ctx.isChangeLightAdjustmentEnabled && (
              <ChangeLightAdjustmentOnBeforeEntered
                enabledLightAdjustment={!!ctx.enabledLightAdjustment}
                onChangeLightAdjustment={ctx.setEnabledLightAdjustment}
              />
            )}
          </VideoWrapper>
          <SideBox>
            <RequestEntryForm />
            <SideBoxItemSeparator />
          </SideBox>
        </Inner>
      </Wrapper>
    </PageLayout>
  )
})

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
`

const Inner = styled.div`
  width: 100%;
  justify-content: center;
  ${({ theme }) => {
    if (theme.responsive.pc || theme.responsive.spLandscape) {
      return css`
        display: flex;
        margin-top: 20px;
        padding: 0 16px;
      `
    }
  }}
  ${({ theme }) => {
    if (theme.responsive.pc) {
      return css`
        margin-top: 44px;
      `
    }
  }}
`

const VideoWrapper = styled.div`
  flex: 1;
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.pc) {
      return css`
        margin-right: 20px;
        max-width: 400px;
      `
    }
  }}
  ${({ theme }) => {
    if (theme.responsive.spPortrait) {
      return css`
        margin-bottom: 16px;
      `
    }
  }}
`

const VideoInner = styled.div`
  position: relative;
  padding-top: calc(100% * 9 / 16);
`

const WaveCircle = styled.div`
  position: absolute;
  left: 8px;
  bottom: 9px;
  width: 24px;
  height: 24px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.black[1]};
  ${(props) => {
    if (props.theme.responsive.spLandscape || props.theme.responsive.pc) {
      return css`
        margin-right: 20px;
        border: 1px solid ${theme.color.gray[3]};
        border-radius: 4px;
      `
    }
  }}
`

const MyVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100%;
  height: 100%;

  // 自分を映す video なので左右反転させる
  transform: scaleX(-1);

  background-color: ${theme.color.black[1]};

  ${(props) => {
    if (props.theme.responsive.spLandscape || props.theme.responsive.pc) {
      return css`
        margin-right: 20px;
        border: 1px solid ${theme.color.gray[3]};
        border-radius: 4px;
      `
    }
  }}
`

const MuteButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 132px;
  height: 52px;
  background-color: rgba(255, 255, 255, 0.5);
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`

const ChangeDeviceDropdownWrapper = styled.div`
  margin-top: 20px;
`

const SideBox = styled.div`
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.pc) {
      return css`
        // NOTE: viewport に対して、padding を持たせる
        padding-bottom: 16px;
        min-width: 435px;
      `
    }
  }}
  ${({ theme }) => {
    if (theme.responsive.spPortrait) {
      return css`
        padding: 0 16px;
        width: 100%;
        max-width: 435px;
        margin: 0 auto;
      `
    }
  }}
`

const BackgroundSettingButton = styled(_BackgroundSettingButton)`
  position: absolute;
  bottom: 8px;
  right: 9px;
`

const SideBoxItemSeparator = styled(WeakColorSeparator)`
  margin: 20px 0;
`
