import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ConnectionPublisher } from '@/lib/interview-sdk-js'
import { ConnectionStatsSummary } from '@/shared/services/interviewService/ConnectionStatsSummary'
import { sharedSlice } from '@/shared/services/interviewService/redux'

const WATCH_INTERVAL = 5000

export const useWatchWebRtcStats = (publisher: ConnectionPublisher) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const id = setInterval(async () => {
      if (!publisher.connectionId) return
      const stats = await publisher.pc?.getStats()
      if (stats === undefined) return

      dispatch(sharedSlice.actions.updateConnectionStatsSummary(summarize(stats)))
    }, WATCH_INTERVAL)
    return () => {
      clearInterval(id)
    }
  }, [dispatch, publisher])
}

const summarize = (stats: RTCStatsReport): ConnectionStatsSummary => {
  const summary: ConnectionStatsSummary = {}
  stats.forEach((v) => {
    if (v.type === 'outbound-rtp' && v.kind === 'video' && v.qualityLimitationReason) {
      summary.qualityLimitationReason = v.qualityLimitationReason
    }
    if (v.type === 'candidate-pair' && v.totalRoundTripTime !== undefined && v.responsesReceived !== undefined) {
      summary.rtt = v.totalRoundTripTime / v.responsesReceived
    }
    if (v.type === 'media-source' && v.kind === 'video' && v.framesPerSecond !== undefined) {
      summary.videoSrcFps = v.framesPerSecond
    }
  })
  summary.protocol = findRelayProtocol(stats)

  return summary
}

export const __summarizeForTest = summarize

// based on https://github.com/blue-agency/react-environment-check/blob/c655637f9161c5442bf20abae4780ad40f640f44/src/getRelayProtocol.ts
function findRelayProtocol(stats: RTCStatsReport): string | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const transportList: any[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const candidatePairList: any[] = []
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const localCandidateList: any[] = []

  stats.forEach((stat) => {
    const type = stat.type as string
    if (type === 'transport') {
      transportList.push(stat)
    } else if (type === 'candidate-pair') {
      candidatePairList.push(stat)
    } else if (type === 'local-candidate') {
      localCandidateList.push(stat)
    }
  })

  let protocol: string | undefined = undefined

  if (transportList.length > 0) {
    transportList.some((transport) => {
      if (transport.dtlsState !== 'connected') {
        return false
      }
      const candidatePair = candidatePairList.find((cp) => transport.selectedCandidatePairId === cp.id)
      if (!candidatePair) {
        return false
      }
      const localCandidate = localCandidateList.find((lc) => candidatePair.localCandidateId === lc.id)
      if (!localCandidate.relayProtocol) {
        return false
      }
      protocol = localCandidate.relayProtocol
      return true
    })
  } else {
    candidatePairList.some((candidatePair) => {
      if (!candidatePair.selected) {
        return false
      }
      const localCandidate = localCandidateList.find((lc) => candidatePair.localCandidateId === lc.id)
      if (!localCandidate.relayProtocol) {
        return false
      }
      protocol = localCandidate.relayProtocol
      return true
    })
  }

  return protocol
}
