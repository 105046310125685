import React, { useMemo } from 'react'
import { theme } from '@blue-agency/rogue'
import { calcAudioVolumeBarHeights } from './calcAudioVolumeBarHeights'

export interface AudioWaveBarProps {
  volume: number | undefined
  width: number
  height: number
  baseBarHeight?: number
  centerMaxBarHeight?: number
  sideMaxBarHeight?: number
  barWidth?: number
  barPadding?: number
  barColor?: string
}

export const AudioWaveBar: React.VFC<AudioWaveBarProps> = (props) => {
  const waveBarLength = 3
  const baseBarHeight = props.baseBarHeight ?? 1
  const centerMaxBarHeight = props.centerMaxBarHeight ?? props.height * 0.8
  const sideMaxBarHeight = props.sideMaxBarHeight ?? (centerMaxBarHeight * 8) / 13

  const barHeights = useMemo(() => {
    if (!props.volume) return [...Array(waveBarLength)].map(() => baseBarHeight)

    return calcAudioVolumeBarHeights(props.volume, {
      waveBarLength,
      baseBarHeight,
      centerMaxBarHeight,
      sideMaxBarHeight,
    })
  }, [props.volume, baseBarHeight, centerMaxBarHeight, sideMaxBarHeight])

  return (
    <AudioWaveBarSvg
      width={props.width}
      height={props.height}
      barHeights={barHeights}
      barWidth={props.barWidth ?? 3}
      barPadding={props.barPadding ?? 3}
      barColor={props.barColor ?? theme.color.white[1]}
    />
  )
}

interface AudioWaveBarSvgProps {
  width: number
  height: number
  barHeights: number[]
  barWidth: number
  barPadding: number
  barColor: string
}

const AudioWaveBarSvg: React.VFC<AudioWaveBarSvgProps> = ({
  width,
  height,
  barHeights,
  barWidth,
  barPadding,
  barColor,
}) => {
  const viewBox = useMemo(() => `0 0 ${width} ${height}`, [width, height])
  const startPosition = width / 2 - barWidth / 2 - barPadding - barWidth
  return (
    <svg width={width} height={height} viewBox={viewBox} version="1.1" xmlns="http://www.w3.org/2000/svg">
      {barHeights.map((barHeight, i) => (
        <AudioWaveBarSvgLine
          key={i}
          y0={height / 2}
          x={i * (barWidth + barPadding) + startPosition}
          h={barHeight}
          barWidth={barWidth}
          barColor={barColor}
        />
      ))}
    </svg>
  )
}

interface AudioWaveBarSvgLineProps {
  x: number
  h: number
  y0: number
  barWidth: number
  barColor: string
}

const AudioWaveBarSvgLine: React.VFC<AudioWaveBarSvgLineProps> = ({ x, h, y0, barColor, barWidth }) => {
  return (
    <line
      strokeLinecap="round"
      x1={x + barWidth / 2}
      y1={y0 - h / 2}
      x2={x + barWidth / 2}
      y2={y0 + h / 2}
      stroke={barColor}
      strokeWidth={barWidth}
    />
  )
}
