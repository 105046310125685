export const BIZ_EXTERNAL_URLS = {
  help: {
    top: 'https://im-cp.zendesk.com/hc/ja/sections/4403938632729',
    interview: 'https://im-cp.zendesk.com/hc/ja/articles/900005557463',
    screenShare: 'https://im-cp.zendesk.com/hc/ja/articles/900004593726',
    environmentCheck: 'https://im-cp.zendesk.com/hc/ja/articles/4404334432793',
    changeDevice: 'https://im-cp.zendesk.com/hc/ja/articles/900005708983',
    backgroundSetting: 'https://im-cp.zendesk.com/hc/ja/articles/4407392604185',
    summarization: 'https://im-cp.zendesk.com/hc/ja/articles/30381336884249',
  },
  survey:
    'https://docs.google.com/forms/d/e/1FAIpQLScLUzV_5Wq6_Y7_0JoZiXARKuWBeNyJyw3OMto-NjVLC0dQGQ/viewform?usp=pp_url&entry.848323147=',
  privacyPolicy: 'https://interview-maker.jp/terms/privacy-policy/',
  terms: 'https://interview-maker.jp/terms/terms-of-service/',
}
