import React, { useCallback } from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'

export const Finished: React.VFC = () => {
  const openMannedSupportPage = useCallback(() => {
    window.open(MY_EXTERNAL_URLS.help.environment.mannedSupport, '_blank')?.focus()
  }, [])
  return (
    <Wrapper>
      <FinishTxt>全てのテストが完了しました。</FinishTxt>
      <ButtonWrapper>
        <NextButton comlinkPushParams={{ action: 'open_manned_support_page' }} onClick={openMannedSupportPage}>
          次に進む
        </NextButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
const FinishTxt = styled(Txt)`
  text-align: center;
  margin-top: 4px;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 18px;
  border-top: 1px solid ${theme.color.gray[4]};
  background-color: ${theme.color.white[1]};
  height: 81px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`
const NextButton = styled(PrimaryButton).attrs({ widthSize: 'MAX' })`
  max-width: 328px;
  border: 1px solid ${theme.color.white[1]};
`
