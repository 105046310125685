import React from 'react'
import { InPageChatBot } from '@blue-agency/im-shared-front'
import { Provider, useSelector } from 'react-redux'
import { BeforeEntered } from './BeforeEntered'
import { Finished } from './Finished'
import { InRoom } from './InRoom'
import { RootState, store } from './redux/store'

export const Interview: React.VFC = () => {
  return (
    <Provider store={store}>
      <Inner />
    </Provider>
  )
}

const Inner: React.VFC = () => {
  const interviewState = useSelector((state: RootState) => state.biz.interviewState)
  const ownState = useSelector((state: RootState) => state.biz.ownState)

  if (interviewState === 'Finished') {
    return (
      <>
        <InPageChatBot botType="biz" />
        <Finished />
      </>
    )
  }

  switch (ownState) {
    case 'BeforeEntered':
      return (
        <>
          <InPageChatBot botType="biz" />
          <BeforeEntered />
        </>
      )
    case 'InRoom':
      return <InRoom />
    case 'Leaved':
      return <Finished leave />
  }
}
