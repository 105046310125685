import React, { useEffect } from 'react'
import { PageLayoutWithGlonavi, usePromptBeforeUnload } from '@blue-agency/im-shared-front'
import { EnvironmentCheck as EnvironmentCheckComponent } from '@blue-agency/react-environment-check'
import { theme } from '@blue-agency/rogue'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { checkBrowser } from '@/shared/services/browserCheckService'
import { InterviewPageContainer } from '../containers/InterviewPageContainer'
import { useEnvironmentCheck } from './useEnvironmentCheck'

export const EnvironmentCheck: React.VFC = () => {
  usePromptBeforeUnload()

  const pageCtx = InterviewPageContainer.useContainer()

  const { protocolCheckConnectionProps } = useEnvironmentCheck()

  useEffect(() => {
    const originalBodyHeight = document.body.style.height
    document.body.style.height = '100%'

    return () => {
      document.body.style.height = originalBodyHeight
    }
  }, [])

  if (pageCtx.isLoading) {
    // TODO: ローディング表示する
    return null
  }

  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }

  return (
    <PageLayoutWithGlonavi backgroundColor={theme.color.gray[5]}>
      <EnvironmentCheckComponent
        userType="interviewer"
        interview={{
          name: pageCtx.name,
          durationMinutes: pageCtx.durationMinutes,
          scheduledStartDate: pageCtx.scheduledStartTime,
        }}
        steps={[
          {
            name: 'overallCheck',
            helpUrl: BIZ_EXTERNAL_URLS.help.environmentCheck,
            checkBrowserFunction: checkBrowser,
            ...protocolCheckConnectionProps,
          },
        ]}
        onFinish={pageCtx.finishEnvironmentCheck}
      />
    </PageLayoutWithGlonavi>
  )
}
