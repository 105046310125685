const communicationsDeviceId = 'communications'
const defaultDeviceId = 'default'

export const findCandidateDevice = (devices: MediaDeviceInfo[]): MediaDeviceInfo | undefined => {
  // "通信"優先
  const communicationsDevice = devices.find((d) => d.deviceId === communicationsDeviceId)
  if (communicationsDevice) {
    return communicationsDevice
  }
  // 次に"既定"
  const defaultDevice = devices.find((d) => d.deviceId === defaultDeviceId)
  if (defaultDevice) {
    return defaultDevice
  }
  // なければ先頭にする
  return devices[0]
}
