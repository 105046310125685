import React from 'react'
import { TertiaryButton, Icon } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ScreenShareUiContainer } from '../../containers/ScreenShareUiContainer'
import { isInterviewReadySelector, SharedState } from '../../redux'
import { controlButtonBase } from './controlButtonBase'

export const ScreenShareButton: React.VFC = React.memo(() => {
  const { openStartScreenShareModal, openFinishScreenShareModal } = ScreenShareUiContainer.useContainer()
  const isRunningOwnScreenShare = useSelector((state: SharedState) => state.shared.isRunningOwnScreenShare)
  const isInterviewReady = useSelector(isInterviewReadySelector)

  const disabled = !isInterviewReady

  return (
    <ScreenShareButtonConsumer
      openStartScreenShareModal={openStartScreenShareModal}
      openFinishScreenShareModal={openFinishScreenShareModal}
      disabled={disabled}
      isRunningOwnScreenShare={isRunningOwnScreenShare}
    />
  )
})

type ConsumerProps = {
  openStartScreenShareModal: () => void
  openFinishScreenShareModal: () => void
  disabled: boolean
  isRunningOwnScreenShare: boolean
}

export const ScreenShareButtonConsumer: React.VFC<ConsumerProps> = React.memo((props) => {
  return props.isRunningOwnScreenShare ? (
    <Button
      text="共有停止"
      onClick={props.openFinishScreenShareModal}
      icon={<Icon name="screen-share-active" size="l" />}
      direction="column"
      disabled={props.disabled}
      comlinkPushParams={{
        action: 'click_screen_share_active_button',
      }}
    />
  ) : (
    <Button
      text="画面共有"
      onClick={props.openStartScreenShareModal}
      icon={<Icon name="screen-share" size="l" />}
      direction="column"
      disabled={props.disabled}
      comlinkPushParams={{
        action: 'click_screen_share_button',
      }}
    />
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const Button = styled(TertiaryButton)`
  ${controlButtonBase};
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`
