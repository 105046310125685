import React from 'react'
import { theme, Icon } from '@blue-agency/rogue'
import { useSessionStorage } from 'react-use'
import styled, { css } from 'styled-components'
import { SESSION_STORAGE_KEY } from '@/shared/services/storageService'

type BasePosition = { top: number; left: number }

type Props = {
  basePosition: BasePosition
}

// 既存のビューから議事録ビューに変更できる旨のメッセージを表示する吹き出し
export const OldViewChangeInfoBalloon: React.VFC<Props> = ({ basePosition }) => {
  // 1度閉じたら面接中は表示しないためにsession storageにストアする
  const [closedMinutesViewChangeInfoInOldView, setClosedMinutesViewChangeInfoInOldView] = useSessionStorage(
    SESSION_STORAGE_KEY.closedMinutesViewChangeInfoInOldView,
    false
  )

  const onClose = () => {
    setClosedMinutesViewChangeInfoInOldView(true)
  }

  if (closedMinutesViewChangeInfoInOldView) {
    return null
  }

  return (
    <Wrapper basePosition={basePosition}>
      <Balloon>
        <Row>
          <Line>文字起こし、プライベートメモがご利用いただけます。</Line>
          <WarningLine>※画面レイアウトが変更されます。</WarningLine>
        </Row>
        <CloseIcon onClick={onClose} />
      </Balloon>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  basePosition: BasePosition
}>`
  position: absolute;
  z-index: 98;
  ${({ basePosition, theme: { responsive } }) => {
    return css`
      top: ${basePosition.top}px;
      left: ${basePosition.left}px;
    `
  }}
`

const Balloon = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 400px;
  background-color: ${theme.color.green[4]};
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  color: ${theme.color.white[1]};
  text-align: left;
  padding: 12px;
  border: 2px solid ${theme.color.green[4]};
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  &:before {
    content: '';
    position: absolute;
    top: 73px;
    left: 10px;
    border: 8px solid transparent;
    border-top: 8px solid ${theme.color.green[4]};
    z-index: 2;
  }
`

const Row = styled.div`
  width: 100%;
  display: 'block';
`
const Line = styled.p`
  display: inline-block;
  width: 100%;
`

const WarningLine = styled.div`
  display: inline-block;
  width: 100%;
  font-size: 12px;
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  cursor: pointer;
`
