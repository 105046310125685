import React from 'react'
import { theme, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName

export const WaitingEntryRequestMessage = (props: Props): React.ReactElement => {
  return (
    <Wrapper className={props.className}>
      <Txt size="l">応募者の参加リクエストを待っています。</Txt>
      <Txt>参加リクエストを承諾すると面接が開始されます。</Txt>
    </Wrapper>
  )
}

export function MinutesViewWaitingEntryRequestMessage(props: Props) {
  return (
    <Wrapper className={props.className}>
      <Txt size="l" color={theme.color.green[4]}>
        応募者の参加リクエストを待っています。
      </Txt>
      <Txt>参加リクエストを承認すると面接が開始されます。</Txt>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: ${theme.color.white[1]};
  height: 90px;

  > *:not(:first-child) {
    margin-top: 8px;
  }
`
