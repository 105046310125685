import React, { useEffect, useState } from 'react'
import { GetSignalingPointsResponse } from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import { alertToast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'
import invariant from 'tiny-invariant'
import { useRequestGetSignalingPoints } from '@/biz/services/bffService'
import { LOCAL_STORAGE_KEY } from '@/biz/services/storageService'
import { InterviewRoom } from '@/shared/services/interviewService'
import { MinutesViewContainer } from '@/shared/services/interviewService/containers/MinutesViewContainer'
import { SignalingPoints } from '@/shared/services/interviewService/types'
import { FinishModal } from './FinishModal'
import { KickoutAllIntervieweeModal } from './KickoutAllIntervieweeModal'
import { PendingEntryRequestsComponent as _PendingEntryRequestsComponent } from './PendingEntryRequestsComponent'
import { InRoomContainer } from './containers/InRoomContainer'
import { useEntryRequest } from './hooks/useEntryRequest'
import { useSessionToken } from './hooks/useSessionToken'

export type SessionToken = {
  user: string
  screenShare: string
  screenShareRecv: string
}

export const InRoom: React.VFC = () => {
  const { interviewGuid } = useParams<{ interviewGuid: string }>()
  const [sessionToken, setSessionToken] = useState<SessionToken>()
  const [signalingPoints, setSignalingPoints] = useState<SignalingPoints>()
  const { getSessionToken } = useSessionToken()
  const { requestGetSignalingPoints } = useRequestGetSignalingPoints()

  useEffect(() => {
    const setSessionTokenFn = async () => {
      const token = await Promise.all([
        getSessionToken(LOCAL_STORAGE_KEY.interviewSessionToken.user),
        getSessionToken(LOCAL_STORAGE_KEY.interviewSessionToken.screenShare),
        getSessionToken(LOCAL_STORAGE_KEY.interviewSessionToken.screenShareRecv),
      ])
      setSessionToken({
        user: token[0],
        screenShare: token[1],
        screenShareRecv: token[2],
      })
    }

    const setSignalingPointsFn = async () => {
      let res: GetSignalingPointsResponse
      try {
        res = await requestGetSignalingPoints(interviewGuid)
      } catch (err) {
        Sentry.captureException(err)
        alertToast('接続先の取得に失敗しました。再読み込みしてください。')
        return
      }
      const obj = res.toObject()
      invariant(obj.main)
      invariant(obj.screenSharing)
      setSignalingPoints({ main: obj.main, screenSharing: obj.screenSharing })
    }

    setSessionTokenFn()
    setSignalingPointsFn()
  }, [getSessionToken, requestGetSignalingPoints, interviewGuid])

  if (sessionToken && signalingPoints) {
    return (
      <InRoomContainer.Provider initialState={{ sessionToken, signalingPoints }}>
        <MinutesViewContainer.Provider>
          <InRoomProvided />
        </MinutesViewContainer.Provider>
      </InRoomContainer.Provider>
    )
  }
  // TODO: ローディング表示
  return null
}

const InRoomProvided: React.VFC = () => {
  const inRoomCtx = InRoomContainer.useContainer()
  const { isMinutesView } = MinutesViewContainer.useContainer()

  useEntryRequest()
  return (
    <Wrapper>
      <InterviewRoom {...inRoomCtx} />
      <PendingEntryRequestsComponent isMinutesView={isMinutesView} />
      <FinishModal />
      <KickoutAllIntervieweeModal />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  position: relative;
  display: flex;
`

const PendingEntryRequestsComponent = styled(_PendingEntryRequestsComponent)<{ isMinutesView: boolean }>`
  position: absolute;
  // WaitingEntryRequestMessageと被った場合を考えてそれより大きくする
  z-index: 4;

  width: 332px;

  ${({ isMinutesView }) => {
    if (isMinutesView) {
      return css`
        right: 20px;
        bottom: 20px;
      `
    } else {
      return css`
        left: 20px;
        bottom: 74px;
      `
    }
  }}
`
