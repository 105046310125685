import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ChangeInterviewQualityRequest, InterviewQualityMode } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestChangeInterviewQuality() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestChangeInterviewQuality = useCallback(
    async (interviewGuid: string, mode: InterviewQualityMode) => {
      const metadata = await buildMetadata()

      const req = new ChangeInterviewQualityRequest()

      req.setInterviewGuid(interviewGuid)
      req.setMode(mode)

      return callBff('changeInterviewQuality', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestChangeInterviewQuality }
}
