import { useCallback } from 'react'
import { comlinkPush, CustomGrpcError } from '@blue-agency/im-shared-front'
import { alertToast } from '@blue-agency/rogue'
import { useDispatch } from 'react-redux'
import invariant from 'tiny-invariant'
import { sharedSlice } from '../redux'

interface GetParticipantResponse {
  participant?: {
    soraClientId: string
    name: string
  }
}

export function useParticipantEnteredEventHandler(
  callGetParticipantRpc: (soraClientId: string) => Promise<GetParticipantResponse>
) {
  const dispatch = useDispatch()

  return useCallback(
    async (interviewGuid: string, soraClientId: string) => {
      try {
        const res = await callGetParticipantRpc(soraClientId)
        invariant(res.participant)

        dispatch(
          sharedSlice.actions.participantEntered({
            soraClientId: res.participant.soraClientId,
            name: res.participant.name,
          })
        )
      } catch (e) {
        if (e instanceof CustomGrpcError) {
          comlinkPush({
            action: 'failed_to_getParticipant',
            metadata: {
              interviewGuid: interviewGuid,
              soraClientId: soraClientId,
              errorMessage: e.message,
            },
          })
          alertToast('参加者の取得に失敗しました。再読み込みしてください。')
          return
        }
      }
    },
    [callGetParticipantRpc, dispatch]
  )
}
