import React, { useContext, useEffect, useReducer } from 'react'
import { alertToast, theme, Txt } from '@blue-agency/rogue'
import styled, { ThemeContext } from 'styled-components'
import { InterviewPageContainer as BizInterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { InterviewPageContainer as MyInterviewPageContainer } from '@/my/pages/InterviewPage/containers/InterviewPageContainer'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { InterviewTimerContainer, Seconds } from '../../containers/InterviewTimerContainer'
import { timerFormat } from './timerFormat'

const MAX_END_TIME_SECONDS: Seconds = 180 * 60
const AUTO_FINISH_TOAST_APPEAR_SECONDS = {
  FIRST: 170 * 60,
  FINAL: 177 * 60,
}

export const Timer: React.VFC = () => {
  const { appType } = InterviewContainer.useContainer()
  return appType === 'my' ? <MyTimer /> : <BizTimer />
}

export const BizTimer: React.VFC = () => {
  const { time: current } = InterviewTimerContainer.useContainer()
  const { responsive } = useContext(ThemeContext)
  const webInterview = BizInterviewPageContainer.useContainer()

  useAutoFinishAlert(current)

  if (webInterview.isLoading || !webInterview.isValid) {
    return null
  }

  const duration: Seconds = webInterview.durationMinutes ? webInterview.durationMinutes * 60 : MAX_END_TIME_SECONDS

  if (responsive.pc) {
    return (
      <Txt color={theme.color.navy[1]}>
        <CurrentTime current={current} duration={duration} /> / {timerFormat(duration)}
      </Txt>
    )
  }
  return (
    <Txt size="s" color={theme.color.navy[1]}>
      <CurrentTime current={current} duration={duration} /> / {timerFormat(duration)}
    </Txt>
  )
}

export const MyTimer: React.VFC = () => {
  const { time: current } = InterviewTimerContainer.useContainer()
  const { responsive } = useContext(ThemeContext)
  const webInterview = MyInterviewPageContainer.useContainer()

  useAutoFinishAlert(current)

  if (webInterview.isLoading || !webInterview.isValid) {
    return null
  }

  if (responsive.pc) {
    return <Txt color={theme.color.navy[1]}>{timerFormat(current)}</Txt>
  }
  return (
    <Txt size="s" color={theme.color.navy[1]}>
      {timerFormat(current)}
    </Txt>
  )
}

const CurrentTime = ({ current, duration }: { current: Seconds; duration: Seconds }) => {
  if (current > duration) {
    return <OverDuration>{timerFormat(current)}</OverDuration>
  } else {
    return <>{timerFormat(current)}</>
  }
}

const OverDuration = styled.span`
  color: ${theme.color.red[2]};
`

const useAutoFinishAlert = (current: Seconds) => {
  type AlertState = 'none' | 'first' | 'final'
  type AlertAction = { type: 'TO_FIRST' } | { type: 'TO_FINAL' }

  const [state, dispatch] = useReducer((_: AlertState, action: AlertAction): AlertState => {
    switch (action.type) {
      case 'TO_FIRST':
        return 'first'
      case 'TO_FINAL':
        return 'final'
    }
  }, 'none')

  useEffect(() => {
    // まもなく終わる面接に再入室した場合には、 state: 'none' の状態から、FIRSTアラートを飛び越えてFINALアラートを表示する必要がある
    if (state !== 'final' && current >= AUTO_FINISH_TOAST_APPEAR_SECONDS.FINAL) {
      alertToast('このルームはまもなく自動終了します。\n面接を終了してください。', { unclosable: true })
      dispatch({ type: 'TO_FINAL' })
      return
    }

    if (state === 'none' && current >= AUTO_FINISH_TOAST_APPEAR_SECONDS.FIRST) {
      // 残り時間に応じてメッセージ中の数値を変える
      // なるべく早めに面接を終わってもらうために、秒以下を切り捨てた数値を表示する
      const rest = Math.floor((MAX_END_TIME_SECONDS - current) / 60)
      alertToast(`このルームはあと${rest}分で自動終了します`, { unclosable: true })
      dispatch({ type: 'TO_FIRST' })
      return
    }
  }, [state, current])
}
