import { useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'

function useManualModal() {
  const [isManualModalShown, setIsManualModalShown] = useState(false)

  const handleManualModalOpen = useCallback(() => {
    setIsManualModalShown(true)
  }, [])
  const handleManualModalClose = useCallback(() => {
    setIsManualModalShown(false)
  }, [])

  return {
    isManualModalShown,
    handleManualModalOpen,
    handleManualModalClose,
  }
}

export const ManualModalContainer = createContainer(useManualModal)
