import React, { useCallback, useRef, useState } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { GetInterviewRecordingResponse, GetInterviewResponse } from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import assert from 'assert'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useMount } from 'react-use'
import { useRequestGetInterview } from '@/biz/services/bffService'
import { useRequestGetInterviewRecording } from '@/biz/services/bffService/useRequestGetInterviewRecording'
import { QUERY_KEY } from '@/biz/services/queryKeyService'
import {
  PageLayout,
  SummaryCard,
  TranscriptionCard,
  PrivateMemoCard,
  DeletedRecording,
} from '@/shared/components/InterviewMinutes'
import { Loading } from '@/shared/components/Loading'
import { NotFinishedPage } from './NotFinishedPage'
import { Recording } from './Recording'
import { usePrivateMemo } from './usePrivateMemo'
import { useSummary } from './useSummary'
import { useTranscription } from './useTranscription'

type Props = {
  interviewGuid: string
  interviewName: string
}

type RecordingInfo =
  | {
      recordingUrl: URL
      recordingStatus: GetInterviewRecordingResponse.RecordingStatus.ENCODED
    }
  | {
      recordingStatus: Exclude<
        GetInterviewRecordingResponse.RecordingStatus,
        GetInterviewRecordingResponse.RecordingStatus.ENCODED
      >
    }

const Content = ({ interviewGuid, interviewName }: Props) => {
  const { requestGetInterviewRecording } = useRequestGetInterviewRecording()
  const loggedInStaff = useLoggedInStaff()
  assert(loggedInStaff)
  const [recordingInfo, setRecordingInfo] = useState<RecordingInfo>({
    recordingStatus: GetInterviewRecordingResponse.RecordingStatus.RECORDING_STATUS_UNKNOWN,
  })

  useMount(async () => {
    const res = await requestGetInterviewRecording(interviewGuid)
    if (res.getRecordingStatus() !== GetInterviewRecordingResponse.RecordingStatus.ENCODED) {
      setRecordingInfo({
        recordingStatus: res.getRecordingStatus(),
      } as RecordingInfo)
      return
    }

    const url = new URL(res.getRecordingUrl())

    setRecordingInfo({
      recordingUrl: url,
      recordingStatus: res.getRecordingStatus(),
    })
  })

  const { summary, handleCreateSummary, status: summaryStatus } = useSummary(interviewGuid)

  const {
    privateMemo,
    handleChange: handleChangePrivateMemo,
    status: privateMemoStatus,
  } = usePrivateMemo(interviewGuid)

  const {
    transcriptions,
    replaceFillerMessage,
    handleCreateGoodPin,
    handleCreateBadPin,
    handleCreateQuestionPin,
    handleDeleteInterviewTranscriptionPinning,
  } = useTranscription(interviewGuid)

  const iframeElement = useRef<HTMLIFrameElement>(null)
  const handleSeekTimeAndPlay = useCallback(
    (time: number) => {
      if (recordingInfo.recordingStatus !== GetInterviewRecordingResponse.RecordingStatus.ENCODED) return
      iframeElement.current?.contentWindow?.postMessage(time, recordingInfo.recordingUrl.origin)
    },
    [recordingInfo]
  )

  return (
    <>
      <PageLayout
        interviewName={interviewName}
        leftColumn={<SummaryCard status={summaryStatus} summary={summary} onCreate={handleCreateSummary} />}
        mainColumn={
          <TranscriptionCard
            loggedInStaffGuid={loggedInStaff.guid}
            replaceFillerMessage={replaceFillerMessage}
            transcriptions={transcriptions}
            handleCreateBadPin={handleCreateBadPin}
            handleCreateGoodPin={handleCreateGoodPin}
            handleCreateQuestionPin={handleCreateQuestionPin}
            handleDeleteInterviewTranscriptionPinning={handleDeleteInterviewTranscriptionPinning}
            handleSeekTimeAndPlay={
              recordingInfo.recordingStatus === GetInterviewRecordingResponse.RecordingStatus.ENCODED
                ? handleSeekTimeAndPlay
                : undefined
            }
          />
        }
        rightColumn={
          <>
            <PrivateMemoCard privateMemo={privateMemo} onChange={handleChangePrivateMemo} status={privateMemoStatus} />
            {recordingInfo.recordingStatus === GetInterviewRecordingResponse.RecordingStatus.DELETED && (
              <DeletedRecording />
            )}
          </>
        }
      />
      {recordingInfo.recordingStatus === GetInterviewRecordingResponse.RecordingStatus.ENCODED && (
        <Recording ref={iframeElement} recordingUrl={recordingInfo.recordingUrl.toString()} />
      )}
    </>
  )
}

export const InterviewMinutesPage: React.VFC = () => {
  const { interviewGuid } = useParams<{ interviewGuid: string }>()
  const { requestGetInterview } = useRequestGetInterview()
  const { data, isLoading } = useQuery([QUERY_KEY.interview, interviewGuid], () => {
    return requestGetInterview(interviewGuid)
  })

  if (isLoading) {
    return <Loading />
  }

  if (data?.getStatus() !== GetInterviewResponse.Status.FINISHED) {
    return <NotFinishedPage />
  }

  return <Content interviewGuid={interviewGuid} interviewName={data.getName() || ''} />
}
