import { useCallback, useEffect } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import styled from 'styled-components'
import { MeetingStatus } from '@/lib/interview-sdk-js'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { VideoPreview as _VideoPreview } from '@/lib/meetingcomponent/VideoPreview'
import { logger } from '@/logger'
import { BackgroundSettingModal, videoPreviewCss } from '@/shared/components/BackgroundSettingModal'
import { useBackgroundEffect } from '@/shared/hooks/useBackgroundEffect'
import { useModal } from '@/shared/hooks/useModal'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { BackgroundEffect, convertToBackgroundEffectOption } from '@/shared/services/interviewService/types'

export const useBackgroundSettingModal = () => {
  const modal = useModal()
  const { appType, interviewGuid } = InterviewContainer.useContainer()
  const { backgroundEffect, backgroundUserImage, changeBackgroundEffect, saveBackgroundUserImage } =
    useBackgroundEffect()

  // 変更はcomlinkMiddlewareでも送信されるが初期値含めて送信するため下記を入れる
  useEffect(() => {
    comlinkPush({
      action: 'selected_background_effect',
      metadata: {
        interviewGuid,
        backgroundEffect,
      },
    })
  }, [backgroundEffect, interviewGuid])

  const { meetingManager } = MeetingManagerContainer.useContainer()

  const onChangeBackgroundEffect = useCallback(
    (backgroundEffect: BackgroundEffect) => {
      changeBackgroundEffect(backgroundEffect)
    },
    [changeBackgroundEffect]
  )

  useEffect(() => {
    const bgOpt = convertToBackgroundEffectOption(backgroundEffect, backgroundUserImage)
    if (!bgOpt) {
      logger.info('backgroundEffectOption is undefined', backgroundEffect, backgroundUserImage)
      return
    }

    // meetingManager開始前に二重に背景処理が走るとエラーになるためガード
    if (meetingManager.meetingStatus !== MeetingStatus.Succeeded) {
      return
    }
    meetingManager.changeBackgroundEffect(bgOpt)
    // 変な操作をして背景変更が割り込んだ場合に反映されないことがないようmeetingStatusをdepsに入れておく
  }, [meetingManager, meetingManager.meetingStatus, backgroundUserImage, backgroundEffect])

  const renderBackgroundSettingModal = () => {
    return (
      <BackgroundSettingModal
        active={modal.active}
        onClose={modal.close}
        appType={appType}
        videoPreview={<VideoPreview />}
        backgroundEffect={backgroundEffect}
        onChangeBackgroundEffect={onChangeBackgroundEffect}
        backgroundUserImage={backgroundUserImage}
        saveBackgroundUserImage={saveBackgroundUserImage}
      />
    )
  }
  return {
    openBackgroundSettingModal: modal.open,
    renderBackgroundSettingModal,
  }
}

const VideoPreview = styled(_VideoPreview)`
  ${videoPreviewCss}
`
