import React from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

type Props = {
  active: boolean
  onClose: () => void
}

export const ScreenShareLimitModal: React.VFC<Props> = ({ active, onClose }) => {
  return (
    <Modal active={active} size="s" title="画面共有の確認" onClose={onClose}>
      <Wrapper>
        <Upper>
          <Txt>他のユーザーが画面共有中は、画面共有を開始できません。</Txt>
        </Upper>
        <Separator />
        <Bottom>
          <TertiaryButton
            widthSize="L1"
            onClick={onClose}
            comlinkPushParams={{ action: 'click_close_screen_sharing_limit_modal_button' }}
          >
            閉じる
          </TertiaryButton>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 16px 20px;
`
const Separator = styled(WeakColorSeparator)`
  margin-top: 35px;
`
const Bottom = styled.div`
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`
