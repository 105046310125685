import { comlinkPush } from '@blue-agency/im-shared-front'
import { MySkywalkerBffServicePromiseClient } from '@blue-agency/proton/my_skywalker_bff'
import { callWithRetry, logRpcErr } from '@/shared/services/bffService'

const hostname = process.env.REACT_APP_MY_API_HOST
if (!hostname) throw new Error('hostname not found')

export const bffClient = new MySkywalkerBffServicePromiseClient(hostname)

if (process.env.REACT_APP_GRPC_WEB_DEVTOOLS_ENABLED === 'true') {
  // @ts-ignore
  const enableDevTools = window.__GRPCWEB_DEVTOOLS__ || (() => {})
  enableDevTools([bffClient])
}

export function callBff<T extends keyof MySkywalkerBffServicePromiseClient>(
  rpcName: T,
  req: Parameters<MySkywalkerBffServicePromiseClient[T]>
): ReturnType<MySkywalkerBffServicePromiseClient[T]> {
  const metadata: Record<string, string> = {
    rpc: rpcName,
  }
  comlinkPush(
    {
      action: 'call_rpc',
      metadata,
    },
    { lazy: true }
  )

  // @ts-expect-error
  return callWithRetry(rpcName, () =>
    // @ts-expect-error
    bffClient[rpcName].apply(bffClient, req).catch((e) => {
      logRpcErr(rpcName, e, undefined)
      throw e
    })
  )
}
