import { useEffect } from 'react'

export function useSetMinHeightToBody() {
  const { body } = document

  useEffect(() => {
    const setMinHeight = () => {
      body.style.minHeight = getWindowHeight() + 'px'
    }

    window.addEventListener('resize', setMinHeight)

    setMinHeight()

    return () => {
      window.removeEventListener('resize', setMinHeight)
    }
    // MEMO: body.style.minHeight を deps に要求されたので、ルールを無効にした
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

function getWindowHeight() {
  return (document && document.documentElement && document.documentElement.clientHeight) || window.innerHeight
}
