import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useRequestCreateInterviewTranscription } from '@/biz/services/bffService'
import { SharedState } from '@/shared/services/interviewService/redux'
import { SoraPushDataAudioStreamingResult } from '@/shared/services/interviewService/types'

export const useInterviewTranscription = (interviewGuid: string) => {
  const soraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  const { requestCreateInterviewTranscription } = useRequestCreateInterviewTranscription()

  const createInterviewTranscription = useCallback(
    async (data: SoraPushDataAudioStreamingResult) => {
      if (soraClientId === undefined) {
        throw new Error('cannot use before entered')
      }
      // MEMO: 自分自身のsoraClientIdなら保存する
      if (soraClientId === data.connection_id) {
        await requestCreateInterviewTranscription({
          interviewGuid,
          soraClientId,
          data,
        })
      }
    },
    [interviewGuid, requestCreateInterviewTranscription, soraClientId]
  )
  return {
    createInterviewTranscription,
  }
}
