import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListPendingEntryRequestsRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

export function useRequestListPendingEntryRequests() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListPendingEntryRequests = useCallback(
    async (interviewGuid: string) => {
      const metadata = await buildMetadata()
      const req = new ListPendingEntryRequestsRequest()
      req.setInterviewGuid(interviewGuid)
      return callBff('listPendingEntryRequests', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestListPendingEntryRequests }
}
