import { useMemo } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import assert from 'assert'
import { useSelector } from 'react-redux'
import type { DropdownItem } from '@/shared/hooks/useDropdown'
import { useDropdown } from '@/shared/hooks/useDropdown'
import { visibleIntervieweesCountSelector } from '@/shared/services/interviewService/redux'
import { InRoomContainer } from '../containers/InRoomContainer'
import { getDropdownItems } from './getDropdownItems'

const notDisplayFinishButtonOrganizationGUIDList = [
  '01FF1AXS7JR3N05ARJ9CDN1A51', //パナソニックグループ
  '01GM2RMJG0PC9D6E8G0VX4Z48D', //株式会社リクルート（Meetup）
  '01FJ11J46T55RKRPQ4T2A2BAK5', //株式会社タムロン
  '01GG78RR4YPSD2W411PRYJBHRB', //オムロンエキスパートリンク株式会社
  '01FNQ5F4SPKB75Y3BK953RB28P', //株式会社ルネサンス
]

export function useFinishDropdown() {
  const { leaveInterview, finishModal, kickoutAllIntervieweeModal } = InRoomContainer.useContainer()
  const loggedInStaff = useLoggedInStaff()
  assert(loggedInStaff?.organizationGuid)
  const intervieweesNotExist = useSelector(visibleIntervieweesCountSelector) === 0

  const items = useMemo<DropdownItem[]>(() => {
    const args = {
      leaveInterview: leaveInterview,
      finishModalOpen: finishModal.open,
      kickoutAllIntervieweeModalOpen: kickoutAllIntervieweeModal.open,
      intervieweesNotExist: intervieweesNotExist,
      notDisplayFinishButtonOrganizationGUIDList: notDisplayFinishButtonOrganizationGUIDList,
      organizationGUID: loggedInStaff?.organizationGuid,
    }
    return getDropdownItems(args)
  }, [
    leaveInterview,
    finishModal.open,
    kickoutAllIntervieweeModal.open,
    intervieweesNotExist,
    loggedInStaff?.organizationGuid,
  ])

  return useDropdown(items)
}
