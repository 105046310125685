import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { sharedSlice } from '../redux'

export const useMuteCamera = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const dispatch = useDispatch()

  return useCallback(() => {
    meetingManager.muteCamera().then(() => {
      dispatch(sharedSlice.actions.ownCameraMutedChanged(true))
    })
  }, [dispatch, meetingManager])
}
