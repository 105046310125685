import { useCallback } from 'react'
import { GetSpecialSurveyURLRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestGetSpecialSurveyUrl() {
  const requestGetSpecialSurveyUrl = useCallback(async (interviewGuid: string) => {
    const req = new GetSpecialSurveyURLRequest()
    req.setInterviewGuid(interviewGuid)
    return callBff('getSpecialSurveyURL', [req])
  }, [])

  return { requestGetSpecialSurveyUrl }
}
