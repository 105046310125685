import { useCallback } from 'react'
import { CreateSessionRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestCreateSession() {
  const requestCreateSession = useCallback(() => {
    const req = new CreateSessionRequest()

    return callBff('createSession', [req])
  }, [])

  return { requestCreateSession }
}
