import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { InterviewRole } from '@/shared/services/interviewService/types'
import { SharedState } from '..'

export const ownRoleSelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.ownSoraClientId,
  (state: SharedState) => state.shared.metadataMap,
  (ownSoraClientId, metadataMap): Omit<InterviewRole, 'recruiter_screen' | 'applicant_screen'> | undefined => {
    if (ownSoraClientId === undefined) {
      return undefined
    }

    const metadata = metadataMap[ownSoraClientId]
    const role = metadata?.interview_role
    if (role === undefined || ['recruiter', 'applicant'].includes(role)) {
      return role
    }
    throw new Error(`invalid own role: ${role}`)
  }
)
