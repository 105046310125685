import React from 'react'
import styled, { css } from 'styled-components'
import { PropsWithChildren, PropsWithClassName } from '@/@types/propsWithTypes'
import { GlobalHeader as _GlobalHeader } from '@/shared/components/GlobalHeader'

type GlobalHeaderProps = React.ComponentProps<typeof _GlobalHeader>

type Props = PropsWithClassName &
  PropsWithChildren & {
    globalHeader?: GlobalHeaderProps
    backgroundColor?: string
  }

export const _PageLayout = (props: Props): React.ReactElement => {
  return (
    <Wrapper>
      {/* NOTE: Wrapper は CSS とのつなぎ目的な役割のみなので、className は Page に適用する */}
      <Page className={props.className} backgroundColor={props.backgroundColor}>
        <GlobalHeader
          leftNode={props.globalHeader?.leftNode}
          rightNode={props.globalHeader?.rightNode}
          disableLogoLink={props.globalHeader?.disableLogoLink}
          dashboardUrl={props.globalHeader?.dashboardUrl}
          newNotification={props.globalHeader?.newNotification}
          readNotification={props.globalHeader?.readNotification}
        />
        <Content>{props.children}</Content>
      </Page>
    </Wrapper>
  )
}

export const Wrapper = styled.div<PropsWithChildren & PropsWithClassName>`
  /* src/assets/layout.css で .root に display: flex を設定している */
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  z-index: 0;
`

const Page = styled.div<{ backgroundColor?: string }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
  grid-template-areas: 'global-header' 'content';

  width: 100%;
  height: 100%;

  ${({ backgroundColor }) =>
    backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `};
`

const GlobalHeader = styled(_GlobalHeader)`
  grid-area: global-header;
`

const Content = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
`
