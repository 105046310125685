import React, { useMemo } from 'react'
import { getInternalUrls } from '@blue-agency/im-shared-front'
import { PropsWithChildren, PropsWithClassName } from '@/@types/propsWithTypes'
import { GlobalHeader } from '@/shared/components/GlobalHeader'
import { _PageLayout as P } from './PageLayout'

type GlobalHeaderProps = Omit<React.ComponentProps<typeof GlobalHeader>, 'dashboardUrl'>

type Props = PropsWithClassName &
  PropsWithChildren & {
    globalHeader?: GlobalHeaderProps
    backgroundColor?: string
  }

export const PageLayout = (props: Props): React.ReactElement => {
  const { dashboard } = useMemo(() => getInternalUrls(), [])
  return <P {...props} globalHeader={{ ...props.globalHeader, dashboardUrl: dashboard }} />
}
