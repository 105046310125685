import React, { useMemo, useState, useCallback } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { assertNever } from '@/assertions'
import { Entrance } from '../Entrance'
import { EnvironmentCheck } from '../EnvironmentCheck'

type Step = 'entrance' | 'environmentCheck'

const useStep = () => {
  const [step, setStep] = useState<Step>('entrance')

  const content = useMemo(() => {
    switch (step) {
      case 'entrance':
        return <Entrance />
      case 'environmentCheck':
        return <EnvironmentCheck />
      default:
        assertNever(step)
    }
  }, [step])

  const toEnvironmentCheck = useCallback(() => {
    setStep('environmentCheck')
  }, [])

  const [isFinished, setIsFinished] = useState(false)
  const handleFinished = useCallback(() => {
    setIsFinished(true)
  }, [])

  return { content, toEnvironmentCheck, isFinished, handleFinished }
}

export const StepContainer = createContainer(useStep)
