import { useCallback } from 'react'
import type { SizeInNumber } from '@/shared/services/interviewService/components/Layout/useAreaSize'

type Args = {
  subVideoAreaSize: SizeWithPixel
}

export type SizeWithPixel = {
  width: number
  height: number
}

export function useMinutesViewAreaSizes({ subVideoAreaSize }: Args) {
  const calcSubVideoSize = useCallback(
    (numParticipants: number): SizeWithPixel => {
      // 既存のtitleView.tsからCalc関数と同じロジックになってます。
      const width = subVideoAreaSize.width - 10
      const height = subVideoAreaSize.height

      const col1 = Math.max(1, Math.floor(0.75 * Math.sqrt((numParticipants * width) / height)))
      const result1 = inner(col1, numParticipants, width, height)

      const col2 = Math.max(1, Math.ceil(0.75 * Math.sqrt((numParticipants * width) / height)))
      const result2 = inner(col2, numParticipants, width, height)

      if (result1.height >= result2.height) {
        return result1
      } else {
        return result2
      }
    },
    [subVideoAreaSize]
  )

  return {
    calcSubVideoSize,
  }
}

function inner(col: number, numParticipants: number, width: number, height: number): SizeInNumber {
  const row = Math.ceil(numParticipants / col)
  const scale = Math.min(Math.floor(width / (16 * col)), Math.floor(height / (9 * row)))
  if (numParticipants <= 3) {
    return {
      width: (16 * scale) / 1.3,
      height: (9 * scale) / 1.3,
    }
  }
  return {
    width: 16 * scale,
    height: 9 * scale,
  }
}
