import { comlinkPush } from '@blue-agency/im-shared-front'
import { SoraCloseEvent } from '@/lib/interview-sdk-js'
import { BackgroundEffect } from '@/shared/services/interviewService/types'

type ComlinkPushSignalingSucceededArg = {
  action: string
  interviewGuid: string
  channelId: string
  webrtcHost: string
  connectionId: string | null
}

const comlinkPushSignalingSucceeded = ({
  action,
  interviewGuid,
  channelId,
  webrtcHost,
  connectionId,
}: ComlinkPushSignalingSucceededArg) => {
  comlinkPush(
    {
      action,
      metadata: {
        interviewGuid,
        channelId: channelId,
        connectionId: connectionId ?? '',
        soraHost: webrtcHost,
      },
    },
    { lazy: true }
  )
}

export const comlinkPushUserPublisherSucceeded = (arg: Omit<ComlinkPushSignalingSucceededArg, 'action'>) => {
  comlinkPushSignalingSucceeded({ action: 'succeeded_to_connect_user_stream_to_sora_in_interview', ...arg })
}

export const comlinkPushScreenSharingSubscriberSucceeded = (arg: Omit<ComlinkPushSignalingSucceededArg, 'action'>) => {
  comlinkPushSignalingSucceeded({ action: 'succeeded_to_subscribe_to_screen_sharing_in_interview', ...arg })
}

type ComlinkPushSignalingDisconnectedArg = {
  action: string
  interviewGuid: string
  channelId: string
  webrtcHost: string
  event: SoraCloseEvent
}

const comlinkPushSignalingDisconnected = ({
  action,
  interviewGuid,
  channelId,
  webrtcHost,
  event,
}: ComlinkPushSignalingDisconnectedArg) => {
  comlinkPush({
    action,
    metadata: {
      interviewGuid,
      channelId: channelId,
      soraHost: webrtcHost,
      title: event.title,
      code: event.code?.toString() ?? '',
      reason: event.reason ?? '',
    },
  })
}

export const comlinkPushUserPublisherDisconnected = (arg: Omit<ComlinkPushSignalingDisconnectedArg, 'action'>) => {
  comlinkPushSignalingDisconnected({ action: 'disconnected_to_connect_user_stream_to_sora_in_interview', ...arg })
}

export const comlinkPushScreenSharingSubscriberDisconnected = (
  arg: Omit<ComlinkPushSignalingDisconnectedArg, 'action'>
) => {
  comlinkPushSignalingDisconnected({ action: 'disconnected_to_subscribe_to_screen_sharing_in_interview', ...arg })
}

type MeetingFeature = {
  enabledNoiseSuppression: boolean | undefined
  backgroundEffect: BackgroundEffect
  videoFrameRate: number
  isOwnCameraMuted: boolean
  isOwnMicMuted: boolean
}

const meetingFeatureToMetadata = ({
  enabledNoiseSuppression,
  backgroundEffect,
  videoFrameRate,
  isOwnCameraMuted,
  isOwnMicMuted,
}: MeetingFeature) => {
  return {
    enabledNoiseSuppression: enabledNoiseSuppression ? 'ON' : 'OFF',
    backgroundEffect,
    frameRate: videoFrameRate.toString(),
    isOwnCameraMuted: isOwnCameraMuted ? 'MUTED' : 'NOT MUTED',
    isOwnMicMuted: isOwnMicMuted ? 'MUTED' : 'NOT MUTED',
  }
}

export const comlinkPushFeatureAtStartMeeting = (feature: MeetingFeature) => {
  comlinkPush({
    action: 'feature_status_at_start_meeting',
    metadata: meetingFeatureToMetadata(feature),
  })
}

export const comlinkPushFeatureAtLeaveMeeting = (feature: MeetingFeature) => {
  comlinkPush({
    action: 'feature_status_at_leave_meeting',
    metadata: meetingFeatureToMetadata(feature),
  })
}

export const comlinkPushIrregularJoin = ({ interviewGuid, status }: { interviewGuid: string; status: string }) => {
  comlinkPush(
    {
      action: 'join_in_irregular_situation',
      metadata: {
        interviewGuid,
        status,
      },
    },
    { lazy: true }
  )
}
