import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { WebSocketMessageMap } from '@/shared/hooks/useInterviewWebSocket'
import type { InterviewState, OwnState } from '@/shared/services/interviewService/types'
import type { EntryRequest } from '../types'

type State = {
  interviewState: InterviewState
  ownState: OwnState
  processingEntryRequest: boolean
  pendingEntryRequests: EntryRequest[]
  // 人数制限でエラーになったとき、名前を表示するために取っておく
  consumedEntryRequests: EntryRequest[]
}

const initialState: State = {
  interviewState: 'NotStarted',
  ownState: 'BeforeEntered',
  processingEntryRequest: false,
  pendingEntryRequests: [],
  consumedEntryRequests: [],
}

export const bizSlice = createSlice({
  name: 'biz',
  initialState,
  reducers: {
    enterRoomButtonClicked: (state) => {
      state.ownState = 'InRoom'
    },
    requestEntryReceived: (state, { payload }: PayloadAction<EntryRequest>) => {
      state.pendingEntryRequests.push(payload)
    },
    entryRequestApproved: (state) => {
      state.processingEntryRequest = true
    },
    entryRequestRejected: (state) => {
      state.processingEntryRequest = true
    },
    approveEntryMessageReceived: (state, { payload }: PayloadAction<WebSocketMessageMap['approve entry']>) => {
      const target = state.pendingEntryRequests.find((r) => r.entryRequestGuid === payload.entry_request_guid)
      if (!target) {
        // 自分が入室前に送られていた参加リクエストの可能性があるので黙って return する
        return
      }

      const newPendingEntryRequests = state.pendingEntryRequests.filter(
        (r) => r.entryRequestGuid !== target.entryRequestGuid
      )
      state.pendingEntryRequests = newPendingEntryRequests
      state.consumedEntryRequests.push(target)
      state.processingEntryRequest = false
    },
    rejectEntryMessageReceived: (state, { payload }: PayloadAction<WebSocketMessageMap['reject entry']>) => {
      const target = state.pendingEntryRequests.find((r) => r.entryRequestGuid === payload.entry_request_guid)
      if (!target) {
        // 自分が入室前に送られていた参加リクエストの可能性があるので黙って return する
        return
      }

      const newPendingEntryRequests = state.pendingEntryRequests.filter(
        (r) => r.entryRequestGuid !== target.entryRequestGuid
      )
      state.pendingEntryRequests = newPendingEntryRequests
      state.consumedEntryRequests.push(target)
      state.processingEntryRequest = false
    },
    cancelEntryRequestReceived: (state, { payload }: PayloadAction<{ entryRequestGuid: string }>) => {
      const newPendingEntryRequests = state.pendingEntryRequests.filter(
        (r) => r.entryRequestGuid !== payload.entryRequestGuid
      )
      state.pendingEntryRequests = newPendingEntryRequests
      state.processingEntryRequest = false
    },
    processEntryRequestError: (state) => {
      state.processingEntryRequest = false
    },
    finishMessageReceived: (state) => {
      state.interviewState = 'Finished'
    },
    leavingInterviewRequested: (state) => {
      state.ownState = 'Leaved'
    },
  },
})
