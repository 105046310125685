const RTT_TH = 0.15
// 軽量モードの10fps未満の9を設定する
const VIDEO_SRC_FPS_TH = 9

export type ConnectionStatsSummary = {
  qualityLimitationReason?: string
  rtt?: number
  protocol?: string
  videoSrcFps?: number
}

export type Indicator = keyof ConnectionStatsSummary

export const getBadIndicators = (
  statsSummary: ConnectionStatsSummary,
  isStaticImageVideoSrc: boolean
): Set<Indicator> => {
  let r: Set<Indicator> = new Set()
  if (statsSummary.qualityLimitationReason && statsSummary.qualityLimitationReason !== 'none') {
    r.add('qualityLimitationReason')
  }
  if (statsSummary.rtt !== undefined && statsSummary.rtt >= RTT_TH) {
    r.add('rtt')
  }
  if (
    statsSummary.videoSrcFps !== undefined &&
    statsSummary.videoSrcFps <= VIDEO_SRC_FPS_TH &&
    !isStaticImageVideoSrc
  ) {
    r.add('videoSrcFps')
  }
  if (statsSummary.protocol === 'tcp') {
    r.add('protocol')
  }
  return r
}

export const getBecomeWorseIndicators = (prev: Set<Indicator> | undefined, curr: Set<Indicator>): Set<Indicator> => {
  if (!prev) return curr

  const r: Set<Indicator> = new Set()

  curr.forEach((i) => {
    if (!prev.has(i)) {
      r.add(i)
    }
  })

  return r
}
