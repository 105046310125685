import { useEffect, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { DeviceChangeObserver } from 'amazon-chime-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'

const useVideoInput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()

  const [videoInputs, setVideoInputs] = useState<MediaDeviceInfo[]>([])
  const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState(meetingManager.selectedVideoInputDevice)

  useEffect(() => {
    const callback = (updatedVideoInputDevice: string | null): void => {
      setSelectedVideoInputDevice(updatedVideoInputDevice)
    }

    meetingManager.subscribeToSelectedVideoInputDevice(callback)

    return (): void => {
      meetingManager.unsubscribeFromSelectedVideoInputDevice(callback)
    }
  }, [meetingManager, videoInputs, selectedVideoInputDevice])

  useEffect(() => {
    let isMounted = true

    const observer: DeviceChangeObserver = {
      videoInputsChanged: (newVideoInputs: MediaDeviceInfo[]) => {
        setVideoInputs(newVideoInputs)
      },
    }

    async function initVideoInput() {
      if (!audioVideo) {
        return
      }

      const devices = await audioVideo.listVideoInputDevices()

      if (isMounted) {
        setVideoInputs(devices)
        audioVideo.addDeviceChangeObserver(observer)
      }
    }

    initVideoInput()

    return () => {
      isMounted = false
      audioVideo?.removeDeviceChangeObserver(observer)
    }
  }, [meetingManager, audioVideo])

  return useMemo(
    () => ({
      videoInputs,
      selectedVideoInputDevice,
      setSelectedVideoInputDevice,
    }),
    [videoInputs, selectedVideoInputDevice, setSelectedVideoInputDevice]
  )
}

export const VideoInputContainer = createContainer(useVideoInput)
