import { useCallback, useState } from 'react'
import { useSessionStorage } from 'react-use'
import { SESSION_STORAGE_KEY } from '@/shared/services/storageService'

export const useTroubleShootingNotification = () => {
  const [showing, setShowing] = useState(false)

  // 1度消したら表示しない
  const [hidden, setHidden] = useSessionStorage(SESSION_STORAGE_KEY.troubleShootingNotificationHidden, false)

  const show = useCallback(() => {
    if (hidden) return
    setShowing(true)
  }, [hidden])
  const hide = useCallback(() => {
    setHidden(true)
    setShowing(false)
  }, [setHidden])

  return { showing, show, hide }
}
