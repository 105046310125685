import React from 'react'
import { theme } from '@blue-agency/rogue'
import { Switch, Case, Default } from 'react-if'
import { If, Then, Else } from 'react-if'
import { shallowEqual, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { CameraMutedImage } from '@/lib/meetingcomponent/CameraMutedImage'
import { LocalVideoContainer } from '@/lib/meetingcomponent/LocalVideoContainer'
import { MainVideoContainer } from '@/lib/meetingcomponent/MainVideoContainer'
import { OverlayPin } from '@/lib/meetingcomponent/OverlayPin'
import { UnavailableVideoInputImage } from '@/lib/meetingcomponent/UnavailableVideoInputImage'
import { Video } from '@/lib/meetingcomponent/Video'
import { useLocalVideoElement } from '@/lib/meetingcomponent/useLocalVideoElement'
import { LocalAudioWaveCircle } from '@/shared/services/interviewService/components/AudioWaveCircle/LocalAudioWaveCircle'
import { AspectBox } from '@/shared/services/interviewService/components/VideoList/AspectBox'
import { NetworkUnstableLevel } from '@/shared/services/interviewService/components/VideoList/NetworkUnstableLevel'
import { SpeakingParticipantContainer } from '@/shared/services/interviewService/containers/SpeakingParticipantContainer'
import { ownParticipantSelector, SharedState } from '@/shared/services/interviewService/redux'
import { isRecruiter } from '@/shared/services/interviewService/types'
import { Footer, AudioStatus, Name, MuteMicIcon, Overlay, CompanyIcon, audioWaveCircleProps } from './CommonVideoParts'

export const LocalVideo: React.VFC = () => {
  const { tileId, isVideoEnabled } = LocalVideoContainer.useContainer()
  const { mainVideoContentTileId } = MainVideoContainer.useContainer()
  const myself = useSelector(ownParticipantSelector, shallowEqual)
  const micMutedMap = useSelector((state: SharedState) => state.shared.micMutedMap)
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)
  const { speakingSoraClientId } = SpeakingParticipantContainer.useContainer()
  const myselfIsRecruiter = myself !== undefined ? isRecruiter(myself.role) : false
  const networkUnstableLevel = useSelector((state: SharedState) => state.shared.networkUnstableLevel)

  const isMainVideo = mainVideoContentTileId === tileId
  const isOwnMicMuted = myself?.soraClientId && micMutedMap[myself.soraClientId]
  const isSpeaking = !isMainVideo && !!myself?.soraClientId && myself?.soraClientId === speakingSoraClientId

  const videoEl = useLocalVideoElement()

  if (!isVideoEnabled) {
    return null
  }

  // カメラオフ時のカメラ映像を画像にしている経緯
  // https://github.com/blue-agency/yashiori-front/pull/1166
  // https://github.com/blue-agency/react-im-interview/pull/27
  return (
    <AspectBox ratio="16x9">
      <Wrapper isSpeaking={isSpeaking}>
        <Switch>
          <Case condition={isOwnCameraMuted}>
            <CameraMutedImage />
          </Case>
          <Case condition={isUnavailableVideoInput}>
            <UnavailableVideoInputImage />
          </Case>
          <Default>
            <Video ref={videoEl} />
          </Default>
        </Switch>
        <Overlay hasName={!!myself?.name} isMainVideo={isMainVideo} />
        <NetworkUnstableLevel networkUnstableLevel={networkUnstableLevel} />
        <OverlayPin tileId={tileId ?? null} icon={isMainVideo ? 'unpin' : 'pin'} target="sub" />

        <Footer>
          <AudioStatus>
            <If condition={isOwnMicMuted}>
              <Then>
                <MuteMicIcon />
              </Then>
              <Else>
                <AudioWaveCircle />
              </Else>
            </If>
          </AudioStatus>

          <Name>
            {myselfIsRecruiter && <CompanyIcon />}
            {myself?.name}
          </Name>
        </Footer>
      </Wrapper>
    </AspectBox>
  )
}

const Wrapper = styled.div<{ isSpeaking: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  background-color: ${theme.color.black[1]};

  ${({ theme }) => {
    if (!theme.responsive.pc) {
      // PC Safari において、overflow: hidden があると、ピンアイコンマウスオーバー時の tooltip の表示が崩れてしまう
      // そのため PC 以外のみ overflow: hidden を有効にする
      return css`
        overflow: hidden;
      `
    }
  }}

  ${({ isSpeaking }) => {
    if (isSpeaking) {
      return css`
        outline: 3px solid ${theme.color.green[4]};
      `
    }
  }}
`

const AudioWaveCircle = styled(LocalAudioWaveCircle).attrs(({ theme }) => audioWaveCircleProps(theme))``
