import React, { useCallback } from 'react'
import { Modal, NewTabLink, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { InterviewContainer } from '../../containers/InterviewContainer'

export const UnknownErrorModal: React.VFC = () => {
  const onClick = useCallback(() => window.location.reload(), [])
  const { appType } = InterviewContainer.useContainer()

  const helpUrl = appType === 'biz' ? BIZ_EXTERNAL_URLS.help.interview : MY_EXTERNAL_URLS.help.interview

  return (
    <StyledModal active title="通信エラー" size="m">
      <Upper>
        <Txt>
          通信エラーが発生しました。ブラウザを再読込してください。
          <br />
          復旧しない場合は
          <NewTabLink href={helpUrl} action="click_help_new_tab_link">
            こちら
          </NewTabLink>
          までお問い合わせください。
        </Txt>
      </Upper>
      <WeakColorSeparator />
      <ButtonWrapper>
        <PrimaryButton
          onClick={onClick}
          widthSize="L1"
          comlinkPushParams={{ action: 'click_button_on_unknown_error_modal' }}
        >
          再読込
        </PrimaryButton>
      </ButtonWrapper>
    </StyledModal>
  )
}

const StyledModal = styled(Modal)`
  margin: 0 20px;
`

const Upper = styled.div`
  margin: 20px 20px 40px;
`

const ButtonWrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
`
