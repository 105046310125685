// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { MeetingSessionConfiguration } from 'amazon-chime-sdk-js'
import { ConnectionPublisher, ConnectionSubscriber } from 'sora-js-sdk'

export class ImMeetingSessionConfiguration extends MeetingSessionConfiguration {
  soraConnectionPublisher?: ConnectionPublisher
  soraConnectionSubscriber?: ConnectionSubscriber
}
