import { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import { CreateCameraTestInvitationResponse } from '@blue-agency/proton/my_skywalker_bff'
import {
  CameraTestConnectionConfigProps,
  CameraTestConnectionProps,
  CameraTestOnConnectCallback,
  CameraTestOnFailureCallback,
} from '@blue-agency/react-environment-check'
import invariant from 'tiny-invariant'
import { useRequestCreateCameraTestInvitation, useRequestDeleteCameraTestSessionToken } from '@/my/services/bffService'

export const useEnvironmentCheck = () => {
  const [createCameraTestRes, setCreateCameraTestRes] = useState<CreateCameraTestInvitationResponse>()

  const { requestCreateCameraTestInvitation } = useRequestCreateCameraTestInvitation()

  useEffect(() => {
    const fn = async () => {
      const res = await requestCreateCameraTestInvitation()
      setCreateCameraTestRes(res)
    }
    fn()
  }, [requestCreateCameraTestInvitation])

  const soraProps = useMemo<CameraTestConnectionConfigProps | undefined>(() => {
    if (!createCameraTestRes) return undefined
    return {
      sessionToken: createCameraTestRes.getSessionToken(),
      channelId: createCameraTestRes.getSoraChannelId(),
      webrtcHost: createCameraTestRes.getWebrtcHost(),
    }
  }, [createCameraTestRes])

  const connectedRef = useRef(false)

  const onConnect = useCallback<CameraTestOnConnectCallback>(
    (result) => {
      invariant(soraProps)
      comlinkPush({
        action: 'succeeded_to_connect_to_sora_in_camera_test',
        metadata: {
          channelId: soraProps.channelId,
          sessionToken: soraProps.sessionToken,
          protocol: result.protocol ?? '',
          sendonlyAttempt: result.sendonlyAttempt?.toString() ?? '',
          recvonlyAttempt: result.recvonlyAttempt?.toString() ?? '',
        },
      })
      connectedRef.current = true
    },
    [soraProps]
  )

  const onFailure = useCallback<CameraTestOnFailureCallback>(
    (e) => {
      invariant(soraProps)

      comlinkPush({
        action: 'failed_to_connect_to_sora_in_camera_test',
        metadata: {
          role: e.role ?? '',
          errorMessage: e.message ?? '',
          reason: e.reason ?? '',
          sessionToken: soraProps.sessionToken,
        },
      })
    },
    [soraProps]
  )

  const { requestDeleteCameraTestSessionToken } = useRequestDeleteCameraTestSessionToken()

  const onLeave = useCallback(async () => {
    if (!connectedRef.current) return
    invariant(soraProps)

    await requestDeleteCameraTestSessionToken({ sessionToken: soraProps.sessionToken })
    comlinkPush({
      action: 'delete_camera_test_session_token',
      metadata: {
        sessionToken: soraProps.sessionToken,
      },
    })
  }, [requestDeleteCameraTestSessionToken, soraProps])

  const cameraTestConnectionProps = useMemo<CameraTestConnectionProps | undefined>(() => {
    if (!soraProps) return undefined

    return { sora: soraProps, onConnect, onFailure, onLeave }
  }, [onConnect, onFailure, onLeave, soraProps])

  return { cameraTestConnectionProps }
}
