// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { TaskCanceler, BaseTask } from 'amazon-chime-sdk-js'
import { ImAudioVideoControllerState } from '../audiovideocontroller/ImAudioVideoControllerState'

export class CleanStoppedSessionTask extends BaseTask {
  protected taskName = 'CleanStoppedSessionTask'
  private taskCanceler: TaskCanceler | null = null

  constructor(private context: ImAudioVideoControllerState) {
    super(context.logger)
  }

  cancel(): void {
    if (this.taskCanceler) {
      this.taskCanceler.cancel()
      this.taskCanceler = null
    }
  }

  async run(): Promise<void> {
    try {
      await this.context.meetingSessionConfiguration?.soraConnectionPublisher?.disconnect()
      await this.context.meetingSessionConfiguration?.soraConnectionSubscriber?.disconnect()
    } finally {
      for (const observer of this.context.removableObservers) {
        observer.removeObserver()
      }

      this.context.statsCollector?.stop()
      this.context.statsCollector = null
      this.context.connectionMonitor?.stop()
      this.context.connectionMonitor = null

      this.context.peer?.close()
      this.context.peer = null
      this.context.recvOnlyPeer?.close()
      this.context.recvOnlyPeer = null
      this.context.localVideoSender = null
      this.context.sdpAnswer = null
      this.context.sdpOfferInit = null
      this.context.videoDownlinkBandwidthPolicy?.reset()
      this.context.iceCandidateHandler = null
      this.context.iceCandidates = []
      this.context.turnCredentials = null
      this.context.videoSubscriptions = null
      this.context.transceiverController?.reset()

      // This should really be a _device deselection_ operation,
      // allowing the device controller to clean up any selected transform
      // device or other resources.
      //
      // We can't fix it within the current API because CSST only knows about
      // `MediaStreamBroker`, not about `DeviceController` — it only knows how
      // to // release media streams that are tracked in the
      // `AudioVideoControllerState`, not how to unselect a device.
      //
      // The issue here is that we now work with much more than streams, and
      // this API hasn't kept pace with the complexity of the rest of the SDK.
      //
      // It's currently up to the developer's application to manage which device
      // is currently selected and `DDC` has to figure out from the stream
      // passed here which device to clean up.
      //
      // This can be addressed in a future v3.0.
      if (this.context.activeAudioInput) {
        this.context.mediaStreamBroker?.releaseMediaStream(this.context.activeAudioInput)
        this.context.activeAudioInput = null
      }
      if (this.context.activeVideoInput) {
        this.context.mediaStreamBroker?.releaseMediaStream(this.context.activeVideoInput)
        this.context.activeVideoInput = null
      }
      this.context.realtimeController?.realtimeSetLocalAudioInput(null)

      const tile = this.context.videoTileController?.getLocalVideoTile()
      if (tile) {
        tile.bindVideoStream('', true, null, null, null, null)
      }
      this.context.videoTileController?.removeAllVideoTiles()
    }
  }
}
