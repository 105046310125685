import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { ownRoleSelector, SharedState } from '..'
import type { Participant } from '../../types'

export const participantsSelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.participantWithoutRoleList,
  (state: SharedState) => state.shared.metadataMap,
  (participantWithoutRoleList, metadataMap) => {
    return Object.entries(metadataMap)
      .map(([soraClientId, metadata]): Participant | undefined => {
        // isDestroyed のときはすでに退室している参加者なので undefined を返す
        if (metadata.isDestroyed) return undefined

        // 画面共有時は名前がないので空文字を返す
        // 存在しない参加者情報のときは undefined を返す
        const name = ((): string | undefined => {
          if (metadata.interview_role === 'applicant_screen' || metadata.interview_role === 'recruiter_screen') {
            return '画面共有'
          }
          const p = participantWithoutRoleList.find((p) => p.soraClientId === soraClientId)
          return p?.name
        })()

        if (name === undefined) return undefined

        return {
          soraClientId,
          name,
          role: metadata.interview_role,
        }
      })
      .filter((x): x is Participant => x !== undefined)
  }
)

export const ownParticipantSelector = createDraftSafeSelector(
  participantsSelector,
  (state: SharedState) => state.shared.ownSoraClientId,
  (participants, ownSoraClientId) => {
    return participants.find((p) => p.soraClientId === ownSoraClientId)
  }
)

export const participantsExcludingSelfSelector = createDraftSafeSelector(
  participantsSelector,
  (state: SharedState) => state.shared.ownSoraClientId,
  (participants, ownSoraClientId) => {
    return participants.filter((p) => p.soraClientId !== ownSoraClientId)
  }
)

// 画面共有かどうかは区別しない
export const participantsOfSameRoleSelector = createDraftSafeSelector(
  participantsExcludingSelfSelector,
  ownRoleSelector,
  (participants, ownRole) => {
    if (ownRole === undefined) return participants
    const sameRoles = ownRole === 'recruiter' ? ['recruiter', 'recruiter_screen'] : ['applicant', 'applicant_screen']
    return participants.filter((p) => sameRoles.includes(p.role))
  }
)

// 画面共有かどうかは区別しない
export const participantsOfOtherRoleSelector = createDraftSafeSelector(
  participantsExcludingSelfSelector,
  ownRoleSelector,
  (participants, ownRole) => {
    if (ownRole === undefined) return participants
    const sameRoles = ownRole === 'recruiter' ? ['recruiter', 'recruiter_screen'] : ['applicant', 'applicant_screen']
    return participants.filter((p) => !sameRoles.includes(p.role))
  }
)

// 自分とは同じロールの参加者一覧 (自分自身および画面共有は結果に含まれない)
export const participantsOfSameRoleAndNotContentShareSelector = createDraftSafeSelector(
  participantsExcludingSelfSelector,
  ownRoleSelector,
  (participants, ownRole) => {
    if (ownRole === undefined) return []
    return participants.filter((p) => p.role === ownRole)
  }
)

// 自分とは別ロールの参加者一覧 (画面共有は結果に含まれない)
export const participantsOfOtherRoleAndNotContentShareSelector = createDraftSafeSelector(
  participantsExcludingSelfSelector,
  ownRoleSelector,
  (participants, ownRole) => {
    if (ownRole === undefined) return []
    const roleToBeIncluded = ownRole === 'recruiter' ? 'applicant' : 'recruiter'
    return participants.filter((p) => p.role === roleToBeIncluded)
  }
)

export const interviewersSelector = createDraftSafeSelector(participantsSelector, (participants) => {
  return participants.filter((p) => p.role === 'recruiter' || p.role === 'recruiter_screen')
})

export const intervieweesSelector = createDraftSafeSelector(participantsSelector, (participants) => {
  return participants.filter((p) => p.role === 'applicant' || p.role === 'applicant_screen')
})

export const interviewersWithoutContentShareSelector = createDraftSafeSelector(participantsSelector, (participants) => {
  return participants.filter((p) => p.role === 'recruiter')
})

export const intervieweesWithoutContentShareSelector = createDraftSafeSelector(participantsSelector, (participants) => {
  return participants.filter((p) => p.role === 'applicant')
})

export const contentShareSelector = createDraftSafeSelector(participantsSelector, (participants) => {
  return participants.filter((p) => p.role === 'recruiter_screen' || p.role === 'applicant_screen')
})

export const participantsWithoutContentShareSelector = createDraftSafeSelector(participantsSelector, (participants) => {
  return participants.filter((p) => p.role !== 'recruiter_screen' && p.role !== 'applicant_screen')
})

// 見た目上見えている面接官の数. セルフビューがオフになっている自分はカウントしない
export const visibleInterviewersCountSelector = createDraftSafeSelector(
  interviewersSelector,
  ownRoleSelector,
  (state: SharedState) => state.shared.selfViewVisibility,
  (interviewers, ownRole, selfViewVisibility) => {
    if (selfViewVisibility === 'hidden') {
      return Math.max(0, interviewers.length - Number(ownRole === 'recruiter'))
    }
    return interviewers.length
  }
)

export const visibleIntervieweesCountSelector = createDraftSafeSelector(
  intervieweesSelector,
  ownRoleSelector,
  (state: SharedState) => state.shared.selfViewVisibility,
  (interviewees, ownRole, selfViewVisibility) => {
    if (selfViewVisibility === 'hidden') {
      return Math.max(0, interviewees.length - Number(ownRole === 'applicant'))
    }
    return interviewees.length
  }
)

export const shortageParticipantSoraClientIdsSelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.participantWithoutRoleList,
  (state: SharedState) => state.shared.metadataMap,
  (state: SharedState) => state.shared.ownSoraClientId,
  (participantWithoutRoleList, metadataMap, ownSoraClientId) => {
    if (!ownSoraClientId) return []
    return Object.entries(metadataMap)
      .map(([soraClientId, metadata]): string | null => {
        // 自身は取得する必要がない
        if (soraClientId === ownSoraClientId) return null

        // 画面共有系はparticipantが必要ないので除外
        if (metadata.interview_role !== 'applicant' && metadata.interview_role !== 'recruiter') {
          return null
        }

        if (metadata.isDestroyed) return null

        if (participantWithoutRoleList.some((p) => p.soraClientId === soraClientId)) {
          return null
        }

        return soraClientId
      })
      .filter((x): x is string => x !== null)
  }
)
