import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { Rnd } from 'react-rnd'
import styled from 'styled-components'
import { BizInterviewGuideContainer } from '@/biz/pages/InterviewPage/containers/BizInterviewGuideContainer'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'
import { GuidePartView } from './GuidePartView'

type Props = {
  boundsClass: string
}

export const PcInterviewGuideModal: React.VFC<Props> = (props) => {
  const { closeInterviewGuideArea: close } = InterviewGuideContainer.useContainer()
  const { guide } = BizInterviewGuideContainer.useContainer()

  if (!guide) {
    return null
  }

  const BottomRightHandle = () => (
    <ResizeControlWrapper>
      <ResizeControl>
        <ResizableIcon />
      </ResizeControl>
    </ResizeControlWrapper>
  )

  return (
    <Rnd
      default={{
        x: 8,
        y: 8,
        width: 450,
        height: 640,
      }}
      dragHandleClassName="header"
      bounds={props.boundsClass}
      minWidth="320px"
      minHeight="200px"
      resizeHandleComponent={{ bottomRight: <BottomRightHandle /> }}
    >
      <DraggableContent>
        <Header className="header">
          <HeaderTitle>
            <InterviewGuideIcon size="m" />
            <Txt color={theme.color.white[1]} bold>
              面接ガイド
            </Txt>
          </HeaderTitle>
          <HeaderControls>
            <DraggableIcon />
            <ControlsSeparator />
            {/* ios chrome and safariにてクリックできるようにする https://github.com/bokuweb/react-rnd/issues/733#issuecomment-924397205 */}
            <IconButton onClick={close} onGotPointerCapture={close}>
              <CloseIcon />
            </IconButton>
          </HeaderControls>
        </Header>
        <Body>
          <NameWrapper>
            <Txt color={theme.color.gray[1]} bold>
              {guide.name}
            </Txt>
          </NameWrapper>
          <GuideParts>
            {guide.parts.map((part) => {
              return <GuidePartView key={part.startSeconds} part={part} />
            })}
          </GuideParts>
        </Body>
      </DraggableContent>
    </Rnd>
  )
}

const DraggableContent = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
  background-color: ${theme.color.navy[1]};
  padding: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: grab;
`

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;

  *:not(:last-child) {
    margin-right: 6px;
  }
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;

  *:not(:last-child) {
    margin-right: 16px;
  }
`

const Body = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  opacity: 0.95;
  background-color: ${theme.color.white[1]};
`

const NameWrapper = styled.div`
  padding: 4px 12px;
`

const GuideParts = styled.div`
  display: flex;
  flex-direction: column;
  ${overflowYScrollStyle};
`

const IconButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
`

const CloseIcon = styled(Icon).attrs({ name: 'close' })`
  color: ${theme.color.white[1]};
`

const InterviewGuideIcon = styled(Icon).attrs({ name: 'guide' })`
  color: ${theme.color.white[1]};
`

const DraggableIcon = styled(Icon).attrs({ name: 'move-all' })`
  color: ${theme.color.white[1]};
`

const ResizableIcon = styled(Icon).attrs({ name: 'resize' })`
  color: ${theme.color.white[1]};
  width: 100%;
  height: 100%;
`

const ControlsSeparator = styled.div`
  background-color: ${theme.color.white[1]};
  height: 18px;
  width: 1px;
`

const ResizeControlWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: visible;
  display: flex;
  align-items: center;
  justify-content: center;
`

const ResizeControl = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 33px;
  height: 33px;
  background-color: ${theme.color.navy[1]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
