import { useCallback, useEffect, useRef } from 'react'
import differenceBy from 'lodash-es/differenceBy'
import { useSelector } from 'react-redux'
import { AudioOutputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { SoundPlayContainer } from '@/shared/containers/SoundPlayContainer'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { SharedState } from '../../redux'
import { notificationSoundSrc } from './notificationSoundSrc'

export function usePlayNotificationSoundWhenChatMessageReceived() {
  const { playSound } = SoundPlayContainer.useContainer()
  const { selectedAudioOutputDevice } = AudioOutputContainer.useContainer()

  const playNotificationSound = useThrottleCallback(30_000, () => {
    // Safariでは音声出力デバイスの一覧がchime SDKで取得できないため、selectedAudioOutputDeviceはnullになる。
    // playSoundにspearkerDeviceとしてnullを渡すと渡すとデフォルトデバイスを指定して再生してくれるため、Safariでも再生できる。
    playSound(selectedAudioOutputDevice, notificationSoundSrc, 0.5)
  })

  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  const chatMessages = useSelector((state: SharedState) => state.shared.chatMessages)
  const prevChatMessages = usePrevious(chatMessages)

  useEffect(() => {
    const playNotificationSoundWhenChatMessageReceived = () => {
      const added = (() => {
        if (!prevChatMessages) {
          return chatMessages
        }
        return differenceBy(chatMessages, prevChatMessages, (m) => m.messageGuid)
      })()

      const isNotMine = added.some((m) => m.participant.soraClientId !== ownSoraClientId)

      if (isNotMine) {
        playNotificationSound()
      }
    }
    playNotificationSoundWhenChatMessageReceived()
  }, [chatMessages, ownSoraClientId, playNotificationSound, prevChatMessages])
}

const useThrottleCallback = (duration: number, cb: () => void) => {
  const flagRef = useRef(false)

  return useCallback(() => {
    if (flagRef.current) return
    flagRef.current = true
    cb()
    setTimeout(() => (flagRef.current = false), duration)
  }, [cb, duration])
}
