declare global {
  interface HTMLCanvasElement {
    captureStream(frameRate: number): MediaStream
  }
}

const MUTED_VIDEO_STREAM_FPS = 15

export abstract class StaticImageStream {
  private stream: MediaStream | null = null

  constructor() {
    return
  }

  public start(): MediaStream {
    if (this.stream && this.stream.active) {
      return this.stream
    }
    this.stop()
    this.stream = this.buildMutedStream()
    return this.stream
  }

  public stop(): void {
    return
  }

  protected abstract src(): string

  private buildMutedStream(): MediaStream {
    const width = 640
    const height = 360
    const c = document.createElement('canvas')
    c.width = width
    c.height = height
    const ctx = c.getContext('2d')
    if (!ctx) throw new Error('ctx not found')
    const img = new Image()
    img.src = this.src()
    img.onload = () => {
      ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height)
    }
    // NOTE: 完全な静止画だとvideo trackが流れないので1x1のダミーのデータを描画している
    let dummyPixelColorFlag = true
    window.setInterval(() => {
      ctx.fillStyle = dummyPixelColorFlag ? '#000' : '#001'
      ctx.fillRect(0, 0, 1, 1)
      dummyPixelColorFlag = !dummyPixelColorFlag
    }, 1000)
    return c.captureStream(MUTED_VIDEO_STREAM_FPS)
  }
}
