import React from 'react'
import { theme, Txt, A } from '@blue-agency/rogue'
import reactStringReplace from 'react-string-replace'
import styled from 'styled-components'
import { ChatMessage, ChatRole } from '../../types'

type Props = {
  message: ChatMessage
}

const regExp = /(https?:\/\/\S+)/g

export const ChatMessageComponent: React.VFC<Props> = React.memo(({ message }) => {
  return (
    <Wrapper message={message}>
      <Name>{message.participant.name}</Name>
      <Body>
        <Span>
          {reactStringReplace(message.text, regExp, (match, i) => (
            <A
              key={i}
              href={match}
              target="_blank"
              rel="noopener noreferrer"
              comlinkPushParams={{ action: 'click_url_in_chat_message' }}
            >
              {match}
            </A>
          ))}
        </Span>
      </Body>
    </Wrapper>
  )
})

const backgroundColorMap: Record<ChatRole, string> = {
  interviewer: theme.color.green[8],
  interviewee: theme.color.red[4],
}

type MessageProps = {
  message: ChatMessage
}
const Wrapper = styled.div<MessageProps>`
  padding: 8px;
  border-bottom: 1px solid ${theme.color.gray[5]};

  background-color: ${({ message }) => backgroundColorMap[message.participant.role]};
`
const Name = styled(Txt).attrs({ color: theme.color.gray[1], size: 's' })`
  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://nuconeco.net/iphone-safari-type/
  -webkit-text-size-adjust: 100%;
`
const Body = styled(Txt).attrs({ size: 's' })`
  margin-left: 12px;
  // Safari横画面で文字サイズが大きくなることを防ぐ
  // https://nuconeco.net/iphone-safari-type/
  -webkit-text-size-adjust: 100%;
`
const Span = styled.span`
  word-break: break-all;
`
