import { useCallback } from 'react'
import { CreateCameraTestInvitationRequest } from '@blue-agency/proton/my_skywalker_bff'
import { callBff } from './bffClient'

export function useRequestCreateCameraTestInvitation() {
  const requestCreateCameraTestInvitation = useCallback(() => {
    const req = new CreateCameraTestInvitationRequest()

    return callBff('createCameraTestInvitation', [req])
  }, [])

  return { requestCreateCameraTestInvitation }
}
