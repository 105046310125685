import React, { useEffect, useState } from 'react'
import { GetSignalingPointsResponse } from '@blue-agency/proton/web/v2/my_skywalker_bff'
import { alertToast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import invariant from 'tiny-invariant'
import { RootState } from '@/my/pages/InterviewPage/Interview/redux/store'
import { useRequestGetSignalingPoints } from '@/my/services/bffService'
import { LOCAL_STORAGE_KEY } from '@/my/services/storageService'
import { InterviewRoom } from '@/shared/services/interviewService'
import { SignalingPoints } from '@/shared/services/interviewService/types'
import { LeaveModal } from './LeaveModal'
import { InRoomContainer } from './containers/InRoomContainer'
import { useSessionToken, SessionToken } from './containers/useSessionToken'

export const InRoom: React.VFC = React.memo(() => {
  const { interviewGuid } = useParams<{ interviewGuid: string }>()
  const [sessionToken, setSessionToken] = useState<SessionToken>()
  const [signalingPoints, setSignalingPoints] = useState<SignalingPoints>()
  const { getSessionToken } = useSessionToken()
  const { requestGetSignalingPoints } = useRequestGetSignalingPoints()
  const invitationToken = useSelector((state: RootState) => state.my.invitationToken)
  invariant(invitationToken)

  useEffect(() => {
    const setSessionTokenFn = async () => {
      const token = await Promise.all([
        getSessionToken(LOCAL_STORAGE_KEY.interviewSessionToken.user),
        getSessionToken(LOCAL_STORAGE_KEY.interviewSessionToken.screenShare),
        getSessionToken(LOCAL_STORAGE_KEY.interviewSessionToken.screenShareRecv),
      ])
      setSessionToken({
        user: token[0],
        screenShare: token[1],
        screenShareRecv: token[2],
      })
    }

    const setSiganlingPointsFn = async () => {
      let res: GetSignalingPointsResponse
      try {
        res = await requestGetSignalingPoints(interviewGuid, invitationToken)
      } catch (err) {
        Sentry.captureException(err)
        alertToast('接続先の取得に失敗しました。再読み込みしてください。')
        return
      }
      const obj = res.toObject()
      invariant(obj.main)
      invariant(obj.screenSharing)
      setSignalingPoints({ main: obj.main, screenSharing: obj.screenSharing })
    }

    setSessionTokenFn()
    setSiganlingPointsFn()
  }, [getSessionToken, requestGetSignalingPoints, interviewGuid, invitationToken])

  if (sessionToken && signalingPoints) {
    return (
      <InRoomContainer.Provider initialState={{ sessionToken, signalingPoints }}>
        <InRoomContainerProvided />
      </InRoomContainer.Provider>
    )
  }
  // TODO: ローディング表示
  return null
})

const InRoomContainerProvided: React.VFC = React.memo(() => {
  const ctx = InRoomContainer.useContainer()
  return (
    <>
      <InterviewRoom {...ctx} />
      <LeaveModal />
    </>
  )
})
