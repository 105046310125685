import { Icon, TertiaryButton } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { controlButtonBase } from '@/shared/services/interviewService/components/controlButtons/controlButtonBase'
import { InterviewPinningContainer } from '@/shared/services/interviewService/containers/InterviewPinningContainer'
import { isInterviewReadySelector } from '../../redux'

export function PinButton() {
  const { isInterviewPinningAreaOpen, toggleInterviewPinningAreaOpen } = InterviewPinningContainer.useContainer()
  const isInterviewReady = useSelector(isInterviewReadySelector)

  const disabled = !isInterviewReady
  return (
    <>
      {isInterviewPinningAreaOpen ? (
        <Button
          onClick={toggleInterviewPinningAreaOpen}
          text="ピン非表示"
          direction="column"
          icon={<Icon name="map-pin-fill" size="l" />}
          disabled={disabled}
          comlinkPushParams={{ action: 'click_close_interview_pinning_button' }}
        />
      ) : (
        <Button
          onClick={toggleInterviewPinningAreaOpen}
          text="ピン"
          direction="column"
          icon={<Icon name="map-pin-outline" size="l" />}
          disabled={disabled}
          comlinkPushParams={{ action: 'click_open_interview_pinning_button' }}
        />
      )}
    </>
  )
}

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const Button = styled(TertiaryButton)`
  ${controlButtonBase}
  border: 0px;
  width: 100%;
  height: 100%;
  background-color: transparent;
`
