import React, { useCallback } from 'react'
import { InputText, Txt, theme } from '@blue-agency/rogue'
import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled, { css } from 'styled-components'
import { ManualModal } from '../ManualModal'
import { ManualModalContainer } from '../containers/ManualModalContainer'
import { useRequestEntryForm } from './useRequestEntryForm'

export const RequestEntryForm: React.VFC = () => {
  const ctx = useRequestEntryForm()
  const { handleManualModalOpen, isManualModalShown } = ManualModalContainer.useContainer()

  const checkKeyDown = useCallback<React.KeyboardEventHandler<HTMLFormElement>>((e) => {
    // Enter キーを押下されたときに Form を送信しない
    if (e.key === 'Enter') e.preventDefault()
  }, [])

  return (
    <div>
      <GuideTxtWrapper>
        <Title>
          {ctx.isRequesting ? '参加をリクエストしました。このままお待ちください。' : '名前を入力してください'}
        </Title>
        <Txt size="s">リクエストが承認されると接続を開始します。</Txt>
        <Caption color={ctx.isRequesting ? theme.color.navy[1] : theme.color.gray[1]}>
          開始時間を過ぎても承認されない場合は、企業の担当者様にご連絡をお願いします。
        </Caption>
      </GuideTxtWrapper>
      <form onSubmit={ctx.requestEnter} onKeyDown={checkKeyDown}>
        <NameInputWrapper>
          <InputText
            {...ctx.form.register('name')}
            placeholder="山田 太郎"
            size="max"
            disabled={ctx.isRequesting}
            onBlurError={ctx.form.formState.errors['name']?.message}
            autoComplete="off"
          />
        </NameInputWrapper>
        <ButtonWrapper>
          <ManualButton
            comlinkPushParams={{ action: 'click_watch_interview_manual' }}
            widthSize="L1"
            onClick={handleManualModalOpen}
          >
            操作方法を確認
          </ManualButton>
          <ManualModal active={isManualModalShown} />
          <RequestButton
            type="submit"
            comlinkPushParams={{ action: 'click_request_entry_button' }}
            widthSize="L1"
            disabled={ctx.isRequesting}
          >
            {ctx.isRequesting ? '参加リクエスト済' : '参加をリクエスト'}
          </RequestButton>
        </ButtonWrapper>
      </form>
    </div>
  )
}

const GuideTxtWrapper = styled.div`
  margin-bottom: 16px;
`

const Title = styled(Txt).attrs({ size: 'xl' })`
  margin-bottom: 8px;
`

const Caption = styled(Txt).attrs({ size: 's' })`
  margin-top: 8px;
`

const NameInputWrapper = styled.div`
  width: 100%;

  ${({ theme }) => {
    if (theme.responsive.pc) {
      return css`
        width: 260px;
      `
    }
  }}
`
const ButtonWrapper = styled.div`
  margin-top: 16px;
  display: flex;

  & > *:not(:first-child) {
    margin-left: 20px;
  }
`
const RequestButton = styled(PrimaryButton)`
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.spPortrait) {
      return css`
        width: 100%;
      `
    }
  }}
`

const ManualButton = styled(TertiaryButton)`
  ${({ theme }) => {
    if (theme.responsive.spLandscape || theme.responsive.spPortrait) {
      return css`
        width: 100%;
      `
    }
  }}
`
