import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { assertNever } from '@/assertions'
import { SharedState } from '..'

export const isLocalVideoEnabledSelector = createDraftSafeSelector(
  (state: SharedState) => state.shared.selfViewVisibility,
  (visibility) => {
    switch (visibility) {
      case 'visible':
        return true
      case 'hidden':
        return false
    }
    assertNever(visibility)
  }
)
