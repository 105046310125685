import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  DataChannelMessagingContainer,
  Data,
  labelNetworkUnstableLevel,
} from '@/lib/meetingcomponent/DataChannelMessagingContainer'
import { sharedSlice } from '@/shared/services/interviewService/redux'
import { UnstableLevel } from '@/shared/services/interviewService/types'

export const useReceiveNetworkUnstableLevel = () => {
  const { addObserver, removeObserver } = DataChannelMessagingContainer.useContainer()
  const dispatch = useDispatch()

  useEffect(() => {
    const fn = (data: Data) => {
      const d = data as {
        soraClientId: string
        unstableLevel: UnstableLevel
      }

      dispatch(sharedSlice.actions.unstableLevelMessageReceived(d))
    }
    addObserver(labelNetworkUnstableLevel, fn)

    return () => {
      removeObserver(labelNetworkUnstableLevel, fn)
    }
  }, [dispatch, addObserver, removeObserver])
}
