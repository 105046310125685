export type MinutesViewSizesLocalStorageKey =
  | 'MINUTES_VIEW_LEFT_SECTION_WIDTH'
  | 'MINUTES_VIEW_INTERVIEW_MINUTES_AREA_HEIGHT'
  | 'MINUTES_VIEW_VIDEO_AREA_HEIGHT'

export const setMinutesViewSizesLocalStorage = (key: MinutesViewSizesLocalStorageKey, value: number) => {
  localStorage.setItem(key, JSON.stringify(value))
}

export const getLocalStorageLefSectionWithPercent = (key: MinutesViewSizesLocalStorageKey): number | null => {
  const data = localStorage.getItem(key)
  if (data) {
    return JSON.parse(data)
  }
  return null
}
