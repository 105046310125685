import { useCallback, useEffect, useRef, useReducer } from 'react'
import { comlinkPush } from '@blue-agency/im-shared-front'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { WebSocketMessage } from 'react-use-websocket/dist/lib/types'
import { logger } from '@/logger'

const MAX_HEARTBEATS_COUNT = 3
const RECONNECT_COUNT = 5
const RECONNECT_INTERVAL_MS = 3000
const HEARTBEATS_INTERVAL_MS = 5000

export const WebSocketReadyState = ReadyState

export type WebSocketMessageMap = {
  pong: {
    status: 'pong'
  }
  start: {
    status: 'start'
    channel_id: string
    start_time: string
    current_time: string
  }
  finish: {
    status: 'finish'
    channel_id: string
  }
  'in trouble': {
    status: 'in trouble'
    action: 'change webrtc host'
    channel_id: string
    webrtc_host: string
  }
  'request entry': {
    status: 'request entry'
    channel_id: string
    entry_request_guid: string
  }
  'approve entry': {
    status: 'approve entry'
    channel_id: string
    entry_request_guid: string
  }
  'reject entry': {
    status: 'reject entry'
    channel_id: string
    entry_request_guid: string
  }
  'cancel entry request': {
    status: 'cancel entry request'
    entry_request_guid: string
  }
  enter: {
    status: 'enter'
    channel_id: string
    sora_client_id: string
  }
  'fail to enter': {
    status: 'fail to enter'
    channel_id: string
    entry_request_guid: string
  }
  'changed quality': {
    status: 'changed quality'
  }
}

export type InterviewWebSocketMessageListenerFn<K extends keyof WebSocketMessageMap> = (
  message: WebSocketMessageMap[K]
) => void

type InterviewWebSocketMessageListener<K extends keyof WebSocketMessageMap> = {
  status: K
  fn: InterviewWebSocketMessageListenerFn<K>
}

export type InterviewWebSocket = {
  readyState: ReadyState
  sendMessage: (message: WebSocketMessage) => void
  addMessageListener: <K extends keyof WebSocketMessageMap>(
    status: K,
    listener: InterviewWebSocketMessageListenerFn<K>
  ) => void
  removeMessageListener: <K extends keyof WebSocketMessageMap>(
    status: K,
    listener: InterviewWebSocketMessageListenerFn<K>
  ) => void
}

export const useInterviewWebSocket = (socketUrl: string): InterviewWebSocket => {
  // NOTE: any を使っている理由
  // https://github.com/blue-agency/skywalker-front/pull/40#discussion_r529146791
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const messageListenersRef = useRef<InterviewWebSocketMessageListener<any>[]>([])
  const heartbeatsCntRef = useRef(0)
  const [forcedReconnectCnt, forceReconnect] = useReducer((prev) => ++prev, 0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSocketUrl = useCallback(() => socketUrl, [socketUrl, forcedReconnectCnt])

  const onMessage = useCallback((event: MessageEvent) => {
    const message = (() => {
      try {
        return JSON.parse(event.data)
      } catch {
        throw new Error(`Failed to JSON.parse: ${event.data}`)
      }
    })()

    if (message.status === 'pong') {
      heartbeatsCntRef.current = 0
      return
    }

    logger.info('[ws] receive message', message)

    messageListenersRef.current.forEach((listener) => {
      if (message.status === listener.status) {
        listener.fn(message)
      }
    })
  }, [])

  const onOpen = useCallback(() => {
    comlinkPush('open_interview_websocket')
  }, [])

  const { readyState, sendMessage } = useWebSocket(getSocketUrl, {
    onOpen,
    onMessage,
    // MEMO: 正常に終了した時はcodeが1005でreasonが空文字なので、それ以外の時は必ず再接続するようにしている
    shouldReconnect: (event) => !(event.code === 1005 && event.reason === ''),
    reconnectAttempts: RECONNECT_COUNT,
    reconnectInterval: RECONNECT_INTERVAL_MS,
    // NOTE: pongなどws受信で再レンダリングが発生するのを抑止 https://github.com/robtaussig/react-use-websocket/issues/93
    filter: () => false,
  })

  useEffect(() => {
    if (readyState !== ReadyState.OPEN) return
    const intervalId = setInterval(() => {
      if (MAX_HEARTBEATS_COUNT <= heartbeatsCntRef.current) {
        forceReconnect()
        comlinkPush('force_reconnect_interview_websocket')
        heartbeatsCntRef.current = 0
        return
      }
      heartbeatsCntRef.current++
      sendMessage(JSON.stringify({ status: 'ping' }))
    }, HEARTBEATS_INTERVAL_MS)
    return () => clearInterval(intervalId)
  }, [readyState, sendMessage])

  const addMessageListener = useCallback(
    <K extends keyof WebSocketMessageMap>(status: K, listener: InterviewWebSocketMessageListenerFn<K>) => {
      const newListeners = [
        ...messageListenersRef.current,
        {
          status,
          fn: listener,
        },
      ]
      messageListenersRef.current = newListeners
    },
    []
  )

  const removeMessageListener = useCallback(
    <K extends keyof WebSocketMessageMap>(status: K, targetListener: InterviewWebSocketMessageListenerFn<K>) => {
      const newListeners = messageListenersRef.current.filter((listener) => {
        return listener.status !== status && listener.fn !== targetListener
      })
      messageListenersRef.current = newListeners
    },
    []
  )

  return {
    readyState,
    sendMessage,
    addMessageListener,
    removeMessageListener,
  }
}
