import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb'

const msToNanos = 1000000

/**
 * ts は nullable として使われることが多いので、そのように引数を受け付ける
 * required が true のとき、ts が undefined であれば Error を throw する
 */
export function timestampToDate(ts: Timestamp.AsObject | undefined, required: true): Date
export function timestampToDate(ts: Timestamp.AsObject | undefined, required?: false): Date | undefined
export function timestampToDate(ts: Timestamp.AsObject | undefined, required?: boolean) {
  if (ts === undefined) {
    if (required) {
      throw new Error('timestamp is undefined')
    }
    return undefined
  }

  return new Date(ts.seconds * 1000 + Math.round(ts.nanos / msToNanos))
}
