import React from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { ChatMessageComponent } from '@/shared/services/interviewService/components/ChatList/ChatMessageComponent'
import { ChatMessageForm } from '@/shared/services/interviewService/components/ChatList/ChatMessageForm'
import { useScrollToBottom } from '@/shared/services/interviewService/components/ChatList/useScrollToBottom'
import { ChatContainer } from '@/shared/services/interviewService/containers/ChatContainer'
import { SharedState } from '@/shared/services/interviewService/redux'
import { overflowYScrollStyle } from '@/shared/styles/overflowYScrollStyle'

export const ChatList: React.VFC = React.memo(() => {
  const { closeChatArea } = ChatContainer.useContainer()
  const chatMessages = useSelector((state: SharedState) => state.shared.chatMessages)
  const { messageContainerRef } = useScrollToBottom()

  return (
    <Wrapper>
      <Header>
        <Title>
          <Icon name="message" />
          <Txt bold>チャット</Txt>
        </Title>
        <CloseButton onClick={closeChatArea}>
          <Icon name="close" />
        </CloseButton>
      </Header>
      <Inner ref={messageContainerRef}>
        {chatMessages.map((m) => (
          <ChatMessageComponent key={m.messageGuid} message={m} />
        ))}
      </Inner>
      <FormWrapper>
        <ChatMessageForm />
      </FormWrapper>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.responsive.pc &&
    css`
      padding-bottom: 16px;
      width: 250px;
    `}
`

const HEADER_HEIGHT = '36px'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  padding: 8px;
  align-items: center;
  border-bottom: 1px solid ${theme.color.navy[1]};
`

const Title = styled.div`
  display: flex;
  align-items: center;
  > *:first-child {
    margin-right: 5px;
    /* アイコンの縦位置 調整 */
    margin-bottom: 2px;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
`

const Inner = styled.div`
  ${overflowYScrollStyle};
  flex: 1;
`

const FormWrapper = styled.div`
  padding: 12px 6px 6px 6px;
  border-top: 1px solid #eeeeee;
`
