import React from 'react'
import { PageLayout } from '@blue-agency/im-shared-front'
import { theme, Txt } from '@blue-agency/rogue'
import { Illustration } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const GeneralErrorPage: React.VFC = () => {
  return (
    <PageLayout backgroundColor={theme.color.gray[5]} disableLogoLink>
      <Inner>
        <Description>
          <Txt size="l">
            エラーが発生しました。時間をおいて再度お試しください。
            <br />
            復旧しない場合は、企業の担当者様にお問い合わせください。
          </Txt>
        </Description>
        <Illustration name="general-error" width="200" />
      </Inner>
    </PageLayout>
  )
}

const Inner = styled.div`
  margin: 44px auto;
  max-width: 90%;
  text-align: center;
`

const Description = styled.div`
  margin-bottom: 25px;
`
