import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { SharedState } from '..'
import { isInterviewReadySelector } from './interviewStateSelector'

export const isChatReadySelector = createDraftSafeSelector(
  isInterviewReadySelector,
  (state: SharedState) => state.shared.isChatWebSocketConnected,
  (isInterviewReady, isChatWebSocketConnected) => {
    return isInterviewReady && isChatWebSocketConnected
  }
)
