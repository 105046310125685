import { useState, useCallback } from 'react'
import { createContainer, useCachedPromise } from '@blue-agency/front-state-management'
import { LoggedInStaff, useLoggedInStaff } from '@blue-agency/im-shared-front'
import { useReadNotification } from '@blue-agency/im-shared-front/dist/glonavi/useReadNotification'
import { GetInterviewResponse } from '@blue-agency/proton/biz_skywalker_bff'
import { InterviewQualityMode as BffInterviewQualityMode } from '@blue-agency/proton/web/v2/biz_skywalker_bff'
import * as Sentry from '@sentry/react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { WithLoading } from '@/@types/withLoading'
import {
  useRequestGetInterview,
  useRequestGetInterviewQuality,
  useRequestGetOrganizationInterviewTranscriptionSetting,
} from '@/biz/services/bffService'
import { cacheKey } from '@/biz/services/cacheKeyService'
import { QUERY_KEY } from '@/biz/services/queryKeyService'
import { SESSION_STORAGE_KEY } from '@/biz/services/storageService'
import { InterviewQuality, InterviewQualityMode } from '@/shared/services/interviewService/types'

export type ResponseValid = {
  status: GetInterviewResponse.Status.VALID
  interviewGuid: string
  isValid: true
  qualityMode: InterviewQualityMode
  durationMinutes: number | undefined
  scheduledStartTime: Date | undefined

  invitationToken: string
  videoInterviewGuid: string

  isFinishedEnvironmentCheck: boolean
  finishEnvironmentCheck: () => void

  name: string
  loggedInStaff: LoggedInStaff

  guide?: {
    name: string
    partsList: Array<{
      title: string
      description: string
      durationSeconds: number
    }>
    firstPartStartSeconds: number
  }

  isInterviewTranscriptionEnabled: boolean
}

type Notification = {
  newNotification?: boolean
  readNotification?: () => void
}

type ResponseInvalid = {
  interviewGuid: string
  loggedInStaff: LoggedInStaff
  isValid: false
  isInterviewTranscriptionEnabled: boolean
}

export type ResponseFinished = ResponseInvalid & {
  status: GetInterviewResponse.Status.FINISHED
}

export type ResponseExpired = ResponseInvalid & {
  status: GetInterviewResponse.Status.EXPIRED
}

export type ResponseUnknown = ResponseInvalid & {
  status: GetInterviewResponse.Status.STATUS_UNKNOWN
}

export type Response = WithLoading<ResponseValid | ResponseFinished | ResponseExpired | ResponseUnknown> & Notification

const useInterviewPage = (): Response => {
  const { interviewGuid } = useParams<{ interviewGuid: string }>()

  const { data, isLoading } = useGetInterviewQuery(interviewGuid)
  const loggedInStaff = useLoggedInStaff()
  const { mutateAsync, isLoading: useReadNotificationIsLoading } = useReadNotification()
  const readNotification = useCallback(async () => {
    try {
      await mutateAsync()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [mutateAsync])

  const isInterviewTranscriptionEnabled = useGetInterviewTranscriptionEnabled(interviewGuid)

  const [isFinishedEnvironmentCheck, setIsFinishedEnvironmentCheck] = useState(() => {
    return window.sessionStorage.getItem(SESSION_STORAGE_KEY.interview.environmentCheck(interviewGuid)) === 'done'
  })

  const finishEnvironmentCheck = useCallback(() => {
    setIsFinishedEnvironmentCheck(true)
    window.sessionStorage.setItem(SESSION_STORAGE_KEY.interview.environmentCheck(interviewGuid), 'done')
  }, [interviewGuid])

  const quality = useGetInterviewQuality(interviewGuid)

  if (isLoading || useReadNotificationIsLoading || !loggedInStaff) {
    return { isLoading: true }
  }

  if (!data) throw new Error('data is undefined')

  let duration = data.getDuration()?.getSpecified()?.getDurationSeconds()
  // sec -> min に変換
  if (duration) duration /= 60

  const status = data.getStatus()
  if (status === GetInterviewResponse.Status.VALID) {
    return {
      status,
      isLoading: false,
      isValid: true,
      interviewGuid,
      videoInterviewGuid: data.getVideoInterviewGuid(),
      invitationToken: data.getInvitationToken(),
      loggedInStaff,
      isFinishedEnvironmentCheck,
      finishEnvironmentCheck,
      name: data.getName(),
      guide: data.getGuide()?.toObject(),
      qualityMode: quality.mode,
      durationMinutes: duration,
      newNotification: loggedInStaff?.newNotification,
      readNotification: readNotification,
      scheduledStartTime: data.getScheduledStartTime()?.toDate(),
      isInterviewTranscriptionEnabled: isInterviewTranscriptionEnabled,
    }
  } else {
    return {
      status,
      isLoading: false,
      isValid: false,
      interviewGuid,
      loggedInStaff,
      isInterviewTranscriptionEnabled,
    }
  }
}

export const InterviewPageContainer = createContainer(useInterviewPage)

function useGetInterviewQuery(interviewGuid: string) {
  const { requestGetInterview } = useRequestGetInterview()

  return useQuery([QUERY_KEY.interview, interviewGuid], () => {
    return requestGetInterview(interviewGuid)
  })
}

function useGetInterviewQuality(interviewGuid: string) {
  const { requestGetInterviewQuality } = useRequestGetInterviewQuality()

  return useCachedPromise(cacheKey.getInterviewQuality({ interviewGuid }), async (): Promise<InterviewQuality> => {
    const res = await requestGetInterviewQuality(interviewGuid)
    return {
      mode: res.getMode() === BffInterviewQualityMode.LOW ? 'low' : 'normal',
      videoBitRate: res.getVideoBitRate(),
      videoFrameRate: res.getVideoFrameRate(),
    }
  })
}

function useGetInterviewTranscriptionEnabled(interviewGuid: string) {
  const { requestGetOrganizationInterviewTranscriptionSetting } =
    useRequestGetOrganizationInterviewTranscriptionSetting()
  return useCachedPromise(cacheKey.getInterviewQuality({ interviewGuid }), async (): Promise<boolean> => {
    const res = await requestGetOrganizationInterviewTranscriptionSetting()
    return res.getEnabled()
  })
}
