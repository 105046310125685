import { LOCAL_STORAGE_KEY } from '@/my/services/storageService'

/**
 * 名前を引き継ぐための処理をしたあとリロードする
 */
export function reEntry({ ownName }: { ownName: string }): void {
  setReEntryName(ownName)
  window.location.reload()
}

export function setReEntryName(ownName: string): void {
  window.localStorage.setItem(LOCAL_STORAGE_KEY.interview.reEntryName, ownName)
}

export function getReEntryName(): string | undefined {
  return window.localStorage.getItem(LOCAL_STORAGE_KEY.interview.reEntryName) ?? undefined
}
