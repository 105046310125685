//skywalkerのみPC画面の閾値を927pxにしたいのでrougeとは別に用意する
import { useIsIPad, ResponsiveType, Responsive, Orientation } from '@blue-agency/rogue'
import useMedia from 'use-media'
import { assertNever } from '@/assertions'

type Response = {
  responsive: Responsive
}

export function useInterviewDisplaySize(): Response {
  const orientation = useOrientation()
  const { isIPad } = useIsIPad()
  const isPcSize = useMedia('(min-width: 927px)', window.innerWidth >= 927)

  if (isIPad) {
    switch (orientation) {
      case 'portrait':
        return {
          responsive: responsiveOf('spPortrait'),
        }
      case 'landscape':
        if (isPcSize) {
          return {
            responsive: responsiveOf('pc'),
          }
        }
        return {
          responsive: responsiveOf('spLandscape'),
        }
      default:
        assertNever(orientation)
    }
  }

  if (isPcSize) {
    return {
      responsive: responsiveOf('pc'),
    }
  }

  switch (orientation) {
    case 'portrait':
      return {
        responsive: responsiveOf('spPortrait'),
      }
    case 'landscape':
      return { responsive: responsiveOf('spLandscape') }
    default:
      assertNever(orientation)
  }
}

function responsiveOf(type: ResponsiveType): Responsive {
  const base: Responsive = {
    pc: false,
    spPortrait: false,
    spLandscape: false,
  }

  return {
    ...base,
    [type]: true,
  }
}

export function useOrientation(): Orientation {
  const isPortrait = useMedia('(orientation: portrait)', isPortraitFn())

  if (window.screen.orientation !== undefined) {
    switch (window.screen.orientation.type) {
      case 'portrait-primary':
      case 'portrait-secondary':
        return 'portrait'
      case 'landscape-primary':
      case 'landscape-secondary':
        return 'landscape'
      default:
        assertNever(window.screen.orientation.type)
    }
  }

  return isPortrait ? 'portrait' : 'landscape'
}

function isPortraitFn() {
  // iOS Safari 用
  // eslint-disable-next-line deprecation/deprecation
  if (window.orientation !== undefined) {
    // eslint-disable-next-line deprecation/deprecation
    return window.orientation === 0
  }

  if (window.screen.orientation !== undefined) {
    if (
      window.screen.orientation.type === 'portrait-primary' ||
      window.screen.orientation.type === 'portrait-secondary'
    ) {
      return true
    }
    return false
  }
  // あとから補正させるので、何にも引っかからなかった場合 true を返す
  return true
}
