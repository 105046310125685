import { Action, AnyAction, combineReducers, configureStore, ReducersMapObject } from '@reduxjs/toolkit'
import { save as saveLS, load as loadLS } from 'redux-localstorage-simple'
import { comlinkMiddleware } from '@/comlinkMiddleware'
import { sharedSlice, sharedStoredSlice, timerSlice } from '.'

export function createStore<S, A extends Action = AnyAction>(reducers: ReducersMapObject<S, A>) {
  // ↓ で通ると思ったけどエラーが出るので any で回避
  // const reducer = combineReducers<S & SharedState>({
  // 型パラメータを指定しないと、引数で受けた reducers が含まれていない型定義の reducer が生成される
  //
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reducer = combineReducers<any>({
    ...reducers,
    shared: sharedSlice.reducer,
    sharedStored: sharedStoredSlice.reducer,
    timer: timerSlice.reducer,
  })

  const lsOption = { states: [sharedStoredSlice.name], namespace: 'redux_storage', disableWarnings: true }

  return configureStore({
    reducer,
    preloadedState: loadLS(lsOption),
    middleware: (getDefaultMiddleware) => [
      comlinkMiddleware,
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredPaths: ['shared.chatMessages'],
          ignoredActions: [sharedSlice.actions.enterRpcCallSucceeded.type],
        },
      }),
      saveLS(lsOption),
    ],
  })
}
