import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRequestListPendingEntryRequests } from '@/biz/services/bffService'
import { bizSlice } from '../../../redux/bizSlice'
import { RootState } from '../../../redux/store'
import { InRoomContainer } from '../../containers/InRoomContainer'

export const useListPendingEntryRequestsWhenEntered = () => {
  const { interviewGuid } = InRoomContainer.useContainer()

  const dispatch = useDispatch()
  const { requestListPendingEntryRequests } = useRequestListPendingEntryRequests()
  const ownSoraClientId = useSelector((state: RootState) => state.shared.ownSoraClientId)

  useEffect(() => {
    if (!ownSoraClientId) return

    const fn = async () => {
      const res = await requestListPendingEntryRequests(interviewGuid)
      res.getEntryRequestsList().forEach((entryRequest) => {
        dispatch(
          bizSlice.actions.requestEntryReceived({
            entryRequestGuid: entryRequest.getGuid(),
            name: entryRequest.getName(),
          })
        )
      })
    }
    fn()
  }, [dispatch, interviewGuid, ownSoraClientId, requestListPendingEntryRequests])
}
