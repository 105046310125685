import styled from 'styled-components'
import { useLocalVideoElement } from '@/lib/meetingcomponent/useLocalVideoElement'

export const VideoPreview: React.VFC<{ className?: string }> = ({ className }) => {
  const videoEl = useLocalVideoElement()

  return <Video className={className} ref={videoEl} />
}

const Video = styled.video`
  width: 100px;
  height: 56px;
  transform: scaleX(-1);
  background-color: #999;
`
