import { useCallback } from 'react'
import { useComlinkPushWithStaffGuid } from '@blue-agency/im-shared-front'
import { alertToast } from '@blue-agency/rogue'
import { useDispatch, useSelector } from 'react-redux'
import { bizSlice } from '../../redux/bizSlice'
import { RootState } from '../../redux/store'

export function useCanceledEntryRequestHandler() {
  const dispatch = useDispatch()
  const comlinkPushWithStaffGuid = useComlinkPushWithStaffGuid()

  const pendingEntryRequests = useSelector((state: RootState) => state.biz.pendingEntryRequests)

  return useCallback(
    (entryRequestGuid: string) => {
      const target = pendingEntryRequests.find((r) => r.entryRequestGuid === entryRequestGuid)

      if (target === undefined) {
        comlinkPushWithStaffGuid('participant who canceled not found')
        return
      }

      alertToast(`${target.name}さんのリクエストが\nキャンセルされました。`)

      dispatch(bizSlice.actions.cancelEntryRequestReceived({ entryRequestGuid }))
    },
    [comlinkPushWithStaffGuid, dispatch, pendingEntryRequests]
  )
}
