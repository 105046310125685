import React from 'react'
import { CustomGrpcError } from '@blue-agency/im-shared-front'
import { ErrorInfo } from '@blue-agency/proton/web/v2/errdetails/error_details_pb'
import { ErrorReason } from '@blue-agency/proton/web/v2/my_skywalker_bff/error_reason_pb'
import * as Sentry from '@sentry/react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { GeneralErrorPage } from './GeneralErrorPage'
import { InterviewDeletedPage } from './InterviewDeletedPage'
import { NotFoundPage } from './NotFoundPage'

export const MyErrorBoundary: React.FC = ({ children }) => {
  return <ErrorBoundary FallbackComponent={Fallback}>{children}</ErrorBoundary>
}

type ErrorReasonMessage = keyof typeof ErrorReason

const Fallback: React.VFC<FallbackProps> = ({ error }) => {
  if (error instanceof CustomGrpcError) {
    if (error.isNotFound) {
      const detail = error.errorDetailsOfGoogleApis[0]
      if (detail instanceof ErrorInfo) {
        const reason = detail.getReason() as ErrorReasonMessage
        if (reason === 'INTERVIEW_DELETED') {
          return <InterviewDeletedPage />
        }
      }
      return <NotFoundPage />
    }
  }

  Sentry.captureException(error)

  return <GeneralErrorPage />
}
