import { useEffect } from 'react'
import differenceBy from 'lodash-es/differenceBy'
import { useSelector } from 'react-redux'
import { AudioOutputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { SoundPlayContainer } from '@/shared/containers/SoundPlayContainer'
import { usePrevious } from '@/shared/hooks/usePrevious'
import { RootState } from '../../../../redux/store'
import { notificationSoundSrc } from './notificationSoundSrc'

export function usePlayNotificationSoundWhenRequested() {
  const pendingEntryRequests = useSelector((state: RootState) => state.biz.pendingEntryRequests)
  const prevPendingEntryRequests = usePrevious(pendingEntryRequests)

  const { playSound } = SoundPlayContainer.useContainer()
  const { selectedAudioOutputDevice } = AudioOutputContainer.useContainer()

  useEffect(() => {
    const addedRequests = (() => {
      if (!prevPendingEntryRequests) {
        return pendingEntryRequests
      }
      return differenceBy(pendingEntryRequests, prevPendingEntryRequests, (r) => r.entryRequestGuid)
    })()

    if (addedRequests.length > 0) {
      // Safariでは音声出力デバイスの一覧がchime SDKで取得できないため、selectedAudioOutputDeviceはnullになる。
      // playSoundにspearkerDeviceとしてnullを渡すと渡すとデフォルトデバイスを指定して再生してくれるため、Safariでも再生できる。
      playSound(selectedAudioOutputDevice, notificationSoundSrc, 0.3)
    }
  }, [pendingEntryRequests, playSound, prevPendingEntryRequests, selectedAudioOutputDevice])
}
