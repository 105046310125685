import { useCallback, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { CreateInterviewPinningRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { alertToast } from '@blue-agency/rogue'
import * as Sentry from '@sentry/react'
import { useSelector } from 'react-redux'
import { InterviewPageContainer } from '@/biz/pages/InterviewPage/containers/InterviewPageContainer'
import { useRequestCreateInterviewPinning } from '@/biz/services/bffService'
import { showInterviewPinningToast } from '@/shared/services/interviewService/components/InterviewPinning/interviewPinningToast'
import { SharedState } from '@/shared/services/interviewService/redux'

const notCreatablePinningInterval = 5000

const useInterviewPinning = () => {
  const [isCreatableInterviewPinning, setIsCreatableInterviewPinning] = useState(true)
  const pageCtx = InterviewPageContainer.useContainer()
  if (pageCtx.isLoading) {
    throw new Error('invalid state')
  }
  if (!pageCtx.isValid) {
    throw new Error('invalid status')
  }
  const soraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  const { requestCreateInterviewPinning } = useRequestCreateInterviewPinning()
  const createInterviewPinning = useCallback(
    async (interviewGuid: string, interviewPinningType: CreateInterviewPinningRequest.InterviewPinningType) => {
      if (soraClientId === undefined) {
        throw new Error('cannot use before entered')
      }
      setIsCreatableInterviewPinning(false)
      try {
        await requestCreateInterviewPinning({ interviewGuid, soraClientId, interviewPinningType })
      } catch (err) {
        Sentry.captureException(err)
        alertToast('ピンの入力に失敗しました。')
        return
      }

      switch (interviewPinningType) {
        case CreateInterviewPinningRequest.InterviewPinningType.GOOD:
          showInterviewPinningToast('good', notCreatablePinningInterval)
          break
        case CreateInterviewPinningRequest.InterviewPinningType.BAD:
          showInterviewPinningToast('bad', notCreatablePinningInterval)
          break
        case CreateInterviewPinningRequest.InterviewPinningType.QUESTION:
          showInterviewPinningToast('question', notCreatablePinningInterval)
          break
      }

      setTimeout(() => setIsCreatableInterviewPinning(true), notCreatablePinningInterval)
    },
    [requestCreateInterviewPinning, soraClientId]
  )

  const createInterviewPinningTypeGood = () => {
    createInterviewPinning(pageCtx.interviewGuid, CreateInterviewPinningRequest.InterviewPinningType.GOOD)
  }
  const createInterviewPinningTypeBad = () => {
    createInterviewPinning(pageCtx.interviewGuid, CreateInterviewPinningRequest.InterviewPinningType.BAD)
  }
  const createInterviewPinningTypeQuestion = () => {
    createInterviewPinning(pageCtx.interviewGuid, CreateInterviewPinningRequest.InterviewPinningType.QUESTION)
  }

  return {
    createInterviewPinningTypeGood,
    createInterviewPinningTypeBad,
    createInterviewPinningTypeQuestion,
    isCreatableInterviewPinning,
  }
}

export const BizInterviewPinningContainer = createContainer(useInterviewPinning)
