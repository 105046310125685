import { useEffect } from 'react'
import { alertToast } from '@blue-agency/rogue'
import { AudioVideoObserver } from '@/lib/interview-sdk-js'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { AudioInputContainer, VideoInputContainer } from '@/lib/meetingcomponent/DevicesContainer'
import { buildErrorMessage } from '@/lib/meetingcomponent/deviceChangeError'

export const useHandleDeviceChangeError = () => {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { videoInputs } = VideoInputContainer.useContainer()
  const { audioInputs } = AudioInputContainer.useContainer()

  useEffect(() => {
    if (!audioVideo) {
      return
    }
    const observer: AudioVideoObserver = {
      eventDidReceive(name, attributes) {
        switch (name) {
          case 'audioInputFailed':
            if (audioInputs.length === 0) {
              alert(buildErrorMessage('audioinput'))
            } else {
              alertToast('マイクの切替に失敗しました。ブラウザを再読込してください。')
            }
            break
          case 'videoInputFailed':
            if (videoInputs.length === 0) {
              alert(buildErrorMessage('videoinput'))
            } else {
              alertToast('カメラの切替に失敗しました。ブラウザを再読込してください。')
            }
            break
        }
      },
    }
    audioVideo.addObserver(observer)

    return () => {
      audioVideo?.removeObserver(observer)
    }
  }, [audioVideo, audioInputs.length, videoInputs.length])
}
