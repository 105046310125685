import { useLocalStorage, useMount } from 'react-use'
import { useUA } from '@/shared/hooks/useUA'
import { isMac, isWindows } from '@/shared/services/browserCheckService/browser'

export const useNoiseSuppression = () => {
  const [enabledNoiseSuppression, setEnabledNoiseSuppression] = useLocalStorage<boolean>(
    'enabledNoiseSuppression',
    undefined
  )
  const ua = useUA()
  const isUsedPC = isMac(ua) || isWindows(ua)

  useMount(() => {
    // PCで未設定の場合のデフォルト値をセットする
    // falseだと挙動としては何も対処しないのと同じだが、将来的にデフォルトをONに出来るように残しておく
    if (isUsedPC && enabledNoiseSuppression === undefined) {
      setEnabledNoiseSuppression(false)
    }
  })

  return { enabledNoiseSuppression, setEnabledNoiseSuppression }
}
