import React, { forwardRef } from 'react'
import { A, theme, Toggle, Txt } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { NotificationDot as _NotificationDot } from '@/shared/components/NotificationDot'
import {
  DropdownItem,
  DropdownLinkItem,
  DropdownToggleItem,
  IsDropdownLinkItem,
  IsDropdownToggleItem,
} from '@/shared/hooks/useDropdown'

type Props = PropsWithClassName<{
  items: DropdownItem[]
  width: number
}>

export const Dropdown = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <Wrapper ref={ref} className={props.className} width={props.width}>
      {props.items.map((item, index) => {
        if (IsDropdownLinkItem(item)) {
          return <DropdownLinkComponent index={index} item={item} />
        }
        if (IsDropdownToggleItem(item)) {
          return <DropdownToggleComponent index={index} item={item} />
        }
        return null
      })}
    </Wrapper>
  )
})

type DropdownLinkComponentProps = {
  index: number
  item: DropdownLinkItem
}

function DropdownLinkComponent(props: DropdownLinkComponentProps) {
  const { index, item } = props
  return (
    <ItemWrapper key={index}>
      {item.disabled ? (
        <Unlink>
          <TextWrapper>
            <MainTxtWrapper>
              <Txt color={theme.color.gray[1]}>{item.text}</Txt>
              {item.secondaryText && <SecondaryTxt>{item.secondaryText}</SecondaryTxt>}
              {item.notification && <NotificationDot />}
            </MainTxtWrapper>
            {item.detailDescription && <DetailDescription>{item.detailDescription}</DetailDescription>}
          </TextWrapper>
        </Unlink>
      ) : (
        <Link
          key={index}
          href={item.href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={item.onClick}
          comlinkPushParams={{ action: item.comlinkOnClickAction }}
        >
          <TextWrapper>
            <MainTxtWrapper>
              <Txt color={item.color}>{item.text}</Txt>
              {item.secondaryText && <SecondaryTxt>{item.secondaryText}</SecondaryTxt>}
              {item.notification && <NotificationDot />}
            </MainTxtWrapper>
            {item.detailDescription && <DetailDescription>{item.detailDescription}</DetailDescription>}
          </TextWrapper>
        </Link>
      )}
    </ItemWrapper>
  )
}

type DropdownToggleComponentProps = {
  index: number
  item: DropdownToggleItem
}

function DropdownToggleComponent(props: DropdownToggleComponentProps) {
  const { index, item } = props
  return (
    <ItemWrapper key={index}>
      <ToggleItemWrapper>
        <TextWrapper>
          <MainTxtWrapper>
            <Txt color={item.color}>{item.text}</Txt>
          </MainTxtWrapper>
          {item.detailDescription && <DetailDescription>{item.detailDescription}</DetailDescription>}
          {item.notification && <NotificationDot />}
        </TextWrapper>
        <Toggle
          checked={item.value}
          onChange={() => {
            // @ts-ignore
            // TODO: fix rogue
            item.onClick()
          }}
        />
      </ToggleItemWrapper>
    </ItemWrapper>
  )
}

const Wrapper = styled.div<{ width: number }>`
  width: ${(props) => props.width}px;
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.gray[3]};
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  z-index: 100;
`

const ItemWrapper = styled.div`
  &:not(:first-child) {
    border-top: 1px solid ${theme.color.gray[4]};
  }
`

const ToggleItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
`

const Unlink = styled.div`
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
`

const Link = styled(A)`
  height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: ${theme.color.gray[6]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-flow: column;
`

const MainTxtWrapper = styled.div`
  display: flex;
`

const SecondaryTxt = styled(Txt)`
  margin-left: 12px;
  font-size: 12px;
  line-height: 18px;
  color: ${theme.color.gray[1]};
`
const DetailDescription = styled(Txt)`
  font-size: 12px;
  line-height: 18px;
  color: ${theme.color.gray[1]};
`

const NotificationDot = styled(_NotificationDot)`
  position: absolute;
  top: ${25 - 3.5}px;
  right: 15px;
`
