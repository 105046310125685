import { Comlink } from '@blue-agency/comlink'
import ky from 'ky'
import { ConnectionPublisher } from 'sora-js-sdk'

export type RTCStatsLoggingParams = {
  intervalMs: number
  logEndpoint: string
}

export type StopRTCStatsLogging = () => void

export const startRTCStatsLogging = async (
  publisher: ConnectionPublisher,
  config: RTCStatsLoggingParams
): Promise<StopRTCStatsLogging> => {
  const { uuid } = await Comlink.getClientIdentity()

  let posting = false
  const id = setInterval(async () => {
    if (posting) return
    posting = true

    if (!publisher.clientId) {
      posting = false
      return
    }

    const stats = await publisher.pc?.getStats()
    if (stats === undefined) {
      posting = false
      return
    }

    const data = {
      uuid: uuid,
      data: {
        stats: Array.from(stats.values()),
        channelId: publisher.channelId,
        connectionId: publisher.connectionId,
        timestamp: new Date().getTime(),
      },
    }

    try {
      await ky.post(config.logEndpoint, {
        mode: 'cors',
        credentials: 'omit',
        cache: 'no-cache',
        json: data,
        timeout: config.intervalMs,
      })
    } catch {
      // WebRTC Statsは失敗してもログが送信できないだけなので無視する
    }

    posting = false
  }, config.intervalMs)

  return () => {
    clearInterval(id)
  }
}
