import { useEffect } from 'react'
import { Comlink } from '@blue-agency/comlink'
import { comlinkPush, useComlinkPushWithStaffGuid } from '@blue-agency/im-shared-front'
import { initTracking as initRogueTracking } from '@blue-agency/rogue'
import { finder } from '@medv/finder'
import { History } from 'history'
import { isMac, isWindows } from '../services/browserCheckService/browser'
import { useUA } from './useUA'

export const useAuthorizedTracking = (history: History<unknown>) => {
  const comlinkPush = useComlinkPushWithStaffGuid()
  useEffect(() => {
    const unlisten = history.listen(() => {
      comlinkPush('change_page')
    })

    return () => {
      unlisten()
    }
  }, [comlinkPush, history])
}

export const useUnauthorizedTracking = (history: History<unknown>) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      comlinkPush('change_page')
    })

    return () => {
      unlisten()
    }
  }, [history])
}

export const useTracking = () => {
  useEffect(() => {
    initRogueTracking({
      click: (event, comlinkPushParams) => {
        const element = event.currentTarget
        const metadataBase = {
          tagName: element.tagName,
          label: element.innerText,
          className: element.className,
          selector: finder(element),
        }
        const metadata =
          element instanceof HTMLAnchorElement ? { ...metadataBase, href: element.href } : { ...metadataBase }

        Comlink.push({
          ...comlinkPushParams,
          type: 'manual_activity',
          group: 'skywalker-front',
          action: comlinkPushParams.action || `click_${element.innerText}`,
          metadata: comlinkPushParams.metadata ? { ...metadata, ...comlinkPushParams.metadata } : metadata,
        })
      },
    })

    comlinkPush('open_page')
    comlinkPush({
      action: 'screen_size',
      metadata: {
        width: window.screen.width.toString(),
        height: window.screen.height.toString(),
      },
    })
  }, [])

  useTrackSpScreenOrientation()
}

const angleOrientation: Record<string, string> = {
  '-90': 'landscape',
  0: 'portrait',
  90: 'landscape',
  180: 'portrait',
  270: 'landscape',
}

const useTrackSpScreenOrientation = () => {
  const ua = useUA()

  useEffect(() => {
    if (isWindows(ua) || isMac(ua)) {
      return
    }
    window.addEventListener('orientationchange', pushOrientation)

    pushOrientation()

    return () => {
      window.removeEventListener('orientationchange', pushOrientation)
    }
  }, [ua])
}

function pushOrientation() {
  let angle = window.screen && window.screen.orientation && window.screen.orientation.angle

  // Safari 用
  if (angle === undefined) {
    // eslint-disable-next-line deprecation/deprecation
    angle = Number(window.orientation)
  }

  if (angle !== undefined) {
    comlinkPush({
      action: 'screen_orientation',
      metadata: {
        orientation: angleOrientation[angle.toString()] ?? '',
      },
    })
  }
}
