import { useMemo, useEffect, useState, useCallback, useRef } from 'react'
import { useOnClickOutsideAll } from '@/shared/hooks/useOutsideClick'

export type DropdownBasePosition = {
  top: number
  right: number
  bottom: number
  left: number
}

export type DropdownItemType = 'DropdownLinkItem' | 'DropdownToggleItem'

export type DropdownItem = DropdownLinkItem | DropdownToggleItem

export type DropdownLinkItem = {
  type: DropdownItemType
  text: string
  secondaryText?: string
  detailDescription?: string
  href?: string
  disabled?: boolean
  color?: string
  onClick?: () => void
  comlinkOnClickAction: string
  notification?: boolean
}

export type DropdownToggleItem = {
  type: DropdownItemType
  text: string
  detailDescription?: string
  color?: string
  value: boolean
  onClick?: () => void
  notification?: boolean
}

export function IsDropdownLinkItem(item: DropdownItem): item is DropdownLinkItem {
  return item.type === 'DropdownLinkItem'
}

export function IsDropdownToggleItem(item: DropdownItem): item is DropdownToggleItem {
  return item.type === 'DropdownToggleItem'
}

// ドロップダウンメニューの表示に関わる処理をまとめたhooks
export function useDropdown(items: DropdownItem[]) {
  const [isDropdownShown, setIsDropdownShown] = useState(false)
  const toggleDropdownShown = useCallback(() => setIsDropdownShown((prev) => !prev), [])
  const [basePosition, setBasePosition] = useState<DropdownBasePosition>()

  // Dropdown を表示するために基準の位置としてオープナーの ref を取る
  const dropdownOpenerRef = useRef<HTMLDivElement | null>(null)

  // Dropdown 以外をクリックしたときに Dropdown を閉じる
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  useOnClickOutsideAll([dropdownRef, dropdownOpenerRef], () => setIsDropdownShown(false))

  // Dropdown の項目がクリックされたときに Dropdown を閉じるようにする
  const dropdownItems: DropdownItem[] = useMemo(
    () =>
      items.map((item) => {
        return {
          ...item,
          onClick: () => {
            setIsDropdownShown(false)
            item.onClick?.()
          },
        }
      }),
    [items]
  )

  useEffect(() => {
    if (!dropdownOpenerRef.current) return

    const openerRect = dropdownOpenerRef.current.getBoundingClientRect()

    if (isDropdownShown && openerRect) {
      setBasePosition({
        top: openerRect.top,
        right: openerRect.right,
        bottom: openerRect.bottom,
        left: openerRect.left,
      })
    } else {
      setBasePosition(undefined)
    }
  }, [isDropdownShown])

  return {
    isDropdownShown,
    toggleDropdownShown,
    dropdownItems,
    basePosition,
    dropdownOpenerRef,
    dropdownRef,
  }
}
