import { useEffect, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useSelector } from 'react-redux'
import { AudioInputContainer } from '@/lib/meetingcomponent/DevicesContainer/AudioInputContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'
import { participantsSelector, SharedState } from '../redux'
import { SoraClientId } from '../types'

const INTERVAL = 200 // 5fps
const FFT_SIZE = 512

export type ParticipantAudioVolumeMap = Record<SoraClientId, number>

const useParticipantAudioVolumes = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const participants = useSelector(participantsSelector)
  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)
  const remoteTiles = useSelector((state: SharedState) => state.shared.remoteTiles)

  const [remoteAudioVolumes, setRemoteAudioVolumes] = useState<ParticipantAudioVolumeMap>({})
  const [localAudioVolume, setLocalAudioVolume] = useState<number | undefined>()

  // Remote
  useEffect(() => {
    const targets = participants
      .map((participant) => {
        const tileId = remoteTiles[participant.soraClientId]
        if (!tileId) return undefined
        return { tileId, soraClientId: participant.soraClientId }
      })
      .filter((e): e is { tileId: number; soraClientId: string } => !!e)

    const analyzerIds: string[] = []
    for (const target of targets) {
      const analyserId = meetingManager.startTileAudioWaveAnalyser(
        target.tileId,
        (data) => {
          let vol = Math.max(...data)
          setRemoteAudioVolumes((prev) => ({
            ...prev,
            [target.soraClientId]: vol,
          }))
        },
        { interval: INTERVAL, fftSize: FFT_SIZE }
      )
      if (analyserId !== null) {
        analyzerIds.push(analyserId)
      }
    }

    return () => {
      for (const analyzerId of analyzerIds) {
        meetingManager.stopTileAudioWaveAnalyser(analyzerId)
      }
    }
  }, [meetingManager, participants, remoteTiles])

  // Local
  const { audioInputs, selectedAudioInputDevice, audioInputDeviceUpdateTime } = AudioInputContainer.useContainer()

  useEffect(() => {
    if (!ownSoraClientId) return
    meetingManager.startAudioInputAudioWaveAnalyser(
      (data) => {
        let vol = Math.max(...data)
        setLocalAudioVolume(vol)
      },
      { interval: INTERVAL, fftSize: FFT_SIZE }
    )
    return () => {
      meetingManager.stopAudioInputAudioWaveAnalyser()
    }
  }, [meetingManager, ownSoraClientId, selectedAudioInputDevice, audioInputs, audioInputDeviceUpdateTime])
  // 選択しているデバイスが変わったとき、あるいは新しいデバイスが接続されてそれがデフォルトになったとき
  // ノイズ抑制オンオフだけが変わったときはデバイスIDは同じなのでaudioInputDeviceUpdateTimeで見る
  // enabledNoiseSuppressionだとデバイスの準備が終わる前に呼ばれる場合がある

  return {
    remoteAudioVolumes,
    localAudioVolume,
  }
}

export const ParticipantAudioVolumesContainer = createContainer(useParticipantAudioVolumes)
