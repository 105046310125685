import { Txt, theme } from '@blue-agency/rogue'
import styled from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'

type Props = PropsWithClassName & {
  interviewName: string
  leftColumn: React.ReactNode
  mainColumn: React.ReactNode
  rightColumn: React.ReactNode
}

export const PageLayout = (props: Props): React.ReactElement => {
  return (
    <Wrapper className={props.className}>
      <Header>
        <Txt size="l">{props.interviewName}</Txt>
      </Header>
      <Body>
        <LeftColumn>{props.leftColumn}</LeftColumn>
        <MainColumn>{props.mainColumn}</MainColumn>
        <RightColumn>{props.rightColumn}</RightColumn>
      </Body>
    </Wrapper>
  )
}

const Wrapper = styled.div<PropsWithClassName>`
  display: flex;
  flex-direction: column;
  padding: 0 20px 16px;
  background-color: ${theme.color.gray[5]};
  height: 100vh;
`

const Header = styled.div`
  padding: 16px 0;
  height: 56px;
`

const Body = styled.div`
  display: flex;
  height: calc(100% - 56px);
`

const Column = styled.div`
  flex: 0 0 auto;
  box-sizing: border-box;
`

const LeftColumn = styled(Column)`
  width: calc(35% - 8px);
  margin-right: 12px;
`

const MainColumn = styled(Column)`
  width: calc(40% - 8px);
  margin-right: 12px;
`

const RightColumn = styled(Column)`
  width: calc(25% - 8px);
`
