import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useIsIPad } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { WindowSizeContainer } from '@/shared/containers/WindowSizeContainer'
import { useDropdown } from '@/shared/hooks/useDropdown'
import type { DropdownItem } from '@/shared/hooks/useDropdown'
import {
  BizNewPcControlsReloadButtonMaxHeight,
  BizNewPcControlsShareScreenMaxHeight,
  BizNewPcControlsChatButtonMaxHeight,
  BizNewPcControlsVideoButtonMaxHeight,
} from '@/shared/services/interviewService/components/MinutesViewPcControls'
import { ChatContainer } from '@/shared/services/interviewService/containers/ChatContainer'
import { InterviewGuideContainer } from '@/shared/services/interviewService/containers/InterviewGuideContainer'
import { MinutesViewContainer } from '@/shared/services/interviewService/containers/MinutesViewContainer'
import { ScreenShareUiContainer } from '@/shared/services/interviewService/containers/ScreenShareUiContainer'
import { useMuteCamera } from '@/shared/services/interviewService/hooksForApp/useMuteCamera'
import { useUnmuteCamera } from '@/shared/services/interviewService/hooksForApp/useUnmuteCamera'
import { InterviewAutoQuestionContainer } from '../../containers/InterviewAutoQuestionContainer'
import { InterviewContainer } from '../../containers/InterviewContainer'
import { useTroubleShootingNotification } from '../../hooksForApp/useTroubleShootingNotification'
import { useUnstableConnectionWarning } from '../../hooksForApp/useUnstableConnectionWarning'
import { isChatReadySelector, isInterviewReadySelector, SharedState } from '../../redux'
import { useBackgroundSettingModal } from './useBackgroundSettingModal'
import { useChangeDeviceModal } from './useChangeDeviceModal'
import { useQualityModeModal } from './useQualityModeModal'
import { useReloadModal } from './useReloadModal'
import { useUnstableConnectionModal } from './useUnstableConnectionModal'
import { useUnstableConnectionWarningBalloon } from './useUnstableConnectionWarningBalloon'

export function useMenuDropdown() {
  const { appType, interviewQuality, changeInterviewQuality } = InterviewContainer.useContainer()
  const { hasInterviewGuide, toggleInterviewGuideAreaOpen } = InterviewGuideContainer.useContainer()
  const { isInterviewAutoQuestionAreaOpen, toggleInterviewAutoQuestionAreaOpen } =
    InterviewAutoQuestionContainer.useContainer()
  const { isMinutesView, toggleMinutesView } = MinutesViewContainer.useContainer()

  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)
  const windowSize = WindowSizeContainer.useContainer()

  const { responsive } = useContext(ThemeContext)
  const { isIPad } = useIsIPad()
  const isEnabledDeviceChange = useMemo(() => {
    return responsive.pc
  }, [responsive.pc])

  const isEnabledBackgroundSetting = useMemo(() => {
    return !isUnavailableVideoInput && responsive.pc && !isIPad
  }, [isUnavailableVideoInput, responsive.pc, isIPad])

  const isEnabledChangeQuality = !!changeInterviewQuality

  const { warning, close } = useUnstableConnectionWarning(interviewQuality)
  const {
    showing: showingTroubleShootingNotification,
    show: showTroubleShootingNotification,
    hide: hideTroubleShootingNotification,
  } = useTroubleShootingNotification()
  useEffect(() => {
    if (warning) {
      showTroubleShootingNotification()
    }
  }, [showTroubleShootingNotification, warning])

  /**
   * 再読込モーダル
   */
  const { openReloadModal, renderReloadModal } = useReloadModal()

  /**
   * 接続が不安定なときは〜 モーダル
   */
  const { openUnstableConnectionModal, renderUnstableConnectionModal } = useUnstableConnectionModal(
    responsive.pc && !isIPad
  )
  const onClickUnstableConnectionItem = useCallback(() => {
    hideTroubleShootingNotification()
    openUnstableConnectionModal()
  }, [hideTroubleShootingNotification, openUnstableConnectionModal])

  /**
   * デバイス選択モーダル
   */
  const { openChangeDeviceModal, renderChangeDeviceModal } = useChangeDeviceModal()
  const onClickDeviceSelect = useCallback(() => {
    openChangeDeviceModal()
  }, [openChangeDeviceModal])

  const helpHref = appType === 'biz' ? BIZ_EXTERNAL_URLS.help.interview : MY_EXTERNAL_URLS.help.interview

  /**
   * 背景設定モーダル
   */
  const { openBackgroundSettingModal, renderBackgroundSettingModal } = useBackgroundSettingModal()
  const onClickBackgroundSettingModal = useCallback(() => {
    openBackgroundSettingModal()
  }, [openBackgroundSettingModal])

  /**
   * 面接ガイド開閉
   */
  const onClickToggleInterviewGuide = useCallback(() => {
    toggleInterviewGuideAreaOpen()
  }, [toggleInterviewGuideAreaOpen])

  /**
   * 軽量モード設定モーダル
   */
  const { openQualityModeModal, renderQualityModeModal } = useQualityModeModal()

  /**
   * カメラ設定（新モードで利用）
   */
  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const cameraDisabled = useSelector(
    (state: SharedState) => state.shared.isUnavailableVideoInput || state.shared.userSignalingStatus !== 'Completed'
  )
  const muteCamera = useMuteCamera()
  const unmuteCamera = useUnmuteCamera()

  /**
   * チャット（新モードで利用）
   */
  const isChatReady = useSelector(isChatReadySelector)
  const { isChatAreaOpen, toggleChatAreaOpen } = ChatContainer.useContainer()

  /**
   * 画面共有（新モードで利用）
   */
  const showScreenShareButton = typeof navigator.mediaDevices.getDisplayMedia !== 'undefined'
  const { openStartScreenShareModal, openFinishScreenShareModal } = ScreenShareUiContainer.useContainer()
  const isRunningOwnScreenShare = useSelector((state: SharedState) => state.shared.isRunningOwnScreenShare)
  const isInterviewReady = useSelector(isInterviewReadySelector)

  const screenShareDisabled = !isInterviewReady

  const items = useMemo<DropdownItem[]>(() => {
    const items: DropdownItem[] = [
      {
        type: 'DropdownLinkItem',
        text: 'トラブルシューティング',
        onClick: onClickUnstableConnectionItem,
        comlinkOnClickAction: 'click_unstable_connection_item',
        notification: showingTroubleShootingNotification,
      },
      { type: 'DropdownLinkItem', text: 'ヘルプ', href: helpHref, comlinkOnClickAction: 'click_help_item' },
    ]
    if (
      isEnabledDeviceChange &&
      !isIPad &&
      appType === 'biz' &&
      isMinutesView &&
      windowSize.height <= BizNewPcControlsReloadButtonMaxHeight
    ) {
      if (windowSize.height <= BizNewPcControlsVideoButtonMaxHeight) {
        items.push({
          type: 'DropdownLinkItem',
          text: 'カメラ',
          disabled: cameraDisabled,
          secondaryText: isOwnCameraMuted ? '解除' : 'オフ',
          onClick: isOwnCameraMuted ? unmuteCamera : muteCamera,
          comlinkOnClickAction: 'click_camera_setting_select_item_in_dropdown',
        })
      }
      if (windowSize.height <= BizNewPcControlsChatButtonMaxHeight) {
        items.push({
          type: 'DropdownLinkItem',
          text: 'チャット',
          disabled: !isChatReady,
          secondaryText: isChatAreaOpen ? 'オフ' : 'オン',
          onClick: toggleChatAreaOpen,
          comlinkOnClickAction: 'click_chat_setting_select_item_in_dropdown',
        })
      }
      if (windowSize.height <= BizNewPcControlsShareScreenMaxHeight) {
        items.push({
          type: 'DropdownLinkItem',
          text: '画面共有',
          disabled: screenShareDisabled || !showScreenShareButton,
          onClick: isRunningOwnScreenShare ? openFinishScreenShareModal : openStartScreenShareModal,
          comlinkOnClickAction: 'click_screen_share_setting_select_item_in_dropdown',
        })
      }
      items.push({
        type: 'DropdownLinkItem',
        text: '再読込',
        onClick: openReloadModal,
        comlinkOnClickAction: 'click_reload_select_item_in_dropdown',
      })
    }
    if (!responsive.pc && hasInterviewGuide) {
      items.unshift({
        type: 'DropdownLinkItem',
        text: '面接ガイド',
        onClick: onClickToggleInterviewGuide,
        comlinkOnClickAction: 'click_toggle_interview_guide_area_open_button_in_dropdown',
      })
    }
    if (isEnabledDeviceChange && !isIPad) {
      items.push({
        type: 'DropdownLinkItem',
        text: '音声 / 映像設定',
        onClick: onClickDeviceSelect,
        comlinkOnClickAction: 'click_device_select_item',
      })
    }
    if (isEnabledDeviceChange && isIPad) {
      items.push({
        type: 'DropdownLinkItem',
        text: '映像設定',
        onClick: onClickDeviceSelect,
        comlinkOnClickAction: 'click_camera_device_select_item',
      })
    }
    if (isEnabledBackgroundSetting) {
      items.push({
        type: 'DropdownLinkItem',
        text: '背景設定',
        onClick: onClickBackgroundSettingModal,
        comlinkOnClickAction: 'click_background_setting_item',
      })
    }
    if (isEnabledChangeQuality) {
      items.push({
        type: 'DropdownLinkItem',
        text: '軽量モード設定',
        secondaryText: interviewQuality.mode === 'low' ? '軽量モード利用中' : '通常モード利用中',
        onClick: openQualityModeModal,
        comlinkOnClickAction: 'click_quality_mode_select_item',
      })
    }
    if (isEnabledDeviceChange && !isIPad && appType === 'biz' && !isMinutesView) {
      items.push({
        type: 'DropdownLinkItem',
        text: 'im Assistant （β版）',
        secondaryText: isInterviewAutoQuestionAreaOpen ? 'ON' : 'OFF',
        onClick: toggleInterviewAutoQuestionAreaOpen,
        comlinkOnClickAction: 'click_im_assistant_mode_select_item',
      })
    }
    if (isEnabledDeviceChange && appType === 'biz' && isMinutesView) {
      items.push({
        type: 'DropdownToggleItem',
        text: '議事録ビュー',
        value: isMinutesView,
        onClick: toggleMinutesView,
        comlinkOnClickAction: 'click_im_minute_view_select_item',
      })
    }

    return items
  }, [
    windowSize,
    isOwnCameraMuted,
    muteCamera,
    unmuteCamera,
    openReloadModal,
    isChatReady,
    isChatAreaOpen,
    toggleChatAreaOpen,
    cameraDisabled,
    isRunningOwnScreenShare,
    openFinishScreenShareModal,
    openStartScreenShareModal,
    screenShareDisabled,
    showScreenShareButton,
    onClickUnstableConnectionItem,
    showingTroubleShootingNotification,
    helpHref,
    responsive.pc,
    hasInterviewGuide,
    isEnabledDeviceChange,
    isIPad,
    isEnabledBackgroundSetting,
    isEnabledChangeQuality,
    isInterviewAutoQuestionAreaOpen,
    isMinutesView,
    toggleInterviewAutoQuestionAreaOpen,
    toggleMinutesView,
    onClickToggleInterviewGuide,
    onClickDeviceSelect,
    onClickBackgroundSettingModal,
    interviewQuality.mode,
    openQualityModeModal,
    appType,
  ])

  const dropdown = useDropdown(items)

  const { renderUnstableConnectionWarningBalloon } = useUnstableConnectionWarningBalloon(
    dropdown.dropdownOpenerRef,
    warning,
    close
  )

  return {
    ...dropdown,
    openReloadModal,
    showingTroubleShootingNotification,

    renderUnstableConnectionModal,
    renderReloadModal,
    renderChangeDeviceModal,
    renderBackgroundSettingModal,
    renderQualityModeModal,
    renderUnstableConnectionWarningBalloon,
  }
}
