import { useCallback } from 'react'
import { useRequestCreateSession } from '@/my/services/bffService'

export type SessionToken = {
  user: string
  screenShare: string
  screenShareRecv: string
}

export const useSessionToken = () => {
  const { requestCreateSession } = useRequestCreateSession()

  const getSessionToken = useCallback(
    async (storageKey: string) => {
      const sessionTokenInLocalStorage = localStorage.getItem(storageKey)
      if (sessionTokenInLocalStorage) return sessionTokenInLocalStorage

      const res = await requestCreateSession()

      const sessionToken = res.getSessionToken()

      localStorage.setItem(storageKey, sessionToken)
      return sessionToken
    },
    [requestCreateSession]
  )

  return { getSessionToken }
}
