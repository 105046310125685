import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { InterviewContainer } from '../containers/InterviewContainer'

export function useFinishMessageHandler() {
  const dispatch = useDispatch()

  const { ws, onReceiveFinishMessage } = InterviewContainer.useContainer()
  const { addMessageListener, removeMessageListener } = ws

  useEffect(() => {
    const finishHandler = () => {
      onReceiveFinishMessage()
    }

    addMessageListener('finish', finishHandler)
    return () => {
      removeMessageListener('finish', finishHandler)
    }
  }, [addMessageListener, dispatch, onReceiveFinishMessage, removeMessageListener])
}
