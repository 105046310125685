import React from 'react'
import { Modal, Txt, WeakColorSeparator, theme, NewTabLink } from '@blue-agency/rogue'
import { PrimaryButton } from '@blue-agency/rogue/im'
import reactStringReplace from 'react-string-replace'
import styled from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'
import { InterviewContainer } from '../../../containers/InterviewContainer'
import image1 from './image1.png'
import image2 from './image2.png'
import image3 from './image3.png'
import image4 from './image4.png'
import image5 from './image5.png'

const text = {
  title: '画面共有',
  main: '画面共有を開始します',
  card: {
    first: {
      main: {
        text: `資料は事前に開いておき
アプリケーションウィンドウ
で共有してください`,
        boldKeyword: 'アプリケーションウィンドウ',
      },
      sub: {
        text: `PowerPointは閲覧表示モードをご利用ください。
スライドショーモードでは正常に動作しない場合があります。
`,
        linkKeyword: /(閲覧表示モード|スライドショーモード)/,
      },
    },
    second: {
      main: {
        text: `資料の画面を
最小化 or 最大化していると
共有できません`,
        boldKeyword: '最小化 or 最大化',
      },
      sub: {
        text: `「最大化時に共有できない事象」は
Macのみ発生します。`,
      },
    },
    third: {
      main: {
        text: `共有中の画面が停止したら
画面共有を終了し
再度開始してください`,
        boldKeyword: `画面共有を終了し
再度開始してください`,
      },
      sub: {
        text: '共有中にPowerPointの表示モードを変更すると、停止する場合があります。',
      },
    },
  },
}

type Props = {
  active: boolean
  onClose: () => void
  onStart: () => void
}

export const StartScreenShareModal: React.VFC<Props> = ({ active, onClose, onStart }) => {
  const { appType } = InterviewContainer.useContainer()
  const screenShareHelpLink = appType === 'biz' ? BIZ_EXTERNAL_URLS.help.screenShare : MY_EXTERNAL_URLS.help.screenShare

  return (
    <Modal size="l" active={active} title={text.title} onClose={onClose}>
      <Wrapper>
        <Upper>
          <Txt bold size="l">
            {text.main}
          </Txt>
          <CardGroup>
            <Card>
              <CardUpper>
                <Image1 src={image1} />
                <Image2 src={image2} />
                <Image3 src={image3} />
              </CardUpper>
              <CardBottom>
                <Txt>
                  {reactStringReplace(text.card.first.main.text, text.card.first.main.boldKeyword, (match, index) => (
                    <BoldSpan key={index}>{match}</BoldSpan>
                  ))}
                </Txt>
                <SubTxt>
                  {reactStringReplace(text.card.first.sub.text, text.card.first.sub.linkKeyword, (match, index) => (
                    <NewTabLink key={index} href={screenShareHelpLink} action="open_screenShare_help_page">
                      {match}
                    </NewTabLink>
                  ))}
                </SubTxt>
              </CardBottom>
            </Card>
            <Card>
              <CardUpper>
                <Image4 src={image4} />
              </CardUpper>
              <CardBottom>
                <Txt>
                  {reactStringReplace(text.card.second.main.text, text.card.second.main.boldKeyword, (match, index) => (
                    <BoldSpan key={index}>{match}</BoldSpan>
                  ))}
                </Txt>
                <SubTxt>{text.card.second.sub.text}</SubTxt>
              </CardBottom>
            </Card>
            <Card>
              <CardUpper>
                <Image5 src={image5} />
              </CardUpper>
              <CardBottom>
                <Txt>
                  {reactStringReplace(text.card.third.main.text, text.card.third.main.boldKeyword, (match, index) => (
                    <BoldSpan key={index}>{match}</BoldSpan>
                  ))}
                </Txt>
                <SubTxt>{text.card.third.sub.text}</SubTxt>
              </CardBottom>
            </Card>
          </CardGroup>
        </Upper>
        <WeakColorSeparator />
        <Bottom>
          <ButtonWrapper>
            <PrimaryButton
              widthSize="L2"
              onClick={onStart}
              comlinkPushParams={{ action: 'click_start_screen_share_button' }}
            >
              画面共有を開始
            </PrimaryButton>
          </ButtonWrapper>
        </Bottom>
      </Wrapper>
    </Modal>
  )
}

const Wrapper = styled.div``
const Upper = styled.div`
  padding: 20px 12px;
  background: ${theme.color.gray[5]};
  text-align: center;
`
const CardGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`
const Card = styled.div`
  width: 210px;
  background-color: ${theme.color.white[1]};
  border: 1px solid ${theme.color.navy[3]};
  border-radius: 4px;
`
const CardUpper = styled.div`
  position: relative;
  height: 145px;
`
const Image1 = styled.img`
  position: absolute;
  top: 8px;
  left: 11px;
  width: 70px;
  height: 70px;
`
const Image2 = styled.img`
  position: absolute;
  top: 20px;
  left: 109px;
  width: 70px;
  height: 70px;
`
const Image3 = styled.img`
  position: absolute;
  top: 68px;
  left: 51px;
  width: 70px;
  height: 70px;
`
const Image4 = styled.img`
  position: absolute;
  top: 14px;
  left: 8px;
  width: 182px;
`
const Image5 = styled.img`
  position: absolute;
  top: 14px;
  left: 16px;
  width: 164px;
`
const CardBottom = styled.div`
  padding: 0 8px 8px;
  text-align: left;
`
const SubTxt = styled(Txt).attrs({ size: 's', color: theme.color.gray[1] })`
  margin-top: 5px;
`
const BoldSpan = styled.span`
  font-weight: bold;
`
const Bottom = styled.div`
  padding: 20px 16px;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
