import { theme } from '@blue-agency/rogue'
import styled from 'styled-components'

export const Circle = styled.div<{ width: number; height: number }>`
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.width}px`};
  background-color: ${theme.color.green[4]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
