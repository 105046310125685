import React from 'react'
import { Icon, theme } from '@blue-agency/rogue'
import { Tooltip } from '@blue-agency/rogue/im'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { FinishButton as BizFinishButton } from '@/biz/components/FinishButton'
import { useFinishDropdown } from '@/biz/pages/InterviewPage/Interview/InRoom/hooks/useFinishDropdown'
import type { DropdownBasePosition } from '@/shared/hooks/useDropdown'
import { MinutesViewChangeInfoBalloon } from '@/shared/services/interviewService/components/MinutesViewChangeInfoBalloon'
import { useMenuDropdown } from '@/shared/services/interviewService/hooksForUi/useMenuDropdown'
import { buttonResetStyle } from '@/shared/styles/buttonResetStyle'
import { Dropdown as _Dropdown } from './Dropdown'
import { Timer } from './Timer'
import { CameraMuteButton } from './controlButtons/CameraMuteButton'
import { ChatButton } from './controlButtons/ChatButton'
import { MicMuteButton } from './controlButtons/MicMuteButton'
import { ReloadButton } from './controlButtons/ReloadButton'
import { ScreenShareButton } from './controlButtons/ScreenShareButton'

export const BizNewPcControlsReloadButtonMaxHeight = 500
export const BizNewPcControlsShareScreenMaxHeight = 415
export const BizNewPcControlsChatButtonMaxHeight = 350
export const BizNewPcControlsVideoButtonMaxHeight = 300

export const MinutesViewPcControls: React.VFC = () => {
  const {
    dropdownItems: menuDropdownItems,
    dropdownOpenerRef: menuDropdownOpenerRef,
    dropdownRef: menuDropdownRef,
    isDropdownShown: isMenuDropdownShown,
    basePosition: menuDropdownBasePosition,
    toggleDropdownShown: toggleMenuDropdownShown,
    renderReloadModal,
    renderUnstableConnectionModal,
    renderChangeDeviceModal,
    renderBackgroundSettingModal,
    renderQualityModeModal,
  } = useMenuDropdown()

  // 画面共有(getDisplayMedia)できない環境では画面共有ボタンを出さない
  const showScreenShareButton = typeof navigator.mediaDevices.getDisplayMedia !== 'undefined'

  const menuDropdownWidth = 250

  return (
    <Wrapper>
      <TopSectionWrapper>
        <Timer />
        <TopButtonsWrapper>
          <MicMuteButton />
        </TopButtonsWrapper>
        <VideoButtonMediaWrapper>
          <CameraMuteButton />
        </VideoButtonMediaWrapper>
        <ChatButtonMediaWrapper>
          <ChatButton />
        </ChatButtonMediaWrapper>
        <ScreenShareButtonMediaWrapper>{showScreenShareButton && <ScreenShareButton />}</ScreenShareButtonMediaWrapper>
        <ReloadButtonMediaWrapper>
          <ReloadButton />
        </ReloadButtonMediaWrapper>
        <TopButtonsWrapper>
          <MoreButtonsWrapper>
            <MinutesViewChangeInfoBalloon basePosition={{ top: 0, left: 70 }} />{' '}
            <DropdownOpener ref={menuDropdownOpenerRef}>
              <MoreButton onClick={toggleMenuDropdownShown} data-tip data-for="more">
                <MoreIcon />
              </MoreButton>
              {!isMenuDropdownShown && (
                <Tooltip id="more" arrowPosition="bottomLeft">
                  その他のメニュー
                </Tooltip>
              )}
            </DropdownOpener>
          </MoreButtonsWrapper>
          {isMenuDropdownShown &&
            menuDropdownBasePosition &&
            ReactDOM.createPortal(
              <MenuDropdown
                ref={menuDropdownRef}
                items={menuDropdownItems}
                basePosition={menuDropdownBasePosition}
                width={menuDropdownWidth}
              />,
              document.getElementById('root')!
            )}
        </TopButtonsWrapper>
      </TopSectionWrapper>
      <BottomSectionWrapper>
        <FinishButtonAndDropdown />
      </BottomSectionWrapper>
      {renderReloadModal()}
      {renderChangeDeviceModal()}
      {renderBackgroundSettingModal()}
      {renderQualityModeModal()}
      {renderUnstableConnectionModal()}
    </Wrapper>
  )
}

function FinishButtonAndDropdown() {
  const {
    dropdownItems: finishDropdownItems,
    dropdownOpenerRef: finishDropdownOpenerRef,
    dropdownRef: finishDropdownRef,
    isDropdownShown: isFinishDropdownShown,
    basePosition: finishDropdownBasePosition,
    toggleDropdownShown: toggleFinishDropdownShown,
  } = useFinishDropdown()

  const finishDropdownWidth = 234

  return (
    <>
      <DropdownOpener ref={finishDropdownOpenerRef}>
        <BizFinishButton toggle={toggleFinishDropdownShown} isDropdownShown={isFinishDropdownShown} />
      </DropdownOpener>
      {isFinishDropdownShown &&
        finishDropdownBasePosition &&
        ReactDOM.createPortal(
          <FinishDropdown
            ref={finishDropdownRef}
            items={finishDropdownItems}
            basePosition={finishDropdownBasePosition}
            width={finishDropdownWidth}
          />,
          document.getElementById('root')!
        )}
    </>
  )
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px;
  align-items: center;
  border-right: 1px solid ${theme.color.gray[3]};
`

const TopSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const TopButtonsWrapper = styled.div`
  width: 90px;
  height: 48px;
`

const ReloadButtonMediaWrapper = styled(TopButtonsWrapper)`
  @media (max-height: ${BizNewPcControlsReloadButtonMaxHeight}px) {
    display: none;
  }
`

const ScreenShareButtonMediaWrapper = styled(TopButtonsWrapper)`
  @media (max-height: ${BizNewPcControlsShareScreenMaxHeight}px) {
    display: none;
  }
`

const ChatButtonMediaWrapper = styled(TopButtonsWrapper)`
  @media (max-height: ${BizNewPcControlsChatButtonMaxHeight}px) {
    display: none;
  }
`

const VideoButtonMediaWrapper = styled(TopButtonsWrapper)`
  @media (max-height: ${BizNewPcControlsVideoButtonMaxHeight}px) {
    display: none;
  }
`

const BottomSectionWrapper = styled.div``

const moreButtonsWrapper = css`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 16px;
  }

  & > * {
    width: 90px;
    height: 48px;
  }
`

const MoreButtonsWrapper = styled.div`
  position: absolute;
  ${moreButtonsWrapper}
`

const MoreButton = styled.button`
  ${buttonResetStyle};
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MoreIcon = styled(Icon).attrs({ name: 'more-horizontal', width: '24px', height: '24px' })`
  cursor: pointer;
`

const DropdownOpener = styled.div`
  position: relative;
`

type DropdownProps = {
  basePosition: DropdownBasePosition
}

const MenuDropdown = styled(_Dropdown)<DropdownProps>`
  position: fixed;
  top: 80px;
  right: calc(100% - ${({ basePosition }) => basePosition.right + 156}px);
  @media (max-height: ${BizNewPcControlsReloadButtonMaxHeight}px) {
    top: 0;
    overflow-y: scroll;
    right: calc(100% - ${({ basePosition }) => basePosition.right + 260}px);
    height: 280px;
  }
  z-index: 99;
`

const FinishDropdown = styled(_Dropdown)<DropdownProps>`
  position: absolute;
  bottom: 60px;
  right: calc(100% - ${({ basePosition }) => basePosition.right + 130}px);
  z-index: 99;
`
