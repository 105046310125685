import React from 'react'
import { AudioWaveBar, AudioWaveBarProps } from './AudioWaveBar'
import { Circle } from './Circle'

export interface AudioWaveCircleProps extends AudioWaveBarProps {
  className?: string
}

export const AudioWaveCircle: React.VFC<AudioWaveCircleProps> = ({ className, width, height, ...rest }) => {
  return (
    <Circle className={className} width={width} height={height}>
      <AudioWaveBar width={width} height={height} {...rest} />
    </Circle>
  )
}
