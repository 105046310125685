import React from 'react'
import { theme, WifiIcon as _WifiIcon } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { PropsWithClassName } from '@/@types/propsWithTypes'
import { UnstableLevel } from '../../types'

interface NetworkUnstableLevelProps extends PropsWithClassName {
  networkUnstableLevel: UnstableLevel | undefined
}

export const NetworkUnstableLevel: React.VFC<NetworkUnstableLevelProps> = ({ className, networkUnstableLevel }) => {
  return (
    <Wrapper className={className}>
      {networkUnstableLevel !== undefined && <WifiIcon unstableLevel={networkUnstableLevel} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;

  ${(props) => {
    if (props.theme.responsive.pc) {
      return css`
        top: 8px;
        right: 8px;
      `
    }
    if (props.theme.responsive.spLandscape) {
      return css`
        top: 0px;
        right: 2px;
      `
    }
    if (props.theme.responsive.spPortrait) {
      return css`
        top: 0;
        right: 2px;
      `
    }
  }}
`

const WifiIcon = styled(_WifiIcon).attrs(({ theme }) => ({ size: theme.responsive.pc ? 'm' : 's' }))`
  color: ${theme.color.limeGreen[1]};
`
