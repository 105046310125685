import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { ListInterviewTranscriptionsRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

type Args = {
  interviewGuid: string
  afterStartTime: number
  transcriptionLimit: number
}

export function useRequestListInterviewTranscriptions() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestListInterviewTranscriptions = useCallback(
    async (args: Args) => {
      const metadata = await buildMetadata()

      const req = new ListInterviewTranscriptionsRequest()
      req.setInterviewGuid(args.interviewGuid)
      req.setAfterStartTime(args.afterStartTime)
      req.setTranscriptionLimit(args.transcriptionLimit)

      return callBff('listInterviewTranscriptions', [req, metadata], loggedInStaff?.guid)
    },
    [buildMetadata, loggedInStaff]
  )

  return { requestListInterviewTranscriptions }
}
