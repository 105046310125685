import { useEffect, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { DeviceChangeObserver } from 'amazon-chime-sdk-js'
import { useLatest } from 'react-use'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'

const useAudioInput = () => {
  const { meetingManager } = MeetingManagerContainer.useContainer()
  const { audioVideo } = AudioVideoContainer.useContainer()
  const [audioInputs, setAudioInputs] = useState<MediaDeviceInfo[]>([])
  const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState(meetingManager.selectedAudioInputDevice)
  const [audioInputDeviceUpdateTime, setAudioInputDeviceUpdateTime] = useState(Date.now())
  const selectedInputRef = useLatest(selectedAudioInputDevice)

  useEffect(() => {
    const callback = (updatedAudioInputDevice: string | null): void => {
      setSelectedAudioInputDevice(updatedAudioInputDevice)
      setAudioInputDeviceUpdateTime(Date.now())
    }
    meetingManager.subscribeToSelectedAudioInputDevice(callback)

    return (): void => {
      meetingManager.unsubscribeFromSelectedAudioInputDevice(callback)
    }
  }, [meetingManager, audioInputs, selectedAudioInputDevice])

  useEffect(() => {
    let isMounted = true

    const observer: DeviceChangeObserver = {
      audioInputsChanged: async (newAudioInputs: MediaDeviceInfo[]) => {
        setAudioInputs(newAudioInputs)
      },
    }

    async function initAudioInput() {
      if (!audioVideo) {
        return
      }

      const devices = await audioVideo.listAudioInputDevices()

      if (isMounted) {
        setAudioInputs(devices)
        audioVideo.addDeviceChangeObserver(observer)
      }
    }

    initAudioInput()

    return () => {
      isMounted = false
      audioVideo?.removeDeviceChangeObserver(observer)
    }
  }, [meetingManager, audioVideo, selectedInputRef])

  return useMemo(
    () => ({
      audioInputs,
      selectedAudioInputDevice,
      audioInputDeviceUpdateTime,
    }),
    [audioInputs, selectedAudioInputDevice, audioInputDeviceUpdateTime]
  )
}

export const AudioInputContainer = createContainer(useAudioInput)
