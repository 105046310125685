import React, { useContext } from 'react'
import { Icon, Responsive, theme, useIsIPad } from '@blue-agency/rogue'
import ReactDOM from 'react-dom'
import styled, { css, ThemeContext } from 'styled-components'
import { NotificationDot as _NotificationDot } from '@/shared/components/NotificationDot'
import type { DropdownBasePosition } from '@/shared/hooks/useDropdown'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { buttonResetStyle } from '@/shared/styles/buttonResetStyle'
import { useMenuDropdown } from '../hooksForUi/useMenuDropdown'
import { Dropdown as _Dropdown } from './Dropdown'
import { CameraMuteButton } from './controlButtons/CameraMuteButton'
import { ChatButton } from './controlButtons/ChatButton'
import { MicMuteButton } from './controlButtons/MicMuteButton'

export const SpPortraitControls: React.VFC = React.memo(() => {
  const { responsive } = useContext(ThemeContext)
  const { isIPad } = useIsIPad()
  const { appType } = InterviewContainer.useContainer()
  const isMy = appType === 'my'
  return <SpPortraitControlsConsumer responsive={responsive} isIPad={isIPad} isMy={isMy} />
})

type ConsumerProps = {
  responsive: Responsive
  isIPad: boolean
  isMy: boolean
}

const menuDropdownWidth = 250

const SpPortraitControlsConsumer: React.VFC<ConsumerProps> = React.memo(({ responsive, isIPad, isMy }) => {
  const {
    dropdownItems,
    dropdownRef,
    dropdownOpenerRef,
    isDropdownShown,
    basePosition,
    toggleDropdownShown,
    showingTroubleShootingNotification,
    renderReloadModal,
    renderUnstableConnectionModal,
    renderQualityModeModal,
    renderUnstableConnectionWarningBalloon,
  } = useMenuDropdown()

  return (
    <Wrapper>
      <ChatButton />
      <MicMuteButton />
      <CameraMuteButton />
      <DropdownOpener ref={dropdownOpenerRef}>
        <MenuButton onClick={toggleDropdownShown}>
          <MenuIcon />
        </MenuButton>
        {showingTroubleShootingNotification && <NotificationDot />}
      </DropdownOpener>
      {isDropdownShown &&
        basePosition &&
        ReactDOM.createPortal(
          <Dropdown ref={dropdownRef} items={dropdownItems} basePosition={basePosition} width={menuDropdownWidth} />,
          document.getElementById('root')!
        )}
      {ReactDOM.createPortal(renderUnstableConnectionWarningBalloon(), document.getElementById('root')!)}
      {renderReloadModal()}
      {renderUnstableConnectionModal()}
      {renderQualityModeModal()}
    </Wrapper>
  )
})

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid ${theme.color.gray[3]};
  height: 100%;
  width: 100%;

  & > *:not(:last-child) {
    width: 74px;
    height: 100%;
  }
`

const DropdownOpener = styled.div`
  height: 100%;
`

const MenuButton = styled.button`
  ${buttonResetStyle};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 100%;
  color: ${theme.color.navy[1]};
`

const MenuIcon = styled(Icon).attrs({ name: 'more-horizontal', width: '24px', height: '24px' })`
  width: 21px;
  height: 21px;
`

type DropdownProps = {
  basePosition: DropdownBasePosition
}

const Dropdown = styled(_Dropdown)<DropdownProps>`
  ${({ theme, basePosition }) => {
    if (theme.responsive.spPortrait) {
      return css`
        position: fixed;
        bottom: calc(100% - ${basePosition.top}px);
        right: calc(100% - ${basePosition.right}px);
      `
    }
    if (theme.responsive.spLandscape) {
      return css`
        position: fixed;
        bottom: calc(100% - ${basePosition.bottom}px);
        right: calc(100% - ${basePosition.left}px);
      `
    }
  }}
`

const NotificationDot = styled(_NotificationDot)`
  position: absolute;
  top: 16px;
  right: 12px;
`
