import { Icon, TertiaryButton } from '@blue-agency/rogue'
import styled from 'styled-components'

export const BackgroundSettingIcon = styled(Icon).attrs({ name: 'background-blur-weak' })`
  width: 16px;
  height: 16px;
`

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
export const BackgroundSettingButton = styled(TertiaryButton).attrs({ text: '背景', direction: 'column' })`
  width: 52px;
  height: 44px;
  padding: 4px 0 0;
  font-size: 10px;
`
