// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import {
  FullJitterBackoff,
  DefaultBrowserBehavior,
  ContentShareMediaStreamBroker,
  Destroyable,
  isDestroyable,
  DeviceController,
  Logger,
  DeviceControllerBasedMediaStreamBroker,
  DefaultReconnectController,
  DefaultWebSocketAdapter,
} from 'amazon-chime-sdk-js'
import {
  ContentShareController,
  MeetingSession,
  ImContentShareController,
  AudioVideoFacade,
  AudioVideoController,
} from '..'
import { assertIsDefined } from '../../assertions'
import { ImAudioVideoController } from '../audiovideocontroller/ImAudioVideoController'
import { ImAudioVideoFacade } from '../audiovideofacade/ImAudioVideoFacade'
import { ImMeetingSessionConfiguration } from './ImMeetingSessionConfiguration'

export class ImMeetingSession implements MeetingSession, Destroyable {
  private _configuration?: ImMeetingSessionConfiguration
  private _contentShareConfiguration?: ImMeetingSessionConfiguration
  private _logger?: Logger
  private audioVideoController?: AudioVideoController
  private contentShareController?: ContentShareController
  private _deviceController?: DeviceController
  private audioVideoFacade?: AudioVideoFacade

  constructor(
    configuration: ImMeetingSessionConfiguration,
    contentShareConfiguration: ImMeetingSessionConfiguration,
    logger: Logger,
    deviceController: DeviceControllerBasedMediaStreamBroker
  ) {
    this._configuration = configuration
    this._contentShareConfiguration = contentShareConfiguration
    this._logger = logger

    this.checkBrowserSupportAndFeatureConfiguration()

    this._deviceController = deviceController
    this.audioVideoController = new ImAudioVideoController(
      this._configuration,
      this._logger,
      new DefaultWebSocketAdapter(this._logger),
      deviceController,
      new DefaultReconnectController(
        this._configuration.reconnectTimeoutMs,
        new FullJitterBackoff(
          this._configuration.reconnectFixedWaitMs,
          this._configuration.reconnectShortBackOffMs,
          this._configuration.reconnectLongBackOffMs
        )
      )
    )
    // FIXME: do not use ts-ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    deviceController.bindToAudioVideoController(this.audioVideoController)
    const contentShareMediaStreamBroker = new ContentShareMediaStreamBroker(this._logger)
    this.contentShareController = new ImContentShareController(
      contentShareMediaStreamBroker,
      new ImAudioVideoController(
        this._contentShareConfiguration,
        this._logger,
        new DefaultWebSocketAdapter(this._logger),
        contentShareMediaStreamBroker,
        new DefaultReconnectController(
          this._configuration.reconnectTimeoutMs,
          new FullJitterBackoff(
            this._configuration.reconnectFixedWaitMs,
            this._configuration.reconnectShortBackOffMs,
            this._configuration.reconnectLongBackOffMs
          )
        )
      ),
      this.audioVideoController
    )

    this.audioVideoFacade = new ImAudioVideoFacade(
      this.audioVideoController,
      this.audioVideoController.videoTileController,
      this.audioVideoController.realtimeController,
      this.audioVideoController.audioMixController,
      this._deviceController,
      this.contentShareController
    )
  }

  get configuration(): ImMeetingSessionConfiguration {
    assertIsDefined(this._configuration)
    return this._configuration
  }

  get logger(): Logger {
    assertIsDefined(this._logger)
    return this._logger
  }

  get audioVideo(): AudioVideoFacade {
    assertIsDefined(this.audioVideoFacade)
    return this.audioVideoFacade
  }

  get contentShare(): ContentShareController {
    assertIsDefined(this.contentShareController)
    return this.contentShareController
  }

  get deviceController(): DeviceController {
    assertIsDefined(this._deviceController)
    return this._deviceController
  }

  async destroy(): Promise<void> {
    if (isDestroyable(this.contentShareController)) {
      await this.contentShareController.destroy()
    }
    if (isDestroyable(this.audioVideoController)) {
      await this.audioVideoController.destroy()
    }

    this._logger = undefined
    this._configuration = undefined
    this._contentShareConfiguration = undefined
    this._deviceController = undefined
    this.audioVideoFacade = undefined
    this.audioVideoController = undefined
    this.contentShareController = undefined
  }

  private checkBrowserSupportAndFeatureConfiguration(): void {
    const browserBehavior = new DefaultBrowserBehavior()
    const browser = `${browserBehavior.name()} ${browserBehavior.majorVersion()} (${browserBehavior.version()})`
    this.logger.info(`browser is ${browser}`)
    if (!browserBehavior.isSupported()) {
      this.logger.warn(
        'this browser is not currently supported. ' +
          'Stability may suffer. ' +
          `Supported browsers are: ${browserBehavior.supportString()}.`
      )
    }

    if (this.configuration.enableUnifiedPlanForChromiumBasedBrowsers) {
      if (browserBehavior.hasChromiumWebRTC()) {
        this.logger.info('WebRTC unified plan for Chromium-based browsers is enabled')
      } else {
        this.logger.info(`WebRTC unified plan is required for ${browserBehavior.name()}`)
      }
    }

    this.configuration.enableSimulcastForUnifiedPlanChromiumBasedBrowsers = false
  }
}
