import { memo } from 'react'
import { Icon, Txt as _Txt, NewTabLink } from '@blue-agency/rogue'
import styled, { css } from 'styled-components'
import { BIZ_EXTERNAL_URLS } from '@/biz/services/urlService'
import { MY_EXTERNAL_URLS } from '@/my/services/urlService'

const HELP_LINK_MAP = {
  biz: BIZ_EXTERNAL_URLS.help.backgroundSetting,
  my: MY_EXTERNAL_URLS.help.backgroundSetting,
} as const

export const Caution: React.VFC<{ appType: 'biz' | 'my' }> = memo(({ appType }) => {
  return (
    <Container>
      <LightBulb />
      <TextContainer rowGap="10px">
        <TextContainer>
          <Row>
            <Txt bold>Windows: </Txt>
            <Txt>Google Chrome, Microsoft Edgeの最新版のみ利用可能です。</Txt>
          </Row>
          <Row>
            <Txt bold>Mac: </Txt>
            <Txt>Google Chromeの最新版のみ利用可能です。</Txt>
          </Row>
        </TextContainer>
        <TextContainer>
          <Row>
            <Txt>背景の設定は端末に負荷がかかります。正常に動作しない場合は「なし」を選択して下さい。</Txt>
          </Row>
          <Row>
            <Txt>
              詳細は
              <NewTabLink href={HELP_LINK_MAP[appType]} action="click_background_setting_modal_help_link">
                ヘルプページ
              </NewTabLink>
              をご確認ください。
            </Txt>
          </Row>
        </TextContainer>
      </TextContainer>
    </Container>
  )
})

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px;
`

const TextContainer = styled.div<{ rowGap?: string }>`
  display: flex;
  flex-direction: column;
  ${({ rowGap }) =>
    rowGap &&
    css`
      row-gap: ${rowGap};
    `}
`

const Row = styled.div`
  display: flex;
  width: 600px;
`

const LightBulb = styled(Icon).attrs({ size: 'l', name: 'light-bulb' })`
  margin-right: 10px;
`

const Txt = styled(_Txt).attrs({ size: 'm' })`
  line-height: 24px;
`
