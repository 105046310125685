import React from 'react'
import { Responsive, Icon, SecondaryDangerButton } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { isInterviewReadySelector } from '@/shared/services/interviewService/redux'

type Props = {
  toggle: () => void
  isDropdownShown: boolean
}

export const FinishButton: React.VFC<Props> = React.memo((props) => {
  const { responsive } = useTheme()
  return <FinishButtonConsumer toggle={props.toggle} isDropdownShown={props.isDropdownShown} responsive={responsive} />
})

type ConsumerProps = {
  responsive: Responsive
} & Props

const FinishButtonConsumer: React.VFC<ConsumerProps> = React.memo((props) => {
  const isInterviewReady = useSelector(isInterviewReadySelector)
  const disabled = !isInterviewReady

  if (props.responsive.pc) {
    return (
      // NOTE: このファイル内の重複+myとの重複は想定内
      // eslint-disable-next-line @blue-agency/react/unique-action-name
      <PcStyled onClick={props.toggle} disabled={disabled} comlinkPushParams={{ action: 'click_finish_button' }}>
        終了 / 退室
        <PcIconWrapper>
          {props.isDropdownShown ? <Icon name="arrow-up" size="s" /> : <Icon name="arrow-down" size="s" />}
        </PcIconWrapper>
      </PcStyled>
    )
  }
  return (
    <SpStyled
      size="s"
      onClick={props.toggle}
      direction="column"
      disabled={disabled}
      // eslint-disable-next-line @blue-agency/react/unique-action-name
      comlinkPushParams={{ action: 'click_finish_button' }}
    >
      終了
      <SpIconWrapper>
        {props.isDropdownShown ? <Icon name="arrow-down" size="s" /> : <Icon name="arrow-up" size="s" />}
      </SpIconWrapper>
    </SpStyled>
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const PcStyled = styled(SecondaryDangerButton)`
  font-weight: bold;
  width: 105px;
`

// レイアウト実装の過渡期で、PC, SP でスタイルが異なる
// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const SpStyled = styled(SecondaryDangerButton)`
  font-weight: bold;
  border-radius: 4px;
  width: 56px;
  height: 27px;
`

const PcIconWrapper = styled.span`
  position: relative;
  top: 1px;
  left: 12px;
`

const SpIconWrapper = styled.span`
  position: relative;
  top: 1px;
  left: 3px;
`
