import { UAParser } from 'ua-parser-js'

let result: UAParser.IResult

export function getUserAgent(): UAParser.IResult {
  if (result === undefined) {
    const parser = new UAParser(navigator.userAgent)
    result = parser.getResult()
  }
  return result
}

/**
 * ブラウザ名を返す
 * 返す値は ↓ を確認する
 * node_modules/@types/ua-parser-js/index.d.ts
 */
export function getBrowser(): string {
  const ua = getUserAgent()
  const browserName = ua.browser.name

  if (browserName === undefined) {
    throw new Error('Unexpected browser')
  }
  return browserName
}

export function isIOSDevice(): boolean {
  const ua = getUserAgent()
  if (
    // NOTE: Safariで「デスクトップ用Webサイトの表示」を有効にしたとき、UAからはmacOSとの区別がつかない
    // タッチスクリーンかどうかで判別している
    (ua.os.name === 'Mac OS' && hasTouchableScreen()) ||
    ua.os.name === 'iOS'
  ) {
    return true
  }
  return false
}

function hasTouchableScreen(): boolean {
  return 'ontouchend' in document
}

export function isInternetExplorer(): boolean {
  const browser = getBrowser()
  return browser === 'IE'
}

export function isSafari(): boolean {
  const browser = getBrowser()
  return browser === 'Safari' || browser === 'Mobile Safari'
}
