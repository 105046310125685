import { PrimaryButton, TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const NextButton = styled(PrimaryButton).attrs({
  children: '次の説明に進む',
  widthSize: 'L2',
  heightSize: 'L',
})``
export const BackButton = styled(TertiaryButton).attrs({
  children: '前の説明に戻る',
  widthSize: 'L2',
  heightSize: 'L',
})`
  margin-right: 21px;
`
export const CloseButton = styled(TertiaryButton).attrs({
  children: '待機画面に戻る',
  widthSize: 'L2',
  heightSize: 'L',
})``
