import { useEffect, useMemo, useState } from 'react'
import { createContainer } from '@blue-agency/front-state-management'
import { useSelector } from 'react-redux'
import { SharedState } from '../redux'
import { SoraClientId } from '../types'
import { ParticipantAudioVolumesContainer } from './ParticipantAudioVolumesContainer'

const MIN_TH = 90

export type ParticipantAudioWaveMap = Record<SoraClientId, number[]>

const useSpeakingParticipant = () => {
  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)
  const micMutedMap = useSelector((state: SharedState) => state.shared.micMutedMap)
  const metadataMap = useSelector((state: SharedState) => state.shared.metadataMap)

  const { remoteAudioVolumes, localAudioVolume } = ParticipantAudioVolumesContainer.useContainer()

  const [speakingSoraClientId, setSpeakingSoraClientId] = useState<string | undefined>(undefined)
  useEffect(() => {
    if (!ownSoraClientId) return
    if (localAudioVolume === undefined) return

    const audioVolumes = { ...remoteAudioVolumes, [ownSoraClientId]: localAudioVolume }

    let maxVol = 0
    let speakingSoraClientId: string | undefined = undefined
    for (const [soraClientId, vol] of Object.entries(audioVolumes)) {
      if (micMutedMap[soraClientId]) {
        continue
      }
      const metadata = metadataMap[soraClientId]
      if (!metadata || metadata.isDestroyed) {
        continue
      }
      // 画面共有は発話者に含めない
      if (metadata.interview_role === 'applicant_screen' || metadata.interview_role === 'recruiter_screen') {
        continue
      }

      if (vol > MIN_TH && vol > maxVol) {
        maxVol = vol
        speakingSoraClientId = soraClientId
      }
    }
    setSpeakingSoraClientId(speakingSoraClientId)
  }, [remoteAudioVolumes, localAudioVolume, ownSoraClientId, micMutedMap, metadataMap])

  return useMemo(
    () => ({
      speakingSoraClientId,
    }),
    [speakingSoraClientId]
  )
}

export const SpeakingParticipantContainer = createContainer(useSpeakingParticipant)
