import { useEffect, useMemo, useRef } from 'react'
import { Icon, theme, Txt } from '@blue-agency/rogue'
import { Case, Default, Else, If, Switch, Then } from 'react-if'
import { shallowEqual, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { CameraMutedImage } from '@/lib/meetingcomponent/CameraMutedImage'
import { Overlay } from '@/lib/meetingcomponent/CommonVideoParts'
import { MainVideoContainer } from '@/lib/meetingcomponent/MainVideoContainer'
import { OverlayPin } from '@/lib/meetingcomponent/OverlayPin'
import { UnavailableVideoInputImage } from '@/lib/meetingcomponent/UnavailableVideoInputImage'
import { Video } from '@/lib/meetingcomponent/Video'
import { useLocalVideoElement } from '@/lib/meetingcomponent/useLocalVideoElement'
import { LocalAudioWaveCircle as _LocalAudioWaveCircle } from '@/shared/services/interviewService/components/AudioWaveCircle/LocalAudioWaveCircle'
import { RemoteAudioWaveCircle as _RemoteAudioWaveCircle } from '@/shared/services/interviewService/components/AudioWaveCircle/RemoteAudioWaveCircle'
import { SizeWithPixel } from '@/shared/services/interviewService/components/Layout/useMinutesViewAreaSizes'
import { NetworkUnstableLevel } from '@/shared/services/interviewService/components/VideoList/NetworkUnstableLevel'
import { SpeakingParticipantContainer } from '@/shared/services/interviewService/containers/SpeakingParticipantContainer'
import { useGetParticipantInfoForDisplay } from '@/shared/services/interviewService/hooksForUi/useGetParticipantInfoForDisplay'
import {
  contentShareSelector,
  isLocalVideoEnabledSelector,
  ownParticipantSelector,
  participantsOfOtherRoleAndNotContentShareSelector,
  participantsOfSameRoleAndNotContentShareSelector,
} from '@/shared/services/interviewService/redux'
import type { SharedState } from '@/shared/services/interviewService/redux'
import { isRecruiter } from '@/shared/services/interviewService/types'
import type { Participant } from '@/shared/services/interviewService/types'

type MinutesViewVideosProps = {
  calcSubVideoSize: (numParticipants: number) => SizeWithPixel
}

export function MinutesViewVideos(props: MinutesViewVideosProps) {
  const { calcSubVideoSize } = props

  const remoteTiles = useSelector((state: SharedState) => state.shared.remoteTiles)
  const { remote, local, numParticipants } = useGetParticipants()

  const { speakingSoraClientId } = SpeakingParticipantContainer.useContainer()

  const videoSize = useMemo(() => {
    return calcSubVideoSize(numParticipants)
  }, [calcSubVideoSize, numParticipants])

  return (
    <Wrapper>
      {remote.map((p) => {
        const id = p.soraClientId
        const tileId = remoteTiles[id]
        if (tileId === undefined) {
          return null
        }
        return (
          <RemoteVideoMinutesView
            key={id}
            soraClientId={id}
            tileId={tileId}
            isRecruiter={isRecruiter(p.role)}
            isSpeaking={id === speakingSoraClientId}
            {...videoSize}
          />
        )
      })}

      {local !== undefined && (
        <LocalVideoMinutesView
          soraClientId={local.participant.soraClientId}
          tileId={local.tileId}
          isRecruiter={isRecruiter(local.participant.role)}
          isSpeaking={local.participant.soraClientId === speakingSoraClientId}
          {...videoSize}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: start;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${theme.color.darkGray[1]};
  padding: 2px;
`

type TileProps = {
  soraClientId: string
  tileId: number
  width: number
  height: number
  isRecruiter: boolean
  isSpeaking: boolean
}

function RemoteVideoMinutesView({ soraClientId, tileId, width, height, isRecruiter, isSpeaking }: TileProps) {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { mainVideoContentTileId } = MainVideoContainer.useContainer()
  const { name, isScreenShare } = useGetParticipantInfoForDisplay(soraClientId)
  const micMutedMap = useSelector((state: SharedState) => state.shared.micMutedMap)
  const networkUnstableLevelMap = useSelector((state: SharedState) => state.shared.networkUnstableLevelMap)
  const isMicMuted = micMutedMap[soraClientId]
  const networkUnstableLevel = networkUnstableLevelMap[soraClientId]
  const isMainVideo = mainVideoContentTileId === tileId
  const myself = useSelector(ownParticipantSelector, shallowEqual)

  const videoEl = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (!audioVideo || !videoEl.current) return

    audioVideo.bindVideoElement(tileId, videoEl.current)
    // NOTE: タイルビューと面接ビューはdisplay: noneで隠すことで切り替えている See ./index.tsx#L146
    // その際、videoは存在しているので二重に再生されている。画面回転や軽量モード切替などの際に少しずれてリバーブのかかったように聞こえることがある。
    // 面接ビューでのみ音声が出力し、タイルビューではミュート固定とすることで二重に再生せず、ズレて聞こえる問題を防ぐ。
    videoEl.current.muted = true

    return () => {
      audioVideo?.unbindVideoElement(tileId)
    }
  }, [audioVideo, tileId])

  return (
    <VideoWrapper width={width} height={height}>
      <Video ref={videoEl} />
      {!isScreenShare && <NetworkUnstableLevel networkUnstableLevel={networkUnstableLevel} />}

      <Footer>
        <AudioStatus>
          <If condition={isMicMuted}>
            <Then>
              <MuteMicIcon />
            </Then>
            <Else>
              <RemoteAudioWaveCircle attendeeId={soraClientId} />
            </Else>
          </If>
        </AudioStatus>
        <Name>
          {isRecruiter && <CompanyIcon />}
          {name}
        </Name>
      </Footer>

      <Overlay hasName={!!myself?.name} isMainVideo={isMainVideo} />
      <SpeakingFrame isSpeaking={isSpeaking} />
      <OverlayPin tileId={tileId} icon="pin" target="tile" />
    </VideoWrapper>
  )
}

function LocalVideoMinutesView({ soraClientId, tileId, width, height, isRecruiter, isSpeaking }: TileProps) {
  const { mainVideoContentTileId } = MainVideoContainer.useContainer()
  const { name } = useGetParticipantInfoForDisplay(soraClientId)

  const micMutedMap = useSelector((state: SharedState) => state.shared.micMutedMap)
  const isMicMuted = micMutedMap[soraClientId]

  const isOwnCameraMuted = useSelector((state: SharedState) => state.shared.isOwnCameraMuted)
  const isUnavailableVideoInput = useSelector((state: SharedState) => state.shared.isUnavailableVideoInput)
  const networkUnstableLevel = useSelector((state: SharedState) => state.shared.networkUnstableLevel)
  const isMainVideo = mainVideoContentTileId === tileId
  const myself = useSelector(ownParticipantSelector, shallowEqual)

  const videoEl = useLocalVideoElement()

  return (
    <VideoWrapper width={width} height={height}>
      <Switch>
        <Case condition={isOwnCameraMuted}>
          <CameraMutedImage />
        </Case>
        <Case condition={isUnavailableVideoInput}>
          <UnavailableVideoInputImage />
        </Case>
        <Default>
          <Video ref={videoEl} />
        </Default>
      </Switch>
      <NetworkUnstableLevel networkUnstableLevel={networkUnstableLevel} />

      <Footer>
        <AudioStatus>
          <If condition={isMicMuted}>
            <Then>
              <MuteMicIcon />
            </Then>
            <Else>
              <LocalAudioWaveCircle />
            </Else>
          </If>
        </AudioStatus>

        <Name>
          {isRecruiter && <CompanyIcon />}
          {name}
        </Name>
      </Footer>

      <Overlay hasName={!!myself?.name} isMainVideo={isMainVideo} />
      <SpeakingFrame isSpeaking={isSpeaking} />
      <OverlayPin tileId={tileId} icon="pin" target="tile" />
    </VideoWrapper>
  )
}

type GetParticipantsResponse = {
  remote: ReadonlyArray<Participant>
  // 「自分の画面に自分の映像を表示」をオフにしているときも undefined となる
  local:
    | {
        participant: Participant
        tileId: number
      }
    | undefined
  numParticipants: number
}

const VideoWrapper = styled.div<{ width: number; height: number }>`
  cursor: pointer;
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`

const Footer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 3px 9px;
  height: 36px;
  display: flex;
  align-items: center;
`

const AudioStatus = styled.div`
  margin-right: 5px;
`

const Name = styled(Txt).attrs({ color: theme.color.white[1], size: 'm' })`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  text-shadow: 0px 0px 3px ${theme.color.black[1]};

  display: flex;
  align-items: center;
`

const CompanyIcon = styled(Icon).attrs({ name: 'company' })`
  filter: drop-shadow(0px 0px 2px ${theme.color.black[1]});
  margin-right: 4px;
`

const MuteMicIcon = styled(Icon).attrs({ name: 'mute-mike' })`
  width: 12px;
  height: 12px;
  padding: 4px;

  border-radius: 50%;
  background-color: ${theme.color.red[2]};
  color: ${theme.color.white[1]};
  opacity: 0.9;
`

const RemoteAudioWaveCircle = styled(_RemoteAudioWaveCircle).attrs({
  width: 20,
  height: 20,
  barWidth: 2.5,
  barPadding: 2.5,
  baseBarHeight: 0,
  centerMaxBarHeight: 13,
  sideMaxBarHeight: 8,
})``

const LocalAudioWaveCircle = styled(_LocalAudioWaveCircle).attrs({
  width: 20,
  height: 20,
  barWidth: 2.5,
  barPadding: 2.5,
  baseBarHeight: 0,
  centerMaxBarHeight: 13,
  sideMaxBarHeight: 8,
})``

const SpeakingFrame = styled.div<{ isSpeaking: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  ${({ isSpeaking }) => {
    return css`
      border: 3px solid ${isSpeaking ? theme.color.green[4] : theme.color.darkGray[1]};
    `
  }}
`

function useGetParticipants(): GetParticipantsResponse {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const screenShare = useSelector(contentShareSelector)
  const sameRoleParticipants = useSelector(participantsOfSameRoleAndNotContentShareSelector)
  const otherRoleParticipants = useSelector(participantsOfOtherRoleAndNotContentShareSelector)

  const remote = useMemo(() => {
    return [...screenShare, ...otherRoleParticipants, ...sameRoleParticipants]
  }, [screenShare, otherRoleParticipants, sameRoleParticipants])

  const localParticipant = useSelector(ownParticipantSelector, shallowEqual)
  const localTileId = audioVideo?.getLocalVideoTile()?.id()
  const localVideoEnabled = useSelector(isLocalVideoEnabledSelector)
  const local =
    localVideoEnabled && localParticipant !== undefined && localTileId !== undefined
      ? {
          participant: localParticipant,
          tileId: localTileId,
        }
      : undefined

  return {
    remote,
    local,
    numParticipants: local === undefined ? remote.length : remote.length + 1,
  }
}
