import React, { useMemo } from 'react'
import LogLevel from 'amazon-chime-sdk-js/build/logger/LogLevel'
import { ScreenShareUiContainer } from '@/shared/services/interviewService/containers/ScreenShareUiContainer'
import { AudioVideoContainer } from './AudioVideoContainer'
import { ContentShareContainer } from './ContentShareContainer'
import { DataChannelMessagingContainer } from './DataChannelMessagingContainer'
import { AudioInputContainer, AudioOutputContainer, VideoInputContainer } from './DevicesContainer'
import { LocalVideoContainer } from './LocalVideoContainer'
import { MainVideoContainer } from './MainVideoContainer'
import { MeetingManagerContainer } from './MeetingManagerContainer'
import { RemoteVideoTileContainer } from './RemoteVideoTileContainer'
import { VideosAreaContainer } from './VideosAreaContainer'

export const MeetingProvider: React.FC = ({ children }) => {
  const meetingManagerInitialState = useMemo(
    () => ({
      logLevel: LogLevel.OFF,
    }),
    []
  )

  return (
    <MeetingManagerContainer.Provider initialState={meetingManagerInitialState}>
      <AudioVideoContainer.Provider>
        <AudioInputContainer.Provider>
          <AudioOutputContainer.Provider>
            <VideoInputContainer.Provider>
              <MainVideoContainer.Provider>
                <LocalVideoContainer.Provider>
                  <RemoteVideoTileContainer.Provider>
                    <DataChannelMessagingContainer.Provider>
                      <ContentShareContainer.Provider>
                        <ScreenShareUiContainer.Provider>
                          <VideosAreaContainer.Provider>{children}</VideosAreaContainer.Provider>
                        </ScreenShareUiContainer.Provider>
                      </ContentShareContainer.Provider>
                    </DataChannelMessagingContainer.Provider>
                  </RemoteVideoTileContainer.Provider>
                </LocalVideoContainer.Provider>
              </MainVideoContainer.Provider>
            </VideoInputContainer.Provider>
          </AudioOutputContainer.Provider>
        </AudioInputContainer.Provider>
      </AudioVideoContainer.Provider>
    </MeetingManagerContainer.Provider>
  )
}
