import type { ConnectionPublisher, ConnectionSubscriber, Callbacks as CallbackTypes } from '@/lib/interview-sdk-js'
import {
  buildPublisher,
  buildSendonlyPublisher,
  buildSubscriber,
} from '@/shared/services/interviewService/containers/soraConnection'
import { SoraConnectionProps } from '@/shared/services/interviewService/types'

const DEFAULT_VIDEO_BITRATE = 600
const SCREEN_SHARE_BITRATE = 256

type Callbacks = {
  onNotify: CallbackTypes['notify']
  onDisconnect?: CallbackTypes['disconnect']
  onMessage?: CallbackTypes['message']
}

export const buildUserPublisher = (props: SoraConnectionProps, callbacks: Callbacks) => {
  const { channelId, webrtcHost, invitationToken, sessionToken, videoBitrate, dataChannels } = props

  const publisher = buildPublisher({
    channelId,
    webrtcHost,
    metadata: {
      invitation_token: invitationToken,
      session_token: sessionToken,
      is_screen_shared: false,
    },
    videoBitrate: videoBitrate || DEFAULT_VIDEO_BITRATE,
    dataChannels,
  })

  publisher.on('notify', callbacks.onNotify)
  callbacks.onDisconnect && publisher.on('disconnect', callbacks.onDisconnect)
  callbacks.onMessage && publisher.on('message', callbacks.onMessage)

  return publisher
}

export const buildScreenSharingPublisher = (props: SoraConnectionProps): ConnectionPublisher => {
  const { channelId, webrtcHost, invitationToken, sessionToken, videoBitrate, dataChannels } = props

  const publisher = buildSendonlyPublisher({
    channelId,
    webrtcHost,
    metadata: {
      invitation_token: invitationToken,
      session_token: sessionToken,
      is_screen_shared: true,
    },
    videoBitrate: videoBitrate || SCREEN_SHARE_BITRATE,
    dataChannels,
  })

  return publisher
}

export const buildScreenSharingSubscriber = (
  props: SoraConnectionProps,
  callbacks: Callbacks
): ConnectionSubscriber => {
  const { channelId, webrtcHost, invitationToken, sessionToken, videoBitrate, dataChannels } = props

  const subscriber = buildSubscriber({
    channelId,
    webrtcHost,
    metadata: {
      invitation_token: invitationToken,
      session_token: sessionToken,
      is_screen_sharing_recv: true,
    },
    videoBitrate: videoBitrate || SCREEN_SHARE_BITRATE,
    dataChannels,
  })

  subscriber.on('notify', callbacks.onNotify)
  callbacks.onDisconnect && subscriber.on('disconnect', callbacks.onDisconnect)

  return subscriber
}

export const isUnseparatedChannel = (
  mainPublisherProps: SoraConnectionProps,
  screenSharingSubscriberProps: SoraConnectionProps
) => {
  // チャンネル分離以前のデータで同じチャンネルIDの場合、受信する必要がない
  return (
    mainPublisherProps.channelId === screenSharingSubscriberProps.channelId &&
    mainPublisherProps.webrtcHost === screenSharingSubscriberProps.webrtcHost
  )
}
