import { useCallback } from 'react'
import { DeleteInterviewTranscriptionPinningResponse } from '@blue-agency/proton/biz_skywalker_bff'
import { alertToast, toast, Icon, theme } from '@blue-agency/rogue'
import styled from 'styled-components'

type Props = {
  transcriptionGuid: string
  transcriptionPinningGuid: string
  onIconClick: (transcriptionGuid: string, transcriptionPinningGuid: string) => void
  requestDeleteInterviewTranscriptionPinning: (
    transcriptionPinningGuid: string
  ) => Promise<DeleteInterviewTranscriptionPinningResponse>
}

export const DeleteTranscriptionPin: React.VFC<Props> = ({
  transcriptionGuid,
  transcriptionPinningGuid,
  onIconClick,
  requestDeleteInterviewTranscriptionPinning,
}) => {
  const deleteInterviewTranscriptionPinning = useCallback(
    async (transcriptionPinningGuid: string) => {
      try {
        await requestDeleteInterviewTranscriptionPinning(transcriptionPinningGuid)
        toast('ピンを削除しました')
      } catch (err) {
        alertToast('ピンの削除に失敗しました。')
        return
      }
    },
    [requestDeleteInterviewTranscriptionPinning]
  )

  const handleDeleteButtonClick = () => {
    deleteInterviewTranscriptionPinning(transcriptionPinningGuid)
    onIconClick(transcriptionGuid, transcriptionPinningGuid)
  }

  return (
    <>
      <DeletePinWrapper onClick={handleDeleteButtonClick}>
        <DeletePin></DeletePin>
      </DeletePinWrapper>
    </>
  )
}

const DeletePinWrapper = styled.div`
  position: relative;
`

const DeletePin = styled(Icon).attrs({ name: 'delete' })`
  position: absolute;
  top: -32px;
  height: 24px;
  width: 24px;
  display: flex;
  padding: 8px 12px;
  gap: 12px;
  border-radius: 4px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
  background: ${theme.color.white[1]};
  color: ${theme.color.red[2]};
  &:hover {
    background-color: ${theme.color.gray[4]};
    cursor: pointer;
  }
`
