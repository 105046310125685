import React, { useCallback } from 'react'
import { Modal, Txt, WeakColorSeparator } from '@blue-agency/rogue'
import { SecondaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'

export const NumberOfParticipantsLimitErrorModal: React.VFC = () => {
  const onClick = useCallback(() => window.location.reload(), [])

  return (
    <Modal active title="参加人数オーバー" size="m">
      <Upper>
        <Txt>参加人数の上限を超えているため、参加できません。</Txt>
      </Upper>
      <WeakColorSeparator />
      <ButtonWrapper>
        <SecondaryButton
          onClick={onClick}
          widthSize="L2"
          comlinkPushParams={{ action: 'click_button_on_number_of_participants_limit_error_modal' }}
        >
          前の画面に戻る
        </SecondaryButton>
      </ButtonWrapper>
    </Modal>
  )
}

const Upper = styled.div`
  margin: 20px 20px 40px;
`

const ButtonWrapper = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
`
