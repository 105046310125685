import { useCallback } from 'react'
import { useLoggedInStaff } from '@blue-agency/im-shared-front'
import { CreateInterviewTranscriptionPinningRequest } from '@blue-agency/proton/biz_skywalker_bff'
import { callBff } from './bffClient'
import { useMetadata } from './metadata'

type Args = {
  interviewGuid: string
  interviewPinningType: CreateInterviewTranscriptionPinningRequest.InterviewPinningType
  transcriptionSentenceGuid: string
}

export function useRequestCreateInterviewTranscriptionPinning() {
  const { buildMetadata } = useMetadata()
  const loggedInStaff = useLoggedInStaff()

  const requestCreateInterviewTranscriptionPinning = useCallback(
    async (args: Args) => {
      const metadata = await buildMetadata()

      const req = new CreateInterviewTranscriptionPinningRequest()
      req.setInterviewGuid(args.interviewGuid)
      req.setType(args.interviewPinningType)
      req.setTranscriptionSentenceGuid(args.transcriptionSentenceGuid)

      return callBff('createInterviewTranscriptionPinning', [req, metadata], loggedInStaff?.guid)
    },

    [buildMetadata, loggedInStaff]
  )

  return { requestCreateInterviewTranscriptionPinning }
}
