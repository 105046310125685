// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
// Modifications copyright (C) 2021 Stadium, Inc.
import { BaseTask, RemovableObserver, TaskCanceler } from 'amazon-chime-sdk-js'
import { ImMeetingSessionStatusCode } from '..'
import { ImAudioVideoControllerState } from '../audiovideocontroller/ImAudioVideoControllerState'

const ErrorMessage = {
  AlreadyInvitedAsAnotherRole: 'Already invited as another role',
  NumParticipantsLimitExceeded: 'The number of participants exceeds the upper limit',
} as const

export class OpenSoraConnectionTask extends BaseTask implements RemovableObserver {
  protected taskName = 'OpenSoraConnectionTask'

  private removeTrackAddedEventListener: (() => void) | null = null
  private removeTrackRemovedEventListeners: { [trackId: string]: () => void } = {}

  private taskCanceler: TaskCanceler | null = null

  constructor(private context: ImAudioVideoControllerState) {
    super(context.logger)
  }

  removeObserver(): void {
    this.removeTrackAddedEventListener && this.removeTrackAddedEventListener()
    Object.values(this.removeTrackRemovedEventListeners).forEach((f) => f())
  }

  cancel(): void {
    if (this.taskCanceler) {
      this.taskCanceler.cancel()
      this.taskCanceler = null
    }
  }

  async run(): Promise<void> {
    this.context.removableObservers.push(this)
    const configuration = this.context.meetingSessionConfiguration

    if (this.context.peer && this.context.peer.connectionState !== 'closed') {
      return
    }

    if (!configuration?.soraConnectionPublisher) {
      throw new Error('No ConnectionPublisher provided')
    }
    const soraConnection = configuration.soraConnectionPublisher

    this.removeTrackAddedEventListener = () => {
      soraConnection.on('track', (): null => null)
      this.removeTrackAddedEventListener = null
    }
    if (!(soraConnection.metadata as { is_screen_shared: boolean })['is_screen_shared']) {
      soraConnection.on('track', this.trackAddedHandler)
      soraConnection.on('removetrack', (event: MediaStreamTrackEvent) => {
        const attendeeId = (event.target as EventTarget & { id: string }).id
        const track = event.track
        if (track.kind !== 'video') {
          return
        }

        const tiles = this.context.videoTileController?.getAllVideoTiles()
        if (tiles && tiles.length > 0) {
          const removed = tiles.find((tile) => tile.state().boundAttendeeId === attendeeId)
          if (removed) {
            this.context.videoTileController?.removeVideoTile(removed.id())
          }
        }
      })
    }

    const stream = new MediaStream()
    const videoTrack = this.context.activeVideoInput?.getVideoTracks()[0]
    const audioTrack = this.context.activeAudioInput?.getAudioTracks()[0]
    if (videoTrack === undefined) {
      throw new Error(
        `Aborting ${this.taskName} due to the meeting status code: ${ImMeetingSessionStatusCode.NoActiveVideoInput}`
      )
    }
    if (audioTrack === undefined) {
      throw new Error(
        `Aborting ${this.taskName} due to the meeting status code: ${ImMeetingSessionStatusCode.NoActiveAudioInput}`
      )
    }
    stream.addTrack(videoTrack)
    stream.addTrack(audioTrack)

    try {
      await soraConnection.connect(stream)
    } catch (e) {
      if (e.message.includes(ErrorMessage.NumParticipantsLimitExceeded)) {
        throw new Error(
          `Aborting ${this.taskName} due to the meeting status code: ${ImMeetingSessionStatusCode.SoraParticipantsLimitExceeded} ${e.message}`
        )
      }

      if (e.message.includes(ErrorMessage.AlreadyInvitedAsAnotherRole)) {
        throw new Error(
          `Aborting ${this.taskName} due to the meeting status code: ${ImMeetingSessionStatusCode.SoraAlreadyInvitedAsAnotherRole} ${e.message}`
        )
      }

      throw new Error(
        `Aborting ${this.taskName} due to the meeting status code: ${ImMeetingSessionStatusCode.SoraPublisherConnectionFailed} ${e.message}`
      )
    }

    this.context.peer = soraConnection.pc
    if (this.context.peer) {
      this.context.transceiverController?.setPeer(this.context.peer)
    }
  }

  private trackAddedHandler = (event: RTCTrackEvent): void => {
    const track: MediaStreamTrack = event.track
    this.context.logger.info(`received track event: kind=${track.kind} id=${track.id} label=${track.label}`)

    if (event.transceiver && event.transceiver.currentDirection === 'inactive') {
      return
    }

    if (!event.streams[0]) {
      this.context.logger.warn('Track event but no stream')
      return
    }

    const stream: MediaStream = event.streams[0]
    if (track.kind === 'audio') {
      // this.context.audioMixController.bindAudioStream(stream)
    } else if (track.kind === 'video' && !this.trackIsVideoInput(track)) {
      this.addRemoteVideoTrack(track, stream)
    }
  }

  private trackIsVideoInput(track: MediaStreamTrack): boolean {
    if (this.context.transceiverController?.useTransceivers()) {
      this.logger.debug(() => {
        return 'getting video track type (unified-plan)'
      })
      return this.context.transceiverController.trackIsVideoInput(track)
    }
    this.logger.debug(() => {
      return 'getting video track type (plan-b)'
    })
    if (this.context.activeVideoInput) {
      const tracks = this.context.activeVideoInput.getVideoTracks()
      if (tracks && tracks[0] && tracks[0].id === track.id) {
        return true
      }
    }
    return false
  }

  private addRemoteVideoTrack(track: MediaStreamTrack, stream: MediaStream): void {
    const trackId = track.id
    const attendeeId = stream.id
    if (this.context.videoTileController?.haveVideoTileForAttendeeId(attendeeId)) {
      this.context.logger.info(`Not adding remote track. Already have tile for attendeeId:  ${attendeeId}`)
      return
    }

    const tile = this.context.videoTileController?.addVideoTile()

    let width: number
    let height: number
    if (track.getSettings) {
      const cap: MediaTrackSettings = track.getSettings()
      width = cap.width as number
      height = cap.height as number
    } else {
      const cap: MediaTrackCapabilities = track.getCapabilities()
      width = cap.width as number
      height = cap.height as number
    }
    if (tile) {
      tile.bindVideoStream(attendeeId, false, stream, width, height, null, attendeeId)
    }
    this.logger.info(`video track added, created tile=${tile?.id()} track=${trackId} attendeeId=${attendeeId}`)
  }
}
