import { useEffect, useRef } from 'react'
import { DefaultVideoTile, VideoTileState } from 'amazon-chime-sdk-js'
import { useMount } from 'react-use'
import { AudioVideoContainer } from '@/lib/meetingcomponent/AudioVideoContainer'
import { MeetingManagerContainer } from '@/lib/meetingcomponent/MeetingManagerContainer'

export function useLocalVideoElement() {
  const { audioVideo } = AudioVideoContainer.useContainer()
  const { meetingManager } = MeetingManagerContainer.useContainer()

  const videoEl = useRef<HTMLVideoElement>(null)

  useMount(() => {
    if (!videoEl.current) {
      return
    }
    meetingManager.bindClonedLocalStreamToVideoElement(videoEl.current)
  })

  useEffect(() => {
    const videoTileDidUpdate = (tileState: VideoTileState) => {
      if (!tileState.localTile || !tileState.boundVideoStream || !videoEl.current) {
        return
      }

      meetingManager.bindClonedLocalStreamToVideoElement(videoEl.current)
    }

    audioVideo?.addObserver({ videoTileDidUpdate })

    return () => {
      audioVideo?.removeObserver({ videoTileDidUpdate })
    }
  }, [audioVideo, meetingManager])

  useEffect(() => {
    const video = videoEl.current
    return () => {
      if (video) {
        DefaultVideoTile.disconnectVideoStreamFromVideoElement(video, false)
      }
    }
  }, [])

  return videoEl
}
