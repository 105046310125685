const makeCacheKey =
  <T extends { [key: string]: string } | void>(key: string) =>
  (params: T) => {
    return (
      key +
      '-' +
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values((params as any) || {})
        .sort()
        .join('-')
    )
  }

export const cacheKey = {
  getInterviewQuality: makeCacheKey<{ interviewGuid: string }>('getInterviewQuality'),
  getInterviewQualityInRoom: makeCacheKey<{ interviewGuid: string }>('getInterviewQualityInRoom'),
}
