import React, { useMemo } from 'react'
import { Dropdown, DropdownOption, Txt, Icon } from '@blue-agency/rogue'
import { TertiaryButton } from '@blue-agency/rogue/im'
import styled from 'styled-components'
import { usePlayDemoSound } from '../services/interviewService/hooksForUi/useMenuDropdown/useChangeDeviceModal/usePlayDemoSound'

type Props = {
  speakerDevices: readonly MediaDeviceInfo[]
  selectedSpeakerDeviceId: string | undefined
  onChangeDevice: (deviceId: string) => void
}

export const ChangeSpeakerDropdownOnBeforeEntered: React.VFC<Props> = (props) => {
  const { playSound, isPlaying } = usePlayDemoSound(props.selectedSpeakerDeviceId ?? undefined)
  const options = useMemo<DropdownOption[]>(() => {
    return props.speakerDevices.map((d) => ({
      label: d.label,
      value: d.deviceId,
    }))
  }, [props.speakerDevices])

  return (
    <>
      <Wrapper>
        <TxtWrapper>
          <Txt bold>スピーカー</Txt>
        </TxtWrapper>
        <DropdownWrapper>
          <Dropdown
            size="max"
            value={props.selectedSpeakerDeviceId}
            options={options}
            onChange={(e) => {
              props.onChangeDevice(e.target.value)
            }}
          />
        </DropdownWrapper>
      </Wrapper>
      <ButtonWrapper>
        <DemoSoundPlayButton
          icon={<Icon name="volume" size="s" />}
          comlinkPushParams={{
            action: 'start_playing_demo_sound_before_entered_interview',
          }}
          onClick={playSound}
          disabled={isPlaying}
        >
          スピーカーをテスト
        </DemoSoundPlayButton>
      </ButtonWrapper>
    </>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const TxtWrapper = styled.div`
  width: 80px;
  margin-bottom: 8px;
`

const DropdownWrapper = styled.div`
  margin-bottom: 8px;
  flex: 1;
`

const DemoSoundPlayButton = styled(TertiaryButton).attrs({ widthSize: 'L2', heightSize: 'S' })`
  background-color: white;
  border-color: white;
  color: #3a97cc;
`
const ButtonWrapper = styled.div`
  text-align: right;
`
