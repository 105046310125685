import React, { useEffect, useState } from 'react'
import { AuthContainer, getInternalUrls, SignInCallbackPage, SignInPage } from '@blue-agency/im-shared-front'
import { Route, Redirect, Switch } from 'react-router-dom'
import { history } from '@/historyModule'
import { Loading } from '@/shared/components/Loading'
import { useUnauthorizedTracking } from '@/shared/hooks/useTracking'

// NOTE: 他のサービスでログイン済みならログインする
// 他のサービスでログイン済みだが、シームレスログインに失敗するか
// 他のサービスでも未ログインならログイン画面にリダイレクトする
const MaybeRedirectToSignIn = () => {
  const [shouldRedirect, setShouldRedirect] = useState(false)

  const { maybeLogin } = AuthContainer.useContainer()

  useEffect(() => {
    const f = async () => {
      const loggedIn = await maybeLogin()
      if (loggedIn) return
      setShouldRedirect(true)
    }
    f()
  }, [maybeLogin])

  if (!shouldRedirect) return <Loading />

  return (
    <Redirect
      to={{
        pathname: getInternalUrls().signIn,
        search: `?redirect=${
          // サインイン後にリダイレクトするパスとして、URL の origin 以降を渡す
          window.location.href.split(window.location.origin)[1]
        }`,
      }}
    />
  )
}

export const UnauthorizedRoutes: React.VFC = () => {
  useUnauthorizedTracking(history)
  return (
    <Switch>
      <Route exact path={getInternalUrls().signIn} component={SignInPage} />
      <Route exact path={getInternalUrls().signInCallback} component={SignInCallbackPage} />
      <MaybeRedirectToSignIn />
    </Switch>
  )
}
