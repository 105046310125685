import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import invariant from 'tiny-invariant'
import { timestampToDate } from '../../bffService'
import { InterviewContainer } from '../containers/InterviewContainer'
import { Role } from '../containers/InterviewContainer/enterRpcResponseType'
import { sharedSlice, SharedState } from '../redux'
import { ChatRole } from '../types'

export function useCallEnterRpcWhenEntered() {
  const { callEnterRpc } = InterviewContainer.useContainer()
  const dispatch = useDispatch()
  const ownSoraClientId = useSelector((state: SharedState) => state.shared.ownSoraClientId)

  useEffect(() => {
    const fn = async () => {
      if (!ownSoraClientId) return

      const res = await callEnterRpc(ownSoraClientId)

      const chatMessages = res.messagesList.map((m) => {
        invariant(m.participant)

        return {
          messageGuid: m.messageGuid,
          participant: {
            ...m.participant,
            role: mapRoleToChatRole(m.role),
          },
          text: m.text,
          createTime: timestampToDate(m.createTime, true),
        }
      })

      dispatch(
        sharedSlice.actions.enterRpcCallSucceeded({
          participants: res.participantsList,
          chatRoomGuid: res.chatRoomGuid,
          chatMessages,
        })
      )
    }
    fn()
  }, [callEnterRpc, dispatch, ownSoraClientId])
}

function mapRoleToChatRole(role: Role): ChatRole {
  switch (role) {
    case Role.INTERVIEWER:
      return 'interviewer'
    case Role.INTERVIEWEE:
      return 'interviewee'
    default:
      throw new Error('unexpected role')
  }
}
