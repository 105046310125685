import { createContainer } from '@blue-agency/front-state-management'
import { GetParticipantResponse as BizGetParticipantResponse } from '@blue-agency/proton/biz_skywalker_bff'
import { GetParticipantResponse as MyGetParticipantResponse } from '@blue-agency/proton/my_skywalker_bff'
import invariant from 'tiny-invariant'
import { InterviewWebSocket } from '@/shared/hooks/useInterviewWebSocket'
import {
  InterviewQuality,
  InterviewQualityMode,
  SignalingPushMessageData,
  SoraConnectionProps,
} from '@/shared/services/interviewService/types'
import { EnterRpcResponse } from './enterRpcResponseType'

// NOTE: 構造は biz, my 共通だが、定義が別
// ひとつの型として使用したいので Union 型をつくる
// Union 型であれば型に差があるとき、biz, my どちらかの callGetParticipantRpc がこの型を返せなくてエラーになるのでよい
type GetParticipantResponse = BizGetParticipantResponse.AsObject | MyGetParticipantResponse.AsObject

export type AppType = 'biz' | 'my'

export type InterviewContainerProps = {
  appType: AppType
  interviewGuid: string
  ws: InterviewWebSocket
  mainPublisherProps: SoraConnectionProps
  screenSharingPublisherProps: SoraConnectionProps
  screenSharingSubscriberProps: SoraConnectionProps
  interviewQuality: InterviewQuality
  callEnterRpc: (soraClientId: string) => Promise<EnterRpcResponse>
  callGetParticipantRpc: (soraClientId: string) => Promise<GetParticipantResponse>
  onReceivePushEvent(data: SignalingPushMessageData): void
  onRequestFinish: () => void
  onReceiveFinishMessage: () => void
  // bizの場合のみ以下存在
  changeInterviewQuality?: (mode: InterviewQualityMode) => Promise<void>
  isInterviewTranscriptionEnabled?: boolean
}

function useInterview(props?: InterviewContainerProps) {
  invariant(props)
  return { ...props }
}

export const InterviewContainer = createContainer(useInterview)
