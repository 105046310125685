import { Comlink } from '@blue-agency/comlink'
import { setSelectedDeviceIdToStorage } from '@/lib/meetingcomponent/localStorage'

/**
 * 指定された種類のデバイスについて、選択されているデバイスが変更されたときに localStorage に保存を行う
 * ＆Comlinkでイベント送信する
 */
export function storeDeviceIdInLS(
  kind: MediaDeviceKind,
  devices: MediaDeviceInfo[],
  selectedDeviceId: string | null
): void {
  if (!selectedDeviceId) {
    return
  }
  setSelectedDeviceIdToStorage(kind, selectedDeviceId)

  const device = devices?.find((e) => e.deviceId === selectedDeviceId)
  if (device) {
    Comlink.push({
      type: 'manual_activity',
      group: 'media-devices',
      action: `${kind}_selected`,
      metadata: {
        selectedDevice: device.label,
      },
    })
  }
}
