import React from 'react'
import { Responsive, SecondaryDangerButton } from '@blue-agency/rogue'
import { useSelector } from 'react-redux'
import styled, { useTheme } from 'styled-components'
import { InterviewContainer } from '@/shared/services/interviewService/containers/InterviewContainer'
import { isInterviewReadySelector } from '@/shared/services/interviewService/redux'

const buttonText = '退室'

export const FinishButton: React.VFC = React.memo(() => {
  const { onRequestFinish } = InterviewContainer.useContainer()
  const { responsive } = useTheme()
  return <FinishButtonConsumer onRequestFinish={onRequestFinish} responsive={responsive} />
})

type ConsumerProps = {
  onRequestFinish: () => void
  responsive: Responsive
}

const FinishButtonConsumer: React.VFC<ConsumerProps> = React.memo((props) => {
  const isInterviewReady = useSelector(isInterviewReadySelector)
  const disabled = !isInterviewReady

  if (props.responsive.pc) {
    return (
      <PcStyled
        onClick={props.onRequestFinish}
        disabled={disabled}
        // NOTE: このファイル内の重複+bizとの重複は想定内
        // eslint-disable-next-line @blue-agency/react/unique-action-name
        comlinkPushParams={{ action: 'click_finish_button' }}
      >
        {buttonText}
      </PcStyled>
    )
  }
  return (
    <SpStyled
      onClick={props.onRequestFinish}
      direction="column"
      disabled={disabled}
      // eslint-disable-next-line @blue-agency/react/unique-action-name
      comlinkPushParams={{ action: 'click_finish_button' }}
    >
      {buttonText}
    </SpStyled>
  )
})

// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const PcStyled = styled(SecondaryDangerButton)`
  font-weight: bold;
  width: 84px;
`

// レイアウト実装の過渡期で、PC, SP でスタイルが異なる
// directionが必要なので、古いButtonコンポーネントを利用している
// eslint-disable-next-line deprecation/deprecation
const SpStyled = styled(SecondaryDangerButton)`
  font-weight: bold;
  border-radius: 4px;
  width: 60px;
  height: 27px;
`
